import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ResourceGroupCategoryMapInterface,
  ResourceTypeCategoryMapInterface,
} from '../../../models/master';
import {
  Row,
  Accordion,
  AccordionItem,
  Column,
  Search,
  Checkbox,
  TooltipDefinition,
} from 'carbon-components-react';
import './EditPermission.scss';
import IconWithToolTip from '../../../components/IconWithToolTip/IconWithToolTip';
import { Search16 } from '@carbon/icons-react';
import {
  permissionTableCategories,
  resourceGroupCategories,
  tabConfig,
} from '../config';

import { Tab, Tabs, TabPanel, TabList, TabPanels } from '@carbon/react';

interface PropsInterface {
  handleResourceTypeChange: (
    evt: any,
    state: any,
    target: any,
    id: any
  ) => void;
  handleResourceGroupChange: (
    evt: any,
    state: any,
    target: any,
    id: any,
    pass_resource_group_id: boolean
  ) => void;
  resourceTypeCategoryMap: ResourceTypeCategoryMapInterface[] | null;
  resourceGroupCategoryMap: ResourceGroupCategoryMapInterface[] | null;
  tabIndex: number;
}

const EditPermissionTable = (props: PropsInterface) => {
  const {
    resourceTypeCategoryMap,
    handleResourceTypeChange,
    handleResourceGroupChange,
    resourceGroupCategoryMap,
    tabIndex,
  } = props;
  const [searchText, setSearchText] = useState('');
  const { t } = useTranslation('roleDetails');

  const applySearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const tableHeaders = (tabKey: string) => {
    return (
      <div className='table-header-container'>
        <Column className='searchBar'>
          <Search
            size='lg'
            labelText={t('rolePermissionTable.searchPlaceholder') as string}
            id='role-permission-table-search'
            className='tableSearch'
            value={searchText}
            placeholder={t('searchPlaceholderText')}
            onChange={e => applySearch(e)}
            renderIcon={
              <IconWithToolTip
                icon={<Search16 />}
                iconDescription={t('rolePermissionTable.searchPlaceholder')}
              />
            }
          />
        </Column>
        <div className='header'>
          <Row>
            <Column lg={6} md={3}></Column>
            <Column lg={2} md={1}>
              {tabKey === 'resource-type' &&
                t('editPermission.tableHeaders.create')}
              {tabKey === 'resource-group' && (
                <TooltipDefinition
                  tooltipText={`
                  ${t('editPermission.resourcesLabel')}: ${t(
                    'editPermission.tooltips.resourcegroup.resources.create'
                  )}`}
                  align='center'
                >
                  <span className='text'>
                    {t('editPermission.tableHeaders.create')}
                  </span>
                </TooltipDefinition>
              )}
            </Column>
            <Column lg={2} md={1}>
              {tabKey === 'resource-type' &&
                t('editPermission.tableHeaders.read')}
              {tabKey === 'resource-group' && (
                <TooltipDefinition
                  tooltipText={`${t('editPermission.groupLabel')}: ${t(
                    'editPermission.tooltips.resourcegroup.group.read'
                  )}
                  ${t('editPermission.resourcesLabel')}: ${t(
                    'editPermission.tooltips.resourcegroup.resources.read'
                  )}`}
                  align='center'
                >
                  <span className='text'>
                    {t('editPermission.tableHeaders.read')}
                  </span>
                </TooltipDefinition>
              )}
            </Column>
            <Column lg={2} md={1}>
              {tabKey === 'resource-type' &&
                t('editPermission.tableHeaders.update')}
              {tabKey === 'resource-group' && (
                <TooltipDefinition
                  tooltipText={`${t('editPermission.groupLabel')}: ${t(
                    'editPermission.tooltips.resourcegroup.group.update'
                  )}
                  ${t('editPermission.resourcesLabel')}: ${t(
                    'editPermission.tooltips.resourcegroup.resources.update'
                  )}`}
                  align='center'
                >
                  <span className='text'>
                    {t('editPermission.tableHeaders.update')}
                  </span>
                </TooltipDefinition>
              )}
            </Column>
            <Column lg={2} md={1}>
              {tabKey === 'resource-type' &&
                t('editPermission.tableHeaders.delete')}
              {tabKey === 'resource-group' && (
                <TooltipDefinition
                  tooltipText={`${t('editPermission.groupLabel')}: ${t(
                    'editPermission.tooltips.resourcegroup.group.delete'
                  )}
                  ${t('editPermission.resourcesLabel')}: ${t(
                    'editPermission.tooltips.resourcegroup.resources.delete'
                  )}`}
                  align='center'
                >
                  <span className='text'>
                    {t('editPermission.tableHeaders.delete')}
                  </span>
                </TooltipDefinition>
              )}
            </Column>
            <Column lg={2} md={1}>
              {tabKey === 'resource-type' &&
                t('editPermission.tableHeaders.admin')}
              {tabKey === 'resource-group' && (
                <TooltipDefinition
                  tooltipText={`${t('editPermission.groupLabel')}: ${t(
                    'editPermission.tooltips.resourcegroup.group.admin'
                  )}\n
                  ${t('editPermission.resourcesLabel')}: ${t(
                    'editPermission.tooltips.resourcegroup.resources.admin'
                  )}`}
                  align='center'
                >
                  <span className='text'>
                    {t('editPermission.tableHeaders.admin')}
                  </span>
                </TooltipDefinition>
              )}
            </Column>
          </Row>
        </div>
      </div>
    );
  };

  const renderResourceTypeCheckBox = (
    id: string,
    isChecked: boolean,
    access_type: string,
    resource_id: string
  ) => {
    return (
      <Checkbox
        id={id}
        labelText={''}
        checked={isChecked}
        value={access_type}
        onChange={(evt: any, state: any, target: any) =>
          handleResourceTypeChange(evt, state, target, resource_id)
        }
      />
    );
  };

  const renderResourceGroupCheckBox = (
    id: string,
    isChecked: boolean,
    access_type: string,
    resource_id: string,
    pass_resource_group_id: boolean
  ) => {
    return (
      <Checkbox
        id={id}
        labelText={''}
        checked={isChecked}
        value={access_type}
        disabled={id.includes('disabled')}
        onChange={(evt: any, state: any, target: any) =>
          handleResourceGroupChange(
            evt,
            state,
            target,
            resource_id,
            pass_resource_group_id
          )
        }
      />
    );
  };

  const componentByResourceType = (category: string) => {
    let tableRow: any = [];

    resourceTypeCategoryMap != null &&
      resourceTypeCategoryMap
        .filter(
          (resourceType: ResourceTypeCategoryMapInterface) =>
            resourceType.label
              .toLowerCase()
              .includes(searchText.toLowerCase()) &&
            resourceType.display === true &&
            resourceType.category === category
        )
        .forEach(
          (resourceType: ResourceTypeCategoryMapInterface, index, arr) => {
            if (resourceType.category === category) {
              tableRow.push(
                <Row
                  className={`resource-type-row ${resourceType.id}`}
                  key={resourceType.id}
                >
                  <Column lg={6} md={3} className='resource-type-col'>
                    <TooltipDefinition
                      tooltipText={`${t(
                        `editPermission.tooltips.resourcetype.${resourceType.id}`
                      )}`}
                      align='center'
                      direction='bottom'
                    >
                      <span className='text'>{resourceType.label}</span>
                    </TooltipDefinition>
                  </Column>
                  <Column lg={2} md={1} className='resource-type-col'>
                    {!resourceType.edited_access_type?.create.disabled &&
                      (resourceType.edited_access_type.all.value === true ||
                      resourceType.edited_access_type?.create.value === true
                        ? renderResourceTypeCheckBox(
                            resourceType.id + '-create',
                            true,
                            'create',
                            resourceType.id
                          )
                        : renderResourceTypeCheckBox(
                            resourceType.id + '-create',
                            false,
                            'create',
                            resourceType.id
                          ))}
                  </Column>
                  <Column lg={2} md={1} className='resource-type-col'>
                    {!resourceType.edited_access_type?.read.disabled &&
                      (resourceType.edited_access_type.all.value === true ||
                      resourceType.edited_access_type?.read.value === true
                        ? renderResourceTypeCheckBox(
                            resourceType.id + '-read',
                            true,
                            'read',
                            resourceType.id
                          )
                        : renderResourceTypeCheckBox(
                            resourceType.id + '-read',
                            false,
                            'read',
                            resourceType.id
                          ))}
                  </Column>
                  <Column lg={2} md={1} className='resource-type-col'>
                    {!resourceType.edited_access_type?.update.disabled &&
                      (resourceType.edited_access_type.all.value === true ||
                      resourceType.edited_access_type?.update.value === true
                        ? renderResourceTypeCheckBox(
                            resourceType.id + '-update',
                            true,
                            'update',
                            resourceType.id
                          )
                        : renderResourceTypeCheckBox(
                            resourceType.id + '-update',
                            false,
                            'update',
                            resourceType.id
                          ))}
                  </Column>
                  <Column lg={2} md={1} className='resource-type-col'>
                    {!resourceType.edited_access_type?.delete.disabled &&
                      (resourceType.edited_access_type.all.value === true ||
                      resourceType.edited_access_type?.delete.value === true
                        ? renderResourceTypeCheckBox(
                            resourceType.id + '-delete',
                            true,
                            'delete',
                            resourceType.id
                          )
                        : renderResourceTypeCheckBox(
                            resourceType.id + '-delete',
                            false,
                            'delete',
                            resourceType.id
                          ))}
                  </Column>
                  <Column lg={2} md={1} className='resource-type-col'>
                    {!resourceType.edited_access_type?.admin.disabled && (
                      <TooltipDefinition
                        tooltipText={`${t(
                          `editPermission.tooltips.checkbox.${resourceType.id}.admin`
                        )}`}
                        align='center'
                        direction='bottom'
                        className='checkbox-tooltip'
                      >
                        {resourceType.edited_access_type.all.value === true ||
                        resourceType.edited_access_type?.admin.value === true
                          ? renderResourceTypeCheckBox(
                              resourceType.id + '-admin',
                              true,
                              'admin',
                              resourceType.id
                            )
                          : renderResourceTypeCheckBox(
                              resourceType.id + '-admin',
                              false,
                              'admin',
                              resourceType.id
                            )}
                      </TooltipDefinition>
                    )}
                  </Column>
                </Row>
              );
            }
          }
        );

    return tableRow;
  };

  const componentByResourceGroup = (grouptype: string) => {
    let tableRow: any = [];
    resourceGroupCategoryMap != null &&
      resourceGroupCategoryMap
        .filter(
          (resourceType: ResourceGroupCategoryMapInterface) =>
            resourceType.label
              .toLowerCase()
              .includes(searchText.toLowerCase()) &&
            resourceType.category === grouptype
        )
        .forEach((rg: ResourceGroupCategoryMapInterface) => {
          tableRow.push(
            <Row className={`resource-group-row ${rg.id}`} key={rg.id}>
              <Column lg={4} md={2}>
                <span className='rg_name'>{rg.label}</span>
              </Column>
              <Column lg={2} md={1} className='resource-group-col'>
                <div className='labels'>
                  <TooltipDefinition
                    tooltipText={`${t(
                      'editPermission.tooltips.resourcegroup.group.permission_name'
                    )}`}
                    align='center'
                  >
                    <span className='group-check'>
                      {t('editPermission.groupLabel')}
                    </span>
                  </TooltipDefinition>
                  <TooltipDefinition
                    tooltipText={`${t(
                      'editPermission.tooltips.resourcegroup.resources.permission_name'
                    )}`}
                    align='center'
                  >
                    <span className='resources-check'>
                      {t('editPermission.resourcesLabel')}
                    </span>
                  </TooltipDefinition>
                </div>
              </Column>
              <Column lg={2} md={1} className='resource-group-col'>
                <div className='empty-check'></div>
                <div className='resources-check'>
                  {rg.edited_resource_group_access_type.create.value === true ||
                  rg.edited_resource_group_access_type.all.value === true
                    ? renderResourceGroupCheckBox(
                        rg.id + '-create-resource-group-access-type',
                        true,
                        'create',
                        rg.id,
                        true
                      )
                    : renderResourceGroupCheckBox(
                        rg.id + '-create-resource-group-access-type',
                        false,
                        'create',
                        rg.id,
                        true
                      )}
                </div>
              </Column>
              <Column lg={2} md={1} className='resource-group-col'>
                <div className='group-check'>
                  {rg.edited_resource_access_type.read.value === true ||
                  rg.edited_resource_access_type.all.value === true ? (
                    <TooltipDefinition
                      tooltipText={`${t(
                        'editPermission.tooltips.checkbox.by_resource_group_access_read'
                      )}`}
                      align='center'
                      className='checkbox-tooltip'
                    >
                      {renderResourceGroupCheckBox(
                        `${rg.id}-read-resource-access-type ${
                          rg.edited_resource_access_type.read.disabled
                            ? 'disabled'
                            : ''
                        }`,
                        true,
                        'read',
                        rg.id,
                        false
                      )}
                    </TooltipDefinition>
                  ) : (
                    renderResourceGroupCheckBox(
                      rg.id + '-read-resource-access-type',
                      false,
                      'read',
                      rg.id,
                      false
                    )
                  )}
                </div>
                <div className='resources-check'>
                  {rg.edited_resource_group_access_type.read.value === true ||
                  rg.edited_resource_group_access_type.all.value === true
                    ? renderResourceGroupCheckBox(
                        rg.id + '-read-resource-group-access-type',
                        true,
                        'read',
                        rg.id,
                        true
                      )
                    : renderResourceGroupCheckBox(
                        rg.id + '-read-resource-group-access-type',
                        false,
                        'read',
                        rg.id,
                        true
                      )}
                </div>
              </Column>
              <Column lg={2} md={1} className='resource-group-col'>
                <div className='group-check'>
                  {rg.edited_resource_access_type.update.value === true ||
                  rg.edited_resource_access_type.all.value === true
                    ? renderResourceGroupCheckBox(
                        rg.id + '-update-resource-access-type',
                        true,
                        'update',
                        rg.id,
                        false
                      )
                    : renderResourceGroupCheckBox(
                        rg.id + '-update-resource-access-type',
                        false,
                        'update',
                        rg.id,
                        false
                      )}
                </div>
                <div className='resources-check'>
                  {rg.edited_resource_group_access_type.update.value === true ||
                  rg.edited_resource_group_access_type.all.value === true
                    ? renderResourceGroupCheckBox(
                        rg.id + '-update-resource-group-access-type',
                        true,
                        'update',
                        rg.id,
                        true
                      )
                    : renderResourceGroupCheckBox(
                        rg.id + '-update-resource-group-access-type',
                        false,
                        'update',
                        rg.id,
                        true
                      )}
                </div>
              </Column>
              <Column lg={2} md={1} className='resource-group-col'>
                <div className='group-check'>
                  {rg.edited_resource_access_type.delete.value === true ||
                  rg.edited_resource_access_type.all.value === true
                    ? renderResourceGroupCheckBox(
                        rg.id + '-delete-resource-access-type',
                        true,
                        'delete',
                        rg.id,
                        false
                      )
                    : renderResourceGroupCheckBox(
                        rg.id + '-delete-resource-access-type',
                        false,
                        'delete',
                        rg.id,
                        false
                      )}
                </div>
                <div className='resources-check'>
                  {rg.edited_resource_group_access_type.delete.value === true ||
                  rg.edited_resource_group_access_type.all.value === true
                    ? renderResourceGroupCheckBox(
                        rg.id + '-delete-resource-group-access-type',
                        true,
                        'delete',
                        rg.id,
                        true
                      )
                    : renderResourceGroupCheckBox(
                        rg.id + '-delete-resource-group-access-type',
                        false,
                        'delete',
                        rg.id,
                        true
                      )}
                </div>
              </Column>
              <Column lg={2} md={1} className='resource-group-col'>
                <div className='group-check'>
                  {rg.edited_resource_access_type.admin.value === true ||
                  rg.edited_resource_access_type.all.value === true
                    ? renderResourceGroupCheckBox(
                        rg.id + '-admin-resource-access-type',
                        true,
                        'admin',
                        rg.id,
                        false
                      )
                    : renderResourceGroupCheckBox(
                        rg.id + '-admin-resource-access-type',
                        false,
                        'admin',
                        rg.id,
                        false
                      )}
                </div>
                <div className='resources-check'>
                  {rg.edited_resource_group_access_type.admin.value === true ||
                  rg.edited_resource_group_access_type.all.value === true
                    ? renderResourceGroupCheckBox(
                        rg.id + '-admin-resource-group-access-type',
                        true,
                        'admin',
                        rg.id,
                        true
                      )
                    : renderResourceGroupCheckBox(
                        rg.id + '-admin-resource-group-access-type',
                        false,
                        'admin',
                        rg.id,
                        true
                      )}
                </div>
              </Column>
            </Row>
          );
        });
    return tableRow;
  };

  return (
    <div className='edit-permission-table-container'>
      <Tabs defaultSelectedIndex={tabIndex}>
        <TabList
          className='edit-permission-tab-contained'
          contained
          aria-label={''}
        >
          {tabConfig(t).map((tab: any) => {
            return (
              <Tab key={tab.key} className={tab.className}>
                {tab.label}
              </Tab>
            );
          })}
        </TabList>
        <TabPanels>
          {tabConfig(t).map((tab: any) => {
            return (
              <TabPanel className='edit-permission-tab-panel' key={tab.key}>
                {tableHeaders(tab.key)}
                {tab.key === 'resource-type' ? (
                  <Accordion align='start' key={tab.key}>
                    {permissionTableCategories
                      .sort((a, b) => (a.name < b.name ? 1 : -1))
                      .map(category => (
                        <AccordionItem
                          title={category.name}
                          key={category.name}
                          open
                        >
                          {componentByResourceType(category.name)}
                        </AccordionItem>
                      ))}
                  </Accordion>
                ) : (
                  <Accordion align='start' key={tab.key}>
                    {resourceGroupCategories(t).map((category: any) => (
                      <AccordionItem
                        title={category.name}
                        key={category.name}
                        open
                      >
                        {componentByResourceGroup(category.key)}
                      </AccordionItem>
                    ))}
                  </Accordion>
                )}
              </TabPanel>
            );
          })}
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default EditPermissionTable;
