import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import useAnalytics from '../../../lib/useAnalytics';
import analyticsData from '../../../lib/analyticsEventData';
import { EnvironmentTypes } from '../../../lib/enums';
import dateUtils from '../../../lib/dates';
import images from '../../../images/images.js';

import {
  DeploymentEnvironment,
  AppliedFilter,
  ResourceGroup,
  Location,
} from '../../../models/master';

import GenericTableWithFilters from '../../../components/GenericTableWithFilters/GenericTableWithFilters';
import IconWithToolTip from '../../../components/IconWithToolTip/IconWithToolTip';
import AutoDiscoverLabel from '../../../components/AutoDiscoverLabel/AutoDiscoverLabel';
import LabelTag from '../../../components/LabelTag/LabelTag';

//import './LocationsTable.scss';

interface TableRow {
  id: any;
  name: string;
  name__format: JSX.Element;
  infrastructureGroup: string;
  type: string;
  managed: string;
  autoDiscovery: JSX.Element | string | undefined;
  lastUpdated: string;
}

interface Filter {
  key: string;
  type: 'multi' | 'single';
  values: string[];
}

const LocationDetailsDeploymentTable = (props: {
  title: string;
  filteredDataCallback: (data: any) => void;
  filtersSelected: any;
  filtersAppliedCallback: (data: any) => void;
  filters: any;
  rows: DeploymentEnvironment[] | null;
  data: DeploymentEnvironment[] | null;
  filteredDataSet: DeploymentEnvironment[] | null;
  elementCount: number;
  onRefresh: () => void;
  currentPageNumber: number;
  currentPageSize: number;
  onPageChange: (pageData: any) => void;
  sortRows(arg0: unknown, direction: string): void;
  leftInlineFilters?: Filter[];
  visibilityFlag?: string;
  resourceGroupsData: ResourceGroup[] | null;
  error403Flag: boolean;
  setOpenRegisterDeploymentEnvTearSheet: () => void;
  locationData: Location | null;
}) => {
  const { t } = useTranslation('locationDetails');
  const { rows, onRefresh } = props;
  const error403Flag = props.error403Flag;
  const tableRef = useRef<HTMLDivElement>(null);
  const formatTags = (data: string[]) => {
    return <LabelTag labelArray={data} count={3} />;
  };

  const { trackButtonClicked } = useAnalytics();

  const myheaders = [
    {
      key: 'name__format',
      originalKey: 'name',
      header: t('tableHeaders.name'),
      sort: true,
    },
    {
      key: 'infrastructureGroup',
      originalKey: 'resourceGroup',
      header: t('tableHeaders.infrastructureGroup'),
      sort: true,
    },
    {
      key: 'type',
      originalKey: 'type',
      header: t('tableHeaders.type'),
      sort: true,
    },
    {
      key: 'managed',
      originalKey: 'managed',
      header: t('tableHeaders.managed'),
      sort: true,
    },
    {
      key: 'autoDiscovery',
      originalKey: 'autoDiscovery',
      header: t('tableHeaders.autoDiscovery'),
      sort: true,
    },
    {
      key: 'lastUpdated',
      originalKey: 'updated_at',
      header: t('tableHeaders.lastUpdated'),
      sort: true,
      style: { minWidth: '12.5rem' },
    },
  ];

  const autoDiscoveryLabel = (row: DeploymentEnvironment) => {
    if (row.auto_discover) {
      if (row.type === EnvironmentTypes.VPC) {
        return <AutoDiscoverLabel label={[t('securityGroup')]} />;
      } else if (row.type === EnvironmentTypes.CLUSTER) {
        return <AutoDiscoverLabel label={[t('namespaces')]} />;
      } else {
        return <AutoDiscoverLabel label={[t('apps')]} />;
      }
    } else {
      return t('off');
    }
  };

  // const getResourceGroup = (id: string) => {
  //   const resourceGroup =
  //     id && Array.isArray(props.resourceGroupsData)
  //       ? props.resourceGroupsData?.find(
  //           resourceGroup => resourceGroup.resource_id === id
  //         )
  //       : null;

  //   return resourceGroup ? resourceGroup.name : '';
  // };

  /**
   * @description - Function to format the raw data into table rows format.
   * @returns {TableRow[] | [] | null} - Returns the formatted data to be rendered as table rows.
   */
  const setRowsData = () => {
    let formattedRows: TableRow[] = [];
    if (error403Flag) return [];
    if (rows && rows.length === 0) return [];
    if (rows)
      rows.map((row: DeploymentEnvironment) => {
        formattedRows.push({
          id: row.resource_id,
          name: row.name,
          name__format: (
            <div className='name-column-value'>
              <Link
                className='no-underline-link'
                to={`/deploymentEnvironmentDetails?deplId=${row.resource_id}`}
                state={{
                  breadcrumb: [
                    {
                      url: '/',
                      label: t('home'),
                    },
                    {
                      url: '/locations',
                      label: t('locations'),
                    },
                    {
                      url: `/locationDetails?cloudId=${row.cloud_id}&locationId=${row.location_id}`,
                      label: row.location_name ?? '',
                    },
                  ],
                }}
              >
                {row.name}

                {row?.is_discovered && (
                  <div className='icon'>
                    <IconWithToolTip
                      icon={images.AutoDiscoverdLockIconSmall()}
                      iconDescription={t('autoDiscoveredAccessLimited')}
                    />
                  </div>
                )}
              </Link>
            </div>
          ),
          infrastructureGroup: row?.resourceGroup,
          managed: row.unmanaged ? t('no') : t('yes'),
          autoDiscovery: autoDiscoveryLabel(row),
          type: row.type,
          lastUpdated: dateUtils.getUserFriendlyDate(row.updated_at),
        });
        return 0;
      });
    else return null;
    return formattedRows;
  };

  const actionButtonCallback = () => {
    trackButtonClicked(
      analyticsData['Locations Details'].events.registerDepEnv.props,
      analyticsData['Locations Details'].events.registerDepEnv.event
    );
    props.setOpenRegisterDeploymentEnvTearSheet();
  };

  const emptyStateData = {
    icon: images.NoDeploymentEmptySmall(),
    notFoundIcon: images.NotFoundLarge(),
    emptyStateHeader: t('emptyState.emptyContainerHeader'),
    emptyStateDescription: t('emptyState.emptyContainerDescription'),
    link: '/',
    buttonText: t('emptyState.buttonText'),
    click: actionButtonCallback,
  };

  const actionButtonShow = () => {
    if (!props.locationData?.unmanaged) {
      return {
        text: t('registerDeploymentEnv'),
        actionButtonCallback: actionButtonCallback,
      };
    }
  };
  return (
    <div className='location-details-deployment-table' ref={tableRef}>
      <GenericTableWithFilters
        id='table-with-filter-panel'
        title={props.title}
        rows={setRowsData()}
        data={props.filteredDataSet}
        headers={myheaders}
        isSortable={true}
        totalElementsCount={props.elementCount ? props.elementCount : 0}
        fullData={props.data}
        onTableRefresh={() => onRefresh()}
        filteredDataCallback={(data: TableRow[]) =>
          props.filteredDataCallback(data)
        }
        selectedFiltersVal={props.filtersSelected as any}
        setFilterApplied={(data: AppliedFilter[]) =>
          props.filtersAppliedCallback(data)
        }
        filters={props.filters}
        currentPageNumber={props.currentPageNumber}
        currentPageSize={props.currentPageSize}
        onPageChange={(pageData: any) => props.onPageChange(pageData)}
        emptyState={emptyStateData}
        sortRows={(data, sortDirection) => props.sortRows(data, sortDirection)}
        leftInlineFilters={props.leftInlineFilters}
        actionButton={actionButtonShow()}
        visibilityFlag={props.visibilityFlag}
        render403Container={props.error403Flag}
      />
    </div>
  );
};

export default LocationDetailsDeploymentTable;
