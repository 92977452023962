import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateTearsheetStep } from '@carbon/ibm-products';
import { CheckmarkOutline16 } from '@carbon/icons-react';

import './ConnectGateway.scss';

import images from '../../../../images/images';
import { FormData } from '../RegisterDeploymentEnv';
import { InlineNotification } from 'carbon-components-react';

export type ConnectedGatewayStatus =
  | 'registerNamespace'
  | 'gatewaysInstalled'
  | 'nameSpaceExists';

export type DeploymentEnvType = 'cluster' | 'vpc' | 'node';

interface Props {
  connectGatewayStatus: ConnectedGatewayStatus;
  deploymentEnvType: DeploymentEnvType | string;
  formData: FormData;
  showError: boolean;
  onCloseRegisterError: () => void;
  titleErrorMessage: string;
  subTitleErrorMessage: string;
}

const ConnectGateway: React.FC<Props> = ({
  connectGatewayStatus,
  deploymentEnvType,
  formData,
  showError,
  onCloseRegisterError,
  titleErrorMessage,
  subTitleErrorMessage,
}) => {
  const { t } = useTranslation('registerDeploymentEnv');
  const errorRef = useRef<HTMLDivElement>(null);

  const [clicked, setClicked] = useState<boolean>(true);

  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.scrollIntoView(true);
    }
  }, [showError]);

  useEffect(() => {
    (connectGatewayStatus === 'nameSpaceExists' ||
      connectGatewayStatus === 'gatewaysInstalled') &&
      setClicked(true);
  }, [connectGatewayStatus]);

  const connectGatewayMessage: any = {
    registerNamespace: {
      title: t('connectGateway.descriptionTitle.registerNamepaces'),
      description: t('connectGateway.description.registerNamepaces'),
    },
    gatewaysInstalled: {
      title: t('connectGateway.descriptionTitle.gatewaysInstalled'),
      description: t('connectGateway.description.gatewaysInstalled'),
    },
    nameSpaceExists: {
      title: t('connectGateway.descriptionTitle.nameSpaceExists'),
      description: t('connectGateway.description.nameSpaceExists'),
    },
  };

  return (
    <>
      <CreateTearsheetStep
        className='connect-gateway-tearsheet third-step'
        title={t('connectGateway.title')}
        disableSubmit={!clicked}
        hasFieldset={false}
        includeStep={deploymentEnvType === 'cluster'}
        data-testid='deployment-env-connect-gateway-step'
      >
        {showError && (
          <div ref={errorRef}>
            <InlineNotification
              kind='error'
              aria-label=''
              title={titleErrorMessage}
              subtitle={subTitleErrorMessage}
              onClose={() => onCloseRegisterError() as any}
              lowContrast
            />
          </div>
        )}
        <div className='connect-gateway-container'>
          {!formData.unmanaged ? (
            <>
              <div className='tear-sheet-heading'>
                {connectGatewayStatus === 'nameSpaceExists' ||
                connectGatewayStatus === 'gatewaysInstalled'
                  ? t('gatewayType')
                  : t('connectGateway.heading')}
              </div>
              <div className='tile-section'>
                <div
                  className={`${
                    connectGatewayStatus === 'nameSpaceExists' ||
                    connectGatewayStatus === 'gatewaysInstalled'
                      ? 'disabled'
                      : 'enabled'
                  } connect-gateway-tile`}
                  onClick={() => {
                    setClicked(!clicked);
                  }}
                  data-testid='connect-gateway-tile'
                >
                  <div className='tile-content'>
                    <div className='tile-icons'>
                      <span className='redhat-icon icon'>
                        {images.redHatIconSvgElement()}
                      </span>
                      <span className='selected-icon icon'>
                        {connectGatewayStatus === 'registerNamespace' &&
                          clicked &&
                          images.selectedTickIcon()}
                        {(connectGatewayStatus === 'nameSpaceExists' ||
                          connectGatewayStatus === 'gatewaysInstalled') &&
                          clicked && (
                            <div className='outline-icon'>
                              <CheckmarkOutline16 />
                            </div>
                          )}
                      </span>
                    </div>
                    <div className='tile-heading'>
                      {t('connectGateway.tileOne.heading')}
                    </div>
                    <div className='tile-description'>
                      {t('connectGateway.tileOne.description')}
                    </div>
                  </div>
                </div>
              </div>
              <span className='divider'></span>
              <div className='description-section'>
                <div className='description-content'>
                  <div className='title'>
                    {connectGatewayMessage[connectGatewayStatus].title}
                  </div>
                  <div className='description'>
                    {connectGatewayMessage[connectGatewayStatus].description}
                  </div>
                </div>
                <div className='connectGatewayStepImage'>
                  {images.connectGatewayStepPng()}
                </div>
              </div>
            </>
          ) : (
            <div className='no-gateway-container'>
              <div className='no-gateway-icon-container'>
                {images.noGatewayLargeIcon()}
              </div>
              <div className='heading'>
                {t('connectGateway.noGatewayHeading')}
              </div>
              <div className='description'>
                {t('connectGateway.noGatewayDescription')}
              </div>
            </div>
          )}
        </div>
      </CreateTearsheetStep>
    </>
  );
};

export default ConnectGateway;
