import react from 'react';
import { useTranslation } from 'react-i18next';

import { IconType } from '../../ConnectionAccessPolicyStateView/config';
import Card from '../../ConnectionAccessPolicyStateView/Card';
import images from '../../../../../images/images';
import { VerticalEmptyState } from '../../../../../components/EmptyState/EmptyState';
import { NetworkSegments } from '../../../../../models/master';

import './SelectedDataCard.scss';

type SelectedType = 'from' | 'to';

interface Item {
  id: string;
  label: string;
  type: IconType;
  parentId?: string;
}

const SelectedDataCard = (props: {
  selected: SelectedType;
  selectedData: Item[];
  heading: string;
  btnDisabled: boolean;
  onRemove: (item: Item, type: SelectedType) => void;
  onViewAllBtnClick: () => void;
  selectedSegment?: NetworkSegments | null;
  onSelect: (type: SelectedType) => void;
  disableFrom?: boolean;
}) => {
  const { t } = useTranslation('createPolicyAllowConnections');
  const {
    selectedData,
    selected,
    onSelect,
    btnDisabled,
    selectedSegment,
    disableFrom,
  } = props;

  const getEmptyState = () =>
    props.heading === 'from'
      ? {
          icon: [images.noApplicationsIcon(), images.noPartitionsIcon()],
          emptyStateHeader: t('emptyState.from.emptyContainerHeader'),
          emptyStateDescription: t('emptyState.from.emptyContainerDescription'),
          link: '/',
          buttonText: t('emptyState.from.buttonText'),
          click: () => props.onViewAllBtnClick(),
        }
      : {
          icon: [images.noServicesIcon()],
          emptyStateHeader: t('emptyState.to.emptyContainerHeader'),
          emptyStateDescription: t('emptyState.to.emptyContainerDescription'),
          link: '/',
          buttonText: t('emptyState.to.buttonText'),
          click: () => props.onViewAllBtnClick(),
        };

  return (
    <div
      className={selected === props.heading ? 'cardEnabled' : 'cardDisabled'}
      //Disable the click action in the Large From Tile on the RHSI Network Segment Flow.
      onClick={() => {
        return props.heading === 'from' && disableFrom
          ? false
          : onSelect(props.heading as SelectedType);
      }}
    >
      <div className='heading'>{t(props.heading)}</div>
      {selectedData && selectedData.length > 0 ? (
        selectedData.map(item => (
          <Card
            key={item.id}
            label={item.label}
            type={item.type}
            selectedSegment={selectedSegment}
            onRemove={() =>
              props.onRemove(item, props.heading === 'from' ? 'from' : 'to')
            }
          />
        ))
      ) : (
        <VerticalEmptyState
          icon={getEmptyState().icon}
          header={getEmptyState().emptyStateHeader}
          description={getEmptyState().emptyStateDescription}
          link={getEmptyState()?.link}
          buttonText={getEmptyState()?.buttonText}
          click={getEmptyState()?.click}
          btnDisabled={btnDisabled}
        />
      )}
    </div>
  );
};

export default SelectedDataCard;
