import { useQuery } from '@tanstack/react-query';
import {
  getApplications,
  getApplicationsDeployments,
} from '../controllers/application';
import { QueryOptions, queryDefaultOptions } from './queryDefaults';
import { QueryKeys } from '../lib/enums';

export const useApplications = (
  fetchDeployments = false,
  fetchServices = false,
  options?: QueryOptions
) => {
  return useQuery({
    queryKey: [QueryKeys.APPLICATIONS],
    queryFn: () => getApplications(fetchDeployments, fetchServices),
    ...queryDefaultOptions,
    ...options,
  });
};

export const useApplicationsDeploymentsData = (options?: QueryOptions) => {
  return useQuery({
    queryKey: [QueryKeys.APPLICATIONDEPLOYMENTS],
    queryFn: getApplicationsDeployments,
    ...queryDefaultOptions,
    ...options,
  });
};
