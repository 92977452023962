import { axiosInstance } from './api';
import { MCNM_NETWORK_API_URL } from './config';

export const getClouds = async () => {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/cloud`,
    });
    return response?.data?.clouds ?? [];
  } catch (error) {
    console.log('api error: ', error);
    throw error;
  }
};

//Get one of the clouds that MCNM oversees
export const getCloudDetails = async (cloud_id: string) => {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloud_id}`,
    });
    return response.data;
  } catch (error) {
    console.log('Cloud details API error: ', error);
    throw error;
  }
};

//Add a new cloud that MCNM should oversee
export const addCloud = async ({ data }: { data: any }) => {
  try {
    const response = await axiosInstance({
      method: 'POST',
      url: `${MCNM_NETWORK_API_URL}/cloud`,
      data,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Delete an existing cloud that MCNM oversees
export const deleteCloud = async ({ cloud_id }: { cloud_id: string }) => {
  try {
    const response = await axiosInstance({
      method: 'DELETE',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloud_id}`,
    });
    return response;
  } catch (error) {
    console.log('error in DELETE clouds', error);
    throw error;
  }
};

//Update an existing cloud that MCNM oversees
export const updateCloud = async ({
  cloud_id,
  data,
}: {
  cloud_id: string;
  data: any;
}) => {
  try {
    const response = await axiosInstance({
      method: 'PUT',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloud_id}`,
      data,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCloudTypes = async () => {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/cloudtypes`,
    });
    return response.data?.cloudtypes || [];
  } catch (error) {
    throw error;
  }
};
