import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import NarrowTearsheetComponent from '../../../components/NarrowTearsheet/NarrowTearsheet';
import { NotificationContext } from '../../../components/Notifications/Context/NotificationProvider';
import InlineNotification from '../../../components/Notifications/Inline/Notification';
import { addRoleIdentity } from '../../../controllers/roleApis';
import { Identity } from '../../../models/master.js';
import { AxiosError } from 'axios';
import AssignIdentityForm from './AssignIdentityForm';
import './AssignIdentity.scss';
import { updateIdentity } from '../../../controllers/identityApis';

const defaultFormValue = {
  id: {
    value: '',
  },

  labels: {
    value: [],
  },
};

interface Props {
  open: boolean;
  roleId: string | null;
  identities: Identity[] | null;
  assignedIdentities: Identity[] | null;
  onClose: () => void;
  refreshDetailsData: () => void;
}

const AssignIdentity: React.FC<Props> = ({
  open,
  onClose,
  refreshDetailsData,
  identities,
  assignedIdentities,
  roleId,
}) => {
  const [formData, setFormData] = useState<any>(defaultFormValue);
  const [showFailNotification, toggleFailNotification] = useState(false);
  const [errorType, setErrorType] = useState('default');
  const [subTitleErrorMsg, setSubTitleErrorMsg] = useState('');
  const [selectedIdentity, setSelectedIdentity] = useState<Identity | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation('roleDetails');
  const navigate = useNavigate();
  const notification = useContext(NotificationContext);

  const handleOnChange = (name: string, value: any) => {
    if (name === 'id') {
      setSelectedIdentity(value);
      setFormData((prevState: any) => ({
        ...prevState,
        id: {
          value: value.resource_id,
        },
        labels: { value: value.labels },
      }));
    } else {
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: {
          value,
        },
      }));
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      let updatedIdentity: Identity | null = null;

      if (selectedIdentity != null) {
        try {
          updatedIdentity = await updateIdentity(formData.id.value, {
            labels: formData.labels.value,
            name: selectedIdentity.name,
            resource_id: selectedIdentity?.resource_id,
            description: selectedIdentity?.description,
          });
        } catch (error) {
          console.error(error);
        }
      }
      await addRoleIdentity(roleId, formData.id.value, {
        labels: updatedIdentity?.labels,
      });

      // Trigger success toastbar
      notification.onTrigger('TOAST', {
        title: t('assignIdentity.successNotification.title', {
          identityName: updatedIdentity?.name ?? '',
        }),
        subtitle: t('assignIdentity.successNotification.subtitle'),
      });
      // close modal and fetch new data in parent
      // TODO: navigate to card view when functionality is added
      refreshDetailsData();
    } catch (error: any) {
      console.log(error);
      const err = error as AxiosError;
      toggleFailNotification(true);
      if (err.response?.status === 403) {
        setErrorType('auth');
        setSubTitleErrorMsg(t('failureNotification.authSubtitle'));
      } else {
        setErrorType('default');
        setSubTitleErrorMsg(t('failureNotification.subtitle'));
      }

      const errorMessage: string =
        error.response != null ? error.response['customErrorMessage'] : '';
      errorMessage.length > 0 && setSubTitleErrorMsg(errorMessage);
      toggleFailNotification(true);
    } finally {
      setLoading(false);
    }
  };

  const enableSaveButton = () => {
    if (formData.id.value.length > 0) {
      return true;
    } else return false;
  };

  return (
    <NarrowTearsheetComponent
      className='assign-identity-tearsheet'
      title={t('assignIdentity.tearsheet.title')}
      description={t('assignIdentity.tearsheet.description')}
      open={open}
      actions={[
        {
          kind: 'primary',
          label: t('assignIdentity.tearsheet.submitButtonText'),
          onClick: () => handleSubmit(),
          disabled: !enableSaveButton(),
          loading: loading,
        },
        {
          kind: 'secondary',
          label: t('assignIdentity.tearsheet.cancelButtonText'),
          onClick: () => {
            refreshDetailsData();
            onClose();
            setFormData((prevState: any) => ({
              ...prevState,
              id: {
                value: '',
              },
              labels: { value: [] },
            }));
            toggleFailNotification(false);
            setLoading(false);
          },
        },
      ]}
    >
      {showFailNotification && (
        <InlineNotification
          onClose={() => toggleFailNotification(false) as any}
          kind={'error'}
          title={
            errorType === 'default'
              ? (t('failureNotification.title') as string)
              : (t('failureNotification.authTitle') as string)
          }
          subtitle={subTitleErrorMsg}
        />
      )}
      <AssignIdentityForm
        formData={formData}
        onChange={(name: string, value: any) => handleOnChange(name, value)}
        identities={identities}
        assignedIdentities={assignedIdentities}
      />
    </NarrowTearsheetComponent>
  );
};

export default AssignIdentity;
