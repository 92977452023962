export const MCNM_API_URL = process.env.REACT_APP_API_URL;
export const MCNM_TENANT_API_URL = `/tenant`;
export const MCNM_APP_API_URL = `/apps`;
export const MCNM_AUTHORIZATION_API_URL = `/auth`;
export const MCNM_NETWORK_API_URL = `/nets`;
export const MCNM_AUTH_URL =
  process.env.REACT_APP_AUTH_URL || process.env.PUBLIC_URL + '/' + MCNM_API_URL;
export const MCNM_RESOURCEGROUP_API_URL = `/auth`;
export const MCNM_TOPOLOGY_API_URL = `/topology`;
export const MCNM_METRICS_API_URL = `/metrics/api/v1`;
export const MCNM_AUTODEPLOY_GATEWAY_API_URL = `/gwdeployer`;
export const MCNM_GATEWAY_CONTROLLER_SKUPPER_API_URL = '/skupper';
export const MCNM_APP_SUBSCRIPTION_USAGE_URL = `/subscription-usage`;
export const MAPBOX_API_URL = process.env.REACT_MAPBOX_API_URL;
export const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

export function fetchToken() {
  const selectedAccount = window.localStorage.getItem('SELECTED_ACCOUNT');
  const authDetails = JSON.parse(
    localStorage.getItem(`ACCOUNT_DETAILS_${selectedAccount}`)
  );
  if (authDetails) {
    return `Bearer ${authDetails.token}`;
  }
}
