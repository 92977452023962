import React from 'react';
import { ZoomIn32, ZoomOut32, ZoomInArea32 } from '@carbon/icons-react';
import { Button } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';

import './ZoomOptions.scss';

interface Props {
  onZoomIn: () => void;
  onZoomOut: () => void;
  onResetZoom: () => void;
  zoomContainerCustomStyle: {
    [key: string]: string;
  };
}

const ZoomOptions: React.FC<Props> = ({
  onZoomIn,
  onZoomOut,
  onResetZoom,
  zoomContainerCustomStyle,
}) => {
  const { t } = useTranslation('topologyCanvasView');

  return (
    <div
      className='topology-zoom-options'
      style={zoomContainerCustomStyle ? zoomContainerCustomStyle : {}}
    >
      <Button
        kind='ghost'
        title={t('zoom.zoomIn')}
        renderIcon={ZoomIn32}
        onClick={onZoomIn}
      />
      <Button
        kind='ghost'
        title={t('zoom.zoomOut')}
        renderIcon={ZoomOut32}
        onClick={onZoomOut}
      />
      <Button
        kind='ghost'
        title={t('zoom.resetZoom')}
        renderIcon={ZoomInArea32}
        onClick={onResetZoom}
      />
    </div>
  );
};

export default ZoomOptions;
