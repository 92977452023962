import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import NarrowTearsheetComponent from '../../../components/NarrowTearsheet/NarrowTearsheet';
import { NotificationContext } from '../../../components/Notifications/Context/NotificationProvider';
import InlineNotification from '../../../components/Notifications/Inline/Notification';
import { updateRole } from '../../../controllers/roleApis';
import { Role } from '../../../models/master.js';
import { AxiosError } from 'axios';
import EditRoleDetailsForm from './EditRoleDetailsForm';
import './EditRoleDetails.scss';

const defaultFormValue = {
  name: {
    value: '',
    error: false,
    errorMessage: '',
  },
  description: { value: '' },
  labels: {
    value: [],
  },
};

const requiredFields = ['name'];

interface Props {
  open: boolean;
  roleId: string | null;
  onClose: () => void;
  refreshDetailsData: () => void;
  roleDetails: Role | null;
}

const EditRoleDetails: React.FC<Props> = ({
  open,
  onClose,
  refreshDetailsData,
  roleId,
  roleDetails,
}) => {
  const [formData, setFormData] = useState<any>(defaultFormValue);
  const [showFailNotification, toggleFailNotification] = useState(false);
  const [changed, setChanged] = useState<boolean>(false);
  const [errorType, setErrorType] = useState('default');
  const [subTitleErrorMsg, setSubTitleErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation('roleDetails');
  const navigate = useNavigate();
  const notification = useContext(NotificationContext);

  const setEditRoleData = () => {
    const updatedFormData = {
      name: {
        ...formData.name,
        value: roleDetails?.name,
      },
      description: {
        ...formData.description,
        value: roleDetails?.description,
      },

      labels: {
        ...formData.labels,
        value: roleDetails?.labels,
      },
    };

    setFormData(updatedFormData);
  };

  useEffect(() => {
    setEditRoleData();
  }, [roleDetails]);

  const handleOnChange = (name: string, value: any) => {
    if (!changed) setChanged(true);
    const errorMessage = checkFieldValidation(name, value);
    if (name === 'name') {
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: {
          value,
          error: !!errorMessage,
          errorMessage,
        },
      }));
    } else {
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: {
          value,
        },
      }));
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await updateRole(roleId, {
        name: formData.name.value,
        description: formData.description.value,
        labels: formData.labels.value,
      });
      // Trigger success toastbar
      notification.onTrigger('TOAST', {
        title: t('editRoleDetails.successNotification.title'),
        subtitle: t('editRoleDetails.successNotification.subtitle'),
      });
      // close modal and fetch new data in parent
      // TODO: navigate to card view when functionality is added
      refreshDetailsData();
    } catch (error: any) {
      console.log(error);
      const err = error as AxiosError;
      toggleFailNotification(true);
      if (err.response?.status === 403) {
        setErrorType('auth');
        setSubTitleErrorMsg(t('failureNotification.authSubtitle'));
      } else {
        setErrorType('default');
        setSubTitleErrorMsg(t('editRoleDetails.failureNotification.subtitle'));
      }

      const errorMessage: string =
        error.response != null ? error.response['customErrorMessage'] : '';
      errorMessage.length > 0 && setSubTitleErrorMsg(errorMessage);
      toggleFailNotification(true);
    } finally {
      setLoading(false);
    }
  };

  const isFormValid = () => {
    for (const field of requiredFields) {
      const value = formData[field]?.value;
      const trimmedValue = typeof value === 'string' ? value?.trim() : value;
      if (checkFieldValidation(field, trimmedValue)) {
        return false;
      }
    }
    return true;
  };

  const checkFieldValidation = (name: string, value: any) => {
    let errorMessage = '';
    switch (name) {
      case 'name':
        errorMessage = !value
          ? t('editRoleDetails.form.nameField.validation.required')
          : '';
        break;
      default:
        break;
    }
    return errorMessage;
  };

  const enableSaveButton = () => {
    if (isFormValid() && changed) {
      return true;
    } else return false;
  };

  return (
    <NarrowTearsheetComponent
      className='edit-role-details-tearsheet'
      title={t('editRoleDetails.tearsheet.title')}
      description={t('editRoleDetails.tearsheet.description')}
      open={open}
      actions={[
        {
          kind: 'primary',
          label: t('editRoleDetails.tearsheet.submitButtonText'),
          onClick: () => handleSubmit(),
          disabled: !enableSaveButton(),
          loading: loading,
        },
        {
          kind: 'secondary',
          label: t('editRoleDetails.tearsheet.cancelButtonText'),
          onClick: () => {
            onClose();
            setEditRoleData();
            toggleFailNotification(false);
            setChanged(false);
            refreshDetailsData();
            setLoading(false);
          },
        },
      ]}
    >
      {showFailNotification && (
        <InlineNotification
          onClose={() => toggleFailNotification(false) as any}
          kind={'error'}
          title={
            errorType === 'default'
              ? (t('editRoleDetails.failureNotification.title') as string)
              : (t('failureNotification.authTitle') as string)
          }
          subtitle={subTitleErrorMsg}
        />
      )}
      <EditRoleDetailsForm formData={formData} onChange={handleOnChange} />
    </NarrowTearsheetComponent>
  );
};

export default EditRoleDetails;
