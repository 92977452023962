import React from 'react';
import { useTranslation } from 'react-i18next';
import { ComponentProps } from '../config';

import './DisabledTooltip.scss';

const DisabledTooltip: React.FC<ComponentProps> = ({ node }) => {
  const { t } = useTranslation('topologyTooltipDisabled');

  return (
    <div className='topology-tooltip-disabled'>
      <div className='body'>
        <div className='label'>{t('message')}</div>
      </div>
    </div>
  );
};

export default DisabledTooltip;
