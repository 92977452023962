import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';

import WideTearsheet from '../../../components/WideTearsheet/WideTearsheet';

import { NotificationContext } from '../../../components/Notifications/Context/NotificationProvider';
import { removeExtraSpace } from '../../../lib/utils';

import './RegisterGateway.scss';
import GatewaySelect from './GatewayTypeSelect/GatewayTypeSelect';
import GatewayDeploymentEnvDetails from './GatewayDeploymentEnvDetails/GatewayDeploymentEnvDetails';
import GatewayDetailsForm from './GatewayDetailsForm/GatewayDetailsForm';
import MultiStepTearsheet from '../../../components/MultiStepTearSheet/MultiStepTearSheet';

import {
  Cloud,
  Gateway,
  NetworkSegments,
  Location,
  DeploymentEnvironment,
  GatewayIngressTypes,
  SkupperComputeProfile,
  LinkedAgent,
} from '../../../models/master.js';
import {
  addGateway,
  getGatewayIngressTypes,
  getRhsiComputeProfile,
} from '../../../controllers/gateawayApis.js';
import { getNetworkSegments } from '../../../controllers/networksegmentsApi.js';
import { getAllCloudNamespaces } from '../../../controllers/cloudApis.js';
import { getAllLocations } from '../../../controllers/locationApis.js';
import { getResourceGroups } from '../../../controllers/resourceGroupApi.js';
import {
  getDeploymentEnvSubtypes,
  getDeploymentEnvs,
  updatePartition,
} from '../../../controllers/deploymentEnv';
import { getAgentList } from '../../../controllers/agentApi';
import DeploymentEnvTable from './DeploymentEnvTable/DeploymentEnvTable';
import { registerNamespace } from '../../../controllers/partitionApi';
import { GatewayType } from '../GatewaysContainer';
import CreateNetworkSegment from '../../NetworkSegmentsContainer/CreateNetworkSegment/CreateNetworkSegment';
import { InlineNotification } from 'carbon-components-react';
import { getGwComputeProfiles } from '../../../controllers/gatewayDeploy';
import {
  GatewaySubTypes,
  IngressTypes,
  NetworkSegmentCompatibilitySet,
} from '../../../lib/enums';
import {
  domainNameValidation,
  ingressAnnotationRegex,
} from '../../../lib/regex.js';
import { DEFAULT_NETWORK_SEGMENT_ID } from '../../../lib/constants';
const defaultFormValue = {
  name: {
    value: '',
    error: false,
    errorMessage: '',
  },
  resourceGroup: {
    value: {
      resource_id: 'default-infra',
      name: 'Default_Infrastructure_Group',
    },
    error: false,
    errorMessage: '',
  },
  cloud: {
    value: {
      resource_id: '',
      name: '',
    },
    error: false,
    errorMessage: '',
  },
  location: {
    value: {
      resource_id: '',
      name: '',
    },
    error: false,
    errorMessage: '',
  },
  labels: {
    value: [],
    error: false,
    errorMessage: '',
  },
  description: {
    value: '',
    error: false,
    errorMessage: '',
  },
  intended_compute_profile: { value: '', error: false, errorMessage: '' },
  rhsi_compute_profile: { value: '', error: false, errorMessage: '' },
  skupper_site_configuration: {
    ingress_type: { value: '', error: false, errorMessage: '' },
    ingress_hostname: { value: '', error: false, errorMessage: '' },
    router_ingress_hostname: { value: '', error: false, errorMessage: '' },
    controller_ingress_hostname: { value: '', error: false, errorMessage: '' },
    ingress_annotations: { value: '', error: false, errorMessage: '' },
  },
  version: { value: '', error: false, errorMessage: '' },
  deployed_in_partition_id: {
    value: {
      resource_id: '',
      name: '',
    },
    error: false,
    errorMessage: '',
  },
  deployed_in_depl_env_id: {
    value: {
      resource_id: '',
      name: '',
    },
    error: false,
    errorMessage: '',
  },
  deployed_in_type: {
    value: '',
    error: false,
    errorMessage: '',
  },
};

const requiredFields: any = {
  'AXON-EDGE': [
    'name',
    'resourceGroup',
    'cloud',
    'location',
    'intended_compute_profile',
    'version',
  ],
  'RHSI-EDGE': ['name', 'resourceGroup', 'rhsi_compute_profile'],
  'AXON-WP': [
    'name',
    'resourceGroup',
    'cloud',
    'location',
    'intended_compute_profile',
    'version',
  ],
};

interface Props {
  open: boolean;
  onClose: () => void;
  onGatewayCreate: () => void;
  gatewayList?: Gateway[] | null;
  gatewayType?: string;
  gatewayNamesList: string[];
  gatewayTypes: GatewayType[] | null;
  clouds: Cloud[];
}

export interface Item {
  depEnvid: string;
  type: string;
  resourceGroupId: string;
  depEnvName: string;
  cloud_id: string;
  cloudName: string;
  location_id: string;
  locationName: string;
}

export type AxonConnectionMethod = 'auto' | 'manual';

export const gatewayType = (id: any) =>
  id === 'AXON-EDGE' || id === 'RHSI-EDGE' ? 'edge' : 'waypoint';

const RegisterGateway: React.FC<Props> = ({
  open,
  onClose,
  onGatewayCreate,
  gatewayList,
  gatewayNamesList,
  gatewayTypes,
  clouds,
}) => {
  const { t } = useTranslation('gatewaysContainer');

  const notification = useContext(NotificationContext);

  const [authError, setAuthError] = useState(false);
  const [subTitleErrorMsg, setSubTitleErrorMsg] = useState<string>('');
  const [showFailNotification, toggleFailNotification] = useState(false);
  const [changed, setChanged] = useState(false);
  const [axonConnectionMethod, setAxonConnectionMethod] =
    useState<AxonConnectionMethod>('auto');
  const [editDeployedInVpcMode, setEditDeployedInVpcMode] =
    useState<boolean>(false);

  const [enableIngress, setEnableIngress] = useState<boolean>(false);
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const [formData, setFormData] = useState<any>(defaultFormValue);
  const [selectedDepEnv, setSelectedDepEnv] = useState<Item>({
    depEnvid: '',
    type: '',
    resourceGroupId: '',
    depEnvName: '',
    cloud_id: '',
    cloudName: '',
    location_id: '',
    locationName: '',
  });
  const [gwComputeProfiles, setGwComputerProfiles] = useState<any[] | null>([]);
  const [axonAutoCheckboxTicked, setAxonAutoCheckboxTicked] =
    useState<boolean>(false);
  const [selectedNetworkSegment, setSelectedNetworkSegment] =
    useState<any>(null);
  const [selectedLinkedAgent, setSelectedLinkedAgent] = useState<any>(null);
  const [createNameSpace, setCreateNameSpace] = useState<boolean>(false);
  const [selectedNameSpace, setSelectedNameSpace] = useState<any>(null);

  const [firstStepComplete, setFirstStepComplete] = useState<boolean>(false);
  const [selectedGatewayType, setSelectedGatewayType] =
    useState<GatewayType | null>({
      resource_id: GatewaySubTypes.RHSI_EDGE,
      name: 'Service Interconnect edge gateway',
    });
  const [openDeplEnvTable, setOpenDeplEnvTable] = useState<boolean>(false);
  const [toggleGwFormErrorNotification, setToggleGwFormErrorNotification] =
    useState<boolean>(false);
  const [openCreateNetworkSegment, toggleCreateNetworkSegment] =
    useState(false);

  // Data storing state

  const [resourceGroupList, setResourceGroupList] = useState<any>([]);
  const [networkSegments, setNetworkSegments] = useState<NetworkSegments[]>([
    {
      name: t('createNetworkSegmentButton'),
      resource_id: 'create-network-segment',
      labels: [],
      resource_group_id: '',
      compatibility_set: NetworkSegmentCompatibilitySet.ServiceInterconnect,
    },
  ]);
  const [locations, setLocations] = useState<Location[] | null>(null);
  const [deploymentEnvsList, setDeploymentEnvsList] = useState<
    DeploymentEnvironment[] | null
  >(null);
  const [depEnvSubTypesList, setDepEnvSubTypesList] = useState<any>(null);
  const [linkedAgentList, setLinkedAgentList] = useState<LinkedAgent | null>(
    null
  );
  const [nameSpacesList, setNameSpacesList] = useState<any>([]);
  const [gwIngressTypes, setGwIngressTypes] = useState<GatewayIngressTypes[]>(
    []
  );
  const [gwRhsiComputeProfiles, setGwRhsiComputeProfiles] = useState<
    SkupperComputeProfile[]
  >([]);
  const [optionalParamInValid, setOptionalParamInValid] = useState({
    ingress_hostname: false,
    router_ingress_hostname: false,
    controller_ingress_hostname: false,
    ingress_annotations: false,
  });

  // Error storing state

  const [selectedClusterError, setSelectedClusterError] =
    useState<boolean>(false);
  const [selectedClusterErrorType, setSelectedClusterErrorType] =
    useState<string>('');
  const [inlineNotificationTitle, setInlineNotificationTitle] =
    useState<string>('');
  const [inlineNotificationSubTitle, setInlineNotificationSubTitle] =
    useState<string>('');
  const [inlineNotificationAuthTitle, setInlineNotificationAuthTitle] =
    useState<string>('');
  const [inlineNotificationAuthSubTitle, setInlineNotificationAuthSubTitle] =
    useState<string>('');

  useEffect(() => {
    if (open) {
      getResourceGroups()
        .then(response => {
          let infrasResourceGrps = response.resource_groups.filter(
            (resourceGroup: any) => resourceGroup.type === 'infrastructure'
          );
          infrasResourceGrps != null &&
            setResourceGroupList(infrasResourceGrps);
        })
        .catch(error => console.log(error));
      try {
        setDataLoading(true);
        fetchNetworkSegments();
        fetchLocations();
        fetchDepEnvSubTypes();
        fetchLinkedAgentList();
        //  fetchComputeProfiles();
        fetchDeploymentEnvs();
        fetchGwIngressTypes();
        fetchRhsiGwComputeProfiles();
      } catch (error) {
        console.log(error);
      } finally {
        setDataLoading(false);
      }
    }
  }, [open]);

  useEffect(() => {
    if (deploymentEnvsList != null) {
      deploymentEnvsList.forEach(
        (data: { [x: string]: any; resource_group_id: any }) => {
          const resourceGroup =
            data.resource_group_id && Array.isArray(resourceGroupList)
              ? resourceGroupList?.find(
                  resourceGroup =>
                    resourceGroup.resource_id === data.resource_group_id
                )
              : null;

          data['resourceGroup'] = resourceGroup ? resourceGroup.name : '';
        }
      );

      deploymentEnvsList.forEach(data => {
        const subTypeName =
          (Array.isArray(depEnvSubTypesList) &&
            depEnvSubTypesList.length > 0 &&
            depEnvSubTypesList?.find(
              depEnvSubType => depEnvSubType.type_code === data.subtype
            )?.type_name) ??
          '';

        data['subTypeName'] = subTypeName;
      });
      setDeploymentEnvsList(deploymentEnvsList);
    }
  }, [resourceGroupList, depEnvSubTypesList, deploymentEnvsList]);

  const closeNetworkSegmentTearsheet = () => {
    toggleCreateNetworkSegment(false);
  };

  const openNetworkSegmentTearsheet = () => {
    toggleCreateNetworkSegment(true);
  };

  const fetchNetworkSegments = async () => {
    let networkSegments: NetworkSegments[] = [];
    try {
      networkSegments = await getNetworkSegments();
      if (networkSegments != null) {
        networkSegments.push({
          name: t('createNetworkSegmentButton'),
          resource_id: 'create-network-segment',
          labels: [],
          resource_group_id: '',
          compatibility_set: 'RHSI',
        });
        setNetworkSegments(networkSegments);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchLocations = async () => {
    let locations;
    try {
      locations = await getAllLocations();
      locations != null && setLocations(locations);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchDeploymentEnvs = async () => {
    try {
      const deploymentEnvsList: DeploymentEnvironment[] =
        await getDeploymentEnvs('all', false, 'all');
      deploymentEnvsList != null && setDeploymentEnvsList(deploymentEnvsList);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDepEnvSubTypes = async () => {
    try {
      const envSubtypesList = await getDeploymentEnvSubtypes();
      envSubtypesList != null && setDepEnvSubTypesList(envSubtypesList);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchLinkedAgentList = async () => {
    try {
      const linkedAgentList: LinkedAgent = await getAgentList();
      setLinkedAgentList(
        linkedAgentList ?? {
          gateways: [],
          available: [],
        }
      );
    } catch (err) {
      console.log(err);
      setLinkedAgentList({
        gateways: [],
        available: [],
      });
    }
  };

  const fetchComputeProfiles = async () => {
    try {
      const gwComputeProfiles = await getGwComputeProfiles();
      gwComputeProfiles != null && setGwComputerProfiles(gwComputeProfiles);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchClusterNameSpaces = async (
    cloud_id: string,
    cluster_id: string
  ) => {
    try {
      const nameSpacesList = await getAllCloudNamespaces(cloud_id, cluster_id);
      nameSpacesList != null && setNameSpacesList(nameSpacesList.namespaces);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchGwIngressTypes = async () => {
    try {
      const gwIngressTypes = await getGatewayIngressTypes();
      Array.isArray(gwIngressTypes) && setGwIngressTypes(gwIngressTypes);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRhsiGwComputeProfiles = async () => {
    try {
      const computeProfiles = await getRhsiComputeProfile();
      Array.isArray(computeProfiles) &&
        setGwRhsiComputeProfiles(computeProfiles);
    } catch (error) {
      console.log(error);
    }
  };

  const selectDeploymentEnv = (item: Item) => {
    setSelectedDepEnv(prevState => ({
      ...prevState,
      depEnvid: item.depEnvid,
      type: item.type,
      resourceGroupId: item.resourceGroupId,
      depEnvName: item.depEnvName,
      cloud_id: item.cloud_id,
      cloudName: item.cloudName,
      location_id: item.location_id,
      locationName: item.locationName,
    }));
  };

  const enableButton = (status: boolean) => {
    setChanged(status);
  };

  const isFormValid = () => {
    let gwSubType: string =
      selectedGatewayType != null ? selectedGatewayType?.resource_id : '';

    if (gwSubType.length > 0) {
      for (const field of requiredFields[gwSubType]) {
        const value = formData[field].value;
        const trimmedValue = typeof value === 'string' ? value.trim() : value;
        if (checkFieldValidation(field, trimmedValue, '')) {
          return false;
        }
      }
    }

    return true;
  };

  const checkFieldValidation = (name: string, value: any, subKey: string) => {
    let errorMessage = '';

    switch (name) {
      case 'name':
        const valueEmpty = value === '';
        const notUnique = gatewayNamesList.find(
          name => name.trim() === value.trim()
        )
          ? true
          : false;
        const error = valueEmpty || notUnique;
        const message = valueEmpty
          ? t('validation.name.required')
          : t('validation.name.notUnique');
        errorMessage = error ? message : '';
        break;
      case 'resourceGroup':
        errorMessage = !value.resource_id
          ? t('validation.resourceGroup.required')
          : '';
        break;
      case 'cloud':
        errorMessage = !value.resource_id ? t('validation.cloud.required') : '';
        break;
      case 'location':
        errorMessage = !value.resource_id
          ? t('validation.location.required')
          : '';
        break;
      case 'intended_compute_profile':
        errorMessage =
          !value.profile_name && axonConnectionMethod === 'auto'
            ? t('validation.intended_compute_profile.required')
            : '';
        break;
      case 'rhsi_compute_profile':
        errorMessage =
          !value.profile && axonConnectionMethod === 'auto'
            ? t('validation.intended_compute_profile.required')
            : '';
        break;
      case 'gateway_image':
        errorMessage =
          !value &&
          axonConnectionMethod === 'auto' &&
          selectedDepEnv.cloudName.includes('IBM')
            ? t('validation.gateway_image.required')
            : '';
        break;
      case 'skupper_site_configuration':
        if (value) {
          if (
            subKey === 'ingress_hostname' ||
            subKey === 'router_ingress_hostname' ||
            subKey === 'controller_ingress_hostname'
          ) {
            let domainNameRegex = domainNameValidation();
            let valid = domainNameRegex.test(value);
            errorMessage = !valid ? t('validation.hostname.errorMessage') : '';
          }

          if (subKey === 'ingress_annotations') {
            let ingressAnnotation: any = ingressAnnotationRegex();
            let annotationArr = value.split(',');
            let valid = annotationArr.every((annotation: string) =>
              ingressAnnotation.test(annotation.trim())
            );
            errorMessage = !valid
              ? t('validation.ingressAnnotation.errorMessage')
              : '';
          }
        }
        setOptionalParamInValid(prevState => ({
          ...prevState,
          [subKey]: !!errorMessage,
        }));

        break;
    }

    return errorMessage;
  };

  const handleSelectDeploymentEnv = (
    selectedDepEnv: Item,
    gatewayType: GatewayType | null
  ) => {
    let deploymentPartitionId: string = '',
      deploymentPartitionName: string = '',
      updateFormAction: boolean = true,
      deployedInType = '';

    if (gatewayType != null && gatewayType.resource_id === 'RHSI-EDGE') {
      let nsNonCompatibilitysetisTrue = nameSpacesList.some(
        (namespace: any) => {
          if (namespace.network_segment_id === DEFAULT_NETWORK_SEGMENT_ID) {
            return true;
          } else
            return networkSegments.some((ns: any) => {
              if (
                ns.resource_id === namespace.network_segment_id &&
                ns.compatibility_set !== 'RHSI'
              ) {
                return true;
              }
            });
        }
      );
      if (nsNonCompatibilitysetisTrue) {
        setSelectedClusterError(true);
        setSelectedClusterErrorType('nonCompatibleCluster');
        updateFormAction = false;
      }

      let filteredNameSpace = nameSpacesList.filter(
        (namespace: any) => namespace.name === selectedLinkedAgent.namespace
      );
      if (filteredNameSpace.length > 0) {
        if (
          filteredNameSpace[0].network_segment_id !==
          selectedNetworkSegment.resource_id
        ) {
          setSelectedClusterError(true);
          setSelectedClusterErrorType('differentNetworkSegment');
          updateFormAction = false;
        } else {
          let gwIsDeployed = gatewayList?.some(
            (gateway: Gateway) =>
              gateway.subtype === 'RHSI-EDGE' &&
              gateway.deployed_in_partition_id ===
                filteredNameSpace[0].resource_id
          );
          if (gwIsDeployed) {
            setSelectedClusterError(true);
            setSelectedClusterErrorType('gatewayIsDeployed');
            updateFormAction = false;
          } else {
            deploymentPartitionName = filteredNameSpace[0].name;
            deploymentPartitionId = filteredNameSpace[0].resource_id;
            deployedInType = 'cluster';
            createNameSpace && setCreateNameSpace(false);
            setSelectedNameSpace(filteredNameSpace[0]);
          }
        }
      } else {
        setCreateNameSpace(true);
        deployedInType = 'cluster';
        deploymentPartitionId = '';
        deploymentPartitionName = selectedLinkedAgent.namespace;
      }
    } else if (
      gatewayType != null &&
      (gatewayType.resource_id === 'AXON-EDGE' ||
        gatewayType.resource_id === 'AXON-WP')
    ) {
      deploymentPartitionId = '';
      deploymentPartitionName = '';
      deployedInType = 'vpc';
    }

    const selectedResourceGroup = resourceGroupList?.find(
      (rg: any) => rg.resource_id === selectedDepEnv.resourceGroupId
    );

    const updatedFormData = {
      resourceGroup: {
        ...formData.resourceGroup,
        value: {
          resource_id: selectedResourceGroup?.resource_id ?? '',
          name: selectedResourceGroup?.name ?? '',
        },
      },
      cloud: {
        ...formData.cloud,
        value: {
          resource_id: selectedDepEnv.cloud_id,
          name: selectedDepEnv.cloudName,
        },
      },
      location: {
        ...formData.cloud,
        value: {
          resource_id: selectedDepEnv.location_id,
          name: selectedDepEnv.locationName,
        },
      },
      deployed_in_depl_env_id: {
        ...formData.deployed_in_depl_env_id,
        value: {
          resource_id: selectedDepEnv.depEnvid,
          name: selectedDepEnv.depEnvName,
        },
      },
      deployed_in_partition_id: {
        ...formData.deployed_in_partition_id,
        value: {
          resource_id: deploymentPartitionId,
          name: deploymentPartitionName,
        },
      },
      deployed_in_type: {
        ...formData.deployed_in_type,
        value: deployedInType,
      },
    };

    if (updateFormAction) {
      setFormData((formData: any) => ({
        ...formData,
        ...updatedFormData,
      }));
      setOpenDeplEnvTable(false);
      setSelectedClusterError(false);
      setSelectedClusterErrorType('');
    }
  };

  const handleClickDeploymentEnv = (
    item: Item,
    makeApiCall: boolean = true
  ) => {
    if (
      selectedGatewayType != null &&
      selectedGatewayType.resource_id === 'RHSI-EDGE'
    ) {
      makeApiCall && fetchClusterNameSpaces(item.cloud_id, item.depEnvid);
    }
    selectDeploymentEnv(item);
    setSelectedClusterError(false);
    setSelectedClusterErrorType('');
  };

  const handleOnBlur = (name: string, value: any, subKey: string = '') => {
    let defaultSkupperConfig = JSON.parse(
      JSON.stringify(defaultFormValue.skupper_site_configuration)
    );
    const errorMessage = checkFieldValidation(name, value, subKey);

    if (errorMessage)
      if (name === 'skupper_site_configuration') {
        if (
          subKey === 'ingress_type' &&
          (value.id.length === 0 || value.id === IngressTypes.NONE)
        ) {
          setFormData((prevState: any) => ({
            ...prevState,
            skupper_site_configuration: {
              ...defaultSkupperConfig,
              ingress_type: { ...defaultSkupperConfig.ingress_type, value },
            },
          }));
          setOptionalParamInValid({
            ingress_hostname: false,
            router_ingress_hostname: false,
            controller_ingress_hostname: false,
            ingress_annotations: false,
          });
        } else {
          setFormData((prevState: any) => ({
            ...prevState,
            skupper_site_configuration: {
              ...prevState.skupper_site_configuration,
              [subKey]: {
                value,
                error: !!errorMessage,
                errorMessage,
              },
            },
          }));
        }
      } else {
        setFormData((prevState: any) => ({
          ...prevState,
          [name]: {
            value,
            error: !!errorMessage,
            errorMessage,
          },
        }));
      }
  };

  const handleOnChange = (name: string, value: any, subKey: string = '') => {
    let defaultSkupperConfig = JSON.parse(
      JSON.stringify(defaultFormValue.skupper_site_configuration)
    );
    let errorMessage = checkFieldValidation(name, value, subKey);

    if (name === 'skupper_site_configuration') {
      if (
        subKey === 'ingress_type' &&
        (value.id.length === 0 || value.id === IngressTypes.NONE)
      ) {
        setFormData((prevState: any) => ({
          ...prevState,
          skupper_site_configuration: {
            ...defaultSkupperConfig,
            ingress_type: { ...defaultSkupperConfig.ingress_type, value },
          },
        }));
        setOptionalParamInValid({
          ingress_hostname: false,
          router_ingress_hostname: false,
          controller_ingress_hostname: false,
          ingress_annotations: false,
        });
      } else {
        setFormData((prevState: any) => ({
          ...prevState,
          skupper_site_configuration: {
            ...prevState.skupper_site_configuration,
            [subKey]: {
              value,
              error: !!errorMessage,
              errorMessage,
            },
          },
        }));
      }
    } else {
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: {
          value,
          error: !!errorMessage,
          errorMessage,
        },
      }));
    }
  };

  const handleSubmit = async () => {
    toggleFailNotification(false);
    setAuthError(false);
    setSubTitleErrorMsg('');
    setInlineNotificationAuthSubTitle('');
    setInlineNotificationAuthTitle('');
    setInlineNotificationTitle('');
    setInlineNotificationSubTitle('');

    let skupperConfigArgs = {
      ingress: formData?.skupper_site_configuration?.ingress_type?.value?.id,
      ingress_host:
        formData?.skupper_site_configuration?.ingress_hostname?.value,
      router_ingress_host:
        formData?.skupper_site_configuration?.router_ingress_hostname?.value,
      controller_ingress_host:
        formData?.skupper_site_configuration?.controller_ingress_hostname
          ?.value,
      ingress_annotations:
        formData?.skupper_site_configuration?.ingress_annotations?.value,
    };

    let gateway: any = null,
      gateway_data: any;

    // Axon Gateway creation
    if (
      selectedGatewayType?.resource_id === 'AXON-EDGE' ||
      selectedGatewayType?.resource_id === 'AXON-WP'
    ) {
      gateway_data = {
        name: removeExtraSpace(formData?.name?.value),
        description: formData?.description?.value.trim(),
        labels: formData?.labels?.value,
        resource_group_id: formData?.resourceGroup?.value.resource_id,
        cloud_id: formData?.cloud?.value.resource_id,
        location_id: formData?.location?.value.resource_id,
        subtype: selectedGatewayType?.resource_id,
        type: gatewayType(selectedGatewayType?.resource_id),
        auto_deploy: axonConnectionMethod === 'auto' ? true : false,
        deployed_in_type: formData?.deployed_in_type.value,
        deployed_in_depl_env_id:
          formData?.deployed_in_depl_env_id.value.resource_id,
        n_instances: 1,
      };

      if (axonConnectionMethod === 'auto') {
        gateway_data['intended_compute_profile'] =
          formData?.intended_compute_profile?.value?.profile_name;
        // gateway_data['version'] = formData.version.value;
      }

      try {
        gateway = await addGateway(gateway_data);

        notification.onTrigger('TOAST', {
          title: t('successNotificationGateway.title'),
          subtitle: t('successNotificationGateway.subtitle', {
            name: gateway.name,
          }),
          lowContrast: true,
        });

        // close modal and fetch refresh data
        // TODO: navigate to details view in future
        onGatewayCreate();
      } catch (error) {
        const err = error as AxiosError;
        let errorMessage: string = '';
        if (err.response?.status === 403) {
          setAuthError(true);
          setInlineNotificationAuthTitle(
            t('failureNotificationGateway.authTitle')
          );
          setInlineNotificationAuthSubTitle(
            t('failureNotificationGateway.authSubtitle')
          );
        }
        if (err.response?.data?.error ?? err.response?.data?.status) {
          if (err.response?.data?.error != null) {
            errorMessage =
              err.response?.data?.error.length > 0
                ? err.response?.data?.error
                : '';
          } else if (err.response?.data?.status != null) {
            errorMessage =
              err.response?.data?.status.length > 0
                ? err.response?.data?.status
                : '';
          }
        }
        setInlineNotificationTitle(
          t('failureNotificationGateway.title') as string
        );
        setInlineNotificationSubTitle(
          t('failureNotificationGateway.subtitle') as string
        );
        errorMessage.length > 0 &&
          setSubTitleErrorMsg(
            errorMessage[0].toUpperCase() + errorMessage.slice(1) + '.'
          );
        toggleFailNotification(true);
        return Promise.reject(() => console.log(error));
      }
    }

    // Skupper Gateway Creation

    if (selectedGatewayType?.resource_id === 'RHSI-EDGE') {
      let newNameSpace: any;

      if (createNameSpace) {
        try {
          newNameSpace = await registerNamespace(
            formData?.cloud?.value.resource_id,
            formData?.deployed_in_depl_env_id?.value.resource_id,
            {
              name: selectedLinkedAgent?.namespace,
              network_segment_id: selectedNetworkSegment?.resource_id,
            }
          );
          notification.onTrigger('TOAST', {
            title: t('successNotificationNamespace.title'),
            subtitle: t('successNotificationNamespace.subtitle', {
              name: newNameSpace.name,
            }),
            lowContrast: true,
          });
          setCreateNameSpace(false);
        } catch (error) {
          const err = error as AxiosError;
          let errorMessage: string = '';
          if (err.response?.status === 403) {
            setAuthError(true);
            setInlineNotificationAuthTitle(
              t('failureNotificationNamespace.authTitle')
            );
            setInlineNotificationAuthSubTitle(
              t('failureNotificationNamespace.authSubtitle')
            );
          }
          if (err.response?.data?.error ?? err.response?.data?.status) {
            if (err.response?.data?.error != null) {
              errorMessage =
                err.response?.data?.error.length > 0
                  ? err.response?.data?.error
                  : '';
            } else if (err.response?.data?.status != null) {
              errorMessage =
                err.response?.data?.status.length > 0
                  ? err.response?.data?.status
                  : '';
            }
          }
          setInlineNotificationTitle(
            t('failureNotificationNamespace.title') as string
          );
          setInlineNotificationSubTitle(
            t('failureNotificationNamespace.subtitle') as string
          );
          errorMessage.length > 0 &&
            setSubTitleErrorMsg(
              errorMessage[0].toUpperCase() + errorMessage.slice(1) + '.'
            );
          toggleFailNotification(true);
          return Promise.reject(() => console.log(error));
        }

        if (newNameSpace) {
          const rhsi_gateway_data = {
            name: formData?.name?.value,
            subtype: selectedGatewayType?.resource_id?.toUpperCase(),
            type: gatewayType(selectedGatewayType?.resource_id),
            resource_group_id: formData?.resourceGroup?.value.resource_id,
            cloud_id: formData?.cloud?.value.resource_id,
            location_id: formData?.location?.value.resource_id,
            deployed_in_type: 'namespace',
            deployed_in_depl_env_id:
              formData?.deployed_in_depl_env_id?.value.resource_id,
            deployed_in_partition_id: newNameSpace?.resource_id,
            description: formData?.description?.value.trim(),
            labels: formData?.labels?.value,
            auto_deploy: false,
            open_horizon_node_id: selectedLinkedAgent?.id,
            n_instances: 1,
            intended_compute_profile:
              formData.rhsi_compute_profile?.value?.profile,
            site_configuration: JSON.stringify(skupperConfigArgs),
          };
          try {
            gateway = await addGateway(rhsi_gateway_data);
            setTimeout(() => {
              notification.onTrigger('TOAST', {
                title: t('successNotificationGateway.title'),
                subtitle: t('successNotificationGateway.subtitle', {
                  name: gateway.name,
                }),
                lowContrast: true,
              });
            }, 1500);

            // close modal and fetch new data
            // TODO: navigate to details view in future
            onGatewayCreate();
          } catch (error) {
            const err = error as AxiosError;
            let errorMessage: string = '';
            if (err.response?.status === 403) {
              setAuthError(true);
              setInlineNotificationAuthTitle(
                t('failureNotificationGateway.authTitle')
              );
              setInlineNotificationAuthSubTitle(
                t('failureNotificationGateway.authSubtitle')
              );
            }
            if (err.response?.data?.error ?? err.response?.data?.status) {
              if (err.response?.data?.error != null) {
                errorMessage =
                  err.response?.data?.error.length > 0
                    ? err.response?.data?.error
                    : '';
              } else if (err.response?.data?.status != null) {
                errorMessage =
                  err.response?.data?.status.length > 0
                    ? err.response?.data?.status
                    : '';
              }
            }
            setInlineNotificationTitle(
              t('failureNotificationGateway.title') as string
            );
            setInlineNotificationSubTitle(
              t('failureNotificationGateway.subtitle') as string
            );
            errorMessage.length > 0 &&
              setSubTitleErrorMsg(
                errorMessage[0].toUpperCase() + errorMessage.slice(1) + '.'
              );
            toggleFailNotification(true);
            return Promise.reject(() => console.log(error));
          }
        }

        if (gateway) {
          try {
            await updatePartition(
              'cluster',
              'namespace',
              newNameSpace?.cloud_id,
              newNameSpace?.cluster_id,
              newNameSpace?.resource_id,
              {
                ...newNameSpace,
                gateway_id: gateway?.resource_id,
                auto_discover: true,
              }
            );

            setTimeout(() => {
              notification.onTrigger('TOAST', {
                title: t('successNotificationUpdateNamespace.title'),
                subtitle: t('successNotificationUpdateNamespace.subtitle', {
                  name: newNameSpace.name,
                }),
                lowContrast: true,
              });
            }, 3000);
          } catch (error) {
            const err = error as AxiosError;
            notification.onTrigger('TOAST', {
              title: t('failureNotificationUpdateNamespace.title'),
              subtitle: t('failureNotificationUpdateNamespace.subtitle', {
                name: newNameSpace.name,
              }),
              kind: 'error',
              lowContrast: true,
            });

            console.log(err);
          }
        }
      } else {
        const rhsi_gateway_data = {
          name: formData?.name.value,
          subtype: selectedGatewayType?.resource_id.toUpperCase(),
          type: gatewayType(selectedGatewayType?.resource_id),
          resource_group_id: formData?.resourceGroup?.value.resource_id,
          cloud_id: formData?.cloud?.value.resource_id,
          location_id: formData?.location?.value.resource_id,
          deployed_in_type: 'namespace',
          deployed_in_depl_env_id:
            formData?.deployed_in_depl_env_id?.value?.resource_id,
          deployed_in_partition_id:
            formData?.deployed_in_partition_id?.value?.resource_id,
          description: formData?.description?.value.trim(),
          labels: formData?.labels?.value,
          auto_deploy: false,
          open_horizon_node_id: selectedLinkedAgent?.id,
          n_instances: 1,
          intended_compute_profile:
            formData?.rhsi_compute_profile?.value?.profile,
          site_configuration: JSON.stringify(skupperConfigArgs),
        };
        try {
          gateway = await addGateway(rhsi_gateway_data);
          notification.onTrigger('TOAST', {
            title: t('successNotificationGateway.title'),
            subtitle: t('successNotificationGateway.subtitle', {
              name: gateway.name,
            }),
            lowContrast: true,
          });

          // close modal and fetch new data
          // TODO: navigate to details view in future
          onGatewayCreate();
        } catch (error) {
          const err = error as AxiosError;
          let errorMessage: string = '';
          if (err.response?.status === 403) {
            setAuthError(true);
            setInlineNotificationAuthTitle(
              t('failureNotificationGateway.authTitle')
            );
            setInlineNotificationAuthSubTitle(
              t('failureNotificationGateway.authSubtitle')
            );
          }
          if (err.response?.data?.error ?? err.response?.data?.status) {
            if (err.response?.data?.error != null) {
              errorMessage =
                err.response?.data?.error.length > 0
                  ? err.response?.data?.error
                  : '';
            } else if (err.response?.data?.status != null) {
              errorMessage =
                err.response?.data?.status.length > 0
                  ? err.response?.data?.status
                  : '';
            }
          }
          setInlineNotificationTitle(
            t('failureNotificationGateway.title') as string
          );
          setInlineNotificationSubTitle(
            t('failureNotificationGateway.subtitle') as string
          );
          errorMessage.length > 0 &&
            setSubTitleErrorMsg(
              errorMessage[0].toUpperCase() + errorMessage.slice(1) + '.'
            );
          toggleFailNotification(true);
          return Promise.reject(() => console.log(error));
        }
        if (gateway) {
          try {
            let partition = await updatePartition(
              'cluster',
              'namespace',
              formData?.cloud?.value.resource_id,
              formData?.deployed_in_depl_env_id?.value.resource_id,
              formData?.deployed_in_partition_id?.value.resource_id,
              {
                ...selectedNameSpace,
                gateway_id: gateway?.resource_id,
                auto_discover: true,
              }
            );

            setTimeout(() => {
              notification.onTrigger('TOAST', {
                title: t('successNotificationUpdateNamespace.title'),
                subtitle: t('successNotificationUpdateNamespace.subtitle', {
                  name: partition.name,
                }),
                lowContrast: true,
              });
            }, 1500);
            setSelectedNameSpace(null);
          } catch (error) {
            const err = error as AxiosError;
            notification.onTrigger('TOAST', {
              title: t('failureNotificationUpdateNamespace.title'),
              subtitle: t('failureNotificationUpdateNamespace.subtitle', {
                name: newNameSpace.name,
              }),
              kind: 'error',
              lowContrast: true,
            });

            console.log(err);
          }
        }
      }
    }
  };

  const handleErrorBarClose = () => {
    toggleFailNotification(false);
    setAuthError(false);
    setSubTitleErrorMsg('');
    setInlineNotificationAuthSubTitle('');
    setInlineNotificationAuthTitle('');
    setInlineNotificationTitle('');
    setInlineNotificationSubTitle('');
    setCreateNameSpace(false);
  };

  return (
    <>
      <MultiStepTearsheet
        className='register-gateway-tearsheet'
        submitButtonText={t('selectGatewayTypeTearSheet.submitButtonText')}
        cancelButtonText={t('selectGatewayTypeTearSheet.cancelButtonText')}
        backButtonText={t('selectGatewayTypeTearSheet.backButtonText')}
        nextButtonText={t('selectGatewayTypeTearSheet.nextButtonText')}
        description={t('createGateway.multisteptearsheet.description')}
        title={t('createGateway.multisteptearsheet.title')}
        open={open}
        onClose={onClose}
        onRequestSubmit={handleSubmit}
      >
        <GatewaySelect
          toggleComplete={setFirstStepComplete}
          complete={firstStepComplete}
          gatewayTypeList={gatewayTypes}
          selectedGatewayType={selectedGatewayType}
          selectGatewayTypeCallback={(e: GatewayType) => {
            selectedGatewayType != null &&
              setSelectedDepEnv({
                depEnvid: '',
                type: '',
                resourceGroupId: '',
                depEnvName: '',
                cloud_id: '',
                cloudName: '',
                location_id: '',
                locationName: '',
              });
            selectedGatewayType != null && setFormData(defaultFormValue);
            selectedLinkedAgent != null && setSelectedLinkedAgent(null);
            toggleGwFormErrorNotification &&
              setToggleGwFormErrorNotification(false);
            setSelectedGatewayType(e);
          }}
          loading={dataLoading}
        />
        {
          <GatewayDeploymentEnvDetails
            networkSegments={networkSegments}
            linkedAgents={linkedAgentList}
            gatewayType={selectedGatewayType}
            openDeploymentEnvTable={() => {
              setOpenDeplEnvTable(true);
            }}
            resourceGroupList={resourceGroupList}
            onChange={handleOnChange}
            formData={formData}
            axonConnectionMethod={axonConnectionMethod}
            onSelectAxonConnectionMethod={(method: AxonConnectionMethod) => {
              setAxonConnectionMethod(method);
            }}
            selectedDepEnv={selectedDepEnv}
            onClickEditDeployedInVpcBtn={() => setEditDeployedInVpcMode(true)}
            onSelectNetworkSegment={(networkSegment: any) => {
              setSelectedNetworkSegment(networkSegment);
              if (selectedDepEnv != null) {
                setSelectedDepEnv({
                  depEnvid: '',
                  type: '',
                  resourceGroupId: '',
                  depEnvName: '',
                  cloud_id: '',
                  cloudName: '',
                  location_id: '',
                  locationName: '',
                });
                setCreateNameSpace(false);
              }
            }}
            onSelectLinkedAgent={(linkedAgent: any) => {
              setSelectedLinkedAgent(linkedAgent);
              if (selectedDepEnv != null) {
                setSelectedDepEnv({
                  depEnvid: '',
                  type: '',
                  resourceGroupId: '',
                  depEnvName: '',
                  cloud_id: '',
                  cloudName: '',
                  location_id: '',
                  locationName: '',
                });
                setCreateNameSpace(false);
              }
            }}
            selectedLinkedAgent={selectedLinkedAgent}
            deploymentEnvs={
              deploymentEnvsList != null
                ? deploymentEnvsList.filter((env: DeploymentEnvironment) => {
                    if (selectedGatewayType !== null) {
                      if (
                        selectedGatewayType.resource_id === 'AXON-EDGE' ||
                        selectedGatewayType.resource_id === 'AXON-WP'
                      ) {
                        if (env.type === 'vpc') {
                          let gwIsDeployed = gatewayList?.some(
                            gateway =>
                              Object.keys(gateway).includes(
                                'deployed_in_depl_env_id'
                              ) &&
                              gateway.subtype !== 'RHSI-EDGE' &&
                              gateway.deployed_in_depl_env_id ===
                                env.resource_id
                          );
                          if (gwIsDeployed) {
                            return false;
                          }
                        }
                        let cloudAutoDiscoverOn: boolean = false;
                        if (clouds.length > 0) {
                          let filteredCloud = clouds.find(
                            cloud =>
                              cloud.resource_id === env.cloud_id &&
                              env.type === 'vpc'
                          );
                          cloudAutoDiscoverOn =
                            filteredCloud != null &&
                            filteredCloud.auto_discover;
                        }

                        if (axonConnectionMethod === 'auto') {
                          return (
                            env.unmanaged === false &&
                            env.type === 'vpc' &&
                            cloudAutoDiscoverOn
                          );
                        } else {
                          return env.unmanaged === false && env.type === 'vpc';
                        }
                      } else if (
                        selectedGatewayType.resource_id === 'RHSI-EDGE'
                      ) {
                        return (
                          env.unmanaged === false && env.type === 'cluster'
                        );
                      }
                    }
                  })
                : []
            }
            openNetworkSegmentTearsheet={openNetworkSegmentTearsheet}
            selectedNetworkSegment={selectedNetworkSegment}
            onBlur={handleOnBlur}
            notifyCreateNameSpace={createNameSpace}
            toggleNotifyCreateNameSpace={
              () => {}
              // setCreateNameSpace(!createNameSpace)
            }
            disableSubmit={() => {
              if (
                selectedGatewayType?.resource_id === 'AXON-EDGE' ||
                selectedGatewayType?.resource_id === 'AXON-WP'
              ) {
                if (axonConnectionMethod === 'auto') {
                  if (
                    Object.values(selectedDepEnv).every(
                      prop => prop.length === 0
                    )
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return false;
                }
              } else if (selectedGatewayType?.resource_id === 'RHSI-EDGE') {
                if (
                  Object.values(selectedDepEnv).every(prop => prop.length === 0)
                ) {
                  return true;
                } else {
                  return false;
                }
              }
            }}
          />
        }
        {
          <GatewayDetailsForm
            resourceGroupList={resourceGroupList}
            formData={formData}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            cloudsList={clouds}
            locationsList={locations}
            gatewayType={selectedGatewayType}
            axonConnectionMethod={axonConnectionMethod}
            axonAutoCheckboxTicked={axonAutoCheckboxTicked}
            handleAxonAutoCheckbox={() =>
              setAxonAutoCheckboxTicked(!axonAutoCheckboxTicked)
            }
            selectedDepEnv={selectedDepEnv}
            enableIngress={enableIngress}
            handleSelectIngressCheckbox={(id: string) => {
              id === '' || id === IngressTypes.NONE
                ? enableIngress && setEnableIngress(!enableIngress)
                : !enableIngress && setEnableIngress(!enableIngress);
            }}
            isFormValid={isFormValid()}
            clouds={clouds}
            gwComputeProfiles={[]}
            gwIngressTypes={gwIngressTypes}
            gwRhsiComputeProfiles={gwRhsiComputeProfiles}
            setToggleErrorNotification={setToggleGwFormErrorNotification}
            toggleErrorNotification={toggleGwFormErrorNotification}
            optionalParamInValid={Object.values(optionalParamInValid).some(
              Boolean
            )}
            selectedGatewayType={selectedGatewayType}
          >
            {showFailNotification && (
              <InlineNotification
                onClose={() => handleErrorBarClose() as any}
                kind={'error'}
                title={
                  authError
                    ? inlineNotificationAuthTitle
                    : inlineNotificationTitle
                }
                subtitle={
                  authError
                    ? inlineNotificationAuthSubTitle
                    : subTitleErrorMsg.length > 0
                    ? subTitleErrorMsg
                    : inlineNotificationSubTitle
                }
                lowContrast
              />
            )}
          </GatewayDetailsForm>
        }
      </MultiStepTearsheet>
      <WideTearsheet
        className='deployment-env-table-wide-tearsheet'
        title={
          selectedGatewayType?.resource_id === 'AXON-EDGE' ||
          selectedGatewayType?.resource_id === 'AXON-WP'
            ? t('deploymentEnvTable.vpcTitle')
            : t('deploymentEnvTable.clusterTitle')
        }
        description={
          selectedGatewayType?.resource_id === 'AXON-EDGE' ||
          selectedGatewayType?.resource_id === 'AXON-WP'
            ? t('deploymentEnvTable.vpcDescription')
            : t('deploymentEnvTable.clusterDescription')
        }
        open={openDeplEnvTable}
        actions={[
          {
            kind: 'primary',
            label: t('deploymentEnvTable.selectBtn'),
            onClick: () => {
              handleSelectDeploymentEnv(selectedDepEnv, selectedGatewayType);
            },
            disabled: Object.values(selectedDepEnv).every(
              prop => prop.length === 0
            ),
          },
          {
            kind: 'secondary',
            label: t('deploymentEnvTable.cancelBtn'),
            onClick: () => {
              setOpenDeplEnvTable(false);
              setSelectedClusterError(false);
              setSelectedClusterErrorType('');
              setCreateNameSpace(false);
              if (!editDeployedInVpcMode) {
                setSelectedDepEnv({
                  depEnvid: '',
                  type: '',
                  resourceGroupId: '',
                  depEnvName: '',
                  cloud_id: '',
                  cloudName: '',
                  location_id: '',
                  locationName: '',
                });
              } else {
                setEditDeployedInVpcMode(false);
              }
            },
          },
        ]}
      >
        {
          <DeploymentEnvTable
            deploymentEnvs={
              deploymentEnvsList != null
                ? deploymentEnvsList.filter((env: DeploymentEnvironment) => {
                    if (selectedGatewayType !== null) {
                      if (
                        selectedGatewayType.resource_id === 'AXON-EDGE' ||
                        selectedGatewayType.resource_id === 'AXON-WP'
                      ) {
                        if (env.type === 'vpc') {
                          let gwIsDeployed = gatewayList?.some(
                            gateway =>
                              Object.keys(gateway).includes(
                                'deployed_in_depl_env_id'
                              ) &&
                              gateway.subtype !== 'RHSI-EDGE' &&
                              gateway.deployed_in_depl_env_id ===
                                env.resource_id
                          );
                          if (gwIsDeployed) {
                            return false;
                          }
                        }
                        let cloudAutoDiscoverOn: boolean = false;
                        if (clouds.length > 0) {
                          let filteredCloud = clouds.find(
                            cloud =>
                              cloud.resource_id === env.cloud_id &&
                              env.type === 'vpc'
                          );
                          cloudAutoDiscoverOn =
                            filteredCloud != null &&
                            filteredCloud.auto_discover;
                        }

                        if (axonConnectionMethod === 'auto') {
                          return (
                            env.unmanaged === false &&
                            env.type === 'vpc' &&
                            cloudAutoDiscoverOn
                          );
                        } else {
                          return env.unmanaged === false && env.type === 'vpc';
                        }
                      } else if (
                        selectedGatewayType.resource_id === 'RHSI-EDGE'
                      ) {
                        return (
                          env.unmanaged === false && env.type === 'cluster'
                        );
                      }
                    }
                  })
                : []
            }
            resourceGroupsData={resourceGroupList}
            deploymentEnvSubtypesData={depEnvSubTypesList}
            handleSelectDepEnv={(item: Item, makeApiCall: boolean) =>
              handleClickDeploymentEnv(item, makeApiCall)
            }
            selectedDepEnv={selectedDepEnv}
            enableButton={(status: boolean) => enableButton(status)}
            error={selectedClusterError}
            errorType={selectedClusterErrorType}
            onCloseError={() => {
              setSelectedClusterError(false);
              setSelectedClusterErrorType('');
            }}
          />
        }
      </WideTearsheet>
      {openCreateNetworkSegment && (
        <CreateNetworkSegment
          open={openCreateNetworkSegment}
          onClose={closeNetworkSegmentTearsheet}
          networkSegments={networkSegments}
          groups={resourceGroupList}
          refreshData={() => {
            fetchNetworkSegments();
          }}
          actionType={'ADD'}
          className={'create-network-segment'}
        />
      )}
    </>
  );
};

export default RegisterGateway;
