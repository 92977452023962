import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Service, ServiceEndPoints } from '../../models/master';
import GenericTableWithFilters from '../../components/GenericTableWithFilters/GenericTableWithFilters';
import images from '../../images/images';
import dateUtils from '../../lib/dates';
import './ServiceEndPointsApplDeployments.scss';
import {
  Button,
  Column,
  Row,
  TextInput,
  Link as CarbonLink,
  ComboBox,
} from 'carbon-components-react';
import { Add16, TrashCan16 } from '@carbon/icons-react';
import { CardEmptyState } from '../../components/CardEmptyState/CardEmptyState';
import InlineNotification from '../../components/Notifications/Inline/Notification';
import { NotificationContext } from '../../components/Notifications/Context/NotificationProvider';
import { ipRegexPattern } from '../../lib/regex';
import { AxiosError } from 'axios';
import { addDeploymentServiceEndPoint } from '../../controllers/applicationApis';
import { VerticalEmptyState } from '../../components/EmptyState/EmptyState';
import useAnalytics from '../../lib/useAnalytics';
import analyticsData from '../../lib/analyticsEventData';

const defaultFormValue = {
  local_service_ip_address: {
    value: '',
    error: false,
    errorMessage: '',
  },
  service_id: {
    value: null,
    error: false,
    errorMessage: '',
  },
};

interface FormData {
  local_service_ip_address: {
    value: string;
    error: boolean;
    errorMessage: string;
  };
  service_id: {
    value: Service | null;
    error: boolean;
    errorMessage: string;
  };
}

const requiredFields = ['local_service_ip_address', 'service_id'];

const ServiceEndPointsApplDeployments = (props: {
  serviceEndPointsData: ServiceEndPoints[] | null;
  onRefresh: () => void;
  removeServiceEndPoints: (serviceEndPointId: any) => void;
  hasFilter?: boolean;
  hasPagination?: boolean;
  filteredDataSet?: any;
  elementCount?: any;
  filteredDataCallback: (data: any) => void;
  filtersSelected?: any;
  filtersAppliedCallback: (data: any) => void;
  filters?: any;
  currentPageNumber?: number;
  currentPageSize?: number;
  onPageChange: (pageData: any) => void;
  sortRows(arg0: unknown, sortDirection: string): void;
  applicationId: string | null;
  deploymentId: string | null;
  deploymentEnvId: string | null;
  services: Service[] | null;
  onRegisterServiceEndpoint: (serviceEndPoint: ServiceEndPoints) => void;
}) => {
  const { t } = useTranslation('applicationDeploymentDetails');
  const { onRefresh, removeServiceEndPoints } = props;

  const notification = useContext(NotificationContext);

  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState<FormData>(defaultFormValue);
  const [loading, setLoading] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState<{
    errorType: string;
    display: boolean;
  }>({ errorType: 'default', display: false });

  const { trackButtonClicked } = useAnalytics();

  useEffect(() => {
    if (
      Array.isArray(props.serviceEndPointsData) &&
      props.serviceEndPointsData.length
    ) {
      setShowForm(true);
    } else setShowForm(false);
  }, [props.serviceEndPointsData]);

  const checkDuplicateIp = (ip: string) => {
    const trimmedIP = ip?.trim();
    const serviceEndPoint = props.serviceEndPointsData?.find(
      serviceEndPoint => serviceEndPoint.local_service_ip_address === trimmedIP
    );

    return serviceEndPoint ? true : false;
  };

  const checkFieldValidation = (name: string, value: any) => {
    let errorMessage = '';
    switch (name) {
      case 'local_service_ip_address':
        const regexPattern = ipRegexPattern();
        errorMessage = !value
          ? t('serviceEndPoints.validation.local_service_ip_address.required')
          : !regexPattern.test(value)
          ? t(
              'serviceEndPoints.validation.local_service_ip_address.invalidRegex'
            )
          : checkDuplicateIp(value)
          ? t('serviceEndPoints.validation.local_service_ip_address.duplicate')
          : '';
        break;
      case 'service_id':
        errorMessage = !value
          ? t('serviceEndPoints.validation.service_id.required')
          : '';
    }
    return errorMessage;
  };

  const handleOnChange = (name: string, value: any) => {
    const errorMessage = checkFieldValidation(name, value);

    setFormData((prevState: any) => ({
      ...prevState,
      [name]: {
        value,
        error: !!errorMessage,
        errorMessage,
      },
    }));
  };

  const isFormValid = () => {
    for (const field of requiredFields) {
      const value = (formData as any)[field].value;
      const trimmedValue = typeof value === 'string' ? value.trim() : value;
      if (checkFieldValidation(field, trimmedValue)) {
        return false;
      }
    }

    return true;
  };

  const handleSubmit = async () => {
    try {
      trackButtonClicked(
        analyticsData['Application Deployment Details'].events
          .registerServiceEndpoint.props,
        analyticsData['Application Deployment Details'].events
          .registerServiceEndpoint.event
      );
      setLoading(true);
      const data = {
        local_service_ip_address:
          formData.local_service_ip_address?.value?.trim(),
        service_id: formData.service_id.value?.resource_id,
        depl_env_id: props.deploymentEnvId,
        deployment_id: props.deploymentId,
      };

      const serviceEndpointData = await addDeploymentServiceEndPoint(
        props.applicationId,
        props.deploymentId,
        data
      );

      props.onRegisterServiceEndpoint(serviceEndpointData as any);

      setFormData(defaultFormValue);

      // Trigger success toastbar
      notification.onTrigger('TOAST', {
        title: t('serviceEndPoints.success.title'),
        subtitle: t('serviceEndPoints.success.subtitle'),
      });
    } catch (error: any) {
      console.error(error);
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        setShowErrorSnackbar({ errorType: '403', display: true });
      } else {
        setShowErrorSnackbar({ errorType: 'default', display: true });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCloseErrorBar = () => {
    setShowErrorSnackbar({ errorType: 'default', display: false });
  };

  const serviceEndPointsHeaders = [
    {
      key: 'serviceName',
      originalKey: 'serviceName',
      header: t('serviceEndPoints.serviceName'),
      sort: 'sortByName',
    },
    {
      key: 'local_ip',
      originalKey: 'local_ip',
      header: t('serviceEndPoints.localIp'),
      sort: 'sortByName',
    },
    {
      key: 'global_ip',
      originalKey: 'global_ip',
      header: t('serviceEndPoints.globalIp'),
      sort: 'sortByName',
    },
    {
      key: 'updated',
      originalKey: 'updated',
      header: t('serviceEndPoints.updated'),
      sort: 'sortByName',
    },
    {
      key: 'deleteServiceEndPoint',
      originalKey: 'updated',
    },
  ];

  const getServiceName = (service_id: string) => {
    const service = props.services?.find(
      service => service.resource_id === service_id
    );

    return service ? service.name : '';
  };

  const setRowsData = () => {
    let formattedRows: {
      id: string;
      serviceName: string;
      local_ip: any;
      global_ip: string;
      updated: any;
      deleteServiceEndPoint: JSX.Element;
    }[] = [];
    if (props.serviceEndPointsData && props.serviceEndPointsData.length === 0)
      return [];

    if (props.serviceEndPointsData)
      props.serviceEndPointsData.map((row: ServiceEndPoints) => {
        formattedRows.push({
          id: row.resource_id,
          serviceName: getServiceName(row.service_id),
          local_ip: row.local_service_ip_address,
          global_ip: row.global_ip_address,
          updated: dateUtils.getUserFriendlyDate(row.updated_at),
          deleteServiceEndPoint: (
            <div className='instance-delete-icon'>
              <div className='icon'>
                <CarbonLink
                  className='delete-link'
                  onClick={() => {
                    trackButtonClicked(
                      analyticsData['Application Deployment Details'].events
                        .deleteServiceEndpoint.props,
                      analyticsData['Application Deployment Details'].events
                        .deleteServiceEndpoint.event
                    );
                    removeServiceEndPoints(row);
                  }}
                >
                  <TrashCan16 className='trash-can-svg' />
                </CarbonLink>
              </div>
            </div>
          ),
        });

        return 0;
      });
    else return null;
    return formattedRows;
  };

  const emptyStateData = {
    icon: images.noApplicationsIcon(),
    notFoundIcon: images.NotFoundLarge(),
    emptyStateHeader: t('serviceEndPoints.emptyState.emptyContainerHeader'),
    emptyStateDescription: t(
      'serviceEndPoints.emptyState.emptyContainerDescription'
    ),
    link: '/',
    buttonText: t('serviceEndPoints.emptyState.buttonText'),
  };

  return (
    <div className='application-deployment-services-card'>
      <div className='header-container'>
        <div className='header'>{t('serviceEndPoints.header')}</div>
        {!showForm && (
          <Button
            kind='ghost'
            className='add-deployment-button'
            renderIcon={Add16}
            onClick={() => setShowForm(true)}
          >
            <span className='text'>
              {t('serviceEndPoints.emptyState.buttonText')}
            </span>
          </Button>
        )}
      </div>
      {showForm ? (
        <div className='local-ip-and-register'>
          <Row>
            <Column lg={10} md={8}>
              {showErrorSnackbar?.display && (
                <InlineNotification
                  onClose={() => handleCloseErrorBar() as any}
                  kind={'error'}
                  title={
                    showErrorSnackbar?.errorType === '403'
                      ? (t('serviceEndPoints.error.authTitle') as string)
                      : (t('serviceEndPoints.error.title') as string)
                  }
                  subtitle={
                    showErrorSnackbar?.errorType === '403'
                      ? (t('serviceEndPoints.error.authSubtitle') as string)
                      : (t('serviceEndPoints.error.subtitle') as string)
                  }
                />
              )}
            </Column>
          </Row>
          <Row className='section'>
            <Column lg={4} md={3}>
              <ComboBox
                id='service_id'
                name='service_id'
                className='service_id'
                selectedItem={formData?.service_id?.value}
                onChange={data => {
                  handleOnChange('service_id', data.selectedItem);
                }}
                items={props.services ?? []}
                itemToString={item => (item?.name ? item?.name : '')}
                translateWithId={t}
                titleText={t('serviceEndPoints.service_id.labels')}
                placeholder={t('serviceEndPoints.service_id.placeholder')}
                invalid={formData?.service_id?.error}
                invalidText={formData?.service_id?.errorMessage}
              />
            </Column>

            <Column lg={4} md={3}>
              <TextInput
                labelText={t('localip') as string}
                id='local_service_ip_address'
                name={t('localip')}
                placeholder={t('inputPlaceholder')}
                autoComplete='off'
                maxLength={45}
                value={formData?.local_service_ip_address?.value}
                onChange={e =>
                  handleOnChange('local_service_ip_address', e.target.value)
                }
                invalid={formData?.local_service_ip_address?.error}
                invalidText={formData?.local_service_ip_address?.errorMessage}
              />
            </Column>
            <Column lg={4} md={2}>
              <div className='btn-action'>
                <Button
                  className='registerLocalIp'
                  kind='primary'
                  size='md'
                  disabled={!isFormValid() || loading}
                  onClick={handleSubmit}
                >
                  {t('registerButton')} <Add16 />
                </Button>
              </div>
            </Column>
          </Row>
          {Array.isArray(props.serviceEndPointsData) &&
            props.serviceEndPointsData.length > 0 && (
              <Row>
                <Column lg={10}>
                  <div className='table-container'>
                    <GenericTableWithFilters
                      id='instance-application-depl-table'
                      rows={setRowsData()}
                      data={props.filteredDataSet}
                      headers={serviceEndPointsHeaders}
                      isSortable={false}
                      totalElementsCount={
                        props.elementCount ? props.elementCount : 0
                      }
                      fullData={props.filteredDataSet}
                      onTableRefresh={() => onRefresh()}
                      filteredDataCallback={(data: any) =>
                        props.filteredDataCallback(data)
                      }
                      selectedFiltersVal={props.filteredDataSet as any}
                      setFilterApplied={(data: any) =>
                        props.filtersAppliedCallback(data)
                      }
                      filters={props.filteredDataSet}
                      currentPageNumber={props.currentPageNumber}
                      currentPageSize={props.currentPageSize}
                      onPageChange={(pageData: any) =>
                        props.onPageChange(pageData)
                      }
                      emptyState={emptyStateData}
                      sortRows={(data, sortDirection) =>
                        props.sortRows(data, sortDirection)
                      }
                      hasFilter={true}
                      hasPagination={false}
                    />
                  </div>
                </Column>
              </Row>
            )}
        </div>
      ) : (
        <VerticalEmptyState
          icon={images.noServiceEndpointLargeIcon()}
          header={t('serviceEndPoints.emptyState.emptyContainerHeader')}
          description={t(
            'serviceEndPoints.emptyState.emptyContainerDescription'
          )}
        />
      )}
    </div>
  );
};

export default ServiceEndPointsApplDeployments;
