import React from 'react';
import { useTranslation } from 'react-i18next';
import { genericStatusField, genericTableCardStatusConfig } from './Config';
import './GenericStatusField.scss';

interface Props {
  status: genericStatusField;
  showLabel?: boolean;
}

const GenericStatusField: React.FC<Props> = ({ status, showLabel = true }) => {
  const { t } = useTranslation('genericStatusField');

  const config = genericTableCardStatusConfig(t, status);

  if (config) {
    const Icon = config.icon ?? null;
    return (
      <div className='generic-status-component'>
        {Icon && <span className='status-icon'>{<Icon />}</span>}
        {showLabel && <span className='status-label'>{config.label}</span>}
      </div>
    );
  }

  return <></>;
};

export default GenericStatusField;
