import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { HierarchyLink } from 'd3';
import { useTranslation } from 'react-i18next';
import {
  EdgeClassType,
  Location,
  Locations,
  Resouce,
  Visibility,
  Node,
} from '../types';
import D3Graph from './D3Graph';
import Options, { SelectedLayers, SupportedOptions } from './Options';
import ZoomOptions from './ZoomOptions';

import './TopologyCanvasView.scss';
import { VisibilityFlags } from '../../../lib/enums';
import TopologyEmptyState from '../TopologyEmptyState';
import LegendButton from '../LegendsContainer/LegendButton';
import LegendPanel from './LegendPanel';
import GraphParams from './GraphParams';
import { ENABLE_TOPOLOGY_GRAPH_CONFIG } from '../../../config';

interface Props {
  data: Locations;
  onNodeHover: (
    node: Location | Resouce,
    position: { x: number; y: number }
  ) => void;
  onNodeLeave: () => void;
  onNodeClick: (node: Location | Resouce) => void;
  onEdgeClick: (
    edge: HierarchyLink<Location | Resouce>,
    edgeType: EdgeClassType
  ) => void;
  zoomContainerCustomStyle: {
    [key: string]: string;
  };
  graphParamsCustomStyle: {
    [key: string]: string;
  };
  legendCustomStyle: {
    [key: string]: string;
  };
  onVisibilityChange: (visibility: Visibility) => void;
  visibility: string;
  selectedNode?: Location | Resouce | null;
  setOpen: (open: boolean) => void;
  open: boolean;
  setLegendOpen: (open: boolean) => void;
  legendOpen: boolean;
  legendContainerStyle: {
    [key: string]: string;
  };
}

const defaultSelectedLayers = {
  applicationConnections: true,
  policy: false,
  unmanaged: false,
  gateways: true,
  gatewayConnections: true,
};
const noneSelectedLayers = {
  applicationConnections: false,
  policy: false,
  unmanaged: false,
  gateways: false,
  gatewayConnections: false,
};

const TopologyCanvasView = forwardRef<any, Props>(
  (
    {
      data,
      onNodeHover,
      onNodeLeave,
      onNodeClick,
      onEdgeClick,
      zoomContainerCustomStyle,
      graphParamsCustomStyle,
      legendCustomStyle,
      onVisibilityChange,
      visibility,
      selectedNode,
      setOpen,
      open,
      setLegendOpen,
      legendOpen,
      legendContainerStyle,
    },
    ref
  ) => {
    const canvasRef = useRef<HTMLDivElement>(null);
    const d3Instance = useRef<D3Graph | null>(null);

    const { t } = useTranslation('topologyCanvasView');

    const [selectedOption, setSelectedOption] =
      useState<SupportedOptions | null>(null);

    const [selectedLayers, setSelectedLayers] = useState({
      ...defaultSelectedLayers,
      unmanaged: visibility === VisibilityFlags.ALL,
    });
    const [selectedExpansionLevel, setSelectedExpansionLevel] = useState(1);

    useEffect(() => {
      if (canvasRef.current) {
        try {
          if (!window.localStorage.getItem('TOPOLOGY_LAYERS_SELECTED')) {
            window.localStorage.setItem(
              'TOPOLOGY_LAYERS_SELECTED',
              JSON.stringify(defaultSelectedLayers)
            );
          }
          const selLayers = window.localStorage.getItem(
            'TOPOLOGY_LAYERS_SELECTED'
          );
          if (selLayers) {
            const selLayersObj = JSON.parse(selLayers);
            setSelectedLayers(selLayersObj);
          }

          d3Instance.current = new D3Graph(canvasRef.current, data, t, {
            onNodeHover,
            onNodeLeave,
            onNodeClick,
            onEdgeClick,
            onCanvasClicked: handleCanvasClick,
            onNodeDblClick: handleNodeDblClick,
          });

          if (d3Instance.current) {
            d3Instance.current.initZoom();
            d3Instance.current.selectedLayers = selectedLayers;
            d3Instance.current.toggleNodes();
          }
          window.addEventListener('resize', handleResize);
          handleResize();
        } catch (error) {
          console.error(error);
        }
      }
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
      const selLayersfromStorage = window.localStorage.getItem(
        'TOPOLOGY_LAYERS_SELECTED'
      );
      if (selLayersfromStorage) {
        const defaultLayersObj = JSON.parse(selLayersfromStorage);

        setSelectedLayers(defaultLayersObj);
        if (d3Instance.current) {
          d3Instance.current.selectedLayers = defaultLayersObj;
          d3Instance.current.toggleNodes();
        }
      }
    }, [window.localStorage.getItem('TOPOLOGY_LAYERS_SELECTED')]);

    const handleResize = () => {
      if (d3Instance.current) {
        d3Instance.current.resizeCanvas();
      }
    };

    const handleZoomIn = () => {
      if (d3Instance.current) {
        d3Instance.current.zoomIn();
      }
    };

    const handleZoomOut = () => {
      if (d3Instance.current) {
        d3Instance.current.zoomOut();
      }
    };

    const handleReset = () => {
      if (d3Instance.current) {
        d3Instance.current.resetZoom();
      }
    };

    const handleLinkDistance = (data: any) => {
      if (d3Instance.current) {
        d3Instance.current.handleD3LinkDistance(data);
      }
    };

    const handleCanvasClick = () => {
      handleToggleOptionSelection(null);
    };

    const handleToggleOptionSelection = (value: SupportedOptions | null) => {
      setSelectedOption(value);
    };

    const handleToggleLayerSelction = (
      type: keyof SelectedLayers,
      value: boolean
    ) => {
      let updatedSelectedLayers;
      if (type === 'gatewayConnections' && value === true) {
        updatedSelectedLayers = {
          ...selectedLayers,
          [type]: value,
          gateways: true,
        };
      } else if (type === 'gateways' && value === false) {
        updatedSelectedLayers = {
          ...selectedLayers,
          [type]: value,
          gatewayConnections: false,
        };
      } else if (type === 'gateways' && value === true) {
        updatedSelectedLayers = { ...selectedLayers, [type]: value };
        open && setOpen(false);
      } else {
        updatedSelectedLayers = { ...selectedLayers, [type]: value };
      }

      if (type === VisibilityFlags.UNMANAGED) {
        onVisibilityChange(
          value ? VisibilityFlags.ALL : VisibilityFlags.MANAGED
        );
        open && setOpen(false);
      }

      setSelectedLayers(updatedSelectedLayers);
      if (updatedSelectedLayers) {
        window.localStorage.setItem(
          'TOPOLOGY_LAYERS_SELECTED',
          JSON.stringify(updatedSelectedLayers)
        );
      }

      if (d3Instance.current) {
        d3Instance.current.selectedLayers = updatedSelectedLayers;
        d3Instance.current.renderNodes();
      }
    };

    const handleToggleNodeSelction = (level: number) => {
      setSelectedExpansionLevel(level);
      level === 1 && open && setOpen(false);

      if (d3Instance.current) {
        d3Instance.current.selectedExpansionLevel = level;
        d3Instance.current.enableFixNodesPosition = true;
        d3Instance.current.toggleNodes();
      }
    };

    const handleNodeDblClick = (node: Node<Location | Resouce>) => {
      setSelectedExpansionLevel(0);
      if (
        (d3Instance.current?.focusedEdge &&
          !d3Instance?.current?.checkEdgeVisible(
            d3Instance?.current?.focusedEdge
          )) ||
        (d3Instance.current?.focusedNode &&
          !d3Instance?.current?.checkNodeVisible(
            d3Instance?.current?.focusedNode
          )) ||
        (node.data.children !== null &&
          d3Instance?.current?.focusedEdge.includes(node.data.resource_id))
      ) {
        setOpen(false);
      }
    };

    useImperativeHandle(ref, () => ({
      toggleFocusedEdge(edge: string) {
        if (d3Instance.current) {
          d3Instance.current.focusedEdge = edge;
          d3Instance.current.renderNodes();
        }
      },
      toggleFocusedNode(node: string) {
        if (d3Instance.current) {
          d3Instance.current.focusedNode = node;
          d3Instance.current.renderNodes();
        }
      },
    }));

    return (
      <>
        {Array.isArray(data?.locations) && data?.locations.length > 0 ? (
          <>
            <div ref={canvasRef} className='topology-canvas-view'></div>

            <ZoomOptions
              zoomContainerCustomStyle={zoomContainerCustomStyle}
              onZoomIn={handleZoomIn}
              onZoomOut={handleZoomOut}
              onResetZoom={handleReset}
            />
            <LegendButton
              legendCustomStyle={legendCustomStyle}
              setLegendOpen={setLegendOpen}
            />
            <LegendPanel
              legendCustomStyle={legendContainerStyle}
              setLegendOpen={setLegendOpen}
              legendOpen={legendOpen}
              legendContainerStyle={legendContainerStyle}
            />
          </>
        ) : (
          <TopologyEmptyState />
        )}

        <Options
          selectedOption={selectedOption}
          onToggleOptionSelection={handleToggleOptionSelection}
          selectedLayers={selectedLayers}
          onToggleLayerSelection={handleToggleLayerSelction}
          selectedExpansionLevel={selectedExpansionLevel}
          onToggleNodeSelction={handleToggleNodeSelction}
        />
        {ENABLE_TOPOLOGY_GRAPH_CONFIG == 'true' && (
          <GraphParams
            handleConnSlider={(data: any) => handleLinkDistance(data)}
            graphParamCustomStyle={graphParamsCustomStyle}
          />
        )}
      </>
    );
  }
);

export default TopologyCanvasView;
