import React, { useState, useEffect } from 'react';
import { Search } from 'carbon-components-react';
import { Location16 } from '@carbon/icons-react';

import { getAddressLocation } from '../../controllers/geoLocationApi';

import { useTranslation } from 'react-i18next';
import './AddressSearch.scss';

export interface Address {
  id: string;
  name: string;
  street_address: string;
  city: string;
  state: string;
  country: string;
  coordinates: string;
  zip: string;
}

interface Props {
  onSelect: (address: Address) => void;
  name: string;
}

const AddressSearch: React.FC<Props> = ({ name, onSelect }) => {
  const [searchValue, setSearchValue] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [addressList, setAddressList] = useState<Address[]>([]);

  const { t } = useTranslation('addressSearch');

  useEffect(() => {
    const getData = setTimeout(() => {
      fetchAddress();
    }, 500);

    return () => clearTimeout(getData);
  }, [searchValue]);

  const fetchAddress = async () => {
    if (searchValue?.length >= 3) {
      try {
        await getAddressLocation(searchValue).then(response => {
          setAddressList(response.features);
        });
        setShowOptions(true);
      } catch (error) {
        console.log(error);
        throw error;
      }
    } else {
      setShowOptions(false);
    }
  };

  const handleAddressSelect = (addressData: Address) => {
    onSelect(addressData);
    setShowOptions(false);
    setAddressList([]);
  };

  const getAddressPredictionValues = (
    prediction: any,
    i: number,
    predictionData: any
  ) => {
    const formattedData: any = {};
    if (!predictionData.zip && prediction.context[i].id.includes('postcode')) {
      formattedData.zip = prediction.context[i].text;
    }
    if (!predictionData.state && prediction.context[i].id.includes('region')) {
      formattedData.state = prediction.context[i].text;
    }
    if (
      !predictionData.country &&
      prediction.context[i].id.includes('country')
    ) {
      formattedData.country = prediction.context[i].text;
    }
    if (!predictionData.city && prediction.context[i].id.includes('place')) {
      formattedData.city = prediction.context[i].text;
    }
    return formattedData;
  };

  const formatAddressValues = (prediction: any) => {
    let predictionData = {
      id: prediction.id,
      name: prediction.place_name,
      street_address: '',
      coordinates: '',
      zip: '',
      city: '',
      state: '',
      country: '',
    };
    if (prediction.place_type[0] === 'poi') {
      predictionData.street_address = `${prediction.text || ''}, ${
        prediction.properties.address
      }`;
    } else if (prediction.place_type[0] === 'address') {
      predictionData.street_address = `${prediction.address || ''} ${
        prediction.addressline || prediction.text
      }`;
    } else if (prediction.properties && prediction.properties.address) {
      predictionData.street_address = prediction.properties.address;
    }
    if (prediction.geometry) {
      predictionData.coordinates = `${prediction.geometry.coordinates[0]}, ${prediction.geometry.coordinates[1]}`;
    }
    if (prediction.place_type[0] === 'place') {
      predictionData.city = prediction.text;
    }
    if (prediction.place_type[0] === 'postcode') {
      predictionData.zip = prediction.text;
    }
    if (prediction.place_type[0] === 'region') {
      predictionData.state = prediction.text;
    }
    if (prediction.place_type[0] === 'country') {
      predictionData.country = prediction.text;
    }
    if (prediction.context) {
      for (let i = 0; i < prediction.context.length; i += 1) {
        if (prediction.context[i]) {
          const formattedData = getAddressPredictionValues(
            prediction,
            i,
            predictionData
          );
          predictionData = { ...predictionData, ...formattedData };
        }
      }
    }
    return predictionData;
  };

  const getAddressDropdown = () => {
    return (
      <div className='address-search-div'>
        {addressList?.map((addressList: Address) => {
          const addressData = formatAddressValues(addressList);
          return (
            addressData && (
              <div
                key={addressData.id}
                className='address-search-list'
                onClick={() => handleAddressSelect(addressData)}
              >
                <div className='address-search-icon'>
                  <Location16 />
                </div>
                <div className='address-search-content'>
                  {addressData.street_address && (
                    <p className='address-search-title'>
                      {addressData.street_address}
                    </p>
                  )}
                  <div className='address-search-subtitle'>
                    {addressData.city && <span>{addressData.city}, </span>}
                    {addressData.state && <span>{addressData.state}</span>}
                    {addressData.country && <p>{addressData.country}</p>}{' '}
                  </div>
                </div>
              </div>
            )
          );
        })}
      </div>
    );
  };

  return (
    <div className='address-search-component'>
      <div className='address-search-bar'>
        <Search
          name={name}
          labelText=''
          onChange={e => setSearchValue(e.target.value)}
          size='lg'
          placeholder={t('placeholderText')}
          id='search-address'
          onClear={() => {
            setShowOptions(false);
            setAddressList([]);
          }}
        />
        {showOptions && (
          <div>
            {addressList && addressList.length > 0 ? (
              getAddressDropdown()
            ) : (
              <div className='address-search-list'>{t('noResultsFound')}</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default AddressSearch;
