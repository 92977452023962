import { ComponentType } from 'react';
import { EdgeClassType, Location, Resouce, ResouceType } from '../types';
import ApplicationDetails from './ApplicationDetails/ApplicationDetails';
import ApplicationService from './ApplicationService/ApplicationService';
import LocationDetails from './LocationDetails/LocationDetails';
import GatewayDetails from './GatewayDetails/GatewayDetails';
import DeploymentEnvironmentDetails from './DeploymentEnvironments/DeploymentEnvironments';
import ServiceDetails from './ServiceDetails/ServiceDetails';
import GatewayConnection from './GatewayConnection/GatewayConnection';
import ApplicationServiceConnection from './ApplicationServiceConnection/ApplicationServiceConnection';
import PartitionDetails from './PartitionDetails/PartitionDetails';
import { NetworkSegment } from '../../../models/master';

export interface NodeSidePaneDetailsComponentProps {
  node: Location | Resouce;
  open: boolean;
  onClose: () => void;
  sidePanelWidth: string;
  handleSidePanelWidth: (width: string) => void;
  handleHeaderWidth: (width: number) => void;
  initialHeaderWidth: number | null;
  visibility?: 'all' | 'managed' | 'unmanaged';
  headerWidth: number | null;
}

export interface NodeComponentConfig {
  component: ComponentType<NodeSidePaneDetailsComponentProps> | null;
}

export const getNodeComponentConfig = (type: 'location' | ResouceType) => {
  const config = {
    location: {
      component: LocationDetails,
    },
    deployment_env: {
      component: DeploymentEnvironmentDetails,
    },
    application: {
      component: ApplicationDetails,
    },
    service: {
      component: ServiceDetails,
    },
    gateway: {
      component: GatewayDetails,
    },
    partition: {
      component: PartitionDetails,
    },
  };

  return config[type] as NodeComponentConfig;
};

export interface EdgeSidePaneDetailsComponentProps {
  source: Location | Resouce;
  target: Location | Resouce;
  open: boolean;
  onClose: () => void;
  sidePanelWidth: string;
  handleSidePanelWidth: (width: string) => void;
  handleHeaderWidth: (width: number) => void;
  initialHeaderWidth: number | null;
  headerWidth: number | null;
  networkSegments?: NetworkSegment[] | null;
}

export interface EdgeComponentConfig {
  component: ComponentType<EdgeSidePaneDetailsComponentProps> | null;
}

export type EdgeType = 'application-service' | 'gateway-gateway';

export const getEdgeComponentConfig = (
  type: EdgeType,
  edgeType: EdgeClassType
) => {
  const config = {
    'relation-line': {
      'application-service': {
        component: ApplicationService,
      },
    },
    'gateway-line': {
      'gateway-gateway': {
        component: GatewayConnection,
      },
    },
    'gateway-line-hitSpot': {
      'gateway-gateway': {
        component: GatewayConnection,
      },
    },
    'connection-line': {
      'service-application': {
        component: ApplicationServiceConnection,
      },
      'application-service': {
        component: ApplicationServiceConnection,
      },
    },
    'connection-line-hitSpot': {
      'service-application': {
        component: ApplicationServiceConnection,
      },
      'application-service': {
        component: ApplicationServiceConnection,
      },
    },
  };

  const edgeConfig = (config as any)[edgeType];

  if (edgeConfig) {
    return edgeConfig[type] as EdgeComponentConfig;
  }

  return null;
};
