import React, { useEffect, useRef, useState } from 'react';
import { CreateTearsheetStep } from '@carbon/ibm-products';
import { useTranslation } from 'react-i18next';
import './GatewayDeploymentEnvDetails.scss';
import AxonGateway from '../GatewayModels/AxonGateway/AxonGateway';
import SkupperGateway from '../GatewayModels/SkupperGateway/SkupperGateway';
import {
  DeploymentEnvironment,
  LinkedAgent,
  NetworkSegments,
} from '../../../../models/master';
import { Item } from '../DeploymentEnvTable/DeploymentEnvTable';
import { AxonConnectionMethod } from '../RegisterGateway';
import { GatewayType } from '../../GatewaysContainer';

interface Props {
  gatewayType: GatewayType | null;
  networkSegments: NetworkSegments[];
  openDeploymentEnvTable: () => void;
  resourceGroupList?: any;
  onChange?: any;
  formData?: any;
  axonConnectionMethod: AxonConnectionMethod;
  onSelectAxonConnectionMethod: (method: AxonConnectionMethod) => void;
  selectedDepEnv: Item;
  onClickEditDeployedInVpcBtn: () => void;
  linkedAgents: LinkedAgent | null;
  onSelectNetworkSegment: (networkSegment: any) => void;
  onSelectLinkedAgent: (linkedAgent: any) => void;
  selectedLinkedAgent: any;
  deploymentEnvs: DeploymentEnvironment[] | null;
  openNetworkSegmentTearsheet: () => void;
  selectedNetworkSegment: any;
  onBlur: (name: string, value: any) => void;
  notifyCreateNameSpace: boolean;
  toggleNotifyCreateNameSpace: () => void;
  disableSubmit: any;
}

const GatewayDeploymentEnvDetails: React.FC<Props> = ({
  gatewayType,
  networkSegments,
  openDeploymentEnvTable,
  resourceGroupList,
  onChange,
  formData,
  axonConnectionMethod,
  onSelectAxonConnectionMethod,
  selectedDepEnv,
  onClickEditDeployedInVpcBtn,
  linkedAgents,
  onSelectNetworkSegment,
  onSelectLinkedAgent,
  selectedLinkedAgent,
  deploymentEnvs,
  openNetworkSegmentTearsheet,
  selectedNetworkSegment,
  onBlur,
  notifyCreateNameSpace,
  toggleNotifyCreateNameSpace,
  disableSubmit,
}) => {
  const { t } = useTranslation('gatewaysContainer');

  let isDeplEnvPresent =
    deploymentEnvs === null ? false : deploymentEnvs.length > 0 ? true : false;

  return (
    <CreateTearsheetStep
      className='gateway-details'
      title={t('gatewayDetails.title')}
      disableSubmit={disableSubmit()}
      hasFieldset={false}
    >
      {(gatewayType?.resource_id === 'AXON-EDGE' ||
        gatewayType?.resource_id === 'AXON-WP') && (
        <AxonGateway
          openDeploymentEnvTable={openDeploymentEnvTable}
          resourceGroupList={resourceGroupList}
          handleOnChange={onChange}
          formData={formData}
          axonConnectionMethod={axonConnectionMethod}
          onSelectAxonConnectionMethod={onSelectAxonConnectionMethod}
          selectedDepEnv={selectedDepEnv}
          onClickEditDeployedInVpcBtn={onClickEditDeployedInVpcBtn}
          isDeplEnvPresent={isDeplEnvPresent}
          selectedGatewayType={gatewayType}
        />
      )}
      {gatewayType?.resource_id === 'RHSI-EDGE' && (
        <SkupperGateway
          openDeploymentEnvTable={openDeploymentEnvTable}
          resourceGroupList={resourceGroupList}
          handleOnChange={onChange}
          formData={formData}
          selectedDepEnv={selectedDepEnv}
          onClickEditDeployedInVpcBtn={onClickEditDeployedInVpcBtn}
          networkSegments={networkSegments}
          linkedAgents={linkedAgents}
          onSelectNetworkSegment={onSelectNetworkSegment}
          onSelectLinkedAgent={onSelectLinkedAgent}
          selectedLinkedAgent={selectedLinkedAgent}
          isDeplEnvPresent={isDeplEnvPresent}
          openNetworkSegmentTearsheet={openNetworkSegmentTearsheet}
          selectedNetworkSegment={selectedNetworkSegment}
          onBlur={onBlur}
          notifyCreateNameSpace={notifyCreateNameSpace}
          toggleNotifyCreateNameSpace={toggleNotifyCreateNameSpace}
        />
      )}
    </CreateTearsheetStep>
  );
};

export default GatewayDeploymentEnvDetails;
