import React, { ReactNode } from 'react';

const PageSubTitle: React.FC<{ children: ReactNode | string }> = ({
  children,
}) => {
  return (
    <div className='sub-heading-text' data-testid='page-sub-title'>
      {children}
    </div>
  );
};

export default PageSubTitle;
