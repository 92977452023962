import React from 'react';
import { useTranslation } from 'react-i18next';
import { ComponentProps } from '../config';
import IconButton from '../../../IconButton/IconButton';
import images from '../../../../images/images';

import './LocationTooltip.scss';

const LocationTooltip: React.FC<ComponentProps> = ({ node }) => {
  const { t } = useTranslation('topologyTooltipLocation');

  const getHeaderTitle = () => {
    if (node?.is_discovered) {
      return (
        <div className='header'>
          <span className='header-title'>{node?.name}</span>
          <span className='page-title-icon'>
            <IconButton
              icon={images.AutoDiscoverdLockIcon()}
              iconDescription={t('autoDiscoveredAccessLimited')}
            />
          </span>
        </div>
      );
    }

    if (node) {
      return <div className='header'>{node.name}</div>;
    }

    return '';
  };

  const getCloudInfo = () => {
    if (node && node?.cloud_name) {
      return (
        <div className='cloud-info'>
          <span className='page-title-icon'>
            <IconButton
              icon={images.cloudSingleIcon()}
              iconDescription={t('autoDiscoveredAccessLimited')}
            />
          </span>
          <span className='cloud-title'>{node?.cloud_name}</span>
        </div>
      );
    }
    return '';
  };

  const title = getHeaderTitle();
  const cloudDetails = getCloudInfo();

  return (
    <div className='topology-tooltip-location'>
      {title}
      <div className='body'>
        <div className='label'>
          {t('environments', {
            number: node.children?.filter(d => d._type === 'deployment_env')
              .length,
          })}
        </div>
        {cloudDetails}
        {node?.type && (
          <div className='label'>
            {t('type', {
              value: node?.type?.charAt(0).toUpperCase() + node?.type?.slice(1),
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default LocationTooltip;
