import { TFunction } from 'i18next';
import {
  ApplicationData,
  GatewayCustomData,
  PartitionData,
  PolicyData,
} from '../../models/master';
import {
  HealthStatusType,
  getHealthStatusConfig,
} from '../../components/GatewayStatus/config';
import { ResourcesList } from '../../lib/enums';
import images from '../../images/images';
import { getCombinedLabelsForFilter } from '../../lib/utils';

export type TableListType =
  | ResourcesList.GATEWAYS
  | ResourcesList.APPLICATIONS
  | ResourcesList.NAMESPACES
  | ResourcesList.POLICIES;

export const gatewaySet = [
  { value: 'AXON', name: 'Mesh' },
  { value: 'RHSI', name: 'Service Interconnect' },
];

export const getTabs = (t: TFunction) => {
  return [
    {
      label: t('resourcesList.gateways.tab_name'),
      name: ResourcesList.GATEWAYS,
      valueColumn: 'name',
    },
    {
      label: t('resourcesList.applications.tab_name'),
      name: ResourcesList.APPLICATIONS,
      valueColumn: 'name',
    },
    {
      label: t('resourcesList.namespaces.tab_name'),
      name: ResourcesList.NAMESPACES,
      valueColumn: 'name',
    },
    {
      label: t('resourcesList.policies.tab_name'),
      name: ResourcesList.POLICIES,
      valueColumn: 'name',
    },
  ];
};

export const getResourcesTableFilters = <T>(
  type: TableListType,
  t: TFunction,
  data: T[]
) => {
  const filters = {
    gateways: [
      {
        key: 'health_status',
        label: t('resourcesList.filters.gateways.health'),
        placeHolderText: t(
          'resourcesList.filters.gateways.healthPlaceHolderText'
        ),
        type: 'single',
        translationReqd: true,
        translationData: {
          operational: t('resourcesList.filters.gateways.operational'),
          critical: t('resourcesList.filters.gateways.critical'),
          major: t('resourcesList.filters.gateways.major'),
          minor: t('resourcesList.filters.gateways.minor'),
          pending: t('resourcesList.filters.gateways.pending'),
          deploying: t('resourcesList.filters.gateways.deploying'),
          provisioning: t('resourcesList.filters.gateways.provisioning'),
          terminating: t('resourcesList.filters.gateways.terminating'),
          redeploying: t('resourcesList.filters.gateways.redeploying'),
          notdeployed: t('resourcesList.filters.gateways.notdeployed'),
        },
        hasIcon: true,
        values: [
          ...Array.from(
            new Set(
              data
                ?.filter(
                  gateway => (gateway as GatewayCustomData).health_status
                )
                .map(gateway => (gateway as GatewayCustomData).health_status)
            )
          ).map(status => {
            const healthConfig = getHealthStatusConfig(
              t,
              status as HealthStatusType
            );
            return {
              icon: healthConfig?.icon,
              label: status,
            };
          }),
        ],
      },
      {
        key: 'location_name',
        label: t('resourcesList.filters.gateways.location'),
        placeHolderText: t(
          'resourcesList.filters.gateways.locationPlaceHolderText'
        ),
        type: 'single',
        values: [
          ...Array.from(
            new Set(
              data
                ?.filter(
                  gateway => (gateway as GatewayCustomData).location_name
                )
                .map(gateway => (gateway as GatewayCustomData).location_name)
            )
          ),
        ],
      },
    ],
    applications: [
      {
        key: 'applicationGroup',
        type: 'single',
        label: t('resourcesList.filters.applications.applicationGroup'),
        placeHolderText: t(
          'resourcesList.filters.applications.applicationGroupPlaceholder'
        ),
        values: [
          ...Array.from(
            new Set(
              (data
                ? data.filter(
                    app => (app as ApplicationData).resource_group_name
                  )
                : []
              ).map(app => (app as ApplicationData).resource_group_name)
            )
          ),
        ],
      },
      {
        key: 'labels',
        type: 'multi',
        label: t('resourcesList.filters.applications.labels'),
        placeHolderText: t(
          'resourcesList.filters.applications.labelsPlaceholder'
        ),
        values: [
          ...Array.from(
            new Set(
              data
                .map(application =>
                  getCombinedLabelsForFilter(
                    (application as ApplicationData).labels,
                    (application as ApplicationData).discovered_labels
                  )
                )
                .flat()
            )
          ),
        ],
      },
    ],
    namespaces: [
      {
        key: 'name',
        label: t('resourcesList.filters.namespaces.name'),
        type: 'multi',
        placeHolderText: t('resourcesList.filters.namespaces.namePlaceholder'),
        values: [
          ...Array.from(
            new Set(
              data.map(namespace => (namespace as PartitionData).name).flat()
            )
          ),
        ],
      },
      {
        key: 'auto_discover',
        label: t('resourcesList.filters.namespaces.autoDiscovery'),
        type: 'multi',
        placeHolderText: t(
          'resourcesList.filters.namespaces.autoDiscoveryPlaceholder'
        ),
        values: [
          ...Array.from(
            new Set(
              data
                .map(namespace => (namespace as PartitionData).auto_discover)
                .flat()
            )
          ),
        ],
      },
    ],
    policies: [
      {
        key: 'resource_group_name',
        type: 'single',
        label: t('resourcesList.filters.policies.applicationGroup'),
        placeHolderText: t(
          'resourcesList.filters.policies.applicationGroupPlaceholder'
        ),
        values: [
          ...Array.from(
            new Set(
              data
                ?.filter(
                  policy =>
                    (policy as PolicyData).resource_group_name !== undefined
                )
                ?.map(policy => (policy as PolicyData).resource_group_name)
            )
          ),
        ],
      },

      {
        key: 'to',
        type: 'single',
        label: t('resourcesList.filters.policies.to'),
        placeHolderText: t('resourcesList.filters.policies.toPlaceholder'),
        values: [
          ...Array.from(
            new Set(
              data
                ?.filter(policy => (policy as PolicyData).to_svc_name)
                ?.map(policy => (policy as PolicyData).to_svc_name)
            )
          ),
        ],
      },
      {
        key: 'labels',
        type: 'multi',
        label: t('resourcesList.filters.policies.labels'),
        placeHolderText: t('resourcesList.filters.policies.labelsPlaceholder'),
        values: [
          ...Array.from(
            new Set(data.map(policy => (policy as PolicyData).labels).flat())
          ),
        ],
      },
    ],
  };

  return filters[type];
};

export const getResourcesTableHeaders = (type: TableListType, t: TFunction) => {
  const headerConfig = {
    gateways: [
      {
        key: 'name__format',
        originalKey: 'name',
        header: t('resourcesList.gateways.name'),
        sort: true,
      },
      {
        key: 'type',
        originalKey: 'type',
        header: t('resourcesList.gateways.type'),
        sort: true,
        style: { minWidth: '9rem' },
      },
      {
        key: 'health_status_format',
        originalKey: 'health_status',
        header: t('resourcesList.gateways.health_status'),
        sort: true,
      },
      {
        key: 'cloud',
        originalKey: 'cloud',
        header: t('resourcesList.gateways.cloud'),
        sort: true,
      },
      {
        key: 'location',
        originalKey: 'location_name',
        header: t('resourcesList.gateways.location'),
        sort: true,
      },
      {
        key: 'version',
        originalKey: 'version',
        header: t('resourcesList.gateways.procedural_status'),
        sort: true,
      },
      {
        key: 'metric_collection',
        originalKey: 'metric_collection',
        header: t('resourcesList.gateways.metric_collection'),
        sort: true,
      },
      {
        key: 'updated_at',
        originalKey: 'updated_at',
        header: t('resourcesList.gateways.updated'),
        sort: true,
      },
    ],
    applications: [
      {
        key: 'name__format',
        originalKey: 'name',
        header: t('resourcesList.applications.name'),
        sort: 'sortByName',
      },
      {
        key: 'resource_group_name',
        originalKey: 'resource_group_name',
        header: t('resourcesList.applications.resource_groups'),
        sort: true,
      },
      {
        key: 'deployments',
        originalKey: 'deployments',
        header: t('resourcesList.applications.deployments'),
        sort: true,
      },
      {
        key: 'allLabels',
        originalKey: 'allLabels',
        header: t('resourcesList.applications.labels'),
      },
      {
        key: 'updated_at',
        originalKey: 'updated_at',
        header: t('resourcesList.applications.updated'),
        sort: true,
      },
    ],
    namespaces: [
      {
        key: 'name__format',
        originalKey: 'name',
        header: t('resourcesList.namespaces.name'),
        sort: true,
      },
      {
        key: 'deployment_env_name',
        originalKey: 'deployment_env_name',
        header: t('resourcesList.namespaces.deployment_environment'),
        sort: true,
      },
      {
        key: 'infrastructure_group',
        originalKey: 'infra_group',
        header: t('resourcesList.namespaces.infra_group'),
        sort: true,
      },
      {
        key: 'cloud_name',
        originalKey: 'cloud_name',
        header: t('resourcesList.namespaces.cloud'),
        sort: true,
      },
      {
        key: 'location_name',
        originalKey: 'location_name',
        header: t('resourcesList.namespaces.location'),
        sort: true,
      },
      {
        key: 'auto_discover',
        originalKey: 'auto_discover',
        header: t('resourcesList.namespaces.autodiscover'),
        sort: true,
      },
      {
        key: 'allLabels',
        originalKey: 'allLabels',
        header: t('resourcesList.namespaces.labels'),
      },
      {
        key: 'updated_at',
        originalKey: 'updated_at',
        header: t('resourcesList.namespaces.updated'),
        sort: true,
      },
    ],
    policies: [
      {
        key: 'name__format',
        originalKey: 'name',
        header: t('resourcesList.policies.name'),
        sort: true,
      },
      {
        key: 'resource_group_name',
        originalKey: 'resource_group_name',
        header: t('resourcesList.policies.resource_groups'),
        sort: true,
      },
      {
        key: 'from',
        originalKey: 'from',
        header: t('resourcesList.policies.from'),
        sort: true,
      },
      {
        key: 'to_svc_name',
        originalKey: 'to_svc_name',
        header: t('resourcesList.policies.to'),
        sort: true,
      },
      {
        key: 'labels',
        originalKey: 'labels',
        header: t('resourcesList.policies.labels'),
      },
      {
        key: 'updated_at',
        originalKey: 'updated_at',
        header: t('resourcesList.policies.updated'),
        sort: true,
      },
    ],
  };

  return headerConfig[type];
};

export const getResourcesEmptyStateValue = (
  type: TableListType,
  t: TFunction,
  navigate: TFunction
) => {
  const emptyStateConfig = {
    gateways: {
      icon: images.noGatewayLargeIcon(),
      notFoundIcon: images.NotFoundSmall(),
      emptyStateHeader: t(
        'resourcesList.emptyState.gateways.emptyContainerHeader'
      ),
      emptyStateDescription: t(
        'resourcesList.emptyState.gateways.emptyContainerDescription'
      ),
      link: '/',
      buttonText: t('resourcesList.emptyState.gateways.buttonText'),
    },
    applications: {
      icon: images.noApplicationsIcon(),
      notFoundIcon: images.NotFoundSmall(),
      emptyStateHeader: t(
        'resourcesList.emptyState.applications.emptyContainerHeader'
      ),
      emptyStateDescription: t(
        'resourcesList.emptyState.applications.emptyContainerDescription'
      ),
      link: '/',
      buttonText: t('resourcesList.emptyState.applications.buttonText'),
      click: () =>
        navigate('/applications', { state: { resourceType: 'APPLICATION' } }),
    },
    namespaces: {
      icon: images.noServicesLargeIcon(),
      notFoundIcon: images.NotFoundSmall(),
      emptyStateHeader: t(
        'resourcesList.emptyState.namespaces.emptyContainerHeader'
      ),
      emptyStateDescription: t(
        'resourcesList.emptyState.namespaces.emptyContainerDescription'
      ),
      link: false,
      buttonText: t('resourcesList.emptyState.namespaces.buttonText'),
      click: () => {},
    },
    policies: {
      icon: images.noPartitionsIcon(),
      notFoundIcon: images.NotFoundSmall(),
      emptyStateHeader: t(
        'resourcesList.emptyState.policies.emptyContainerHeader'
      ),
      emptyStateDescription: t(
        'resourcesList.emptyState.policies.emptyContainerDescription'
      ),
      link: '/',
      buttonText: t('resourcesList.emptyState.policies.buttonText'),
      click: () => {},
    },
  };

  return emptyStateConfig[type];
};
