import { Grid32, List32 } from '@carbon/icons-react';
import { FlexGrid } from '@carbon/react';
import {
  Button,
  Column,
  ContentSwitcher,
  OnChangeData,
  Row,
  SkeletonPlaceholder,
} from 'carbon-components-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FindAndFilterBar from '../../components/FindAndFilterBar/FindAndFilterBar';
import Header from '../../components/Header/Header';
import SortDropDown from '../../components/SortDropdown/SortDropDown';
import sortData from '../../lib/tableSort';
import { manageFiltersFromStorage } from '../../lib/utils';
import GatewaysCard from './GatewaysCard/GatewaysCard';
import GatewaysTable from './GatewaysTable/GatewaysTable';
import Error500Card from '../../components/ErrorState/Error500Card';

import './GatewaysContainer.scss';
import analyticsData from '../../lib/analyticsEventData';

import {
  AppliedFilter,
  Cloud,
  Error500Type,
  Gateway,
  GatewayCustomData,
  Location,
  NetworkSegment,
  ResourceGroup,
} from '../../models/master';

import { AxiosError } from 'axios';
import { CardEmptyState } from '../../components/CardEmptyState/CardEmptyState';
import Error403Card from '../../components/ErrorState/Error403Card';
import { getGateways } from '../../controllers/gateawayApis.js';
import { getResourceGroups } from '../../controllers/resourceGroupApi';
import images from '../../images/images';
import { getLocationDetails } from '../../controllers/locationApis';
import { getNetworkSegments } from '../../controllers/networksegmentsApi';
import { getPartition } from '../../controllers/deploymentEnv';
import {
  HealthStatusType,
  getHealthStatusConfig,
} from '../../components/GatewayStatus/config';
import { GatewaySubTypes, VisibilityFlags } from '../../lib/enums';
import RegisterGateway from './RegisterGateway/RegisterGateway';
import { getGatewayCompatibilitySet } from '../../controllers/gateawayApis';
import { getClouds } from '../../controllers/cloudApis';
import useAnalytics from '../../lib/useAnalytics';
import {
  DEFAULT_NETWORK_SEGMENT_ID,
  defaultNetworkSegmentObject,
} from '../../lib/constants';

type View = 'table' | 'card';

const defaultPermissionMap = {
  gateways: true,
  resourceGroup: true,
  location: true,
  networkSegment: true,
};
export interface GatewayType {
  resource_id: string;
  name: string;
}

const GatewaysContainer = () => {
  const { t } = useTranslation('gatewaysContainer');

  let viewStateValue = localStorage.getItem('VIEW_STATE') as View;
  const [view, setView] = useState<View>(
    viewStateValue ? viewStateValue : 'table'
  );

  const [currentPageNumber, setPageNumber] = useState(1);
  const [currentPageSize, setPageSize] = useState(25);
  const [gatewaysCustomData, setGatewaysCustomData] = useState<
    GatewayCustomData[] | null
  >(null);
  const [gateWaysData, setGateWaysData] = useState<GatewayCustomData[] | null>(
    null
  );
  const [resourceDataLoading, toggleResourceDataLoading] = useState(false);
  const [filteredData, setFilteredData] = useState<GatewayCustomData[] | []>(
    []
  );
  const [filterApplied, setFilterApplied] = useState<AppliedFilter[] | []>([]);
  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | 'NONE'>(
    'NONE'
  );
  const [visibilityFlag, setVisibilityFlag] = useState('All');
  const [createGatewayType, setCreateGatewayType] = useState('');
  const [showRegisterGateway, setShowRegisterGateway] = useState(false);
  const [resourceGroupsData, setResourceGroupsData] = useState<
    ResourceGroup[] | null
  >(null);
  const [existingGatewayNames, setExistingGatewayNames] = useState<string[]>(
    []
  );
  const [permissionMap, setPermissionMap] = useState(defaultPermissionMap);
  const [error500, setError500] = useState<null | Error500Type>(null);

  const { pageViewed, trackButtonClicked } = useAnalytics();
  const [networkSegments, setNetworkSegments] = useState<
    NetworkSegment[] | null
  >([]);
  const [gatewayTypes, setGatewayTypes] = useState<GatewayType[] | null>(null);
  const [clouds, setClouds] = useState<Cloud[]>([]);

  const removeDuplicateNetworkSegments = () => {
    let networkSegmentElements = new Set(
      gatewaysCustomData
        ?.filter(
          gateway =>
            gateway?.network_segment_name &&
            gateway?.network_segment_name !== undefined
        )
        .map((item: any) => item.network_segment_name)
    );
    let segmentElements = Array.from(networkSegmentElements);
    let elementIndex = segmentElements.indexOf('—');
    if (elementIndex > -1) {
      segmentElements.splice(elementIndex, 1);
    }
    return segmentElements;
  };

  const leftInlineMultiSelectFilter: any = [
    {
      key: 'gatewayNetworkSegment',
      type: 'multi',
      values: removeDuplicateNetworkSegments().map(item => {
        return {
          label: item,
          value: item,
        };
      }) as any,
      placeHolderText: removeDuplicateNetworkSegments().length,
    },
  ];

  const applyVisibilityFlag = (flag: string) => {
    setCustomGatewayData(flag, gateWaysData);
  };

  const setCustomGatewayData = (
    flag: string,
    gateWaysList: GatewayCustomData[] | null
  ) => {
    let filteredGateways: GatewayCustomData[] | undefined;
    switch (flag.toLowerCase()) {
      case 'all':
        setGatewaysCustomData(gateWaysList);
        break;
      case 'waypoints':
        filteredGateways = gateWaysList?.filter(
          gateway => gateway.type !== 'edge'
        );
        filteredGateways && filteredGateways?.length > 0
          ? setGatewaysCustomData(filteredGateways)
          : setGatewaysCustomData([]);
        break;
      case 'gateways':
        filteredGateways = gateWaysList?.filter(
          gateway => gateway.type === 'edge'
        );
        filteredGateways && filteredGateways?.length > 0
          ? setGatewaysCustomData(filteredGateways)
          : setGatewaysCustomData([]);
        break;
      default:
        break;
    }
  };

  const onGatewayCreate = () => {
    setShowRegisterGateway(false);
    refreshData();
  };

  const refreshData = async () => {
    try {
      setGateWaysData(null);
      setGatewaysCustomData(null);
      setSortKey('');
      setSortDirection('NONE');
      toggleResourceDataLoading(true);
      setFilterApplied(manageFiltersFromStorage());
      let resourceGroups: any = [];
      let currentPermissionMap = permissionMap;
      try {
        resourceGroups = await getResourceGroups(true);
        setResourceGroupsData(
          resourceGroups.resource_groups as ResourceGroup[]
        );
        if (!currentPermissionMap.resourceGroup) {
          currentPermissionMap = {
            ...currentPermissionMap,
            resourceGroup: true,
          };
          setPermissionMap(currentPermissionMap);
        }
      } catch (error) {
        const err = error as AxiosError;
        if (
          err?.response?.status === 403 &&
          err?.response?.statusText === 'Forbidden'
        ) {
          currentPermissionMap = {
            ...currentPermissionMap,
            resourceGroup: false,
          };
          setPermissionMap(currentPermissionMap);
        }
      }
      let networkSegmentsList: NetworkSegment[];
      try {
        networkSegmentsList = await getNetworkSegments();
        setNetworkSegments(networkSegments);
        if (!currentPermissionMap.networkSegment) {
          currentPermissionMap = {
            ...currentPermissionMap,
            networkSegment: true,
          };
          setPermissionMap(currentPermissionMap);
        }
      } catch (error) {
        console.error(error);
        const err = error as AxiosError;
        if (err?.response?.status === 403) {
          currentPermissionMap = {
            ...currentPermissionMap,
            networkSegment: false,
          };
          setPermissionMap(currentPermissionMap);
        }
      }
      let gateWaysData: GatewayCustomData[] = [];

      const gateways = await getGateways();

      if (error500) {
        setError500(null);
      }

      await Promise.allSettled(
        gateways.map(async (gateway: Gateway) => {
          let resourceName = getResourceGroupName(
            gateway.resource_group_id,
            resourceGroups.resource_groups
          );
          let networkSegment: NetworkSegment | undefined;
          try {
            networkSegment = await getNetworkSegment(
              gateway,
              networkSegmentsList
            );
          } catch (error) {
            console.log(error);
          }
          let location: Location | undefined;
          if (!currentPermissionMap.location) {
            currentPermissionMap = {
              ...currentPermissionMap,
              location: true,
            };
            setPermissionMap(currentPermissionMap);
          }
          if (gateway.cloud_id && gateway.location_id) {
            try {
              location = await getLocationDetails(
                gateway.cloud_id,
                gateway.location_id,
                VisibilityFlags.ALL,
                true
              );
            } catch (error) {
              const err = error as AxiosError;
              if (err?.response?.status === 403) {
                currentPermissionMap = {
                  ...currentPermissionMap,
                  location: false,
                };

                setPermissionMap(currentPermissionMap);
              }
            }
            gateWaysData.push({
              ...gateway,
              resourceGroupsPermission: currentPermissionMap['resourceGroup'],
              locationPermission: currentPermissionMap['location'],
              networkSegmentPermission: currentPermissionMap['networkSegment'],
              resource_name: resourceName,
              location_name: location?.name || '',
              network_segment_name: networkSegment?.name ?? '',
              network_segment_id: networkSegment?.resource_id ?? '',
              type_name: gateway.subtype
                ? t(`gatewayTypes.${gateway.subtype}`)
                : '',
            });
          } else {
            gateWaysData.push({
              ...gateway,
              resourceGroupsPermission: currentPermissionMap['resourceGroup'],
              locationPermission: currentPermissionMap['location'],
              networkSegmentPermission: currentPermissionMap['networkSegment'],
              resource_name: resourceName,
              location_name: '',
              network_segment_name: networkSegment?.name ?? '',
              network_segment_id: networkSegment?.resource_id ?? '',
              type_name: gateway.subtype
                ? t(`gatewayTypes.${gateway.subtype}`)
                : '',
            });
          }
        })
      );
      setCustomGatewayData(visibilityFlag, gateWaysData);
      setGateWaysData(gateWaysData);
      if (!permissionMap.gateways) {
        setPermissionMap(permissionMap => ({
          ...permissionMap,
          gateways: true,
        }));
      }
      // setCloudFilters(getCloudFilters(gateWaysData));
      const existingGatewayNamesList = gateWaysData.map(
        (gateway: Gateway) => gateway.name
      );
      setExistingGatewayNames(existingGatewayNamesList);
      fetchGwCompatibilitySet();
      try {
        let clouds = await getClouds();
        Array.isArray(clouds) && setClouds(clouds);
      } catch (err) {
        console.log(err);
        setClouds([]);
      }
    } catch (error) {
      setGateWaysData([]);
      setGatewaysCustomData([]);
      toggleResourceDataLoading(false);
      console.error(error);
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        setPermissionMap(permissionMap => ({
          ...permissionMap,
          gateways: false,
        }));
      }

      if (err.response!?.status >= 500) {
        setError500(err.response!?.status.toString() as Error500Type);
      }
    }
  };

  const handleSort = (data: { id: string; text: string }) => {
    if (gatewaysCustomData) {
      if (data.id === 'atoz') {
        setSortDirection('ASC');
        const sortedGatewaysData = Array.isArray(gatewaysCustomData)
          ? gatewaysCustomData.sort((a, b) =>
              a.name?.trim()?.toLowerCase() > b.name?.trim()?.toLowerCase()
                ? 1
                : -1
            )
          : [];
        setGatewaysCustomData([...sortedGatewaysData]);
      } else {
        setSortDirection('DESC');
        const sortedGatewaysData = Array.isArray(gatewaysCustomData)
          ? gatewaysCustomData.sort((a, b) =>
              a.name?.trim()?.toLowerCase() < b.name?.trim()?.toLowerCase()
                ? 1
                : -1
            )
          : [];
        setGatewaysCustomData([...sortedGatewaysData]);
      }
    }
  };

  const renderFilter = () => {
    return (
      <FindAndFilterBar
        data={gatewaysCustomData}
        filteredDataCallback={data =>
          setFilteredData(data as GatewayCustomData[] | [])
        }
        filteredData={
          gatewaysCustomData
            ? filterApplied?.length > 0
              ? filteredData
              : gatewaysCustomData
            : null
        }
        filtersApplied={filterApplied as any}
        filtersAppliedCallback={data => setFilterApplied(data)}
        persistFilter
        filters={getCloudFilters(gatewaysCustomData) as any}
        onRefresh={() => refreshData()}
        leftInlineMultiSelectFilter={leftInlineMultiSelectFilter}
        visibilityFlag={visibilityFlag}
      />
    );
  };
  const setPageChange = (pageData: { page: number; pageSize: number }) => {
    setPageNumber(pageData.page);
    setPageSize(pageData.pageSize);
  };

  useEffect(() => {
    pageViewed('Gateways');
    toggleResourceDataLoading(true);
    refreshData();
    fetchGwCompatibilitySet();
  }, []);

  useEffect(() => {
    gatewaysCustomData != null
      ? toggleResourceDataLoading(false)
      : toggleResourceDataLoading(true);
  }, [gatewaysCustomData]);

  const handleTableSort = (
    data: { id: string; text: string },
    sortDirection: 'ASC' | 'DESC' | 'NONE'
  ) => {
    setSortDirection(sortDirection);
    setSortKey(data.id);
  };

  const refreshGateways = (isRefresh?: boolean) => {
    toggleResourceDataLoading(true);
    refreshData();
  };

  const fetchGwCompatibilitySet = async () => {
    try {
      const gatewayCompatibilitySet = await getGatewayCompatibilitySet();
      const filteredGatewayCompatibilitySet = Array.isArray(
        gatewayCompatibilitySet?.subtypes
      )
        ? gatewayCompatibilitySet?.subtypes.filter(
            (subtype: GatewayType) =>
              subtype.resource_id === GatewaySubTypes.RHSI_EDGE
          )
        : [];

      setGatewayTypes(filteredGatewayCompatibilitySet ?? []);
    } catch (error) {
      console.log(error);
    }
  };

  const getCloudFilters = (gatewaysData: GatewayCustomData[] | null) => [
    {
      key: 'type_name',
      label: t('filters.type'),
      placeHolderText: t('filters.placeHolderText'),
      type: 'multi',
      values: [
        ...Array.from(
          new Set(
            gatewaysData
              ?.filter(
                gateway =>
                  gateway?.type_name && gateway?.type_name !== undefined
              )
              .map(gateway => gateway?.type_name)
          )
        ),
      ],
    },
    {
      key: 'health_status',
      label: t('filters.health'),
      placeHolderText: t('filters.placeHolderText'),
      type: 'single',
      translationReqd: true,
      translationData: {
        operational: t('operational'),
        critical: t('critical'),
        minor: t('minor'),
        notdeployed: t('notdeployed'),
        pending: t('pending'),
        errored: t('errored'),
        unreachable: t('unreachable'),
      },
      hasIcon: true,
      values: [
        ...Array.from(
          new Set(
            gatewaysData
              ?.filter(
                gateway =>
                  gateway?.health_status && gateway?.health_status !== undefined
              )
              .map(gateway => gateway.health_status)
          )
        ).map(status => {
          const healthConfig = getHealthStatusConfig(
            t,
            status as HealthStatusType
          );
          return {
            icon: healthConfig.icon,
            label: status,
          };
        }),
      ],
    },
    {
      key: 'location_name',
      label: t('filters.location'),
      placeHolderText: t('filters.placeHolderText'),
      type: 'single',
      values: [
        ...Array.from(
          new Set(
            gatewaysData
              ?.filter(
                gateway =>
                  gateway?.location_name && gateway?.location_name !== undefined
              )
              .map(gateway => gateway?.location_name)
          )
        ),
      ],
    },
    {
      key: 'intended_version',
      label: t('filters.version'),
      placeHolderText: t('filters.placeHolderText'),
      type: 'single',
      values: [
        ...Array.from(
          new Set(
            gatewaysData
              ?.filter(
                gateway =>
                  gateway?.intended_version &&
                  gateway?.intended_version !== undefined
              )
              .map(gateway => gateway?.intended_version)
          )
        ),
      ],
    },
  ];

  const getResourceGroupName = (
    id: string,
    resouceGroups: ResourceGroup[] | null
  ) => {
    const resouceGroup =
      id &&
      Array.isArray(resouceGroups) &&
      resouceGroups.find(resouceGroup => resouceGroup.resource_id === id);
    return resouceGroup ? resouceGroup.name : '';
  };

  const getNetworkSegment = async (
    gateway: Gateway,
    networkSegments: NetworkSegment[]
  ) => {
    let networkSegmentId = DEFAULT_NETWORK_SEGMENT_ID;
    if (gateway.subtype && gateway.subtype === 'RHSI-EDGE') {
      networkSegmentId = '';
      if (
        gateway?.deployed_in_depl_env_id &&
        gateway?.deployed_in_partition_id
      ) {
        const partition = await getPartition(
          gateway.deployed_in_depl_env_id,
          gateway.deployed_in_partition_id
        );
        networkSegmentId = partition?.network_segment_id ?? '';
      }
    }
    if (networkSegmentId === DEFAULT_NETWORK_SEGMENT_ID) {
      return defaultNetworkSegmentObject;
    }
    return networkSegments.find(
      nwSegment => nwSegment?.resource_id === networkSegmentId
    );
  };

  const learnMore = {
    url: 'https://www.ibm.com/docs/en/hybrid-cloud-mesh?topic=gateways-understanding',
    label: t('learnMore'),
  };

  return (
    <div className='gateway-container'>
      <Header
        title={t('header')}
        subTitle={t('subheader')}
        learnMoreLink={learnMore}
        breadcrumbs={[
          {
            url: '/',
            label: t('home'),
          },
        ]}
        actions={[
          {
            kind: 'primary',
            onClick: () => {
              trackButtonClicked(
                analyticsData['Gateways'].events.createGatewayBtn.props,
                analyticsData['Gateways'].events.createGatewayBtn.event
              );
              setShowRegisterGateway(true);
            },
            text: t('addGateway'),
            disabled: !permissionMap['gateways'],
          },
        ]}
      />
      <div className='page-content'>
        <div className='gateway-switcher'>
          {view !== 'table' ? (
            <>
              {resourceDataLoading ? (
                <div className='skeleton-sort-drop-down'>
                  <SkeletonPlaceholder className={'sorting-skeleton'} />
                </div>
              ) : (
                <SortDropDown
                  id='gateway-sort-dropdown'
                  size='lg'
                  onSort={handleSort}
                  sortDir={sortDirection}
                />
              )}
            </>
          ) : null}
          <ContentSwitcher className='view-switcher'>
            <Button
              className={
                'switch-button' + (view === 'table' ? ' selected' : '')
              }
              onClick={() => {
                setView('table');
                localStorage.setItem('VIEW_STATE', 'table');
              }}
              renderIcon={List32}
              hasIconOnly
              tooltipPosition='bottom'
              iconDescription={t('table')}
            />
            <Button
              className={'switch-button' + (view === 'card' ? ' selected' : '')}
              onClick={() => {
                setView('card');
                localStorage.setItem('VIEW_STATE', 'card');
              }}
              renderIcon={Grid32}
              hasIconOnly
              tooltipPosition='bottom'
              iconDescription={t('card')}
            />
          </ContentSwitcher>
        </div>
        <div className='body'>
          {view === 'table' ? (
            <GatewaysTable
              currentPageNumber={currentPageNumber}
              currentPageSize={currentPageSize}
              onPageChange={pageData => setPageChange(pageData)}
              sortRows={(
                data: { id: string; text: string },
                direction: 'ASC' | 'DESC' | 'NONE'
              ) => handleTableSort(data, direction)}
              rows={
                Array.isArray(gatewaysCustomData)
                  ? filterApplied?.length > 0
                    ? sortData(filteredData, sortKey, sortDirection)
                    : sortData(gatewaysCustomData, sortKey, sortDirection)
                  : null
              }
              elementCount={
                gatewaysCustomData
                  ? filterApplied?.length > 0
                    ? filteredData?.length
                    : gatewaysCustomData?.length
                  : 0
              }
              filteredDataSet={
                gatewaysCustomData
                  ? filterApplied?.length > 0
                    ? filteredData
                    : gatewaysCustomData
                  : null
              }
              filteredDataCallback={data => {
                data && setFilteredData(data as GatewayCustomData[] | []);
                setPageNumber(1);
              }}
              data={gatewaysCustomData}
              filtersSelected={filterApplied as any}
              filtersAppliedCallback={data => setFilterApplied(data)}
              // noDataCallBack={() => openTearsheet()}
              persistFilter
              filters={getCloudFilters(gatewaysCustomData)}
              onRefresh={() => refreshGateways(true)}
              leftInlineMultiSelectFilter={leftInlineMultiSelectFilter}
              visibilityFlag={visibilityFlag}
              resourceGroupsList={resourceGroupsData}
              emptyStateClick={async (item: any) => {
                await setCreateGatewayType(item.id);
                setShowRegisterGateway(true);
              }}
              error403Flag={!permissionMap['gateways']}
              error500Flag={!!error500}
            />
          ) : !permissionMap['gateways'] ? (
            <Error403Card />
          ) : error500 ? (
            <Error500Card />
          ) : resourceDataLoading ? (
            <div>
              {!gatewaysCustomData && renderFilter()}

              <Row className='skeleton-card-view'>
                <Column>
                  <SkeletonPlaceholder className='resouce-group-skeleton' />
                </Column>
                <Column>
                  <SkeletonPlaceholder className='resouce-group-skeleton' />
                </Column>
                <Column>
                  <SkeletonPlaceholder className='resouce-group-skeleton' />
                </Column>
                <Column>
                  <SkeletonPlaceholder className='resouce-group-skeleton' />
                </Column>
              </Row>
            </div>
          ) : (
            <div>
              {gatewaysCustomData && gatewaysCustomData?.length >= 0
                ? renderFilter()
                : null}
              <div className='card-view'>
                <FlexGrid>
                  <Row className='resource-card-alignment'>
                    {gatewaysCustomData ? (
                      (filterApplied?.length > 0
                        ? filteredData
                        : gatewaysCustomData
                      )?.length > 0 ? (
                        (filterApplied?.length > 0
                          ? filteredData
                          : gatewaysCustomData
                        ).map(gateway => (
                          <Column
                            lg={4}
                            md={4}
                            className='gateway-resource-card'
                          >
                            <GatewaysCard
                              name={gateway.name}
                              id={gateway.resource_id}
                              health_status={gateway.health_status}
                              status={gateway.procedural_status}
                              type={gateway.type}
                              subType={gateway.subtype}
                              location={gateway?.location_name}
                              cloud={gateway?.cloud_name}
                              resourceGroup={
                                getResourceGroupName(
                                  gateway.resource_group_id,
                                  resourceGroupsData
                                ).length > 0
                                  ? getResourceGroupName(
                                      gateway.resource_group_id,
                                      resourceGroupsData
                                    )
                                  : '—'
                              }
                              cloud_id={gateway.cloud_id}
                              location_id={gateway.location_id}
                              publicIp={gateway.public_ip}
                              path={`/gatewayDetails?gatewayId=${gateway.resource_id}`}
                              updated_at={gateway.updated_at}
                              locationPermission={gateway.locationPermission}
                              networkSegmentPermission={
                                gateway.networkSegmentPermission
                              }
                              network_segment_name={
                                gateway.network_segment_name
                              }
                              network_segment_id={gateway.network_segment_id}
                              version={gateway.intended_version}
                            />
                          </Column>
                        ))
                      ) : (
                        <CardEmptyState
                          filterApplied={filterApplied}
                          emptyState={{
                            icon: images.noGatewayLargeIcon(),
                            header: t('emptyState.emptyContainerHeader'),
                            description: t(
                              'emptyState.emptyContainerDescription'
                            ),
                          }}
                        />
                      )
                    ) : (
                      <div className='no-resource-group'></div>
                    )}
                  </Row>
                </FlexGrid>
              </div>
            </div>
          )}
        </div>
      </div>
      {showRegisterGateway && (
        <RegisterGateway
          open={showRegisterGateway}
          onClose={() => setShowRegisterGateway(false)}
          onGatewayCreate={() => onGatewayCreate()}
          gatewayList={gateWaysData}
          gatewayType={createGatewayType}
          gatewayNamesList={existingGatewayNames}
          gatewayTypes={gatewayTypes}
          clouds={clouds}
        />
      )}
    </div>
  );
};

export default GatewaysContainer;
