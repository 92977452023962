import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TextInput,
  RadioButton,
  RadioButtonGroup,
  Dropdown,
} from '@carbon/react';
import useAnalytics from '../../lib/useAnalytics';

import './ConnectGateway.scss';
import { Column, Row, TextArea, TooltipIcon } from 'carbon-components-react';
import {
  Gateway,
  GatewayIngressTypes,
  LinkedAgent,
  ResourceGroup,
  SkupperComputeProfile,
} from '../../models/master';
import { Tooltip } from '@carbon/react';
import { Help16 } from '@carbon/icons-react';
import AddLabels from '../../components/AddLabels/AddLabels';
import { domainNameValidation, ingressAnnotationRegex } from '../../lib/regex';
import { IngressTypes } from '../../lib/enums';

const ConnectGateway = (props: {
  createGatewayLoader: boolean;
  tearsheetId: string;
  resourceGroups: ResourceGroup[] | null;
  initalFormData: any;
  handleChange: any;
  namespaceList: any;
  mode: 'ADD' | 'EDIT';
  partitionData?: any;
  agentList: LinkedAgent | null;
  gatewayList: Gateway[] | [];
  gatewayNames: string[];
  agentError: any;
  gwRhsiComputeProfiles: SkupperComputeProfile[];
  gwIngressTypes: GatewayIngressTypes[];
  formData: any;
  gatewayNameError: any;
  onChange: any;
}) => {
  const { t } = useTranslation('registerNamespace');
  const {
    tearsheetId,
    resourceGroups,
    handleChange,
    initalFormData,
    mode,
    partitionData,
    agentList,
    gatewayList,
    gatewayNames,
    agentError,
    gwRhsiComputeProfiles,
    gwIngressTypes,
    formData,
    gatewayNameError,
    onChange,
  } = props;

  const [enableIngress, setEnableIngress] = useState<boolean>(false);
  const [showConfigurableSettings, setShowConfigurableSettings] =
    useState<boolean>(false);
  const [showSettings, setShowSettings] = useState<boolean>(false);

  const { trackButtonClicked } = useAnalytics();

  return (
    <div className='connect-gateway-details'>
      <Row>
        <Column className='modal-header'>{t('connectGateway.header')}</Column>
      </Row>
      <div className='selectionSection'>
        <RadioButtonGroup
          legendText={t('connectGateway.installationMethodName')}
          name='install_method'
          onChange={(e: any) => {
            trackButtonClicked({
              RegisterNamespace: 'Connect a gateway to namespace',
              action:
                'Connect a gateway button clicked from register namespace modal',
            });
            onChange('install_method', e);
            showConfigurableSettings &&
              setShowConfigurableSettings(!showConfigurableSettings);
          }}
          valueSelected={formData?.install_method?.toString()}
        >
          <RadioButton
            labelText={
              !agentList?.available?.length ? (
                <span className='connect-gateway-radio-section'>
                  {t('connectGateway.radioButtonFromAgentText')}
                  <TooltipIcon
                    align='center'
                    className='connect-gateway-tooltip'
                    tooltipText={
                      t('connectGateway.agentNotAvailableTooltipText') as string
                    }
                  >
                    <Help16 />
                  </TooltipIcon>
                </span>
              ) : (
                t('connectGateway.radioButtonFromAgentText')
              )
            }
            value='fromAgent'
            id={`default-${tearsheetId}`}
            disabled={!agentList?.available?.length}
          />
          <RadioButton
            labelText={
              gatewayList?.length === 0 ? (
                <span className='connect-gateway-radio-section'>
                  {t('connectGateway.radioBtnChooseExisting')}
                  <TooltipIcon
                    align='center'
                    className='connect-gateway-tooltip'
                    tooltipText={
                      t('connectGateway.noExistingGatewayTooltipText') as string
                    }
                  >
                    <Help16 />
                  </TooltipIcon>
                </span>
              ) : (
                t('connectGateway.radioBtnChooseExisting')
              )
            }
            value='existingGateway'
            id={`existingGateway-${tearsheetId}`}
            disabled={gatewayList?.length === 0}
          />
        </RadioButtonGroup>
      </div>
      {!formData.install_method ? null : formData.install_method ===
        'fromAgent' ? (
        <div className='agent-details'>
          <Row>
            <Column sm={2} md={3} lg={8}>
              <Dropdown
                className='form-field'
                light
                items={
                  Array.isArray(agentList?.available)
                    ? agentList?.available
                    : []
                }
                itemToString={(item: any) =>
                  `${item?.name} (${item?.namespace})`
                }
                id={`select-agent-dropdown-${tearsheetId}`}
                selectedItem={formData?.agent_node ?? ''}
                itemToElement={(item: any) => {
                  const trimmedValue =
                    typeof item?.namespace === 'string'
                      ? item?.namespace?.trim()
                      : item?.namespace;
                  const isNamespaceAlreadyExist =
                    mode === 'ADD'
                      ? props?.namespaceList?.find(
                          (namespace: any) => namespace?.name === trimmedValue
                        )
                      : props?.namespaceList?.find(
                          (namespace: any) =>
                            namespace?.name === trimmedValue &&
                            namespace?.name !== partitionData?.name
                        );
                  return isNamespaceAlreadyExist ? (
                    <Tooltip
                      label={t('connectGateway.namespaceExist')}
                      align='right'
                    >
                      <button className='disabledButton'>
                        <span className='agentListName-disabled'>{`${item?.name} (${item?.namespace})`}</span>
                      </button>
                    </Tooltip>
                  ) : (
                    <span className='agentListName'>{`${item?.name} (${item?.namespace})`}</span>
                  );
                }}
                titleText={t('connectGateway.agent.label')}
                onChange={(e: any) => {
                  const item = e.selectedItem;
                  const trimmedValue =
                    typeof item?.namespace === 'string'
                      ? item?.namespace?.trim()
                      : item?.namespace;
                  const isNamespaceAlreadyExist =
                    mode === 'ADD'
                      ? props?.namespaceList?.find(
                          (namespace: any) => namespace?.name === trimmedValue
                        )
                      : props?.namespaceList?.find(
                          (namespace: any) =>
                            namespace?.name === trimmedValue &&
                            namespace?.name !== partitionData?.name
                        );
                  if (isNamespaceAlreadyExist) return null;
                  else {
                    !showConfigurableSettings &&
                      setShowConfigurableSettings(!showConfigurableSettings);
                    onChange('agent_node', e.selectedItem);
                  }
                }}
                label={t('connectGateway.agent.placeholder')}
                invalid={agentError?.error}
                invalidText={agentError?.errorMsg}
              />
            </Column>
            {showConfigurableSettings && (
              <>
                <Column sm={2} md={3} lg={8}>
                  <TextInput
                    light
                    name='connected-gateway-name'
                    className='form-field gateway-name-field'
                    id={`connected-gateway-editable-input`}
                    labelText={t('connectGateway.gateway.label') as string}
                    onChange={(e: any) =>
                      onChange('gateway_name', e.target.value)
                    }
                    placeholder={t('connectGateway.gateway.placeholder')}
                    value={formData?.gateway_name}
                    invalid={gatewayNameError?.error}
                    invalidText={gatewayNameError?.errorMessage}
                    size='md'
                    readOnly
                  />
                </Column>
                <Column sm={2} md={6} lg={8}>
                  <Dropdown
                    className='form-field'
                    light
                    items={resourceGroups ?? []}
                    itemToString={(item: ResourceGroup) => item?.name}
                    id={`select-resourcegroup-${tearsheetId}`}
                    selectedItem={
                      initalFormData?.infrastructureGroup?.value ?? ''
                    }
                    titleText={t('connectGateway.infrastructureGroup.label')}
                    onChange={(e: any) =>
                      handleChange('infrastructureGroup', e.selectedItem)
                    }
                    label={t('connectGateway.infrastructureGroup.placeholder')}
                  />
                </Column>
              </>
            )}
          </Row>
          {showConfigurableSettings && (
            <div className='gateway-configuration-section'>
              <div className='heading'>
                {t('connectGateway.configurationForm.heading')}
              </div>
              <div className='description'>
                <span className='descriptionText'>
                  {t('connectGateway.configurationForm.description')}
                </span>
                <span className='link'>
                  <a
                    href='https://www.ibm.com/docs/en/SSTZ4DO/rhsi/rhsi-deploy-ui.html'
                    className='ingress-link'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {t('connectGateway.ingressForm.descriptionLink')}
                  </a>
                </span>
              </div>
              <Row className='gateway-sizing-section'>
                <Column md={4}>
                  <Dropdown
                    light
                    id='select-gateway-sizing'
                    items={gwRhsiComputeProfiles.sort((p1, p2) =>
                      p1?.profile > p2?.profile ? -1 : 1
                    )}
                    selectedItem={gwRhsiComputeProfiles.find(
                      cp => cp.profile === formData.rhsi_compute_profile
                    )}
                    itemToString={(gatewaySizing: any) =>
                      `${
                        gatewaySizing?.profile?.charAt(0).toUpperCase() +
                        gatewaySizing?.profile?.slice(1)
                      } (${t('requests')}: CPU ${gatewaySizing?.cpu_req}, MEM ${
                        gatewaySizing?.mem_req
                      }, ${t('limits')}: CPU ${gatewaySizing?.cpu_lim}, MEM ${
                        gatewaySizing?.mem_lim
                      })`
                    }
                    onChange={(e: any) =>
                      onChange('rhsi_compute_profile', e.selectedItem)
                    }
                    label={t('connectGateway.gatewaySizeDropdown.label')}
                    titleText={t('connectGateway.gatewaySizeDropdown.title')}
                    invalid={gwRhsiComputeProfiles.length === 0}
                    invalidText={t(
                      'connectGateway.rhsiComputeProfileListEmptyError'
                    )}
                  />
                </Column>
              </Row>
              {
                <div className='gateway-configuration-ingress-form'>
                  <div className='ingress-form-heading'>
                    {t('connectGateway.ingressForm.heading')}
                  </div>
                  <div className='ingress-form-description'>
                    <span className='descriptionText'>
                      {t('connectGateway.ingressForm.description')}
                    </span>
                  </div>
                  <Row className='ingress-config-form'>
                    <Column md={4}>
                      <Dropdown
                        light
                        id='select-gateway-ingress-type'
                        items={gwIngressTypes}
                        selectedItem={
                          formData.skupper_site_configuration?.ingress_type
                            ?.label
                        }
                        itemToString={(item: any) => item.label}
                        onChange={(e: any) => {
                          onChange(
                            'skupper_site_configuration',
                            e.selectedItem.id,
                            'ingress_type'
                          );
                          e.selectedItem.id === '' ||
                          e.selectedItem.id === IngressTypes.NONE
                            ? enableIngress && setEnableIngress(!enableIngress)
                            : !enableIngress &&
                              setEnableIngress(!enableIngress);
                        }}
                        label={t('connectGateway.ingressType.label')}
                        titleText={t('connectGateway.ingressType.titleText')}
                        invalid={gwIngressTypes.length === 0}
                        invalidText={t(
                          'connectGateway.ingressTypeListEmptyError'
                        )}
                      />
                    </Column>
                    {enableIngress && (
                      <>
                        <Column md={4}>
                          <TextInput
                            id='gateway-ingress-hostname'
                            light
                            onChange={(e: any) =>
                              onChange(
                                'skupper_site_configuration',
                                e.target.value,
                                'ingress_hostname'
                              )
                            }
                            value={
                              formData.skupper_site_configuration
                                ?.ingress_hostname?.value
                            }
                            size='md'
                            labelText={t(
                              'connectGateway.ingressHostname.label'
                            )}
                            placeholder={t(
                              'connectGateway.ingressHostname.placeholder'
                            )}
                            invalid={
                              formData?.skupper_site_configuration
                                ?.ingress_hostname?.error
                            }
                            invalidText={
                              formData?.skupper_site_configuration
                                ?.ingress_hostname?.errorMessage
                            }
                          />
                        </Column>
                        <Column md={4} id='router-ingress-hostname-col'>
                          <TextInput
                            id='router-ingress-hostname'
                            light
                            onChange={(e: any) =>
                              onChange(
                                'skupper_site_configuration',
                                e.target.value,
                                'router_ingress_hostname'
                              )
                            }
                            value={
                              formData.skupper_site_configuration
                                ?.router_ingress_hostname?.value
                            }
                            size='md'
                            labelText={t(
                              'connectGateway.routerIngressHostname.label'
                            )}
                            placeholder={t(
                              'connectGateway.routerIngressHostname.placeholder'
                            )}
                            invalid={
                              formData?.skupper_site_configuration
                                ?.router_ingress_hostname?.error
                            }
                            invalidText={
                              formData?.skupper_site_configuration
                                ?.router_ingress_hostname?.errorMessage
                            }
                          />
                        </Column>
                        <Column md={4} id='controller-ingress-hostname-col'>
                          <TextInput
                            id='controller-ingress-hostname'
                            light
                            onChange={(e: any) =>
                              onChange(
                                'skupper_site_configuration',
                                e.target.value,
                                'controller_ingress_hostname'
                              )
                            }
                            value={
                              formData.skupper_site_configuration
                                ?.controller_ingress_hostname?.value
                            }
                            size='md'
                            labelText={t(
                              'connectGateway.controllerIngressHostname.label'
                            )}
                            placeholder={t(
                              'connectGateway.controllerIngressHostname.placeholder'
                            )}
                            invalid={
                              formData?.skupper_site_configuration
                                ?.controller_ingress_hostname?.error
                            }
                            invalidText={
                              formData?.skupper_site_configuration
                                ?.controller_ingress_hostname?.errorMessage
                            }
                          />
                        </Column>
                        <Column md={8} id='ingress-annotations-col'>
                          <TextInput
                            id='ingress-annotations'
                            light
                            onChange={(e: any) =>
                              onChange(
                                'skupper_site_configuration',
                                e.target.value,
                                'ingress_annotations'
                              )
                            }
                            value={
                              formData.skupper_site_configuration
                                ?.ingress_annotations?.value
                            }
                            size='md'
                            labelText={t(
                              'connectGateway.ingressAnnotations.label'
                            )}
                            placeholder={t(
                              'connectGateway.ingressAnnotations.placeholder'
                            )}
                            invalid={
                              formData?.skupper_site_configuration
                                ?.ingress_annotations?.error
                            }
                            invalidText={
                              formData?.skupper_site_configuration
                                ?.ingress_annotations?.errorMessage
                            }
                          />
                        </Column>
                      </>
                    )}
                  </Row>
                </div>
              }
            </div>
          )}
          {showConfigurableSettings ? (
            <div className='optional-details'>
              <Row className='row form-heading'>
                {t('connectGateway.optionalDetailsHeading')}
              </Row>
              <Row className='row gateway-labels-row'>
                <Column md={8}>
                  <AddLabels
                    id='gateway-labels'
                    labelText={t('connectGateway.gatewayLabels.label')}
                    placeholder={t('connectGateway.gatewayLabels.placeholder')}
                    onChange={data => onChange('gateway_labels', data)}
                    btnText={t('connectGateway.gatewayLabels.btnText')}
                    btnKind='secondary'
                    tagType='green'
                    defaultValues={formData?.gateway_labels}
                  />
                </Column>
              </Row>
              <Row className='row gateway-description-row'>
                <Column md={8}>
                  <TextArea
                    id='gateway-description'
                    name='description'
                    autoComplete='off'
                    labelText={t('connectGateway.gatewayDescription.label')}
                    placeholder={t(
                      'connectGateway.gatewayDescription.placeholder'
                    )}
                    onChange={(e: any) =>
                      onChange('gateway_description', e?.target?.value)
                    }
                    value={formData?.gateway_description}
                    maxLength={300}
                  />
                </Column>
              </Row>
            </div>
          ) : null}
        </div>
      ) : (
        <div className='agent-details'>
          <div className='gatewayContainer'>
            <Dropdown
              className='form-field gateway-dropdown'
              light
              items={gatewayList ? gatewayList : []}
              itemToElement={(item: any) => {
                const gatewayNode = Array.isArray(agentList?.gateways)
                  ? agentList?.gateways?.find(
                      (agent: any) => agent?.gateway_id === item?.resource_id
                    )
                  : null;
                const trimmedValue =
                  typeof gatewayNode?.namespace === 'string'
                    ? gatewayNode?.namespace?.trim()
                    : gatewayNode?.namespace;
                const isNamespaceAlreadyExist =
                  mode === 'ADD'
                    ? props?.namespaceList?.find(
                        (namespace: any) => namespace?.name === trimmedValue
                      )
                    : props?.namespaceList?.find(
                        (namespace: any) =>
                          namespace?.name === trimmedValue &&
                          namespace?.name !== partitionData?.name
                      );
                return isNamespaceAlreadyExist ? (
                  <Tooltip
                    label={t('connectGateway.namespaceExist')}
                    align='right'
                  >
                    <button className='disabledButton'>
                      <span className='agentListName-disabled'>{`${item?.name} (${trimmedValue})`}</span>
                    </button>
                  </Tooltip>
                ) : (
                  <span className='agentListName'>{`${item?.name} (${trimmedValue})`}</span>
                );
              }}
              id={`select-gateway-dropdown-${tearsheetId}`}
              selectedItem={formData?.gateway?.name ?? ''}
              titleText={t('connectGateway.chooseGateway.label')}
              onChange={(e: any) => {
                const gatewayNode = Array.isArray(agentList?.gateways)
                  ? agentList?.gateways?.find(
                      (agent: any) =>
                        agent?.gateway_id === e?.selectedItem?.resource_id
                    )
                  : null;
                const trimmedValue =
                  typeof gatewayNode?.namespace === 'string'
                    ? gatewayNode?.namespace?.trim()
                    : gatewayNode?.namespace;
                const isNamespaceAlreadyExist =
                  mode === 'ADD'
                    ? props?.namespaceList?.find(
                        (namespace: any) => namespace?.name === trimmedValue
                      )
                    : props?.namespaceList?.find(
                        (namespace: any) =>
                          namespace?.name === trimmedValue &&
                          namespace?.name !== partitionData?.name
                      );
                !isNamespaceAlreadyExist &&
                  !showSettings &&
                  setShowSettings(!showSettings);
                return isNamespaceAlreadyExist
                  ? null
                  : onChange('gateway', e.selectedItem);
              }}
              label={t('connectGateway.chooseGateway.placeholder')}
            />
            {formData?.gateway?.name ? (
              <>
                <TextInput
                  labelText={
                    t('connectGateway.infrastructureGroup.label') as string
                  }
                  id={`namespaceName-${tearsheetId}`}
                  className='form-field infra-name-field'
                  value={
                    formData?.gateway?.resource_group_id
                      ? resourceGroups?.find(
                          rg =>
                            rg.resource_id ===
                            formData?.gateway?.resource_group_id
                        )?.name
                      : ''
                  }
                  name='infrastructure_name'
                  readOnly
                />
              </>
            ) : null}
          </div>
          {showSettings && (
            <div className='configuration-settings-section'>
              <div className='heading'>
                {t('connectGateway.skupperConfiguration.heading')}
              </div>
              <Row className='configuration-settings-row'>
                <Column md={4} lg={8} className='field-div-col'>
                  <div className='gateway-sizing field-div'>
                    <span className='label'>
                      {t(
                        'connectGateway.skupperConfiguration.gatewaySizing.label'
                      )}
                    </span>
                    <span className='value'>{formData.gatewaySizing}</span>
                  </div>
                </Column>
                <Column md={4} lg={8} className='field-div-col'>
                  <div className='ingress-type field-div'>
                    <span className='label'>
                      {t(
                        'connectGateway.skupperConfiguration.ingressType.label'
                      )}
                    </span>
                    <span className='value'>
                      {formData?.skupper_site_configuration?.ingress_type
                        ?.value || '—'}
                    </span>
                  </div>
                </Column>
                <Column md={4} lg={8} className='field-div-col'>
                  <div className='ingress-type field-div'>
                    <span className='label'>
                      {t(
                        'connectGateway.skupperConfiguration.ingressHostName.label'
                      )}
                    </span>
                    <span className='value'>
                      {formData?.skupper_site_configuration?.ingress_hostname
                        ?.value || '—'}
                    </span>
                  </div>
                </Column>
                <Column md={4} lg={8} className='field-div-col'>
                  <div className='router-ingress-host-name field-div'>
                    <span className='label'>
                      {t(
                        'connectGateway.skupperConfiguration.routerIngressHostName.label'
                      )}
                    </span>
                    <span className='value'>
                      {formData?.skupper_site_configuration
                        ?.router_ingress_hostname?.value || '—'}
                    </span>
                  </div>
                </Column>
                <Column md={4} lg={8} className='field-div-col'>
                  <div className='controller-ingress-host-name field-div'>
                    <span className='label'>
                      {t(
                        'connectGateway.skupperConfiguration.controllerIngressHostName.label'
                      )}
                    </span>
                    <span className='value'>
                      {formData?.skupper_site_configuration
                        ?.controller_ingress_hostname?.value || '—'}
                    </span>
                  </div>
                </Column>
                <Column md={4} lg={8} className='field-div-col'>
                  <div className='ingress-annotations field-div'>
                    <span className='label'>
                      {t(
                        'connectGateway.skupperConfiguration.ingressAnnotations.label'
                      )}
                    </span>
                    <span className='value'>
                      {formData?.skupper_site_configuration?.ingress_annotations
                        ?.value || '—'}
                    </span>
                  </div>
                </Column>
              </Row>
            </div>
          )}
        </div>
      )}
    </div>
    // </Modal>
  );
};

export default ConnectGateway;
