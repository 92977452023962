import { SimpleBarChart } from '@carbon/charts-react';
import { BarChartOptions, ChartTabularData } from '@carbon/charts/interfaces';

interface Props {
  data: ChartTabularData;
  options: BarChartOptions;
}
const SimpleCustomBarchart = ({ data, options }: Props) => {
  return <SimpleBarChart data={data} options={options}></SimpleBarChart>;
};

export default SimpleCustomBarchart;
