import { Information16 } from '@carbon/icons-react';
import {
  ComboBox,
  FormLabel,
  TextInput,
  Toggle,
  TooltipDefinition,
  TooltipIcon,
} from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import AddLabels from '../../components/AddLabels/AddLabels';
import { Secret } from '../../models/master';
import useAnalytics from '../../lib/useAnalytics';

const EditPartitionForm = (props: {
  onChange: any;
  formData: any;
  resourceGroupList: any;
  partitionType: string;
  secrets: Secret[] | null;
  partitionData: any;
  mode?: 'ADD' | 'EDIT' | 'CONFIGURE';
}) => {
  const {
    onChange,
    formData,
    resourceGroupList,
    partitionType,
    secrets,
    partitionData,
    mode,
  } = props;

  const { t } = useTranslation('editPartition');
  const isToggleDisabled =
    mode === 'CONFIGURE' ? false : partitionData.unmanaged;

  const { trackButtonClicked } = useAnalytics();

  const autodiscoverToggle = () => (
    <Toggle
      className='autodiscover-toggle'
      labelText={mode === 'CONFIGURE' ? t('form.autoDiscover.toggleLabel') : ''}
      id='autodiscover-sg-namespaces'
      labelA={t('form.autoDiscover.applications')}
      labelB={t('form.autoDiscover.applications')}
      toggled={formData.auto_discover.value}
      onToggle={(val: any, id: string) => {
        if (val && mode !== 'CONFIGURE')
          trackButtonClicked({
            CTA: 'Autodiscover applications',
            action:
              partitionType === 'namespace'
                ? 'Autodiscover applications switched on from edit namespace modal'
                : 'Autodiscover applications switched on from edit security group modal',
          });
        onChange('auto_discover', val);
      }}
      disabled={isToggleDisabled}
    />
  );

  const renderAutodiscoverToggle = () => {
    return (
      <div className='auto-discover-toggles'>
        {isToggleDisabled ? (
          <TooltipDefinition
            direction='bottom'
            align='start'
            tooltipText={
              t('form.autoDiscover.tooolTipUnmanaged', {
                partitionType: partitionType ?? '',
              }) as string
            }
          >
            {autodiscoverToggle()}
          </TooltipDefinition>
        ) : (
          autodiscoverToggle()
        )}
      </div>
    );
  };

  return (
    <div className={`edit-partition-form ${mode?.toLowerCase()}`}>
      <TextInput
        id='editPartitionName'
        className='form-field'
        name='name'
        placeholder={
          t('form.name.placeholder', {
            partitionType: partitionType ?? '',
          }) as string
        }
        labelText={t<string>('form.name.label')}
        value={formData.name.value}
        onChange={e => onChange('name', e.target.value)}
        invalid={formData.name.error}
        invalidText={formData.name.errorMessage}
        maxLength={60}
        disabled={partitionData.is_discovered}
      />
      {/* <ComboBox
        id='editPartitionResourceGroup'
        name='resourceGroup'
        className='form-field'
        selectedItem={formData.resourceGroup.value}
        onChange={data => onChange('resourceGroup', data.selectedItem)}
        items={
          resourceGroupList
            ? resourceGroupList.filter(
                (resource: { type: string }) =>
                  resource.type === 'infrastructure'
              ) ?? []
            : []
        }
        itemToString={item => (item ? item.name : '')}
        titleText={t('form.resourceGroup.label')}
        placeholder={t('form.resourceGroup.placeholder')}
        invalid={formData.resourceGroup.error}
        invalidText={formData.resourceGroup.errorMessage}
      /> */}
      <AddLabels
        id='partitionLabels'
        labelText={t('form.labels.label')}
        placeholder={t('form.labels.placeholder')}
        onChange={data => onChange('labels', data)}
        btnText={t('form.labels.buttonText')}
        btnKind='secondary'
        tagType='green'
        defaultValues={formData.labels.value}
      />
      {mode !== 'CONFIGURE' && (
        <ComboBox
          className={
            !(formData.auto_discover.value === true) ||
            partitionData.is_discovered
              ? 'secret-dropdown-disabled'
              : 'secret-dropdown'
          }
          disabled={
            !(formData.auto_discover.value === true) ||
            partitionData.is_discovered
          }
          placeholder={t('form.secret.placeholder', {
            partitionType: partitionData?.type,
          })}
          id='editPartition-secret-name-dropdown'
          titleText={t<string>('form.secret.label')}
          size='lg'
          items={Array.isArray(secrets) ? secrets : []}
          itemToString={(item: any) => item?.name}
          translateWithId={t}
          onChange={(e: any) => {
            onChange('credentials_key', e.selectedItem);
          }}
          selectedItem={formData.credentials_key}
        />
      )}
      <div className='autodicoveryWrapper'>
        <div className='auto-discover-row'>
          <FormLabel className='autodiscover-label'>
            {mode === 'CONFIGURE'
              ? t('form.autoDiscover.configureLabel')
              : t('form.autoDiscover.label')}
            {mode !== 'CONFIGURE' && (
              <TooltipIcon
                className='autodiscover-tooltip'
                tooltipText={
                  t('form.autoDiscover.tooltipText', {
                    partitionType: partitionType ?? '',
                  }) as string
                }
              >
                <Information16 />
              </TooltipIcon>
            )}
          </FormLabel>
          {renderAutodiscoverToggle()}
        </div>
        {/* {formData.auto_discover.value &&
        formData.auto_discover.value === true ? (
          <ComboBox
            id='editPartitionAppResourceGroup'
            name='appResourceGroup'
            className='form-field'
            selectedItem={formData.appResourceGroup.value}
            onChange={data => onChange('appResourceGroup', data.selectedItem)}
            items={
              resourceGroupList
                ? resourceGroupList.filter(
                    (resource: { type: string }) =>
                      resource.type === 'application'
                  ) ?? []
                : []
            }
            itemToString={item => (item ? item.name : '')}
            titleText={t('form.appResourceGroup.label')}
            placeholder={t('form.appResourceGroup.placeholder')}
            invalid={formData.appResourceGroup.error}
            invalidText={formData.appResourceGroup.errorMessage}
          />
        ) : null} */}
      </div>
      {/* {formData.auto_discover.value && formData.auto_discover.value === true ? (
        <TextInput
          labelText={t('form.endpoint.label')}
          placeholder={t('form.endpoint.placeholder')}
          id='register-env-endpoint'
          name='api_end_point'
          onChange={e => onChange('api_end_point', e.target.value)}
          autoComplete='off'
          size='lg'
          value={formData.api_end_point.value}
          invalid={formData.api_end_point.error}
          invalidText={t('form.endpoint.errorText')}
          maxLength={60}
        />
      ) : null} */}
    </div>
  );
};

export default EditPartitionForm;
