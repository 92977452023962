import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Column, Button } from 'carbon-components-react';
import { useLocation } from 'react-router-dom';
import images from '../../images/images';
import './LoginPage.scss';
import CallBackHandler from '../../pages/CallBackHandler/CallBackHandler';
import { MCNM_AUTH_URL } from '../../controllers/config.js';
import { Trans } from 'react-i18next';
import useAnalytics from '../../lib/useAnalytics';
import analyticsData from '../../lib/analyticsEventData';

interface Props {
  onLogin: () => void;
}

const LoginPage: React.FC<Props> = ({ onLogin }) => {
  const { t } = useTranslation('loginPage');
  const location = useLocation();
  const [hideLogin, setHideLogin] = useState(false);
  const { pageViewed, trackButtonClicked } = useAnalytics();

  React.useEffect(() => {
    if (location.pathname.includes('/callback')) {
      setHideLogin(true);
    }
  }, [location]);

  React.useEffect(() => {
    pageViewed('Login');
  }, []);

  const navigateToURL = () => {
    try {
      window.location.replace(`${MCNM_AUTH_URL}/login/`);
    } catch (e) {
      console.log('Error caught', e);
    }
  };
  return (
    <>
      {!hideLogin ? (
        <Row className='container'>
          <Column lg={12} md={6} className='svg-container'>
            {images.loginImg()}
          </Column>
          <Column md={2} className='button-container'>
            <p className='text-container '>
              <Trans i18nKey={t('loginTo')} />
            </p>
            <Button
              onClick={() => {
                trackButtonClicked(
                  analyticsData['Login & Logout'].events.userLogin.props,
                  analyticsData['Login & Logout'].events.userLogin.event
                );
                try {
                  navigateToURL();
                } catch (e) {
                  console.log('ERROR');
                }
              }}
            >
              {t('login')}
            </Button>
          </Column>
        </Row>
      ) : (
        <CallBackHandler onLogin={onLogin} />
      )}
    </>
  );
};

export default LoginPage;
