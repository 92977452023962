import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ClickableTile, Row, Column } from 'carbon-components-react';

import dateUtils from '../../../lib/dates';
import './LocationsCards.scss';
import LabelTag, { TagType } from '../../../components/LabelTag/LabelTag';
import IconWithToolTip from '../../../components/IconWithToolTip/IconWithToolTip';
import GenericTruncateString from '../../../components/GenericTruncateString/GenericTruncateString';

const LocationsCards = ({
  cloudId,
  locationId,
  cloudName,
  city,
  type,
  country,
  resourceName,
  description,
  tags,
  updated,
  unmanaged,
  typeList,
}: {
  cloudId: string;
  locationId: string;
  cloudName: string;
  city: string;
  type: string;
  country: string;
  resourceName: string;
  description: string;
  tags: string[] | TagType[];
  updated: string;
  unmanaged: boolean;
  typeList: any;
}) => {
  const { t } = useTranslation('location');
  const navigate = useNavigate();

  const getTypeValue = () => {
    const typeValue = typeList.find(
      ({ code }: { code: string }) => code === type
    );
    return !!typeValue?.label ? typeValue?.label : '—';
  };

  return (
    <ClickableTile
      className='locations-card'
      onClick={() =>
        cloudId &&
        locationId &&
        navigate(`/locationDetails?cloudId=${cloudId}&locationId=${locationId}`)
      }
    >
      <Row className='header'>
        <Column className='name-row'>
          <div className='name' title={resourceName ?? ''}>
            {!!resourceName ? (
              <GenericTruncateString
                str={resourceName}
                maxLength={25}
                limit={11}
              />
            ) : (
              '—'
            )}
          </div>
          {unmanaged ? (
            <div className='icon'>
              <IconWithToolTip
                icon={<div className='unmanaged-icon' />}
                iconDescription={t('unmanaged')}
              />
            </div>
          ) : null}
        </Column>
      </Row>
      <Row className='data'>
        <Column>
          <div className='label-text'>{t('cardSubheading.cloud')}</div>
          <div className='name'>
            {!!cloudName ? (
              <GenericTruncateString
                str={cloudName}
                maxLength={25}
                limit={11}
              />
            ) : (
              '—'
            )}
          </div>
        </Column>
        <Column>
          <div className='label-text'>{t('cardSubheading.city')}</div>
          <div className='name'>{city}</div>
        </Column>
      </Row>

      <Row className='data'>
        <Column>
          <div className='label-text'>{t('cardSubheading.type')}</div>
          <div className='name'>{getTypeValue()}</div>
        </Column>
        <Column>
          <div className='label-text'>{t('cardSubheading.country')}</div>
          <div className='name'>{country}</div>
        </Column>
      </Row>
      <Row className='tags'>
        {Array.isArray(tags) && tags.length > 0 ? (
          <Column className='label-col'>
            <LabelTag labelArray={tags} singleLine={true} />
          </Column>
        ) : (
          ''
        )}
      </Row>
      <Row className='data'>
        <Column>
          <div className='helper-text'>
            {t('updated', {
              date: !!updated
                ? dateUtils.getUserFriendlyDate(updated)
                : updated,
            })}
          </div>
        </Column>
      </Row>
    </ClickableTile>
  );
};

export default LocationsCards;
