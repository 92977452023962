import React from 'react';
import '@carbon/styles/css/styles.css';
import '@carbon/charts/styles.css';
import { GroupedBarChart, LineChart } from '@carbon/charts-react';
import { ToolbarOptions, TooltipOptions } from '@carbon/charts/interfaces';
import {
  Modal,
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableProps,
  TableRow,
} from '@carbon/react';
import {
  DataTableCell,
  DataTableHeader,
  DataTableRow,
} from '../../../models/dataTable';
import { exportCSV } from '../../../lib/utils';
import { useTranslation } from 'react-i18next';
import { AxisDomain } from 'd3';
import { METRICS_CHART_TIME_OFFSET } from '../../../lib/constants';

interface chartColorStructure {
  pairing?: any;
  scale: any;
}
interface Props {
  data: any;
  chartType: string;
  title: string;
  axes: any;
  legend: any;
  height: string;
  curveType: string;
  chartItemsColor: chartColorStructure;
  tooltip: TooltipOptions;
  toolbar: ToolbarOptions;
  showTableModal: boolean;
  tableHeaders?: DataTableHeader[];
  exportFileName?: string;
  onCloseTableModal?: Function;
}

const CustomCharts: React.FC<Props> = ({
  data,
  title,
  axes,
  legend,
  height,
  curveType,
  chartType,
  chartItemsColor,
  tooltip,
  toolbar,
  showTableModal,
  tableHeaders = [],
  exportFileName = '',
  onCloseTableModal = () => {},
}) => {
  const { t } = useTranslation('customChart');
  const getChartOptions = () => {
    const options = {
      title: title,
      axes: axes,
      legend: legend,
      height: height,
      curve: '',
      color: chartItemsColor,
      tooltip: tooltip,
      toolbar: toolbar,
    };
    if (chartType === 'multiLine') options['curve'] = curveType;
    return options;
  };

  return (
    <>
      {chartType === 'multiLine' ? (
        <LineChart data={data} options={getChartOptions()}></LineChart>
      ) : (
        <GroupedBarChart
          data={data}
          options={getChartOptions()}
        ></GroupedBarChart>
      )}
      <Modal
        className='chart-table-data-modal'
        size='md'
        modalHeading={title}
        onRequestClose={() => onCloseTableModal()}
        onRequestSubmit={() => {
          // Create export csv data based on the table header configuration.
          // This is to show exactly the same table header text in the csv file
          const exportData: any[] = [];
          data.forEach((obj: any) => {
            let newObj = {};
            tableHeaders.forEach(tableHeader => {
              newObj = {
                ...newObj,
                [tableHeader.header]:
                  typeof obj[tableHeader.key] === 'string'
                    ? obj[tableHeader.key].replace(/,/g, '')
                    : obj[tableHeader.key],
              };
            });
            exportData.push(newObj);
          });
          exportCSV(exportData, exportFileName);
        }}
        primaryButtonText={t('downloadCSV')}
        secondaryButtonText={t('cancel')}
        open={showTableModal}
      >
        <DataTable
          rows={data as any}
          headers={tableHeaders as DataTableHeader[]}
          isSortable
          stickyHeader
        >
          {({
            rows,
            headers,
            getTableProps,
            getHeaderProps,
            getRowProps,
            getTableContainerProps,
          }: {
            rows: any;
            headers: DataTableHeader[];
            getTableProps: () => typeof TableProps;
            getHeaderProps: any;
            getRowProps: any;
            getTableContainerProps: any;
          }) => (
            <TableContainer {...getTableContainerProps()}>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers
                      .filter(header => header['key'] !== 'id')
                      .map(header => (
                        <TableHeader
                          className='header'
                          {...getHeaderProps({ header })}
                        >
                          {header.header}
                        </TableHeader>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row: DataTableRow) => (
                    <TableRow {...getRowProps({ row })}>
                      {row.cells
                        .filter((cell: DataTableCell) => {
                          if (cell.info['header'] !== 'id') {
                            return true;
                          }
                        })
                        .map((cell: DataTableCell) => {
                          return (
                            <TableCell className='value' key={cell.id}>
                              {cell.value}
                            </TableCell>
                          );
                        })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DataTable>
      </Modal>
    </>
  );
};

export default CustomCharts;
