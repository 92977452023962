import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ServiceForm, { FormData, PortData } from './ServiceForm';
import NarrowTearsheetComponent from '../../../components/NarrowTearsheet/NarrowTearsheet';
import InlineNotification from '../../../components/Notifications/Inline/Notification';
import { NotificationContext } from '../../../components/Notifications/Context/NotificationProvider';
import { AxiosError } from 'axios';

import {
  Service,
  ServiceData,
  Port,
  CompatibilitySet,
} from '../../../models/master';

import {
  addApplicationService,
  updateApplicationService,
} from '../../../controllers/applicationApis.js';

import {
  nameRegexPattern,
  alphaNumbericNoSpaceRegexPattern,
} from '../../../lib/regex';
import useAnalytics from '../../../lib/useAnalytics';
import analyticsData from '../../../lib/analyticsEventData';

import './ManageService.scss';

const defaultFormValue = {
  name: {
    value: '',
    error: false,
    errorMessage: '',
  },
  ports: [],
  labels: {
    value: [],
    error: false,
    errorMessage: '',
  },
};

const requiredFields = ['name'];

interface Props {
  open: boolean;
  mode: 'ADD' | 'EDIT';
  serviceData: ServiceData | null;
  onCloseService: () => void;
  applicationId: string;
  addService: (data: Service) => void;
  updateService: (data: Service) => void;
  allPortNumbers: Port[];
  networkSegmentCompatibilitySet: CompatibilitySet;
}

const ManageService: React.FC<Props> = ({
  open,
  mode,
  serviceData,
  onCloseService,
  applicationId,
  addService,
  updateService,
  allPortNumbers,
  networkSegmentCompatibilitySet,
}) => {
  const [formData, setFormData] = useState<FormData>(defaultFormValue);
  const [loading, setLoading] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [changed, setChanged] = useState(false);
  const [errorType, setErrorType] = useState('default');
  const [authError, setAuthError] = useState(false);
  const [subTitleErrorMsg, setSubTitleErrorMsg] = useState('');

  const notification = useContext(NotificationContext);

  const { t } = useTranslation('manageService');

  const { trackButtonClicked } = useAnalytics();

  useEffect(() => {
    if (mode === 'EDIT' && serviceData) {
      // If user change network segment, then there is a posibility that existing service name is invalid.
      // So we should show that validation message when user open the screen.
      const errorMessage = checkFieldValidation('name', serviceData.name);

      const updatedFormData = {
        name: {
          ...formData.name,
          value: serviceData.name,
          error: !!errorMessage,
          errorMessage,
        },
        ports: [...formData.ports, ...serviceData.ports],
        labels: {
          ...formData.labels,
          value: serviceData.labels,
        },
      };
      setFormData(updatedFormData);
    }
  }, [mode, serviceData]);

  const isFormValid = () => {
    for (const field of requiredFields) {
      const value = (formData as any)[field].value;
      if (checkFieldValidation(field, value)) {
        return false;
      }
      // Check whether we have atleast one port and protocol
      if (Array.isArray(formData.ports) && formData.ports.length === 0) {
        return false;
      }
    }
    return true;
  };

  const checkFieldValidation = (name: string, value: any) => {
    let errorMessage = '';
    switch (name) {
      case 'name':
        const regexPattern =
          networkSegmentCompatibilitySet !== 'RHSI'
            ? nameRegexPattern()
            : alphaNumbericNoSpaceRegexPattern();
        const regexErrorMsg =
          networkSegmentCompatibilitySet !== 'RHSI'
            ? t('validation.name.invalidRegex')
            : t('validation.name.rshiInvalidRegex');

        errorMessage = !value
          ? t('validation.name.required')
          : regexPattern && !regexPattern.test(value)
          ? regexErrorMsg
          : '';
        break;
    }
    return errorMessage;
  };

  const handleOnChange = (name: string, value: any) => {
    if (!changed) setChanged(true);
    if (showErrorSnackbar) {
      setShowErrorSnackbar(false);
      setAuthError(false);
    }

    const trimmedValue = typeof value === 'string' ? value?.trim() : value;

    const errorMessage = checkFieldValidation(name, trimmedValue);

    setFormData((prevState: any) => ({
      ...prevState,
      [name]: {
        value,
        error: !!errorMessage,
        errorMessage,
      },
    }));
  };

  const handleAddPort = (data: PortData) => {
    if (!changed) setChanged(true);
    if (showErrorSnackbar) {
      setShowErrorSnackbar(false);
      setAuthError(false);
    }

    setFormData((prevState: any) => ({
      ...prevState,
      ports: [...prevState.ports, data],
    }));
  };

  const handleRemovePort = (index: number, data: PortData) => {
    if (!changed) setChanged(true);
    if (showErrorSnackbar) {
      setShowErrorSnackbar(false);
      setAuthError(false);
    }

    formData.ports.splice(index, 1);

    setFormData(prevState => ({
      ...prevState,
      step2: {
        ports: [...prevState.ports],
      },
    }));
  };

  const getPorts = () =>
    formData.ports.map((port, i) => {
      return {
        port_number: port.port_number,
        protocol: port.protocol,
      };
    });

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const data = {
        name: formData?.name?.value?.trim(),
        ports: getPorts(),
        labels: formData?.labels?.value,
        application_id: applicationId,
      };

      let applicationData;
      if (mode === 'ADD') {
        trackButtonClicked(
          analyticsData['Application Details'].events.registerService.props,
          analyticsData['Application Details'].events.registerService.event
        );
        applicationData = await addApplicationService(applicationId, data);
        addService(applicationData);
      } else {
        applicationData = await updateApplicationService(
          applicationId,
          serviceData?.id,
          data
        );
        updateService(applicationData);
      }

      handleClose();

      // Trigger success toastbar
      notification.onTrigger('TOAST', {
        title: t('notificationTitle'),
        subtitle:
          mode === 'ADD'
            ? t('add.notificationSubtitle')
            : t('edit.notificationSubtitle'),
      });
    } catch (error: any) {
      console.error(error);
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        setShowErrorSnackbar(true);
        setAuthError(true);

        // We added this project.reject to prevent the modal closing when error occured.
        // But in latest version of '@carbon/ibm-products' the below line is not required and is throwing error.
        // return Promise.reject(() => console.log(error));
      }

      // TODO: We need to improve the API error handling logic.
      if (
        error?.response?.data?.error?.includes('svcName') &&
        error?.response?.data?.status === 'Resource already exists'
      ) {
        setErrorType('duplicateSrv');
      } else {
        const errorMessage: string =
          error.response !== undefined
            ? error.response['customErrorMessage']
            : '';
        errorMessage.length > 0 && setSubTitleErrorMsg(errorMessage);
        setErrorType('default');
      }

      setShowErrorSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setFormData(defaultFormValue);
    setShowErrorSnackbar(false);
    setAuthError(false);
    setLoading(false);
    setChanged(false);
    onCloseService();
  };

  const handleCloseErrorBar = () => {
    setShowErrorSnackbar(false);
    setAuthError(false);
  };

  const getErrorSubtitle = () => {
    if (errorType === 'duplicateSrv') {
      return t('duplicateSrvErrSubtitle');
    }
    if (subTitleErrorMsg.length === 0) {
      return t('errorSubtitle');
    } else {
      return subTitleErrorMsg;
    }
  };

  return (
    <NarrowTearsheetComponent
      title={mode === 'ADD' ? t('add.title') : t('edit.title')}
      description={
        mode === 'ADD' ? t('add.description') : t('edit.description')
      }
      open={open}
      className={'manage-application-service'}
      actions={[
        {
          kind: 'primary',
          label: mode === 'ADD' ? t('add.save') : t('edit.save'),
          onClick: () => handleSubmit(),
          disabled: !isFormValid() || !changed,
          loading: loading,
        },
        {
          kind: 'secondary',
          label: t('cancelBtn'),
          onClick: handleClose,
        },
      ]}
    >
      {showErrorSnackbar && (
        <InlineNotification
          onClose={() => handleCloseErrorBar() as any}
          kind={'error'}
          title={
            authError
              ? (t('authErrorTitle') as string)
              : mode === 'ADD'
              ? (t('add.error.title') as string)
              : (t('edit.error.title') as string)
          }
          subtitle={
            authError ? (t('authErrorSubtitle') as string) : getErrorSubtitle()
          }
        />
      )}
      <ServiceForm
        open={open}
        formData={formData}
        onChange={handleOnChange}
        onAddPort={handleAddPort}
        onRemovePort={handleRemovePort}
        allPortNumbers={allPortNumbers}
      />
    </NarrowTearsheetComponent>
  );
};

export default ManageService;
