import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

import MultiStepTearsheet from '../../../components/MultiStepTearSheet/MultiStepTearSheet';
import CloudSelect from './CloudSelect';
import AddDetails from './AddDetails';
import EditCloudDetails from './EditCloudDetails';
import { NotificationContext } from '../../../components/Notifications/Context/NotificationProvider';

import { addCloud, updateCloud } from '../../../controllers/cloudApis';
import {
  ResourceGroup,
  CloudType,
  Cloud,
  Secret,
} from '../../../models/master';
import { useCloudTypes } from '../../../hooks/useClouds';

import './RegisterCloud.scss';
import { getSecrets } from '../../../controllers/tenantApis';
import { getConditionalSecrets } from '../../../lib/utils';
import WideTearsheet from '../../../components/WideTearsheet/WideTearsheet';
import { removeExtraSpace } from '../../../lib/utils';
import useAnalytics from '../../../lib/useAnalytics';
import RegisterSecret from '../../SecretManager/RegisterSecret/RegisterSecret';
import analyticsData from '../../../lib/analyticsEventData';
import { inValidateListCacheFn } from '../../../lib/invalidateQueriesFunctions';
import { QueryKeys } from '../../../lib/enums';
import { DEFAULT_INFRASTRUCTURE_ID } from '../../../lib/constants';
import { useQueryClient } from '@tanstack/react-query';

interface Props {
  open: boolean;
  closeTearsheet: () => void;
  refreshData: () => void;
  selectedCloudData?: Cloud | null;
  editCloud?: boolean;
  cloudsData?: any;
  groups?: ResourceGroup[] | null;
}

const defaultFormData = {
  name: {
    value: '',
    error: false,
    errorMessage: '',
  },
  description: '',
  auto_discover: false,
  // the key in the secrets manager for the credentials to this node/cluster
  credentials_key: {
    id: '',
    name: '',
  },
  api_end_point: '',
  infrastructureGroup: {
    id: '',
    name: '',
  },
  is_private: false,
  ibm_cos_bucket_url: {
    value: '',
    error: false,
    errorMessage: '',
  },
};

const RegisterCloud: React.FC<Props> = ({
  open,
  closeTearsheet,
  refreshData,
  editCloud = false,
  selectedCloudData,
  cloudsData,
  groups,
}) => {
  const { t } = useTranslation('registerCloud');
  const { trackButtonClicked } = useAnalytics();

  const [onInitialStep, setOnInitialStep] = useState(true);
  const [selectedCloud, setSelectedCloud] = useState<CloudType | null>(null);
  const [formData, setFormData] = useState(defaultFormData);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openRegisterSecret, setOpenRegisterSecret] = useState(false);
  const [errorType, setErrorType] = useState('default');
  const [subTitleErrorMsg, setSubTitleErrorMsg] = useState('');
  const [secrets, setSecrets] = useState<Secret[] | null>(null);
  const [initialFormDataEdit, setInitialFormDataEdit] =
    useState(defaultFormData);
  const [notIncludeIntroStep, setNotIncludeIntroStep] = useState(true);
  const [secretsData, setSecretsData] = useState<[] | null>(null);
  const [nextButtonText, setNextButtonText] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const notification = useContext(NotificationContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleClose = () => {
    setFormData(defaultFormData);
    setOnInitialStep(false);
    setNotIncludeIntroStep(true);
    setNextButtonText(true);
    setSelectedCloud(null);
    setError(false);
    setErrorType('default');
    setSubTitleErrorMsg('');
    setSecrets(null);
    closeTearsheet();
  };

  // Queries cloud types
  const { data: cloudTypes, isFetching: isFetchingCloudTypes } = useCloudTypes({
    enabled: open,
  });

  const handleSubmit = async () => {
    const data = {
      ...formData,
      auto_discover: formData.auto_discover,
      description: formData.description,
      is_private: (
        editCloud
          ? selectedCloudData?.type === 'other'
          : selectedCloud?.code === 'other'
      )
        ? formData.is_private
        : false,
      name: removeExtraSpace(formData.name.value),
      credentials_key:
        formData.auto_discover === true ? formData.credentials_key.id : '',
      url: formData.auto_discover === true ? formData.api_end_point : '',
      resource_group_id: formData.infrastructureGroup.id,
      type: editCloud ? selectedCloudData?.type : selectedCloud?.code,
      ibm_cos_bucket_url:
        formData.auto_discover === true
          ? formData.ibm_cos_bucket_url.value
          : '',
    };
    if (!editCloud)
      trackButtonClicked(
        analyticsData['Cloud'].events.registerCloudLink.props,
        analyticsData['Cloud'].events.registerCloudLink.event
      );
    try {
      setSubmitLoading(true);

      queryClient.invalidateQueries({
        queryKey: [QueryKeys.CLOUDS],
      });
      let cloud;
      editCloud
        ? (cloud = await updateCloud(selectedCloudData?.resource_id, data))
        : (cloud = await addCloud(data));
      handleSuccess(cloud);
    } catch (error: any) {
      const errorMessage: string =
        error.response !== undefined
          ? error.response['customErrorMessage']
          : '';
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        setError(true);
        setErrorType('403');
        return !editCloud ? Promise.reject(() => console.log(error)) : null;
      }
      setError(true);
      errorMessage.length > 0 && setSubTitleErrorMsg(errorMessage);
      setErrorType('default');
      return !editCloud ? Promise.reject(() => console.log(error)) : null;
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleSuccess = (cloud: any) => {
    refreshData();

    editCloud
      ? // Trigger success toastbar edit
        notification.onTrigger('TOAST', {
          title: t('editCloud.notification.title', { name: cloud.name }),
          subtitle: t('editCloud.notification.subtitle'),
        })
      : // trigger success toastbar
        notification.onTrigger('ACTION', {
          title: t('notification.title'),
          subtitle: t('notification.subtitle', { name: cloud.name }),
          action: t('notification.button'),
          onActionClick: () =>
            navigate(`/cloudDetails?cloudId=${cloud.resource_id}`),
        });
    inValidateListCacheFn(QueryKeys.CLOUDS);
    setError(false);
    setErrorType('default');
    setSubTitleErrorMsg('');
    closeTearsheet();
  };

  const enableSaveButton = () => {
    if (JSON.stringify(initialFormDataEdit) !== JSON.stringify(formData)) {
      return checkFormValid();
    } else return false;
  };

  const checkFormValid = () => {
    if (formData.auto_discover && formData.credentials_key.id === '') {
      return false;
    }

    // if (formData.auto_discover && formData.api_end_point === '') {
    //   return false;
    // }

    if (formData?.ibm_cos_bucket_url.error) {
      return false;
    }

    return (
      formData?.name?.value !== '' &&
      !formData?.name?.error &&
      formData?.infrastructureGroup?.id !== ''
    );
  };

  const handleOnChange = (type: string, value: any) => {
    // if (value != null) {
    switch (type) {
      case 'name':
        const notUnique =
          cloudsData?.filter((cloud: any) => cloud.name.trim() === value.trim())
            .length > 0
            ? true
            : false;
        const error =
          selectedCloudData?.name === value && editCloud
            ? !notUnique
            : notUnique;
        const message = notUnique ? t('nameExists') : '';

        setFormData({
          ...formData,
          name: {
            value,
            error: error,
            errorMessage: message,
          },
        });
        break;
      case 'infrastructureGroup':
        setFormData({
          ...formData,
          infrastructureGroup: {
            id: value?.resource_id ?? '',
            name: value?.name ?? '',
          },
        });
        break;
      case 'credentials_key':
        setFormData({
          ...formData,
          credentials_key: {
            id: value?.name ?? '',
            name: value?.name ?? '',
          },
        });
        break;
      case 'ibm_cos_bucket_url':
        const regExp = /^cos:\/\//;
        const regexPathTest = value ? regExp.test(value.trim()) : true;
        const regexErrorMessage = !regexPathTest
          ? t('addDetails.ibm_cos_bucket_url.errorText')
          : '';

        setFormData({
          ...formData,
          ibm_cos_bucket_url: {
            value,
            error: !regexPathTest,
            errorMessage: regexErrorMessage,
          },
        });
        break;
      default:
        setFormData({
          ...formData,
          [type]: value,
        });
    }
    // }
  };

  const fetchSecrets = async () => {
    try {
      setLoading(true);
      const response = await getSecrets();
      if (response?.secrets.length) {
        // setIsSecretsAvailable(true);
        setSecretsData(response);
        const secretConditionalValues = getConditionalSecrets(
          response,
          selectedCloud && selectedCloud.code
        );
        setSecrets(secretConditionalValues);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onMountStep = () => {
    const secretConditionalValues = getConditionalSecrets(
      secretsData,
      selectedCloud && selectedCloud.code
    );
    setSecrets(secretConditionalValues);
  };

  const getSelectedCloud = () => {
    const selectedCloud = cloudTypes?.find(
      (cloud: CloudType) => cloud.code === selectedCloudData?.type
    );
    selectedCloud ? setSelectedCloud(selectedCloud) : setSelectedCloud(null);
  };

  useEffect(() => {
    if (open) {
      fetchSecrets();
      if (groups && !editCloud) {
        const defaultResourceGroup = groups?.find(
          (rg: ResourceGroup) => rg?.resource_id === DEFAULT_INFRASTRUCTURE_ID
        );
        setFormData({
          ...formData,
          infrastructureGroup: {
            id: defaultResourceGroup?.resource_id ?? '',
            name: defaultResourceGroup?.name ?? '',
          },
        });
      }
    }
  }, [groups, open, selectedCloud]);

  useEffect(() => {
    if (editCloud && selectedCloudData) {
      const resourceGroupData = groups?.find(
        (item: ResourceGroup) =>
          item.resource_id === selectedCloudData.resource_group_id
      );
      const editCloudFormData = {
        ...selectedCloudData,
        name: {
          value: selectedCloudData?.name,
          error: false,
          errorMessage: '',
        },
        description: selectedCloudData?.description,
        auto_discover: selectedCloudData?.auto_discover,
        // the key in the secrets manager for the credentials to this node/cluster
        credentials_key: {
          id: selectedCloudData?.credentials_key || '',
          name: selectedCloudData?.credentials_key || '',
        },
        api_end_point: '',
        infrastructureGroup: {
          id: resourceGroupData?.resource_id || '',
          name: resourceGroupData?.name || '',
        },
        ibm_cos_bucket_url: {
          value: selectedCloudData?.ibm_cos_bucket_url ?? '',
          error: false,
          errorMessage: '',
        },
      };
      setInitialFormDataEdit(editCloudFormData);
      setFormData(editCloudFormData);
    }
    getSelectedCloud();
  }, [editCloud, selectedCloudData, open, cloudTypes]);

  const openSecretRegister = async () => {
    setOpenRegisterSecret(true);
  };

  return (
    <div>
      {editCloud ? (
        <WideTearsheet
          title={t('editCloud.title')}
          description={t('editCloud.description')}
          open={open}
          actions={[
            {
              kind: 'primary',
              label: t('editCloud.submitButtonText'),
              onClick: () => handleSubmit(),
              disabled: !enableSaveButton(),
              loading: submitLoading,
            },
            {
              kind: 'secondary',
              label: t('cancelButtonText'),
              onClick: () => handleClose(),
            },
          ]}
        >
          <EditCloudDetails
            selectedCloud={{
              ...selectedCloudData,
              providerName: selectedCloud?.name,
            }}
            formData={formData}
            groups={groups ?? []}
            onChange={handleOnChange}
            formValid={checkFormValid()}
            error={error}
            errorType={errorType}
            onCloseError={() => {
              setError(false);
              setErrorType('default');
              setSubTitleErrorMsg('');
              return true;
            }}
            secrets={secrets}
            showAutoDiscover={
              selectedCloud ? selectedCloud?.is_discoverable : true
            }
            onMount={onMountStep}
            onRegisterSecret={() => openSecretRegister()}
            openRegisterSecret={openRegisterSecret}
            customErrorMsg={subTitleErrorMsg}
          />
        </WideTearsheet>
      ) : (
        <MultiStepTearsheet
          className={`register-cloud-tearsheet ${
            onInitialStep ? `` : 'secrets-step'
          }`}
          submitButtonText={t('submitButtonText')}
          cancelButtonText={t('cancelButtonText')}
          backButtonText={t('backButtonText')}
          nextButtonText={
            nextButtonText ? t('nextButtonText') : t('getStarted')
          }
          description={t('description')}
          title={t('title')}
          open={open}
          onClose={handleClose}
          onRequestSubmit={handleSubmit}
        >
          <CloudSelect
            onNext={() => {
              if (notIncludeIntroStep) {
                trackButtonClicked(
                  analyticsData['Cloud'].events.cloudTypeSelected.props,
                  analyticsData['Cloud'].events.cloudTypeSelected.event
                );
                setOnInitialStep(true);
              }
            }}
            onMount={() => {
              setError(false);
              setNextButtonText(true);
              if (notIncludeIntroStep) {
                setOnInitialStep(false);
              }
            }}
            selectedCloud={selectedCloud}
            onSelect={(cloud: any) => {
              setSelectedCloud(cloud);
              if (selectedCloud && selectedCloud.code !== cloud.code) {
                setFormData(defaultFormData);
              }
            }}
            cloudTypes={cloudTypes ?? []}
            isFetchingCloudTypes={isFetchingCloudTypes}
          />
          <AddDetails
            selectedCloud={selectedCloud}
            formData={formData}
            groups={groups ?? []}
            onChange={handleOnChange}
            formValid={checkFormValid()}
            error={error}
            errorType={errorType}
            onCloseError={() => {
              setError(false);
              setErrorType('default');
              setSubTitleErrorMsg('');
              return true;
            }}
            secrets={secrets}
            showAutoDiscover={
              selectedCloud ? selectedCloud?.is_discoverable : true
            }
            onMount={onMountStep}
            onRegisterSecret={() => openSecretRegister()}
            secretLoading={loading}
            openRegisterSecret={openRegisterSecret}
            customErrorMsg={subTitleErrorMsg}
          />
        </MultiStepTearsheet>
      )}
      <RegisterSecret
        open={openRegisterSecret}
        mode='ADD'
        onClose={() => setOpenRegisterSecret(false)}
        onSecretCreate={() => {
          fetchSecrets();
          setOpenRegisterSecret(false);
        }}
        secrets={secrets}
      />
    </div>
  );
};

export default RegisterCloud;
