import { axiosInstance } from './api';
import { MCNM_NETWORK_API_URL } from './config';

export async function getNetworkSegments() {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/networksegment`,
    });

    return response.data.network_segments;
  } catch (error) {
    throw error;
  }
}
