import React from 'react';
import {
  InlineNotification,
  InlineNotificationProps,
} from 'carbon-components-react';
import './Notification.scss';

const Notification = (props: InlineNotificationProps) => {
  const { kind, subtitle, title, onClose, hideCloseButton = false } = props;

  return (
    <div className='inline-notification-container'>
      <InlineNotification
        className={`notification ${kind}`}
        kind={kind}
        subtitle={subtitle}
        title={title}
        onClose={onClose}
        hideCloseButton={hideCloseButton}
      ></InlineNotification>
    </div>
  );
};

export default Notification;
