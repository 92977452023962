import { useQuery } from '@tanstack/react-query';
import { getAllCloudNamespaces } from '../controllers/namespace';
import { QueryOptions, queryDefaultOptions } from './queryDefaults';
import { QueryKeys, VisibilityFlags } from '../lib/enums';
import { DeploymentEnvironment } from '../models/master';

export const useCloudNamespaces = (
  cloudId: string,
  deploymentEnvironments: DeploymentEnvironment[] | null,
  view: string = VisibilityFlags.ALL,
  options?: QueryOptions
) => {
  return useQuery({
    queryKey: [QueryKeys.CLOUD_NAMESPACES, cloudId, view],
    queryFn: () => getAllCloudNamespaces(cloudId, deploymentEnvironments, view),
    ...queryDefaultOptions,
    ...options,
  });
};
