import React, { useState } from 'react';
import { CreateTearsheetStep } from '@carbon/ibm-products';
import { Column, Row, Button, ComboBox } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import './AssignRoles.scss';
import { Add16, TrashCan16 } from '@carbon/icons-react';
import { Role } from '../../../../models/master';

interface Props {
  rolesList: Role[] | null;
  rolesAdded: Role[];
  addRole: (e: any) => void;
  removeRole: (index: number, e: any) => void;
  loading: boolean;
}

const AssignRoles: React.FC<Props> = ({
  rolesList,
  rolesAdded,
  addRole,
  removeRole,
  loading,
}) => {
  const { t } = useTranslation('identity');
  const [selectedRole, setSelectedRole] = useState(null);

  const filterRolesList = (rolesList: Role[]) => {
    const rolesAddedData = rolesAdded?.map(
      (role: { resource_id: string }) => role?.resource_id
    );
    return rolesList.filter(
      (role: Role) =>
        !rolesAddedData.includes(role?.resource_id) &&
        role?.resource_id !== 'HubAdmin'
    );
  };

  return (
    <CreateTearsheetStep
      className='assign-roles'
      title={t('createIdentityPage.addRoles.title')}
      subtitle={t('createIdentityPage.addRoles.subtitle')}
      disableSubmit={!(Array.isArray(rolesAdded) && rolesAdded.length > 0)}
      isSubmitting={loading}
      onNext={() => {}}
    >
      <Row className='row row-add'>
        <Column md={6}>
          <ComboBox
            id='rolesListDropdown'
            name='roles'
            className='role-list'
            selectedItem={selectedRole}
            onChange={(data: any) => {
              setSelectedRole(data?.selectedItem);
            }}
            items={filterRolesList(rolesList ?? [])}
            itemToString={(item: { name: any }) => (item ? item.name : '')}
            titleText={t('createIdentityPage.addRoles.rolesLabel')}
            placeholder={t('createIdentityPage.addRoles.rolePlaceholder')}
            size='lg'
          />
        </Column>
        <Column md={1}>
          <div className='button add-role-btn'>
            <Button
              kind='secondary'
              size={'default'}
              disabled={!selectedRole}
              renderIcon={Add16}
              onClick={
                selectedRole
                  ? () => {
                      addRole(selectedRole);
                      setSelectedRole(null);
                    }
                  : () => {}
              }
            >
              {t('createIdentityPage.addRoles.addbtnText')}
            </Button>
          </div>
        </Column>
      </Row>
      {Array.isArray(rolesAdded) && rolesAdded.length > 0 && (
        <div className='roles-list'>
          <Row className='role-header'>
            <Column md={6}>
              <div className='role-header-title'>
                {t('createIdentityPage.addRoles.roleName')}
              </div>
            </Column>
          </Row>
          {rolesAdded.map((role, index) => (
            <Row className='role-data'>
              <Column md={6}>
                <div className='role-row'>
                  <div className='roleName'>{role.name}</div>
                  <div className='remove-button-container'>
                    <Button
                      kind='ghost'
                      size='small'
                      onClick={() => removeRole(index, role)}
                      iconDescription=' '
                      renderIcon={TrashCan16}
                    />
                  </div>
                </div>
              </Column>
            </Row>
          ))}
        </div>
      )}
    </CreateTearsheetStep>
  );
};

export default AssignRoles;
