import React from 'react';
import { Button } from 'carbon-components-react';
import './IconButton.scss';

const IconButton: React.FC<{
  iconDescription: string;
  icon: React.ReactElement;
}> = ({ iconDescription, icon }) => {
  return (
    <div className='icon-button-container'>
      <Button hasIconOnly iconDescription={iconDescription}>
        {icon}
      </Button>
    </div>
  );
};

export default IconButton;
