import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import NarrowTearsheetComponent from '../../../components/NarrowTearsheet/NarrowTearsheet';
import InlineNotification from '../../../components/Notifications/Inline/Notification';
import { NotificationContext } from '../../../components/Notifications/Context/NotificationProvider';

import { removeExtraSpace } from '../../../lib/utils';
import { AxiosError } from 'axios';
import AddRolesForm from './AddRolesForm';
import { getRoles, updateRole } from '../../../controllers/roleApis.js';
import {
  addIdentityRole,
  getIdentityRoles,
} from '../../../controllers/identityApis.js';
import { Role } from '../../../models/master.js';

import './AddRoles.scss';

const defaultFormValue = {
  roleName: {
    value: '',
    error: false,
    errorMessage: '',
  },
  labels: {
    value: [],
  },
};

const requiredFields = ['roleName'];
interface Props {
  open: boolean;
  onClose: () => void;
  addRoletoIdentity: () => void;
  identityId: string | null;
}
interface formData {
  roleName: {
    value: string;
    error: boolean;
    errorMessage: string;
  };
  labels: {
    value: string[];
  };
}

interface roleIdNameLabels {
  role_id: string;
  name: string;
  labels: string[];
}

const AddRoles: React.FC<Props> = ({
  open,
  onClose,
  addRoletoIdentity,
  identityId,
}) => {
  const { t } = useTranslation('addRolesIdentity');
  const notification = useContext(NotificationContext);

  const [formData, setFormData] = useState<formData>(defaultFormValue);
  const [showFailNotification, toggleFailNotification] = useState(false);
  const [changed, setChanged] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [roles, setRoles] = useState<Role[] | []>([]);
  const [roleIdNameLabels, setRoleIdNameLabels] = useState<roleIdNameLabels[]>(
    []
  );
  const [roleSelectedValue, setRoleSelectedValue] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const fetchRoles = async () => {
    try {
      const allRoles = await getRoles();
      const identityRoles = await getIdentityRoles(identityId);
      const identityRolesNames = identityRoles?.map((role: any) => role.name);

      const filteredRoles = allRoles?.roles?.filter(
        (role: any) =>
          !identityRolesNames.includes(role.name) && role.name !== 'HubAdmin'
      );

      const filteredRolesNames = filteredRoles?.map((role: any) => role.name);
      setRoles(filteredRolesNames);

      const roleIdNamesLabelsObj = filteredRoles.map((item: any) => ({
        role_id: item.resource_id,
        name: item.name,
        labels: item.labels,
      }));
      setRoleIdNameLabels(roleIdNamesLabelsObj);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const isFormValid = () => {
    for (const field of requiredFields) {
      const value = formData[field as keyof typeof formData].value;

      const trimmedValue = typeof value === 'string' ? value.trim() : value;
      if (checkFieldValidation(field, trimmedValue)) {
        return false;
      }
    }
    return true;
  };

  const cancelForm = () => {
    onClose();
    setFormData(defaultFormValue);
    toggleFailNotification(false);
    setAuthError(false);
  };

  const checkFieldValidation = (name: string, value: any) => {
    let errorMessage = '';
    switch (name) {
      case 'roleName':
        errorMessage = !value ? t('validation.roleName.required') : '';
        break;
    }
    return errorMessage;
  };

  const handleOnChangeName = (name: string, value: string) => {
    if (!changed) setChanged(true);
    const errorMessage = checkFieldValidation(name, value);

    //Retain selected role name from model combo_box
    setRoleSelectedValue(value);

    //Filter based on selected name from combo_box
    const filterRoleIdByNameLabel = roleIdNameLabels.find(
      e => e.name === value
    );

    const existedRoleLabels = filterRoleIdByNameLabel
      ? filterRoleIdByNameLabel.labels
      : '';

    //Setting existing values to form before adding role
    const newFormValue = {
      roleName: {
        value: value,
        error: !!errorMessage,
        errorMessage: '',
      },
      labels: {
        value: existedRoleLabels ? existedRoleLabels : [],
      },
    };
    setFormData(newFormValue);
  };

  const handleOnChangeLabel = (name: string, value: any) => {
    if (!changed) setChanged(true);
    const errorMessage = checkFieldValidation(name, value);
    setFormData((prevState: any) => ({
      ...prevState,
      [name]: {
        value,
        error: !!errorMessage,
        errorMessage,
      },
    }));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const role_FormData = {
        name: removeExtraSpace(formData?.roleName?.value),
        labels: formData?.labels?.value,
      };
      //Get role_id, name and labels by mapping selected role name from add role model
      const filterRoleIdByName = roleIdNameLabels.find(
        e => e.name === roleSelectedValue
      );

      const roleIdIdentity = filterRoleIdByName
        ? (filterRoleIdByName.role_id as string)
        : '';

      // Updating role labels
      try {
        await updateRole(roleIdIdentity, role_FormData);
      } catch (error) {
        console.error(error);
      }

      // Adding role to existing identity by role_id
      await addIdentityRole(identityId, roleIdIdentity);

      notification.onTrigger('TOAST', {
        title: t('successNotification.title', {
          roleName: roleSelectedValue,
        }),
        subtitle: t('successNotification.subtitle'),
      });

      setChanged(false);
      addRoletoIdentity();
      setFormData(defaultFormValue);
      toggleFailNotification(false);
      setAuthError(false);
      fetchRoles();
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        setAuthError(true);
      }
      console.log(error);
      toggleFailNotification(true);
      return Promise.reject(() => console.log(error));
    } finally {
      setLoading(false);
    }
  };

  const handleErrorBarClose = () => {
    toggleFailNotification(false);
    setAuthError(false);
  };

  return (
    <NarrowTearsheetComponent
      className='add-roleIdentity-tearsheet'
      title={t('titleRoleIdentityForm')}
      description={t('roleIdentityFormDescription')}
      open={open}
      actions={[
        {
          kind: 'primary',
          label: t('submitButtonText'),
          onClick: () => handleSubmit(),
          disabled: !isFormValid() || !changed,
          loading: loading,
        },
        {
          kind: 'secondary',
          label: t('cancelButtonText'),
          onClick: () => {
            cancelForm();
            toggleFailNotification(false);
            setChanged(false);
            setAuthError(false);
            setLoading(false);
          },
        },
      ]}
    >
      {showFailNotification && (
        <InlineNotification
          onClose={() => handleErrorBarClose() as any}
          kind={'error'}
          title={
            authError
              ? (t('failureNotification.authTitle') as string)
              : (t('failureNotification.title') as string)
          }
          subtitle={
            authError
              ? (t('failureNotification.authSubtitle') as string)
              : (t('failureNotification.subtitle') as string)
          }
        />
      )}
      {open && <AddRolesForm
        formData={formData}
        onChangeName={handleOnChangeName}
        onchangeLabel={handleOnChangeLabel}
        rolesData={roles}
      />}
    </NarrowTearsheetComponent>
  );
};

export default AddRoles;
