import React from 'react';
import { useTranslation } from 'react-i18next';
//import { useNavigate } from 'react-router-dom';
import { TooltipDefinition } from 'carbon-components-react';
import { Link } from 'react-router-dom';
import HealthStatus from '../../../components/GatewayStatus/HealthStatus';
import ProceduralStatus from '../../../components/GatewayStatus/ProceduralStatus';
import {
  HealthStatusType,
  ProceduralStatusType,
} from '../../../components/GatewayStatus/config';
import GenericStatusField from '../../../components/GenericStatusField/GenericStatusField';
import GenericTableWithFilters from '../../../components/GenericTableWithFilters/GenericTableWithFilters';
import GenericTruncateString from '../../../components/GenericTruncateString/GenericTruncateString';
import images from '../../../images/images.js';
import dateUtils from '../../../lib/dates';
import {
  AppliedFilter,
  GatewayCustomData,
  ResourceGroup,
} from '../../../models/master';

import './GatewaysTable.scss';
import useAnalytics from '../../../lib/useAnalytics';
import { DEFAULT_NETWORK_SEGMENT_ID } from '../../../lib/constants';

interface GatewayTableRows {
  id: any;
  gateway_id: string;
  cloud_id: string | undefined;
  location_id: string | undefined;
  name:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
  name__format: JSX.Element;
  type?: string;
  health_status: JSX.Element | undefined | string;
  version: JSX.Element | string | undefined;
  location: JSX.Element;
  resourceGroup: JSX.Element | undefined | string;
  resource_name: JSX.Element | undefined | string;
  updated_at: string;
  type_name?: JSX.Element | undefined | string;
  network_segment_name: JSX.Element | string | undefined;
}

interface Filter {
  key: string;
  type: 'multi' | 'single';
  values: string[];
}

const GatewaysTable = (props: {
  filteredDataCallback: (data: any) => void;
  filtersSelected: any;
  filtersAppliedCallback: (data: any) => void;
  filters: any;
  rows: GatewayCustomData[] | null;
  data: GatewayCustomData[] | null;
  filteredDataSet: GatewayCustomData[] | null;
  elementCount: number;
  onRefresh: () => void;
  currentPageNumber: number;
  currentPageSize: number;
  onPageChange: (pageData: any) => void;
  sortRows(arg0: unknown, direction: string): void;
  resourceGroupsList: ResourceGroup[] | null;
  leftInlineFilters?: Filter[];
  leftInlineMultiSelectFilter?: Filter[];
  visibilityFlag?: string;
  emptyStateClick: any;
  persistFilter?: boolean;
  error403Flag: boolean;
  error500Flag: boolean;
}) => {
  const { t } = useTranslation('gatewaysContainer');
  const { rows, onRefresh, emptyStateClick } = props;
  const error403Flag = props.error403Flag;
  const error500Flag = props.error500Flag;
  const { trackButtonClicked } = useAnalytics();

  const capitalizeFirstLetterInString = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const gatewayStatus = (
    status: string,
    name: string,
    version: string | number
  ) => {
    let statusWithIcon;
    statusWithIcon = (
      <span className='procedural__status'>
        <ProceduralStatus
          status={status as ProceduralStatusType}
          gatewayName={name}
          version={version}
        />
      </span>
    );
    return statusWithIcon;
  };

  const myheaders = [
    {
      key: 'name__format',
      header: t('tableHeaders.name'),
      sort: true,
      originalKey: 'name',
    },
    {
      key: 'type_name',
      header: t('tableHeaders.type'),
      sort: true,
      originalKey: 'type_name',
      style: { minWidth: '9rem' },
    },
    {
      key: 'health_status',
      header: t('tableHeaders.health'),
      sort: true,
      originalKey: 'health_status',
      style: { minWidth: '9rem' },
    },
    {
      key: 'location',
      originalKey: 'location_name',
      header: t('tableHeaders.location'),
      sort: true,
    },
    {
      key: 'network_segment_name',
      originalKey: 'network_segment_name',
      header: t('tableHeaders.networkSegment'),
      sort: true,
    },
    {
      key: 'version',
      originalKey: 'version',
      header: t('tableHeaders.version'),
      sort: true,
    },
    {
      key: 'updated_at',
      originalKey: 'updated_at',
      header: t('tableHeaders.updated_at'),
      sort: true,
    },
  ];

  const emptyStateData = {
    icon: images.noGatewayLargeIcon(),
    notFoundIcon: images.NotFoundLarge(),
    emptyStateHeader: t('emptyState.emptyContainerHeader'),
    emptyStateDescription: t('emptyState.emptyContainerDescription'),
    link: '/',
    buttonText: t('emptyState.buttonText'),
    dropdown: {
      label: t('addGateway'),
      items: [
        { id: 'edge', text: t('edgeGateway') },
        { id: 'waypoint', text: t('waypointOption') },
      ],
    },
    dropdownClick: (item: any) => {
      emptyStateClick(item);
    },
  };

  /**
   * @description - Function to format the raw data into table rows format.
   * @returns {GatewayTableRows[] | [] | null} - Returns the formatted data to be rendered as table rows.
   */

  const getResourceGroupName = (id: string) => {
    const resouceGroup =
      id &&
      Array.isArray(props.resourceGroupsList) &&
      props.resourceGroupsList.find(
        resouceGroup => resouceGroup.resource_id === id
      );

    return resouceGroup ? resouceGroup.name : '—';
  };

  const setRowsData = () => {
    let formattedRows: GatewayTableRows[] = [];
    if (error403Flag) return [];
    if (error500Flag) return [];
    if (rows && rows.length === 0) return [];

    if (rows)
      rows.map((row: GatewayCustomData, index) => {
        formattedRows.push({
          id: row.resource_id + index,
          gateway_id: row.resource_id,
          cloud_id: row.cloud_id,
          location_id: row.location_id,
          name: !!row.name ? capitalizeFirstLetterInString(row.name) : '—',
          name__format: (
            <Link
              className='no-underline-link'
              onClick={() =>
                trackButtonClicked({
                  CTA: 'Gateways, Open gateway details',
                  action: 'View the gateway details',
                })
              }
              to={`/gatewayDetails?gatewayId=${row.resource_id}`}
            >
              <TooltipDefinition
                tooltipText={t(row.type) + t('gateway')}
                direction={
                  rows.length - 1 === index ||
                  (index + 1) % props.currentPageSize === 0
                    ? 'top'
                    : 'bottom'
                }
                align='start'
              >
                {row.type === 'edge'
                  ? images.EdgeGatewayIcon()
                  : images.WaypointGatewayIcon()}
              </TooltipDefinition>
              <span className='table__gateway__name'>
                <GenericTruncateString str={row?.name} tableView={true} />
              </span>
            </Link>
          ),
          health_status: !!row?.health_status ? (
            <div className='health-status'>
              <HealthStatus
                status={row?.health_status as HealthStatusType}
                showLabel={true}
                viewEventsLink={true}
                gatewayName={row.name}
                tooltipPosition='top'
              />
            </div>
          ) : (
            '—'
          ),
          version: gatewayStatus(
            row.procedural_status,
            row.name,
            row.intended_version
          ),
          location: !row.locationPermission ? (
            <GenericStatusField status='notAuthorised'></GenericStatusField>
          ) : row.location_id ? (
            <Link
              className='no-underline-link'
              to={`/locationDetails?cloudId=${row.cloud_id}&locationId=${row.location_id}`}
            >
              <GenericTruncateString str={row.location_name} tableView={true} />
            </Link>
          ) : (
            <div>—</div>
          ),
          resourceGroup: !row.resourceGroupsPermission ? (
            <GenericStatusField status='notAuthorised' />
          ) : (
            getResourceGroupName(row.resource_group_id)
          ),
          resource_name: !row.resourceGroupsPermission ? (
            <GenericStatusField status='notAuthorised' />
          ) : !!row.resource_name ? (
            row.resource_name
          ) : (
            '—'
          ),
          network_segment_name: !row.networkSegmentPermission ? (
            <GenericStatusField status='notAuthorised'></GenericStatusField>
          ) : !!row.network_segment_name ? (
            row.network_segment_id === DEFAULT_NETWORK_SEGMENT_ID ? (
              row.network_segment_name
            ) : (
              <Link
                className='no-underline-link'
                to={`/networkSegmentDetails?nwSegId=${row.network_segment_id}`}
              >
                {row.network_segment_name}
              </Link>
            )
          ) : (
            '—'
          ),
          updated_at: !!row?.updated_at
            ? dateUtils.getUserFriendlyDate(row?.updated_at)
            : '—',
          type_name: row.subtype ? (
            t(`gatewayTypes.${row.subtype}`)
          ) : (
            <div>—</div>
          ),
        });
        return 0;
      });
    else return null;
    return formattedRows;
  };

  return (
    <GenericTableWithFilters
      id='table-with-filter-panel'
      rows={setRowsData()}
      data={props.filteredDataSet}
      headers={myheaders}
      isSortable={true}
      totalElementsCount={props.elementCount ? props.elementCount : 0}
      fullData={props.data}
      onTableRefresh={() => onRefresh()}
      filteredDataCallback={(data: GatewayTableRows[]) =>
        props.filteredDataCallback(data)
      }
      selectedFiltersVal={props.filtersSelected as any}
      setFilterApplied={(data: AppliedFilter[]) =>
        props.filtersAppliedCallback(data)
      }
      filters={props.filters}
      currentPageNumber={props.currentPageNumber}
      currentPageSize={props.currentPageSize}
      onPageChange={(pageData: any) => props.onPageChange(pageData)}
      emptyState={emptyStateData}
      sortRows={(data, sortDirection) => props.sortRows(data, sortDirection)}
      leftInlineMultiSelectFilter={props.leftInlineMultiSelectFilter}
      visibilityFlag={props.visibilityFlag}
      persistFilter
      render403Container={props.error403Flag}
      render500Container={props.error500Flag}
    />
  );
};

export default GatewaysTable;
