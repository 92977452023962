import { MCNM_TENANT_API_URL } from './config';
import { axiosInstance } from './api';

export async function getAgentList(availabilityValue = false) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_TENANT_API_URL}/gwagentnodes?only_available=${availabilityValue}`,
    });

    return response.data;
  } catch (error) {
    console.error(error);
    if (error.response.status === 403) throw error;
  }
}
