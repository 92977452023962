import { useCallback, useState } from 'react';
import useAnalyticsContext from '../components/analytics-context/useAnalyticsContext';
import { isFunctionalCookieAccepted } from './utils';
import sha256 from 'crypto-js/sha256';

const useAnalytics = () => {
  const { analytics } = useAnalyticsContext();
  // const [isCookieAccepted, setIsCookieAccepted] = useState(
  //   getCookie('notice_preferences') === '0:' ? false : true
  // );
  const selectedAccount = window.localStorage.getItem('SELECTED_ACCOUNT');
  const auth: any = window?.localStorage?.getItem(
    `ACCOUNT_DETAILS_${selectedAccount}`
  );
  const tenantId = JSON.parse(auth)?.tenant_id;
  const userId = JSON.parse(auth)?.user_id ?? '';
  const user_uuid = JSON.parse(auth)?.identity_uuid;

  const resetAnalytics = useCallback(() => {
    analytics.reset();
  }, [analytics]);

  const pageViewed = useCallback(
    async (pageName: string) => {
      const hashedEmail = sha256(userId).toString();
      if (isFunctionalCookieAccepted()) {
        await identifyUser(user_uuid ?? `hybridCloudMesh-${hashedEmail}`);
      } else {
        await identifyUser(`hybridCloudMesh-${hashedEmail}`);
      }
      analytics.page(pageName, {
        UT30: '30ASC',
        productCode: 'WW1314',
        productCodeType: 'WWPC',
        productTitle: 'IBM Hybrid Cloud Mesh',
        text: 'Product CTA Clicked',
        instanceId: tenantId ?? '',
      });
    },
    [analytics]
  );

  const trackButtonClicked = useCallback(
    async (data: any, event: string = 'CTA Clicked') => {
      const hashedEmail = sha256(userId).toString();
      if (isFunctionalCookieAccepted()) {
        await identifyUser(user_uuid ?? `hybridCloudMesh-${hashedEmail}`);
        // setIsCookieAccepted(true);
      } else {
        await identifyUser(`hybridCloudMesh-${hashedEmail}`);
        // setIsCookieAccepted(false);
        // }
      }
      analytics.track(event, {
        ...data,
        UT30: '30ASC',
        productCode: 'WW1314',
        productCodeType: 'WWPC',
        productTitle: 'IBM Hybrid Cloud Mesh',
        text: 'Product CTA Clicked',
        instanceId: tenantId ?? '',
      });
    },
    [analytics]
  );

  const identifyUser = useCallback(
    (userId: string) => {
      analytics.identify(userId, {
        UT30: '30ASC',
        productCode: 'WW1314',
        productCodeType: 'WWPC',
        productTitle: 'IBM Hybrid Cloud Mesh',
        text: 'Product CTA Clicked',
        instanceId: tenantId ?? '',
      });
    },
    [analytics]
  );

  const groupUser = useCallback(
    (data: any) => {
      analytics.group(`${data} Group ID`, { data });
    },
    [analytics]
  );

  return {
    pageViewed,
    trackButtonClicked,
    identifyUser,
    groupUser,
    resetAnalytics,
  };
};

export default useAnalytics;
