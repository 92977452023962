import { useTranslation } from 'react-i18next';
import GenericTableWithFilters from '../../components/GenericTableWithFilters/GenericTableWithFilters';
import { APIKey } from '../../models/master';
import images from '../../images/images.js';
import './APIKeysTable.scss';
import { PasswordInput, CopyButton, Button } from 'carbon-components-react';
import sortData from '../../lib/tableSort';

interface APIKeyTableRows {
  id: string;
  name: string;
  APIKeyJsx: JSX.Element;
}

const APIKeysTable = (props: {
  loading: boolean;
  filteredDataCallback: (data: any) => void;
  filtersSelected: any;
  filtersAppliedCallback: (data: any) => void;
  filters: any;
  data: APIKey[] | null;
  filteredDataSet: APIKey[] | null;
  elementCount: number;
  onRefresh: () => void;
  currentPageNumber: number;
  currentPageSize: number;
  onPageChange: (pageData: any) => void;
  sortRows(arg0: unknown, direction: string): void;
  sortKey: string;
  sortDirection: 'ASC' | 'DESC' | 'NONE';
  onDeleteKey: (key: string) => void;
  error500Flag: boolean;
}) => {
  const {
    loading,
    data,
    filtersSelected,
    sortKey,
    sortDirection,
    filteredDataSet,
    filters,
    elementCount,
    currentPageNumber,
    currentPageSize,
    onRefresh,
    filteredDataCallback,
    filtersAppliedCallback,
    onPageChange,
    sortRows,
    onDeleteKey,
  } = props;

  const { t } = useTranslation('apiKeyManager');

  const uniqueId = () => {
    return Math.random().toString(36);
  };

  const myheaders = [
    {
      key: 'name',
      header: t('keys.tableHeaders.name'),
      sort: 'sortByName',
      originalKey: 'name',
    },
    {
      key: 'APIKeyJsx',
      originalKey: 'apiKey',
      header: t('keys.tableHeaders.APIKey'),
    },
  ];

  const emptyStateData = {
    icon: images.noAPIKeysIcon(),
    notFoundIcon: images.noAPIKeysIcon(),
    emptyStateHeader: t('emptyState.emptyContainerHeader'),
    emptyStateDescription: t('emptyState.emptyContainerDescription'),
  };

  /**
   * @description - Function to format the raw data into table rows format.
   * @returns {APIKeyTableRows[] | [] | null} - Returns the formatted data to be rendered as table rows.
   */

  const hideKey = (key: string) => {
    return (
      <div className='key-cell'>
        <PasswordInput
          className='key-cell'
          value={key}
          labelText={''}
          id={uniqueId()}
          showPasswordLabel={t('form.showAPIKeyLabel')}
          hidePasswordLabel={t('form.hideAPIKeyLabel')}
          tooltipPosition='left'
        />
        <CopyButton
          onClick={() => {
            navigator.clipboard.writeText(key);
          }}
          feedback={'Copied!'}
          feedbackTimeout={2000}
        />
        <Button kind='danger--ghost' onClick={() => onDeleteKey(key)}>
          {t('deleteKeyButtonText')}
        </Button>
      </div>
    );
  };

  const getFormattedRows = (items: any) => {
    const formattedData: APIKeyTableRows[] = [];

    if (props.error500Flag) return [];

    if (Array.isArray(items)) {
      for (const item of items) {
        formattedData.push({
          id: item.apiKey,
          name: !!item.name ? item.name : '—',
          APIKeyJsx: hideKey(item.apiKey),
        });
      }
    }

    return formattedData;
  };

  return (
    <div className='apikeys-table'>
      <GenericTableWithFilters
        id='api-key-table-with-filter-panel'
        rows={
          loading
            ? null
            : filtersSelected.length > 0
            ? sortData(
                getFormattedRows(filteredDataSet as any),
                sortKey,
                sortDirection
              )
            : sortData(getFormattedRows(data), sortKey, sortDirection)
        }
        data={
          data ? (filtersSelected.length > 0 ? filteredDataSet : data) : null
        }
        headers={myheaders}
        isSortable={true}
        totalElementsCount={elementCount ? elementCount : 0}
        fullData={data}
        onTableRefresh={onRefresh}
        filteredDataCallback={filteredDataCallback}
        selectedFiltersVal={filtersSelected}
        setFilterApplied={filtersAppliedCallback}
        filters={filters}
        currentPageNumber={currentPageNumber}
        currentPageSize={currentPageSize}
        onPageChange={onPageChange}
        emptyState={emptyStateData}
        sortRows={sortRows}
        render500Container={props.error500Flag}
      />
    </div>
  );
};

export default APIKeysTable;
