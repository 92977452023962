import moment from 'moment';
import {
  CustomLink,
  CustomLinkType,
  Location,
  LocationsNode,
  Resouce,
  ResouceType,
} from '../types';
import LocationSvg from '../icons/location.svg';
import ClusterSvg from '../icons/cluster.svg';
import NodeSvg from '../icons/node.svg';
import VpcSvg from '../icons/vpc.svg';
import PartitionSvg from '../icons/partition.svg';
import ApplicationSvg from '../icons/application.svg';
import ServiceSvg from '../icons/service.svg';
import TransitGatewaySvg from '../icons/transitGateway.svg';
import EdgeGatewaySvg from '../icons/edgeGateway.svg';
import WpGatewaySvg from '../icons/waypointGateway.svg';
import MajorSvg from '../icons/major.svg';
import OperationalSvg from '../icons/pending.svg';
import CriticalSvg from '../icons/critical.svg';
import { MetricsQueryDirection } from '../../../lib/enums';

export const DEFAULT_NODE_RADIUS = 16;
export const DEFAULT_STEP = '24h';
export const SCALE_FACTOR = 1.5;
export const GROUP_COLOR = 'rgba(232, 218, 255, 1)';

export const getNodeConfig = (
  type: 'location' | ResouceType,
  data?: Location | Resouce
) => {
  const config = {
    location: {
      icon: LocationSvg,
      disabledIcon: null,
      class: 'location-svg',
      labelKey: 'name',
      radius: 16,
      height: '16',
      width: '16',
      x: -8,
      y: -10,
      labelDy: 35,
    },
    deployment_env: {
      icon: {
        cluster: ClusterSvg,
        node: NodeSvg,
        vpc: VpcSvg,
      },
      class: data?.infra_only
        ? 'infraonly deployment-env-svg'
        : 'deployment-env-svg',
      labelKey: 'name',
      radius: 16,
      height: '16',
      width: '16',
      x: -8,
      y: -8,
      labelDy: 35,
    },
    partition: {
      icon: PartitionSvg,
      class: 'partition-svg',
      labelKey: 'name',
      radius: 16,
      height: '16',
      width: '16',
      x: -8,
      y: -8,
      labelDy: 35,
    },
    application: {
      icon: ApplicationSvg,
      class: 'application-svg',
      labelKey: 'name',
      radius: 16,
      height: '16',
      width: '16',
      x: -8,
      y: -8,
      labelDy: 35,
    },
    service: {
      icon: ServiceSvg,
      class: 'service-svg',
      labelKey: 'name',
      radius: 16,
      height: '16',
      width: '16',
      x: -8,
      y: -8,
      labelDy: 30,
    },
    gateway: {
      icon: {
        edge: EdgeGatewaySvg,
        waypoint: WpGatewaySvg,
        errored: MajorSvg,
        deploying: OperationalSvg,
        operational: OperationalSvg,
        critical: CriticalSvg,
      },
      class: 'gateway-svg',
      labelKey: 'name',
      radius: 16,
      height: '16',
      width: '16',
      x: -7,
      y: -8,
      labelDy: 30,
    },
  };

  return config[type];
};

export const getLineConfig = (
  edge:
    | {
        source: string;
        target: string;
        type?: CustomLinkType;
      }
    | CustomLink<LocationsNode | Resouce>,
  focusedEdge: string
) => {
  const { source, target } = edge;
  let sourceId = '';
  let targetId = '';

  if (typeof source === 'string' && typeof target === 'string') {
    sourceId = source;
    targetId = target;
  }

  if (typeof source === 'object' && typeof target === 'object') {
    sourceId = (edge as d3.HierarchyLink<Location | Resouce>)?.source?.data
      ?.uniqueId;
    targetId = (edge as d3.HierarchyLink<Location | Resouce>)?.target?.data
      ?.uniqueId;
  }

  const key = sourceId + '-' + targetId;
  const focused = focusedEdge === key ? ' focus' : '';
  const focusedHitSpotConn = focusedEdge === key ? '-focus' : '';

  switch (edge.type) {
    case 'DATAFLOW': {
      // If it is a location to location data flow then we need to render nested lines
      if (sourceId?.includes('location') && targetId?.includes('location')) {
        return 'nested-line' + focused;
      }

      return 'connection-line' + focusedHitSpotConn;
    }
    case 'DATAFLOW_HITSPOT': {
      // If it is a location to location data flow then we need to render nested lines
      if (sourceId?.includes('location') && targetId?.includes('location')) {
        return 'nested-line' + focused;
      }

      return 'connection-line' + focused;
    }
    case 'GATEWAY': {
      return 'gateway-line' + focusedHitSpotConn;
    }
    case 'GATEWAY_HITSPOT': {
      return 'gateway-line' + focused;
    }
    case 'POLICY': {
      return 'policy-line' + focused;
    }
    case 'NESTED': {
      return 'nested-line';
    }
    case 'DATAFLOW-NESTED': {
      return 'nested-line';
    }
    case 'POLICY-NESTED': {
      return 'nested-line';
    }
    case 'GATEWAY-NESTED': {
      return 'nested-line';
    }
    default: {
      return 'relation-line' + focused;
    }
  }
};

export const getTunnelAverageLatencyQuery = (
  gwId: string,
  remoteGwId: number | string
) => {
  const start = moment().subtract(1, 'd').toISOString();
  const end = moment().toISOString();
  const step = 86400;

  return `gw_rtt_avg_us{gw_id="${gwId}",remote_gw_id="${remoteGwId}"}&start=${start}&end=${end}&step=${step}`;
};

export const getAppServiceAverageLatencyQuery = (
  appId: string,
  serviceId: string,
  appNamespace: string,
  svcNamespace: string
) => {
  const dir = MetricsQueryDirection.INCOMING;
  const step = DEFAULT_STEP;
  return (
    `avg by (app_id, app_cluster, app_namespace, app_network_segment, svc_id, svc_cluster, svc_namespace, svc_network_segment, direction, tenant_id, app_resource_group_id, svc_resource_group_id)` +
    `(` +
    `avg_over_time(flow_latency_microseconds_sum{app_id="${appId}",svc_id="${serviceId}",app_namespace="${appNamespace}",svc_namespace="${svcNamespace}",direction="${dir}"}[${step}])` +
    '/' +
    `avg_over_time(flow_latency_microseconds_count{app_id="${appId}",svc_id="${serviceId}",app_namespace="${appNamespace}",svc_namespace="${svcNamespace}",direction="${dir}"}[${step}]))`
  );
};
