import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'carbon-components-react';

import NetworkSegmentForm from './NetworkSegmentForm';
import {
  NetworkSegmentData,
  NetworkSegments,
  ResourceGroup,
} from '../../../models/master';
import {
  addNetworkSegments,
  updateNetworkSegments,
} from '../../../controllers/networksegmentsApi';
import { removeExtraSpace } from '../../../lib/utils';
import { NotificationContext } from '../../../components/Notifications/Context/NotificationProvider';
import InlineNotification from '../../../components/Notifications/Inline/Notification';
import { gatewaySet } from '../../NetworkSegmentDetails/config';
import { AxiosError } from 'axios';
import debounce from 'lodash/debounce';

import './CreateNetworkSegment.scss';

export interface FormData {
  name: {
    value: string;
    error?: boolean;
    errorMessage?: string;
  };
  gatewaySet: {
    name: string;
    value: string;
  };
  infrastructureGroup: {
    value: ResourceGroup | null;
    error?: boolean;
    errorMessage?: string;
  };
  description: {
    value: string;
    error?: boolean;
    errorMessage?: string;
  };
  labels: {
    value: string[];
    error?: boolean;
    errorMessage: string;
  };
}

const defaultFormValue = {
  name: {
    value: '',
    error: false,
    errorMessage: '',
  },
  gatewaySet: {
    name: 'Red Hat Service Interconnect',
    value: 'RHSI',
  },

  infrastructureGroup: {
    value: null,
    error: false,
    errorMessage: '',
  },
  labels: {
    value: [],
    error: false,
    errorMessage: '',
  },
  description: {
    value: '',
    error: false,
    errorMessage: '',
  },
};

const requiredFields = ['name', 'infrastructureGroup'];

interface Props {
  open: boolean;
  onClose: () => void;
  networkSegmentData?: NetworkSegmentData | null;
  networkSegments: NetworkSegments[] | null;
  groups: ResourceGroup[] | null;
  actionType: 'ADD' | 'EDIT';
  updateCallBack?: (networkSegmentData: NetworkSegmentData) => void;
  className: 'create-network-segment' | 'edit-network-segment';
  refreshData: (id?: string | undefined) => void;
  fromPage?: string;
}

const CreateNetworkSegment: React.FC<Props> = ({
  open,
  onClose,
  networkSegmentData,
  networkSegments,
  groups,
  refreshData,
  actionType,
  updateCallBack,
  className,
  fromPage = '',
}) => {
  const [formData, setFormData] = useState<FormData>(defaultFormValue);
  const [networkSegmentLoading, setNetworkSegmentLoading] = useState(false);
  const [showErrorNotification, toggleErrorNotification] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [subTitleErrorMsg, setSubTitleErrorMsg] = useState('');
  const [errorType, setErrorType] = useState('default');

  const notification = useContext(NotificationContext);

  const { t } = useTranslation('createNetworkSegment');

  useEffect(() => {
    setEditFormData();
  }, [actionType, networkSegmentData, groups]);

  const checkFieldValidation = (name: string, value: any) => {
    let errorMessage = '';
    switch (name) {
      case 'name':
        const valueEmpty = value === '';
        let notUnique;
        notUnique =
          networkSegments && isUniqueName(actionType, networkSegments, value);
        const error = valueEmpty || notUnique;
        const message = valueEmpty
          ? t('validation.name.required')
          : t('validation.name.notUnique');
        errorMessage = error ? message : '';
        break;
      case 'infrastructureGroup':
        errorMessage = !value
          ? t('validation.infrastructureGroup.required')
          : '';
        break;
    }

    return errorMessage;
  };
  const isUniqueName = (
    actionType: string,
    networkSegments: NetworkSegments[],
    value: string
  ) => {
    let notUnique = false;
    if (actionType === 'EDIT' && networkSegments && value) {
      notUnique =
        networkSegments?.filter(
          (networkSegment: NetworkSegments) =>
            networkSegment.name.trim() === value.trim() &&
            networkSegment.name.trim() !== networkSegmentData?.name.trim()
        ).length > 0
          ? true
          : false;
      return notUnique;
    } else if (actionType === 'ADD' && networkSegments) {
      notUnique =
        networkSegments &&
        networkSegments?.filter(
          (networkSegment: NetworkSegments) =>
            networkSegment.name.trim() === value.trim()
        ).length > 0
          ? true
          : false;
      return notUnique;
    }
    return notUnique;
  };

  const validateForm = () => {
    for (const field of requiredFields) {
      const value = (formData as any)[field].value;
      const trimmedValue = typeof value === 'string' ? value.trim() : value;
      if (checkFieldValidation(field, trimmedValue)) {
        return false;
      }
    }
    return true;
  };

  const isFormValid = () => {
    const isValid = validateForm();
    if (actionType === 'EDIT') {
      return editValidation() && isValid;
    }
    return isValid;
  };

  const editValidation = () => {
    if (
      networkSegmentData?.name === formData?.name?.value &&
      networkSegmentData?.compatibility_set === formData?.gatewaySet?.value &&
      editValidationLabels() &&
      networkSegmentData?.resource_group_id ===
        formData?.infrastructureGroup?.value?.resource_id &&
      networkSegmentData?.description === formData?.description?.value
    ) {
      return false;
    } else {
      return true;
    }
  };

  const editValidationLabels = () => {
    if (
      JSON.stringify(networkSegmentData?.labels) ===
      JSON.stringify(formData?.labels?.value)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleOnChange = (name: string, value: any) => {
    if (!networkSegmentLoading) setNetworkSegmentLoading(true);
    const errorMessage = checkFieldValidation(name, value);
    setFormData((prevState: any) => ({
      ...prevState,
      [name]: {
        value,
        error: !!errorMessage,
        errorMessage,
      },
    }));
  };

  const handleSubmit = async () => {
    try {
      setNetworkSegmentLoading(true);
      const requestData = {
        name: removeExtraSpace(formData.name.value),
        compatibility_set: formData.gatewaySet.value,
        resource_group_id:
          formData.infrastructureGroup.value &&
          formData.infrastructureGroup.value.resource_id,
        labels: formData.labels.value,
        description: formData.description.value,
      };
      if (actionType === 'ADD') {
        const networkSegmentAdded = await addNetworkSegments(requestData);
        if (fromPage && fromPage === 'registerNamespace') {
          onClose();
          refreshData(networkSegmentAdded?.resource_id ?? '');
        } else {
          // Trigger success toastbar
          notification.onTrigger('TOAST', {
            title: t('successNotification.title'),
            subtitle: t('successNotification.subTitle', {
              networkSegment: formData.name.value,
            }),
          });
          onClose();
          refreshData();
        }
      } else {
        const networkSegmen: NetworkSegmentData = await updateNetworkSegments(
          networkSegmentData?.resource_id,
          requestData
        );
        if (updateCallBack) {
          onClose();
          updateCallBack(networkSegmen);
        }
        notification.onTrigger('TOAST', {
          title: t('editSuccessNotification.title'),
          subtitle: t('editSuccessNotification.subTitle'),
        });
      }
      setNetworkSegmentLoading(false);
    } catch (error: any) {
      const err = error as AxiosError;
      const errorMessage: string =
        error.response !== undefined
          ? error.response['customErrorMessage']
          : '';
      errorMessage.length > 0 && setSubTitleErrorMsg(errorMessage);
      toggleErrorNotification(true);
      if (err.response?.status === 403) {
        setErrorMessage(t('failureNotification.authErrorTitle'));
        setErrorType('auth');
      } else {
        setErrorMessage(t('failureNotification.title'));
        setErrorType('default');
      }
    } finally {
      setNetworkSegmentLoading(false);
    }
  };

  const handleInlineErrorCloseButton = () => {
    toggleErrorNotification(false);
    setErrorType('default');
  };

  const setEditFormData = () => {
    if (actionType === 'EDIT' && networkSegmentData) {
      const resourceGroupSelected = networkSegmentData.resource_group_id
        ? groups?.find(
            (rg: any) =>
              rg?.resource_id === networkSegmentData?.resource_group_id
          )
        : groups?.find((rg: any) => rg?.resource_id === 'default-app');

      let updatedFormData = {
        name: {
          ...formData.name,
          value: networkSegmentData?.name,
        },
        gatewaySet: {
          ...formData.gatewaySet,
          name:
            gatewaySet.find(
              (gateway: any) =>
                networkSegmentData?.compatibility_set === gateway.value
            )?.name ?? '',
          value:
            gatewaySet.find(
              (gateway: any) =>
                networkSegmentData?.compatibility_set === gateway.value
            )?.value ?? '',
        },
        infrastructureGroup: {
          ...formData.infrastructureGroup,
          value: resourceGroupSelected ? resourceGroupSelected : null,
        },
        description: {
          ...formData.description,
          value: networkSegmentData?.description,
        },
        labels: {
          ...formData.labels,
          value: JSON.parse(JSON.stringify(networkSegmentData?.labels)),
        },
      };
      setFormData(updatedFormData);
    }
  };

  return (
    <Modal
      className={className}
      id={className}
      open={open}
      modalHeading={
        actionType === 'EDIT' ? t('edit.header') : t('create.header')
      }
      primaryButtonText={
        actionType === 'EDIT' ? t('edit.save') : t('create.save')
      }
      secondaryButtonText={t('cancel')}
      preventCloseOnClickOutside={true}
      onRequestClose={() => onClose()}
      onSecondarySubmit={() => onClose()}
      onRequestSubmit={debounce(handleSubmit, 1000)}
      size={'md'}
      primaryButtonDisabled={!isFormValid() || !networkSegmentLoading}
    >
      {showErrorNotification && (
        <InlineNotification
          onClose={() => handleInlineErrorCloseButton() as any}
          kind={'error'}
          title={errorMessage + '.'}
          subtitle={
            errorType === 'auth'
              ? (t('failureNotification.authErrorSubtitle') as string)
              : subTitleErrorMsg.length === 0
              ? (t('failureNotification.subtitle') as string)
              : subTitleErrorMsg
          }
        />
      )}
      <NetworkSegmentForm
        formData={formData}
        onChange={handleOnChange}
        groups={groups}
        subHeader={
          actionType === 'EDIT' ? t('edit.subheader') : t('create.subheader')
        }
      />
    </Modal>
  );
};

export default CreateNetworkSegment;
