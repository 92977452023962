import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import NarrowTearsheetComponent from '../../../components/NarrowTearsheet/NarrowTearsheet';
import InlineNotification from '../../../components/Notifications/Inline/Notification';
import { NotificationContext } from '../../../components/Notifications/Context/NotificationProvider';

import { removeExtraSpace } from '../../../lib/utils';
import { AxiosError } from 'axios';
import EditIdentityDetailsForm from './EditIdentityDetailsForm';
import { updateIdentity } from '../../../controllers/identityApis.js';
import './EditIdentityDetails.scss';

const requiredFields = ['name'];
interface Props {
  open: boolean;
  onClose: () => void;
  identityId: string | null;
  editIdentityDetails: any;
  refreshData: () => void;
  selectedIdentityData: any;
}

const defaultFormValue = {
  name: {
    value: '',
    error: false,
    errorMessage: '',
  },
  description: {
    value: '',
    error: false,
    errorMessage: '',
  },
  labels: {
    value: [],
  },
};

const EditIdentityDetails: React.FC<Props> = ({
  open,
  onClose,
  identityId,
  editIdentityDetails,
  selectedIdentityData,
  refreshData,
}) => {
  const { t } = useTranslation('editIdentityDetails');
  const notification = useContext(NotificationContext);

  const [formData, setFormData] = useState<any>(defaultFormValue);

  const [initialFormDataEdit, setInitialFormDataEdit] =
    useState<any>(defaultFormValue);

  const [showFailNotification, toggleFailNotification] = useState(false);
  const [changed, setChanged] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (editIdentityDetails) {
      const updatedFormData = {
        name: {
          ...formData.name,
          value: editIdentityDetails.name,
        },
        description: {
          ...formData.description,
          value: editIdentityDetails.description,
        },
        labels: {
          ...formData.labels,
          value: Array.isArray(editIdentityDetails.labels)
            ? [...editIdentityDetails.labels]
            : null,
        },
      };
      setInitialFormDataEdit(updatedFormData);
      setFormData(updatedFormData);
    }
  }, [editIdentityDetails, selectedIdentityData]);

  const isFormValid = () => {
    for (const field of requiredFields) {
      const value = formData[field].value;
      const trimmedValue = typeof value === 'string' ? value.trim() : value;
      if (checkFieldValidation(field, trimmedValue)) {
        return false;
      }
    }
    return true;
  };

  const checkValueChanged = () => {
    let changed = false;
    Object.keys(formData).map(key => {
      if (formData[key].value !== initialFormDataEdit[key].value)
        changed = true;
      return null;
    });
    return changed;
  };

  const checkFieldValidation = (name: string, value: any) => {
    let errorMessage = '';
    switch (name) {
      case 'name':
        errorMessage = !value ? t('validation.identityName.required') : '';
        break;
    }
    return errorMessage;
  };

  const handleOnChange = (name: string, value: any) => {
    if (!changed) setChanged(true);
    const errorMessage = checkFieldValidation(name, value);
    setFormData((prevState: any) => ({
      ...prevState,
      [name]: {
        value,
        error: !!errorMessage,
        errorMessage,
      },
    }));
  };

  const cancelEditButton = () => {
    setLoading(false);
    toggleFailNotification(false);
    setChanged(false);
    setAuthError(false);
    setFormData(initialFormDataEdit);
    onClose();
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const identity_FormData = {
        name: removeExtraSpace(formData?.name?.value),
        labels: formData?.labels?.value,
        description: formData?.description.value,
        resource_id: identityId,
      };

      //Updating identity
      await updateIdentity(identityId, identity_FormData);

      notification.onTrigger('TOAST', {
        title: t('successNotification.title'),
        subtitle: t('successNotification.subtitle'),
      });

      cancelEditButton();
      refreshData();
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        setAuthError(true);
      }
      console.log(error);
      toggleFailNotification(true);
    } finally {
      setLoading(false);
    }
  };

  const handleErrorBarClose = () => {
    toggleFailNotification(false);
    setAuthError(false);
  };

  return (
    <NarrowTearsheetComponent
      className='edit-identityDetails-tearsheet'
      title={t('titleEditIdentityDetailsForm')}
      description={t('editIdentityDetailsFormDescription')}
      open={open}
      actions={[
        {
          kind: 'primary',
          label: t('submitButtonText'),
          onClick: () => handleSubmit(),
          disabled: !(isFormValid() && checkValueChanged()),
          loading: loading,
        },
        {
          kind: 'secondary',
          label: t('cancelButtonText'),
          onClick: () => cancelEditButton(),
        },
      ]}
    >
      {showFailNotification && (
        <InlineNotification
          onClose={() => handleErrorBarClose() as any}
          kind={'error'}
          title={
            authError
              ? (t('failureNotification.authTitle') as string)
              : (t('failureNotification.title') as string)
          }
          subtitle={
            authError
              ? (t('failureNotification.authSubtitle') as string)
              : (t('failureNotification.subtitle') as string)
          }
        />
      )}
      <EditIdentityDetailsForm formData={formData} onChange={handleOnChange} />
    </NarrowTearsheetComponent>
  );
};

export default EditIdentityDetails;
