export const typeList = [
  {
    label: 'AWS Cloud',
    value: 'cloud-aws',
  },
  {
    label: 'IBM Cloud',
    value: 'cloud-ibm',
  },
  // {
  //   label: 'Red Hat OCP',
  //   value: 'cloud-ibm',
  // },
  // {
  //   label: 'Azure',
  //   value: 'cloud-azure',
  // },
  {
    label: 'Kubernetes',
    value: 'credentials-k8s',
  },
];

export const secretManagerList = [
  {
    label: 'IbmCloudSecretsManager',
    value: 'ibm-secrets-manager',
  },
  {
    label: 'TenantsIbmCloudSecretsManager',
    value: 'tenants-ibm-secrets-manager',
  },
];
