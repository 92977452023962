import { AxiosError } from 'axios';
import Header from '../../components/Header/Header';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getNetworkSegments } from '../../controllers/networksegmentsApi';
import {
  AppliedFilter,
  Error500Type,
  NetworkSegments,
  ResourceGroup,
} from '../../models/master';
import {
  ContentSwitcher,
  Button,
  Row,
  Column,
  SkeletonPlaceholder,
} from 'carbon-components-react';
import SortDropDown from '../../components/SortDropdown/SortDropDown';
import { List32, Grid32 } from '@carbon/icons-react';
import { manageFiltersFromStorage } from '../../lib/utils';
import NetworkSegmentsTable from './NetworkSegmentsTable';
import Error403Card from '../../components/ErrorState/Error403Card';
import Error500Card from '../../components/ErrorState/Error500Card';
import { FlexGrid } from '@carbon/react';
import ResourceCard from '../../components/ApplicationCard/ResourceCard';
import sortData from '../../lib/tableSort';
import FindAndFilterBar from '../../components/FindAndFilterBar/FindAndFilterBar';
import dateUtils from '../../lib/dates';
import { CardEmptyState } from '../../components/CardEmptyState/CardEmptyState';
import { getResourceGroups } from '../../controllers/resourceGroupApi';
import CreateNetworkSegment from './CreateNetworkSegment/CreateNetworkSegment';

import './NetworkSegmentsContainer.scss';
import GenericStatusField from '../../components/GenericStatusField/GenericStatusField';
import useAnalytics from '../../lib/useAnalytics';

type View = 'table' | 'card';

const defaultPermissionMap = {
  networkSegments: true,
  resourceGroup: true,
};

const NetworkSegmentsContainer = () => {
  const { t } = useTranslation('networkSegments');

  let viewStateValue = localStorage.getItem('VIEW_STATE') as View;
  const [view, setView] = useState<View>(
    viewStateValue ? viewStateValue : 'table'
  );

  const [nwSegmentsDataLoading, setNwSegmentsDataLoading] = useState(false);
  const [networkSegments, setNetworkSegments] = useState<
    NetworkSegments[] | null
  >(null);
  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | 'NONE'>(
    'NONE'
  );
  const [filterApplied, setFilterApplied] = useState<AppliedFilter[] | []>([]);
  const [currentPageNumber, setPageNumber] = useState(1);
  const [currentPageSize, setPageSize] = useState(25);
  const [filteredData, setFilteredData] = useState<NetworkSegments[] | []>([]);
  const [permissionMap, setPermissionMap] = useState(defaultPermissionMap);
  const [resourceGroupsData, setResourceGroupsData] = useState<
    ResourceGroup[] | null
  >(null);
  const [openCreateNetworkSegment, toggleCreateNetworkSegment] =
    useState(false);
  const { pageViewed } = useAnalytics();
  const [error500, setError500] = useState<null | Error500Type>(null);

  useEffect(() => {
    pageViewed('Network Segments');
    setNwSegmentsDataLoading(true);
    refreshData();
  }, []);

  const fetchNwSegmentsList = async () => {
    setNwSegmentsDataLoading(true);
    try {
      const response = await getNetworkSegments(true);
      if (!permissionMap.networkSegments) {
        setPermissionMap(permissionMap => ({
          ...permissionMap,
          networkSegments: true,
        }));
      }

      if (error500) {
        setError500(null);
      }

      setNetworkSegments(response);
    } catch (error) {
      console.error(error);
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        setPermissionMap(permissionMap => ({
          ...permissionMap,
          networkSegments: false,
        }));
      }

      if (err.response!?.status >= 500) {
        setError500(err.response!?.status.toString() as Error500Type);
      }
    } finally {
      setNwSegmentsDataLoading(false);
    }
  };

  const fetchResourceGroups = async () => {
    try {
      const response = await getResourceGroups(true);
      const resourceData = response.resource_groups as ResourceGroup[];

      const filteredResourceGroups = resourceData?.filter(
        resource => resource.type === 'infrastructure'
      );
      if (!permissionMap.resourceGroup) {
        setPermissionMap(permissionMap => ({
          ...permissionMap,
          resourceGroup: true,
        }));
      }
      setResourceGroupsData(filteredResourceGroups);
    } catch (error) {
      console.error(error);
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        setPermissionMap(permissionMap => ({
          ...permissionMap,
          resourceGroup: false,
        }));
      }
    }
  };

  const refreshData = () => {
    setNetworkSegments(null);
    setSortKey('');
    setSortDirection('NONE');
    fetchNwSegmentsList();
    fetchResourceGroups();
    setFilterApplied(manageFiltersFromStorage());
  };

  const closeTearsheet = () => {
    toggleCreateNetworkSegment(false);
  };

  const openTearsheet = () => {
    toggleCreateNetworkSegment(true);
  };

  const handleSort = (data: { id: string; text: string }) => {
    if (Array.isArray(networkSegments)) {
      if (data.id === 'atoz') {
        setSortDirection('ASC');
        const sortedNwSegments = Array.isArray(networkSegments)
          ? networkSegments.sort((a, b) =>
              a.name?.trim()?.toLowerCase() > b.name?.trim()?.toLowerCase()
                ? 1
                : -1
            )
          : [];
        setNetworkSegments([...sortedNwSegments]);
      } else {
        setSortDirection('DESC');
        const sortedNwSegments = Array.isArray(networkSegments)
          ? networkSegments.sort((a, b) =>
              a.name?.trim()?.toLowerCase() < b.name?.trim()?.toLowerCase()
                ? 1
                : -1
            )
          : [];
        setNetworkSegments([...sortedNwSegments]);
      }
    }
  };

  const handleTableSort = (
    data: { id: string; text: string },
    sortDirection: 'ASC' | 'DESC' | 'NONE'
  ) => {
    setSortDirection(sortDirection);
    setSortKey(data.id);
  };

  const setPageChange = (pageData: { page: number; pageSize: number }) => {
    setPageNumber(pageData.page);
    setPageSize(pageData.pageSize);
  };

  const renderFilter = () => {
    return (
      <FindAndFilterBar
        data={networkSegments ? networkSegments : []}
        filteredDataCallback={data =>
          setFilteredData(data as NetworkSegments[] | [])
        }
        persistFilter
        filteredData={
          networkSegments
            ? filterApplied.length > 0
              ? filteredData
              : networkSegments
            : null
        }
        filtersApplied={filterApplied as any}
        filtersAppliedCallback={data => setFilterApplied(data)}
        filters={[
          {
            key: 'labels',
            type: 'multi',
            label: t('filters.labels'),
            placeHolderText: t('filters.labelsPlaceholder'),
            values: [
              ...Array.from(
                new Set(
                  (networkSegments ? networkSegments : [])
                    ?.map(app => app.labels)
                    ?.flat()
                )
              ),
            ],
          },
        ]}
        onRefresh={() => refreshData()}
      />
    );
  };

  const getResourceGroupName = (id: string) => {
    const resouceGroup =
      id &&
      Array.isArray(resourceGroupsData) &&
      resourceGroupsData.find(resouceGroup => resouceGroup.resource_id === id);

    return resouceGroup ? resouceGroup.name : '';
  };

  return (
    <div className='network-segments-container'>
      <Header
        title={t('header')}
        subTitle={t('subheader')}
        loading={nwSegmentsDataLoading}
        breadcrumbs={[
          {
            url: '/',
            label: t('home'),
          },
        ]}
        actions={[
          {
            kind: 'primary',
            onClick: () => openTearsheet(),
            text: t('createNetworkSegment'),
          },
        ]}
      />
      <div className='page-content'>
        <div className='nwSegments-switcher'>
          {view !== 'table' ? (
            <>
              {nwSegmentsDataLoading ? (
                <div className='skeleton-sort-drop-down'>
                  <SkeletonPlaceholder className={'sorting-skeleton'} />
                </div>
              ) : (
                <SortDropDown
                  id='application-sort-dropdown'
                  size='lg'
                  onSort={handleSort}
                  sortDir={sortDirection}
                />
              )}
            </>
          ) : null}
          <ContentSwitcher className='view-switcher'>
            <Button
              className={
                'switch-button' + (view === 'table' ? ' selected' : '')
              }
              onClick={() => {
                setView('table');
                localStorage.setItem('VIEW_STATE', 'table');
              }}
              renderIcon={List32}
              hasIconOnly
              tooltipPosition='bottom'
              iconDescription={t('table')}
            />
            <Button
              className={'switch-button' + (view === 'card' ? ' selected' : '')}
              onClick={() => {
                setView('card');
                localStorage.setItem('VIEW_STATE', 'card');
              }}
              renderIcon={Grid32}
              hasIconOnly
              tooltipPosition='bottom'
              iconDescription={t('card')}
            />
          </ContentSwitcher>
        </div>
        <div className='body'>
          {view === 'table' ? (
            <NetworkSegmentsTable
              currentPageNumber={currentPageNumber}
              currentPageSize={currentPageSize}
              sortRows={(
                data: { id: string; text: string },
                direction: 'ASC' | 'DESC' | 'NONE'
              ) => handleTableSort(data, direction)}
              onPageChange={(pageData: any) => setPageChange(pageData)}
              rows={
                Array.isArray(networkSegments)
                  ? filterApplied.length > 0
                    ? sortData(filteredData, sortKey, sortDirection)
                    : sortData(networkSegments, sortKey, sortDirection)
                  : null
              }
              elementCount={
                networkSegments
                  ? filterApplied.length > 0
                    ? filteredData.length
                    : networkSegments.length
                  : 0
              }
              filteredDataSet={
                networkSegments
                  ? filterApplied.length > 0
                    ? filteredData
                    : networkSegments
                  : null
              }
              filteredDataCallback={data => {
                data && setFilteredData(data as NetworkSegments[] | []);
                setPageNumber(1);
              }}
              data={networkSegments}
              filtersSelected={filterApplied as any}
              filtersAppliedCallback={data => {
                setFilterApplied(data);
              }}
              persistFilter
              filters={[
                {
                  key: 'labels',
                  type: 'multi',
                  label: t('filters.labels'),
                  placeHolderText: t('filters.labelsPlaceholder'),
                  values: [
                    ...Array.from(
                      new Set(
                        (networkSegments ? networkSegments : [])
                          ?.map(app => app.labels)
                          ?.flat()
                      )
                    ),
                  ],
                },
              ]}
              onRefresh={() => refreshData()}
              resourceGroupsList={resourceGroupsData}
              error403Flag={!permissionMap['networkSegments']}
              error500Flag={!!error500}
              resourceGroupPermission={permissionMap.resourceGroup}
            />
          ) : !permissionMap['networkSegments'] ? (
            <Error403Card />
          ) : error500 ? (
            <Error500Card />
          ) : nwSegmentsDataLoading ? (
            <div>
              {renderFilter()}
              <div className='skeleton-card-view'>
                <SkeletonPlaceholder className={'application-skeleton'} />
                <SkeletonPlaceholder className={'application-skeleton'} />
                <SkeletonPlaceholder className={'application-skeleton'} />
                <SkeletonPlaceholder className={'application-skeleton'} />
              </div>
            </div>
          ) : (
            <div>
              {renderFilter()}
              <div className='card-view'>
                <FlexGrid>
                  <Row className='resource-card-alignment'>
                    {networkSegments &&
                    (filterApplied.length > 0 ? filteredData : networkSegments)
                      .length > 0 ? (
                      (filterApplied.length > 0
                        ? filteredData
                        : networkSegments
                      ).map(nwSegment => (
                        <Column lg={4} md={4} className='resource-card'>
                          <ResourceCard
                            key={nwSegment.resource_id}
                            resourceType={'NETWORKSEGMENT'}
                            header={nwSegment.name}
                            tags={nwSegment.labels}
                            data={{
                              description: nwSegment.description,
                              gatewaySet: nwSegment?.compatibility_set
                                ? t(`${nwSegment?.compatibility_set}`)
                                : '—',
                              resourceGroupId: !permissionMap.resourceGroup ? (
                                <GenericStatusField status={'notAuthorised'} />
                              ) : (
                                getResourceGroupName(
                                  nwSegment.resource_group_id
                                )
                              ),
                            }}
                            updated={dateUtils.getUserFriendlyDate(
                              nwSegment.updated_at
                            )}
                            path={`/networkSegmentDetails?nwSegId=${nwSegment.resource_id}`}
                          />
                        </Column>
                      ))
                    ) : (
                      <CardEmptyState
                        filterApplied={filterApplied}
                        emptyState={{
                          icon: <></>,
                          header: t('emptyState.emptyContainerHeader'),
                          description: t(
                            'emptyState.emptyContainerDescription'
                          ),
                        }}
                      />
                    )}
                  </Row>
                </FlexGrid>
              </div>
            </div>
          )}
        </div>
      </div>
      {openCreateNetworkSegment && (
        <CreateNetworkSegment
          open={openCreateNetworkSegment}
          onClose={closeTearsheet}
          networkSegments={networkSegments}
          groups={resourceGroupsData}
          refreshData={refreshData}
          actionType={'ADD'}
          className={'create-network-segment'}
        />
      )}
    </div>
  );
};

export default NetworkSegmentsContainer;
