import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ComponentProps } from '../config';

import './GatewayTooltip.scss';
import images from '../../../../images/images';
import ProceduralStatus from '../../../GatewayStatus/ProceduralStatus';
import {
  HealthStatusType,
  ProceduralStatusType,
} from '../../../GatewayStatus/config';
import HealthStatus from '../../../GatewayStatus/HealthStatus';
import { getGatewayCompatibilitySet } from '../../../../controllers/topologyApis';
import { SkeletonPlaceholder } from 'carbon-components-react';

const GatewayTooltip: React.FC<ComponentProps> = ({ node }) => {
  const { t } = useTranslation('topologyTooltipGateway');

  const [loading, setLoading] = useState(false);
  const [compatibilityData, setCompatibilityData] = useState<any>();

  const fetchCompatibilityData = async () => {
    try {
      setLoading(true);
      const response = await getGatewayCompatibilitySet();
      const compatibilitySubtype = response?.subtypes.find(
        (e: any) => e?.resource_id === node?.subtype
      );
      setCompatibilityData(compatibilitySubtype);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getGatewayIcon = () => {
    return node?.type === 'edge'
      ? images.EdgeGatewayIcon()
      : images.WaypointGatewayIcon();
  };

  const getGatewayType = () => {
    const type = node?.type === 'edge' ? t('edge') : t('waypoint');

    if (
      node?.type &&
      compatibilityData &&
      (compatibilityData?.resource_id === 'AXON-EDGE' ||
        compatibilityData?.resource_id === 'AXON-WP' ||
        compatibilityData?.resource_id === 'RHSI-EDGE')
    ) {
      return compatibilityData?.name;
    }

    return type;
  };

  useEffect(() => {
    fetchCompatibilityData();
  }, []);

  return (
    <div className='topology-tooltip-gateway'>
      <div className='header'>
        {node.name}

        {/* For future use when version we will get */}
        {/* <span className='version'>v 1.0</span> */}
      </div>
      {loading && (
        <div className='skeleton-container'>
          <SkeletonPlaceholder className='service-skeleton'></SkeletonPlaceholder>
          <SkeletonPlaceholder className='service-skeleton'></SkeletonPlaceholder>
          <SkeletonPlaceholder className='service-skeleton'></SkeletonPlaceholder>
          <SkeletonPlaceholder className='service-skeleton'></SkeletonPlaceholder>
        </div>
      )}
      {!loading && (
        <>
          {node && (
            <div className='body'>
              <div className='gw-type-info'>
                <span className='gw-type-icon'>{getGatewayIcon()}</span>
                <span className='gw-type-title'>{getGatewayType()}</span>
              </div>

              <HealthStatus
                status={node?.health_status as HealthStatusType}
                showLabel={true}
                gatewayName={node?.name}
                tooltipPosition='top'
              />
              {node?.procedural_status !== 'operational' &&
                node?.procedural_status !== 'notdeployed' && (
                  <div className='gw-procedural-status'>
                    {node?.procedural_status !== 'errored' && (
                      <span className='gw-procedural-status-icon'>
                        {images.pendingStatusIcon()}
                      </span>
                    )}
                    <ProceduralStatus
                      status={node?.procedural_status as ProceduralStatusType}
                      gatewayName={node?.name}
                    />
                  </div>
                )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default GatewayTooltip;
