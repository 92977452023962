import { DropdownItem } from './MetricComponent';

export const typeDrownDown: DropdownItem[] = [
  {
    text: 'In/out bytes',
    id: 1,
  },
  {
    text: '2nd option bytes',
    id: 2,
  },
];

export const mockData = [
  {
    group: 'bytes in',
    key: '9a',
    value: 80,
  },
  {
    group: 'bytes in',
    key: '10a',
    value: 60,
  },
  {
    group: 'bytes in',
    key: '11a',
    value: 81,
  },
  {
    group: 'bytes in',
    key: '12p',
    value: 83,
  },
  {
    group: 'bytes in',
    key: '1p',
    value: 84,
  },
  {
    group: 'bytes out',
    key: '9a',
    value: 78,
  },
  {
    group: 'bytes out',
    key: '10a',
    value: 56,
  },
  {
    group: 'bytes out',
    key: '11a',
    value: 75,
  },
  {
    group: 'bytes out',
    key: '12p',
    value: 79,
  },
  {
    group: 'bytes out',
    key: '1p',
    value: 80,
  },
];
