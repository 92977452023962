import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Column,
  Row,
  TooltipDefinition,
} from 'carbon-components-react';
import {
  ChevronDown16,
  ChevronUp16,
  Edit16,
  Launch16,
} from '@carbon/icons-react';

import useAnalytics from '../../lib/useAnalytics';
import analyticsData from '../../lib/analyticsEventData';
import { EnvironmentTypes, VisibilityFlags } from '../../lib/enums';

import images from '../../images/images';
import Overflow from '../../components/Overflow/Overflow';
import CloudNameSpaceTable from './CloudNameSpaceTable';
import GenericStatusField from '../../components/GenericStatusField/GenericStatusField';

import {
  DeploymentEnvironment,
  Gateway,
  Location,
  Namespace,
  NetworkSegment,
  ResourceGroup,
} from '../../models/master';

import './CloudLocationDeplEnv.scss';

interface Props {
  index: number;
  location: Location;
  gateways: Gateway[] | null;
  resourceGroups: ResourceGroup[] | null;
  deploymentEnv: DeploymentEnvironment;
  networksegments: NetworkSegment[];
  namespaceDeplEnvMap: { [key: string]: Namespace[] };
  nameSpaceLoading: boolean;
  getDeplEnvSubTypeName: (deploymentEnv: DeploymentEnvironment) => string;
  onEdit: (deploymentEnv: DeploymentEnvironment) => void;
  onRegisterNamespace: (deploymentEnv: DeploymentEnvironment) => void;
  onEditNamespace: (
    deploymentEnv: DeploymentEnvironment,
    namespace: Namespace
  ) => void;
  defaultPermissionMap: {
    location: boolean;
    deploymentEnv: boolean;
    namespace: boolean;
    networkSegmant: boolean;
    gateway: boolean;
    resourceGroup: boolean;
  };
}

const CloudLocationDeplEnv = ({
  index,
  location,
  gateways,
  resourceGroups,
  deploymentEnv,
  networksegments,
  namespaceDeplEnvMap,
  nameSpaceLoading,
  getDeplEnvSubTypeName,
  defaultPermissionMap,
  onEdit,
  onRegisterNamespace,
  onEditNamespace,
}: Props) => {
  const { t } = useTranslation('cloudDetails');

  const { trackButtonClicked } = useAnalytics();

  const [isExpanded, toggleExpand] = useState(false);

  const getResourceGroupName = (resourceGroupId: string) => {
    if (!defaultPermissionMap['resourceGroup']) {
      return <GenericStatusField status='notAuthorised'></GenericStatusField>;
    }

    if (Array.isArray(resourceGroups)) {
      const resourceGroup = resourceGroups.find(
        resourceGroup => resourceGroup.resource_id === resourceGroupId
      );

      return resourceGroup?.name ?? '—';
    }

    return '';
  };

  const getNamespaceInDeplEnv = (deplEnvId: string) => {
    const namespaceList = namespaceDeplEnvMap[deplEnvId];

    return namespaceList ?? [];
  };

  const handleManagedChange = (deploymentEnv: DeploymentEnvironment) => {
    if (deploymentEnv.type === EnvironmentTypes.VPC) {
      trackButtonClicked(
        analyticsData['Cloud Details'].events.manageVpc.props,
        analyticsData['Cloud Details'].events.manageVpc.event
      );
    }

    if (deploymentEnv.type === EnvironmentTypes.CLUSTER) {
      trackButtonClicked(
        analyticsData['Cloud Details'].events.manageCluster.props,
        analyticsData['Cloud Details'].events.manageCluster.event
      );
    }

    onEdit(deploymentEnv);
  };

  const handleDeplEnvCardClick = (deploymentEnv: DeploymentEnvironment) => {
    if (deploymentEnv.unmanaged && !location.unmanaged) {
      handleManagedChange(deploymentEnv);
    }

    if (
      !deploymentEnv.unmanaged &&
      deploymentEnv.type === EnvironmentTypes.CLUSTER
    ) {
      toggleExpand(!isExpanded);
    }
  };

  const checkClickable = (deploymentEnv: DeploymentEnvironment) => {
    let className = '';

    if (deploymentEnv.unmanaged && !location.unmanaged) {
      className = 'clickable';
    }

    if (
      !deploymentEnv.unmanaged &&
      deploymentEnv.type === EnvironmentTypes.CLUSTER
    ) {
      className = 'clickable';
    }

    return className;
  };

  return (
    <div
      className={`cloud-location-deployment-env ${
        deploymentEnv.unmanaged
          ? VisibilityFlags.UNMANAGED
          : VisibilityFlags.MANAGED
      } ${checkClickable(deploymentEnv)}`}
      key={deploymentEnv.resource_id}
      id={`deploymentEnv-card-${deploymentEnv.resource_id}`}
      onClick={() => handleDeplEnvCardClick(deploymentEnv)}
    >
      <div className='title-session'>
        <div className='label-group'>
          <div className='icon'>
            {deploymentEnv.unmanaged
              ? images.unmanagedIcon()
              : images.managedIcon()}
          </div>
          <Overflow
            align='start'
            toolTipDirection='bottom'
            key={`title-${deploymentEnv.resource_id}`}
          >
            <h4 className='title'>{deploymentEnv.name}</h4>
          </Overflow>
          {deploymentEnv.unmanaged && (
            <h5 className='unmanaged-label'>{t(VisibilityFlags.UNMANAGED)}</h5>
          )}
          <Overflow
            align='start'
            toolTipDirection='bottom'
            key={`subtype-${deploymentEnv.resource_id}`}
          >
            <h5 className='subtype'>{getDeplEnvSubTypeName(deploymentEnv)}</h5>
          </Overflow>
        </div>

        <div className='button-group' onClick={e => e.stopPropagation()}>
          {!deploymentEnv.unmanaged && (
            <>
              <Button
                kind='ghost'
                renderIcon={Launch16}
                iconDescription={t('detailsPage')}
                hasIconOnly
                tooltipPosition='bottom'
                onClick={e => {
                  e.stopPropagation();

                  trackButtonClicked(
                    analyticsData['Cloud Details'].events.depEnvDetails.props,
                    analyticsData['Cloud Details'].events.depEnvDetails.event
                  );

                  window.open(
                    window.location.origin +
                      process.env.PUBLIC_URL +
                      `/deploymentEnvironmentDetails?deplId=${deploymentEnv.resource_id}`
                  );
                }}
              />
              <Button
                kind='ghost'
                renderIcon={Edit16}
                iconDescription={t('edit')}
                hasIconOnly
                tooltipPosition='bottom'
                onClick={() => onEdit(deploymentEnv)}
              />
            </>
          )}

          {deploymentEnv.unmanaged && !location.unmanaged && (
            <Button
              kind='ghost'
              onClick={() => handleManagedChange(deploymentEnv)}
            >
              {t('manage')}
            </Button>
          )}

          {deploymentEnv.unmanaged && location.unmanaged && (
            <TooltipDefinition
              direction={index > 1 ? 'top' : 'bottom'}
              align='end'
              tooltipText={t('tooltip.envManagedToggleTooltip') as string}
              className='manage-location-tooltip'
            >
              <Button kind='ghost' disabled className='disabled'>
                {t('manage')}
              </Button>
            </TooltipDefinition>
          )}
        </div>
      </div>

      {!deploymentEnv.unmanaged && (
        <Row className='details-session'>
          <Column md={3} lg={4}>
            <div className='label'>{t('infraGroup')}</div>
            <div className='value'>
              {getResourceGroupName(deploymentEnv.resource_group_id)}
            </div>
          </Column>
        </Row>
      )}

      {!deploymentEnv.unmanaged &&
        (deploymentEnv.type === EnvironmentTypes.VPC ||
          deploymentEnv.type === EnvironmentTypes.NODE) && (
          <div className='no-table-session'></div>
        )}

      {!deploymentEnv.unmanaged &&
        deploymentEnv.type === EnvironmentTypes.CLUSTER && (
          <div>
            <CloudNameSpaceTable
              isExpanded={isExpanded}
              deploymentEnv={deploymentEnv}
              gateways={gateways}
              networksegments={networksegments}
              namespaces={getNamespaceInDeplEnv(deploymentEnv.resource_id)}
              nameSpaceLoading={nameSpaceLoading}
              getResourceGroupName={getResourceGroupName}
              defaultPermissionMap={defaultPermissionMap}
              onRegisterNamespace={onRegisterNamespace}
              onEditNamespace={onEditNamespace}
            />
            <div
              id={`namespace-chevron-${deploymentEnv?.resource_id}`}
              className='action-row'
              onClick={() => toggleExpand(!isExpanded)}
            >
              <div className='label'>
                {isExpanded ? t('collapse') : t('expand')}
              </div>
              <div className='chevron-icon'>
                {isExpanded ? <ChevronUp16 /> : <ChevronDown16 />}
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default CloudLocationDeplEnv;
