import { Column, Row } from 'carbon-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ZoomIn24, ZoomOut24, ZoomInArea24 } from '@carbon/icons-react';
import images from '../../../images/images';
import './LegendToolsInd.scss';

const LegendToolsInd = () => {
  const { t } = useTranslation('legendContainer');

  const fetchIconAndDescription = (icon: any, desc: string) => {
    return (
      <Column className='legend-tools-indicators-label'>
        <Row>
          <Column lg={1}>{icon}</Column>
          <Column> {desc}</Column>
        </Row>
      </Column>
    );
  };

  return (
    <div className='topology-legend-tools_indicators'>
      <Row>
        <Column>
          <Row>
            <Column className='legend-tools-indicators-header'>
              {t('tools.tools')}
            </Column>
          </Row>
          <Row>
            {fetchIconAndDescription(
              images.topologyLegend(),
              t('tools.topology')
            )}
            <Column className='legend-tools-indicators-label'>
              <Row>
                <Column lg={1} className='tools-icon'>
                  <ZoomIn24 />
                </Column>
                <Column> {t('tools.zoomIn')}</Column>
              </Row>
            </Column>
          </Row>
          <Row>
            {fetchIconAndDescription(images.layersLegend(), t('tools.layers'))}
            <Column className='legend-tools-indicators-label'>
              <Row>
                <Column lg={1} className='tools-icon'>
                  <ZoomOut24 />
                </Column>
                <Column> {t('tools.zoomOut')}</Column>
              </Row>
            </Column>
          </Row>
          <Row>
            {fetchIconAndDescription(
              images.expandsToLegend(),
              t('tools.expandTo')
            )}
            <Column className='legend-tools-indicators-label'>
              <Row>
                <Column lg={1} className='tools-icon'>
                  <ZoomInArea24 />
                </Column>
                <Column> {t('tools.zoomSel')}</Column>
              </Row>
            </Column>
          </Row>
        </Column>
        <Column>
          <Row>
            <Column className='legend-tools-indicators-header'>
              {t('indicators.indicators')}
            </Column>
          </Row>
          <Row>
            {fetchIconAndDescription(
              images.autoDiscoveredLegend(),
              t('indicators.autodiscovered')
            )}
            {fetchIconAndDescription(
              images.notAuthorizedLockLegend(),
              t('indicators.notAuthorized')
            )}
          </Row>
          <Row>
            {fetchIconAndDescription(
              images.appToServiceLegend(),
              t('indicators.apptoservice')
            )}
            {fetchIconAndDescription(
              images.eventsLegend(),
              t('indicators.events')
            )}
          </Row>
          <Row>
            {fetchIconAndDescription(
              images.gatewayToGatewayLegend(),
              t('indicators.gwToGw')
            )}
            <Column className='legend-tools-indicators-label'>
              <Row></Row>
            </Column>
          </Row>
        </Column>
      </Row>
    </div>
  );
};

export default LegendToolsInd;
