import { useNavigate } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbSkeleton,
} from 'carbon-components-react';
import './Breadcrumbs.scss';

export interface BreadcrumbProperties {
  breadCrumbs: BreadCrumb[];
  loadingContent?: boolean;
}
export interface BreadCrumb {
  url: string;
  label: string;
  loading?: boolean;
}

const Breadcrumbs = (props: BreadcrumbProperties) => {
  const navigate = useNavigate();

  if (props.loadingContent) {
    return (
      <div className='breadcrumb-container' data-testid='breadcrumb-container'>
        <BreadcrumbSkeleton>
          {props.breadCrumbs.map((breadcrumb, index) => (
            <BreadcrumbItem
              key={index}
              className='breadcrumb-item-skeleton'
              data-testid={`breadcrumb-item-${index}`}
            />
          ))}
        </BreadcrumbSkeleton>
      </div>
    );
  }
  return (
    <Breadcrumb
      className='breadcrumb-container'
      data-testid='breadcrumb-container'
    >
      {props.breadCrumbs.map((breadCrumbItem, index) => (
        <BreadcrumbItem
          onClick={() => navigate(breadCrumbItem.url)}
          className='breadcrumb-label'
          key={index}
          data-testid={`breadcrumb-item-${index}`}
        >
          {breadCrumbItem.label}
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
