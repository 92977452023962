import React, { useState, useEffect } from 'react';
import { TextArea, ComboBox } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import AddLabels from '../../components/AddLabels/AddLabels';
import NarrowTearsheetComponent from '../../components/NarrowTearsheet/NarrowTearsheet';
import './EditEvent.scss';
import { updateEvent } from '../../controllers/eventsApis';
import { getIdentities } from '../../controllers/identityApis';
import { Identity } from '../../models/master';

type userList = {
  id: string;
  text: string;
};

const EditEvent = (props: {
  name: string | undefined;
  comment?: string;
  assigned?: string;
  labels?: string[] | null;
  open: boolean;
  onClose: () => void;
  saveChangesCallBack: () => void;
  updateErrorCallBack: (error: any) => void;
  eventId: string;
  isHandled: boolean;
  buttonText: string | undefined;
}) => {
  const {
    name,
    comment,
    assigned,
    labels,
    open,
    onClose,
    saveChangesCallBack,
    updateErrorCallBack,
    eventId,
    isHandled,
    buttonText,
  } = props;
  const { t } = useTranslation('editEvent');
  const [users, setUsers] = useState<userList[]>([
    { id: 'assignToMe', text: t('assignToMe') },
  ]);
  const [assignedUser, setAssignedUser] = useState<{
    id: string;
    text: string;
  }>();
  const [userComment, setUserComment] = useState(comment);
  const [userLabels, setUserLabels] = useState(labels);
  const [openEditEventTearsheet, setOpenEditEventTearsheet] =
    useState<boolean>(open);
  const [loading, setLoading] = useState(false);

  const saveChangesBtnHandle = async () => {
    const updateEventPayload = {
      assigned_user: assignedUser ? assignedUser?.id : '',
      comment: userComment,
      is_handled: true,
      labels: userLabels,
      correlated: eventId,
    };

    if (assignedUser && assignedUser.id === 'assignToMe') {
      const selectedAccount = window.localStorage.getItem('SELECTED_ACCOUNT');
      const auth: any = window?.localStorage?.getItem(
        `ACCOUNT_DETAILS_${selectedAccount}`
      );
      updateEventPayload.assigned_user = JSON.parse(auth).username;
    }
    if (buttonText === 'normal') {
      updateEventPayload.is_handled = isHandled;
    } else if (buttonText === 'Reopen') {
      updateEventPayload.is_handled = false;
    } else if (buttonText === 'Close') {
      updateEventPayload.is_handled = true;
    } else if (buttonText === 'Assign') {
      updateEventPayload.is_handled = isHandled;
    } else if (buttonText === 'Reassign') {
      updateEventPayload.is_handled = false;
    }
    try {
      setLoading(true);
      await updateEvent(eventId, updateEventPayload);
      saveChangesCallBack();
    } catch (error: any) {
      updateErrorCallBack(error);
    } finally {
      setOpenEditEventTearsheet(false);
      setLoading(false);
      onClose();
    }
  };

  useEffect(() => {
    setOpenEditEventTearsheet(open);
    setAssigneeList();
  }, [open]);

  useEffect(() => {
    setAssigneeList();
  }, []);

  const setAssigneeList = async () => {
    let allIdentities: userList[] = [];
    const selectedAccount = window.localStorage.getItem('SELECTED_ACCOUNT');
    const auth: any = window?.localStorage?.getItem(
      `ACCOUNT_DETAILS_${selectedAccount}`
    );
    const currentUser = JSON.parse(auth)?.username;
    try {
      const identityList = await getIdentities();
      identityList.map((identity: Identity) => {
        if (
          !(assigned && assigned === identity?.name) &&
          currentUser !== identity?.name
        )
          allIdentities.push({
            id: identity?.name,
            text: identity?.name,
          });
        return null;
      });
    } catch (e) {
      allIdentities = [];
    }
    if (assigned && currentUser !== assigned) {
      const assignedUser = { id: assigned, text: assigned };
      setUsers([...users, ...allIdentities, assignedUser]);
      setAssignedUser(assignedUser);
    } else {
      //if (buttonText !== 'normal')
      await setUsers([...users, ...allIdentities]);
      setAssignedUser({
        id: 'assignToMe',
        text: t('assignToMe'),
      });
    }
  };

  const cancelBtnHandle = () => {
    setOpenEditEventTearsheet(false);
    onClose();
  };

  const handleLabelChange = (data: string[]) => {
    setUserLabels(data);
  };

  const handleEventEdit = (item: any) => {
    setAssignedUser(item);
  };

  const combobox = () => {
    return (
      <ComboBox
        id='assign-user-combo'
        placeholder={t('assign')}
        selectedItem={assignedUser}
        titleText={t('form.assignUser.label')}
        translateWithId={t}
        items={users}
        onChange={e => {
          handleEventEdit(e.selectedItem);
        }}
        itemToString={item => (item ? item.text : '')}
      />
    );
  };

  return (
    <NarrowTearsheetComponent
      open={openEditEventTearsheet}
      title={t('title')}
      description={t('description')}
      className='edit-event-modal'
      actions={[
        {
          kind: 'primary',
          label: t('submitButtonText'),
          onClick: () => saveChangesBtnHandle(),
          loading: loading,
        },
        {
          kind: 'secondary',
          label: t('cancelButtonText'),
          onClick: () => cancelBtnHandle(),
        },
      ]}
    >
      <div className='items-container'>
        <p className='name item'>{name}</p>
        <div className='item'>{combobox()}</div>
        <div className='item'>
          <TextArea
            id='userCommentEventEdit'
            labelText={t('form.comment.label')}
            value={userComment}
            onChange={(e: any) => {
              setUserComment(e.target.value);
            }}
          ></TextArea>
        </div>
        <div className='item'>
          <AddLabels
            id='editEventLabels'
            labelText={t('form.labels.label')}
            placeholder={t('form.labels.placeholder')}
            onChange={data => handleLabelChange(data)}
            btnText={t('form.labels.buttonText')}
            btnKind='secondary'
            tagType='green'
            defaultValues={userLabels}
          />
        </div>
      </div>
    </NarrowTearsheetComponent>
  );
};

export default EditEvent;
