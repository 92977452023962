import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InlineNotification from '../../../components/Notifications/Inline/Notification';
import { NotificationContext } from '../../../components/Notifications/Context/NotificationProvider';
import { updatePolicy } from '../../../controllers/policyApi';
import { getResourceGroups } from '../../../controllers/resourceGroupApi';
import { removeExtraSpace } from '../../../lib/utils';
import './EditPolicyDetails.scss';
import WideTearsheet from '../../../components/WideTearsheet/WideTearsheet';
import EditDetails from './EditDetails';
import { AxiosError } from 'axios';
import { TableTypes } from '../config';
import { PolicyData } from '../../../models/master';
import { NetworkSegmentCompatibilitySet } from '../../../lib/enums';

interface Props {
  open: boolean;
  onClose: () => void;
  onRefresh: (t: TableTypes) => void;
  policyDetailsData?: any | null;
  updatePolicyData?: (data: any) => void;
  editedPolicy: () => void;
  networkSegment: any;
  policyList: any;
  hasNetworkSegmentAuth: boolean;
}

const EditPolicyDetails: React.FC<Props> = ({
  open,
  onClose,
  onRefresh,
  policyDetailsData,
  updatePolicyData,
  editedPolicy,
  networkSegment,
  policyList,
  hasNetworkSegmentAuth,
}) => {
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [resourceGroupList, setResourceGroupList] = useState<any>([]);
  const { t } = useTranslation('editPolicy');
  const notification = useContext(NotificationContext);
  const [showFailNotification, toggleFailNotification] = useState(false);
  const [changed, setChanged] = useState(false);
  const [changedName, setChangedName] = useState(false);
  const [changedResourceGroup, setChangedResourceGroup] = useState(false);
  const [changedLabels, setChangedLabels] = useState(false);
  const [changedDescription, setChangedDescription] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [subTitleErrorMsg, setSubTitleErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getResourceGroups().then(response => {
      setResourceGroupList(response.resource_groups);
    });
  }, []);

  const checkFormValid = () => {
    let isFormValid = true;
    let nameError = checkFieldValid('name', formData.name.value);
    let resourceGroupErr = checkFieldValid(
      'resourceGroup',
      formData.resourceGroup.value
    );
    if (nameError.length > 0 || resourceGroupErr.length > 0) {
      isFormValid = false;
    }
    if (nameError.length > 0) {
      isFormValid = false;
    }

    return isFormValid;
  };

  const checkFieldValid = (name: string, value: any) => {
    let errorMessage = '';

    switch (name) {
      case 'name':
        const valueEmpty =
          value === '' || value === null || value === undefined ? true : false;

        const networkSegmentVal = networkSegment
          ? networkSegment
          : formData?.networkSegment;

        const filteredPolicyList =
          policyList?.filter(
            (policy: PolicyData) =>
              policy?.network_segment_id === networkSegmentVal?.resource_id &&
              policy?.name === value &&
              policy?.resource_id !== policyDetailsData?.resource_id
          ) ?? [];
        const notUnique = filteredPolicyList?.length > 0 ? true : false;
        errorMessage = valueEmpty
          ? t('nameError')
          : notUnique
          ? t('nameExists')
          : '';
        break;
      case 'resourceGroup':
        if (value === '' || value === null || value === undefined)
          errorMessage = t('resourceGroupError');
        break;
    }

    return errorMessage;
  };

  const form = {
    name: {
      value: policyDetailsData?.name,
      error: false,
      errorMessage: '',
    },
    description: {
      value: policyDetailsData.description,
      error: false,
      errorMessage: '',
    },
    resourceGroup: {
      value:
        policyDetailsData.resource_group_id === ''
          ? 'default-app'
          : policyDetailsData.resource_group_id,
      error: false,
      errorMessage: '',
    },
    resourceGroupList: [],
    labels: {
      value: policyDetailsData?.labels?.map((d: any) => d),
      error: false,
      errorMessage: '',
    },
    networkSegment: {
      resource_id: networkSegment?.resource_id,
      name: networkSegment?.name,
      compatibility_set: networkSegment?.compatibility_set,
      error: false,
      errorMessage: '',
    },
  };

  const [formData, setFormData] = useState<any>(form);

  useEffect(() => {
    setFormData((updatedFormData: any) => ({
      ...updatedFormData,
      resourceGroupList: resourceGroupList?.filter(
        (group: { type: string }) => group?.type === 'application'
      ),
    }));
  }, [resourceGroupList]);

  const handleOnChange = (e: { target: { name: string; value: any } }) => {
    // Clear error snackbar if present
    if (showErrorSnackbar) {
      setShowErrorSnackbar(false);
      setAuthError(false);
    }
    const name = e.target.name;
    const value = e?.target?.value;

    switch (name) {
      case 'name':
        if (policyDetailsData?.name !== value) setChangedName(true);
        else setChangedName(false);
        break;
      case 'resourceGroup':
        if (policyDetailsData.resource_group_id !== value)
          setChangedResourceGroup(true);
        else setChangedResourceGroup(false);
        break;
      case 'description':
        if (policyDetailsData.description !== value)
          setChangedDescription(true);
        else setChangedDescription(false);
        break;
      case 'labels':
        if (JSON.stringify(policyDetailsData.labels) !== JSON.stringify(value))
          setChangedLabels(true);
        else setChangedLabels(false);
        break;
    }

    let errorMessage = checkFieldValid(name, value);
    setFormData((prevState: { [x: string]: any }) => ({
      ...prevState,
      [name]: {
        value,
        error: !!errorMessage,
        errorMessage,
      },
    }));
  };

  const handlePolicySubmit = async () => {
    try {
      setLoading(true);
      // Clear error snackbar if present
      if (showErrorSnackbar) {
        setShowErrorSnackbar(false);
        setAuthError(false);
      }
      // toggleFailNotification(false);
      const value = formData.resourceGroup?.value;

      const policyDetails = {
        ...policyDetailsData,
        name: removeExtraSpace(formData?.name?.value),
        description: formData?.description?.value,
        // Note : Remove Resource Group for Experimental release.
        resource_group_id: value,
        resourceGroup: resourceGroupList.filter(
          (d: { resource_id: any }) => d.resource_id === value
        ),
        labels: formData?.labels?.value,
      };

      const policy = await updatePolicy(
        policyDetailsData.resource_id,
        policyDetails
      );

      // Refreshes data on successful policy addition
      onClose();
      if (updatePolicyData) {
        updatePolicyData(policy);
      }
      notification.onTrigger('TOAST', {
        title: t('toast.title'),
        subtitle: t('toast.subtitle', { name: policy.name }),
      });

      setChangedName(false);
      setChangedResourceGroup(false);
      setChangedDescription(false);
      setChangedLabels(false);

      // close modal and fetch new data in parent
      // TODO: navigate to card view when functionality is added
      editedPolicy();
    } catch (error: any) {
      const err = error as AxiosError;
      console.log(err);
      if (err.response?.status === 403) {
        setAuthError(true);
        toggleFailNotification(true);
        return Promise.reject(() => console.error(err));
      }
      const errorMessage: string =
        error.response !== undefined
          ? error.response['customErrorMessage']
          : '';
      errorMessage.length > 0 && setSubTitleErrorMsg(errorMessage);
      toggleFailNotification(true);
      // We added this project.reject to prevent the modal closing when error occured.
      // But in latest version of '@carbon/ibm-products' the below line is not required and is throwing error.
      // return Promise.reject(() => console.log(error));
    } finally {
      setLoading(false);
    }
  };

  const handleErrorBarClose = () => {
    toggleFailNotification(false);
    setAuthError(false);
  };

  return (
    <div>
      <WideTearsheet
        title={t('title')}
        description={t('description')}
        open={open}
        actions={[
          {
            kind: 'primary',
            label: t('saveButtonText'),
            onClick: () => handlePolicySubmit(),
            disabled:
              !checkFormValid() ||
              (!changedName &&
                !changedDescription &&
                !changedLabels &&
                !changedResourceGroup),
            loading: loading,
          },
          {
            kind: 'secondary',
            label: t('cancelButtonText'),
            onClick: () => {
              onClose();
              toggleFailNotification(false);
              setChangedName(false);
              setChangedResourceGroup(false);
              setChangedDescription(false);
              setChangedLabels(false);
              setAuthError(false);
            },
          },
        ]}
      >
        {showFailNotification && (
          <InlineNotification
            kind='error'
            title={
              authError
                ? (t('error.authTitle') as string)
                : (t('error.title') as string)
            }
            subtitle={
              authError
                ? (t('error.authSubtitle') as string)
                : subTitleErrorMsg.length > 0
                ? subTitleErrorMsg
                : (t('error.subtitle') as string)
            }
            onClose={() => handleErrorBarClose() as any}
          />
        )}
        <EditDetails
          formData={formData}
          onChange={handleOnChange}
          checkFormValid={checkFormValid()}
          hasNetworkSegmentAuth={hasNetworkSegmentAuth}
        ></EditDetails>
      </WideTearsheet>
    </div>
  );
};

export default EditPolicyDetails;
