import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './SkupperGateway.scss';
import {
  Button,
  Column,
  Dropdown,
  InlineNotification,
  Row,
  SkeletonPlaceholder,
  TextInput,
} from 'carbon-components-react';
import { LinkedAgent, NetworkSegments } from '../../../../../models/master';
import { Add16, Edit16 } from '@carbon/icons-react';
import { Item } from '../../DeploymentEnvTable/DeploymentEnvTable';
import images from '../../../../../images/images';

interface Props {
  openDeploymentEnvTable: () => void;
  resourceGroupList?: any;
  handleOnChange: any;
  formData?: any;
  selectedDepEnv: Item;
  onClickEditDeployedInVpcBtn: () => void;
  networkSegments: NetworkSegments[];
  linkedAgents: LinkedAgent | null;
  onSelectNetworkSegment: (networkSegment: any) => void;
  onSelectLinkedAgent: (linkedAgent: any) => void;
  selectedLinkedAgent: any;
  isDeplEnvPresent: boolean;
  openNetworkSegmentTearsheet: () => void;
  selectedNetworkSegment: any;
  onBlur: (name: string, value: any) => void;
  notifyCreateNameSpace: boolean;
  toggleNotifyCreateNameSpace: () => void;
}

const SkupperGateway: React.FC<Props> = ({
  openDeploymentEnvTable,
  resourceGroupList,
  handleOnChange,
  formData,
  selectedDepEnv,
  onClickEditDeployedInVpcBtn,
  networkSegments,
  linkedAgents,
  onSelectNetworkSegment,
  onSelectLinkedAgent,
  selectedLinkedAgent,
  isDeplEnvPresent,
  openNetworkSegmentTearsheet,
  selectedNetworkSegment,
  onBlur,
  notifyCreateNameSpace,
  toggleNotifyCreateNameSpace,
}) => {
  const { t } = useTranslation('gatewaysContainer');

  return (
    <div className='skupper-gateway'>
      {notifyCreateNameSpace && (
        <InlineNotification
          kind='info'
          aria-label={t(
            'selectGatewayTypeTearSheet.warningNotification.aria-label'
          )}
          subtitle={t('createNameSpaceNotification') as string}
          title={t('createNameSpaceNotificationTitle')}
          lowContrast
          onClose={() => toggleNotifyCreateNameSpace() as any}
        />
      )}
      <div className='heading'>
        <Row className='row'>
          <Column md={4}>
            <div className='header-text'>{t('skupperEdgeGateway.header')}</div>
            <TextInput
              labelText={''}
              id='selected-gateway-type'
              value={t('skupperEdgeGateway.heading')}
              name='gateway-type'
              placeholder={''}
              autoComplete='off'
              maxLength={60}
              readOnly
            />
          </Column>
        </Row>
      </div>
      <div className='skupper-gateway-form'>
        <div className='connect-to-agent-section'>
          <div className='heading'>
            {t('skupperEdgeGateway.connectAgentSectionHeading')}
          </div>
          <div className='connect-to-agent-section-form'>
            <Row className='row skupper-gateway-form-row'>
              <Column md={4}>
                <Dropdown
                  id='gateway-network-segment'
                  className='form-field'
                  items={networkSegments?.filter(
                    networkSegment =>
                      networkSegment.compatibility_set === 'RHSI'
                  )}
                  itemToString={item => item.name}
                  renderSelectedItem={item =>
                    item.resource_id !== 'create-network-segment'
                      ? item.name
                      : t('skupperEdgeGateway.form.networkSegmentPlaceholder')
                  }
                  itemToElement={(item: NetworkSegments) => {
                    if (item.resource_id !== 'create-network-segment') {
                      return (
                        <span>
                          {`${item?.name} (${t(item.compatibility_set)})`}
                        </span>
                      );
                    } else {
                      return (
                        <div
                          className='create-network-segment-button'
                          onClick={openNetworkSegmentTearsheet}
                        >
                          {`${item.name} +`}
                        </div>
                      );
                    }
                  }}
                  onChange={(e: any) => {
                    e.selectedItem.resource_id !== 'create-network-segment' &&
                      onSelectNetworkSegment(e.selectedItem);
                  }}
                  label={t('skupperEdgeGateway.form.networkSegmentPlaceholder')}
                  titleText={t('skupperEdgeGateway.form.networkSegmentLabel')}
                />
              </Column>
              <Column md={4} className='linked-agent-col'>
                {linkedAgents != null ? (
                  <Dropdown
                    id='gateway-linked-agent'
                    className='form-field'
                    items={
                      linkedAgents?.available?.filter(
                        (agent: any) =>
                          agent?.namespace != null ||
                          agent?.namespace?.length > 0
                      ) ?? []
                    }
                    itemToString={(item: any) =>
                      item ? `${item.name} (${item.namespace})` : ''
                    }
                    onChange={(e: any) => {
                      onSelectLinkedAgent(e.selectedItem);
                      handleOnChange('name', e.selectedItem.name);
                    }}
                    label={t('skupperEdgeGateway.form.linkedAgentPlaceholder')}
                    titleText={t('skupperEdgeGateway.form.linkedAgentLabel')}
                    invalid={
                      linkedAgents === null ||
                      (Array.isArray(linkedAgents?.available) &&
                        linkedAgents?.available?.length === 0)
                    }
                    invalidText={t('linkedAgentListEmptyError')}
                  />
                ) : (
                  <SkeletonPlaceholder className='skeleton-tile linked-agent-dropdown' />
                )}
              </Column>
            </Row>
            <Row className='row skupper-gateway-form-row'>
              {selectedLinkedAgent !== null && (
                <Column md={4}>
                  <TextInput
                    labelText={
                      t('skupperEdgeGateway.form.nameSpaceLabel') as string
                    }
                    id='gateway-namespace-name'
                    name='nameSpaceName'
                    autoComplete='off'
                    maxLength={45}
                    value={selectedLinkedAgent.namespace}
                    readOnly
                  />
                </Column>
              )}
              {selectedLinkedAgent !== null && (
                <Column md={4}>
                  <TextInput
                    labelText={
                      t('skupperEdgeGateway.form.gatewayNameLabel') as string
                    }
                    id='gateway-name'
                    name='gatewayName'
                    autoComplete='off'
                    maxLength={45}
                    value={formData.name.value}
                    onChange={e => {
                      handleOnChange('name', e.target.value);
                    }}
                    invalid={formData.name.error}
                    invalidText={formData.name.errorMessage}
                    onBlur={e => onBlur('name', e.target.value)}
                  />
                </Column>
              )}
            </Row>
          </div>
        </div>
        <div className='deploy-in-agent-cluster-section'>
          {isDeplEnvPresent && (
            <>
              {Object.values(selectedDepEnv).every(
                prop => prop.length === 0
              ) && (
                <>
                  <div className='heading'>
                    {t('skupperEdgeGateway.deployButtonSectionHeading')}
                  </div>
                  <div className='sub-heading'>
                    {t('skupperEdgeGateway.deployButtonSectionSubHeading')}
                  </div>
                  <Button
                    kind='tertiary'
                    className='add-deployment-button'
                    renderIcon={Add16}
                    onClick={openDeploymentEnvTable}
                    disabled={
                      selectedLinkedAgent == null ||
                      selectedNetworkSegment == null
                    }
                  >
                    <span className='text'>
                      {t('skupperEdgeGateway.selectClusterBtn')}
                    </span>
                  </Button>
                </>
              )}
              {Object.values(selectedDepEnv).every(prop => prop.length > 0) && (
                <div className='deploy-in-cluster-form'>
                  <div className='cluster-selected-heading'>
                    {t('skupperEdgeGateway.clusterSelectedHeading')}
                  </div>
                  <Row className='row deploy-in-cluster-form-row'>
                    <Column md={6}>
                      <TextInput
                        labelText={
                          t(
                            'skupperEdgeGateway.form.clusterNameLabel'
                          ) as string
                        }
                        id='cluster-name'
                        value={formData.deployed_in_depl_env_id.value.name}
                        name='cluster-name'
                        placeholder={''}
                        autoComplete='off'
                        maxLength={60}
                        readOnly
                      />
                    </Column>
                    <Column md={2}>
                      <Button
                        kind='tertiary'
                        className='edit-deployment-button'
                        renderIcon={Edit16}
                        onClick={() => {
                          openDeploymentEnvTable();
                          onClickEditDeployedInVpcBtn();
                        }}
                      >
                        <span className='text'>
                          {t('skupperEdgeGateway.form.editBtnLabel')}
                        </span>
                      </Button>
                    </Column>
                  </Row>
                  <Row className='row deploy-in-cluster-form-row'>
                    <Column md={4}>
                      <TextInput
                        labelText={
                          t('skupperEdgeGateway.form.cloudNameLabel') as string
                        }
                        id='cloud-name'
                        value={formData.cloud.value.name}
                        name='cloud-name'
                        placeholder={''}
                        autoComplete='off'
                        maxLength={60}
                        readOnly
                      />
                    </Column>
                    <Column md={4}>
                      <TextInput
                        labelText={
                          t(
                            'skupperEdgeGateway.form.locationNameLabel'
                          ) as string
                        }
                        id='location-name'
                        value={formData.location.value.name}
                        name='location-name'
                        placeholder={''}
                        autoComplete='off'
                        maxLength={60}
                        readOnly
                      />
                    </Column>
                  </Row>
                  <Row className='row deploy-in-cluster-form-row'>
                    <Column md={4}>
                      <Dropdown
                        id='infra-resource-group'
                        label={''}
                        selectedItem={formData?.resourceGroup?.value as any}
                        onChange={data =>
                          handleOnChange('resourceGroup', data.selectedItem)
                        }
                        items={resourceGroupList ?? []}
                        itemToString={item => (item ? item.name : '')}
                        titleText={t(
                          'skupperEdgeGateway.form.resourceGroupLabel'
                        )}
                        placeholder={t('')}
                        translateWithId={t}
                      />
                    </Column>
                  </Row>
                </div>
              )}
            </>
          )}
          {!isDeplEnvPresent && (
            <Row>
              <Column md={3}>
                <div className='heading'>
                  {t('skupperEdgeGateway.deployButtonSectionHeading')}
                </div>
                <div className='empty-depl-env-container'>
                  <div className='no-deployment-env-img'>
                    {images.noDeploymentEnvsIcon()}
                  </div>
                  <div className='empty-message-heading'>
                    {t('skupperEdgeGateway.noDeplEnv.heading')}
                  </div>
                  <div className='empty-message-description'>
                    {t('skupperEdgeGateway.noDeplEnv.description')}
                  </div>
                </div>
              </Column>
            </Row>
          )}
        </div>
      </div>
    </div>
  );
};

export default SkupperGateway;
