import { MCNM_NETWORK_API_URL } from './config';
import { axiosInstance } from './api';
import {
  defaultNetworkSegmentObject,
  DEFAULT_NETWORK_SEGMENT_ID,
} from '../lib/constants';

export async function getNetworkSegments(throwError = false) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/networksegment`,
    });
    return (
      response?.data?.network_segments?.filter(
        ns => ns.resource_id !== DEFAULT_NETWORK_SEGMENT_ID
      ) ?? []
    );
  } catch (error) {
    if (throwError) {
      throw error;
    }

    console.error(error);
    if (error.response.status === 403) throw error;
  }
}

export async function addNetworkSegments(data) {
  try {
    const response = await axiosInstance({
      method: 'POST',
      url: `${MCNM_NETWORK_API_URL}/networksegment`,
      data: data,
    });
    return response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

// get particular Network Segment data
export async function getNetworkSegmentDetails(networksegment_id) {
  try {
    if (networksegment_id === DEFAULT_NETWORK_SEGMENT_ID) {
      return defaultNetworkSegmentObject;
    }
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/networksegment/${networksegment_id}`,
    });

    return response.data;
  } catch (error) {
    console.log('error in GET Network segment', error);
    throw error;
  }
}

//Delete an existing Network Segment
export async function deleteNetworkSegment(networksegment_id) {
  try {
    const response = await axiosInstance({
      method: 'DELETE',
      url: `${MCNM_NETWORK_API_URL}/networksegment/${networksegment_id}`,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

//Update an existing Network Segment
export async function updateNetworkSegments(networksegment_id, data) {
  try {
    const response = await axiosInstance({
      method: 'PUT',
      url: `${MCNM_NETWORK_API_URL}/networksegment/${networksegment_id}`,
      data: data,
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
