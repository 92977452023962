import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import GenericTruncateString from '../../../components/GenericTruncateString/GenericTruncateString';
import LabelTag from '../../../components/LabelTag/LabelTag';
import {
  DataTable,
  TableContainer,
  TableToolbar,
  TableToolbarSearch,
  Button,
  TableToolbarContent,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  DataTableSkeleton,
  TableProps,
  Pagination,
  TooltipDefinition,
} from 'carbon-components-react';
import {
  DataTableHeader,
  DataTableRow,
  DataTableCell,
} from '../../../models/dataTable';
import { Add16, TrashCan16 } from '@carbon/icons-react';
import { VerticalEmptyState } from '../../../components/EmptyState/EmptyState';
import dateUtils from '../../../lib/dates';
import images from '../../../images/images.js';
import { Role } from '../../../models/master';
import { getRoles } from '../../../controllers/roleApis.js';
import { getIdentityRoles } from '../../../controllers/identityApis.js';
import IconWithToolTip from '../../../components/IconWithToolTip/IconWithToolTip';
import './IdentityRolesTable.scss';

interface PropsInterface {
  rows: Role[] | null;
  headers: DataTableHeader[];
  loading: boolean;
  style?: any;
  openAssignIdentity?: () => void;
  sortRows(arg0: unknown, direction: string): void;
  roles?: Role[] | null;
  identityId?: any;
  roleHasNotAuthorized?: boolean;
  removeRoleCallBack: (roleId: string, roleName: string) => void;
}

const IdentityRolesTable = (Props: PropsInterface) => {
  const {
    rows,
    headers,
    loading,
    openAssignIdentity,
    sortRows,
    roles,
    identityId,
    roleHasNotAuthorized,
    removeRoleCallBack,
  } = Props;

  const { t } = useTranslation('identityDetails');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState('');
  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | 'NONE'>(
    'NONE'
  );
  const [formattedRowData, setFormattedRowData] = useState<any | null>(null);
  const [addRoleButtonDisableStatus, setAddRoleButtonDisableStatus] =
    useState(false);

  const formatTags = (data: string[]) => {
    return Array.isArray(data) && data.length > 0 ? (
      <LabelTag labelArray={data} count={3} />
    ) : (
      <p>&mdash;</p>
    );
  };

  const disableAddRoleButton = async () => {
    try {
      const allRoles = await getRoles();
      const identityRoles = await getIdentityRoles(identityId);
      const identityRolesNames = identityRoles?.map((role: any) => role.name);

      const filteredRoles = allRoles?.roles?.filter(
        (role: any) =>
          !identityRolesNames.includes(role.name) && role.name !== 'HubAdmin'
      );

      const filteredRolesNames = filteredRoles?.map((role: any) => role.name);
      if (filteredRolesNames.length === 0) {
        setAddRoleButtonDisableStatus(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    disableAddRoleButton();
  }, [roles]);

  useEffect(() => {
    formatIdentitiesData(rows);
  }, [rows, roles]);

  const formatIdentitiesData = (data: Role[] | null) => {
    const identityFormattedData: any = [];

    if (data != null && data?.length > 0) {
      data?.forEach((item: Role) => {
        let obj = {
          labels: item.labels ? formatTags(item.labels) : '—',
          name: item.name,
          name__format: (
            <Link
              className='no-underline-link'
              to={`/roleDetails?roleId=${item.resource_id}`}
            >
              <GenericTruncateString str={item?.name} tableView={true} />
            </Link>
          ),
          id: item.resource_id,
          lastUpdated: dateUtils.getUserFriendlyDate(item.updated_at),
        };
        identityFormattedData.push(obj);
        setFormattedRowData([...identityFormattedData]);
      });
    } else {
      setFormattedRowData([]);
    }
  };

  const getFilteredRows = () => {
    if (!searchText && Array.isArray(formattedRowData)) {
      return [...formattedRowData];
    }

    if (searchText && Array.isArray(formattedRowData))
      return formattedRowData?.filter(identity =>
        identity?.name?.toLowerCase().includes(searchText?.toLowerCase())
      );

    return [];
  };

  const handlePagination = (change: any) => {
    setPage(change.page);
    setPageSize(change.pageSize);
  };

  const applySearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleChangeSort = (index: number) => {
    const newSortDirection =
      sortKey === headers[index].originalKey
        ? sortDirection === 'ASC'
          ? 'DESC'
          : 'ASC'
        : 'ASC';
    setSortDirection(newSortDirection);
    const sortedKey =
      headers[index]?.originalKey !== undefined
        ? headers[index]?.originalKey ?? ''
        : '';
    setSortKey(sortedKey);
    sortRows(
      {
        id: headers[index].originalKey,
        text: headers[index].header,
      },
      newSortDirection
    );
  };

  const getEmptyStateConfig = () => {
    if (roleHasNotAuthorized) {
      return (
        <VerticalEmptyState
          icon={images.noCloudAccessIcon()}
          header={t('emptyState.notAuthorizedHeader')}
          description={t('emptyState.notAuthorizedDescription')}
        />
      );
    } else {
      if (searchText.length > 0)
        return (
          <VerticalEmptyState
            icon={images.NotFoundLarge()}
            header={t('emptyState.noResultFound')}
            description={t('emptyState.noResultDescription')}
            additionalClassName='small'
          />
        );
      else
        return (
          <VerticalEmptyState
            icon={images.noRoleIcon()}
            header={t('emptyState.emptyContainerHeader')}
            description={t('emptyState.emptyContainerDescription')}
            buttonText={t('assignRole') ?? ''}
            link={true}
            additionalClassName='small'
            click={openAssignIdentity}
          />
        );
    }
  };

  if (loading) {
    return (
      <div className='role-identities-table-container'>
        <DataTableSkeleton
          showHeader
          showToolbar
          rowCount={3}
          columnCount={7}
        />
      </div>
    );
  }
  return (
    <div className='role-identities-table-container'>
      <div className='role-identities-header'>
        {t('tableTitle', { count: rows?.length })}
      </div>
      {rows != null && rows?.length > 0 && (
        <DataTable rows={getFilteredRows()} headers={headers} isSortable>
          {({
            rows,
            headers,
            getHeaderProps,
            getRowProps,
            getTableProps,
            getToolbarProps,
            getTableContainerProps,
          }: {
            rows: DataTableRow[];
            headers: DataTableHeader[];
            getTableProps: () => TableProps;
            getHeaderProps: any;
            getRowProps: any;
            getToolbarProps: any;
            getTableContainerProps: any;
          }) => (
            <TableContainer {...getTableContainerProps()}>
              <TableToolbar {...getToolbarProps()}>
                <TableToolbarContent>
                  <TableToolbarSearch
                    expanded
                    onChange={e => applySearch(e)}
                    placeholder={t('searchPlaceholder')}
                  />
                  {!addRoleButtonDisableStatus ? (
                    <Button
                      kind='ghost'
                      className='add-identity-button'
                      renderIcon={Add16}
                      onClick={openAssignIdentity}
                    >
                      <span className='text'>{t('addRoleBtn')}</span>
                    </Button>
                  ) : (
                    <TooltipDefinition
                      tooltipText={t('roleDisableTooltipText') as string}
                      direction='bottom'
                    >
                      <Button
                        kind='ghost'
                        className='add-identity-button disabled'
                        renderIcon={Add16}
                        onClick={openAssignIdentity}
                        disabled
                      >
                        <span className='text'>{t('addRoleBtn')}</span>
                      </Button>
                    </TooltipDefinition>
                  )}
                </TableToolbarContent>
              </TableToolbar>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header: DataTableHeader, index: number) => (
                      <TableHeader
                        key={header.key}
                        {...getHeaderProps({
                          header,
                          isSortable: Boolean(header.sort),
                        })}
                        onClick={() =>
                          header.sort ? handleChangeSort(index) : undefined
                        }
                        isSortHeader={Boolean(header.sort)}
                        sortDirection={
                          headers[index].originalKey === sortKey
                            ? sortDirection
                            : 'NONE'
                        }
                      >
                        {header.header}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getFilteredRows().length > 0 &&
                    rows.map((row: DataTableRow, index: number) => (
                      <TableRow {...getRowProps({ row })} key={row.id}>
                        {row.cells.map((cell: DataTableCell) => {
                          const actionBtnId = `${row.id}:actionBtn`;
                          if (actionBtnId == cell.id) {
                            return (
                              <TableCell key={row.id + cell.id}>
                                <div
                                  className='delete-action-button'
                                  onClick={() => {
                                    removeRoleCallBack(
                                      row.id,
                                      row?.cells[0]?.value?.props?.children
                                        ?.props?.str
                                    );
                                  }}
                                >
                                  <IconWithToolTip
                                    icon={<TrashCan16 />}
                                    iconDescription={t('remove.toolTip')}
                                  />
                                </div>
                              </TableCell>
                            );
                          }
                          return (
                            <TableCell key={row.id + cell.id}>
                              {cell.value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              {getFilteredRows().length > 0 && (
                <Pagination
                  totalItems={rows.length}
                  itemsPerPageText={t('identityRoles.pagination.itemsPerPage')}
                  itemRangeText={(min, max, totalLength) => {
                    return t('identityRoles.pagination.itemKey', {
                      min: min,
                      max: max,
                      total: totalLength,
                    });
                  }}
                  pageSize={pageSize}
                  page={page}
                  pageRangeText={(current, total) =>
                    t('identityRoles.pagination.pageKey', { sum: total })
                  }
                  pageSizes={[10, 25, 50, 100, 250]}
                  onChange={change => handlePagination(change)}
                />
              )}
            </TableContainer>
          )}
        </DataTable>
      )}
      {getFilteredRows().length === 0 ? getEmptyStateConfig() : null}
    </div>
  );
};

export default IdentityRolesTable;
