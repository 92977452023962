import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './AxonGateway.scss';
import {
  Button,
  Column,
  ComboBox,
  Dropdown,
  RadioButton,
  RadioButtonGroup,
  Row,
  TextInput,
} from 'carbon-components-react';
import { Add16, Edit16 } from '@carbon/icons-react';
import { Item } from '../../DeploymentEnvTable/DeploymentEnvTable';
import { AxonConnectionMethod } from '../../RegisterGateway';
import images from '../../../../../images/images';
import { GatewayType } from '../../../GatewaysContainer';

interface Props {
  openDeploymentEnvTable: () => void;
  resourceGroupList?: any;
  handleOnChange: any;
  formData?: any;
  axonConnectionMethod: AxonConnectionMethod;
  onSelectAxonConnectionMethod: (method: AxonConnectionMethod) => void;
  selectedDepEnv: Item;
  onClickEditDeployedInVpcBtn: () => void;
  isDeplEnvPresent: boolean;
  selectedGatewayType: GatewayType;
}

const AxonGateway: React.FC<Props> = ({
  openDeploymentEnvTable,
  resourceGroupList,
  handleOnChange,
  formData,
  axonConnectionMethod,
  onSelectAxonConnectionMethod,
  selectedDepEnv,
  onClickEditDeployedInVpcBtn,
  isDeplEnvPresent,
  selectedGatewayType,
}) => {
  const { t } = useTranslation('gatewaysContainer');

  return (
    <div className='axon-gateway'>
      <div className='heading'>
        <Row className='row'>
          <Column md={4}>
            <div className='header-text'>{t('axonGateway.header')}</div>
            <TextInput
              labelText={''}
              id='selected-gateway-type'
              value={
                selectedGatewayType.resource_id === 'AXON-EDGE'
                  ? t('axonGateway.edgeGateway')
                  : t('axonGateway.waypointGateway')
              }
              name='gateway-type'
              placeholder={''}
              autoComplete='off'
              maxLength={60}
              readOnly
            />
          </Column>
        </Row>
      </div>
      <div className='axon-gateway-form'>
        <div className='axon-deploy-method'>
          <RadioButtonGroup
            legendText={t('axonGateway.selectMethodLabel')}
            name='axon-method'
            onChange={(val: AxonConnectionMethod) => {
              onSelectAxonConnectionMethod(val);
            }}
            valueSelected={axonConnectionMethod}
          >
            <RadioButton
              labelText={t('axonGateway.radioButtonAutoDeploy')}
              value='auto'
              id='axon-auto'
            />
            <RadioButton
              labelText={t('axonGateway.radioButtonManualDeploy')}
              value='manual'
              id='axon-manual'
            />
          </RadioButtonGroup>
        </div>
        <div className='deploy-in-vpc-section'>
          <div className='heading'>
            {axonConnectionMethod === 'manual'
              ? t('axonGateway.deployButtonSectionHeadingOptional')
              : t('axonGateway.deployButtonSectionHeading')}
          </div>
          {isDeplEnvPresent && (
            <>
              {Object.values(selectedDepEnv).every(
                prop => prop.length === 0
              ) && (
                <Button
                  kind='tertiary'
                  className='add-deployment-button'
                  renderIcon={Add16}
                  onClick={openDeploymentEnvTable}
                >
                  <span className='text'>{t('axonGateway.selectVpcBtn')}</span>
                </Button>
              )}
              {Object.values(selectedDepEnv).every(prop => prop.length > 0) && (
                <div className='deploy-in-vpc-form'>
                  <Row className='row deploy-in-vpc-form-row'>
                    <Column md={4}>
                      <TextInput
                        labelText={
                          t('axonGateway.form.cloudNameLabel') as string
                        }
                        id='cloud-name'
                        value={formData.cloud.value.name}
                        name='cloud-name'
                        placeholder={''}
                        autoComplete='off'
                        maxLength={60}
                        readOnly
                      />
                    </Column>
                    <Column md={4}>
                      <TextInput
                        labelText={
                          t('axonGateway.form.locationNameLabel') as string
                        }
                        id='location-name'
                        value={formData.location.value.name}
                        name='location-name'
                        placeholder={''}
                        autoComplete='off'
                        maxLength={60}
                        readOnly
                      />
                    </Column>
                  </Row>
                  <Row className='row deploy-in-vpc-form-row'>
                    <Column md={6}>
                      <TextInput
                        labelText={t('axonGateway.form.vpcNameLabel') as string}
                        id='vpc-name'
                        value={formData.deployed_in_depl_env_id.value.name}
                        name='vpc-name'
                        placeholder={''}
                        autoComplete='off'
                        maxLength={60}
                        readOnly
                      />
                    </Column>
                    <Column md={2}>
                      <Button
                        kind='tertiary'
                        className='edit-deployment-button'
                        renderIcon={Edit16}
                        onClick={() => {
                          openDeploymentEnvTable();
                          onClickEditDeployedInVpcBtn();
                        }}
                      >
                        <span className='text'>
                          {t('axonGateway.form.editBtnLabel')}
                        </span>
                      </Button>
                    </Column>
                  </Row>
                  {axonConnectionMethod === 'auto' && (
                    <Row className='row deploy-in-vpc-form-row'>
                      <Column md={4}>
                        <Dropdown
                          id='infra-resource-group'
                          label={''}
                          selectedItem={formData?.resourceGroup?.value as any}
                          onChange={data =>
                            handleOnChange('resourceGroup', data.selectedItem)
                          }
                          items={resourceGroupList ?? []}
                          itemToString={item => (item ? item.name : '')}
                          titleText={t('axonGateway.form.resourceGroupLabel')}
                          placeholder={t('')}
                          translateWithId={t}
                        />
                      </Column>
                    </Row>
                  )}
                </div>
              )}
            </>
          )}

          {!isDeplEnvPresent && (
            <Row>
              <Column md={3}>
                <div className='empty-depl-env-container'>
                  <div className='no-deployment-env-img'>
                    {images.noDeploymentEnvsIcon()}
                  </div>
                  <div className='empty-message-heading'>
                    {t('axonGateway.noDeplEnv.heading')}
                  </div>
                  <div className='empty-message-description'>
                    {axonConnectionMethod === 'auto'
                      ? t('axonGateway.noDeplEnv.autoDescription')
                      : t('axonGateway.noDeplEnv.manualDescription')}
                  </div>
                </div>
              </Column>
            </Row>
          )}
        </div>
      </div>
    </div>
  );
};

export default AxonGateway;
