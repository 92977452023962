import { ChangeEvent, useState, useEffect, useRef } from 'react';
import { Search } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import { SearchData } from '../../models/master';
import './AutofillSearch.scss';

const AutofillSearch = (props: {
  suggestions: SearchData[];
  onClick?: (i: any, t: string) => void;
}) => {
  const { t } = useTranslation('autofillSearch');
  const [showOptions, setShowOptions] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState<
    SearchData[] | null
  >(null);
  const [filteredSuggestionsCount, setFilteredSuggestionsCount] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [activeOptionIndex, setActiveOptionIndex] = useState(0);
  const suggestionMenuRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let suggestionsCount = 0;
    filteredSuggestions?.forEach(
      suggestion => (suggestionsCount += suggestion.suggestionOptions.length)
    );
    setFilteredSuggestionsCount(suggestionsCount);
  }, [filteredSuggestions]);

  useEffect(() => {
    document.addEventListener('mousedown', closeSuggestion);
    return () => {
      document.removeEventListener('mousedown', closeSuggestion);
    };
  });

  const handleOnChange = (e: ChangeEvent) => {
    let filteredSuggestions: SearchData[] = [];
    let searchInput = (e.target as HTMLInputElement).value;
    props.suggestions.forEach(searchItem => {
      filteredSuggestions.push({
        type: searchItem.type,
        suggestionTitle: searchItem.suggestionTitle,
        icon: searchItem?.icon,
        suggestionOptions: searchItem.suggestionOptions.filter(
          suggestion =>
            suggestion[searchItem.filterKey]
              .toLowerCase()
              .indexOf(searchInput.toLowerCase()) > -1
        ),
        filterKey: searchItem.filterKey,
        enableCustomDiv: searchItem?.enableCustomDiv,
        suggestionDisplayKey: searchItem?.suggestionDisplayKey,
      });
    });
    searchInput.length === 0 && setActiveOptionIndex(0);
    setSearchInput(searchInput);
    setFilteredSuggestions(filteredSuggestions);
    setShowOptions(true);
  };

  const handleOptionClick = (item: any, type: string) => {
    props.onClick?.(item, type);
    setShowOptions(false);
    setSearchInput('');
    setFilteredSuggestions([]);
  };

  const handleKeyDown = (e: any) => {
    // UP ARROW
    if (e.keyCode === 38) {
      e.preventDefault();
      if (activeOptionIndex === 0) {
        setActiveOptionIndex(filteredSuggestionsCount);
        return;
      }
      setActiveOptionIndex(activeOptionIndex - 1);
    }
    // DOWN ARROW
    else if (e.keyCode === 40) {
      e.preventDefault();
      if (activeOptionIndex - 1 === filteredSuggestionsCount) {
        setActiveOptionIndex(1);
        return;
      }
      setActiveOptionIndex(activeOptionIndex + 1);
    }
    // ENTER
    else if (e.keyCode === 13) {
      let prevChildCount = 0;
      let count = 0;
      filteredSuggestions?.forEach(suggestion => {
        prevChildCount += count;
        count = 0;
        suggestion.suggestionOptions.forEach((option, index) => {
          count++;
          if (index === activeOptionIndex - prevChildCount - 1) {
            props.onClick?.(option, suggestion.type);
          }
        });
      });
      setActiveOptionIndex(0);
      setShowOptions(false);
      setSearchInput('');
      setFilteredSuggestions([]);
    }
  };

  const autofillSuggestions = () => {
    let prevChildCount = 0,
      count = 0;

    if (filteredSuggestions != null && filteredSuggestionsCount > 0) {
      return (
        <div className='autofill-suggestion-list'>
          {filteredSuggestions.map(suggestion => {
            const Icon = suggestion?.icon;
            prevChildCount += count;
            count = 0;
            return (
              <div
                className={`${suggestion.type}--list autofill-suggestion-category`}
                key={suggestion.suggestionTitle}
              >
                {suggestion.suggestionOptions.length > 0 && (
                  <div className='heading'>{suggestion.suggestionTitle}</div>
                )}
                <div className='autofill-suggestion-options'>
                  {suggestion.suggestionOptions.map((option, index) => {
                    count++;
                    return (
                      <div
                        className={
                          index === activeOptionIndex - prevChildCount - 1
                            ? 'autofill-option active'
                            : 'autofill-option'
                        }
                        onClick={() =>
                          handleOptionClick(option, suggestion.type)
                        }
                        key={option.id}
                      >
                        {Icon && (
                          <div className='option-icon'>
                            <Icon />
                          </div>
                        )}
                        {suggestion.enableCustomDiv ? (
                          <div className='option-name'>{option.customDiv}</div>
                        ) : (
                          <div className='option-name'>
                            {option[suggestion.suggestionDisplayKey]}
                          </div>
                        )}
                        <div className='add-icon'>{t('addIcon')}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div className='no-suggestions'>
          <div className='no-suggestions-message'>{t('noResult')}</div>
        </div>
      );
    }
  };

  const closeSuggestion = (e: any) => {
    if (
      suggestionMenuRef.current &&
      showOptions &&
      !suggestionMenuRef.current.contains(e.target)
    ) {
      setShowOptions(false);
      setSearchInput('');
      setFilteredSuggestions([]);
    }
  };

  return (
    <div className='autofill-search-component' ref={suggestionMenuRef}>
      <Search
        closeButtonLabelText='Clear search input'
        id='search-1'
        labelText='Search for resources to add'
        onChange={(e: ChangeEvent) => handleOnChange(e)}
        size='lg'
        value={searchInput}
        placeholder={t('searchPlaceholder')}
        onClear={() => {
          setShowOptions(false);
          setSearchInput('');
          setFilteredSuggestions([]);
          setActiveOptionIndex(0);
        }}
        // onKeyDown={(e: any) => {
        //   handleKeyDown(e);
        // }}
      />
      {showOptions && searchInput && (
        <div className='autofill-suggestion-container'>
          {autofillSuggestions()}
        </div>
      )}
    </div>
  );
};
export default AutofillSearch;
