import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ClickableTile, Row, Column } from 'carbon-components-react';
import dateUtils from '../../../lib/dates';
import LabelTag, { TagType } from '../../../components/LabelTag/LabelTag';
import GenericTruncateString from '../../../components/GenericTruncateString/GenericTruncateString';
import './RoleCard.scss';
import { Identity } from '../../../models/master';

const RoleCard = ({
  roleId,
  resourceName,
  identities,
  tags,
  updated,
}: {
  roleId: string;
  resourceName: string;
  identities: Identity[];
  tags: string[] | TagType[];
  updated: string;
}) => {
  const { t } = useTranslation('roles');
  const navigate = useNavigate();

  return (
    <ClickableTile
      className='roles-card'
      onClick={() => roleId && navigate(`/roleDetails?roleId=${roleId}`)}
    >
      <Row className='header'>
        <Column className='name-row'>
          <div className='name' title={resourceName ?? ''}>
            {!!resourceName ? (
              <GenericTruncateString
                str={resourceName}
                maxLength={30}
                limit={13}
              />
            ) : (
              '—'
            )}
          </div>
        </Column>
      </Row>
      <Row className='data'>
        <Column>
          <div className='label-text'>{t('cardSubheading.identities')}</div>
          <div className='value'>{identities.length}</div>
        </Column>
      </Row>

      <Row className='tags'>
        <Column className='label-col'>
          <LabelTag labelArray={tags} singleLine={true} />
        </Column>
      </Row>
      <Row>
        <Column>
          <div className='helper-text'>
            {t('updated', {
              date: !!updated ? dateUtils.getUserFriendlyDate(updated) : '—',
            })}
          </div>
        </Column>
      </Row>
    </ClickableTile>
  );
};

export default RoleCard;
