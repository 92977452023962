import React from 'react';
import { CreateTearsheetStep } from '@carbon/ibm-products';
import { Column, Row, TextInput, TextArea } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import AddLabels from '../../../../components/AddLabels/AddLabels';
import './AddIdentityDetails.scss';
import IdentityTag from '../../../../components/IdentityTag/IdentityTag';
import { FormData } from '../CreateIdentity';
interface Props {
  formData: FormData;
  onChange: (e: { target: { name: string; value: any } }) => void;
  onBlur: (e: { target: { name: string; value: any } }) => void;
  checkFormValid: boolean;
  addedIdentityDetails: { name: string | null; resourceId: string | null };
  children: any;
}

const AddIdentityDetails: React.FC<Props> = ({
  formData,
  onChange,
  checkFormValid,
  onBlur,
  addedIdentityDetails,
  children,
}) => {
  const { name, description, resourceId, labels } = formData;
  const { t } = useTranslation('identity');

  return (
    <>
      {/* Render error snackbar */}
      {children}
      <CreateTearsheetStep
        className='add-identity-details'
        title={t('createIdentityPage.addDetails.title')}
        subtitle={t('createIdentityPage.addDetails.subtitle')}
        disableSubmit={!checkFormValid}
      >
        {addedIdentityDetails?.name ? (
          <Row className='row'>
            <Column md={8}>
              <IdentityTag
                name={addedIdentityDetails?.name?.trim() ?? ''}
                showName
                mailId={addedIdentityDetails.resourceId ?? ''}
                size='xlg'
              />
            </Column>
          </Row>
        ) : null}
        <Row className='row'>
          <Column md={4}>
            <TextInput
              labelText={t('createIdentityPage.addDetails.name') as string}
              id='create-identity-name'
              value={name.value}
              name='name'
              placeholder={t(
                'createIdentityPage.addDetails.identityNamePlaceholder'
              )}
              onChange={onChange}
              onBlur={onBlur}
              invalid={name.error}
              invalidText={name.errorMessage}
              autoComplete='off'
              maxLength={80}
            />
          </Column>
          <Column md={4}>
            <TextInput
              labelText={t('createIdentityPage.addDetails.emailId') as string}
              id='create-identity-mail'
              value={resourceId.value}
              name='resourceId'
              placeholder={t(
                'createIdentityPage.addDetails.emailIdPlaceholder'
              )}
              onChange={onChange}
              invalid={resourceId.error}
              invalidText={resourceId.errorMessage}
              onBlur={onBlur}
              autoComplete='off'
              maxLength={80}
              type='email'
            />
          </Column>
        </Row>
        <Row className='row'>
          <Column md={8}>
            <TextArea
              id='create-identity-desciption'
              name='description'
              autoComplete='off'
              labelText={t('createIdentityPage.addDetails.description')}
              placeholder={t(
                'createIdentityPage.addDetails.descriptionPlaceholder'
              )}
              onChange={onChange}
              value={description.value}
              maxCount={300}
              enableCounter={true}
            />
          </Column>
        </Row>
        <Row className='row'>
          <Column md={8}>
            <AddLabels
              id='create-identity-labels'
              labelText={t('createIdentityPage.addDetails.labelName')}
              placeholder={t('createIdentityPage.addDetails.labelPlaceholder')}
              onChange={data =>
                onChange({ target: { name: 'labels', value: data } })
              }
              btnText={t('createIdentityPage.addDetails.labelbtnText')}
              btnKind='secondary'
              tagType='green'
              defaultValues={labels.value}
            />
          </Column>
        </Row>
      </CreateTearsheetStep>
    </>
  );
};

export default AddIdentityDetails;
