import { useQuery } from '@tanstack/react-query';
import { getNetworkSegments } from '../controllers/networksegment';
import { QueryOptions, queryDefaultOptions } from './queryDefaults';
import { QueryKeys } from '../lib/enums';

export const useNetworksegments = (options?: QueryOptions) => {
  return useQuery({
    queryKey: [QueryKeys.NETWORKSEGMENTS],
    queryFn: () => getNetworkSegments(),
    ...queryDefaultOptions,
    ...options,
  });
};
