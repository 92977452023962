import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { Link } from 'react-router-dom';
import SidePanelDetailsComponent from '../../../CustomSidePanel/SidePanelDetailsComponent/SidePanelDetailsComponent';
import SidePanelTableComponent from '../../../CustomSidePanel/TableComponent/SidePanelTableComponent';
import {
  getApplication,
  getApplicationService,
  getDeployments,
} from '../../../../controllers/applicationApis';
import { SkeletonPlaceholder, Tab, Tabs } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import CustomSidePanel from '../../../CustomSidePanel/CustomSidePanel';
import { EdgeService16 } from '@carbon/icons-react';
import IconWithToolTip from '../../../IconWithToolTip/IconWithToolTip';
import images from '../../../../images/images';
import {
  Service,
  Port,
  ApplicationData,
  NetworkSegments,
  ResourceGroup,
  DeploymentData,
  Deployment,
  Partition,
} from '../../../../models/master';
import { NodeSidePaneDetailsComponentProps } from '../config';
import SidePanelEmptyState from '../../../CustomSidePanel/SidePanelEmptyState/SidePanelEmptyState';
import SidePanelDetailsCard from '../../../CustomSidePanel/SidePanelDetailsComponent/SidePanelDetailsCard';
import { getNetworkSegmentDetails } from '../../../../controllers/networksegmentsApi';
import { getResourceGroup } from '../../../../controllers/resourceGroupApi';
import {
  getDeploymentEnv,
  getPartition,
} from '../../../../controllers/deploymentEnv';

import './ServiceDetails.scss';
import { DEFAULT_NETWORK_SEGMENT_ID } from '../../../../lib/constants';

const defaultPermissionMap = {
  app: true,
  service: true,
  resourceGroup: true,
  deploymentEnv: true,
  partitions: true,
  networkSegments: true,
};

const defaultServerErrorMap = {
  service: '',
};

const ServiceDetails: React.FC<NodeSidePaneDetailsComponentProps> = ({
  node,
  open,
  onClose,
  sidePanelWidth,
  handleSidePanelWidth,
  handleHeaderWidth,
  initialHeaderWidth,
  headerWidth,
}) => {
  const { t } = useTranslation('topologySidePanelServiceDetails');
  const [serviceData, setServiceData] = useState<Service | null>(null);
  const [appDetailsData, setAppDetailsData] = useState<ApplicationData | null>(
    null
  );
  const [networkSegmentsData, setNetworkSegmentsData] =
    useState<NetworkSegments | null>(null);
  const [resourceGroup, setResourceGroup] = useState<ResourceGroup | null>(
    null
  );
  const [deploymentData, setDeploymentData] = useState<DeploymentData[] | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const [permissionMap, setPermissionMap] = useState(defaultPermissionMap);
  const [serverErrorMap, setServerErrorMap] = useState(defaultServerErrorMap);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let applicationData: ApplicationData | null = null;
        try {
          const serviceData: Service = await getApplicationService(
            node.application_id,
            node.service_uniqueId,
            true
          );
          setServiceData(serviceData);

          applicationData = await getApplication(node?.application_id);
          setAppDetailsData(applicationData);
        } catch (error) {
          const err = error as AxiosError;
          if (
            err?.response?.status === 403 &&
            err?.response?.statusText === 'Forbidden'
          ) {
            setPermissionMap(permissionMap => ({
              ...permissionMap,
              service: false,
            }));
          }

          const errorCode = err.response!?.status;
          if (errorCode >= 500) {
            setServerErrorMap(permissionMap => ({
              ...permissionMap,
              service: errorCode.toString(),
            }));
          }
        }
        if (applicationData?.network_segment_id) {
          try {
            const networkSegment: NetworkSegments =
              await getNetworkSegmentDetails(
                applicationData.network_segment_id
              );
            setNetworkSegmentsData(networkSegment);
          } catch (error) {
            const err = error as AxiosError;
            if (
              err?.response?.status === 403 &&
              err?.response?.statusText === 'Forbidden'
            ) {
              setPermissionMap(permissionMap => ({
                ...permissionMap,
                networkSegments: false,
              }));
            }
          }
        }

        try {
          const deploymentsData: Deployment[] = await getDeployments(
            applicationData?.resource_id
          );

          formatDeploymentData(deploymentsData);
        } catch (error) {
          const err = error as AxiosError;
          if (
            err?.response?.status === 403 &&
            err?.response?.statusText === 'Forbidden'
          ) {
            setPermissionMap(permissionMap => ({
              ...permissionMap,
              deploymentsData: false,
            }));
          }

          const errorCode = err.response!?.status;
          if (errorCode >= 500) {
            setServerErrorMap(permissionMap => ({
              ...permissionMap,
              service: errorCode.toString(),
            }));
          }
        }

        if (applicationData?.resource_group_id) {
          try {
            const resourceGroup: ResourceGroup = await getResourceGroup(
              applicationData.resource_group_id
            );
            setResourceGroup(resourceGroup);
          } catch (error) {
            const err = error as AxiosError;
            if (
              err?.response?.status === 403 &&
              err?.response?.statusText === 'Forbidden'
            ) {
              setPermissionMap(permissionMap => ({
                ...permissionMap,
                resourceGroup: false,
              }));
            }
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleNetworkSegmentClick = (data: NetworkSegments | null) => {
    window.open(
      window.location.origin +
        process.env.PUBLIC_URL +
        `/networkSegmentDetails?nwSegId=${networkSegmentsData?.resource_id}`
    );
  };

  const handleApplicationClick = (data: ApplicationData | null) => {
    window.open(
      window.location.origin +
        process.env.PUBLIC_URL +
        `/ApplicationDetails?appId=${appDetailsData?.resource_id}`
    );
  };

  const formatAppDetailsData = () => {
    const formattedData = [
      {
        label: t('networkSegment'),
        value: networkSegmentsData?.name,
        valueType:
          networkSegmentsData?.resource_id === DEFAULT_NETWORK_SEGMENT_ID
            ? 'displayField'
            : 'link',
        linkCallback: () => handleNetworkSegmentClick(networkSegmentsData),
        notAuthorized: !permissionMap['networkSegments'],
      },
      {
        label: t('application'),
        value: appDetailsData?.name,
        valueType: 'link',
        linkCallback: () => handleApplicationClick(appDetailsData),
        notAuthorized: !permissionMap['service'],
      },
      {
        label: t('applicationGroup'),
        value: resourceGroup?.name,
        valueType: 'displayField',
        notAuthorized:
          !permissionMap['service'] || !permissionMap['resourceGroup'],
      },
    ];
    return formattedData;
  };

  const formatDeploymentData = (deployments: any) => {
    try {
      const depData: DeploymentData[] = [];
      if (deployments != null && deployments.length > 0) {
        deployments?.map((item: any) => {
          let environmentName: string;
          let partitionName: string;
          let partition: Partition;
          const fetchData = async () => {
            try {
              const environment = await getDeploymentEnv(item['depl_env_id']);

              if (item['depl_env_id'] && item['partition_id']) {
                partition = await getPartition(
                  item['depl_env_id'],
                  item['partition_id']
                );
              }
              partitionName = partition?.name;
              environmentName =
                partitionName != null && partitionName.length > 0
                  ? environment?.name + '/' + partitionName
                  : environment?.name;
              let obj = {
                id: item['resource_id'],
                environment: {
                  id: item['depl_env_id'],
                  name: environmentName,
                },
                partition: partitionName,
              };
              depData.push(obj);
              setDeploymentData([...depData]);
            } catch (error) {
              console.error(error);
            }
          };
          fetchData();
        });
      } else {
        setDeploymentData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formatOtherDepl = () => {
    let rows: {
      name: JSX.Element | undefined;
    }[] = [];
    if (deploymentData) {
      deploymentData?.forEach(depEnv => {
        rows.push({
          name: (
            <div className='sidepanel-table-link'>
              <Link
                to={`/applicationDeplDetails?appId=${appDetailsData?.resource_id}&deplId=${depEnv?.id}`}
                target='_blank'
                className='no-underline-link hyperLink'
              >
                {depEnv?.environment?.name}
              </Link>
            </div>
          ),
        });
      });
    }
    return rows;
  };

  const getHeaderTitle = () => {
    if (serviceData?.is_discovered) {
      return (
        <div className='header-title'>
          <span>
            <EdgeService16 />{' '}
            {node?.name?.charAt(0).toUpperCase() + node?.name?.slice(1)}{' '}
          </span>
          <span className='page-title-icon'>
            <IconWithToolTip
              icon={images.AutoDiscoverdLockIcon()}
              iconDescription={t('autoDiscoveredAccessLimited')}
            />
          </span>
        </div>
      );
    }

    if (node) {
      return (
        <div>
          <EdgeService16 />{' '}
          {node?.name?.charAt(0).toUpperCase() + node?.name?.slice(1)}
        </div>
      );
    }

    return '';
  };

  const getLink = () => {
    if (node) {
      return `/ApplicationDetails?appId=${node.application_id}` ?? ' ';
    }

    return '';
  };

  const title = getHeaderTitle();
  const link = getLink();

  return (
    <div className='topology-service-details-container'>
      <CustomSidePanel
        open={open}
        onClose={onClose}
        title={title}
        link={link}
        sidePanelWidth={sidePanelWidth}
        handleSidePanelWidth={handleSidePanelWidth}
        handleHeaderWidth={handleHeaderWidth}
        initialHeaderWidth={initialHeaderWidth}
        headerWidth={headerWidth}
      >
        <Tabs>
          <Tab id='topology-details-tab-1' label={t('details')}>
            <SidePanelDetailsComponent title={t('services')}>
              {loading && (
                <div className='skeleton-container'>
                  <SkeletonPlaceholder className='service-skeleton'></SkeletonPlaceholder>
                  <SkeletonPlaceholder className='service-skeleton'></SkeletonPlaceholder>
                  <SkeletonPlaceholder className='service-skeleton'></SkeletonPlaceholder>
                  <SkeletonPlaceholder className='service-skeleton'></SkeletonPlaceholder>
                  <SkeletonPlaceholder className='service-skeleton'></SkeletonPlaceholder>
                </div>
              )}
              {!loading && serviceData ? (
                <div>
                  <SidePanelDetailsCard data={formatAppDetailsData()} />
                  <SidePanelTableComponent
                    headers={[
                      { key: 'port_number', header: t('port') },
                      { key: 'protocol', header: t('protocol') },
                    ]}
                    rows={serviceData?.ports as Port[]}
                  />
                </div>
              ) : !loading ? (
                <SidePanelEmptyState
                  icon={images.noServicesSmallIcon()}
                  header={t('emptyState.header')}
                  message={t('emptyState.message')}
                  btnText={t('emptyState.btnText')}
                  btnUrl={`/ApplicationDetails?appId=${node.application_id}`}
                  resourceType='SERVICE'
                  notAuthorized={!permissionMap['service']}
                  error500={!!serverErrorMap['service']}
                />
              ) : null}
            </SidePanelDetailsComponent>
            <SidePanelDetailsComponent
              title={`${t('otherDeployments')} ${
                Array.isArray(deploymentData)
                  ? '(' + deploymentData?.length + ')'
                  : ''
              }`}
            >
              {loading && (
                <div className='skeleton-container'>
                  <SkeletonPlaceholder className='service-skeleton'></SkeletonPlaceholder>
                  <SkeletonPlaceholder className='service-skeleton'></SkeletonPlaceholder>
                  <SkeletonPlaceholder className='service-skeleton'></SkeletonPlaceholder>
                  <SkeletonPlaceholder className='service-skeleton'></SkeletonPlaceholder>
                  <SkeletonPlaceholder className='service-skeleton'></SkeletonPlaceholder>
                </div>
              )}
              {!loading && serviceData && deploymentData ? (
                <SidePanelTableComponent
                  headers={[{ key: 'name', header: t('DeplEnvAndPartition') }]}
                  rows={formatOtherDepl()}
                />
              ) : !loading ? (
                <SidePanelEmptyState
                  icon={images.noAppDeploymentSmallIcon()}
                  header={t('emptyState.deployments.header')}
                  message={t('emptyState.deployments.message')}
                  btnText={t('emptyState.deployments.btnText')}
                  btnUrl={`/ApplicationDetails?appId=${node.application_id}`}
                  resourceType='ENVIRONMENT'
                  notAuthorized={
                    !permissionMap['app'] || !permissionMap['deploymentEnv']
                  }
                  error500={!!serverErrorMap['service']}
                />
              ) : null}
            </SidePanelDetailsComponent>
          </Tab>
        </Tabs>
      </CustomSidePanel>
    </div>
  );
};

export default ServiceDetails;
