import React from 'react';
import { useTranslation } from 'react-i18next';
import { TooltipDefinition } from 'carbon-components-react';
import { VerticalDirection } from 'carbon-components-react/typings/shared';
import { HealthStatuses } from '../../lib/enums';
import { HealthStatusType, getHealthStatusConfig } from './config';

import './GatewayStatus.scss';

interface Props {
  status: HealthStatusType;
  showLabel?: boolean;
  gatewayName?: string;
  viewEventsLink?: boolean;
  tooltipPosition?: VerticalDirection;
}

const HealthStatus: React.FC<Props> = ({
  status,
  gatewayName,
  showLabel = true,
  tooltipPosition = 'bottom',
}) => {
  const { t } = useTranslation('gatewayStatus');
  const config = getHealthStatusConfig(t, status);

  if (config) {
    const Icon = config.icon ?? null;
    if (status === HealthStatuses.UNREACHABLE) {
      return (
        <TooltipDefinition
          tooltipText={t('viewEvent')}
          direction={tooltipPosition}
          align='center'
        >
          <div
            className='health-status-component tooltip'
            onClick={e => {
              e.preventDefault();
              window.open(
                window.location.origin +
                  process.env.PUBLIC_URL +
                  `/event?resource_name=${gatewayName}`
              );
            }}
          >
            {Icon && <span className='icon'>{Icon}</span>}
            {showLabel && <span className='label'>{config.label}</span>}
          </div>
        </TooltipDefinition>
      );
    }

    return (
      <div className='health-status-component'>
        {Icon && <span className='icon'>{Icon}</span>}
        {showLabel && <span className='label'>{config.label}</span>}
      </div>
    );
  }

  return <></>;
};

export default HealthStatus;
