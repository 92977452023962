import { axiosInstance } from './api';

export async function getEvent(event_id) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `/events/event/${event_id}`,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function updateEvent(event_id, data) {
  try {
    const response = await axiosInstance({
      method: 'PUT',
      url: `/events/event/${event_id}`,
      data: data,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Delete an existing event that MCNM oversees
export async function deleteEvent(event_id) {
  try {
    const response = await axiosInstance({
      method: 'DELETE',
      url: `/events/event/${event_id}`,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Get all the events that MCNM oversees across all events
export async function getAllEvents() {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `/events/event`,
    });
    return response.data.events;
  } catch (error) {
    // console.log(error);
    throw error;
  }
}
