import {
  ClickableTile,
  Column,
  Row,
  TooltipDefinition,
} from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import HealthStatus from '../../../components/GatewayStatus/HealthStatus';
import ProceduralStatus from '../../../components/GatewayStatus/ProceduralStatus';
import {
  HealthStatusType,
  ProceduralStatusType,
} from '../../../components/GatewayStatus/config';
import GenericTruncateString from '../../../components/GenericTruncateString/GenericTruncateString';
import images from '../../../images/images';
import dateUtils from '../../../lib/dates';

import GenericStatusField from '../../../components/GenericStatusField/GenericStatusField';
import './GatewaysCard.scss';
import { DEFAULT_NETWORK_SEGMENT_ID } from '../../../lib/constants';

const GatewaysCard = ({
  name,
  id,
  status,
  health_status,
  type,
  subType,
  location,
  cloud,
  resourceGroup,
  publicIp,
  cloud_id,
  location_id,
  path,
  updated_at,
  locationPermission,
  networkSegmentPermission,
  network_segment_name,
  network_segment_id,
  version,
}: {
  name: string;
  id: string;
  status: string;
  health_status: string;
  type: string;
  subType: string | undefined;
  location: string;
  cloud: string | undefined;
  resourceGroup: string;
  publicIp: string;
  cloud_id: string | undefined;
  location_id: string | undefined;
  path?: string;
  updated_at: string;
  locationPermission: boolean;
  networkSegmentPermission: boolean;
  network_segment_name: string | undefined;
  network_segment_id: string | undefined;
  version: string | number;
}) => {
  const { t } = useTranslation('gatewaysContainer');
  const navigate = useNavigate();

  const gatewayHelathStatusIcon = () => {
    let icon;
    icon = (
      <HealthStatus
        status={health_status as HealthStatusType}
        showLabel={false}
        viewEventsLink={true}
        gatewayName={name}
      />
    );
    return icon;
  };

  const gatewayStatus = (
    status: string,
    name: string,
    version: string | number
  ) => {
    let statusWithIcon;
    statusWithIcon = (
      <span className='procedural__status'>
        <ProceduralStatus
          status={status as ProceduralStatusType}
          gatewayName={name}
          version={version}
        />
      </span>
    );
    return statusWithIcon;
  };
  return (
    <ClickableTile
      className='resource-card1'
      key={id}
      onClick={() => path && navigate(path)}
    >
      <Row className='header'>
        <Column lg={13}>
          <div className='name'>
            {
              <>
                <TooltipDefinition
                  tooltipText={t(type) + t('gateway')}
                  direction='bottom'
                  align='center'
                >
                  {type === 'edge'
                    ? images.EdgeGatewayIcon()
                    : images.WaypointGatewayIcon()}
                </TooltipDefinition>
                <GenericTruncateString str={name} tableView={false} limit={9} />
              </>
            }
          </div>
        </Column>
        <Column lg={3}>
          <div
            className={`health-status status-${health_status?.toLowerCase()}`}
          >
            {gatewayHelathStatusIcon()}
          </div>
        </Column>
      </Row>
      <Row className='data'>
        <div className='bx--col-md-4'>
          <div className='label-text'>{t('cardSubheading.type')}</div>
          <div className='value' id={`gateway-type-${id}`}>
            {subType ? (
              <GenericTruncateString
                str={t(`gatewayTypes.${subType}`)}
                tableView={false}
                truncateEndOfString={true}
                containerId={`gateway-type-${id}`}
              />
            ) : (
              '—'
            )}
          </div>
        </div>
        <div className='bx--col-md-4'>
          <div className='label-text'>{t('cardSubheading.location')}</div>
          <div className='value'>
            {!locationPermission ? (
              <GenericStatusField status='notAuthorised'></GenericStatusField>
            ) : !!location ? (
              <Link
                className='no-underline-link'
                to={`/locationDetails?cloudId=${cloud_id}&locationId=${location_id}`}
                id={`gateway-location-${id}`}
              >
                {
                  <GenericTruncateString
                    str={location}
                    truncateEndOfString={true}
                    containerId={`gateway-location-${id}`}
                  />
                }
              </Link>
            ) : (
              '—'
            )}
          </div>
        </div>
      </Row>

      <Row className='data'>
        <div className='bx--col-md-4'>
          <div className='label-text'>{t('cardSubheading.networkSegment')}</div>
          <div className='value'>
            {!networkSegmentPermission ? (
              <GenericStatusField status='notAuthorised'></GenericStatusField>
            ) : !!network_segment_name ? (
              network_segment_id === DEFAULT_NETWORK_SEGMENT_ID ? (
                <GenericTruncateString
                  str={network_segment_name}
                  truncateEndOfString={true}
                  containerId={`gateway-networksegment-${id}`}
                />
              ) : (
                <Link
                  className='no-underline-link'
                  to={`/networkSegmentDetails?nwSegId=${network_segment_id}`}
                  id={`gateway-networksegment-${id}`}
                >
                  {
                    <GenericTruncateString
                      str={network_segment_name}
                      truncateEndOfString={true}
                      containerId={`gateway-networksegment-${id}`}
                    />
                  }
                </Link>
              )
            ) : (
              '—'
            )}
            {/* {!!network_segment ? network_segment : '—'} */}
          </div>
        </div>
        <div className='bx--col-md-4'>
          <div className='label-text'>{t('cardSubheading.version')}</div>
          <div className='value'>{gatewayStatus(status, name, version)}</div>
        </div>
      </Row>

      <Row className='card-footer'>
        <Column>
          <div className='helper-text'>
            {t('cardSubheading.updated_at', {
              date: !!updated_at
                ? dateUtils.getUserFriendlyDate(updated_at)
                : updated_at,
            })}
          </div>
        </Column>
      </Row>
    </ClickableTile>
  );
};

export default GatewaysCard;
