import { SkeletonPlaceholder, Tab, Tabs } from 'carbon-components-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import {
  DeploymentEnvironment,
  Location as LocationDetailsType,
} from '../../../../models/master';
import SidePanelDetailsCard from '../../../CustomSidePanel/SidePanelDetailsComponent/SidePanelDetailsCard';
import SidePanelDetailsComponent from '../../../CustomSidePanel/SidePanelDetailsComponent/SidePanelDetailsComponent';
import SidePanelTableComponent from '../../../CustomSidePanel/TableComponent/SidePanelTableComponent';
import CustomSidePanel from '../../../CustomSidePanel/CustomSidePanel';
import { Map16, Cloud16 } from '@carbon/icons-react';
import IconWithToolTip from '../../../IconWithToolTip/IconWithToolTip';
import images from '../../../../images/images';
import './LocationDetails.scss';
import { getDeploymentEnvs } from '../../../../controllers/deploymentEnv';
import { getLocationDetails } from '../../../../controllers/locationApis';
import { getCloud } from '../../../../controllers/cloudApis';
import { NodeSidePaneDetailsComponentProps } from '../config';
import SidePanelEmptyState from '../../../../components/CustomSidePanel/SidePanelEmptyState/SidePanelEmptyState';
import { Link } from 'react-router-dom';
import { VisibilityFlags } from '../../../../lib/enums';

const defaultPermissionMap = {
  deploymentEnvs: true,
  location: true,
  cloud: true,
};

const defaultServerErrorMap = {
  deploymentEnvs: '',
  location: '',
};

const LocationDetails: React.FC<NodeSidePaneDetailsComponentProps> = ({
  node,
  open,
  onClose,
  sidePanelWidth,
  handleSidePanelWidth,
  handleHeaderWidth,
  initialHeaderWidth,
  visibility = VisibilityFlags.MANAGED,
  headerWidth,
}) => {
  const { t } = useTranslation('topologySidePanelLocationDetails');
  const [loading, setLoading] = useState(false);
  const [deploymentEnvData, setDeploymentEnvData] = useState<
    DeploymentEnvironment[] | []
  >([]);
  const [locationDetails, setLocationDetails] =
    useState<LocationDetailsType | null>(null);
  const deploymentTableRows = () => {
    let rows: any = [];
    if (deploymentEnvData) {
      deploymentEnvData.forEach(dev => {
        rows.push({
          name: (
            <Link
              to={`/deploymentEnvironmentDetails?deplId=${dev?.resource_id}`}
              target='_blank'
              className='no-underline-link hyperLink'
            >
              {dev?.name}
            </Link>
          ),
        });
      });
    }
    return rows;
  };
  const [cloudName, setCloudName] = useState<string>('');
  const [permissionMap, setPermissionMap] = useState(defaultPermissionMap);
  const [serverErrorMap, setServerErrorMap] = useState(defaultServerErrorMap);

  const locationDetailsLabels = () => {
    const labels: any = [];
    if (locationDetails?.labels) {
      locationDetails.labels.forEach(el => {
        labels.push(el);
      });
    }
    if (locationDetails?.discovered_labels) {
      locationDetails.discovered_labels.forEach(el => {
        labels.push({
          default: true,
          value: el,
        });
      });
    }
    return labels;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        try {
          const deploymentEnv: DeploymentEnvironment[] =
            await getDeploymentEnvs(visibility, true);
          setDeploymentEnvData(
            deploymentEnv.filter(item => item.location_id === node?.uniqueId)
          );
        } catch (error) {
          const err = error as AxiosError;
          if (
            err?.response?.status === 403 &&
            err?.response?.statusText === 'Forbidden'
          ) {
            setPermissionMap(permissionMap => ({
              ...permissionMap,
              deploymentEnvs: false,
            }));
          }

          const errorCode = err.response!?.status;
          if (errorCode >= 500) {
            setServerErrorMap(permissionMap => ({
              ...permissionMap,
              deploymentEnvs: errorCode.toString(),
            }));
          }
        }

        try {
          const locationInfo = await getLocationDetails(
            node?.cloud_id,
            node?.uniqueId,
            visibility,
            true
          );
          setLocationDetails(locationInfo);
        } catch (error) {
          const err = error as AxiosError;
          if (
            err?.response?.status === 403 &&
            err?.response?.statusText === 'Forbidden'
          ) {
            setPermissionMap(permissionMap => ({
              ...permissionMap,
              location: false,
            }));
          }

          const errorCode = err.response!?.status;
          if (errorCode >= 500) {
            setServerErrorMap(permissionMap => ({
              ...permissionMap,
              location: errorCode.toString(),
            }));
          }
        }

        if (node?.cloud_name) {
          setCloudName(node.cloud_name);
        } else {
          setPermissionMap(permissionMap => ({
            ...permissionMap,
            cloud: false,
          }));
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [node]);

  const setAddressData = () => {
    const addressArray = [];
    if (locationDetails?.street)
      addressArray.push(`${locationDetails?.street},`);
    if (locationDetails?.city) addressArray.push(`${locationDetails?.city},`);
    if (locationDetails?.region)
      addressArray.push(`${locationDetails?.region},`);
    if (locationDetails?.country)
      addressArray.push(`${locationDetails?.country}`);
    return addressArray;
  };

  const handleCloudClick = (data: LocationDetailsType | null) => {
    window.open(
      window.location.origin +
        process.env.PUBLIC_URL +
        `/cloudDetails?cloudId=${data?.cloud_id}`
    );
  };

  const formatAppDetailsData = () => {
    const formattedData = [
      {
        label: t('type'),
        value: locationDetails?.type,
        valueType: 'displayField',
        notAuthorized: !permissionMap['location'],
      },
      {
        label: t('cloud'),
        value: cloudName,
        valueType: 'link',
        linkCallback: () => handleCloudClick(locationDetails),
        notAuthorized: !permissionMap['cloud'],
      },
      {
        label: t('address'),
        value: setAddressData(),
        valueType: 'address',
        notAuthorized: !permissionMap['location'],
      },
      {
        label: t('latitude'),
        value: locationDetails?.geo_coordinates?.split(',')[1],
        valueType: 'displayField',
        notAuthorized: !permissionMap['location'],
      },
      {
        label: t('longitude'),
        value: locationDetails?.geo_coordinates?.split(',')[0],
        valueType: 'displayField',
        notAuthorized: !permissionMap['location'],
      },
      {
        label: t('labels'),
        value: locationDetailsLabels(),
        valueType: 'labelsList',
        notAuthorized: !permissionMap['location'],
      },
    ];

    return formattedData;
  };

  const getHeaderTitle = () => {
    if (locationDetails?.is_discovered) {
      return (
        <div className='header-title'>
          <span>
            <Map16 /> {node.name}{' '}
          </span>
          <span className='page-title-icon'>
            <IconWithToolTip
              icon={images.AutoDiscoverdLockIcon()}
              iconDescription={t('autoDiscoveredAccessLimited')}
            />
          </span>
        </div>
      );
    }
    if (node) {
      return (
        <div>
          <Map16 /> {node.name}
        </div>
      );
    }
    return '';
  };

  const getLink = () => {
    if (node) {
      return (
        `/locationDetails?cloudId=${locationDetails?.cloud_id}&locationId=${locationDetails?.resource_id}` ??
        ' '
      );
    }
    return '';
  };

  const title = getHeaderTitle();
  const link = getLink();

  return (
    <div className='topology-location-details-container'>
      <CustomSidePanel
        open={open}
        onClose={onClose}
        title={title}
        link={link}
        sidePanelWidth={sidePanelWidth}
        handleSidePanelWidth={handleSidePanelWidth}
        handleHeaderWidth={handleHeaderWidth}
        initialHeaderWidth={initialHeaderWidth}
        headerWidth={headerWidth}
      >
        <Tabs>
          <Tab id='topology-details-tab-1' label={t('details')}>
            <SidePanelDetailsComponent title={t('locationDetails')}>
              {loading && (
                <div className='skeleton-container'>
                  <SkeletonPlaceholder className='app-details-skeleton' />
                  <SkeletonPlaceholder className='app-details-skeleton' />
                </div>
              )}
              {!loading && (
                <SidePanelDetailsCard
                  data={formatAppDetailsData()}
                  notAuthorized={
                    !permissionMap['location'] && !permissionMap['cloud']
                  }
                  error500={!!serverErrorMap['location']}
                />
              )}
            </SidePanelDetailsComponent>
            {!loading && (
              <SidePanelDetailsComponent
                title={t('deploymentEnv', {
                  count: deploymentEnvData.length,
                })}
              >
                {deploymentEnvData.length ? (
                  <SidePanelTableComponent
                    headers={[{ key: 'name', header: t('name') }]}
                    rows={deploymentTableRows()}
                  />
                ) : (
                  <SidePanelEmptyState
                    icon={images.SidePanelDeploymentEmpty()}
                    header={t('emptyState.header')}
                    message={t('emptyState.message')}
                    btnText={t('emptyState.btnText')}
                    btnUrl={`/locationDetails?cloudId=${locationDetails?.cloud_id}&locationId=${locationDetails?.resource_id}`}
                    resourceType='ENVIRONMENTDTL'
                    notAuthorized={!permissionMap['deploymentEnvs']}
                    error500={!!serverErrorMap['deploymentEnvs']}
                  />
                )}
              </SidePanelDetailsComponent>
            )}
          </Tab>
        </Tabs>
      </CustomSidePanel>
    </div>
  );
};

export default LocationDetails;
