import { useTranslation } from 'react-i18next';
import Header from '../../components/Header/Header';
import { Information16 } from '@carbon/icons-react';
import IconWithToolTip from '../../components/IconWithToolTip/IconWithToolTip';
import SimpleCustomBarchart from '../../components/Charts/BarChart/SimpleCustomBarChart';
import {
  useSubscriptionUsage,
  useUsageHistory,
} from '../../hooks/useSubscriptionUsage';
import {
  SubscriptionUsageDisplayModel,
  SubscriptionUsageHistoryModel,
} from '../../models/master';
import moment from 'moment';
import { useMemo } from 'react';
import { ScaleTypes } from '@carbon/charts/interfaces';
import groupBy from 'lodash/groupBy';
import { AxiosError } from 'axios';
import Error403Card from '../../components/ErrorState/Error403Card';
import { SubscriptionUsageBarColors } from '../../lib/enums';
import Error500Card from '../../components/ErrorState/Error500Card';

import './SubscriptionUsage.scss';

const SubscriptionUsage = () => {
  const { t, i18n } = useTranslation('subscriptionUsage');
  const permission = {
    subscriptionUsage: true,
  };
  const error500 = {
    subscriptionUsage: false,
  };

  moment.locale(i18n.language);
  const {
    data: subscriptionUsageCurrMonthData,
    isLoading: loadingSubscriptionUsageCurrMonth,
    isError: isErrorUsageCurrentMonth,
    error: subscriptionUsageCurrMonthError,
  } = useSubscriptionUsage();
  const {
    data: subscriptionUsagePrevMonthsData,
    isLoading: loadingSubscriptionUsagePrevMonths,
    isError: isErrorUsagePrevMonth,
    error: subscriptionUsagePrevMonthError,
  } = useUsageHistory();

  if (isErrorUsageCurrentMonth) {
    const error = subscriptionUsageCurrMonthError as AxiosError;
    permission.subscriptionUsage =
      error?.response?.status === 403 ? false : true;
    error500.subscriptionUsage =
      error?.response?.status && error?.response?.status >= 500 ? true : false;
  }
  if (isErrorUsagePrevMonth) {
    const error = subscriptionUsagePrevMonthError as AxiosError;
    permission.subscriptionUsage =
      error?.response?.status === 403 ? false : true;
    error500.subscriptionUsage =
      error?.response?.status && error?.response?.status >= 500 ? true : false;
  }
  const options = {
    title: '',
    axes: {
      left: {
        stacked: true,
        title: t('resourceUnit'),
        mapsTo: 'count',
      },
      bottom: {
        title: t('months'),
        mapsTo: 'month',
        scaleType: ScaleTypes.LABELS,
      },
    },
    height: '500px',
    toolbar: {
      enabled: false,
    },
    color: {
      scale: {
        [t('legends.currentMonth')]: SubscriptionUsageBarColors.CURRENTMONTH,
        [t('legends.prevMonths')]: SubscriptionUsageBarColors.PREVMONTHS,
      },
    },
    legend: {
      truncation: {
        numCharacter: 50,
      },
    },
    tooltip: {
      customHTML: (data: any, markup: any) => {
        const monthName = moment().month(data[0].month).format('MMMM');
        return `
          <div class='datapoint-tooltip'>
            <div
              class='tooltip-color ${
                data[0].isCurrentMonth
                  ? 'tooltip-2-1-1 current-month'
                  : 'tooltip-2-1-2 prev-month'
              }'
            ></div>
            <div class='label'>
              <p data-testid='bar-tooltip-label'>${t('tooltip', {
                month: monthName,
              })}</p>
            </div>

            <p class='value' data-testid='bar-tooltip-value'>${
              data[0].count
            }</p>
          </div>
        `;
      },
    },
    grid: {
      y: {
        alignWithAxisTicks: true,
      },
    },
    data: {
      loading:
        loadingSubscriptionUsagePrevMonths || loadingSubscriptionUsageCurrMonth,
    },
  };

  const formatSubscriptionUsageData = () => {
    const usageData: SubscriptionUsageDisplayModel[] = [];
    subscriptionUsagePrevMonthsData?.forEach(
      (subscription: SubscriptionUsageHistoryModel) => {
        const subscriptionDate = moment(subscription.year_month, 'YYYYMM');
        usageData.push({
          group: t('legends.prevMonths'),
          month: subscriptionDate.format('MMM YYYY'),
          count: subscription.ru_count,
        });
      }
    );
    if (subscriptionUsageCurrMonthData) {
      usageData.push({
        group: t('legends.currentMonth'),
        month: moment().format('MMM YYYY'),
        count: subscriptionUsageCurrMonthData?.ru_count ?? 0,
        isCurrentMonth: true,
      });
    }

    return usageData;
  };

  const formattedSubscriptionData = useMemo(
    () => formatSubscriptionUsageData(),
    [subscriptionUsageCurrMonthData, subscriptionUsagePrevMonthsData]
  );

  return (
    <div
      className='subscription-usage-container'
      data-testid='subscription-usage-container'
    >
      <Header
        title={t('header')}
        subTitle={t('subheader')}
        breadcrumbs={[
          {
            url: '/',
            label: t('home'),
          },
        ]}
      />
      <div className='page-content'>
        {!permission.subscriptionUsage ? (
          <Error403Card />
        ) : error500.subscriptionUsage ? (
          <Error500Card />
        ) : (
          <>
            <div className='page-content-header'>
              <div
                className='header-text'
                data-testid='page-content-title-text'
              >
                {t('contentHeader')}
              </div>
              <div className='info-icon' data-testid='page-content-title-icon'>
                <IconWithToolTip
                  icon={<Information16 />}
                  iconDescription={t('headerInfoTooltip')}
                />
              </div>
            </div>
            <div
              className='page-content-sub-header'
              data-testid='current-month-usage-label'
            >
              {t('contentSubHeader')}
            </div>
            <div
              className='current-month-usage'
              data-testid='current-month-usage-value'
            >
              {subscriptionUsageCurrMonthData?.ru_count ?? 0}
            </div>
            <SimpleCustomBarchart
              data={formattedSubscriptionData}
              options={options}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default SubscriptionUsage;
