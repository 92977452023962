import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FlexGrid } from '@carbon/react';
import {
  Row,
  Column,
  Button,
  ContentSwitcher,
  SkeletonPlaceholder,
  Modal,
} from 'carbon-components-react';
import { List32, Grid32 } from '@carbon/icons-react';
import ResourceCard from './ResourceGroupsCards/ResourceCard';
import Header from '../../components/Header/Header';
import SortDropDown from '../../components/SortDropdown/SortDropDown';
import FindAndFilterBar from '../../components/FindAndFilterBar/FindAndFilterBar';
import ResourceGroupsTable from './ResourceGroupsTable/ResourceGroupsTable';
import ResourceGroupForm from './ResourceGroupForm';
import InlineNotification from '../../components/Notifications/Inline/Notification';
import sortData from '../../lib/tableSort';
import debounce from 'lodash/debounce';

import {
  ResourceGroup,
  AppliedFilter,
  Error500Type,
} from '../../models/master';

import './ResourceGroupsContainer.scss';

import { NotificationContext } from '../../components/Notifications/Context/NotificationProvider';
import {
  getResourceGroups,
  addResourceGroup,
  deleteResourceGroup,
  updateResourceGroup,
} from '../../controllers/resourceGroupApi';
import images from '../../images/images';
import { CardEmptyState } from '../../components/CardEmptyState/CardEmptyState';
import {
  capitalizeFirstLetter,
  manageFiltersFromStorage,
  removeExtraSpace,
} from '../../lib/utils';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import useAnalytics from '../../lib/useAnalytics';
import analyticsData from '../../lib/analyticsEventData';
import Error500Card from '../../components/ErrorState/Error500Card';

export interface RGForm {
  name: {
    value: string;
    invalid: boolean;
  };
  type: {
    value: string;
    invalid: boolean;
  };
  description: string;
}

const defaultFormData = {
  name: {
    value: '',
    invalid: false,
  },
  type: {
    value: '',
    invalid: true,
  },
  description: '',
};

const ResourceGroupsContainer = () => {
  const { t } = useTranslation('resourceGroupsContainer');

  const [view, setView] = useState<'table' | 'card'>('table');
  const [currentPageNumber, setPageNumber] = useState(1);
  const [currentPageSize, setPageSize] = useState(25);
  const [resourceGroupsData, setResourceGroupsData] = useState<
    ResourceGroup[] | null
  >(null);

  const [formData, setFormData] = useState<RGForm>(defaultFormData);
  const [showCreateModal, toggleCreateModal] = useState(false);
  const [createButtonDisabled, toggleCreateButtonDisabled] = useState(true);
  const [resourceDataLoading, toggleResourceDataLoading] = useState(false);
  const [filteredData, setFilteredData] = useState<ResourceGroup[] | []>([]);
  const [filterApplied, setFilterApplied] = useState<AppliedFilter[] | []>([]);
  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | 'NONE'>(
    'NONE'
  );
  const requiredFields: Array<keyof RGForm> = ['name', 'type'];
  const [showFailNotification, toggleFailNotification] = useState(false);
  const [errorCode, setErrorCode] = useState('default');
  const [subTitleErrorMsg, setSubTitleErrorMsg] = useState('');
  const [deleteModalOpen, toggleDeleteModal] = useState(false);
  const [deleteResourceGroupId, setDeleteResourceGroupId] = useState<
    string | null
  >(null);
  const [deleteResourceGroupName, setDeleteResourceGroupName] = useState<
    string | null
  >(null);
  const [editMode, setEditMode] = useState(false);
  const [changed, setChanged] = useState(false);
  const [resourceGroupId, setResourceGroupId] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const [error500, setError500] = useState<null | Error500Type>(null);

  const notification = useContext(NotificationContext);
  const navigate = useNavigate();
  const { pageViewed, trackButtonClicked } = useAnalytics();

  const isFormValid = () => {
    for (const field of requiredFields) {
      const value = (formData[field] as any).value;
      const trimmedValue = typeof value === 'string' ? value.trim() : value;
      if (checkFieldValidation(field, trimmedValue)) {
        return false;
      }
    }
    return true;
  };

  const checkFieldValidation = (type: string, value: string) => {
    let invalid = false;
    switch (type) {
      case 'name':
        invalid = value.trim() === '';
        break;
      case 'type':
        invalid = !(value === 'application' || value === 'infrastructure');
        break;
    }
    return invalid;
  };

  const refreshData = () => {
    setResourceGroupsData(null);
    setSortKey('');
    setSortDirection('NONE');
    getResourceGroups(true)
      .then(response => {
        if (error500) {
          setError500(null);
        }

        setResourceGroupsData(response.resource_groups as ResourceGroup[]);
      })
      .catch(error => {
        const err = error as AxiosError;

        if (err.response?.status === 403) {
          navigate('/403');
        }

        if (err.response!?.status >= 500) {
          setError500(err.response!?.status?.toString() as Error500Type);
        }

        toggleResourceDataLoading(false);
        console.error(error);
      });
    setFilterApplied(manageFiltersFromStorage());
  };

  const closeCreateModal = () => {
    toggleCreateModal(false);
    toggleFailNotification(false);
    setErrorCode('default');
    setChanged(false);
    setEditMode(false);
    setFormData(defaultFormData);
  };

  const handleCreate = async () => {
    trackButtonClicked(
      analyticsData['Admin Resource Groups'].events.createResourceGroup.props,
      analyticsData['Admin Resource Groups'].events.createResourceGroup.event
    );
    try {
      toggleCreateButtonDisabled(true);
      const rgData = {
        name: removeExtraSpace(formData?.name?.value),
        description: formData?.description,
        type: formData?.type?.value,
      };

      !editMode && (await addResourceGroup(rgData));
      editMode && (await updateResourceGroup(resourceGroupId, rgData));
      notification.onTrigger('TOAST', {
        title: editMode
          ? t('editSuccessNotification.title')
          : t('successNotification.title'),
        subtitle: editMode
          ? t('editSuccessNotification.subtitle', { name: rgData.name })
          : t('successNotification.subtitle', { name: rgData.name }),
      });
      setFormData(defaultFormData);
      toggleFailNotification(false);
      setErrorCode('default');
      toggleCreateModal(false);
      setEditMode(false);
      setChanged(false);
      refreshData();
    } catch (error: any) {
      console.log(error);
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        navigate('/403');
      }
      const errorMessage: string =
        error.response !== undefined
          ? error.response['customErrorMessage']
          : '';
      errorMessage.length > 0 && setSubTitleErrorMsg(errorMessage);
      toggleFailNotification(true);

      // We added this project.reject to prevent the modal closing when error occured.
      // But in latest version of '@carbon/ibm-products' the below line is not required and is throwing error.
      // return Promise.reject(() => console.log(error));
    } finally {
      toggleCreateButtonDisabled(false);
    }
  };

  const handleDeleteResourceGroup = async (id: string) => {
    try {
      setDisableButton(true);
      toggleResourceDataLoading(true);
      await deleteResourceGroup(id);

      // Trigger success toastbar
      notification.onTrigger('TOAST', {
        title: t('deleteSuccessNotification.title'),
        subtitle: t('deleteSuccessNotification.subtitle'),
      });

      refreshData();
    } catch (error: any) {
      console.log(error);
      const err = error as AxiosError;
      const errorMessage: string =
        error.response !== undefined
          ? error.response['customErrorMessage']
          : '';
      notification.onTrigger('TOAST', {
        kind: 'error',
        title:
          err.response?.status === 403
            ? (t('deletefailureNotification.authErrorTitle') as string)
            : (t('deletefailureNotification.title') as string),

        subtitle:
          err.response?.status === 403
            ? (t('deletefailureNotification.authErrorSubtitle') as string)
            : errorMessage.length > 0
            ? errorMessage
            : (t('deletefailureNotification.subtitle') as string),
      });

      // We added this project.reject to prevent the modal closing when error occured.
      // But in latest version of '@carbon/ibm-products' the below line is not required and is throwing error.
      // return Promise.reject(() => console.log(error));
    } finally {
      setDisableButton(false);
      toggleResourceDataLoading(false);
      toggleDeleteModal(false);
    }
  };

  const handleFormUpdate = (type: string, value: string) => {
    if (!changed) setChanged(true);
    switch (type) {
      case 'name':
        setFormData({
          ...formData,
          name: {
            value: value,
            invalid: value.trim() === '',
          },
        });
        break;
      case 'type':
        setFormData({
          ...formData,
          type: {
            value: value,
            invalid: !(value === 'application' || value === 'infrastructure'),
          },
        });
        break;
      case 'description':
        setFormData({
          ...formData,
          description: value,
        });
        break;
    }
  };

  const handleSort = (data: { id: string; text: string }) => {
    if (resourceGroupsData) {
      if (data.id === 'atoz') {
        setSortDirection('ASC');
        const sortedResourceGroups = Array.isArray(resourceGroupsData)
          ? resourceGroupsData.sort((a, b) =>
              a.name?.trim()?.toLowerCase() > b.name?.trim()?.toLowerCase()
                ? 1
                : -1
            )
          : [];
        setResourceGroupsData([...sortedResourceGroups]);
      } else {
        setSortDirection('DESC');
        const sortedResourceGroups = Array.isArray(resourceGroupsData)
          ? resourceGroupsData.sort((a, b) =>
              a.name?.trim()?.toLowerCase() < b.name?.trim()?.toLowerCase()
                ? 1
                : -1
            )
          : [];
        setResourceGroupsData([...sortedResourceGroups]);
      }
    }
  };

  const resourceTypeFilters = () => [
    {
      key: 'type',
      type: 'single',
      label: t('filters.type'),
      translationReqd: true,
      translationData: {
        infrastructure: t('infrastructure'),
        application: t('application'),
      },
      values: [
        ...Array.from(
          new Set(resourceGroupsData?.map(resourceGroup => resourceGroup?.type))
        ),
      ],
    },
  ];

  const renderFilter = () => {
    return (
      <FindAndFilterBar
        data={resourceGroupsData}
        filteredDataCallback={data =>
          setFilteredData(data as ResourceGroup[] | [])
        }
        filteredData={
          resourceGroupsData
            ? filterApplied.length > 0
              ? filteredData
              : resourceGroupsData
            : null
        }
        filtersApplied={filterApplied as any}
        filtersAppliedCallback={data => setFilterApplied(data)}
        filters={resourceTypeFilters() as any}
        persistFilter
        onRefresh={() => refreshData()}
      />
    );
  };

  const setPageChange = (pageData: { page: number; pageSize: number }) => {
    setPageNumber(pageData.page);
    setPageSize(pageData.pageSize);
  };

  useEffect(() => {
    pageViewed('Admin Resource Groups');
    toggleResourceDataLoading(true);
    refreshData();
  }, []);

  useEffect(() => {
    resourceGroupsData !== null
      ? toggleResourceDataLoading(false)
      : toggleResourceDataLoading(true);
  }, [resourceGroupsData]);

  useEffect(() => {
    toggleCreateButtonDisabled(!isFormValid() || !changed);
  }, [formData, isFormValid]);

  const handleTableSort = (
    data: { id: string; text: string },
    sortDirection: 'ASC' | 'DESC' | 'NONE'
  ) => {
    setSortDirection(sortDirection);
    setSortKey(data.id);
  };

  const handleCloseErrorBar = () => {
    toggleFailNotification(false);
    setErrorCode('default');
  };

  const requestDeleteResourceGroup = (id: string, name: string) => {
    trackButtonClicked(
      analyticsData['Admin Resource Groups'].events.deleteResourceGroup.props,
      analyticsData['Admin Resource Groups'].events.deleteResourceGroup.event
    );
    setDeleteResourceGroupId(id);
    setDeleteResourceGroupName(name);
    toggleDeleteModal(true);
  };

  const confirmDeleteResourceGroup = () => {
    toggleDeleteModal(false);
    deleteResourceGroupId && handleDeleteResourceGroup(deleteResourceGroupId);
  };

  return (
    <div className='resource-groups-container'>
      <Header
        title={t('header')}
        subTitle={t('subheader')}
        breadcrumbs={[
          {
            url: '/',
            label: t('home'),
          },
        ]}
        actions={[
          {
            kind: 'primary',
            onClick: () => {
              trackButtonClicked(
                analyticsData['Admin Resource Groups'].events
                  .openResourceGroupModal.props,
                analyticsData['Admin Resource Groups'].events
                  .openResourceGroupModal.event
              );
              toggleCreateModal(true);
            },
            text: t('addResourceGroup'),
            disabled: resourceDataLoading,
          },
        ]}
      />
      <div className='page-content'>
        <div className='resource-group-switcher'>
          {view !== 'table' ? (
            <>
              {resourceDataLoading ? (
                <div className='skeleton-sort-drop-down'>
                  <SkeletonPlaceholder className={'sorting-skeleton'} />
                </div>
              ) : (
                <SortDropDown
                  id='resource-group-sort-dropdown'
                  size='lg'
                  items={[
                    { id: 'atoz', text: t('sort.nameAZ') },
                    { id: 'ztoa', text: t('sort.nameZA') },
                  ]}
                  titleText={t('sort.titleText')}
                  placeholder={t('sort.placeholder')}
                  onSort={handleSort}
                  sortDir={sortDirection}
                />
              )}
            </>
          ) : null}
          <ContentSwitcher className='view-switcher'>
            <Button
              className={
                'switch-button' + (view === 'table' ? ' selected' : '')
              }
              onClick={() => {
                setView('table');
              }}
              renderIcon={List32}
              hasIconOnly
              tooltipPosition='bottom'
              iconDescription={t('table')}
            />
            <Button
              className={'switch-button' + (view === 'card' ? ' selected' : '')}
              onClick={() => {
                setView('card');
              }}
              renderIcon={Grid32}
              hasIconOnly
              tooltipPosition='bottom'
              iconDescription={t('card')}
            />
          </ContentSwitcher>
        </div>
        <div className='body'>
          {view === 'table' ? (
            <ResourceGroupsTable
              currentPageNumber={currentPageNumber}
              currentPageSize={currentPageSize}
              sortRows={(
                data: { id: string; text: string },
                direction: 'ASC' | 'DESC' | 'NONE'
              ) => handleTableSort(data, direction)}
              onPageChange={pageData => setPageChange(pageData)}
              rows={
                resourceGroupsData
                  ? filterApplied.length > 0
                    ? sortData(filteredData, sortKey, sortDirection)
                    : sortData(resourceGroupsData, sortKey, sortDirection)
                  : null
              }
              elementCount={
                resourceGroupsData
                  ? filterApplied.length > 0
                    ? filteredData.length
                    : resourceGroupsData.length
                  : 0
              }
              filteredDataSet={
                resourceGroupsData
                  ? filterApplied.length > 0
                    ? filteredData
                    : resourceGroupsData
                  : null
              }
              filteredDataCallback={data => {
                data && setFilteredData(data as ResourceGroup[] | []);
                setPageNumber(1);
              }}
              data={resourceGroupsData}
              filtersSelected={filterApplied as any}
              filtersAppliedCallback={data => setFilterApplied(data)}
              filters={resourceTypeFilters()}
              onRefresh={() => refreshData()}
              noDataCallBack={() => toggleCreateModal(true)}
              persistFilter
              removeResourceGroup={requestDeleteResourceGroup}
              editResourceGroup={(id: string) => {
                trackButtonClicked(
                  analyticsData['Admin Resource Groups'].events
                    .editResourceGroup.props,
                  analyticsData['Admin Resource Groups'].events
                    .editResourceGroup.event
                );
                setResourceGroupId(id);
                toggleCreateModal(true);
                setEditMode(true);
                let selectedResourceGroup: ResourceGroup | undefined =
                  resourceGroupsData?.filter(
                    (rg: any) => rg.resource_id === id
                  )[0];
                selectedResourceGroup != null &&
                  setFormData({
                    ...formData,
                    name: {
                      value: selectedResourceGroup?.name,
                      invalid: selectedResourceGroup?.name.trim() === '',
                    },
                    type: {
                      value: selectedResourceGroup?.type,
                      invalid: !(
                        selectedResourceGroup?.type === 'application' ||
                        selectedResourceGroup?.type === 'infrastructure'
                      ),
                    },
                    description: selectedResourceGroup?.description,
                  });
              }}
              error500Flag={!!error500}
            />
          ) : error500 ? (
            <Error500Card />
          ) : resourceDataLoading ? (
            <div>
              {renderFilter()}
              <Row className='skeleton-card-view'>
                <Column>
                  <SkeletonPlaceholder className='resouce-group-skeleton' />
                </Column>
                <Column>
                  <SkeletonPlaceholder className='resouce-group-skeleton' />
                </Column>
                <Column>
                  <SkeletonPlaceholder className='resouce-group-skeleton' />
                </Column>
                <Column>
                  <SkeletonPlaceholder className='resouce-group-skeleton' />
                </Column>
              </Row>
            </div>
          ) : (
            <div>
              {renderFilter()}
              <div className='card-view'>
                <FlexGrid>
                  <Row className='resource-card-alignment'>
                    {resourceGroupsData ? (
                      (filterApplied.length > 0
                        ? filteredData
                        : resourceGroupsData
                      ).length > 0 ? (
                        (filterApplied.length > 0
                          ? filteredData
                          : resourceGroupsData
                        ).map(resourceGroup => (
                          <Column lg={4} md={4} className='resource-card'>
                            <ResourceCard
                              resourceType={capitalizeFirstLetter(
                                resourceGroup?.type
                              )}
                              resourceName={resourceGroup?.name}
                              description={resourceGroup?.description}
                              updated_at={resourceGroup?.updated_at}
                            />
                          </Column>
                        ))
                      ) : (
                        <CardEmptyState
                          filterApplied={filterApplied}
                          emptyState={{
                            icon: images.ResourceGroupEmpty(),
                            header: t('emptyState.emptyContainerHeader'),
                            description: t(
                              'emptyState.emptyContainerDescription'
                            ),
                            buttonText: t('emptyState.buttonText'),
                            link: true,
                            click: () => toggleCreateModal(true),
                          }}
                        />
                      )
                    ) : (
                      <div className='no-resource-group'></div>
                    )}
                  </Row>
                </FlexGrid>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        className='add-rg-modal'
        modalHeading={editMode ? t('modal.editHeader') : t('modal.header')}
        primaryButtonText={editMode ? t('modal.update') : t('modal.create')}
        secondaryButtonText={t('modal.cancel')}
        open={showCreateModal}
        onRequestClose={() => closeCreateModal()}
        onSecondarySubmit={() => closeCreateModal()}
        onRequestSubmit={debounce(handleCreate, 1000)}
        primaryButtonDisabled={createButtonDisabled}
        size='sm'
        preventCloseOnClickOutside
      >
        {showFailNotification && (
          <InlineNotification
            onClose={() => handleCloseErrorBar() as any}
            kind={'error'}
            title={
              errorCode === '403'
                ? (t('failureNotification.authErrorTitle') as string)
                : (t('failureNotification.title') as string)
            }
            subtitle={
              errorCode === '403'
                ? (t('failureNotification.authErrorSubtitle') as string)
                : subTitleErrorMsg.length > 0
                ? subTitleErrorMsg
                : (t('failureNotification.subtitle') as string)
            }
          />
        )}
        <ResourceGroupForm
          handleFormUpdate={(name, value) => handleFormUpdate(name, value)}
          formState={formData}
          editMode={editMode}
        />
      </Modal>
      <Modal
        className='delete-resource-group-modal'
        danger
        modalHeading={t('deleteModal.header')}
        onRequestClose={() => toggleDeleteModal(false)}
        onRequestSubmit={debounce(confirmDeleteResourceGroup, 1000)}
        primaryButtonText={t('deleteModal.confirmBtn')}
        secondaryButtonText={t('deleteModal.cancelBtn')}
        open={deleteModalOpen}
        size='sm'
        preventCloseOnClickOutside
        primaryButtonDisabled={disableButton}
      >
        {t('deleteModal.body', {
          resourceGroupName: deleteResourceGroupName,
        })}
      </Modal>
    </div>
  );
};

export default ResourceGroupsContainer;
