import { axiosInstance } from './api';
import { MCNM_TOPOLOGY_API_URL } from './config';

export const getTopologyResources = async (view = 'all') => {
  try {
    let response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_TOPOLOGY_API_URL}/topology?visibility=${view}`,
    });
    return response.data ?? [];
  } catch (error) {
    console.log('api error: ', error);
    throw error;
  }
};
