import React from 'react';
import { useTranslation } from 'react-i18next';

import { ComboBox } from 'carbon-components-react';
import AddLabels from '../../../components/AddLabels/AddLabels';

import { Role } from '../../../models/master';

import './AddRoles.scss';

const AddRolesForm = (props: {
  onChangeName: any;
  onchangeLabel: any;
  formData: any;
  rolesData: Role[] | null;
}) => {
  const { onChangeName, formData, rolesData, onchangeLabel } = props;
  const { t } = useTranslation('addRolesIdentity');

  return (
    <div className='add-rolesIdentity-form'>
      <ComboBox
        id='addRoleName'
        name='roleName'
        className='form-field'
        selectedItem={formData.roleName.value}
        onChange={data => onChangeName('roleName', data.selectedItem)}
        items={Array.isArray(rolesData) ? rolesData : []}
        itemToString={item => (item ? item : '')}
        titleText={t('form.roleName.label')}
        placeholder={t('form.roleName.placeholder')}
        invalid={formData.roleName.error}
      />

      <AddLabels
        id='identityAddRoleLabels'
        labelText={t('form.labels.label')}
        placeholder={t('form.labels.placeholder')}
        onChange={data => onchangeLabel('labels', data)}
        btnText={t('form.labels.buttonText')}
        btnKind='secondary'
        tagType='green'
        defaultValues={formData.labels.value}
      />
    </div>
  );
};

export default AddRolesForm;
