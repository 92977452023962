import React, { useEffect, useState } from 'react';
import {
  TextInput,
  Row,
  FormLabel,
  Toggle,
  TooltipIcon,
  Column,
  TextArea,
  Button,
  TooltipDefinition,
  SkeletonPlaceholder,
} from 'carbon-components-react';
import { CreateTearsheetStep } from '@carbon/ibm-products';
import { useTranslation } from 'react-i18next';
import { Add16, Edit16, Information16 } from '@carbon/icons-react';
import AddLabels from '../../components/AddLabels/AddLabels';
import './RegisterNamespace.scss';
import {
  NetworkSegments,
  Secret,
  Gateway,
  ResourceGroup,
  SkupperComputeProfile,
  GatewayIngressTypes,
} from '../../models/master';
import { Dropdown } from '@carbon/react';
import images from '../../images/images';
import useAnalytics from '../../lib/useAnalytics';
import analyticsData from '../../lib/analyticsEventData';
import { IngressTypes, NetworkSegmentCompatibilitySet } from '../../lib/enums';
import ConnectGateway from './ConnectGateway';
import { domainNameValidation, ingressAnnotationRegex } from '../../lib/regex';
import './RegisterNamespace.scss';

interface FormData {
  install_method: string;
  gateway_name: string;
  namespace_name: string;
  agent_node: any;
  gateway: Gateway | null;
  gateway_node: any;
  rhsi_compute_profile: string;
  gatewaySizing: string;
  skupper_site_configuration: any;
  gateway_description: string;
  gateway_labels: any;
}
const defaultValues = {
  install_method: '',
  agent_node: null,
  gateway_name: '',
  namespace_name: '',
  gateway: null,
  gateway_node: null,
  rhsi_compute_profile: 'small',
  gatewaySizing: '',
  skupper_site_configuration: {
    ingress_type: { value: '', error: false, errorMessage: '' },
    ingress_hostname: { value: '', error: false, errorMessage: '' },
    router_ingress_hostname: { value: '', error: false, errorMessage: '' },
    controller_ingress_hostname: { value: '', error: false, errorMessage: '' },
    ingress_annotations: { value: '', error: false, errorMessage: '' },
  },
  gateway_description: '',
  gateway_labels: [],
};

const DeployGateway = (props: {
  onChange: any;
  formData: any;
  secrets: Secret[] | null;
  partitionType: any;
  deploymentdata: any;
  networkSegments: NetworkSegments[] | null;
  openCreateSegment: () => void;
  disConnectGateway: () => void;
  existingCompactibilitySet: string;
  appsDeployed?: any;
  mode: 'ADD' | 'EDIT';
  partitionData?: any;
  gatewayConnectedRemoteConnections: any;
  agentList: any;
  gatewayList: Gateway[] | [];

  createGatewayLoader: boolean;
  namespaceSelected: (data: any) => void;
  resourceGroups: ResourceGroup[] | null;
  handleChange: any;
  namespaceList: any;
  gatewayNames: string[];
  agentError: any;
  gwRhsiComputeProfiles: SkupperComputeProfile[];
  gwIngressTypes: GatewayIngressTypes[];
  nwSegmentsDataLoading: boolean;
  dataLoading: boolean;
}) => {
  const {
    onChange,
    formData,
    secrets,
    partitionType,
    deploymentdata,
    networkSegments,
    openCreateSegment,
    disConnectGateway,
    existingCompactibilitySet,
    appsDeployed,
    partitionData,
    gatewayConnectedRemoteConnections,
    agentList,
    gatewayList,
    createGatewayLoader,
    namespaceSelected,
    resourceGroups,
    handleChange,
    namespaceList,
    gatewayNames,
    agentError,
    gwRhsiComputeProfiles,
    gwIngressTypes,
  } = props;
  const { t } = useTranslation('registerNamespace');
  const [gatewayFormData, setGatewayFormData] = useState<FormData>(
    JSON.parse(JSON.stringify(defaultValues))
  );
  const [optionalParamInValid, setOptionalParamInValid] = useState({
    ingress_hostname: false,
    router_ingress_hostname: false,
    controller_ingress_hostname: false,
    ingress_annotations: false,
  });
  const [defaultInstallMethod, setDefaultInstallMethod] = useState(false);
  const [gatewayNameError, setGatewayNameError] = useState<any>({
    error: false,
    errorMessage: '',
  });

  const { trackButtonClicked } = useAnalytics();

  useEffect(() => {
    if (agentList?.available?.length) {
      setGatewayFormData({
        ...gatewayFormData,
        ...defaultValues,
        install_method: 'fromAgent',
      });
      setDefaultInstallMethod(true);
    } else if (gatewayList?.length > 0 && !agentList?.available?.length) {
      setGatewayFormData({
        ...gatewayFormData,
        ...defaultValues,
        install_method: 'existingGateway',
      });
      setDefaultInstallMethod(true);
    }
  }, [agentList, gatewayList]);

  const onGatewayDetailsChange = (
    key: string,
    payload: any,
    subKey: string = ''
  ) => {
    let defaultSkupperConfig = JSON.parse(
      JSON.stringify(defaultValues.skupper_site_configuration)
    );
    if (key === 'install_method') {
      setGatewayFormData({
        ...gatewayFormData,
        ...defaultValues,
        [key]: payload,
      });
      onChange('install_method', payload.toString());
    } else if (key === 'gateway') {
      let site_configuration = JSON.parse(
        payload?.site_configuration ||
          JSON.stringify({
            ingress_type: '',
            ingress_hostname: '',
            router_ingress_hostname: '',
            controller_ingress_hostname: '',
            ingress_annotations: '',
          })
      );
      const gatewayNode: any = Array.isArray(agentList?.gateways)
        ? agentList?.gateways?.find(
            (agent: any) => agent?.gateway_id === payload?.resource_id
          )
        : [];

      let gwProfile =
        gwRhsiComputeProfiles.find(
          data => data?.profile === payload?.intended_compute_profile
        ) ?? null;

      let gatewaySize = gwProfile
        ? `${
            gwProfile?.profile?.charAt(0).toUpperCase() +
            gwProfile?.profile?.slice(1)
          } (${t('requests')}: CPU ${gwProfile?.cpu_req}, MEM ${
            gwProfile?.mem_req
          }, ${t('limits')}: CPU ${gwProfile?.cpu_lim}, MEM ${
            gwProfile?.mem_lim
          })`
        : '—';
      setGatewayFormData({
        ...gatewayFormData,
        gateway: payload,
        gateway_name: payload?.name,
        gateway_node: gatewayNode,
        namespace_name: gatewayNode?.namespace ?? '',
        gatewaySizing: gatewaySize,
        skupper_site_configuration: {
          ...gatewayFormData.skupper_site_configuration,
          ingress_type: {
            value: site_configuration.ingress,
            error: false,
            errorMessage: '',
          },
          ingress_hostname: {
            value: site_configuration.ingress_host,
            error: false,
            errorMessage: '',
          },
          router_ingress_hostname: {
            value: site_configuration.router_ingress_host,
            error: false,
            errorMessage: '',
          },
          controller_ingress_hostname: {
            value: site_configuration.controller_ingress_host,
            error: false,
            errorMessage: '',
          },
          ingress_annotations: {
            value: site_configuration.ingress_annotations,
            error: false,
            errorMessage: '',
          },
        },
      });
    } else if (key === 'agent_node') {
      checkGatewayNameUnique(payload.name, true);
      setGatewayFormData({
        ...gatewayFormData,
        [key]: payload,
        gateway_name: payload.name,
        namespace_name: payload.namespace,
      });
    } else if (key === 'skupper_site_configuration') {
      let errorMessage = checkFieldValidation(key, payload, subKey);
      if (
        subKey === 'ingress_type' &&
        (payload.length === 0 || payload === IngressTypes.NONE)
      ) {
        setGatewayFormData({
          ...gatewayFormData,
          skupper_site_configuration: {
            ...defaultSkupperConfig,
            ingress_type: {
              ...defaultSkupperConfig.ingress_type,
              value: payload,
            },
          },
        });
        setOptionalParamInValid({
          ingress_hostname: false,
          router_ingress_hostname: false,
          controller_ingress_hostname: false,
          ingress_annotations: false,
        });
      } else {
        setGatewayFormData({
          ...gatewayFormData,
          skupper_site_configuration: {
            ...gatewayFormData.skupper_site_configuration,
            [subKey]: {
              value: payload,
              error: !!errorMessage,
              errorMessage,
            },
          },
        });
      }
    } else {
      if (key === 'gateway_name') checkGatewayNameUnique(payload, true);
      setGatewayFormData({
        ...gatewayFormData,
        [key]: payload,
      });
    }
  };

  const checkGatewayNameUnique = (gatewayName: string, setState = false) => {
    let gatewayError = {
      error: false,
      errorMessage: '',
    };
    if (gatewayNames.includes(gatewayName)) {
      gatewayError = {
        error: true,
        errorMessage: t('connectGateway.uniqueGatewayName'),
      };
    } else {
      gatewayError = {
        error: false,
        errorMessage: '',
      };
    }
    if (setState) setGatewayNameError(gatewayError);

    return !gatewayError?.error;
  };

  const checkFieldValidation = (name: string, value: any, subKey: string) => {
    let errorMessage = '';
    switch (name) {
      case 'skupper_site_configuration':
        if (value) {
          if (
            subKey === 'ingress_hostname' ||
            subKey === 'router_ingress_hostname' ||
            subKey === 'controller_ingress_hostname'
          ) {
            let domainNameRegex = domainNameValidation();
            let valid = domainNameRegex.test(value);
            errorMessage = !valid
              ? t('connectGateway.validation.hostname.errorMessage')
              : '';
          }

          if (subKey === 'ingress_annotations') {
            let ingressAnnotation: any = ingressAnnotationRegex();
            let annotationArr = value.split(',');
            let valid = annotationArr.every((annotation: string) =>
              ingressAnnotation.test(annotation.trim())
            );
            errorMessage = !valid
              ? t('connectGateway.validation.ingressAnnotation.errorMessage')
              : '';
          }
        }
        setOptionalParamInValid(prevState => ({
          ...prevState,
          [subKey]: !!errorMessage,
        }));
        break;
    }
    return errorMessage;
  };

  const isFormValid = () => {
    return !(props.mode === 'ADD' && !formData?.networkSegment?.value);
  };

  const labelNwSg = () => {
    return (
      <FormLabel className='label network-segment-label'>
        {t('form.networkSegment.label')}
        {appsDeployed?.length > 0 ? (
          <TooltipIcon
            className='networksegment-tooltip'
            tooltipText={t('form.networkSegment.tooltipText')}
            direction='right'
          >
            {images.questionIconSvg()}
          </TooltipIcon>
        ) : null}
      </FormLabel>
    );
  };

  const nameSpaceLabel = () => {
    return (
      <FormLabel className='label namespace-label'>
        {t('form.name.label')}
        {deploymentdata?.auto_discover &&
        existingCompactibilitySet === NetworkSegmentCompatibilitySet.Axon &&
        props?.mode === 'EDIT' ? (
          <TooltipIcon
            className='namespace-tooltip'
            tooltipText={t('form.name.tooltipText')}
            direction='right'
          >
            {images.questionIconSvg()}
          </TooltipIcon>
        ) : null}
      </FormLabel>
    );
  };

  const nextFn = () => {
    if (
      gatewayFormData?.gateway_name &&
      gatewayFormData?.gateway_name !== '' &&
      !partitionData?.gateway_id
    ) {
      namespaceSelected(gatewayFormData);
    } else return;
  };

  return (
    <CreateTearsheetStep
      className='connect-gateway-namespace'
      fieldsetLegendText={t('form.networkSegment.label')}
      title={t('deployGateway')}
      secondaryLabel={t('optional')}
      disableSubmit={
        props.nwSegmentsDataLoading ||
        (props.dataLoading &&
          existingCompactibilitySet ===
            NetworkSegmentCompatibilitySet.ServiceInterconnect) ||
        Object.values(optionalParamInValid).some(Boolean) ||
        !isFormValid()
      }
      onNext={nextFn}
      // onMount={onMount}
    >
      <div className='register-namespace-form'>
        {/* <div className='dep-env-title'>{deploymentdata?.name}</div> */}
        <Row className='row'>
          <Column md={3}>
            {props.nwSegmentsDataLoading ? (
              <>
                <div className='form-field-segment'>
                  {t('form.networkSegment.label')}
                </div>
                <SkeletonPlaceholder className='create-namespace-segment-skelton' />
              </>
            ) : (
              <Dropdown
                light
                items={
                  networkSegments
                    ? existingCompactibilitySet ===
                      NetworkSegmentCompatibilitySet.Axon
                      ? networkSegments
                      : [
                          ...networkSegments,
                          { resource_id: 'createNetworkSegment' },
                        ]
                    : existingCompactibilitySet ===
                      NetworkSegmentCompatibilitySet.Axon
                    ? networkSegments
                    : [{ resource_id: 'createNetworkSegment' }]
                }
                itemToString={(item: NetworkSegments) =>
                  item?.resource_id === 'createNetworkSegment' ? (
                    <div
                      className='create-segment-button'
                      onClick={() => {
                        openCreateSegment();
                      }}
                    >
                      <span className='createText'>
                        {t('createNetworkSegment')}
                      </span>
                    </div>
                  ) : (
                    `${item?.name} (${t(item?.compatibility_set)})`
                  )
                }
                id={`select-network-segment`}
                selectedItem={formData?.networkSegment?.value ?? ''}
                titleText={labelNwSg()}
                onChange={(e: any) =>
                  e.selectedItem?.resource_id === 'createNetworkSegment'
                    ? null
                    : onChange('networkSegment', e.selectedItem)
                }
                label={t('form.networkSegment.placeholder')}
                readOnly={
                  appsDeployed?.length > 0 ||
                  (partitionData?.gateway_id && props?.mode === 'EDIT') ||
                  (partitionData?.gateway_id &&
                    gatewayConnectedRemoteConnections?.length > 0)
                    ? true
                    : false
                }
              />
            )}
          </Column>
        </Row>
        {formData?.networkSegment?.value &&
        formData?.networkSegment?.value?.compatibility_set ===
          NetworkSegmentCompatibilitySet.ServiceInterconnect ? (
          <React.Fragment>
            {props.dataLoading ? (
              <SkeletonPlaceholder className='create-namespace-segment-skelton' />
            ) : !formData?.gatewayName?.value &&
              !formData?.gatewayId?.isDisconnected &&
              !partitionData?.gateway_id ? null : (
              <React.Fragment>
                <Row>
                  <Column className='modal-header'>
                    {t('connectGateway.header')}
                  </Column>
                </Row>
                <Row className='row'>
                  <Column md={6}>
                    <div className='details-subtitle'>
                      {t('rhsiGatewayConnectedSubtext', {
                        saveChanges: 'Save changes',
                      })}
                    </div>
                  </Column>
                </Row>
              </React.Fragment>
            )}
            {!props.dataLoading &&
            formData?.networkSegment?.value &&
            formData?.networkSegment?.value?.compatibility_set ===
              NetworkSegmentCompatibilitySet.ServiceInterconnect ? (
              props?.mode === 'ADD' ||
              (props?.mode === 'EDIT' &&
                !partitionData?.gateway_id &&
                !formData?.gatewayId?.isDisconnected) ? (
                <Row className='row'>
                  <Column md={6}>
                    {!agentList && gatewayList?.length === 0 ? null : (
                      <ConnectGateway
                        tearsheetId='connectNamespacetogateway'
                        resourceGroups={resourceGroups}
                        initalFormData={formData}
                        handleChange={handleChange}
                        createGatewayLoader={createGatewayLoader}
                        namespaceList={namespaceList}
                        mode={props.mode}
                        partitionData={partitionData}
                        agentList={agentList}
                        gatewayList={gatewayList}
                        gatewayNames={gatewayNames}
                        gwIngressTypes={gwIngressTypes}
                        gwRhsiComputeProfiles={gwRhsiComputeProfiles}
                        agentError={agentError}
                        gatewayNameError={gatewayNameError}
                        formData={gatewayFormData}
                        onChange={onGatewayDetailsChange}
                      />
                    )}
                  </Column>
                </Row>
              ) : (
                <Row className='row'>
                  <Column md={3}>
                    <div className='gateway-section'>
                      <TextInput
                        labelText={
                          formData?.gatewayId?.isDisconnected
                            ? ''
                            : t('form.connectedGateway.label')
                        }
                        id='connected-gateway'
                        className={
                          formData?.gatewayId?.isDisconnected === true
                            ? 'disabledInput form-field'
                            : 'form-field'
                        }
                        value={
                          formData?.gatewayName
                            ? formData?.gatewayName?.value
                            : ''
                        }
                        name='gatewayName'
                        placeholder={
                          formData?.gatewayId?.isDisconnected === true
                            ? t('form.connectedGateway.disConnectedplaceholder')
                            : t('form.connectedGateway.placeholder')
                        }
                        readOnly
                      />
                    </div>
                  </Column>
                  {!formData?.gatewayId?.isDisconnected &&
                  partitionData?.gateway_id &&
                  props?.mode === 'EDIT' ? (
                    <Button
                      className='disconnect-link'
                      kind='ghost'
                      onClick={disConnectGateway}
                      size='sm'
                    >
                      {t('disConnectButton')}
                    </Button>
                  ) : null}
                </Row>
              )
            ) : null}
          </React.Fragment>
        ) : null}
      </div>
    </CreateTearsheetStep>
  );
};

export default DeployGateway;
