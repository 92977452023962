import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DataTable,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  Pagination,
  TableExpandRow,
  TableExpandedRow,
  TableExpandHeader,
  TableToolbar,
  TableBatchActions,
  TableBatchAction,
  TableSelectAll,
  TableSelectRow,
  Modal,
  DataTableSkeleton,
  Checkbox,
} from 'carbon-components-react';

import { Error500Type, EventData } from '../../../models/master';

import FindAndFilterBar from '../../../components/FindAndFilterBar/FindAndFilterBar';
import GenericTableTitle from '../../../components/GenericTableTitle/GenericTableTitle';
import { VerticalEmptyState } from '../../../components/EmptyState/EmptyState';
import { deleteEvent, updateEvent } from '../../../controllers/eventsApis';
import { NotificationContext } from '../../../components/Notifications/Context/NotificationProvider';
import LabelTag from '../../../components/LabelTag/LabelTag';
import { TableProperties } from '../../../components/GenericTableWithFilters/GenericTableWithFilters';
import './EventsDataTable.scss';
import Error403Card from '../../../components/ErrorState/Error403Card';
import Error500Card from '../../../components/ErrorState/Error500Card';

export interface TableProps extends TableProperties {
  rowsExpansionContent?: any;
  data: EventData[] | null | any;
  persistFilter?: boolean;
  render403Container?: boolean;
  eventsDataLoading: boolean;
  render500Container?: boolean;
}

const style = {
  marginBottom: '0px',
  height: '350px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

const EventsDataTable = (props: TableProps) => {
  const [eventTableLoading, toggleEventTableLoading] = useState<boolean>(false);
  const [checkedBoxItems, setCheckedBoxItems] = useState<any | []>([]);
  const notification = useContext(NotificationContext);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [closeEventModal, setCloseEventModal] = useState<boolean>(false);
  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | 'NONE'>(
    'NONE'
  );
  const [disableButton, setDisableButton] = useState(false);
  const { t } = useTranslation('events');
  const handleChangeSort = (index: number) => {
    const newSortDirection =
      sortKey === props.headers[index].originalKey
        ? sortDirection === 'ASC'
          ? 'DESC'
          : 'ASC'
        : 'ASC';
    setSortDirection(newSortDirection);
    const sortedKey =
      props.headers[index].originalKey !== undefined
        ? props.headers[index].originalKey
        : '';
    setSortKey(sortedKey);
    props.sortRows(
      {
        id: props.headers[index].originalKey,
        text: props.headers[index].header,
      },
      newSortDirection
    );
  };

  function getExpandedRow(row: any, headers: any) {
    const currentRowData = props.rowsExpansionContent.find(
      (item: any) => item?.id === row?.id
    );
    return (
      row.isExpanded && (
        <TableExpandedRow colSpan={headers.length + 3}>
          <div>
            <span className='expand-row-label'>
              {t('expandedLabels.category')}
            </span>
            <span className='expand-row-text'>{currentRowData?.category}</span>
          </div>
          <div>
            <span className='expand-row-label'>
              {t('expandedLabels.updated')}
            </span>
            <span className='expand-row-text'>{currentRowData?.updated}</span>
          </div>
          <div>
            <span className='expand-row-label'>
              {t('expandedLabels.labels')}
            </span>
            <LabelTag
              labelArray={currentRowData?.labels as string[]}
              count={3}
            ></LabelTag>
          </div>
          <div>
            <span className='expand-row-label'>
              {t('expandedLabels.comment')}
            </span>
            <span className='expand-row-text line-break'>
              {currentRowData?.comment}
            </span>
          </div>
        </TableExpandedRow>
      )
    );
  }

  const renderTableHeader = (
    headers: { sort: any; header: any }[],
    getHeaderProps: (headerObj: {
      header: { sort: any; header: any };
      isSortable: boolean;
    }) => any
  ) => {
    const tableHeaders = headers.map(
      (
        header: {
          sort: string;
          header: any;
          style?: { minWidth: string };
        },
        index: number
      ) => {
        return props.headers[index].originalKey !== 'rowsExpansionContent' ? (
          <TableHeader
            {...getHeaderProps({
              header,
              isSortable: Boolean(header.sort),
            })}
            onClick={() => (header.sort ? handleChangeSort(index) : undefined)}
            isSortHeader={Boolean(header.sort)}
            sortDirection={
              props.headers[index].originalKey === sortKey
                ? sortDirection
                : 'NONE'
            }
            style={header?.style ? { ...header.style } : {}}
          >
            {header.header}
          </TableHeader>
        ) : null;
      }
    );
    return tableHeaders;
  };

  /**
   * @description - Function to render each header columns
   * @param headers - The header list.
   * @param getHeaderProps - Function to get header properties
   * @returns {string} - The table header columns
   */

  const getRowsToBeDisplayed = (rows: any) => {
    if (
      (props.hasPagination ? props.hasPagination : true) &&
      props.currentPageNumber &&
      props.currentPageSize
    )
      return rows.slice(
        (props.currentPageNumber - 1) * props.currentPageSize,
        props.currentPageNumber * props.currentPageSize
      );
    return rows;
  };

  /**
   * @description - Function to return find and filter component if it is required in the table.
   * @returns {JSX.Element | null} - Renders search and filter component.
   */

  const handleCheckbox = (data: any) => {
    const selectedRow = props.data.filter(
      (item: any) => item.resource_id === data.id
    );
    if (data.isSelected) {
      const rowsAfterDeselection = checkedBoxItems.filter(
        (item: any) => item.resource_id !== data.id
      );
      setCheckedBoxItems(rowsAfterDeselection);
    } else {
      setCheckedBoxItems([...checkedBoxItems, ...selectedRow]);
    }
  };
  const deleteMultipleEvents = async (data: any) => {
    for (const rec of data) {
      await deleteEvent(rec?.resource_id);
    }
  };

  const closeMultipleEvents = async (data: any) => {
    for (const rec of data) {
      const recToBeUpdate = {
        ...rec,
        is_handled: true,
        assigned_user:
          rec?.assigned_user === 'unassigned' ? '' : rec?.assigned_user,
      };
      await updateEvent(rec?.resource_id, recToBeUpdate);
    }
  };

  const handleDeleteCheckedItems = async () => {
    try {
      setDisableButton(true);
      toggleEventTableLoading(true);

      await deleteMultipleEvents(checkedBoxItems);
      // Trigger success toastbar

      notification.onTrigger('TOAST', {
        title:
          checkedBoxItems.length > 1
            ? t('notification.successNotificationTitleMultiple')
            : t('notification.successNotificationTitle'),
        subtitle:
          checkedBoxItems.length > 1
            ? t('notification.removeNotificationMessageMultiple')
            : t('notification.removeNotificationMessage'),
      });

      setOpenDeleteModal(false);
      setCheckedBoxItems([]);
      toggleEventTableLoading(false);
      props.onTableRefresh();
    } catch (error) {
      notification.onTrigger('TOAST', {
        kind: 'error',
        title: `${t('notification.errorNotificationTitle')}`,
        subtitle: t('notification.removeEventFail'),
      });
    } finally {
      setDisableButton(false);
      toggleEventTableLoading(false);
    }
  };

  const handleCloseCheckedItems = async () => {
    try {
      setDisableButton(true);
      toggleEventTableLoading(true);
      await closeMultipleEvents(checkedBoxItems);
      notification.onTrigger('TOAST', {
        title:
          checkedBoxItems.length > 1
            ? t('notification.closeNotificationTitleMultiple')
            : t('notification.closeNotificationTitle'),
        subtitle:
          checkedBoxItems.length > 1
            ? t('notification.closeNotificationMessageMultiple')
            : t('notification.closeNotificationMessage'),
      });
      props.onTableRefresh();
      setCloseEventModal(false);
      setCheckedBoxItems([]);
      toggleEventTableLoading(false);
    } catch (error) {
      notification.onTrigger('TOAST', {
        kind: 'error',
        title: `${t('notification.errorCloseNotificationTitle')}`,
        subtitle: t('notification.errorCloseNotificationDescription'),
      });
    } finally {
      setDisableButton(false);
      toggleEventTableLoading(false);
    }
  };

  const renderFilterSection = () => (
    <FindAndFilterBar
      data={props.fullData}
      filteredData={props.data}
      filteredDataCallback={data =>
        props.filteredDataCallback(data as Array<any> | [])
      }
      filtersApplied={props.selectedFiltersVal as any}
      filtersAppliedCallback={data => props.setFilterApplied(data)}
      filters={props.filters ? props.filters : []}
      actionButton={props.actionButton ? props.actionButton : undefined}
      onRefresh={() => props.onTableRefresh()}
      showRefresh={props.showRefresh !== undefined ? props.showRefresh : true}
      showSearch={props.showSearch !== undefined ? props.showSearch : true}
      visibilityFlag={props.visibilityFlag}
      customKey={'resource_name'}
      leftInlineFilters={props.leftInlineFilters}
      filterValue={'event_status'}
      persistFilter={props.persistFilter}
    />
  );

  /**
   * @Description - Function to render table title and description
   * @returns {JSX.Element | null} - Renders Title component
   */
  const renderTitleAndDescription = () => {
    if (props.title && props.description) {
      return (
        <GenericTableTitle
          title={props.title}
          description={props.description}
        />
      );
    } else if (props.title) {
      return <GenericTableTitle title={props.title} />;
    } else {
      return null;
    }
  };

  const getEmptyStateConfig = () => {
    if (props.selectedFiltersVal?.length > 0 && props.rows?.length === 0)
      return {
        icon: props.emptyState.notFoundIcon,
        emptyStateHeader: t('notFound.header'),
        emptyStateDescription: t('notFound.description'),
        link: false,
        buttonText: t('notFound.text'),
        click: () => {
          props.setFilterApplied([]);
          props.filteredDataCallback([]);
        },
      };
    else return props.emptyState;
  };

  /**
   * @description - Function to render Empty container when the data list array is empty.
   * @returns {JSX.Element} - Renders no data container.
   */
  const renderNoDataContainer = () => {
    const emptyStateData = getEmptyStateConfig();
    return (
      <div className='events-emptyContainer'>
        <div className='events-emptyContainerCol'>
          <VerticalEmptyState
            icon={emptyStateData?.icon}
            header={
              emptyStateData.emptyStateHeader ??
              t('emptyState.emptyContainerHeader')
            }
            description={
              emptyStateData.emptyStateDescription ??
              t('emptyState.emptyContainerDescription')
            }
          />
        </div>
      </div>
    );
  };

  const render403Container = () => {
    return (
      <div className='emptyContainer'>
        <div className='emptyContainerCol'>
          <Error403Card />
        </div>
      </div>
    );
  };

  const render500Container = () => {
    return (
      <div className='emptyContainer'>
        <div className='emptyContainerCol'>
          <Error500Card />
        </div>
      </div>
    );
  };

  /**
   * @description - Function to render pagination component.
   * @returns {JSX.Element | null} - Renders Pagination component.
   */
  const renderPagination = () =>
    props.rows &&
    props.rows.length !== 0 &&
    (props.hasPagination ? props.hasPagination : true) ? (
      <Pagination
        backwardText={t('prevPageBtn')}
        forwardText={t('nextPageBtn')}
        itemsPerPageText={t('pageItemsNumberText')}
        page={props.currentPageNumber}
        pageNumberText={t('pageNumberText')}
        pageSize={props.currentPageSize ? props.currentPageSize : 10}
        pageSizes={
          props.paginationSizeSelections
            ? props.paginationSizeSelections
            : [10, 25, 50, 100]
        }
        totalItems={props.totalElementsCount}
        onChange={val => props.onPageChange(val)}
        className={
          props.paginationClass
            ? props.paginationClass
            : 'events-tablePagination'
        }
      />
    ) : null;

  return eventTableLoading || props?.eventsDataLoading || !props.rows ? (
    <div>
      {renderFilterSection()}
      <DataTableSkeleton
        headers={props.headers}
        showToolbar={false}
        showHeader={false}
      />
    </div>
  ) : (
    <React.Fragment>
      <DataTable
        rows={props.rows}
        headers={props.headers}
        isSortable={props.isSortable}
      >
        {({
          rows,
          headers,
          getHeaderProps,
          getSelectionProps,
          getToolbarProps,
          getBatchActionProps,
          getRowProps,
          getTableProps,
          getTableContainerProps,
        }: any) => (
          <TableContainer {...getTableContainerProps()}>
            <TableToolbar {...getToolbarProps()}>
              {renderTitleAndDescription()}
              {renderFilterSection()}
              <TableBatchActions
                {...getBatchActionProps()}
                onCancel={e => {
                  getSelectionProps({ rows }).onSelect(e);
                }}
              >
                <TableBatchAction
                  renderIcon={null}
                  onClick={() => setOpenDeleteModal(true)}
                >
                  {checkedBoxItems.length > 1
                    ? t('eventsSelected.deleteMultiple')
                    : t('eventsSelected.delete')}
                </TableBatchAction>
                <TableBatchAction
                  renderIcon={null}
                  onClick={() => setCloseEventModal(true)}
                >
                  {checkedBoxItems.length > 1
                    ? t('eventsSelected.closeMultiple')
                    : t('eventsSelected.close')}
                </TableBatchAction>
              </TableBatchActions>
            </TableToolbar>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  <TableExpandHeader />
                  <Checkbox
                    id='select-all'
                    labelText={''}
                    className={'select-all-checkbox'}
                    checked={getRowsToBeDisplayed(rows).every(
                      (row: any) => row.isSelected === true
                    )}
                    onChange={(e: any) => {
                      getRowsToBeDisplayed(rows).map((row: any) => {
                        <TableSelectRow
                          {...getSelectionProps({ row }).onSelect(e)}
                        />;
                        row.isSelected = e;
                      });
                      const selectedRows = rows.filter((element: any) => {
                        return element.isSelected === true;
                      });
                      const selRows = selectedRows.map((s: { id: any }) =>
                        props.data.filter(
                          (d: { resource_id: any }) => s.id === d.resource_id
                        )
                      );
                      const dataRows = selRows.flat();
                      if (e) {
                        if (selectedRows.length) {
                          setCheckedBoxItems([...dataRows]);
                        } else {
                          setCheckedBoxItems([]);
                        }
                      } else {
                        setCheckedBoxItems([]);
                      }
                    }}
                  />
                  {renderTableHeader(headers, getHeaderProps)}
                </TableRow>
              </TableHead>
              {rows.length === 0 ? null : (
                <TableBody>
                  {getRowsToBeDisplayed(rows).map(
                    (row: {
                      id: React.Key | null | undefined;
                      cells: any[];
                    }) => {
                      return (
                        props.isRowsExpandable ? props.isRowsExpandable : false
                      ) ? (
                        <React.Fragment key={row.id}>
                          <TableExpandRow {...getRowProps({ row })}>
                            <TableSelectRow
                              {...getSelectionProps({ row })}
                              onChange={() => handleCheckbox(row)}
                            />
                            {row.cells.map((cell: any) => (
                              <TableCell key={cell.id}>{cell.value}</TableCell>
                            ))}
                          </TableExpandRow>
                          {getExpandedRow(row, headers)}
                        </React.Fragment>
                      ) : (
                        <TableRow key={row.id}>
                          {row.cells.map(cell => (
                            <TableCell key={cell.id}>{cell.value}</TableCell>
                          ))}
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        )}
      </DataTable>

      {props.render403Container ? render403Container() : null}
      {props.render500Container ? render500Container() : null}
      {!props.render403Container &&
      !props.render500Container &&
      props.rows.length === 0
        ? renderNoDataContainer()
        : null}
      {renderPagination()}

      <Modal
        open={closeEventModal ? closeEventModal : openDeleteModal}
        danger
        modalHeading={
          closeEventModal
            ? checkedBoxItems.length > 1
              ? t('close.modal.headingMultiple')
              : t('close.modal.heading')
            : checkedBoxItems.length > 1
            ? t('delete.modal.headingMultiple')
            : t('delete.modal.heading')
        }
        primaryButtonText={
          closeEventModal
            ? t('close.modal.closeBtn')
            : t('delete.modal.deleteBtn')
        }
        secondaryButtonText={t('delete.modal.cancelBtn')}
        preventCloseOnClickOutside={true}
        onRequestSubmit={() => {
          closeEventModal
            ? handleCloseCheckedItems()
            : handleDeleteCheckedItems();
        }}
        onRequestClose={() => {
          setOpenDeleteModal(false);
          setCloseEventModal(false);
        }}
        className='delete-events-modal'
        size='xs'
        primaryButtonDisabled={disableButton}
      >
        {closeEventModal
          ? checkedBoxItems.length > 1
            ? t('close.modal.descriptionMultiple')
            : t('close.modal.description')
          : checkedBoxItems.length > 1
          ? t('delete.modal.descriptionMultiple')
          : t('delete.modal.description')}
      </Modal>
    </React.Fragment>
  );
};

export default EventsDataTable;
