import { Column, Row } from 'carbon-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import images from '../../../images/images';
import './LegendStatus.scss';

const LegendStatus = () => {
  const { t } = useTranslation('legendContainer');

  const fetchIconAndDescription = (icon: any, desc: string) => {
    return (
      <Row className='legend-status-label-icon'>
        <Column lg={1}>{icon}</Column>
        <Column className='legend-status-label'>{desc}</Column>
      </Row>
    );
  };

  return (
    <div className='topology-legend-status'>
      <div className='legend-status-header'>{t('status.status')}</div>
      <div className='legend-status-desc'>{t('status.statusDesc')}</div>
      <Row className='legend-status-sub-section'>
        <Column>
          {fetchIconAndDescription(
            images.operationalStatusIcon(),
            t('status.operational')
          )}
          {fetchIconAndDescription(
            images.unReachableIcon(),
            t('status.unreachable')
          )}
        </Column>
        <Column>
          {fetchIconAndDescription(
            images.criticalStatusIcon(),
            t('status.critical')
          )}
          {fetchIconAndDescription(
            images.unmanagedLegendSvg(),
            t('status.unmanaged')
          )}
        </Column>
        <Column>
          {fetchIconAndDescription(images.majorStatusIcon(), t('status.major'))}
          {fetchIconAndDescription(
            images.notDeployedIcon(),
            t('status.notDeployed')
          )}
        </Column>
        <Column>
          {fetchIconAndDescription(images.minorStatusIcon(), t('status.minor'))}
        </Column>
        <Column>
          {fetchIconAndDescription(
            images.pendingStatusIcon(),
            t('status.pendDep')
          )}
        </Column>
      </Row>
    </div>
  );
};

export default LegendStatus;
