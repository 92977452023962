import React, { useEffect, useState } from 'react';
import { Dropdown } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import AddLabels from '../../../components/AddLabels/AddLabels';
import { Identity } from '../../../models/master';
import './AssignIdentity.scss';

const AssignIdentityForm = (props: {
  onChange: (name: string, value: any) => void;
  formData: any;
  identities: Identity[] | null;
  assignedIdentities?: Identity[] | null;
}) => {
  const { onChange, formData, identities, assignedIdentities } = props;
  const { t } = useTranslation('roleDetails');

  const [filteredIdentities, setFilteredIdentities] = useState<
    Identity[] | null
  >(null);

  useEffect(() => {
    let filteredIdentitiesData =
      identities != null &&
      identities.filter((identity: Identity) => {
        return (
          assignedIdentities != null &&
          assignedIdentities.every((assignedIdentity: Identity) => {
            return assignedIdentity.resource_id !== identity.resource_id;
          })
        );
      });
    Array.isArray(filteredIdentitiesData) &&
      setFilteredIdentities(filteredIdentitiesData);
  }, []);

  const identitiesCount = identities?.length as number;
  const filteredIdentitiesCount = filteredIdentities?.length as number;
  const unAssignedCounts = (identitiesCount -
    filteredIdentitiesCount) as number;

  return (
    <div className='assign-identity-form'>
      <Dropdown
        label={t('assignIdentity.form.placeHolder')}
        id='assign-identity-form-dropdown'
        titleText={t<string>('assignIdentity.form.label')}
        size='lg'
        items={Array.isArray(filteredIdentities) ? filteredIdentities : []}
        itemToString={(item: Identity) => item?.name}
        itemToElement={(item: Identity) => (
          <div className='identity-dropdown-option'>
            <span>{item.name}</span>
            <span>{`(${item.resource_id})`}</span>
          </div>
        )}
        onChange={(e: any) => {
          onChange('id', e.selectedItem);
        }}
        disabled={
          filteredIdentitiesCount === 0 && identitiesCount !== 0 ? true : false
        }
      />
      <div className='helper-text'>
        {t('assignIdentity.form.identitiesHelperText', {
          unAssignedIdentities: unAssignedCounts,
          totalIdentities: identitiesCount,
        })}{' '}
      </div>

      <AddLabels
        id='identityLabels'
        labelText={t('assignIdentity.form.labelComponent.label')}
        placeholder={t('assignIdentity.form.labelComponent.placeHolder')}
        onChange={data => onChange('labels', data)}
        btnText={t('assignIdentity.form.labelComponent.buttonText')}
        btnKind='secondary'
        tagType='green'
        defaultValues={formData.labels.value}
      />
    </div>
  );
};

export default AssignIdentityForm;
