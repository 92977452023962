import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateTearsheetStep } from '@carbon/ibm-products';
import { Row, Column } from '@carbon/react';
import { Tab, Tabs, TabPanel, TabList, TabPanels } from '@carbon/react';
import RolePermissionsResourceTypeTable from './RolePermissionsResourceTypeTable/RolePermissionsResourceTypeTable';
import RolePermissionsResourceGroupTable from './RolePermissionsResourceGroupTable/RolePermissionsResourceGroupTable';

import {
  getDataByResourceType,
  getHeadersForResourceTable,
  getDataByResourceGroup,
} from './config';

import './RolePermissions.scss';

interface Props {
  resourceTypePermissionsData: any;
  updateResourceTypePermissionsData: any;
  resourceGroupPermissionsData: any;
  updateResourceGroupPermissionsData: any;
  disableSubmit: boolean;
}

const RolePermissions: React.FC<Props> = ({
  resourceTypePermissionsData,
  updateResourceTypePermissionsData,
  resourceGroupPermissionsData,
  updateResourceGroupPermissionsData,
  disableSubmit,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const { t } = useTranslation('createRole');

  const dataByResourceType = getDataByResourceType(t);
  const dataByResourceGroup = getDataByResourceGroup(t);

  return (
    <CreateTearsheetStep
      className='create-role-permissions-step'
      title={t('rolePermissions.title')}
      subtitle={t('rolePermissions.subtitle')}
      disableSubmit={disableSubmit}
      onNext={() => {}}
    >
      <Row className='row'>
        <Column md={8}>
          <Tabs
            selected={selectedIndex}
            onChange={(index: any) => setSelectedIndex(index)}
          >
            <TabList className='role-permission-tab-contained' contained>
              <Tab className='role-permission-tab'>
                {t('rolePermissions.resourceTypeTab')}
              </Tab>
              <Tab className='role-permission-tab'>
                {t('rolePermissions.resourceGroupTab')}
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel className='role-permission-tab-panel'>
                <RolePermissionsResourceTypeTable
                  headers={getHeadersForResourceTable(t, 'resourceType')}
                  rows={dataByResourceType}
                  isSortable={false}
                  resourceTypePermissionsData={resourceTypePermissionsData}
                  updateResourceTypePermissionsData={
                    updateResourceTypePermissionsData
                  }
                />
              </TabPanel>
              <TabPanel className='role-permission-tab-panel'>
                <RolePermissionsResourceGroupTable
                  headers={getHeadersForResourceTable(t, 'resourceGroup')}
                  rows={dataByResourceGroup}
                  isSortable={false}
                  resourceGroupPermissionsData={resourceGroupPermissionsData}
                  updateResourceGroupPermissionsData={
                    updateResourceGroupPermissionsData
                  }
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Column>
      </Row>
    </CreateTearsheetStep>
  );
};

export default RolePermissions;
