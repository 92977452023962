import { MCNM_RESOURCEGROUP_API_URL } from './config';
import { axiosInstance } from './api';

//Get all of the ResourceGroups that MCNM oversees

export async function getResourceGroups(throwError = false) {
  try {
    let response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_RESOURCEGROUP_API_URL}/resource_groups`,
    });

    return response.data;
  } catch (error) {
    // Note: I added an extra throwError parameter here to make this function backward compatible.
    if (throwError) {
      throw error;
    }

    console.log('ERROR IN API', error);
    return {
      resource_groups: [
        {
          description: '',
          name: 'Default_Infrastructure_Group',
          resource_id: 'default-infra',
          type: 'infrastructure',
        },
        {
          description: '',
          name: 'Default_Application_Group',
          resource_id: 'default-app',
          type: 'application',
        },
      ],
    };
  }
}

//Get one of the ResourceGroups that MCNM oversees

export async function getResourceGroup(resource_group_id) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_RESOURCEGROUP_API_URL}/resource_groups/${resource_group_id}`,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

//Get one of the ResourceGroups that MCNM oversees either by passing name or type or both attributes.

export async function getResourceGroupUsingQuery(queryParams) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_RESOURCEGROUP_API_URL}/resource_groups`,
      params: {
        name: queryParams?.name,
        type: queryParams?.type,
      },
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }
}

// Add a new ResourceGroup that MCNM should oversee.

export async function addResourceGroup(data) {
  try {
    const response = await axiosInstance({
      method: 'POST',
      url: `${MCNM_RESOURCEGROUP_API_URL}/resource_groups`,
      data: data,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Delete an existing ResourceGroup that MCNM oversees

export async function deleteResourceGroup(resource_group_id) {
  try {
    const response = await axiosInstance({
      method: 'DELETE',
      url: `${MCNM_RESOURCEGROUP_API_URL}/resource_groups/${resource_group_id}`,
    });
    console.log(response.data);
  } catch (error) {
    console.log(error);
    throw error;
  }
}

// Update an existing ResourceGroup that MCNM oversees.

export async function updateResourceGroup(
  resource_group_id,
  resource_group_data
) {
  try {
    const response = await axiosInstance({
      method: 'PUT',
      url: `${MCNM_RESOURCEGROUP_API_URL}/resource_groups/${resource_group_id}`,
      data: resource_group_data,
    });
    console.log(response.data);
  } catch (error) {
    console.log(error);
    throw error;
  }
}
