import { useState, useContext } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Modal,
  TextInput,
  Checkbox,
  Row,
  Column,
} from 'carbon-components-react';
import { AxiosError } from 'axios';
import { NotificationContext } from '../../../components/Notifications/Context/NotificationProvider';
import {
  RemoteConnections,
  DeploymentEnvironment,
  Namespace,
} from '../../../models/master';
import { deleteGateway } from '../../../controllers/gateawayApis';
import {
  updateCloudCluster,
  updateCloudVpc,
  updateCloudNode,
} from '../../../controllers/cloudApis';
import { updateNamespaceData } from '../../../controllers/deploymentEnv';

import { removeExtraSpace } from '../../../lib/utils';

import './DeleteGateway.scss';

const DeleteGateway = (props: {
  gatewayDetails: any;
  remoteConnections?: RemoteConnections[] | null;
  deploymentEnvs?: any;
  namespace: Namespace | null;
  open: boolean;
  cancelDeleteGatewayCallback: (data: any) => void;
}) => {
  const { t } = useTranslation('gatewayDetails');
  const navigate = useNavigate();
  const notification = useContext(NotificationContext);

  const {
    gatewayDetails,
    remoteConnections,
    open,
    cancelDeleteGatewayCallback,
    deploymentEnvs,
    namespace,
  } = props;

  const [isGatewayNameValid, setIsGatewayNameValid] = useState(false);
  const [isGatewayCheckboxChecked, setIsGatewayCheckboxChecked] =
    useState(false);
  const [inputGatewayName, setInputGatewayName] = useState('');
  const [disableDeleteButton, setDisableDeleteBtn] = useState(false);

  const gatewaySubtypeName = gatewayDetails?.subtype;

  const getTotalCount = () => {
    if (gatewaySubtypeName === 'AXON-EDGE') {
      return deploymentEnvs?.length + remoteConnections?.length + 1;
    } else return remoteConnections && remoteConnections.length + 2;
  };

  const verifyGatewayName = (value: string) => {
    const gatewayName = removeExtraSpace(gatewayDetails?.name);
    setInputGatewayName(value);
    if (value?.toLowerCase() === gatewayName?.toLowerCase()) {
      setIsGatewayNameValid(true);
    } else {
      setIsGatewayNameValid(false);
    }
  };

  const handleCancelDeleteGateway = () => {
    setInputGatewayName('');
    setIsGatewayNameValid(false);
    setIsGatewayCheckboxChecked(false);
    cancelDeleteGatewayCallback(false);
  };

  const getEnvironmentsList = () => {
    const deploymentEnvList = deploymentEnvs?.map((deploymentEnv: any) => {
      return <div className='multigroup-item'>{deploymentEnv?.name}</div>;
    });
    return deploymentEnvList;
  };

  const getNamespaceList = () => {
    return <div className='multigroup-item'>{namespace?.name}</div>;
  };

  const getRemoteConnectionsList = () => {
    const remoteConnectionList = remoteConnections?.map(
      (remoteConnection: any) => {
        return <div className='multigroup-item'>{remoteConnection?.name}</div>;
      }
    );
    return remoteConnectionList;
  };

  const updateDeploymentEnv = async (env: DeploymentEnvironment) => {
    const data = {
      gateway_id: '',
      auto_discover: false, // We need to turn off auto_discover when we disconnect gateway
    };
    try {
      if (env?.type === 'vpc') {
        await updateCloudVpc(env?.cloud_id, env?.resource_id, {
          ...env,
          ...data,
          type: env?.subtype,
          subtype: '',
        });
      }
      if (env?.type === 'cluster') {
        await updateCloudCluster(env?.cloud_id, env?.resource_id, data);
      }
      if (env?.type === 'node') {
        await updateCloudNode(env?.cloud_id, env?.resource_id, {
          ...env,
          data,
        });
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  const updateNamespace = async () => {
    try {
      await updateNamespaceData(
        namespace?.cloud_id,
        namespace?.cluster_id,
        namespace?.resource_id,
        { gateway_id: '', router_site_cor_id: '', auto_discover: false }
      );
    } catch (error: any) {
      console.error(error);
    }
  };

  const submitDeleteRequest = async () => {
    setDisableDeleteBtn(true);
    if (
      gatewayDetails?.type === 'edge' &&
      gatewayDetails?.subtype === 'AXON-EDGE' &&
      deploymentEnvs?.length > 0
    ) {
      for (const env of deploymentEnvs) {
        await updateDeploymentEnv(env);
      }
    }

    if (
      gatewayDetails?.type === 'edge' &&
      gatewayDetails?.subtype === 'RHSI-EDGE' &&
      namespace
    ) {
      await updateNamespace();
    }

    try {
      await deleteGateway(gatewayDetails?.resource_id);
      notification.onTrigger('TOAST', {
        title:
          gatewayDetails?.type === 'edge'
            ? t('deleteGateway.successNotification.titleEdge')
            : t('deleteGateway.successNotification.titleWaypoint'),
        subtitle: t('deleteGateway.successNotification.description', {
          gatewayName: gatewayDetails?.name ?? '',
        }),
      });
      navigate('/gateways');
    } catch (error: any) {
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        notification.onTrigger('TOAST', {
          title: t('deleteGateway.error.authErrorTitle'),
          kind: 'error',
          subtitle: t('deleteGateway.error.authErrorSubtitle'),
        });
      } else {
        const errorMessage: string =
          error.response !== undefined
            ? error.response['customErrorMessage']
            : '';
        notification.onTrigger('TOAST', {
          title: t('deleteGateway.error.title'),
          kind: 'error',
          subtitle:
            errorMessage.length > 0
              ? errorMessage
              : t('deleteGateway.error.errorMsg', {
                  gatewayName: gatewayDetails?.name ?? '',
                }),
        });
      }
    } finally {
      handleCancelDeleteGateway();
      setDisableDeleteBtn(false);
    }
  };

  return (
    gatewayDetails && (
      <div className='delete-gateway'>
        {gatewayDetails.type === 'edge' &&
        (deploymentEnvs?.length > 0 || namespace) ? (
          <Modal
            className='delete-gateway-modal'
            size='sm'
            danger
            modalHeading={t('deleteGateway.modalTitle', {
              gatewayName: gatewayDetails?.name ?? '',
            })}
            onRequestClose={handleCancelDeleteGateway}
            onRequestSubmit={submitDeleteRequest}
            primaryButtonText={t('deleteGateway.deleteButton')}
            secondaryButtonText={t('deleteGateway.cancelButton')}
            primaryButtonDisabled={
              !(isGatewayNameValid && isGatewayCheckboxChecked) ||
              disableDeleteButton
            }
            preventCloseOnClickOutside
            open={open}
          >
            <Row>
              <Column>
                <div className='delete-gateway-description'>
                  {t('deleteGateway.description')}
                </div>
              </Column>
            </Row>
            <Row>
              <Column>
                <TextInput
                  id='delete-gateway-name'
                  labelText={
                    <Trans
                      i18nKey={t('deleteGateway.textFieldLabel', {
                        gatewayName: gatewayDetails?.name ?? '',
                      })}
                    />
                  }
                  placeholder={t('deleteGateway.textFieldPlaceholder')}
                  name='delete-gateway-name'
                  onChange={e => verifyGatewayName(e.target.value)}
                  value={inputGatewayName}
                  autoComplete='off'
                />
              </Column>
            </Row>
            <div>
              <hr className='line-divider' />
              <Row>
                <Column>
                  <div className='delete-gateway-checklist-description'>
                    {t('deleteGateway.gatewayChecklistDescription')}
                  </div>
                </Column>
              </Row>
              <Row>
                <Column>
                  <Checkbox
                    className='delete-gateway-checkbox'
                    id='deleteGatewayCheckbox'
                    checked={isGatewayCheckboxChecked}
                    labelText={t('deleteGateway.gatewayCheckboxLabel', {
                      number: getTotalCount(),
                    })}
                    onChange={(e: any) =>
                      setIsGatewayCheckboxChecked(!isGatewayCheckboxChecked)
                    }
                  />
                  <div className='multi-group-items'>
                    <div className='multigroup-item-header'>
                      {gatewaySubtypeName === 'RHSI-EDGE'
                        ? t('deleteGateway.disconnectNamespaceList', {
                            count: 1,
                          })
                        : t('deleteGateway.disconnectEnvList', {
                            count: deploymentEnvs?.length ?? 0,
                          })}
                    </div>
                    <div>
                      {gatewaySubtypeName === 'RHSI-EDGE'
                        ? getNamespaceList()
                        : getEnvironmentsList()}
                    </div>
                    <div className='multigroup-item-header'>
                      {t('deleteGateway.unregisterRemoteConnectionsList', {
                        count: remoteConnections?.length ?? 0,
                      })}
                    </div>
                    <div>{getRemoteConnectionsList()}</div>
                    <div className='multigroup-item-header'>
                      {t('deleteGateway.unregisterGatewaysList')}
                    </div>
                    <div className='multigroup-item'>
                      {gatewayDetails?.name}
                    </div>
                  </div>
                </Column>
              </Row>
            </div>
          </Modal>
        ) : (
          <Modal
            size='sm'
            danger
            className='no-deployment-modal'
            modalHeading={t('deleteGateway.modalTitle', {
              gatewayName: gatewayDetails?.name ?? '',
            })}
            onRequestClose={handleCancelDeleteGateway}
            onRequestSubmit={submitDeleteRequest}
            preventCloseOnClickOutside
            primaryButtonText={t('deleteGateway.deleteButton')}
            secondaryButtonText={t('deleteGateway.cancelButton')}
            primaryButtonDisabled={disableDeleteButton}
            open={open}
          >
            {t('deleteGateway.description')}
          </Modal>
        )}
      </div>
    )
  );
};

export default DeleteGateway;
