import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header/Header';
import DetailsCard from '../../components/DetailsCard/DetailsCard';
import images from '../../images/images';
import { useEffect, useState } from 'react';
import {
  Link,
  useSearchParams,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import {
  ApplicationData,
  Deployment,
  DeploymentEnvironment,
  DeploymentInstance,
  Error500Type,
  ServiceData,
  ServiceEndPoints,
} from '../../models/master';
import {
  getDeploymentEnv,
  getDeploymentEnvSubtypes,
  getPartition,
  getPartitions,
} from '../../controllers/deploymentEnv';
import {
  Button,
  Column,
  DataTableSkeleton,
  InlineNotificationProps,
  Modal,
  SkeletonPlaceholder,
  TooltipDefinition,
} from 'carbon-components-react';
import {
  deleteDeploymentServiceEndPoints,
  deleteDeploymentInstance,
  getAllDeploymentInstance,
  getApplication,
  getDeploymentServiceEndPoints,
  deleteDeployment,
  getDeployment,
} from '../../controllers/applicationApis';
import InstacneApplDepl from './InstanceApplicationDepl';
import ServicesEndPoints from './ServiceEndPointsApplDeployments';
import Error500 from '../Errors/Error500';

import './ApplicationDeploymentDetails.scss';
import { NotificationContext } from '../../components/Notifications/Context/NotificationProvider';
import IconWithToolTip from '../../components/IconWithToolTip/IconWithToolTip';
import { AxiosError } from 'axios';
import GenericTruncateString from '../../components/GenericTruncateString/GenericTruncateString';

interface ApplicationDetailsData {
  appName: string | undefined;
  envName: string;
  partName: string;
  envType: string;
  services: string | undefined;
  locationName: string;
  envId: string;
  partitionId: string;
}

type LocationState = {
  from: string;
};

const ApplicationDeploymentDetails = () => {
  const { t } = useTranslation('applicationDeploymentDetails');
  const [applicationDetailsData, setApplicationDetailsData] =
    useState<ApplicationData | null>(null);
  const [applDeplDetailsData, setAppDeplDetailsData] =
    useState<DeploymentEnvironment | null>(null);
  const [applDeplDetailsDataLoading, toogleApplDeplDetailsDataLoading] =
    useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const deplId = searchParams.get('deplId');
  const appId = searchParams.get('appId');
  const [deploymentenvId, setDeploymentenvId] = useState<Deployment | any>(
    null
  );
  const [deploymentenvDataId, setDeploymentenvDataId] = useState<
    Deployment | any
  >(null);
  const [applicationDeleteId, setApplicationDeleteId] = useState<
    ApplicationData | any
  >(null);
  // const deploymentenvId = searchParams.get('deploymentenvId');
  const [deployments, setDeployments] = useState<Deployment | any>(null);
  const [depEnvSubTypesNew, setDepEnvSubTypes] = useState<any>(null);
  const [instanceData, setInstanceData] = useState<DeploymentInstance[] | []>(
    []
  );
  const [partitionData, setPartitionData] = useState<
    DeploymentEnvironment[] | any
  >();
  const [serviceEndPointsData, setServiceEndPointsData] = useState<
    ServiceEndPoints[] | []
  >([]);
  const [deleteInstanceModalOpen, toggleDeleteInstanceModal] = useState(false);
  const [deleteServiceEndPointsModal, toggleDeleteServiceEndPointsModal] =
    useState(false);
  const [deleteDeploymentModalOpen, toggleDeploymentModal] = useState(false);

  const [appDeplDetailsFormatData, setAppDeplDetailsFormatData] = useState<
    ApplicationDetailsData | any
  >();
  const [deleteServiceEndPoints, setDeleteServiceEndPoints] =
    useState<ServiceEndPoints | null>(null);
  const [deleteInstance, setInstance] = useState<DeploymentInstance | null>(
    null
  );
  const [deleteAppDeployment, setAppDeployment] = useState<Deployment | any>(
    null
  );
  const [disableDeleteBtn, setDisableDeleteBtn] = useState(false);
  const [notificationData, setNotificationData] =
    useState<InlineNotificationProps>({} as InlineNotificationProps);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [error500, setError500] = useState<null | Error500Type>(null);

  const notification = useContext(NotificationContext);
  const navigate = useNavigate();

  const location = useLocation();
  const state = location.state as LocationState;

  const formatApplicationDeplDetailsData = (
    data: ApplicationData | null,
    deploymentsData: any
  ) => {
    const appDeplDetailsData: ApplicationDetailsData = {
      appName: '',
      envName: '',
      partName: '',
      envType: '',
      services: '',
      locationName: '',
      envId: '',
      partitionId: '',
    };

    const selectedDepEnvSubType = depEnvSubTypesNew?.find(
      (depEnv: { type_code: string | undefined }) =>
        depEnv.type_code === deploymentsData?.subtype
    );
    const environmentType = selectedDepEnvSubType
      ? selectedDepEnvSubType.type_name
      : '';

    let serviceValue = '';
    applicationDetailsData?.services.forEach((service, index) => {
      const seperator =
        index < applicationDetailsData?.services.length - 1 ? ', ' : '';
      serviceValue =
        serviceValue +
        service.name +
        ' (' +
        service.ports.map(port => port.port_number) +
        ')' +
        seperator;

      return null;
    });

    const selectedPartionName = partitionData?.find(
      (partitionName: { resource_id: string | undefined }) =>
        partitionName.resource_id === deployments?.partition_id
    );
    const partitionName = selectedPartionName ? selectedPartionName.name : '';

    appDeplDetailsData.appName = data?.name;
    appDeplDetailsData.services = serviceValue.replace(/,*$/, '');
    appDeplDetailsData.envName = deploymentsData?.name;
    appDeplDetailsData.partName = partitionName;
    appDeplDetailsData.envId = deploymentsData?.resource_id;
    appDeplDetailsData.partitionId = deployments?.partition_id;
    appDeplDetailsData.envType = environmentType;
    appDeplDetailsData.locationName = deploymentsData?.location_name;
    setAppDeplDetailsFormatData(appDeplDetailsData);

    return appDeplDetailsData;
  };

  useEffect(() => {
    toogleApplDeplDetailsDataLoading(true);
    const fetchData = async () => {
      try {
        let res = await getApplication(appId);
        setApplicationDetailsData(res);
      } catch (error) {
        const err = error as AxiosError;
        if (err.response?.status === 404) {
          navigate('/404');
        }

        if (err.response!?.status >= 500) {
          setError500(err.response!?.status?.toString() as Error500Type);
        }

        toogleApplDeplDetailsDataLoading(false);
      }
    };
    fetchData();
  }, []);

  const fetchDeploymentsData = async () => {
    try {
      const deploymentsData = await getDeployment(appId, deplId);
      setDeployments(deploymentsData);
      fetchDeploymentEnvId(deploymentsData);
      fetchPartitionData(deploymentsData);
      fetchDeploymentEnvIdSubTypes(deploymentsData);
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status === 404) {
        navigate('/404');
      }
      toogleApplDeplDetailsDataLoading(false);
    }
  };

  const fetchDeploymentEnvId = async (deploymentsData: Deployment) => {
    try {
      const res = await getDeploymentEnv(deploymentsData?.depl_env_id);
      setAppDeplDetailsData(res);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDeploymentEnvIdSubTypes = async (deploymentsData: Deployment) => {
    const depEnvSubTypes = await getDeploymentEnvSubtypes();
    setDepEnvSubTypes(depEnvSubTypes);
  };

  const fetchPartitionData = async (deploymentsData: Deployment) => {
    try {
      const resPartition = await getPartitions(deploymentsData?.depl_env_id);
      setPartitionData(resPartition.partitions);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    toogleApplDeplDetailsDataLoading(true);
    fetchDeploymentsData();
    fetchServiceEndPointsData();
    fetchInstanceData();
  }, []);

  useEffect(() => {
    if (applDeplDetailsData !== null) {
      toogleApplDeplDetailsDataLoading(false);
    }
  }, [applDeplDetailsData]);

  const fetchServiceEndPointsData = async () => {
    try {
      const resServiceEndpoint = await getDeploymentServiceEndPoints(
        appId,
        deplId
      );
      setServiceEndPointsData(resServiceEndpoint.svc_endpoints);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchInstanceData = async () => {
    let resInstance = await getAllDeploymentInstance(appId, deplId);
    setInstanceData(resInstance.insts);
  };

  useEffect(() => {
    formatApplicationDeplDetailsData(
      applicationDetailsData,
      applDeplDetailsData
    );
  }, [applicationDetailsData, applDeplDetailsData]);

  const refreshData = () => {
    console.log('Refresh Data');
  };

  const confirmDeleteServiceEndPoints = async (serviceId: any) => {
    toggleDeleteServiceEndPointsModal(false);
    try {
      setDisableDeleteBtn(true);
      toogleApplDeplDetailsDataLoading(true);
      await deleteDeploymentServiceEndPoints(
        appId,
        deplId,
        deleteServiceEndPoints?.resource_id
      );
      setDeleteServiceEndPoints(null);
      setServiceEndPointsData(
        serviceEndPointsData!.filter(
          service => service?.resource_id !== serviceId
        )
      );
      // Trigger success toastbar
      notification.onTrigger('TOAST', {
        title: t('serviceEndPoints.successNotificationTitle'),
        subtitle: t('serviceEndPoints.removeServiceSuccess'),
      });
    } catch (error) {
      const err = error as AxiosError;
      console.error(error);
      notification.onTrigger('TOAST', {
        kind: 'error',
        title:
          err.response?.status === 403
            ? `${t('authErrorTitle')}`
            : `${t('serviceEndPoints.errorNotificationTitle')}`,
        subtitle:
          err.response?.status === 403
            ? `${t('authErrorSubtitle')}`
            : t('serviceEndPoints.removeServiceEndPointFail'),
      });

      setOpenErrorModal(true);
    } finally {
      toogleApplDeplDetailsDataLoading(false);
      setDisableDeleteBtn(false);
    }
  };

  const requestDeleteInstance = (instanceId: any) => {
    toggleDeleteInstanceModal(true);
    setInstance(instanceId);
  };

  const requestDeleteServiceEndPoints = (serviceEndPointId: any) => {
    setDeleteServiceEndPoints(serviceEndPointId);
    toggleDeleteServiceEndPointsModal(true);
  };

  const confirmDeleteInstance = async (instanceDeleteId: any) => {
    toggleDeleteInstanceModal(false);
    try {
      setDisableDeleteBtn(true);
      toogleApplDeplDetailsDataLoading(true);
      await deleteDeploymentInstance(
        appId,
        deplId,
        deleteInstance?.resource_id
      );
      setInstance(null);
      setInstanceData(
        instanceData!.filter(
          instance => instance?.resource_id !== instanceDeleteId
        )
      );
      // Trigger success toastbar
      notification.onTrigger('TOAST', {
        title: t('instances.successNotificationTitle'),
        subtitle: t('instances.removeServiceSuccess'),
      });
    } catch (error) {
      const err = error as AxiosError;
      console.error(error);
      notification.onTrigger('TOAST', {
        kind: 'error',
        title:
          err.response?.status === 403
            ? `${t('authErrorTitle')}`
            : `${t('instances.errorNotificationTitle')}`,
        subtitle:
          err.response?.status === 403
            ? `${t('authErrorSubtitle')}`
            : t('instances.removeInstanceFail'),
      });

      setOpenErrorModal(true);
    } finally {
      toogleApplDeplDetailsDataLoading(false);
      setDisableDeleteBtn(false);
    }
  };

  const handleDeleteDeployment = async (deploymentDeleteId: any) => {
    toggleDeploymentModal(false);
    try {
      toogleApplDeplDetailsDataLoading(true);
      let successMsg = t('deployment.removeDeployementSuccess');
      let successUrl = `/applicationDetails?appId=${appId}`;
      if (state.from === 'namespaceDetailsPage') {
        successMsg = t('deployment.removeDeployementSuccessNamespace');
        successUrl = `/partitionDetails?depEnvId=${appDeplDetailsFormatData?.envId}&partitionId=${appDeplDetailsFormatData?.partitionId}`;
      }
      await deleteDeployment(appId, deploymentDeleteId);
      setDeploymentenvId(null);
      setDeploymentenvDataId(deployments?.resource_id !== deploymentDeleteId);
      // Trigger success toastbar
      notification.onTrigger('TOAST', {
        title: t('deployment.successNotificationTitle'),
        subtitle: successMsg,
      });
      navigate(successUrl);
    } catch (error) {
      const err = error as AxiosError;

      notification.onTrigger('TOAST', {
        kind: 'error',
        title:
          err.response?.status === 403
            ? `${t('authErrorTitle')}`
            : `${t('deploymentNotificationMessages.errorNotificationTitle')}`,
        subtitle:
          err.response?.status === 403
            ? `${t('authErrorSubtitle')}`
            : t('deploymentNotificationMessages.removeAppDeploymentFail'),
      });

      console.error(error);
    } finally {
      toogleApplDeplDetailsDataLoading(false);
    }
  };

  const requestDeleteDeployment = (deplId: string, appId: string) => {
    setDeploymentenvDataId(deplId);
    setApplicationDeleteId(appId);
    toggleDeploymentModal(true);
  };

  const getModalParams = (type: string) => {
    if (type === 'instanceModal') {
      return {
        header: t('instances.deleteModal.header'),
        body: t('instances.deleteModal.body', {
          local_ip: deleteInstance?.ip_address,
        }),
        confirmFn: () => confirmDeleteInstance(deleteInstance?.resource_id),
        cancelFn: () => toggleDeleteInstanceModal(false),
      };
    } else if (type === 'serviceEndPointsModal') {
      return {
        header: t('serviceEndPoints.deleteModal.header'),
        body: t('serviceEndPoints.deleteModal.body', {
          local_ip: deleteServiceEndPoints?.local_service_ip_address,
        }),
        confirmFn: () =>
          confirmDeleteServiceEndPoints(deleteServiceEndPoints?.resource_id),
        cancelFn: () => toggleDeleteServiceEndPointsModal(false),
      };
    } else if (type === 'deploymentModal') {
      return {
        header: t('deployment.deleteModal.header'),
        body: t('deployment.deleteModal.body', {
          application_name: applicationDetailsData?.name,
          env_name: appDeplDetailsFormatData?.envName,
          partitionName: appDeplDetailsFormatData?.partName,
        }),
        confirmFn: () => handleDeleteDeployment(deployments?.resource_id),
        cancelFn: () => toggleDeploymentModal(false),
      };
    }
  };

  const deleteModal = (type: string, isOpen: boolean) => {
    const modalParams = getModalParams(type);
    return (
      <Modal
        className='delete-application-deployment-modal'
        danger
        modalHeading={modalParams?.header ?? ''}
        onRequestClose={() => modalParams?.cancelFn()}
        onRequestSubmit={() => modalParams?.confirmFn()}
        primaryButtonText={t('instances.deleteModal.confirm')}
        secondaryButtonText={t('instances.deleteModal.cancel')}
        open={isOpen}
        primaryButtonDisabled={disableDeleteBtn}
        size='sm'
      >
        {modalParams?.body ?? ''}
      </Modal>
    );
  };

  const getTitle = () => {
    return (
      <div className='header__title_appdepl'>
        <GenericTruncateString str={appDeplDetailsFormatData?.envName} /> /
        <GenericTruncateString str={appDeplDetailsFormatData?.partName} />
      </div>
    );
  };

  const getBreadCrumbs = () => {
    if (state != null && state.from === 'namespaceDetailsPage')
      return [
        {
          url: '/',
          label: t('home'),
        },
        {
          url: '/deploymentenvironments',
          label: t('deploymentEnvironments'),
        },
        {
          url: `/deploymentEnvironmentDetails?deplId=${appDeplDetailsFormatData?.envId}`,
          label: appDeplDetailsFormatData?.envName ?? '',
        },
        {
          url: `/partitionDetails?depEnvId=${appDeplDetailsFormatData?.envId}&partitionId=${appDeplDetailsFormatData?.partitionId}`,
          label: appDeplDetailsFormatData?.partName ?? '',
        },
      ];
    return [
      {
        url: '/',
        label: t('home'),
      },
      {
        url: '/applications',
        label: t('applications'),
      },
      {
        url: `/applicationDetails?appId=${appId}`,
        label: appDeplDetailsFormatData?.appName ?? '',
      },
    ];
  };

  const handleRegisterInstance = (instance: DeploymentInstance) => {
    Array.isArray(instanceData)
      ? setInstanceData(prevData => [instance, ...prevData])
      : setInstanceData([instance]);

    // Temporary fix for consumer_ip not assigned for few seconds when we add a new instance
    setTimeout(() => {
      fetchInstanceData();
    }, 5000);
  };

  const handleRegisterServiceEndpoint = (serviceEndPoint: ServiceEndPoints) => {
    Array.isArray(serviceEndPointsData)
      ? setServiceEndPointsData(prevData => [serviceEndPoint, ...prevData])
      : setServiceEndPointsData([serviceEndPoint]);
  };

  if (error500) {
    return <Error500 />;
  }

  return (
    <div className='application-depl-details'>
      <Header
        loading={applDeplDetailsDataLoading}
        title={getTitle()}
        subTitle={appDeplDetailsFormatData?.appName ?? ''}
        headerIcon={
          applDeplDetailsData?.is_discovered ? (
            <IconWithToolTip
              icon={images.AutoDiscoverdLockIcon()}
              iconDescription={t('autoDiscoveredAccessLimited')}
            />
          ) : (
            false
          )
        }
        breadcrumbs={getBreadCrumbs()}
      />
      <div className='appl-depl-details-card'>
        <Column lg={16} className='appl-depl-card'>
          {applDeplDetailsDataLoading ? (
            <div className='page-content'>
              <SkeletonPlaceholder className='details-tile-skeleton' />
            </div>
          ) : (
            <DetailsCard
              type={'APPLICATION_DEPLYOMENT'}
              isEditable={false}
              data={[
                {
                  key: 'name',
                  dataTestId: 'app-depl-details',
                  value: (
                    <div className='name-column-value'>
                      <Link
                        className='no-underline-link'
                        to={`/applicationDetails?appId=${appId}`}
                      >
                        <GenericTruncateString
                          str={appDeplDetailsFormatData?.appName}
                          tableView={false}
                          limit={16}
                        />
                      </Link>
                    </div>
                  ),
                },
                {
                  key: 'environment / partition',
                  dataTestId: 'app-depl-details',
                  value: (
                    <div className='name-column-value'>
                      <Link
                        className='no-underline-link'
                        to={`/deploymentEnvironmentDetails?deplId=${deployments?.depl_env_id}`}
                        data-testId='app-depl-details-environment'
                      >
                        <GenericTruncateString
                          str={appDeplDetailsFormatData?.envName}
                          tableView={false}
                          limit={14}
                        />
                      </Link>
                      <span> / </span>
                      <div>
                        <Link
                          className='no-underline-link'
                          to={`/partitionDetails?depEnvId=${deployments?.depl_env_id}&partitionId=${deployments?.partition_id}`}
                          data-testId='app-depl-details-partitions'
                        >
                          <GenericTruncateString
                            str={appDeplDetailsFormatData?.partName}
                            tableView={false}
                            limit={14}
                          />
                        </Link>
                      </div>
                    </div>
                  ),
                },
                {
                  key: 'environmentType',
                  dataTestId: 'app-depl-details',
                  value: appDeplDetailsFormatData?.envType,
                },
                {
                  key: 'services',
                  dataTestId: 'app-depl-details',
                  value: (
                    <div className='name-column-value'>
                      {appDeplDetailsFormatData?.services
                        ? appDeplDetailsFormatData?.services
                        : '—'}
                    </div>
                  ),
                },
                {
                  key: 'location',
                  dataTestId: 'app-depl-details',
                  value: (
                    <div className='name-column-value'>
                      <Link
                        className='no-underline-link'
                        to={`/locationDetails?cloudId=${applDeplDetailsData?.cloud_id}&locationId=${applDeplDetailsData?.location_id}`}
                      >
                        <GenericTruncateString
                          str={appDeplDetailsFormatData?.locationName}
                          tableView={false}
                          limit={14}
                        />
                      </Link>
                    </div>
                  ),
                },
              ]}
              detailsCardName={t('header')}
              data-testid='app-depl-details-heading'
            />
          )}
        </Column>
      </div>
      <div className='instances-table-card'>
        <InstacneApplDepl
          instanceData={instanceData}
          onRefresh={() => refreshData()}
          removeInstance={instanceId => requestDeleteInstance(instanceId)}
          filteredDataCallback={function (data: any): void {
            throw new Error('Function not implemented.');
          }}
          filtersAppliedCallback={function (data: any): void {
            throw new Error('Function not implemented.');
          }}
          onPageChange={function (pageData: any): void {
            throw new Error('Function not implemented.');
          }}
          sortRows={function (arg0: unknown, sortDirection: string): void {
            throw new Error('Function not implemented.');
          }}
          applicationId={appId}
          deploymentId={deplId}
          onRegisterInstance={handleRegisterInstance}
          resourceGroupId={deployments?.resource_group_id ?? null}
        />
      </div>
      <div className='instances-table-card'>
        <ServicesEndPoints
          serviceEndPointsData={serviceEndPointsData}
          onRefresh={() => refreshData()}
          removeServiceEndPoints={serviceEndPointId =>
            requestDeleteServiceEndPoints(serviceEndPointId)
          }
          filteredDataCallback={function (data: any): void {
            throw new Error('Function not implemented.');
          }}
          filtersAppliedCallback={function (data: any): void {
            throw new Error('Function not implemented.');
          }}
          onPageChange={function (pageData: any): void {
            throw new Error('Function not implemented.');
          }}
          sortRows={function (arg0: unknown, sortDirection: string): void {
            throw new Error('Function not implemented.');
          }}
          applicationId={appId}
          deploymentId={deplId}
          deploymentEnvId={deployments?.depl_env_id}
          services={applicationDetailsData?.services ?? null}
          onRegisterServiceEndpoint={handleRegisterServiceEndpoint}
        />
      </div>
      {instanceData.length > 0 || serviceEndPointsData.length > 0 ? (
        <TooltipDefinition
          tooltipText={t('disableTooltipText') as string}
          direction='top'
        >
          <Button
            className='deploymentDeteteButton'
            kind='danger--ghost'
            disabled={true}
          >
            {t('deleteApplicationDeployment')}
          </Button>
        </TooltipDefinition>
      ) : (
        <Button
          className='deploymentDeteteButton'
          kind='danger--ghost'
          disabled={false}
          onClick={() =>
            requestDeleteDeployment(deployments, appDeplDetailsFormatData)
          }
        >
          {t('deleteApplicationDeployment')}
        </Button>
      )}
      {deleteModal('instanceModal', deleteInstanceModalOpen)}
      {deleteModal('serviceEndPointsModal', deleteServiceEndPointsModal)}
      {deleteModal('deploymentModal', deleteDeploymentModalOpen)}
    </div>
  );
};

export default ApplicationDeploymentDetails;
