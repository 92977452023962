import { useMutation, useQuery } from '@tanstack/react-query';
import {
  addCloud,
  deleteCloud,
  getCloudDetails,
  getClouds,
  updateCloud,
  getCloudTypes,
} from '../controllers/cloud';
import { QueryOptions, queryDefaultOptions } from './queryDefaults';
import { MutationKeys, QueryKeys } from '../lib/enums';

export const useCloudsData = (options?: QueryOptions) => {
  return useQuery({
    queryKey: [QueryKeys.CLOUDS],
    queryFn: () => getClouds(),
    ...queryDefaultOptions,
    ...options,
    ...{ staleTime: 1000 * 30 },
  });
};

export const useCloudsDetails = (cloudId: string, options?: QueryOptions) => {
  return useQuery({
    queryKey: [QueryKeys.CLOUDDETAILS, cloudId],
    queryFn: () => getCloudDetails(cloudId),
    ...queryDefaultOptions,
    ...options,
  });
};

export const useAddCloudData = (options?: QueryOptions) => {
  return useMutation({
    mutationKey: [MutationKeys.ADDCLOUD],
    mutationFn: addCloud,
    ...queryDefaultOptions,
    ...options,
  });
};

export const useUpdateCloudData = (options?: QueryOptions) => {
  return useMutation({
    mutationKey: [MutationKeys.EDITCLOUD],
    mutationFn: updateCloud,
    ...queryDefaultOptions,
    ...options,
  });
};

export const useDeleteCloudData = (options?: QueryOptions) => {
  return useMutation({
    mutationKey: [MutationKeys.DELETECLOUD],
    mutationFn: deleteCloud,
    ...queryDefaultOptions,
    ...options,
  });
};

export const useCloudTypes = (options?: QueryOptions) => {
  return useQuery({
    queryKey: [QueryKeys.CLOUDTYPES],
    queryFn: () => getCloudTypes(),
    ...queryDefaultOptions,
    ...options,
    ...{ staleTime: Infinity },
  });
};
