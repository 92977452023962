import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import GenericTableWithFilters from '../../../components/GenericTableWithFilters/GenericTableWithFilters';
import dateUtils from '../../../lib/dates';
import { Link } from 'react-router-dom';
import LabelTag from '../../../components/LabelTag/LabelTag';
import { IdentityData } from '../../../models/master';
import images from '../../../images/images';
import { TooltipDefinition } from 'carbon-components-react';
import GenericTruncateString from '../../../components/GenericTruncateString/GenericTruncateString';
import IdentityTag from '../../../components/IdentityTag/IdentityTag';

import './IdentityTable.scss';
import useAnalytics from '../../../lib/useAnalytics';
import analyticsData from '../../../lib/analyticsEventData';

const IdentityTable = (props: {
  rows: IdentityData[] | null;
  onRefresh: () => void;
  filteredDataSet: IdentityData[] | null;
  elementCount: number;
  data: IdentityData[] | null;
  filteredDataCallback: (data: any) => void;
  filtersSelected: any;
  filtersAppliedCallback: (data: any) => void;
  filters: any;
  currentPageNumber: number;
  currentPageSize: number;
  onPageChange: (pageData: any) => void;
  sortRows(arg0: unknown, sortDirection: string): void;
  persistFilter?: boolean;
  error500Flag: boolean;
}) => {
  const { t } = useTranslation('identity');
  const { rows, onRefresh } = props;

  const error500Flag = props.error500Flag;

  const navigate = useNavigate();
  const { trackButtonClicked } = useAnalytics();

  const formatTags = (data: string[]) => {
    return Array.isArray(data) && data.length > 0 ? (
      <LabelTag labelArray={data} count={3} />
    ) : (
      <p>&mdash;</p>
    );
  };

  const identityTableHeader = [
    {
      key: 'name',
      originalKey: 'name',
      header: t('tableHeaders.name'),
      sort: 'sortByName',
    },
    {
      key: 'email_id',
      originalKey: 'resource_id',
      header: t('tableHeaders.email_id'),
      sort: 'sortByResourceId',
    },
    {
      key: 'roles',
      originalKey: 'rolesList',
      header: t('tableHeaders.roles'),
      sort: 'sortByRoles',
    },
    {
      key: 'labels',
      originalKey: 'labels',
      header: t('tableHeaders.labels'),
    },
    {
      key: 'updated_at',
      originalKey: 'updated_at',
      header: t('tableHeaders.last_updated'),
      sort: 'sortByCreated',
      style: { minWidth: '14rem' },
    },
  ];

  const setRowsData = () => {
    let formattedRows: {
      id: any;
      name: JSX.Element;
      email_id: string;
      roles: JSX.Element | undefined | string;
      rolesList: string[];
      labels: JSX.Element | string;
      updated_at: any;
    }[] = [];
    if (error500Flag) return [];
    if (rows && rows.length === 0) return [];
    if (rows)
      rows.map((row: IdentityData) => {
        const getRolesMappedValues = (
          from: number,
          to: number,
          isTooltip?: boolean
        ) => {
          return row.roles.slice(from, to).map((role: any, index: any) => (
            <span key={role.name}>
              <span>{role.name}</span>
              {index < row.roles.length - 1 && !isTooltip && (
                <span className='roles-spacing'>{', '}</span>
              )}
            </span>
          ));
        };
        const renderRolesName = () => {
          if (row.roles.length > 0) {
            return (
              <div className='identity-table-tooltip'>
                {getRolesMappedValues(0, 2)}
                {row.roles.length > 2 ? (
                  <TooltipDefinition
                    tooltipText={getRolesMappedValues(
                      2,
                      row.roles.length,
                      true
                    )}
                    direction='bottom'
                    align='center'
                  >
                    {`+${row.roles.slice(2).length}`}
                  </TooltipDefinition>
                ) : (
                  ''
                )}
              </div>
            );
          } else {
            return '—';
          }
        };
        formattedRows.push({
          id: row.resource_id,
          name: (
            <div className='identity__table_name'>
              {!!row.name ? (
                <>
                  <IdentityTag name={row?.name ?? ''} size='lg' />
                  <Link
                    className='no-underline-link'
                    to={`/IdentityDetails?identity_id=${row.resource_id}`}
                    onClick={() =>
                      trackButtonClicked(
                        analyticsData['Admin Identities'].events.viewIdentities
                          .props,
                        analyticsData['Admin Identities'].events.viewIdentities
                          .event
                      )
                    }
                  >
                    <GenericTruncateString str={row.name} tableView={true} />
                  </Link>
                </>
              ) : (
                '—'
              )}
            </div>
          ),
          email_id: !!row.resource_id ? row.resource_id : '—',
          roles: renderRolesName(),
          rolesList: row.rolesList,
          labels: !!row.labels ? formatTags(row?.labels) : <p>&mdash;</p>,
          updated_at: !!row.updated_at
            ? dateUtils.getUserFriendlyDate(row.updated_at)
            : '—',
        });
        return 0;
      });
    else return null;
    return formattedRows;
  };

  const emptyStateData = {
    icon: images.noIdentityIcon(),
    notFoundIcon: images.NotFoundLarge(),
    emptyStateHeader: t('emptyState.emptyContainerHeader'),
    emptyStateDescription: t('emptyState.emptyContainerDescription'),
    link: '/',
    buttonText: t('emptyState.buttonText'),
    click: () =>
      navigate('/identities', {
        state: { resourceType: 'IDENTITY' },
      }),
  };

  return (
    <GenericTableWithFilters
      id='connection-access-policy-table-with-filter-panel'
      rows={setRowsData()}
      data={props.filteredDataSet}
      headers={identityTableHeader}
      isSortable={true}
      totalElementsCount={props.elementCount ? props.elementCount : 0}
      fullData={props.data}
      onTableRefresh={() => onRefresh()}
      filteredDataCallback={(data: any) => props.filteredDataCallback(data)}
      selectedFiltersVal={props.filtersSelected as any}
      setFilterApplied={(data: any) => props.filtersAppliedCallback(data)}
      filters={props.filters}
      currentPageNumber={props.currentPageNumber}
      currentPageSize={props.currentPageSize}
      onPageChange={(pageData: any) => props.onPageChange(pageData)}
      emptyState={emptyStateData}
      sortRows={(data, sortDirection) => props.sortRows(data, sortDirection)}
      persistFilter={props.persistFilter}
      // paginationSizeSelections={[2, 3, 5, 10]}
      render500Container={props.error500Flag}
    />
  );
};

export default IdentityTable;
