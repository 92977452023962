import { TFunction } from 'react-i18next';
import { Application16, EdgeService16 } from '@carbon/icons-react';

import images from '../../images/images';
import { ReactComponent as PartitionIcon } from '../../images/partition.svg';
import { NetworkSegments, PartitionData, Service } from '../../models/master';

export type SelectedType = 'from' | 'to';
export type TableTypes =
  | 'applications'
  | 'services'
  | 'partitions'
  | 'nwSegments';

export type Application = {
  id: string;
  name: string;
  resourceGroups: string;
  services: number;
  labels: string[] | JSX.Element;
};

export interface ServiceData extends Service {
  id: string;
  applicationName: string;
  network_segment_id?: string;
}
export interface NetworkSegmentData extends NetworkSegments {
  id: string;
  resourceGroupName?: string;
}

export interface Item {
  application_id?: any;
  id: string;
  parentId?: string;
  label: string;
  type: TableTypes;
}

export interface FormData {
  from: Item[];
  to: Item[];
}

export type ConnectionTypesItems =
  | Application[]
  | ServiceData[]
  | PartitionData[]
  | NetworkSegmentData[]
  | []
  | null;

export interface DropdownData {
  type: string;
  suggestionTitle: string;
  suggestionOptions: ConnectionTypesItems;
  icon: any;
  suggestionDisplayKey?: any;
  filterKey: any;
}

export const getTabConfig = (type: SelectedType, t: TFunction) => {
  const tabConfig = {
    from: [
      {
        icon: Application16,
        label: t('applications'),
        name: 'applications',
        valueColumn: 'name',
      },
      {
        icon: PartitionIcon,
        label: t('partitions'),
        name: 'partitions',
        valueColumn: 'name',
      },
    ],
    to: [
      {
        icon: EdgeService16,
        label: t('services'),
        name: 'services',
        valueColumn: 'name',
      },
    ],
  };

  return tabConfig[type];
};

export const getTableHeaders = (type: TableTypes, t: TFunction) => {
  const headerConfig = {
    applications: [
      {
        key: 'checkbox',
        originalKey: 'checkbox',
        header: '',
      },
      {
        key: 'name',
        originalKey: 'name',
        header: t('table.applications.name'),
        sort: 'sortByName',
      },
      {
        key: 'resourceGroupName',
        originalKey: 'resourceGroupName',
        header: t('table.applications.resourceGroups'),
      },
      {
        key: 'services',
        originalKey: 'services',
        header: t('table.applications.services'),
        sort: 'sortByServices',
      },
      {
        key: 'labels',
        originalKey: 'labels',
        header: t('table.applications.labels'),
      },
    ],
    services: [
      {
        key: 'checkbox',
        originalKey: 'checkbox',
        header: '',
      },
      {
        key: 'name',
        originalKey: 'name',
        header: t('table.services.name'),
        sort: 'sortByName',
      },
      {
        key: 'applicationName',
        originalKey: 'applicationName',
        header: t('table.services.applicationName'),
        sort: 'sortByApplicationName',
      },
      {
        key: 'portProtocol',
        header: t('table.services.portProtocol'),
      },
      {
        key: 'labels',
        header: t('table.services.labels'),
      },
    ],
    partitions: [
      {
        key: 'checkbox',
        originalKey: 'checkbox',
        header: '',
      },
      {
        key: 'name',
        originalKey: 'name',
        header: t('table.partitions.name'),
        sort: 'sortByName',
      },
      {
        key: 'environment',
        originalKey: 'environment',
        header: t('table.partitions.environment'),
        sort: 'sortByEnvironment',
      },
      {
        key: 'location_name',
        originalKey: 'location_name',
        header: t('table.partitions.location'),
      },
      {
        key: 'cloud_name',
        originalKey: 'cloud_name',
        header: t('table.partitions.cloud'),
      },
      {
        key: 'typeName',
        originalKey: 'typeName',
        header: t('table.partitions.type'),
      },
    ],
    nwSegments: [],
  };

  return headerConfig[type];
};

export const getTableFilters = (type: TableTypes, t: TFunction) => {
  const filters = {
    applications: [
      {
        id: 'labels',
        columnKey: 'labels',
        label: t('table.applications.labels'),
        type: 'multi',
        key: 'labels',
      },
    ],
    services: [
      {
        id: 'applications',
        columnKey: 'applicationName',
        label: t('table.services.applicationName'),
        type: 'single',
        key: 'applicationName',
      },
      {
        id: 'port',
        columnKey: 'ports',
        label: t('table.services.port'),
        type: 'multi',
        key: 'ports',
      },
      {
        id: 'labels',
        columnKey: 'labels',
        label: t('table.services.labels'),
        type: 'multi',
        key: 'labels',
      },
    ],
    partitions: [
      {
        id: 'environment',
        columnKey: 'environment',
        label: t('table.partitions.environment'),
        type: 'multi',
        key: 'environment',
      },
      {
        id: 'location_name',
        columnKey: 'location_name',
        label: t('table.partitions.location'),
        type: 'multi',
        key: 'location_name',
      },
      {
        id: 'typeName',
        columnKey: 'typeName',
        label: t('table.partitions.type'),
        type: 'single',
        key: 'typeName',
      },
    ],
    nwSegments: [],
  };

  return filters[type];
};

export const getEmptyStateValue = (
  type: TableTypes,
  t: TFunction,
  navigate: TFunction
) => {
  const emptyStateConfig = {
    applications: {
      icon: images.noApplicationsIcon(),
      notFoundIcon: images.NotFoundSmall(),
      emptyStateHeader: t('emptyState.applications.emptyContainerHeader'),
      emptyStateDescription: t(
        'emptyState.applications.emptyContainerDescription'
      ),
      link: '/',
      buttonText: t('emptyState.applications.buttonText'),
      click: () =>
        navigate('/applications', { state: { resourceType: 'APPLICATION' } }),
    },
    services: {
      icon: images.noServicesLargeIcon(),
      notFoundIcon: images.NotFoundSmall(),
      emptyStateHeader: t('emptyState.services.emptyContainerHeader'),
      emptyStateDescription: t('emptyState.services.emptyContainerDescription'),
      link: false,
      buttonText: t('emptyState.services.buttonText'),
      click: () => {},
    },
    partitions: {
      icon: images.noPartitionsIcon(),
      notFoundIcon: images.NotFoundSmall(),
      emptyStateHeader: t('emptyState.partitions.emptyContainerHeader'),
      emptyStateDescription: t(
        'emptyState.partitions.emptyContainerDescription'
      ),
      link: '/',
      buttonText: t('emptyState.partitions.buttonText'),
      click: () => {},
    },
    nwSegments: {
      icon: images.NotFoundSmall(),
      notFoundIcon: images.NotFoundSmall(),
      emptyStateHeader: t('emptyState.nwSegments.emptyContainerHeader'),
      emptyStateDescription: t(
        'emptyState.nwSegments.emptyContainerDescription'
      ),
      link: false,
      buttonText: t('emptyState.nwSegments.buttonText'),
      click: () => {},
    },
  };

  return emptyStateConfig[type];
};

export const getSelectedDataCount = (
  type: TableTypes,
  selectedItems: any[]
) => {
  if (selectedItems && selectedItems.length > 0) {
    const selectedType = selectedItems.filter(item => item.type === type);
    return selectedType.length;
  }
  return 0;
};
