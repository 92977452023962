import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableExpandHeader,
  TableExpandRow,
  TableExpandedRow,
  TableCell,
  TableContainer,
  TableToolbar,
  TableToolbarSearch,
  TableToolbarContent,
  TooltipDefinition,
  Checkbox,
} from 'carbon-components-react';

import {
  DataTableCell,
  DataTableHeader,
  DataTableRow,
} from '../../../../../models/dataTable';

import { RowTypeHeader } from '../config';

import './RolePermissionsResourceTypeTable.scss';

export interface TableProps {
  rows: RowTypeHeader[];
  headers: DataTableHeader[];
  isSortable: boolean;
  resourceTypePermissionsData: any;
  updateResourceTypePermissionsData: any;
}

const RolePermissionsResourceTypeTable = (props: TableProps) => {
  const [searchText, setSearchText] = useState('');
  const [filteredFormData, setFilteredFormData] = useState<any>({});
  const {
    resourceTypePermissionsData: formData,
    updateResourceTypePermissionsData,
  } = props;

  const { t } = useTranslation('createRole');

  const getChildData = (id: string) => {
    if (!searchText) {
      return formData[id] || {};
    } else {
      return filteredFormData[id] || {};
    }
  };

  const getFilteredRows = () => {
    if (!searchText) {
      return props.rows;
    } else {
      const filteredFormDataKeys: any = Object.keys(filteredFormData);
      return props.rows.filter((row: any) =>
        filteredFormDataKeys.includes(row.id)
      );
    }
  };

  const applySearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTextValue = e.target.value;
    setSearchText(searchTextValue);
    if (searchTextValue === '' || searchTextValue === null) {
      setFilteredFormData({});
    } else {
      let filteredFormData: any = {};
      const formDataKeys = Object.keys(formData);
      formDataKeys.forEach((formDataKey: string) => {
        const resourceTypeKeys: any = Object.keys(formData[formDataKey]);
        resourceTypeKeys.forEach((subKey: string) => {
          if (subKey.toLowerCase().includes(searchTextValue.toLowerCase())) {
            filteredFormData = {
              ...filteredFormData,
              [formDataKey]: {
                ...filteredFormData[formDataKey],
                [subKey]: formData[formDataKey][subKey],
              },
            };
          }
        });
      });
      setFilteredFormData(filteredFormData);
    }
  };

  const getRowToBeUpdated = (id: string, checkFilter?: boolean) => {
    if (!checkFilter) {
      return formData[id] || {};
    } else {
      return filteredFormData[id] || {};
    }
  };

  const createUpdatedData = (
    value: boolean,
    accessProperty: string,
    resourceTypeHeader: string,
    currentResourceType: string,
    dataToBeUpdated: any,
    checkFilter?: boolean
  ) => {
    const rowToBeUpdated: any = getRowToBeUpdated(
      resourceTypeHeader,
      checkFilter
    );
    const columnToBeUpdated: any = rowToBeUpdated[currentResourceType];
    const updatedData: any = {
      ...dataToBeUpdated,
      [resourceTypeHeader]: {
        ...rowToBeUpdated,
        [currentResourceType]: {
          ...columnToBeUpdated,
          accessProperties: {
            ...columnToBeUpdated?.accessProperties,
            [accessProperty]: {
              ...columnToBeUpdated?.accessProperties[accessProperty],
              value: value,
            },
          },
        },
      },
    };
    return updatedData;
  };

  const handleCheckbox = (
    value: boolean,
    accessProperty: string,
    resourceTypeHeader: string,
    currentResourceType: string
  ) => {
    const updatedResourceTypePermissionsData = createUpdatedData(
      value,
      accessProperty,
      resourceTypeHeader,
      currentResourceType,
      formData
    );
    updateResourceTypePermissionsData(updatedResourceTypePermissionsData);

    if (searchText) {
      const updatedFilteredData = createUpdatedData(
        value,
        accessProperty,
        resourceTypeHeader,
        currentResourceType,
        filteredFormData,
        true
      );
      setFilteredFormData(updatedFilteredData);
    }
  };

  const renderCheckBox = (
    row: DataTableRow,
    key: string,
    property: string,
    currentRowData: any
  ) => {
    return (
      <Checkbox
        id={`${row.id}-${key}-${property}-check-box`}
        labelText=''
        checked={currentRowData[key].accessProperties[property].value}
        onChange={(value: boolean) =>
          handleCheckbox(value, property, row.id, currentRowData[key].id)
        }
      />
    );
  };

  const getExpandedRow = (row: DataTableRow, headers: DataTableHeader[]) => {
    const currentRowData: any = getChildData(row.id);
    const childKeys = currentRowData ? Object.keys(currentRowData) : [];
    return (
      row.isExpanded && (
        <TableExpandedRow
          className='row-expanded-type-table'
          key={`${row.id}-expanded`}
          colSpan={headers.length + 1}
        >
          <Table>
            <TableBody>
              {childKeys.map((key: string) => {
                const accessProperty = Object.keys(
                  currentRowData[key].accessProperties
                );
                return (
                  <TableRow key={key}>
                    <TableCell className='row-resource-type'>
                      {' '}
                      <TooltipDefinition
                        tooltipText={currentRowData[key]?.tooltip}
                      >
                        {currentRowData[key]?.value}
                      </TooltipDefinition>
                    </TableCell>
                    {accessProperty.map((property: string) => {
                      if (
                        currentRowData[key].accessProperties[property] !== ''
                      ) {
                        return (
                          <TableCell
                            className='resource-type-check-box'
                            key={property}
                          >
                            {' '}
                            {currentRowData[key].accessProperties[property]
                              ?.tooltip ? (
                              <TooltipDefinition
                                tooltipText={
                                  currentRowData[key]?.accessProperties[
                                    property
                                  ]?.tooltip
                                }
                                align='center'
                              >
                                {renderCheckBox(
                                  row,
                                  key,
                                  property,
                                  currentRowData
                                )}
                              </TooltipDefinition>
                            ) : (
                              renderCheckBox(row, key, property, currentRowData)
                            )}
                          </TableCell>
                        );
                      } else {
                        return <TableCell></TableCell>;
                      }
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableExpandedRow>
      )
    );
  };

  return (
    <div className='create-role-permission-resource-type-table'>
      <DataTable
        rows={getFilteredRows()}
        headers={props.headers}
        isSortable={props.isSortable}
      >
        {({
          rows,
          headers,
          getHeaderProps,
          getToolbarProps,
          getRowProps,
          getTableProps,
          getTableContainerProps,
        }: any) => (
          <TableContainer {...getTableContainerProps()}>
            <TableToolbar {...getToolbarProps()}>
              <TableToolbarContent>
                <TableToolbarSearch
                  className='role-search'
                  expanded
                  onChange={e => applySearch(e)}
                  placeholder={t('rolePermissions.search')}
                />
              </TableToolbarContent>
            </TableToolbar>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  <TableExpandHeader />
                  {headers.map((header: DataTableHeader) => {
                    return (
                      <TableHeader
                        className='resource-type-header'
                        {...getHeaderProps({ header })}
                      >
                        {header.header}
                      </TableHeader>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.map((row: DataTableRow) => {
                  return (
                    <React.Fragment>
                      <TableExpandRow
                        className='role-details-type-header'
                        {...getRowProps({ row })}
                      >
                        {row?.cells?.map((cell: DataTableCell) => {
                          return (
                            <TableCell
                              className='role-permission-type-expand-cell'
                              key={cell.id}
                            >
                              {cell.value
                                ? `${cell.value} (${
                                    Object.keys(getChildData(row.id)).length
                                  })`
                                : ''}
                            </TableCell>
                          );
                        })}
                      </TableExpandRow>
                      {getExpandedRow(row, headers)}
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
    </div>
  );
};

export default RolePermissionsResourceTypeTable;
