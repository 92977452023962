import { MCNM_NETWORK_API_URL } from './config';
import { axiosInstance } from './api';

//Delete an existing partition under a deployment environment
export async function deletePartition(
  cloud_id,
  dep_env_id,
  partition_id,
  partition_type,
  env_type
) {
  try {
    const response = await axiosInstance({
      method: 'DELETE',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloud_id}/${env_type}/${dep_env_id}/${partition_type}/${partition_id}`,
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

//Register namespace that MCNM should oversee
export async function registerNamespace(cloud_id, cluster_id, data) {
  try {
    const response = await axiosInstance({
      method: 'POST',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloud_id}/cluster/${cluster_id}/namespace`,
      data,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

//Register SecurityGroup that MCNM should oversee
export async function registerSecurityGroup(cloud_id, vpc_id, data) {
  try {
    const response = await axiosInstance({
      method: 'POST',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloud_id}/vpc/${vpc_id}/securitygroup`,
      data,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
