import images from '../../images/images';

export const getThirdPartyCloudIcons = (cloudType: string) => {
  switch (cloudType) {
    case 'AWS':
      return images.awsCloudIcon();
    case 'IBM':
      return images.ibmCloudIcon();
    case 'AZURE':
      return images.microsoftCloudIcon();
    case 'GCP':
      return images.googleCloudIcon();
    default:
      return images.otherCloudIcon();
  }
};

export const getThirdPartyCloudIconsDropdown = (cloudType: string) => {
  switch (cloudType) {
    case 'AWS':
      return images.awsCloudDropDownIcon();
    case 'IBM':
      return images.ibmCloudDropDownIcon();
    case 'AZURE':
      return images.microsoftCloudDropDownIcon();
    case 'GCP':
      return images.googleCloudDropDownIcon();
    default:
      return images.otherCloudDropDownIcon();
  }
};
