import { HTTPError403 } from '@carbon/ibm-products';
import { useTranslation } from 'react-i18next';

export const Error403Page = () => {
  const { t }: { t: any } = useTranslation('error403Page');

  return (
    <HTTPError403
      errorCodeLabel={t('errorCodeLabel')}
      title={t('title')}
      description={t('description')}
    />
  );
};

export default Error403Page;
