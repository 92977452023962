import React, { useState, useContext, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import {
  createLocation,
  updateLocation,
} from '../../../controllers/locationApis';
import { useQueryClient } from '@tanstack/react-query';
import {
  SkeletonText,
  Row,
  Column,
  ButtonSkeleton,
  TextInputSkeleton,
} from 'carbon-components-react';

import useAnalytics from '../../../lib/useAnalytics';
import analyticsData from '../../../lib/analyticsEventData';
import { inValidateListCacheFn } from '../../../lib/invalidateQueriesFunctions';
import images from '../../../images/images';
import {
  VisibilityFlags,
  ActionTypes,
  LocationTypes,
  QueryKeys,
  FromModule,
} from '../../../lib/enums';
import {
  Cloud,
  DeploymentEnvironment,
  Location,
  LocationAddress,
} from '../../../models/master';

import { useCloudsData } from '../../../hooks/useClouds';
import { useLocationsUnderCloud } from '../../../hooks/useLocations';
import WideTearsheet from '../../../components/WideTearsheet/WideTearsheet';
import { NotificationContext } from '../../../components/Notifications/Context/NotificationProvider';
import InlineNotification from '../../../components/Notifications/Inline/Notification';

import RegisterLocationForm from '../../Locations/RegisterLocation/RegisterLocationForm';
export interface FormData {
  name: {
    value: string;
    error: boolean;
    errorMessage: string;
  };
  cloudSelect: {
    value: string;
    error: boolean;
    errorMessage: string;
  };
  cloudType: {
    value: { label: string; code: any } | null;
    error: boolean;
    errorMessage: string;
  };
  cloudRegion: {
    value: string;
    error: boolean;
    isVisible: boolean;
    errorMessage: string;
  };
  address: {
    value: null;
    error: boolean;
    errorMessage: string;
  };
  labels: {
    value: string[];
    error: boolean;
    errorMessage: string;
  };
  auto_discover: boolean;
  is_multi_zone: boolean;
  discovered_labels: {
    value: string[];
    error: boolean;
    errorMessage: string;
  };
  is_discovered: boolean;
  managed: boolean;
}

const defaultFormValue = {
  name: {
    value: '',
    error: false,
    errorMessage: '',
  },
  cloudSelect: {
    value: '',
    error: false,
    errorMessage: '',
  },
  cloudType: {
    value: null,
    error: false,
    errorMessage: '',
  },
  cloudRegion: {
    value: '',
    isVisible: false,
    error: false,
    errorMessage: '',
  },
  address: {
    value: null,
    error: false,
    errorMessage: '',
  },
  labels: {
    value: [],
    error: false,
    errorMessage: '',
  },
  // default values
  auto_discover: false,
  is_multi_zone: false,
  discovered_labels: {
    value: [],
    error: false,
    errorMessage: '',
  },
  is_discovered: false,
  managed: true,
};

const requiredFields = ['name', 'cloudSelect', 'cloudType'];

interface Props {
  open: boolean;
  onClose: () => void;
  refreshLocation: (location: Location) => void;
  cloudRegionMap: any;
  cloudid?: string | null;
  actionType?: string;
  locationDetails?: any;
  cloudDetails?: any;
  updateCallBack?: (data: Location) => void;
  locationsLists?:
    | {
        locationsData: Location[];
        cloudList: Cloud[];
      }
    | undefined;
  allLocationsDeploymentData?: DeploymentEnvironment[] | null;
  haveZone?: boolean;
  fromModule?: FromModule;
}

const RegisterLocation: React.FC<Props> = ({
  open,
  onClose,
  refreshLocation,
  cloudRegionMap,
  cloudid,
  actionType,
  locationDetails,
  cloudDetails,
  updateCallBack,
  locationsLists,
  allLocationsDeploymentData,
  haveZone = false,
  fromModule = FromModule.LOCATION,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation('registerLocation');
  const { trackButtonClicked } = useAnalytics();
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const notification = useContext(NotificationContext);
  const [locationsList, setLocationsList] = useState<Location[] | null>(null);
  const [parentLocationId, setParentLocationId] = useState<string>('');
  const locationId =
    searchParams.get('locationId') || locationDetails?.resource_id || '';
  const [filteredRegion, setFilteredRegion] = useState<string[]>([]);

  const [cloudList, setCloudList] = useState<Cloud[] | null>(null);
  const [cloudId, setCloudId] = useState<string>('');
  const [cloudType, setCloudType] = useState<string>('');
  const [parentCloudLocationId, setParentCloudLocationId] =
    useState<string>('');

  const [formData, setFormData] = useState<FormData>(defaultFormValue);
  const [selectedAddress, setSelectedAddress] =
    useState<LocationAddress | null>(null);
  const [isAutodiscoverLocEdit, setIsAutodiscoverLocEdit] =
    useState<boolean>(false);

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [showFailNotification, toggleFailNotification] =
    useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [subTitleErrorMsg, setSubTitleErrorMsg] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [errorType, setErrorType] = useState<string>('default');

  let defaultPermissionMap = {
    locations: true,
    clouds: true,
  };

  const getFilteredRegion = () => {
    let filteredRegions = [];
    const selectedCloud = cloudId ? cloudId : cloudDetails?.resource_id;
    for (const [key, value] of cloudRegionMap) {
      if (
        Array.isArray(value) &&
        value[2] === selectedCloud &&
        value[0] !== locationDetails?.resource_id
      ) {
        filteredRegions.push(key);
      } else if (
        parentLocationId &&
        locationDetails?.type &&
        value[0] === locationDetails?.parent_location_id
      )
        filteredRegions.push(key);
    }
    setFilteredRegion(filteredRegions);
  };

  const updateFormData = (cloudsList: any) => {
    const { name, cloudSelect, cloudType, cloudRegion, labels } = formData;
    const fData = {
      ...formData,
      name: {
        ...name,
        value: locationDetails?.name,
      },
      cloudSelect: {
        ...cloudSelect,
        value: cloudsList
          ? cloudsList?.find(
              (cloud: { resource_id: any }) =>
                cloud?.resource_id === locationDetails?.cloud_id
            )?.name ?? ''
          : '',
      },
      cloudType: {
        ...cloudType,
        value: {
          label: t(locationDetails?.type ?? ''),
          code: locationDetails?.type,
        },
      },
      labels: {
        ...labels,
        value: JSON.parse(JSON.stringify(locationDetails?.labels) ?? null),
      },
      is_discovered: locationDetails?.is_discovered,
      managed: !locationDetails?.unmanaged,
    };

    if (locationDetails?.type === LocationTypes.ZONE) {
      let regionName = '';
      const regionsIds = [...cloudRegionMap.values()];
      const regionNames = [...cloudRegionMap.keys()];

      setParentLocationId(locationDetails?.parent_location_id);
      if (
        Array.isArray(regionsIds) &&
        Array.isArray(regionNames) &&
        locationDetails?.parent_location_id !== ''
      ) {
        const parentIdIndex = regionsIds?.findIndex(
          ([locationId, cloudLocationId]: any) =>
            locationId === locationDetails?.parent_location_id
        );
        if (parentIdIndex !== -1 && regionNames.length >= parentIdIndex)
          regionName = regionNames[parentIdIndex];
        regionName &&
          setParentCloudLocationId(cloudRegionMap.get(regionName)[1]);
      }

      setFormData({
        ...fData,
        cloudRegion: {
          ...cloudRegion,
          isVisible: true,
          value: regionName,
        },
      });
    } else {
      setFormData({
        ...fData,
        cloudRegion: {
          value: '',
          isVisible: false,
          error: false,
          errorMessage: '',
        },
      });
    }

    const locationAddress = {
      street_address: locationDetails?.street,
      city: locationDetails?.city,
      country: locationDetails?.country,
      state: locationDetails?.state
        ? locationDetails?.state
        : locationDetails?.region,
      coordinates: locationDetails?.geo_coordinates,
    };

    setSelectedAddress(locationAddress);
  };

  //Queries cloud details data
  const {
    data: cloudListData,
    isLoading: cloudListDataLoading,
    error: cloudDetailsError,
    isError: isCloudDetailsError,
  } = useCloudsData({ enabled: actionType === ActionTypes.UPDATE });

  if (isCloudDetailsError) {
    const error = cloudDetailsError as AxiosError;
    defaultPermissionMap.clouds =
      error?.response?.status === 403 ? false : true;
  }

  useMemo(() => {
    if (cloudListData) {
      Array.isArray(cloudListData) && setCloudList(cloudListData);
      if (actionType === ActionTypes.UPDATE) {
        if (locationDetails?.is_discovered) {
          setIsAutodiscoverLocEdit(true);
        } else {
          setIsAutodiscoverLocEdit(false);
        }
        updateFormData(cloudListData);
      }
    }
  }, [locationDetails, cloudListData, actionType]);

  const formatLocations = () => {
    const locationLists: Location[] = locationsLists?.locationsData || [];
    setLocationsList(locationLists as Location[]);
    const cloudLists: Cloud[] = locationsLists?.cloudList || [];
    if (locationsLists?.cloudList) {
      cloudLists && setCloudList(cloudLists);
      if (actionType === ActionTypes.UPDATE) {
        if (locationDetails?.auto_discover) {
          setIsAutodiscoverLocEdit(true);
        } else {
          setIsAutodiscoverLocEdit(false);
        }
        updateFormData(locationsLists?.cloudList);
      }
    }
  };

  useEffect(() => {
    if (
      open &&
      fromModule === FromModule.CLOUD &&
      actionType === ActionTypes.ADD &&
      cloudDetails
    ) {
      setCloudId(cloudDetails.resource_id);
      setFormData(prevFormData => ({
        ...prevFormData,
        cloudSelect: {
          ...prevFormData.cloudSelect,
          value: cloudDetails.name ?? '',
        },
      }));
    }
  }, []);

  useEffect(() => {
    if (locationsLists) {
      formatLocations();
    }
  }, [locationsLists]);

  useEffect(() => {
    getFilteredRegion();
  }, [formData?.cloudType]);

  // Queries locations
  const {
    data: locationsListsUnderCloud,
    isLoading: loadingLocationsListsUnderCloud,
    error: locationListsUnderCloudError,
    isError: isLocationListsUnderCloudError,
  } = useLocationsUnderCloud(
    cloudId ? cloudId : cloudDetails?.resource_id,
    VisibilityFlags.ALL,
    {
      enabled: actionType === ActionTypes.UPDATE,
    }
  );

  if (isLocationListsUnderCloudError) {
    const error = locationListsUnderCloudError as AxiosError;
    defaultPermissionMap.locations =
      error?.response?.status === 403 ? false : true;
  }

  const formatLocationsUnderCloud = () => {
    const locationsListsUnderClouds: Location[] =
      locationsListsUnderCloud || [];
    setLocationsList(locationsListsUnderClouds as Location[]);
  };

  useMemo(() => formatLocationsUnderCloud(), [locationsListsUnderCloud]);

  const handleClose = () => {
    onClose();
    toggleFailNotification(false);
    setErrorType('default');
  };

  const autoDiscoverdTrueLocationEditValidation = () => {
    const locationLabel: string[] = locationDetails?.labels ?? [];
    const updatedLabel: string[] = formData?.labels?.value ?? [];
    if (
      locationDetails?.unmanaged === !formData?.managed &&
      JSON.stringify(locationLabel) === JSON.stringify(updatedLabel)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const autoDiscoverdFalseLocationEditValidation = () => {
    if (
      locationDetails?.name === formData?.name?.value &&
      formData?.cloudType?.value &&
      locationDetails?.type === formData?.cloudType?.value?.code &&
      locationDetails?.unmanaged === !formData?.managed &&
      typeZoneUpdate() &&
      autoDiscoverdTrueLocationEditValidation() &&
      compareSelectedAddress()
    ) {
      return false;
    } else {
      return true;
    }
  };

  const compareSelectedAddress = () => {
    if (
      selectedAddress &&
      selectedAddress?.street_address === locationDetails?.street &&
      selectedAddress?.city === locationDetails?.city &&
      selectedAddress?.country === locationDetails?.country &&
      selectedAddress?.coordinates === locationDetails?.geo_coordinates
    ) {
      return true;
    } else if (selectedAddress == null) {
      return true;
    } else {
      return false;
    }
  };

  const typeZoneUpdate = () => {
    if (locationDetails?.type === LocationTypes.ZONE) {
      const region = cloudRegionMap.get(formData?.cloudRegion?.value);
      const regionId = Array.isArray(region) ? region[0] : null;
      if (!formData?.cloudRegion?.value) {
        return true;
      } else if (locationDetails?.parent_location_id === regionId) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const validateForm = () => {
    if (selectedAddress) {
      let requiredFieldsModified = [...requiredFields];
      if (formData.cloudType.value?.code === LocationTypes.ZONE)
        requiredFieldsModified = [...requiredFieldsModified, 'cloudRegion'];
      for (const field of requiredFieldsModified) {
        const value = (formData as any)[field].value;
        const trimmedValue = typeof value === 'string' ? value.trim() : value;
        if (checkFieldValidation(field, trimmedValue)) {
          return false;
        }
      }
      if (actionType === ActionTypes.UPDATE) {
        if (isAutodiscoverLocEdit) {
          return !autoDiscoverdTrueLocationEditValidation();
        } else return autoDiscoverdFalseLocationEditValidation();
      } else {
        return true;
      }
    }
    return false;
  };

  const isFormValid = () => {
    const isValid = validateForm();

    return isValid;
  };

  const checkFieldValidation = (
    name: string,
    value: any,
    selectedCloudID?: string
  ) => {
    let errorMessage = '';
    if (name === 'name') {
      const locationsListUnderSelectedCloud = locationsList?.filter(
        location =>
          location?.cloud_id ===
          (selectedCloudID || cloudId || locationDetails?.cloud_id)
      );
      const valueEmpty = value === '';

      const notUnique =
        locationDetails?.name?.trim() === value?.trim()
          ? false
          : Array.isArray(locationsListUnderSelectedCloud) &&
            locationsListUnderSelectedCloud?.filter(
              location => location?.name?.trim() === value?.trim()
            )?.length > 0
          ? true
          : false;
      const error = valueEmpty || notUnique;
      const message = valueEmpty
        ? t('validation.name.required')
        : t('validation.name.notUnique');
      errorMessage = error ? message : '';
    }
    if (name === 'cloudSelect') {
      errorMessage = !value ? t('validation.cloudSelect.required') : '';
    }
    if (name === 'cloudType') {
      errorMessage = !value ? t('validation.cloudType.required') : '';
    }
    if (name === 'cloudRegion') {
      errorMessage = !value ? t('validation.cloudRegion.required') : '';
    }
    return errorMessage;
  };

  const handleCloudSelect = (cloud: any) => {
    setCloudType(cloud.type);
    setCloudId(cloudid ? cloudid : cloud?.resource_id);
    handleOnChange('cloudSelect', cloud);
    // If we change the cloud we need to reset the selected region
    setFormData((formData: FormData) => ({
      ...formData,
      cloudRegion: {
        ...formData?.cloudRegion,
        value: '',
        isVisible: false,
      },
      cloudType: {
        ...formData?.cloudType,
        value: null,
      },
    }));

    setParentLocationId('');
    setParentCloudLocationId('');
    const nameErrorMessage = checkFieldValidation(
      'name',
      formData?.name.value,
      cloud?.resource_id
    );
    setFormData((prevFormData: FormData) => ({
      ...prevFormData,
      name: {
        value: formData?.name.value,
        error: !!nameErrorMessage,
        errorMessage: nameErrorMessage,
      },
    }));
  };

  const handleCloudTypeSelect = (typeVal: { label: string; code: string }) => {
    const { cloudRegion } = formData;

    if (typeVal && typeVal?.code === LocationTypes.ZONE) {
      if (locationDetails && locationDetails?.type === LocationTypes.ZONE) {
        let filteredRegions = '';
        for (const [key, value] of cloudRegionMap) {
          if (
            Array.isArray(value) &&
            value[0] === locationDetails?.parent_location_id
          ) {
            filteredRegions = key;
          }
        }
        setFormData((formData: FormData) => ({
          ...formData,
          cloudRegion: {
            ...cloudRegion,
            value: filteredRegions,
            isVisible: true,
          },
        }));
      } else
        setFormData((formData: FormData) => ({
          ...formData,
          cloudRegion: {
            ...cloudRegion,
            isVisible: true,
          },
        }));
    } else {
      setFormData((formData: FormData) => ({
        ...formData,
        cloudRegion: {
          value: '',
          error: false,
          errorMessage: '',
          isVisible: false,
        },
      }));

      setParentLocationId('');
      setParentCloudLocationId('');
    }

    handleOnChange('cloudType', typeVal);
  };

  const handleCloudRegionSelect = (cloudRegion: string) => {
    setParentLocationId(cloudRegionMap?.get(cloudRegion)[0]);
    setParentCloudLocationId(cloudRegionMap?.get(cloudRegion)[1]);
    handleOnChange('cloudRegion', cloudRegion, true);
  };

  const handleOnChange = (name: string, value: any, isVisible?: any) => {
    const errorMessage = checkFieldValidation(name, value);
    setFormData((prevFormData: FormData) => ({
      ...prevFormData,
      [name]: {
        value,
        error: !!errorMessage,
        errorMessage,
        isVisible,
      },
    }));
  };

  const handleUpdateSubmit = async () => {
    try {
      setSubmitLoading(true);
      let data;
      if (isAutodiscoverLocEdit) {
        data = {
          ...locationDetails,
          labels: formData?.labels?.value,
          unmanaged: !formData?.managed,
        };
      } else {
        data = {
          ...locationDetails,
          administrative_region: '',
          name: formData?.name.value,
          auto_discover: formData?.auto_discover,
          geo_coordinates: selectedAddress?.coordinates,
          discovered_labels: formData?.discovered_labels?.value,
          is_discovered: formData?.is_discovered,
          is_multi_zone: formData?.is_multi_zone,
          parent_location_id: parentLocationId,
          city: selectedAddress?.city,
          country: selectedAddress?.country,
          street: selectedAddress?.street_address,
          cloud_id: cloudId,
          type: formData?.cloudType?.value?.code,
          region: selectedAddress?.state || formData?.cloudRegion?.value,
          labels: formData?.labels?.value,
          unmanaged: !formData?.managed,
        };
      }
      delete data.resource_id;
      delete data?.cloud_location_code;

      const updatedData: Location = await updateLocation(
        cloudId ? cloudId : cloudDetails?.resource_id,
        locationId,
        data
      );

      if (updateCallBack) {
        updateCallBack(updatedData);
      }
      handleUpdateLocationNotification();
    } catch (error: any) {
      const errorMessage: string =
        error.response !== undefined
          ? error.response['customErrorMessage']
          : '';
      console.error(error);
      const err = error as AxiosError;
      errorMessage.length > 0 && setSubTitleErrorMsg(errorMessage);
      toggleFailNotification(true);
      if (err.response?.status === 403) {
        setErrorType('auth');
        setErrorMessage(t('failureNotification.authErrorTitle'));
      } else {
        setErrorType('default');
        setErrorMessage(t('failureNotification.editTitle'));
      }
      throw error;
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleSubmit = async () => {
    setSubmitLoading(true);
    if (actionType === ActionTypes.UPDATE) {
      handleUpdateSubmit();
      return;
    }
    trackButtonClicked(
      analyticsData['Locations'].events.registerLocation.props,
      analyticsData['Locations'].events.registerLocation.event
    );

    try {
      setSubmitLoading(true);
      const locationData = {
        administrative_region: '',
        name: formData?.name.value,
        auto_discover: formData?.auto_discover,
        geo_coordinates: selectedAddress?.coordinates,
        discovered_labels: formData?.discovered_labels?.value,
        is_discovered: formData?.is_discovered,
        is_multi_zone: formData?.is_multi_zone,
        parent_location_id: parentLocationId,
        city: selectedAddress?.city,
        country: selectedAddress?.country,
        street: selectedAddress?.street_address,
        cloud_id: cloudId,
        type: formData?.cloudType?.value?.code,
        region: selectedAddress?.state || formData?.cloudRegion?.value,
        labels: formData?.labels?.value,
        unmanaged: !formData?.managed,
      };

      const location = await createLocation(cloudId, locationData);
      setSubmitLoading(false);

      handleSuccessNotification(location);
    } catch (error: any) {
      const err = error as AxiosError;
      const errorMessage: string =
        error.response !== undefined
          ? error.response['customErrorMessage']
          : '';
      errorMessage.length > 0 && setSubTitleErrorMsg(errorMessage);
      toggleFailNotification(true);
      setSubmitLoading(false);
      if (err.response?.status === 403) {
        setErrorMessage(t('failureNotification.authErrorTitle'));
        setErrorType('auth');
      } else {
        setErrorMessage(t('failureNotification.title'));
        setErrorType('default');
      }
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleUpdateLocationNotification = () => {
    inValidateListCacheFn(QueryKeys.LOCATIONS);
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.LOCATIONS],
    });
    handleClose();
    notification.onTrigger('TOAST', {
      title: t('success'),
      subtitle: t('deleteLocation.success'),
    });
  };

  const handleSuccessNotification = (response: Location) => {
    // Trigger success toastbar
    if (fromModule === FromModule.CLOUD) {
      notification.onTrigger('TOAST', {
        title: t('successNotification.title'),
        subtitle: t('successNotification.subtitle', {
          location: formData.name.value,
        }),
      });
    } else {
      notification.onTrigger('ACTION', {
        title: t('successNotification.title'),
        subtitle: t('successNotification.subtitle', {
          location: formData.name.value,
        }),
        action: t('successNotification.button'),
        onActionClick: () => {
          navigate(
            `/locationDetails?cloudId=${response?.cloud_id}&locationId=${response?.resource_id}`
          );
        },
      });
    }

    inValidateListCacheFn(QueryKeys.LOCATIONS);
    refreshLocation(response);
    handleClose();
  };

  const handleErrorBarClose = () => {
    toggleFailNotification(false);
    setErrorType('default');
  };

  const handleCheckbox = (name: string, value: any) => {
    setFormData((prevFormData: FormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const disableManagedCheckbox = () => {
    // If location is already unmanaged then we don't need to disable it
    if (locationDetails?.unmanaged) {
      return false;
    }
    const unmanagedLocationCount = Array.isArray(allLocationsDeploymentData)
      ? allLocationsDeploymentData.filter(
          deploymentEnv => deploymentEnv?.unmanaged === false
        )?.length
      : 0;

    return unmanagedLocationCount > 0;
  };

  const getTitleText = () => {
    if (actionType === ActionTypes.UPDATE) {
      return (
        <div className='auto-discover-title'>
          <div>
            {t('editTitle', {
              locationName: locationDetails?.name
                ? locationDetails?.name
                : formData.name.value,
            })}
          </div>
          {locationDetails?.is_discovered && (
            <div className='auto-discover-icon'>
              {images.AutoDiscoverdLockIcon()}
            </div>
          )}
        </div>
      );
    } else {
      return t('title');
    }
  };

  const dataLoading =
    actionType === ActionTypes.UPDATE &&
    cloudListDataLoading &&
    loadingLocationsListsUnderCloud;

  return (
    <WideTearsheet
      title={getTitleText()}
      description={t('description')}
      open={open}
      className={
        actionType === ActionTypes.ADD
          ? 'register-location-modal'
          : 'edit-location-modal'
      }
      actions={[
        {
          kind: 'primary',
          label:
            actionType === ActionTypes.UPDATE
              ? t('saveBtnText')
              : t('submitButtonText'),
          onClick: () => handleSubmit(),
          disabled: !isFormValid(),
          loading: submitLoading,
        },
        {
          kind: 'secondary',
          label: t('cancelButtonText'),
          onClick: () => onClose(),
        },
      ]}
    >
      {showFailNotification && (
        <InlineNotification
          onClose={() => handleErrorBarClose() as any}
          kind={'error'}
          title={errorMessage + '.'}
          subtitle={
            errorType === 'auth'
              ? (t('failureNotification.authErrorSubtitle') as string)
              : subTitleErrorMsg.length === 0
              ? (t('failureNotification.subtitle') as string)
              : subTitleErrorMsg
          }
        />
      )}

      {dataLoading ? (
        <div className='register-location-body'>
          <Row className='row sub-header-section'>
            <Column md={5} className='sub-header'>
              <SkeletonText className='header-skeleton' />
            </Column>
            <Column md={1}>
              <ButtonSkeleton />
            </Column>
          </Row>
          <div className='register-location-form'>
            <Row>
              <Column md={3} className='col-location-name'>
                <TextInputSkeleton />
              </Column>

              <Column md={3} className='col-location-cloud'>
                <TextInputSkeleton />
              </Column>
            </Row>
            <Row>
              {locationDetails?.is_discovered ? (
                <Column md={3} className='col-location-noEdit-type'>
                  <TextInputSkeleton />
                </Column>
              ) : (
                <Column md={3} className='col-location-type'>
                  <TextInputSkeleton />
                </Column>
              )}
              <Column md={3} className='col-location-region'>
                <TextInputSkeleton />
              </Column>
            </Row>
            <Row>
              <Column md={4}>
                <ButtonSkeleton />
              </Column>
            </Row>
          </div>
        </div>
      ) : (
        <RegisterLocationForm
          formData={formData}
          onChange={handleOnChange}
          showCancelModal={showCancelModal}
          setShowCancelModal={setShowCancelModal}
          onClose={onClose}
          cloudList={cloudList}
          cloudRegionList={filteredRegion}
          onCloudSelect={handleCloudSelect}
          onCloudTypeSelect={handleCloudTypeSelect}
          onCloudRegionSelect={handleCloudRegionSelect}
          selectedAddress={selectedAddress}
          setSelectedAddress={setSelectedAddress}
          actionType={!isAutodiscoverLocEdit}
          isCheckboxEnabled={!disableManagedCheckbox()}
          isRegister={actionType === ActionTypes.ADD}
          handleCheckbox={handleCheckbox}
          locationDetails={locationDetails}
          haveZone={haveZone}
          fromModule={fromModule}
        />
      )}
    </WideTearsheet>
  );
};
export default RegisterLocation;
