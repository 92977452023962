import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import MultiStepTearsheet from '../../../components/MultiStepTearSheet/MultiStepTearSheet';
import AddIdentityDetails from './AddIdentityDetails/AddIdentityDetails';
import AssignRoles from './AssignRoles/AssignRoles';
import { getRoles } from '../../../controllers/roleApis';
import { AxiosError } from 'axios';
import {
  addIdentity,
  addIdentityRoles,
} from '../../../controllers/identityApis';
import InlineNotification from '../../../components/Notifications/Inline/Notification';
import { NotificationContext } from '../../../components/Notifications/Context/NotificationProvider';
import { emailRegexPattern } from '../../../lib/regex';
import { Role } from '../../../models/master';
import useAnalytics from '../../../lib/useAnalytics';
import './CreateIdentity.scss';
import analyticsData from '../../../lib/analyticsEventData';

interface Props {
  open: boolean;
  closeTearsheet: () => void;
  refreshData: () => void;
  identitiesList: string[];
  mode: string;
}
export interface FormData {
  name: {
    value: string;
    error: boolean;
    errorMessage: string;
  };
  description: {
    value: string;
    error: boolean;
    errorMessage: string;
  };
  resourceId: {
    value: string;
    error: boolean;
    errorMessage: string;
  };
  labels: {
    value: string[];
    error: boolean;
    errorMessage: string;
  };
}

const defaultPermissionMap = {
  roles: true,
};

const defaultForm = {
  step1: {
    name: {
      value: '',
      error: false,
      errorMessage: '',
    },
    description: {
      value: '',
      error: false,
      errorMessage: '',
    },
    resourceId: {
      value: '',
      error: false,
      errorMessage: '',
    },
    labels: {
      value: [],
      error: false,
      errorMessage: '',
    },
  },
  step2: {
    roles: [],
  },
};

const defaultDetails = { name: null, resourceId: null };

const CreateIdentity: React.FC<Props> = ({
  open,
  closeTearsheet,
  refreshData,
  identitiesList,
  mode,
}) => {
  const { t } = useTranslation('identity');
  const [formData, setFormData] = useState<{
    step1: FormData;
    step2: { roles: Role[] };
  }>(defaultForm);
  const [rolesList, setRolesList] = useState<Role[] | null>(null);
  const [permissionMap, setPermissionMap] = useState(defaultPermissionMap);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState<{
    name: string | null;
    resourceId: string | null;
  }>(defaultDetails);
  const [showFailNotification, toggleFailNotification] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [subTitleErrorMsg, setSubTitleErrorMsg] = useState('');
  const notification = useContext(NotificationContext);
  const { trackButtonClicked } = useAnalytics();

  useEffect(() => {
    if (open) fetchRoles();
  }, [open]);

  const fetchRoles = async () => {
    try {
      const roles = await getRoles();
      setRolesList(roles?.roles ?? []);
    } catch (error) {
      const err = error as AxiosError;
      if (
        err?.response?.status === 403 &&
        err?.response?.statusText === 'Forbidden'
      ) {
        setPermissionMap(permissionMap => ({
          ...permissionMap,
          roles: false,
        }));
      }
    }
  };

  const handleClose = () => {
    toggleFailNotification(false);
    setFormData(defaultForm);
    setDetails(defaultDetails);
    refreshData();
    closeTearsheet();
  };

  const handleSubmit = async () => {
    trackButtonClicked(
      analyticsData['Admin Identities'].events.createIdentityBtn.props,
      analyticsData['Admin Identities'].events.createIdentityBtn.event
    );
    const data = {
      description: formData.step1.description.value,
      labels: formData.step1.labels.value,
      name: formData.step1.name.value?.trim(),
      resource_id: formData.step1.resourceId.value?.trim(),
    };
    const rolesData = formData.step2.roles?.map(
      (role: { resource_id: string }) => role?.resource_id
    );
    toggleFailNotification(false);
    setLoading(true);
    try {
      const identityData = await addIdentity(data);
      try {
        await addIdentityRoles(identityData?.resource_id, {
          role_resource_ids: rolesData,
        });
        notification.onTrigger('TOAST', {
          title: t('createIdentityPage.successNotification.title', {
            name: identityData.name,
          }),
          subtitle: t('createIdentityPage.successNotification.subtitle'),
        });
      } catch (error: any) {
        handleClose();
        notification.onTrigger('TOAST', {
          kind: 'error',
          title: t('createIdentityPage.roleCreationFailure.title'),
          subtitle: t('createIdentityPage.roleCreationFailure.subtitle'),
        });
      }
      // close modal and fetch new data in parent
      // TODO: navigate to card view when functionality is added
      handleClose();
    } catch (error: any) {
      const err = error as AxiosError;
      const errorMessage: string = error.response['customErrorMessage'];
      if (err.response?.status === 403) {
        toggleFailNotification(true);
        setAuthError(true);

        // To prevent closing of modal we need to return promise rejection
        return Promise.reject(() => console.error(err));
      }
      errorMessage.length > 0 && setSubTitleErrorMsg(errorMessage);

      console.log(error);
      toggleFailNotification(true);
      return Promise.reject(() => console.log(error));
    } finally {
      setLoading(false);
    }
  };

  const checkFieldValid = (name: string, value: any) => {
    let errorMessage = '';

    switch (name) {
      case 'name':
        if (value === '' || value === null || value === undefined)
          errorMessage = t('createIdentityPage.addDetails.nameError');
        break;
      case 'resourceId':
        const valueEmpty =
          value === '' || value === null || value === undefined;
        const notUnique = identitiesList?.find(
          (resourceId: string) => resourceId.trim() === value.trim()
        )
          ? true
          : false;
        const regexPattern = emailRegexPattern();
        const isCorrectPattern = regexPattern.test(value);
        const error = valueEmpty || notUnique || !isCorrectPattern;
        const message = valueEmpty
          ? t('createIdentityPage.addDetails.resourceIdError')
          : isCorrectPattern
          ? t('createIdentityPage.addDetails.resourceIdDuplicateError')
          : t('createIdentityPage.addDetails.wrongEmailFormat');
        errorMessage = error ? message : '';
        break;
    }

    return errorMessage;
  };

  const handleOnChange = (e: { target: { name: string; value: any } }) => {
    const name = e.target.name;
    const value = e?.target?.value;
    setFormData(prevState => ({
      ...prevState,
      [`step1`]: {
        ...prevState[`step1`],
        [name]: {
          value,
        },
      },
    }));
  };

  const handleOnBlur = (e: { target: { name: string; value: any } }) => {
    const name = e.target.name;
    const value = e?.target?.value;
    setDetails(prevState => ({
      ...prevState,
      [name]: value,
    }));
    let errorMessage = checkFieldValid(
      name,
      typeof value === 'string' ? value?.trim() : value
    );
    if (errorMessage)
      setFormData(prevState => ({
        ...prevState,
        [`step1`]: {
          ...prevState[`step1`],
          [name]: {
            value,
            error: !!errorMessage,
            errorMessage,
          },
        },
      }));
  };

  const checkFormValid = () => {
    let isFormValid = true;
    let nameError = checkFieldValid('name', formData.step1.name.value?.trim());
    let resourceIdErr = checkFieldValid(
      'resourceId',
      formData.step1.resourceId.value?.trim()
    );
    if (nameError.length > 0 || resourceIdErr.length > 0) {
      isFormValid = false;
    }
    if (nameError.length > 0) {
      isFormValid = false;
    }

    return isFormValid;
  };

  const handleErrorBarClose = () => {
    toggleFailNotification(false);
    setAuthError(false);
    setSubTitleErrorMsg('');
  };

  return (
    <div className='create-identity-container'>
      <MultiStepTearsheet
        className='register-identity-tearsheet'
        submitButtonText={t('createIdentityPage.submitButtonText')}
        cancelButtonText={t('createIdentityPage.cancelButtonText')}
        backButtonText={t('createIdentityPage.backButtonText')}
        nextButtonText={t('createIdentityPage.nextButtonText')}
        description={t('createIdentityPage.description')}
        title={t('createIdentityPage.title')}
        open={open}
        onClose={handleClose}
        onRequestSubmit={handleSubmit}
      >
        <AddIdentityDetails
          formData={formData.step1}
          onChange={handleOnChange}
          checkFormValid={checkFormValid()}
          onBlur={handleOnBlur}
          addedIdentityDetails={details}
        >
          {showFailNotification && (
            <InlineNotification
              onClose={() => handleErrorBarClose() as any}
              kind={'error'}
              title={
                authError
                  ? (t(
                      'createIdentityPage.failureNotification.authTitle'
                    ) as string)
                  : mode === 'ADD'
                  ? (t(
                      'createIdentityPage.failureNotification.title'
                    ) as string)
                  : (t('failureEditNotification.title') as string)
              }
              subtitle={
                authError
                  ? (t(
                      'createIdentityPage.failureNotification.authSubtitle'
                    ) as string)
                  : subTitleErrorMsg.length > 0
                  ? subTitleErrorMsg
                  : mode === 'ADD'
                  ? (t(
                      'createIdentityPage.failureNotification.subtitle'
                    ) as string)
                  : (t('failureEditNotification.subtitle') as string)
              }
            />
          )}
        </AddIdentityDetails>
        <AssignRoles
          addRole={(selectedRole: any) =>
            setFormData((prevState: any) => ({
              ...prevState,
              step2: { roles: [...prevState.step2.roles, selectedRole] },
            }))
          }
          rolesList={rolesList}
          rolesAdded={formData.step2.roles}
          removeRole={(index: number, roleToBeRemoved: any) => {
            formData.step2.roles.splice(index, 1);

            setFormData(prevState => ({
              ...prevState,
              step2: {
                roles: [...prevState.step2.roles],
              },
            }));
          }}
          loading={loading}
        />
      </MultiStepTearsheet>
    </div>
  );
};

export default CreateIdentity;
