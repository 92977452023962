import { Policy, PolicyData } from '../models/master';
import { axiosInstance } from './api';
import { getApplication, getApplicationService } from './application';
import { MCNM_APP_API_URL } from './config';

const getApplicationandServicesForPolicies = async (
  fetchServices = true,
  fetchApplications = true,
  policy: Policy
) => {
  const fromAppId = policy?.from?.application?.application_id;
  const toAppId = policy?.to?.service?.application_id;
  const svcId = policy?.to?.service?.service_id;

  let svcName = '';
  let appName = '';
  let servicePermission = true;
  let appPermission = true;

  if (fetchServices) {
    try {
      const response = await getApplicationService(toAppId, svcId);
      svcName = response.name;
    } catch (error: any) {
      if (
        error?.response?.status === 403 &&
        error?.response?.statusText === 'Forbidden'
      ) {
        servicePermission = false;
      }
    }
  }

  if (fetchApplications) {
    try {
      const response = await getApplication(fromAppId);
      appName = response.name;
    } catch (error: any) {
      if (
        error?.response?.status === 403 &&
        error?.response?.statusText === 'Forbidden'
      ) {
        appPermission = false;
      }
    }
  }

  return {
    ...policy,
    from_app_name: appName,
    to_svc_name: svcName,
    servicePermission,
    appPermission,
  };
};

export async function getPolicies(
  fetchServices = true,
  fetchApplications = true,
  count = 0
) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_APP_API_URL}/policy`,
    });
    // need to get the names of the application and service that each policy is tied to:
    const fullPolicyData: PolicyData[] = await Promise.all(
      response.data.policys.map(async (policy: Policy, index: number) => {
        // fetch application and service only for given count
        if (count && index <= count - 1) {
          return await getApplicationandServicesForPolicies(
            fetchServices,
            fetchApplications,
            policy
          );
        } else if (!count) {
          return await getApplicationandServicesForPolicies(
            fetchServices,
            fetchApplications,
            policy
          );
        } else {
          return {
            ...policy,
          };
        }
      })
    );

    return fullPolicyData;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
