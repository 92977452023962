import React, { useState, useEffect } from 'react';
import './AddApplicationDeploymentDetails.scss';
import { useTranslation } from 'react-i18next';
import { OnChangeData, RadioButton } from 'carbon-components-react';
import { CheckmarkFilled16 } from '@carbon/icons-react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import GenericTableWithFilters from '../../../../components/GenericTableWithFilters/GenericTableWithFilters';
import sortData from '../../../../lib/tableSort';
import IconWithToolTip from '../../../../components/IconWithToolTip/IconWithToolTip';
import images from '../../../../images/images.js';
import dateUtils from '../../../../lib/dates';
import {
  ResourceGroup,
  AppliedFilter,
  DeploymentEnvironment,
  DeploymentEnvironmentSubtype,
} from '../../../../models/master';

import PartitionsTable from './PartitionsTable';
import GenericTruncateString from '../../../../components/GenericTruncateString/GenericTruncateString';
import { Filter } from '../../../../components/FindAndFilterBar/FindAndFilterBar';

interface Item {
  depEnvid: string;
  type: string;
  resourceGroupId: string;
  autoDiscover?: boolean;
  depEnvName: string;
}

interface Props {
  deploymentEnvData: DeploymentEnvironment[] | null;
  deploymentEnvSubtypesData: DeploymentEnvironmentSubtype[] | null;
  resourceGroupsData: ResourceGroup[] | null;
  refreshData: () => void;
  getAppDeploymentData: any;
  selectedDepEnv: Item;
  handleSelectDepEnv: (item: Item) => void;
  enableButton: (status: boolean) => void;
  appNetworkSegmentId: string;
}

const AddApplicationDeploymentDetails: React.FC<Props> = ({
  deploymentEnvData,
  deploymentEnvSubtypesData,
  resourceGroupsData,
  refreshData,
  getAppDeploymentData,
  selectedDepEnv,
  handleSelectDepEnv,
  enableButton,
  appNetworkSegmentId,
}) => {
  const { t } = useTranslation('addApplicationDeploymentDetails');
  const [filterApplied, setFilterApplied] = useState<AppliedFilter[] | []>([]);
  const [filteredData, setFilteredData] = useState<
    DeploymentEnvironment[] | []
  >([]);
  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | 'NONE'>(
    'NONE'
  );
  const [currentPageNumber, setPageNumber] = useState(1);
  const [currentPageSize, setPageSize] = useState(25);
  const [toggle, setToggle] = useState(false);
  const [cloudFilterName, setCloudFilterName] = useState('clouds');

  const navigate = useNavigate();

  const leftInlineFilters: any = [
    {
      key: t('view'),
      label: t('view'),
      type: '',
      values: [t('leftFilterCloudsValue')]
        .concat(
          Array.from(
            new Set(deploymentEnvData?.map(deplEnv => deplEnv.cloud_name))
          )
        )
        .map(item =>
          item === 'Clouds'
            ? {
                label: t('tableHeaders.cloudName'),
                value: 'clouds',
              }
            : {
                label: item,
                value: item,
              }
        ),
      filterCallback: (e: any) => {
        if (e.selectedItem.label) {
          setCloudFilterName(e.selectedItem.value ?? e.selectedItem.label);
          handleSelectDepEnv({
            depEnvid: '',
            type: '',
            resourceGroupId: '',
            autoDiscover: false,
            depEnvName: '',
          });
        }
      },
    },
  ];

  const getInlineFilteredDeplEnv = () => {
    if (Array.isArray(deploymentEnvData)) {
      if (cloudFilterName !== 'clouds') {
        let filteredData = deploymentEnvData?.filter(
          deplEnv => deplEnv.cloud_name === cloudFilterName
        );
        return filteredData;
      }
      return deploymentEnvData;
    }
    return null;
  };

  const getAppDeploymentTableHeaders = () => {
    let headers = [
      {
        key: 'checkbox',
        originalKey: 'checkbox',
        header: '',
      },
      {
        key: 'name__format',
        originalKey: 'name',
        header: t('tableHeaders.name'),
        sort: 'sortByName',
        style: { minWidth: '11.5rem' },
      },
      {
        key: 'resourceGroup',
        originalKey: 'resourceGroup',
        header: t('tableHeaders.resourceGroup'),
      },
      {
        key: 'location',
        originalKey: 'location',
        header: t('tableHeaders.location'),
        sort: 'sortByLocation',
      },
      {
        key: 'type',
        originalKey: 'type',
        header: t('tableHeaders.type'),
        sort: 'sortByType',
      },
      {
        key: 'cloudName',
        originalKey: 'cloudName',
        header: t('tableHeaders.cloudName'),
        sort: 'sortByCloudName',
      },
      {
        key: 'autodiscover',
        originalKey: 'auto_discover',
        header: t('tableHeaders.autodiscover'),
      },
      {
        key: 'lastUpdated',
        originalKey: 'lastUpdated',
        header: t('tableHeaders.lastUpdated'),
        sort: 'sortByLastUpdated',
      },
    ];

    if (
      toggle &&
      (selectedDepEnv.type === 'cluster' || selectedDepEnv.type === 'vpc')
    ) {
      return headers.splice(0, headers.length - 1);
    }

    return headers;
  };

  const setPageChange = (pageData: { page: number; pageSize: number }) => {
    setPageNumber(pageData.page);
    setPageSize(pageData.pageSize);
  };

  const handleTableSort = (
    data: { id: string; text: string },
    sortDirection: 'ASC' | 'DESC' | 'NONE'
  ) => {
    setSortDirection(sortDirection);
    setSortKey(data.id);
  };

  const getResourceGroupName = (id: string | undefined) => {
    const resourceGroup =
      id && Array.isArray(resourceGroupsData)
        ? resourceGroupsData!.find(
            resourceGroup => resourceGroup.resource_id === id
          )
        : null;

    return resourceGroup ? resourceGroup.name : '';
  };

  const emptyStateData = {
    icon: images.DeploymentEnvironmentEmpty(),
    notFoundIcon: images.NotFoundLarge(),
    emptyStateHeader: t('emptyState.emptyContainerHeader'),
    emptyStateDescription: t('emptyState.emptyContainerDescription'),
    link: '/',
    buttonText: t('emptyState.buttonText'),
    click: () => navigate('/deploymentenvironments'),
  };

  const handleRefresh = () => {
    refreshData();
  };

  const handleRadiobtnClick = (value: boolean, item: Item) => {
    value && handleSelectDepEnv(item);
    value ? setToggle(true) : setToggle(false);
    enableButton(false);

    if (item.type === 'node') {
      getAppDeploymentData(
        item.type,
        item.autoDiscover,
        item.depEnvid,
        item.resourceGroupId,
        item.depEnvName,
        ''
      );
    }
  };

  const renderRadioButton = (item: Item, checked: boolean) => {
    return (
      <div>
        <RadioButton
          id={'' + item.depEnvid}
          labelText=''
          hideLabel
          checked={checked}
          onClick={e =>
            handleRadiobtnClick((e.target as HTMLInputElement).checked, item)
          }
        />
      </div>
    );
  };

  const renderAutoDiscover = (
    type: string,
    autoDiscover: boolean | undefined
  ) => {
    let autoDiscoverField;
    switch (type) {
      case 'cluster':
        autoDiscoverField = autoDiscover ? (
          <div className='auto-discover-field'>
            <div className='auto-discover-label'>
              <div className='label-icon'>
                <CheckmarkFilled16 fill={'green'} />
              </div>
              <span className='label-value'>
                {t('table.autodiscover.namespace')}
              </span>
            </div>
          </div>
        ) : (
          'Off'
        );
        break;
      case 'vpc':
        autoDiscoverField = autoDiscover ? (
          <div className='auto-discover-field'>
            <div className='auto-discover-label'>
              <div className='label-icon'>
                <CheckmarkFilled16 fill={'green'} />
              </div>
              <span className='label-value'>
                {t('table.autodiscover.securitygroup')}
              </span>
            </div>
            <div className='auto-discover-label'>
              <div className='label-icon'>
                <CheckmarkFilled16 fill={'green'} />
              </div>
              <span className='label-value'>
                {t('table.autodiscover.apps')}
              </span>
            </div>
          </div>
        ) : (
          'Off'
        );
        break;
      case 'node':
        autoDiscoverField = autoDiscover ? (
          <div className='auto-discover-field'>
            <div className='auto-discover-label'>
              <div className='label-icon'>
                <CheckmarkFilled16 fill={'green'} />
              </div>
              <span className='label-value'>{'Apps'}</span>
            </div>
          </div>
        ) : (
          'Off'
        );
        break;

      default:
        autoDiscoverField = 'Off';
        break;
    }
    return autoDiscoverField;
  };

  const getFormattedRows = (rows: DeploymentEnvironment[] | null | []) => {
    const formattedData: {
      checkbox: JSX.Element;
      id: string;
      name: string;
      name__format: JSX.Element;
      resourceGroup: string;
      location: string;
      type: string | false;
      autodiscover: JSX.Element | undefined | string;
      lastUpdated: string;
      cloudName: string;
    }[] = [];
    if (rows)
      rows.map((row: DeploymentEnvironment) => {
        let item: Item = {
          depEnvid: row.resource_id,
          type: row.type,
          resourceGroupId: row.resource_group_id,
          autoDiscover: row.auto_discover,
          depEnvName: row.name,
        };
        const checked = row.resource_id === selectedDepEnv.depEnvid;
        formattedData.push({
          checkbox: renderRadioButton(item, checked),
          id: row.resource_id,
          name: row.name,
          name__format: (
            <div className='name-column-value'>
              <Link
                className='no-underline-link'
                to={`/deploymentEnvironmentDetails?deplId=${row.resource_id}`}
              >
                {
                  <GenericTruncateString
                    str={row.name}
                    maxLength={23}
                    limit={9}
                    tableView={false}
                  />
                }
                {row?.is_discovered && (
                  <div className='icon'>
                    <IconWithToolTip
                      icon={images.AutoDiscoverdLockIconSmall()}
                      iconDescription={t('autoDiscoveredAccessLimited')}
                    />
                  </div>
                )}
              </Link>
            </div>
          ),
          resourceGroup: getResourceGroupName(row.resource_group_id),
          location: row.location_name,
          autodiscover: renderAutoDiscover(row.type, row.auto_discover),
          type:
            Array.isArray(deploymentEnvSubtypesData) &&
            deploymentEnvSubtypesData.length > 0 &&
            deploymentEnvSubtypesData?.filter(
              depEnvSubType => depEnvSubType.type_code === row.subtype
            )[0].type_name,
          lastUpdated: dateUtils.getUserFriendlyDate(row.updated_at),
          cloudName: row.cloud_name,
        });
        return 0;
      });
    else return null;
    return formattedData;
  };

  return (
    <div className='add-application-deployment-details-container'>
      <div className='sub-header'>{t('title')}</div>
      <div className='add-application-deployment-details-content'>
        <div
          className={`application-deployment-table ${
            toggle &&
            (selectedDepEnv.type === 'cluster' || selectedDepEnv.type === 'vpc')
              ? 'selected'
              : ''
          }`}
        >
          {
            <GenericTableWithFilters
              id='table-with-filter-panel'
              rows={
                Array.isArray(getInlineFilteredDeplEnv())
                  ? filterApplied.length > 0
                    ? sortData(
                        getFormattedRows(filteredData as any),
                        sortKey,
                        sortDirection
                      )
                    : sortData(
                        getFormattedRows(getInlineFilteredDeplEnv()),
                        sortKey,
                        sortDirection
                      )
                  : null
              }
              data={
                getInlineFilteredDeplEnv()
                  ? filterApplied.length > 0
                    ? filteredData
                    : getInlineFilteredDeplEnv()
                  : null
              }
              headers={getAppDeploymentTableHeaders()}
              isSortable={true}
              totalElementsCount={
                getInlineFilteredDeplEnv()
                  ? filterApplied.length > 0
                    ? filteredData.length
                    : getInlineFilteredDeplEnv()?.length ?? 0
                  : 0
              }
              fullData={getInlineFilteredDeplEnv()}
              onTableRefresh={() => handleRefresh()}
              filteredDataCallback={(data: DeploymentEnvironment[] | []) => {
                data && setFilteredData(data);
                setPageNumber(1);
              }}
              selectedFiltersVal={filterApplied as any}
              setFilterApplied={(data: AppliedFilter[]) =>
                setFilterApplied(data)
              }
              filters={
                [
                  {
                    key: 'location',
                    type: 'multi',
                    mdWidth: 4,
                    lgWidth: 4,
                    label: t('tableHeaders.filter.location'),
                    values: [
                      ...Array.from(
                        new Set(
                          getInlineFilteredDeplEnv()?.map(
                            depenv => depenv.location_name
                          )
                        )
                      ),
                    ],
                  },
                  {
                    key: 'type',
                    type: 'single',
                    mdWidth: 4,
                    lgWidth: 4,
                    label: t('tableHeaders.filter.type'),
                    values: [
                      ...Array.from(
                        new Set(
                          getInlineFilteredDeplEnv()?.map(depenv => depenv.type)
                        )
                      ),
                    ],
                  },
                ] as Filter[]
              }
              currentPageNumber={currentPageNumber}
              currentPageSize={currentPageSize}
              onPageChange={(pageData: any) => setPageChange(pageData)}
              emptyState={emptyStateData}
              sortRows={(
                data: { id: string; text: string },
                sortDirection: 'ASC' | 'DESC' | 'NONE'
              ) => handleTableSort(data, sortDirection)}
              leftInlineFilters={leftInlineFilters}
              visibilityFlag={cloudFilterName}
            />
          }
        </div>
        {toggle &&
          (selectedDepEnv.type === 'cluster' ||
            selectedDepEnv.type === 'vpc') && (
            <div className='partition-table-container'>
              <div className='header'>
                {selectedDepEnv.type === 'cluster'
                  ? t('partitionTable.selectNameSpace')
                  : t('partitionTable.selectSecurityGroup')}
              </div>
              <PartitionsTable
                deployementEnv={selectedDepEnv}
                setAppDeploymentData={getAppDeploymentData}
                appNetworkSegmentId={appNetworkSegmentId}
              />
            </div>
          )}
      </div>
    </div>
  );
};

export default AddApplicationDeploymentDetails;
