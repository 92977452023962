import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useQueryClient } from '@tanstack/react-query';

import {
  Row,
  Column,
  Button,
  ContentSwitcher,
  SkeletonPlaceholder,
  OnChangeData,
} from 'carbon-components-react';

import { List32, Grid32 } from '@carbon/icons-react';
import { FlexGrid } from '@carbon/react';

import useAnalytics from '../../lib/useAnalytics';
import analyticsData from '../../lib/analyticsEventData';
import sortData from '../../lib/tableSort';
import { defaultStaleTime } from '../../hooks/queryDefaults';
import {
  QueryKeys,
  ResourceTypes,
  ViewTypes,
  VisibilityFlags,
  ActionTypes,
  LocationTypes,
} from '../../lib/enums';
import images from '../../images/images';
import {
  getSessionStorageItem,
  manageFiltersFromStorage,
  setSessionStorageItem,
} from '../../lib/utils';

import { Location, AppliedFilter, Error500Type } from '../../models/master';
import { useLocations } from '../../hooks/useLocations';

import Header from '../../components/Header/Header';
import SortDropDown from '../../components/SortDropdown/SortDropDown';
import FindAndFilterBar from '../../components/FindAndFilterBar/FindAndFilterBar';
import Error403Card from '../../components/ErrorState/Error403Card';
import { CardEmptyState } from '../../components/CardEmptyState/CardEmptyState';
import Error500Card from '../../components/ErrorState/Error500Card';
import LocationsCards from './LocationsCards/LocationsCards';
import LocationsTable from './LocationsTable/LocationsTable';
import RegisterLocation from './RegisterLocation/RegisterLocation';
import './LocationsContainer.scss';

type LocationState = {
  resourceType: string;
  navigateBack: boolean;
};

type View = 'table' | 'card';

const LocationsContainer = () => {
  const { t } = useTranslation('location');
  const location = useLocation();
  const state = location.state as LocationState;
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { trackButtonClicked, pageViewed } = useAnalytics();

  const [currentPageNumber, setPageNumber] = useState(1);
  const [currentPageSize, setPageSize] = useState(25);
  const [filteredData, setFilteredData] = useState<Location[] | []>([]);
  const [filterApplied, setFilterApplied] = useState<AppliedFilter[] | []>([]);
  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | 'NONE'>(
    'NONE'
  );
  let viewStateValue = localStorage.getItem('VIEW_STATE') as View;
  const [view, setView] = useState<View>(
    viewStateValue ? viewStateValue : ViewTypes.TABLE
  );
  const [locationsData, setLocationsData] = useState<Location[] | null>(null);
  const [showRegisterLocation, setShowRegisterLocation] = useState(false);
  const [cloudRegionMap, setCloudRegionMap] = useState<any>(new Map());
  const [cloudList, setCloudList] = useState<any>([]);
  let error500: null | Error500Type = null;
  const visibilityFilterFlag =
    getSessionStorageItem('MCNM_VISIBILITY_FLAG') || VisibilityFlags.MANAGED;

  const leftInlineFilters: any = [
    {
      key: 'view',
      label: t('view'),
      type: '',
      values: [
        {
          value: 'all',
          label: t('all'),
        },
        {
          value: 'managed',
          label: t('managed'),
        },
        {
          value: 'unmanaged',
          label: t('unmanaged'),
        },
      ],
      filterCallback: (e: OnChangeData<any>) => {
        if (e.selectedItem !== visibilityFilterFlag) {
          setLocationsData(null);
          setSessionStorageItem('MCNM_VISIBILITY_FLAG', e.selectedItem?.value);
        }
      },
    },
  ];

  const typeList = [
    {
      label: t('site'),
      code: 'site',
    },
    {
      label: t('region'),
      code: 'region',
    },
    {
      label: t('zone'),
      code: 'zone',
    },
  ];

  let defaultPermissionMap = {
    locations: true,
    clouds: true,
  };

  // Queries locations list
  const {
    data: locationsDataLists,
    isLoading: loadingLocations,
    error: locationError,
    isError: isLocationError,
    refetch: refetchLocationsData,
    isRefetching: isRefetchLocationsData,
  } = useLocations(visibilityFilterFlag?.toLocaleLowerCase(), {
    refetchOnWindowFocus: false,
    staleTime: defaultStaleTime,
  });
  if (isLocationError) {
    const error = locationError as AxiosError;
    defaultPermissionMap.locations =
      error?.response?.status === 403 ? false : true;
    if (error.response!?.status >= 500) {
      error500 = error.response!?.status.toString() as Error500Type;
    }
  }

  const formatLocations = () => {
    const locationsDataList: Location[] =
      locationsDataLists?.locationsData &&
      Array.isArray(locationsDataLists.locationsData as Location[])
        ? locationsDataLists.locationsData
        : [];
    setLocationsData(locationsDataList);
    setFilterApplied(manageFiltersFromStorage());
    setCloudList(locationsDataLists?.cloudList);
    locationsDataList
      ?.filter(
        (location: Location) =>
          location.type === LocationTypes.REGION && !location.unmanaged
      )
      .map((location: Location) =>
        setCloudRegionMap(
          new Map(
            cloudRegionMap.set(location.name, [
              location.resource_id,
              location.cloud_location_code,
              location.cloud_id,
            ])
          )
        )
      );
  };

  useMemo(() => formatLocations(), [locationsDataLists, visibilityFilterFlag]);

  const refreshLocation = async () => {
    setSortKey('');
    setSortDirection('NONE');
    setFilterApplied(manageFiltersFromStorage());
    defaultPermissionMap = {
      ...defaultPermissionMap,
      locations: true,
    };
    //clear previous 500 error & 403 error status
    if (error500) {
      error500 = null;
    }
    refetchLocationsData();
  };

  const handleSort = (data: { id: string; text: string }) => {
    if (locationsData) {
      if (data.id === 'atoz') {
        setSortDirection('ASC');
        const sortedResourceGroups = Array.isArray(locationsData)
          ? locationsData.sort((a, b) =>
              a.name?.trim()?.toLowerCase() > b.name?.trim()?.toLowerCase()
                ? 1
                : -1
            )
          : [];
        setLocationsData([...sortedResourceGroups]);
      } else {
        setSortDirection('DESC');
        const sortedResourceGroups = Array.isArray(locationsData)
          ? locationsData.sort((a, b) =>
              a.name?.trim()?.toLowerCase() < b.name?.trim()?.toLowerCase()
                ? 1
                : -1
            )
          : [];
        setLocationsData([...sortedResourceGroups]);
      }
    }
  };

  const renderFilter = () => {
    return (
      <FindAndFilterBar
        data={locationsData}
        filteredDataCallback={data => setFilteredData(data as Location[] | [])}
        filteredData={
          locationsData
            ? filterApplied?.length > 0
              ? filteredData
              : locationsData
            : null
        }
        filtersApplied={filterApplied as any}
        filtersAppliedCallback={data => setFilterApplied(data)}
        filters={getLocationFilters() as any}
        onRefresh={refreshLocation}
        leftInlineFilters={leftInlineFilters}
        visibilityFlag={visibilityFilterFlag}
        persistFilter
      />
    );
  };

  const setPageChange = (pageData: { page: number; pageSize: number }) => {
    setPageNumber(pageData.page);
    setPageSize(pageData.pageSize);
  };

  useEffect(() => {
    pageViewed('Locations');
  }, []);

  useEffect(() => {
    if (state?.resourceType === ResourceTypes.LOCATION) {
      setShowRegisterLocation(true);
    }
  }, [state]);

  const handleTableSort = (
    data: { id: string; text: string },
    sortDirection: 'ASC' | 'DESC' | 'NONE'
  ) => {
    setSortDirection(sortDirection);
    setSortKey(data.id);
  };

  const getLocationFilters = () => [
    {
      key: 'cloud',
      label: t('filters.cloud'),
      placeHolderText: t('filters.cloudPlaceholder'),
      type: 'single',
      values: [
        ...Array.from(
          new Set(locationsData?.map(location => location?.cloud_name))
        ),
      ],
    },
    {
      key: 'type',
      label: t('filters.type'),
      placeHolderText: t('filters.typePlaceholder'),
      translationReqd: true,
      translationData: {
        site: t('site'),
        region: t('region'),
        zone: t('zone'),
      },
      type: 'single',
      values: [
        ...Array.from(new Set(locationsData?.map(location => location?.type))),
      ],
    },
    {
      key: 'city',
      label: t('filters.city'),
      placeHolderText: t('filters.cityPlaceholder'),
      type: 'multi',
      values: [
        ...Array.from(new Set(locationsData?.map(location => location?.city))),
      ],
    },
    {
      key: 'country',
      label: t('filters.country'),
      placeHolderText: t('filters.countryPlaceholder'),
      type: 'multi',
      values: [
        ...Array.from(
          new Set(locationsData?.map(location => location?.country))
        ),
      ],
    },
    {
      key: 'labels',
      type: 'multi',
      label: t('filters.labels'),
      placeHolderText: t('filters.labelsPlaceholder'),
      values: [
        ...Array.from(
          new Set(
            (locationsData ? locationsData : [])
              .map(location => location?.labels)
              .flat()
          )
        ),
      ],
    },
  ];

  const closeAddLocationTearSheet = () => {
    setShowRegisterLocation(false);
    if (state?.navigateBack) {
      navigate(-1);
    }
  };

  const isDataLoading = loadingLocations || isRefetchLocationsData;

  return (
    <div className='location-container'>
      <Header
        title={t('header')}
        subTitle={t('subheader')}
        breadcrumbs={[
          {
            url: '/',
            label: t('home'),
          },
        ]}
        actions={[
          {
            kind: 'primary',
            onClick: () => {
              trackButtonClicked(
                analyticsData['Locations'].events.registerLocationBtn.props,
                analyticsData['Locations'].events.registerLocationBtn.event
              );
              setShowRegisterLocation(true);
            },
            toolTip: t('noCloud'),
            disabled: cloudList?.length ? false : true,
            text: t('addLocation'),
          },
        ]}
      />
      <div className='page-content'>
        <div className='location-switcher'>
          {view !== ViewTypes.TABLE ? (
            <>
              {isDataLoading ? (
                <div className='skeleton-sort-drop-down'>
                  <SkeletonPlaceholder className={'sorting-skeleton'} />
                </div>
              ) : (
                <SortDropDown
                  id='location-sort-dropdown'
                  size='lg'
                  onSort={handleSort}
                  sortDir={sortDirection}
                />
              )}
            </>
          ) : null}
          <ContentSwitcher className='view-switcher'>
            <Button
              className={
                'switch-button' + (view === ViewTypes.TABLE ? ' selected' : '')
              }
              onClick={() => {
                setView(ViewTypes.TABLE);
                localStorage.setItem('VIEW_STATE', ViewTypes.TABLE);
              }}
              renderIcon={List32}
              hasIconOnly
              tooltipPosition='bottom'
              iconDescription={t('table')}
            />
            <Button
              className={
                'switch-button' + (view === ViewTypes.CARD ? ' selected' : '')
              }
              onClick={() => {
                setView(ViewTypes.CARD);
                localStorage.setItem('VIEW_STATE', ViewTypes.CARD);
              }}
              renderIcon={Grid32}
              hasIconOnly
              tooltipPosition='bottom'
              iconDescription={t('card')}
            />
          </ContentSwitcher>
        </div>
        <div className='body'>
          {view === ViewTypes.TABLE ? (
            <LocationsTable
              currentPageNumber={currentPageNumber}
              currentPageSize={currentPageSize}
              sortRows={(
                data: { id: string; text: string },
                direction: 'ASC' | 'DESC' | 'NONE'
              ) => handleTableSort(data, direction)}
              onPageChange={(pageData: { page: number; pageSize: number }) =>
                setPageChange(pageData)
              }
              rows={
                locationsData
                  ? filterApplied?.length > 0
                    ? sortData(filteredData, sortKey, sortDirection)
                    : sortData(locationsData, sortKey, sortDirection)
                  : null
              }
              elementCount={
                locationsData
                  ? filterApplied?.length > 0
                    ? filteredData?.length
                    : locationsData?.length
                  : 0
              }
              filteredDataSet={
                locationsData
                  ? filterApplied?.length > 0
                    ? filteredData
                    : locationsData
                  : null
              }
              filteredDataCallback={data => {
                data && setFilteredData(data as Location[] | []);
                setPageNumber(1);
              }}
              data={locationsData}
              filtersSelected={filterApplied as any}
              filtersAppliedCallback={data => setFilterApplied(data)}
              filters={getLocationFilters()}
              onRefresh={refreshLocation}
              leftInlineFilters={leftInlineFilters}
              visibilityFlag={visibilityFilterFlag}
              persistFilter
              typeList={typeList}
              error403Flag={!defaultPermissionMap['locations']}
              dataLoading={isDataLoading}
              error500Flag={!!error500}
            />
          ) : !defaultPermissionMap['locations'] ? (
            <Error403Card />
          ) : error500 ? (
            <Error500Card />
          ) : isDataLoading ? (
            <div>
              {renderFilter()}
              <Row className='skeleton-card-view'>
                <Column>
                  <SkeletonPlaceholder className='resouce-group-skeleton' />
                </Column>
                <Column>
                  <SkeletonPlaceholder className='resouce-group-skeleton' />
                </Column>
                <Column>
                  <SkeletonPlaceholder className='resouce-group-skeleton' />
                </Column>
                <Column>
                  <SkeletonPlaceholder className='resouce-group-skeleton' />
                </Column>
              </Row>
            </div>
          ) : (
            <div>
              {locationsData && locationsData?.length >= 0
                ? renderFilter()
                : null}
              <div className='card-view'>
                <FlexGrid>
                  <Row className='resource-card-alignment'>
                    {locationsData ? (
                      (filterApplied?.length > 0 ? filteredData : locationsData)
                        ?.length > 0 ? (
                        (filterApplied?.length > 0
                          ? filteredData
                          : locationsData
                        ).map(location => (
                          <Column lg={4} md={4} className='resource-card'>
                            <LocationsCards
                              cloudId={location?.cloud_id}
                              locationId={location?.resource_id}
                              type={location?.type}
                              city={!!location?.city ? location?.city : '—'}
                              country={
                                !!location?.country ? location?.country : '—'
                              }
                              cloudName={location?.cloud_name ?? '—'}
                              resourceName={location.name}
                              description={location.description}
                              tags={location.labels}
                              updated={location.updated_at}
                              unmanaged={location.unmanaged}
                              typeList={typeList}
                            />
                          </Column>
                        ))
                      ) : (
                        <CardEmptyState
                          filterApplied={filterApplied}
                          emptyState={{
                            icon: images.noLocationsLarge(),
                            header: t('emptyState.emptyContainerHeader'),
                            description: t(
                              'emptyState.emptyContainerDescription'
                            ),
                          }}
                        />
                      )
                    ) : (
                      <div className='no-resource-group'></div>
                    )}
                  </Row>
                </FlexGrid>
              </div>
            </div>
          )}
        </div>
      </div>
      {showRegisterLocation && (
        <RegisterLocation
          open={showRegisterLocation}
          onClose={closeAddLocationTearSheet}
          refreshLocation={refreshLocation}
          cloudRegionMap={cloudRegionMap}
          actionType={ActionTypes.ADD}
          locationsLists={locationsDataLists}
        />
      )}
    </div>
  );
};

export default LocationsContainer;
