import React from 'react';
import { useTranslation } from 'react-i18next';
import { ClickableTile, Row, Column } from 'carbon-components-react';

import dateUtils from '../../../lib/dates';
import './ResourceCard.scss';
import Overflow from '../../../components/Overflow/Overflow';
import GenericTruncateString from '../../../components/GenericTruncateString/GenericTruncateString';

const ResourceCard = ({
  resourceType,
  resourceName,
  description,
  updated_at,
}: {
  resourceType: string;
  resourceName: string;
  description: string;
  updated_at: string;
}) => {
  const { t } = useTranslation('resourceGroupsContainer');

  return (
    <ClickableTile className='resource-group-card'>
      <Row className='header'>
        <Column>
          <div className='name' title={resourceName ?? ''}>
            <GenericTruncateString
              str={resourceName}
              maxLength={25}
              limit={11}
            />
          </div>
        </Column>
      </Row>
      <Row className='data'>
        <Column>
          <div className='label-text'>{t('modal.type.label')}</div>
          <div className='name'>{resourceType}</div>
        </Column>
      </Row>

      <Row className='data'>
        <Column>
          <div className='label-text'>{t('tableHeaders.description')}</div>

          <div className='name'>
            <Overflow maxNoOfLines={2}>
              <div className='resource-name' title={description ?? ''}>
                {description ? description : '—'}
              </div>
            </Overflow>
          </div>
        </Column>
      </Row>

      <Row className='data'>
        <Column>
          <div className='helper-text'>
            {t('updated', { date: dateUtils.getUserFriendlyDate(updated_at) })}
          </div>
        </Column>
      </Row>
    </ClickableTile>
  );
};

export default ResourceCard;
