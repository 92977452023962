import { useQuery } from '@tanstack/react-query';
import { QueryOptions, queryDefaultOptions } from './queryDefaults';
import { QueryKeys } from '../lib/enums';
import {
  getSubscriptionUsage,
  getUsageHistory,
} from '../controllers/subscriptionUsage';

export const useSubscriptionUsage = (options?: QueryOptions) => {
  return useQuery({
    queryKey: [QueryKeys.SUBSCRIPTION_USAGE],
    queryFn: getSubscriptionUsage,
    ...queryDefaultOptions,
    ...options,
  });
};

export const useUsageHistory = (options?: QueryOptions) => {
  return useQuery({
    queryKey: [QueryKeys.USAGE_HISTORY],
    queryFn: getUsageHistory,
    ...queryDefaultOptions,
    ...options,
  });
};
