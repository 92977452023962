import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { useSearchParams, useNavigate } from 'react-router-dom';
import {
  Column,
  SkeletonPlaceholder,
  Loading,
  TooltipDefinition,
  Button,
} from 'carbon-components-react';
import { Restart16 } from '@carbon/icons-react';

import Error500 from '../Errors/Error500';
import Header from '../../components/Header/Header';
import CloudLocationList from './CloudLocationList';
import Error403Card from '../../components/ErrorState/Error403Card';
import RegisterCloud from '../Cloud/RegisterCloud/RegisterCloud';
import DetailsCard from '../../components/DetailsCard/DetailsCard';
import Notification from '../../components/Notifications/Inline/Notification';
import GenericStatusField from '../../components/GenericStatusField/GenericStatusField';
import GenericTruncateString from '../../components/GenericTruncateString/GenericTruncateString';

import { reRunInfraDiscovery } from '../../controllers/cloudApis';
import { useResourceGroupsData } from '../../hooks/useResourceGroups';
import { useCloudsData, useCloudsDetails } from '../../hooks/useClouds';

import dateUtils from '../../lib/dates';
import images from '../../images/images';
import useAnalytics from '../../lib/useAnalytics';
import analyticsData from '../../lib/analyticsEventData';

import { DiscoveryStatus, ResourceGroupTypes } from '../../lib/enums';
import { ResourceGroup } from '../../models/master';

import './CloudDetails.scss';

const CloudDetails = () => {
  const { t } = useTranslation('cloudDetails');

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const cloudId = searchParams.get('cloudId');

  const { trackButtonClicked, pageViewed } = useAnalytics();

  const locationListRef = useRef<any>();

  let error500 = false;

  const [editCloudTearsheetOpen, toggleEditCloudTearsheet] = useState(false);
  const [autoDiscoverRunClicked, setAutoDiscoverRunClicked] = useState(false);
  const [showRunNowError, setShowRunNowError] = useState<boolean>(false);

  const defaultPermissionMap = {
    resourceGroup: true,
    cloud: true,
  };

  useEffect(() => {
    pageViewed('Cloud Details');
  }, []);

  useEffect(() => {
    if (autoDiscoverRunClicked) {
      setTimeout(() => {
        setAutoDiscoverRunClicked(false);
      }, 10000);
    }
  }, [autoDiscoverRunClicked]);

  const {
    data: clouds,
    error: cloudError,
    isError: isCloudError,
  } = useCloudsData({
    refetchOnWindowFocus: false,
  });

  if (isCloudError) {
    const error = cloudError as AxiosError;
    defaultPermissionMap.cloud = error?.response?.status === 403 ? false : true;
  }

  const {
    data: cloudDetails,
    isLoading: isCloudDetailsLoading,
    error: cloudDetailsError,
    isError: isCloudDetailsError,
    refetch: refreshCloudDetails,
    isRefetching: isCloudDetailsRefetching,
  } = useCloudsDetails(cloudId ?? '', {
    refetchOnWindowFocus: false,
    enabled: !!cloudId,
  });

  if (isCloudDetailsError) {
    const error = cloudDetailsError as AxiosError;
    defaultPermissionMap.cloud = error?.response?.status === 403 ? false : true;

    if (error.response!?.status === 404) {
      navigate('/404');
    }

    if (error.response!?.status >= 500) {
      error500 = true;
    }
  }

  const cloudDetailsDataLoading =
    isCloudDetailsLoading || isCloudDetailsRefetching;

  const {
    data: resourceGroups,
    error: resourceGroupError,
    isError: isResourceGroupError,
  } = useResourceGroupsData({
    refetchOnWindowFocus: false,
  });

  if (isResourceGroupError) {
    const error = resourceGroupError as AxiosError;
    defaultPermissionMap.resourceGroup =
      error?.response?.status === 403 ? false : true;
  }

  const getSelectedResourceGroup = () => {
    if (Array.isArray(resourceGroups)) {
      return resourceGroups.find(
        (resourceGroup: ResourceGroup) =>
          resourceGroup.resource_id === cloudDetails?.resource_group_id
      );
    }

    return null;
  };

  const selectedResourceGroup = useMemo(
    () => getSelectedResourceGroup(),
    [resourceGroups, cloudDetails]
  );

  const getInfraResourceGroups = () => {
    if (Array.isArray(resourceGroups)) {
      return resourceGroups.filter(
        (resourceGroup: ResourceGroup) =>
          resourceGroup.type === ResourceGroupTypes.INFRASTRUCTURE
      ) as ResourceGroup[];
    }

    return null;
  };

  const infraResourceGroups = useMemo(
    () => getInfraResourceGroups(),
    [resourceGroups]
  );

  useEffect(() => {
    let interval: any;

    if (cloudDetails?.auto_discover === true) {
      if (cloudDetails?.discovery_status !== DiscoveryStatus.COMPLETED) {
        if (cloudDetails?.discovery_status === DiscoveryStatus.ERRORED) {
          clearInterval(interval);
          return;
        }

        if (
          cloudDetails?.discovery_status &&
          [DiscoveryStatus.PENDING, DiscoveryStatus.INPROGRESS].indexOf(
            cloudDetails?.discovery_status
          ) > -1
        ) {
          interval = setInterval(() => {
            refreshCloudDetails();
            locationListRef?.current?.refetchLocationAndDeploymentEnv();
          }, 10000);
        } else {
          interval = setInterval(() => {
            refreshCloudDetails();
            locationListRef?.current?.refetchLocationAndDeploymentEnv();
          }, 30000);
        }
      }
    }

    return () => clearInterval(interval);
  }, [cloudDetails]);

  const handleReRunInfraDiscovery = async () => {
    try {
      setAutoDiscoverRunClicked(true);
      const cloudDetails = await reRunInfraDiscovery(cloudId);
      if (cloudDetails) {
        refreshCloudDetails();
      }
    } catch (error) {
      setShowRunNowError(true);
    }
  };

  const openEditCloudTearsheet = () => {
    trackButtonClicked(
      analyticsData['Cloud Details'].events.cloudEdit.props,
      analyticsData['Cloud Details'].events.cloudEdit.event
    );
    toggleEditCloudTearsheet(true);
  };

  const lastTimeAutodiscoverRunAt = (completedAt: any) => {
    const dateValues = completedAt.split('-');
    return dateValues[0] === '0001'
      ? '—'
      : dateUtils.getUserFriendlyDate(completedAt);
  };

  const getAutoDiscoveryIcon = (status: string | undefined) => {
    let icon;

    switch (status) {
      case 'completed':
        icon = images.autoDiscoverLabelIcon();
        break;
      case 'in-progress':
        icon = <Loading active small withOverlay={false} />;
        break;
      case 'pending':
        icon = images.pendingStatusIcon();
        break;
      case 'errored':
        icon = images.criticalStatusIcon();
        break;
      default:
        break;
    }

    return <div className={`${status}-icon`}>{icon}</div>;
  };

  const getAutodiscoveryStatus = (status: string | undefined) => {
    let value;

    switch (status) {
      case 'completed':
        value = t('completed');
        break;
      case 'in-progress':
        value = (
          <TooltipDefinition
            tooltipText={t('in-progressTooltip')}
            direction='bottom'
          >
            {t('in-progress')}
          </TooltipDefinition>
        );
        break;
      case 'pending':
        value = (
          <TooltipDefinition
            tooltipText={t('pendingTooltip')}
            direction='bottom'
          >
            {t('pending')}
          </TooltipDefinition>
        );
        break;
      case 'errored':
        value = (
          <TooltipDefinition tooltipText={t('errorTooltip')} direction='bottom'>
            <span
              className='autoDiscover-error'
              onClick={() => {
                navigate(`/event?resourceInstance=${cloudId}`);
              }}
            >
              {t('errored')}
            </span>
          </TooltipDefinition>
        );
        break;
      default:
        value = (
          <TooltipDefinition
            tooltipText={t('in-progressTooltip')}
            direction='bottom'
          >
            {t('in-progress')}
          </TooltipDefinition>
        );
        break;
    }

    return value;
  };

  const getCloudDetailsList = () => {
    const cloudDetailsList = [
      {
        key: 'name',
        value: (
          <div className='name'>
            <GenericTruncateString
              tableView={true}
              str={cloudDetails?.name ?? '—'}
              maxLength={25}
              limit={11}
            />
          </div>
        ),
      },
      {
        key: 'infrastructureGroup',
        value: defaultPermissionMap['resourceGroup'] ? (
          selectedResourceGroup?.name ?? '—'
        ) : (
          <GenericStatusField status='notAuthorised'></GenericStatusField>
        ),
      },
      {
        key: 'type',
        value: cloudDetails?.type,
      },
      {
        key: 'registered',
        value: cloudDetails?.created_at
          ? dateUtils.getUserFriendlyDate(cloudDetails?.created_at)
          : '—',
      },
      {
        key: 'secretName',
        value: cloudDetails?.credentials_key
          ? cloudDetails?.credentials_key
          : '—',
      },
      {
        key: 'description',
        value: cloudDetails?.description ? (
          <div className='name descriptionTruncate'>
            <GenericTruncateString
              tableView={true}
              str={cloudDetails?.description ?? '—'}
              maxLength={25}
              limit={11}
            />
          </div>
        ) : (
          '—'
        ),
      },
      {
        key: 'autoDiscover',
        value: (
          <div className='auto-discover-label'>
            <div className='auto-discover-label-item '>
              {cloudDetails?.auto_discover ? (
                cloudDetails?.discovery_status && !autoDiscoverRunClicked ? (
                  <div className='autodiscovery-icon'>
                    {getAutoDiscoveryIcon(cloudDetails?.discovery_status)}
                  </div>
                ) : (
                  <div className='autodiscovery-icon'>
                    {getAutoDiscoveryIcon(DiscoveryStatus.INPROGRESS)}
                  </div>
                )
              ) : null}

              <div className='value'>
                {cloudDetails?.auto_discover
                  ? getAutodiscoveryStatus(
                      cloudDetails?.discovery_status && !autoDiscoverRunClicked
                        ? cloudDetails?.discovery_status
                        : t(DiscoveryStatus.INPROGRESS)
                    )
                  : t('off')}
              </div>
            </div>
          </div>
        ) as any,
      },
    ];
    if (cloudDetails?.auto_discover) {
      cloudDetailsList.push({
        key: 'autoDiscoverRun',
        value: (
          <div className='auto-discover-label'>
            <div className='auto-discover-label-item '>
              <div className='value'>
                <div>
                  <span>
                    {lastTimeAutodiscoverRunAt(
                      cloudDetails?.last_discovery_completed_at
                    )}
                  </span>
                  <span
                    className='run-auto-discovery'
                    onClick={() => {
                      handleReRunInfraDiscovery();
                    }}
                  >
                    {t('runNow')} <Restart16 className='run-button' />
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) as any,
      });
    }

    return cloudDetailsList;
  };

  if (error500) {
    return <Error500 />;
  }

  return (
    <div className='cloud-details-view'>
      <Header
        loading={cloudDetailsDataLoading}
        title={cloudDetails?.name ?? ''}
        breadcrumbs={[
          {
            url: '/',
            label: t('home'),
          },
          {
            url: '/clouds',
            label: t('cloudDetails'),
          },
        ]}
      />

      {!cloudDetailsDataLoading && showRunNowError && (
        <div className='error-notification'>
          <Notification
            kind='error'
            onCloseButtonClick={() => {
              setShowRunNowError(false);
            }}
            subtitle={
              <div className='subtitle'>
                <span>{`${t('runInfraDiscoveryNotification.subtitle', {
                  cloud: cloudDetails?.name,
                })}`}</span>
                <Button
                  kind='ghost'
                  size='sm'
                  onClick={e => {
                    e.stopPropagation();
                    window.open(
                      window.location.origin +
                        process.env.PUBLIC_URL +
                        `/event?resourceInstance=${cloudId}`
                    );
                  }}
                >
                  {t('runInfraDiscoveryNotification.viewEvents')}
                </Button>
              </div>
            }
            title={t('runInfraDiscoveryNotification.errorTile')}
          ></Notification>
        </div>
      )}

      <div className={'cloud-details-card'}>
        <Column lg={16} className='cloud-details-card-container'>
          {cloudDetailsDataLoading ? (
            <SkeletonPlaceholder className='cloud-details-skeleton' />
          ) : !defaultPermissionMap['cloud'] ? (
            <Error403Card />
          ) : (
            <DetailsCard
              type='CLOUD_DETAILS'
              isEditable={true}
              openEditModal={openEditCloudTearsheet}
              detailsCardName={t('detailsCardName')}
              data={getCloudDetailsList()}
            />
          )}
        </Column>
      </div>

      {cloudDetails && (
        <CloudLocationList
          ref={locationListRef}
          cloudDetails={cloudDetails}
          resourceGroups={resourceGroups}
          resourceGroupPermission={defaultPermissionMap['resourceGroup']}
        />
      )}

      {cloudDetails && (
        <RegisterCloud
          open={editCloudTearsheetOpen}
          closeTearsheet={() => toggleEditCloudTearsheet(false)}
          refreshData={refreshCloudDetails}
          selectedCloudData={cloudDetails}
          editCloud={true}
          cloudsData={clouds}
          groups={infraResourceGroups}
        />
      )}
    </div>
  );
};

export default CloudDetails;
