import moment from 'moment';
import { TFunction } from 'react-i18next';

export type Group = 'bytes' | 'packets';

export type History =
  | 'oneHour'
  | 'oneDay'
  | 'oneWeek'
  | 'twoWeek'
  | 'threeWeek';

export type Flow = 'incoming' | 'outgoing';

export type Resolution =
  | 'oneMinute'
  | 'fiveMinute'
  | 'oneHour'
  | 'thirtyMinute'
  | 'oneDay';

export interface Data {
  group: Group;
  key: Date | number;
  value: number;
}

export interface GatewayMetric {
  metric: {
    __name__: string;
    gw_id: string;
    infra_resource_group_id: string;
    gateway: string;
    instance: string;
    job: string;
    tenant_id: string;
    tunnel_id: string;
  };
  values: { timestamp: number; value: string }[];
}

export const BYTES_UNIT = 'G';
export const PACKETS_UNIT = 'K';

export const DEFAULT_TYPE: Group = 'bytes';
export const DEFAULT_HISTORY: History = 'oneHour';
export const DEFAULT_RESOLUTION: Resolution = 'fiveMinute';

export const typeToGroupMap = {
  totalBytes: 'bytes',
  totalPackets: 'packets',
};

export const getTypeDropDown = (
  t: TFunction<'topologySidepanelGatewayDetails', undefined>
) => [
  {
    text: t('totalBytes'),
    id: 'totalBytes',
  },
  // {
  //   text: t('totalPackets'),
  //   id: 'totalPackets',
  // },
];

export const getDefaultHistoryResolution = (history: History) => {
  const config = {
    oneHour: 'fiveMinute',
    oneDay: 'oneHour',
    oneWeek: 'oneDay',
    twoWeek: 'oneDay',
    threeWeek: 'oneDay',
  };

  return config[history] as Resolution;
};

export const getHistoryDropDown = (
  t: TFunction<'topologySidepanelGatewayDetails', undefined>
) => [
  {
    text: t('oneHour'),
    id: 'oneHour',
  },
  {
    text: t('oneDay'),
    id: 'oneDay',
  },
  {
    text: t('oneWeek'),
    id: 'oneWeek',
  },
  {
    text: t('twoWeek'),
    id: 'twoWeek',
  },
  {
    text: t('threeWeek'),
    id: 'threeWeek',
  },
];

export const getResolutionDropDown = (
  t: TFunction<'topologySidepanelGatewayDetails', undefined>,
  history: History
) => {
  const config = {
    oneHour: [
      {
        text: t('oneMinute'),
        id: 'oneMinute',
      },
      {
        text: t('fiveMinute'),
        id: 'fiveMinute',
      },
      {
        text: t('thirtyMinute'),
        id: 'thirtyMinute',
      },
    ],
    oneDay: [
      {
        text: t('oneMinute'),
        id: 'oneMinute',
      },
      {
        text: t('fiveMinute'),
        id: 'fiveMinute',
      },
      {
        text: t('thirtyMinute'),
        id: 'thirtyMinute',
      },
      {
        text: t('oneHour'),
        id: 'oneHour',
      },
    ],
    oneWeek: [
      {
        text: t('oneHour'),
        id: 'oneHour',
      },
      {
        text: t('oneDay'),
        id: 'oneDay',
      },
    ],
    twoWeek: [
      {
        text: t('oneHour'),
        id: 'oneHour',
      },
      {
        text: t('oneDay'),
        id: 'oneDay',
      },
    ],
    threeWeek: [
      {
        text: t('oneHour'),
        id: 'oneHour',
      },
      {
        text: t('oneDay'),
        id: 'oneDay',
      },
    ],
  };

  return config[history];
};

export const getQueryParameters = (
  history: History,
  resolution: Resolution
) => {
  const dataKeys = {
    oneHour_oneMinute: {
      duration: 1,
      type: 'h',
      step: '1m',
    },
    oneHour_fiveMinute: {
      duration: 1,
      type: 'h',
      step: '5m',
    },
    oneHour_thirtyMinute: {
      duration: 1,
      type: 'h',
      step: '30m',
    },
    oneDay_oneMinute: {
      duration: 1,
      type: 'd',
      step: '1m',
    },
    oneDay_fiveMinute: {
      duration: 1,
      type: 'd',
      step: '5m',
    },
    oneDay_thirtyMinute: {
      duration: 1,
      type: 'd',
      step: '30m',
    },
    oneDay_oneHour: {
      duration: 1,
      type: 'd',
      step: '1h',
    },
    oneWeek_oneHour: {
      duration: 1,
      type: 'w',
      step: '1h',
    },
    oneWeek_oneDay: {
      duration: 1,
      type: 'w',
      step: '1d',
    },
    twoWeek_oneHour: {
      duration: 2,
      type: 'w',
      step: '1h',
    },
    twoWeek_oneDay: {
      duration: 2,
      type: 'w',
      step: '1d',
    },
    threeWeek_oneHour: {
      duration: 3,
      type: 'w',
      step: '1h',
    },
    threeWeek_oneDay: {
      duration: 3,
      type: 'w',
      step: '1d',
    },
  };

  const key = `${history}_${resolution}`;

  return (dataKeys as any)[key] ? (dataKeys as any)[key] : null;
};

export const getMetricsQuery = (
  gwId: string,
  group: Group,
  history: History,
  resolution: Resolution,
  flow: Flow
) => {
  const flowType =
    flow === 'incoming'
      ? 'gw_network_logical_incoming'
      : 'gw_network_logical_outgoing';

  const queryType = group === 'bytes' ? 'bytes_total' : 'packets_total';

  const { duration, type, step } = getQueryParameters(history, resolution);

  const start = moment().subtract(duration, type).toISOString();
  const end = moment().toISOString();
  return `sum by (gw_id,gw_infra_resource_group_id)(increase(${flowType}_${queryType}{gw_id="${gwId}"}[${step}]))&start=${start}&end=${end}&step=${step}`;
};

export const getTotalMetricsQuery = (
  gwId: string,
  group: Group,
  flow: Flow,
  time: string
) => {
  const flowType =
    flow === 'incoming'
      ? 'gw_network_logical_incoming'
      : 'gw_network_logical_outgoing';

  const queryType = group === 'bytes' ? 'bytes_total' : 'packets_total';
  return `sum by (gw_id,gw_infra_resource_group_id)(${flowType}_${queryType}{gw_id="${gwId}"})&time=${time}`;
};

export const getTunnelMetricsQuery = (
  gwId: string,
  tunnelName: string,
  group: Group,
  history: History,
  resolution: Resolution,
  flow: Flow
) => {
  const flowType =
    flow === 'incoming'
      ? 'gw_network_logical_incoming'
      : 'gw_network_logical_outgoing';

  const queryType = group === 'bytes' ? 'bytes_total' : 'packets_total';

  const { duration, type, step } = getQueryParameters(history, resolution);

  const start = moment().subtract(duration, type).toISOString();
  const end = moment().toISOString();
  return `increase(${flowType}_${queryType}{gw_id="${gwId}",tunnel_id="${tunnelName}"}[${step}])&start=${start}&end=${end}&step=${step}`;
};

export const getTunnelTotalMetricsQuery = (
  gwId: string,
  tunnelName: string,
  group: Group,
  flow: Flow,
  time: string
) => {
  const flowType =
    flow === 'incoming'
      ? 'gw_network_logical_incoming'
      : 'gw_network_logical_outgoing';

  const queryType = group === 'bytes' ? 'bytes_total' : 'packets_total';
  return `${flowType}_${queryType}{gw_id="${gwId}",tunnel_id="${tunnelName}"}&time=${time}`;
};

export const getTunnelAverageLatencyQuery = (
  gwId: string,
  remoteGwId: string | number
) => {
  const start = moment().subtract(1, 'd').toISOString();
  const end = moment().toISOString();
  const step = 86400;

  return `gw_rtt_avg_us{gw_id="${gwId}",remote_gw_id="${remoteGwId}"}&start=${start}&end=${end}&step=${step}`;
};
