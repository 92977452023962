import { UserProfileImage } from '@carbon/ibm-products';
import './ProfileIcon.scss';

const getUserProfileColor = (name: string) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash += name.toLowerCase().charCodeAt(i);
  }

  const colorIndex = hash % 12;

  const colors = [
    'light-cyan',
    'dark-cyan',
    'light-gray',
    'dark-gray',
    'light-green',
    'dark-green',
    'light-magenta',
    'dark-magenta',
    'light-purple',
    'dark-purple',
    'light-teal',
    'dark-teal',
  ];

  return colors[colorIndex];
};

type ProfileIconProps = {
  name: string;
  size?: 'md' | 'lg' | 'xlg';
};

const ProfileIcon = ({ name, size = 'lg' }: ProfileIconProps) => {
  return name ? (
    <UserProfileImage
      backgroundColor={getUserProfileColor(name)}
      initials={name}
      size={size}
      theme='light'
      className='profile-icon-component'
    />
  ) : null;
};

export default ProfileIcon;
