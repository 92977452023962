import React, { useState } from 'react';
import { Slider } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';

import { Add20 } from '@carbon/icons-react';

import './GraphParams.scss';

interface Props {
  handleConnSlider: (data: any) => void;
  graphParamCustomStyle: {
    [key: string]: string;
  };
}

const GraphParams: React.FC<Props> = ({
  graphParamCustomStyle,
  handleConnSlider,
}) => {
  const { t } = useTranslation('topologyCanvasView');
  const [linkDistData, setLinkDistData] = useState({
    appConn: 300,
    polConn: 300,
    gwConn: 300,
    nestedConn: 300,
    relConn: 40,
  });
  const [showParams, setShowParams] = useState(false);

  return (
    <div
      className='topology-graph-params'
      style={graphParamCustomStyle ? graphParamCustomStyle : {}}
    >
      <div
        className='graph-params'
        onClick={() => setShowParams(prevState => !prevState)}
      >
        <div>Graph parameters</div>
        <Add20 />
      </div>
      {showParams && (
        <div>
          <div className='section'>
            <Slider
              labelText='Nested connection link distance'
              value={300}
              min={50}
              max={2000}
              step={1}
              stepMultiplier={10}
              onChange={val => {
                setLinkDistData(prevData => ({
                  ...prevData,
                  nestedConn: val.value,
                }));
                handleConnSlider(linkDistData);
              }}
              hideTextInput={true}
            />
            <div className='section-textBox'>{linkDistData?.nestedConn}</div>
          </div>
          <div className='section'>
            <Slider
              labelText='App connection link distance'
              value={300}
              min={50}
              max={2000}
              step={1}
              stepMultiplier={10}
              onChange={val => {
                setLinkDistData(prevData => ({
                  ...prevData,
                  appConn: val.value,
                }));
                handleConnSlider(linkDistData);
              }}
              hideTextInput={true}
            />
            <div className='section-textBox'>{linkDistData?.appConn}</div>
          </div>
          <div className='section'>
            <Slider
              labelText='Gateway connection link distance'
              value={300}
              min={50}
              max={2000}
              step={1}
              stepMultiplier={10}
              onChange={val => {
                setLinkDistData(prevData => ({
                  ...prevData,
                  gwConn: val.value,
                }));
                handleConnSlider(linkDistData);
              }}
              hideTextInput={true}
            />
            <div className='section-textBox'>{linkDistData?.gwConn}</div>
          </div>
          <div className='section'>
            <Slider
              labelText='Policy connection link distance'
              value={300}
              min={50}
              max={2000}
              step={1}
              stepMultiplier={10}
              onChange={val => {
                setLinkDistData(prevData => ({
                  ...prevData,
                  polConn: val.value,
                }));
                handleConnSlider(linkDistData);
              }}
              hideTextInput={true}
            />
            <div className='section-textBox'>{linkDistData?.polConn}</div>
          </div>
          <div className='section'>
            <Slider
              labelText='Relation connection link distance'
              value={40}
              min={40}
              max={500}
              step={1}
              stepMultiplier={10}
              onChange={val => {
                setLinkDistData(prevData => ({
                  ...prevData,
                  relConn: val.value,
                }));
                handleConnSlider(linkDistData);
              }}
              hideTextInput={true}
            />
            <div className='section-textBox'>{linkDistData?.relConn}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GraphParams;
