import React, { useState, useEffect } from 'react';
import {
  TextInput,
  Column,
  Row,
  Checkbox,
  Dropdown,
  TextArea,
  Button,
  ComboBox,
} from 'carbon-components-react';
import { useTranslation } from 'react-i18next';

import AddLabels from '../../../components/AddLabels/AddLabels';
import { DeploymentEnvironment } from '../../../models/master';
import { Item } from './EditGateway';
import { Add16, Edit16 } from '@carbon/icons-react';
import images from '../../../images/images';
import { Cloud, Location } from '../../../models/master';
interface ingressType {
  name: string;
  id: string;
}
const MeshGateway = (props: {
  onChange: any;
  onBlur: any;
  formData: any;
  resourceGroupList: any;
  type: 'edge' | 'waypoint' | 'GatewayTypeNotSet';
  subType?: 'AXON-WP' | 'RHSI-EDGE' | 'AXON-EDGE';
  deploymentEnvs: DeploymentEnvironment[] | null;
  onClickEditDeployedInVpcBtn: () => void;
  selectedDepEnv: Item;
  openDeploymentEnvTable: () => void;
  autoDeployed: any;
  cloudsList: Cloud[] | null;
  locationsList: Location[] | null;
}) => {
  const {
    onChange,
    formData,
    onBlur,
    type,
    subType,
    deploymentEnvs,
    onClickEditDeployedInVpcBtn,
    selectedDepEnv,
    openDeploymentEnvTable,
    resourceGroupList,
    autoDeployed,
    cloudsList,
    locationsList,
  } = props;
  const { t } = useTranslation('editGateway');
  const [openDeplEnvTable, setOpenDeplEnvTable] = useState<boolean>(false);
  const [selectedCloud, setSelectedCloud] = useState<string | null>(null);
  const [filteredLocationList, setFilteredLocationList] = useState<
    any[] | null
  >(null);

  useEffect(() => {
    let filterLocations = locationsList?.filter(
      location => location.cloud_id === selectedCloud
    );
    Array.isArray(filterLocations) &&
      setFilteredLocationList([...filterLocations]);
  }, [selectedCloud]);

  // let isDeplEnvPresent =
  //   deploymentEnvs === null ? false : deploymentEnvs.length > 0 ? true : false;

  return (
    <div className='edit-gateway-form'>
      {subType === 'AXON-WP' || 'AXON-EDGE' ? (
        autoDeployed === true ? (
          <>
            <div className='edit-gateway__vpc-details-section'>
              <div className='row edit-gateway__vpc-details__heading'>
                {t('editForm.vpcSection.heading') as string}
              </div>
              <Row className='row'>
                <Column md={3}>
                  <TextInput
                    labelText={t('editForm.vpcSection.name') as string}
                    value={formData?.deploymentEnvName.value}
                    readOnly
                    autoComplete='off'
                    maxLength={60}
                    id='edit-gateway-vpcId'
                  />
                </Column>
              </Row>
            </div>{' '}
            <div className='edit-gateway__gWdetails-section'>
              <div className='edit-gateway__gWdetails-heading'>
                {t('editForm.gWdetails.heading') as string}
              </div>
              <Row className='row'>
                <Column md={3}>
                  <TextInput
                    labelText={t('editForm.gWdetails.name') as string}
                    id='name'
                    value={formData?.name.value}
                    name='name'
                    placeholder={t('editForm.gWdetails.edgePlaceholder')}
                    onChange={e => onChange('name', e.target.value)}
                    invalid={formData?.name.error}
                    invalidText={formData?.name.errorMessage}
                    autoComplete='off'
                    maxLength={60}
                    onBlur={e => onBlur('name', e.target.value)}
                  />
                </Column>
              </Row>
              <Row className='row'>
                <Column md={3}>
                  <TextInput
                    labelText={t('editForm.clusterSection.cloud') as string}
                    id='edit-gateway-cloudId'
                    readOnly
                    value={formData?.cloudName.value.name}
                  />
                </Column>
                <Column md={3}>
                  <TextInput
                    light={true}
                    labelText={t('editForm.clusterSection.location') as string}
                    id='edit-gateway-locationId'
                    readOnly
                    value={formData?.locationName.value.name}
                  />
                </Column>
              </Row>
              <Row className='row'>
                <Column md={6}>
                  <AddLabels
                    id='edit-gateway__gWdetails-add-labels'
                    labelText={t('editForm.gWdetails.label')}
                    placeholder={t('editForm.gWdetails.labelplaceholder')}
                    onChange={data => onChange('labels', data)}
                    btnText={t('editForm.gWdetails.buttonText')}
                    btnKind='secondary'
                    tagType='green'
                    defaultValues={formData.labels.value}
                  />
                </Column>
              </Row>
              <Row className='row'>
                <Column md={6}>
                  <TextArea
                    id='edit-gateway__gWdetails-description'
                    labelText={t('editForm.gWdetails.description')}
                    placeholder={t('editForm.gWdetails.desplaceholder')}
                    name='description'
                    value={formData.description.value}
                    onChange={e => onChange('description', e.target.value)}
                    maxLength={300}
                  />
                </Column>
              </Row>
            </div>
          </>
        ) : (
          // isDeplEnvPresent ? (
          <div className='deploy-in-vpc-form'>
            {/* {formData?.deployedInDeplEnvId?.value?.name === '' &&
            Object.values(selectedDepEnv).every(prop => prop.length === 0) ? ( */}

            {/* <Button
                  kind='tertiary'
                  className='add-deployment-button'
                  renderIcon={Add16}
                  onClick={onClickEditDeployedInVpcBtn}
                >
                  <span className='text'>
                    {t('axonEdgeGateway.selectVpcBtn')}
                  </span>
                </Button> */}

            {/* ) : ( */}
            {formData?.deployedInDeplEnvId?.value?.name !== '' &&
              formData?.deployedInDeplEnvId?.value?.resource_id !== '' && (
                <>
                  <div className='row edit-gateway__vpc-details__heading'>
                    {t('editForm.selectedVpc.heading') as string}
                  </div>
                  <Row className='row deploy-in-vpc-form-row'>
                    <Column md={3} className='flex'>
                      <TextInput
                        labelText={
                          t('editForm.selectedVpc.deployedVPC') as string
                        }
                        id='vpc-name'
                        value={formData?.deployedInDeplEnvId?.value?.name}
                        name='vpc-name'
                        placeholder={''}
                        autoComplete='off'
                        maxLength={60}
                        readOnly
                      />
                      {/* <Button
                      kind='tertiary'
                      className='edit-deployment-button'
                      renderIcon={Edit16}
                      onClick={() => {
                        openDeploymentEnvTable();
                        onClickEditDeployedInVpcBtn();
                      }}
                    >
                      <span className='text'>
                        {t('axonEdgeGateway.form.editBtnLabel')}
                      </span>
                    </Button> */}
                    </Column>
                  </Row>
                </>
              )}

            {/* )} */}
            {/* {Object.values(selectedDepEnv).every(prop => prop.length > 0) && ( */}
            {/* <div className='deploy-in-vpc-form'> */}
            <div className='edit-gateway__gWdetails-section'>
              <div className='edit-gateway__gWdetails-heading'>
                {t('editForm.gWdetails.heading') as string}
              </div>
              <Row className='row'>
                <Column md={3}>
                  <TextInput
                    labelText={t('editForm.gWdetails.name') as string}
                    id='name'
                    value={formData?.name.value}
                    name='name'
                    placeholder={t('editForm.gWdetails.edgePlaceholder')}
                    onChange={e => onChange('name', e.target.value)}
                    invalid={formData?.name.error}
                    invalidText={formData?.name.errorMessage}
                    autoComplete='off'
                    maxLength={60}
                    onBlur={e => onBlur('name', e.target.value)}
                  />
                </Column>
                <Column md={3}>
                  <Dropdown
                    id='infra-resource-group'
                    //name='resourceGroup'
                    className='form-field'
                    items={resourceGroupList ?? []}
                    selectedItem={formData?.resourceGroup?.value as any}
                    itemToString={item => (item ? item.name : '')}
                    onChange={data => {
                      data.selectedItem != null &&
                        setSelectedCloud(data.selectedItem.resource_id);
                      onChange('resourceGroup', {
                        name: data.selectedItem?.name,
                        resource_id: data.selectedItem?.resource_id,
                      });
                    }}
                    titleText={t('axonEdgeGateway.form.resourceGroupLabel')}
                    //placeholder={t('Choose an option')}
                    label={t('Choose an option') as string}
                    //titleText={t('gatewayDetailsForm.cloud.label') as string}
                    //title={t('gatewayDetailsForm.cloud.placeholder') as string}
                    //invalid={formData?.cloudName.error}
                    //invalidText={formData?.cloudName.errorMessage}
                    onBlur={data => onBlur('resourceGroup', data.target)}
                    translateWithId={t}
                  />
                </Column>
              </Row>
              <Row className='row deploy-in-vpc-form-row'>
                <Column md={3}>
                  <Dropdown
                    id='gateway-cloud'
                    className='form-field'
                    items={cloudsList ?? []}
                    itemToString={item => (item ? item.name : '')}
                    onChange={data => {
                      data.selectedItem != null &&
                        setSelectedCloud(data.selectedItem.resource_id);
                      onChange('cloudName', {
                        name: data.selectedItem?.name,
                        resource_id: data.selectedItem?.resource_id,
                      });
                    }}
                    selectedItem={
                      formData?.cloudName?.value.resource_id &&
                      (formData?.cloudName?.value as any)
                    }
                    titleText={t('gatewayDetailsForm.cloud.label')}
                    label={t('gatewayDetailsForm.cloud.placeholder')}
                    invalid={formData?.cloudName.error}
                    invalidText={formData?.cloudName.errorMessage}
                    disabled={
                      Object.values(selectedDepEnv).every(
                        (prop: any) => prop.length > 0
                      ) ||
                      formData?.deployedInDeplEnvId?.value?.name ||
                      formData?.cloudName?.value?.name !== ''
                    }
                    onBlur={data => onBlur('cloudName', data.target)}
                    translateWithId={t}
                  />
                </Column>
                <Column md={3}>
                  <Dropdown
                    id='gateway-location'
                    className='form-field'
                    items={filteredLocationList ?? []}
                    itemToString={item => (item ? item.name : '')}
                    onChange={data => {
                      onChange('locationName', {
                        name: data.selectedItem?.name,
                        resource_id: data.selectedItem?.resource_id,
                      });
                    }}
                    selectedItem={
                      formData?.locationName?.value.resource_id &&
                      (formData?.locationName?.value as any)
                    }
                    label={
                      t('gatewayDetailsForm.location.placeholder') as string
                    }
                    disabled={
                      Object.values(selectedDepEnv).every(
                        (prop: any) => prop.length > 0
                      ) ||
                      formData?.deployedInDeplEnvId?.value?.name ||
                      formData?.cloudName?.value?.name !== ''
                    }
                    titleText={t('gatewayDetailsForm.location.label')}
                    invalid={formData?.locationName.error}
                    invalidText={formData?.locationName.errorMessage}
                    onBlur={data => onBlur('locationName', data.target)}
                    translateWithId={t}
                  />
                </Column>
              </Row>
              <Row className='row'>
                <Column md={6}>
                  <AddLabels
                    id='edit-gateway__gWdetails-add-labels'
                    labelText={t('editForm.gWdetails.label')}
                    placeholder={t('editForm.gWdetails.labelplaceholder')}
                    onChange={data => onChange('labels', data)}
                    btnText={t('editForm.gWdetails.buttonText')}
                    btnKind='secondary'
                    tagType='green'
                    defaultValues={formData.labels.value}
                  />
                </Column>
              </Row>
              <Row className='row'>
                <Column md={6}>
                  <TextArea
                    id='edit-gateway__gWdetails-description'
                    labelText={t('editForm.gWdetails.description')}
                    placeholder={t('editForm.gWdetails.desplaceholder')}
                    name='description'
                    value={formData.description.value}
                    onChange={e => onChange('description', e.target.value)}
                    maxLength={300}
                  />
                </Column>
              </Row>
            </div>
          </div>
        )
      ) : //  : (
      //   <div className='deploy-in-vpc-form'>
      //     {' '}
      //     <Row>
      //       <Column md={3}>
      //         <div className='empty-depl-env-container'>
      //           <div className='no-deployment-env-img'>
      //             {images.noDeploymentEnvsIcon()}
      //           </div>
      //           <div className='empty-message-heading'>
      //             {t('axonEdgeGateway.noDeplEnv.heading')}
      //           </div>
      //           <div className='empty-message-description'>
      //             {t('axonEdgeGateway.noDeplEnv.description')}
      //           </div>
      //         </div>
      //       </Column>
      //     </Row>
      //     <>
      //       <div className='edit-gateway__gWdetails-section'>
      //         <div className='edit-gateway__gWdetails-heading'>
      //           {t('editForm.gWdetails.heading') as string}
      //         </div>
      //         <Row className='row'>
      //           <Column md={3}>
      //             <TextInput
      //               labelText={t('editForm.gWdetails.name') as string}
      //               id='name'
      //               value={formData?.name.value}
      //               name='name'
      //               placeholder={t('editForm.gWdetails.edgePlaceholder')}
      //               onChange={e => onChange('name', e.target.value)}
      //               invalid={formData?.name.error}
      //               invalidText={formData?.name.errorMessage}
      //               autoComplete='off'
      //               maxLength={60}
      //               onBlur={e => onBlur('name', e.target.value)}
      //             />
      //           </Column>
      //           <Column md={3}>
      //             <Dropdown
      //               id='infra-resource-group'
      //               //name='resourceGroup'
      //               className='form-field'
      //               items={resourceGroupList ?? []}
      //               selectedItem={formData?.resourceGroup?.value as any}
      //               itemToString={item => (item ? item.name : '')}
      //               onChange={data => {
      //                 data.selectedItem != null &&
      //                   setSelectedCloud(data.selectedItem.resource_id);
      //                 onChange('resourceGroup', {
      //                   name: data.selectedItem?.name,
      //                   resource_id: data.selectedItem?.resource_id,
      //                 });
      //               }}
      //               titleText={t('axonEdgeGateway.form.resourceGroupLabel')}
      //               //placeholder={t('Choose an option')}
      //               label={t('Choose an option') as string}
      //               //titleText={t('gatewayDetailsForm.cloud.label') as string}
      //               //title={t('gatewayDetailsForm.cloud.placeholder') as string}
      //               //invalid={formData?.cloudName.error}
      //               //invalidText={formData?.cloudName.errorMessage}
      //               onBlur={data => onBlur('resourceGroup', data.target)}
      //               translateWithId={t}
      //             />
      //           </Column>
      //         </Row>
      //         <Row className='row deploy-in-vpc-form-row'>
      //           <Column md={3}>
      //             <Dropdown
      //               id='gateway-cloud'
      //               className='form-field'
      //               items={cloudsList ?? []}
      //               itemToString={item => (item ? item.name : '')}
      //               // onChange={data => {
      //               //   data.selectedItem != null &&
      //               //     setSelectedCloud(data.selectedItem.resource_id);
      //               //   onChange('cloudName', {
      //               //     name: data.selectedItem?.name,
      //               //     resource_id: data.selectedItem?.resource_id,
      //               //   });
      //               // }}

      //               selectedItem={
      //                 formData?.cloudName?.value.resource_id &&
      //                 (formData?.cloudName?.value as any)
      //               }
      //               titleText={t('gatewayDetailsForm.cloud.label')}
      //               label={t('gatewayDetailsForm.cloud.placeholder')}
      //               invalid={formData?.cloudName.error}
      //               invalidText={formData?.cloudName.errorMessage}
      //               disabled={
      //                 Object.values(selectedDepEnv).every(
      //                   (prop: any) => prop.length > 0
      //                 ) ||
      //                 formData?.deployedInDeplEnvId?.value?.name ||
      //                 formData?.cloudName?.value?.name !== ''
      //               }
      //               onBlur={data => onBlur('cloudName', data.target)}
      //               translateWithId={t}
      //             />
      //           </Column>
      //           <Column md={3}>
      //             <Dropdown
      //               id='gateway-location'
      //               className='form-field'
      //               items={filteredLocationList ?? []}
      //               itemToString={item => (item ? item.name : '')}
      //               // onChange={data => {
      //               //   onChange('locationName', {
      //               //     name: data.selectedItem?.name,
      //               //     resource_id: data.selectedItem?.resource_id,
      //               //   });
      //               // }}

      //               selectedItem={
      //                 formData?.locationName?.value.resource_id &&
      //                 (formData?.locationName?.value as any)
      //               }
      //               label={
      //                 t('gatewayDetailsForm.location.placeholder') as string
      //               }
      //               disabled={
      //                 Object.values(selectedDepEnv).every(
      //                   (prop: any) => prop.length > 0
      //                 ) ||
      //                 formData?.deployedInDeplEnvId?.value?.name ||
      //                 formData?.cloudName?.value?.name !== ''
      //               }
      //               titleText={t('gatewayDetailsForm.location.label')}
      //               invalid={formData?.locationName.error}
      //               invalidText={formData?.locationName.errorMessage}
      //               onBlur={data => onBlur('locationName', data.target)}
      //               translateWithId={t}
      //             />
      //           </Column>
      //         </Row>
      //         <Row className='row'>
      //           <Column md={6}>
      //             <AddLabels
      //               id='edit-gateway__gWdetails-add-labels'
      //               labelText={t('editForm.gWdetails.label')}
      //               placeholder={t('editForm.gWdetails.labelplaceholder')}
      //               onChange={data => onChange('labels', data)}
      //               btnText={t('editForm.gWdetails.buttonText')}
      //               btnKind='secondary'
      //               tagType='green'
      //               defaultValues={formData.labels.value}
      //             />
      //           </Column>
      //         </Row>
      //         <Row className='row'>
      //           <Column md={6}>
      //             <TextArea
      //               id='edit-gateway__gWdetails-description'
      //               labelText={t('editForm.gWdetails.description')}
      //               placeholder={t('editForm.gWdetails.desplaceholder')}
      //               name='description'
      //               value={formData.description.value}
      //               onChange={e => onChange('description', e.target.value)}
      //               maxLength={300}
      //             />
      //           </Column>
      //         </Row>
      //       </div>
      //     </>
      //   </div>
      // )
      //)
      null}
    </div>
  );
};
export default MeshGateway;
