import { Row } from 'carbon-components-react';
import './GenericTableTitle.scss';

const GenericTableTitle = (props: { title: string; description?: string }) => {
  const { title, description } = props;
  return (
    <div className='generic-table-title'>
      <Row>
        <div className='title' data-testid='table-title'>
          {title}
        </div>
        {description ? (
          <div className='description' data-testid='table-description'>
            {description}
          </div>
        ) : null}
      </Row>
    </div>
  );
};
export default GenericTableTitle;
