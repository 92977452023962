import { VisibilityFlags } from '../lib/enums';
import { Cloud, CloudData, Location } from '../models/master';
import { axiosInstance } from './api';
import { MCNM_NETWORK_API_URL } from './config';

interface createlocationProps {
  cloudId: string;
  locationData: any;
}

interface updateLocationProps {
  cloudId: string;
  locationId: string;
  data: any;
}

interface deleteLocationProps {
  cloudId: string;
  locationId: string;
}

export const getAllLocations = async (
  view: string = VisibilityFlags.MANAGED
) => {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/cloud`,
    });
    let locationsData: Location[] = [];
    const cloudList: Cloud[] = response?.data?.clouds ?? [];
    let locationPermissionError;

    await Promise.all(
      response?.data?.clouds?.map(async (cloud: CloudData) => {
        const cloudId = cloud?.resource_id;
        let cloudLocations: Location[] = [];
        await getLocations(cloudId, view)
          .then(response => {
            cloudLocations = response ?? [];
          })
          .catch(error => {
            if (error.response!?.status === 403) {
              locationPermissionError = error;
            } else {
              throw error;
            }
          });
        if (Array.isArray(cloudLocations) && cloudLocations?.length > 0) {
          cloudLocations.forEach(eachLocation => {
            eachLocation.cloud_name = cloud?.name;
          });
          locationsData = [...locationsData, ...cloudLocations];
        }
        return [];
      })
    );

    if (
      locationPermissionError &&
      Array.isArray(locationsData) &&
      locationsData?.length === 0
    ) {
      throw locationPermissionError;
    }

    return { locationsData: locationsData, cloudList: cloudList };
  } catch (error) {
    console.log('api error: ', error);
    throw error;
  }
};

export const getLocations = async (
  cloud_id: string,
  view: string = VisibilityFlags.ALL
) => {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloud_id}/locations`,
      params: {
        visibility: view,
      },
    });

    return response.data?.locations;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

//Get location details
export const getLocationDetails = async (
  cloudId: string,
  locationId: string,
  view = VisibilityFlags.ALL
) => {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloudId}/locations/${locationId}`,
      params: {
        visibility: view, // gets back all locations (managed and unmanaged) by default
      },
    });

    return response.data;
  } catch (error) {
    console.log('error in GET  locations details', error);
    throw error;
  }
};

//Create a new location that MCNM should oversee
export async function createLocation({
  cloudId,
  locationData,
}: createlocationProps) {
  try {
    const response = await axiosInstance({
      method: 'POST',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloudId}/locations`,
      data: locationData,
    });

    return response.data;
  } catch (error) {
    console.log('Error creating location', error);
    throw error;
  }
}

//Update an existing location
export const updateLocation = async ({
  cloudId,
  locationId,
  data,
}: updateLocationProps) => {
  try {
    const response = await axiosInstance({
      method: 'PUT',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloudId}/locations/${locationId}`,
      data,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

//Delete an existing location
export const deleteLocation = async ({
  cloudId,
  locationId,
}: deleteLocationProps) => {
  try {
    const response = await axiosInstance({
      method: 'DELETE',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloudId}/locations/${locationId}`,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
