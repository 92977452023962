import React, { useEffect } from 'react';
import {
  TextInput,
  Column,
  Row,
  Dropdown,
  Modal,
  Tile,
  Button,
} from 'carbon-components-react';
import { Close16, Location16 } from '@carbon/icons-react';
import { useTranslation } from 'react-i18next';

import useAnalytics from '../../../lib/useAnalytics';
import analyticsData from '../../../lib/analyticsEventData';

import { Cloud, Location } from '../../../models/master';
import { FromModule } from '../../../lib/enums';

import AddressSearch from '../../../components/AddressSearch/AddressSearch';
import AddLabels from '../../../components/AddLabels/AddLabels';
import GenericManageButton from '../../../components/GenericManageButton/GenericManageButton';

import { FormData } from './RegisterLocation';

import './RegisterLocationForm.scss';
import { getThirdPartyCloudIconsDropdown } from '../../Cloud/ThirdPartyCloudIcons';

interface Props {
  formData: FormData;
  onChange: (name: string, value: any) => void;
  showCancelModal: boolean;
  setShowCancelModal: (val: boolean) => void;
  onClose: () => void;
  onCloudSelect: (cloud: any) => void;
  cloudList: any;
  cloudRegionList: string[];
  onCloudTypeSelect: (type: { label: string; code: string }) => void;
  onCloudRegionSelect: (cloudRegion: string) => void;
  setSelectedAddress: (val: any) => void;
  selectedAddress: any;
  actionType?: boolean;
  locationDetails?: Location;
  handleCheckbox: (name: string, value: any) => void;
  isRegister: boolean;
  isCheckboxEnabled: boolean;
  haveZone?: boolean;
  fromModule?: FromModule;
}

const RegisterLocationForm: React.FC<Props> = ({
  formData,
  onChange,
  showCancelModal,
  setShowCancelModal,
  onClose,
  cloudList,
  cloudRegionList,
  onCloudSelect,
  onCloudTypeSelect,
  onCloudRegionSelect,
  selectedAddress,
  setSelectedAddress,
  actionType,
  handleCheckbox,
  isRegister,
  isCheckboxEnabled,
  locationDetails,
  haveZone = false,
  fromModule = FromModule.LOCATION,
}) => {
  const { t } = useTranslation('registerLocation');
  const { trackButtonClicked } = useAnalytics();

  const typeList = [
    {
      label: t('site'),
      code: 'site',
    },
    {
      label: t('region'),
      code: 'region',
    },
    {
      label: t('zone'),
      code: 'zone',
    },
  ];

  useEffect(() => {
    if (!isRegister && locationDetails?.unmanaged) {
      const timer = setTimeout(() => {
        handleCheckbox('managed', true);
      }, 1000);
      return () => clearTimeout(timer);
    }
    // Clear the timer if component unmounts or if the dependency changes before timeout
  }, []);

  const onAddressSelect = (addressData: any) => {
    setSelectedAddress(addressData);
  };

  const getCloudOptionsIcon = (
    cloudList: Cloud[] | [],
    isRegister: boolean
  ) => {
    let cloudData =
      cloudList && Array.isArray(cloudList)
        ? isRegister
          ? cloudList?.filter((cloud: Cloud) => cloud?.auto_discover !== true)
          : cloudList
        : [];
    let item: any = [];
    cloudData.forEach(cloud => {
      item.push({
        ...cloud,
        cloudName: cloud?.name,
        cloudElement: (
          <div className='dropdown-icon'>
            <span
              className={`icon-filter-dropdown ${
                cloud?.type && `option-type-${cloud?.type}`
              }`}
            >
              {cloud?.type && getThirdPartyCloudIconsDropdown(cloud?.type)}
            </span>
            <span className='cloud-name'>{cloud && cloud?.name}</span>
          </div>
        ),
      });
    });
    return item;
  };

  const getCloudOptionsIconNoEdit = (value: string, cloudList: any) => {
    let cloud =
      cloudList && cloudList?.find((cloud: any) => cloud?.name === value);

    return (
      <span
        className={`icon-filter-dropdown ${
          cloud?.type && `option-type-${cloud?.type}`
        }`}
      >
        {cloud?.type && getThirdPartyCloudIconsDropdown(cloud?.type)}
      </span>
    );
  };
  const showTooltip: boolean = (!isRegister && !isCheckboxEnabled) || haveZone;
  const toolTipText: string =
    haveZone && !isRegister && !isCheckboxEnabled
      ? t('tooltiplabelUnmanaged') + t('tooltipZoneLocationText')
      : haveZone
      ? t('tooltipZoneLocationText')
      : !isRegister && !isCheckboxEnabled
      ? t('tooltiplabelUnmanaged')
      : '';

  return (
    <div className='register-location-body'>
      <Row className='row sub-header-section'>
        <Column md={4} className='sub-header'>
          {t('subHeader')}
        </Column>
        <Column md={2}>
          <GenericManageButton
            handleCheckbox={(checked: boolean) => {
              handleCheckbox('managed', checked);
            }}
            labelText={t('manage')}
            id='manageCheckbox-location'
            isCheckboxDisabled={isRegister || !isCheckboxEnabled || haveZone}
            checked={(formData as FormData) && formData.managed}
            showTooltip={showTooltip}
            toolTipText={toolTipText}
          />
        </Column>
      </Row>
      <div className='register-location-form'>
        <>
          <Row>
            <Column md={3} className='col-location-name'>
              <TextInput
                id='addLocationName'
                name='name'
                className='location-name'
                placeholder={t('form.name.placeholder')}
                labelText={t('form.name.label') as string}
                value={formData.name.value}
                onChange={e => onChange('name', e.target.value)}
                invalid={formData.name.error}
                invalidText={formData.name.errorMessage}
                maxLength={60}
                readOnly={locationDetails?.is_discovered}
              />
            </Column>
            {!isRegister || fromModule === FromModule.CLOUD ? (
              <Column md={3} className='col-location-noEdit-cloud'>
                <TextInput
                  labelText={t('form.cloud.label') as string}
                  id='readOnly-field'
                  name='name'
                  autoComplete='off'
                  maxLength={45}
                  value={formData?.cloudSelect?.value as any}
                  readOnly
                />
                <>
                  {getCloudOptionsIconNoEdit(
                    formData?.cloudSelect?.value,
                    cloudList
                  )}
                </>
              </Column>
            ) : (
              <Column md={3} className='col-location-cloud'>
                <Dropdown
                  id='addLocationCloud'
                  titleText={t('form.selectCloud.label')}
                  label={t('form.selectCloud.placeholder') as string}
                  items={
                    cloudList ? getCloudOptionsIcon(cloudList, isRegister) : []
                  }
                  itemToString={(item: any) => item?.cloudName}
                  itemToElement={(item: any) => item?.cloudElement}
                  translateWithId={t}
                  onChange={data => onCloudSelect(data.selectedItem)}
                  selectedItem={formData.cloudSelect.value}
                  renderSelectedItem={(item: any) => item?.cloudElement}
                />
              </Column>
            )}
          </Row>
          <Row>
            {locationDetails?.is_discovered || haveZone ? (
              <Column md={3} className='col-location-noEdit-type'>
                <TextInput
                  labelText={t('form.type.label') as string}
                  id='readOnly-field'
                  name='name'
                  autoComplete='off'
                  maxLength={45}
                  value={formData?.cloudType?.value?.label}
                  readOnly
                />
              </Column>
            ) : (
              <Column md={3} className='col-location-type'>
                <Dropdown
                  id='addLocationType'
                  label={t('form.type.placeholder') as string}
                  className={
                    'add-location-type ' +
                    (cloudRegionList.length === 0 && 'zone-dropdownItem')
                  }
                  items={typeList}
                  itemToString={(item: any) => (item ? item?.label : '')}
                  translateWithId={t}
                  titleText={t('form.type.label')}
                  onChange={data => {
                    if (!isRegister)
                      trackButtonClicked(
                        analyticsData['Locations'].events.locationTypeSelected
                          .props,
                        analyticsData['Locations'].events.locationTypeSelected
                          .event
                      );
                    onCloudTypeSelect(data.selectedItem);
                  }}
                  selectedItem={formData.cloudType.value}
                />
              </Column>
            )}
            {formData?.cloudRegion?.isVisible && (
              <Column md={3} className='col-location-region'>
                <Dropdown
                  id='addLocationRegion'
                  titleText={t('form.region.label')}
                  label={t('form.region.placeholder') as string}
                  items={cloudRegionList}
                  itemToString={(item: any) => (item ? item : '')}
                  translateWithId={t}
                  //onBlur={e => onCloudRegionSelect(e?.selectedItem)}
                  onChange={e => onCloudRegionSelect(e.selectedItem)}
                  selectedItem={formData.cloudRegion.value}
                />
              </Column>
            )}
          </Row>
        </>

        <Row>
          <Column md={6}>
            <label className='address-label'>{t('addressLabel')}</label>
            {!selectedAddress ? (
              <AddressSearch name='address' onSelect={onAddressSelect} />
            ) : (
              <Tile className='address-tile'>
                <div className='address-line'>
                  <div className='address-location'>
                    <span className='location-icon'>
                      <Location16 />
                    </span>
                    <span className='sub-header'>{selectedAddress?.name}</span>
                  </div>
                  <div className='address-close-icon'>
                    {actionType && (
                      <Button
                        renderIcon={Close16}
                        kind='ghost'
                        onClick={() =>
                          !locationDetails?.is_discovered &&
                          setSelectedAddress(null)
                        }
                        hasIconOnly
                      />
                    )}
                  </div>
                </div>
                <Row className='address-sub-row'>
                  <Column>
                    <label className='address-label '>
                      {t('form.address.address')}
                    </label>
                    <div>{selectedAddress?.street_address || '-'}</div>
                  </Column>
                  <Column>
                    <label className='address-label '>
                      {t('form.address.city')}
                    </label>
                    <div>{selectedAddress?.city || '-'}</div>
                  </Column>
                  <Column>
                    <label className='address-label '>
                      {t('form.address.state')}
                    </label>
                    <div>{selectedAddress?.state || '-'}</div>
                  </Column>
                  <Column>
                    <label className='address-label '>
                      {t('form.address.country')}
                    </label>
                    <div>{selectedAddress?.country || '-'}</div>
                  </Column>
                  <Column>
                    <label className='address-label '>
                      {t('form.address.coordinates')}
                    </label>
                    <div>{selectedAddress?.coordinates || '-'}</div>
                  </Column>
                </Row>
              </Tile>
            )}
          </Column>
        </Row>
        <Row>
          <Column md={4}>
            <AddLabels
              id='addLocationLabels'
              labelText={t('form.labels.label')}
              placeholder={t('form.labels.placeholder')}
              onChange={data => onChange('labels', data)}
              btnText={t('form.labels.buttonText')}
              btnKind='secondary'
              tagType='green'
              defaultValues={formData.labels.value}
            />
          </Column>
        </Row>
        <Modal
          className='cancel-location-modal'
          size='xs'
          danger
          modalHeading={t('cancelModal.title')}
          onRequestClose={() => setShowCancelModal(false)}
          onRequestSubmit={() => onClose()}
          primaryButtonText={
            isRegister
              ? t('cancelModal.primaryaddButton')
              : t('cancelModal.primaryeditButton')
          }
          secondaryButtonText={t('cancelModal.secondaryButton')}
          open={showCancelModal}
        >
          {t('cancelModal.description')}
        </Modal>
      </div>
    </div>
  );
};

export default RegisterLocationForm;
