import React from 'react';

import './Error403Card.scss';
import images from '../../images/images';
import { useTranslation } from 'react-i18next';

const Error403Card = () => {
  const { t } = useTranslation('error403Card');

  const icon = images.notAuthorizedSvg();
  const header = t('403Error.403header');
  const description = t('403Error.403description');

  return (
    <div className='error403-state'>
      <div className='error403-state-icon'>{icon}</div>
      <div className='header'>{header}</div>
      <div className='description'>{description}</div>
    </div>
  );
};

export default Error403Card;
