export const removeExtraSpace = (str: string) => {
  if (!str) return str;

  return str.trim().split(/ +/).join(' ');
};

// Truncation for text without tooltip
export const truncateWithoutTooltip = (
  str: string,
  maxLength: number,
  limit: number
) => {
  if (!str) return str;

  if (str.length >= maxLength) {
    return str.slice(0, limit) + '...' + str.slice(-limit);
  }

  return str;
};

export const getConditionalSecrets = (
  data: any,
  selectedCloudType: string | null,
  deploymentEnvType?: string | null
) => {
  if (data?.secrets && selectedCloudType) {
    const secretValues = data.secrets.reduce(
      (secretArray: any, secret: any) => {
        if (
          (deploymentEnvType === 'cluster' ||
            deploymentEnvType === 'namespace') &&
          secret.type === 'credentials-k8s'
        ) {
          secretArray = [...secretArray, secret];
        }
        if (
          selectedCloudType === 'other' &&
          secret.type === 'credentials-k8s'
        ) {
          secretArray = [...secretArray, secret];
        }
        if (selectedCloudType === 'AWS' && secret.type === 'cloud-aws') {
          secretArray = [...secretArray, secret];
        } else if (
          selectedCloudType === 'IBM' &&
          (secret.type === 'cloud-ibm' || secret.type === 'cloud-ibmcloud')
        ) {
          secretArray = [...secretArray, secret];
        } else if (
          selectedCloudType === 'AZURE' &&
          secret.type === 'cloud-azure'
        ) {
          secretArray = [...secretArray, secret];
        } else {
          secretArray = [...secretArray];
        }
        return secretArray;
      },
      []
    );
    return secretValues;
  }
};

export const removeFiltersFromStorage = () => {
  setSessionStorageItem('MCNM_FILTERS_APPLIED', '');
  setSessionStorageItem('MCNM_SEARCH_FILTER_VALUE', '');
  setSessionStorageItem('MCNM_VISIBILITY_FLAG', '');
};

export const removeViewStateFromStorage = () => {
  window.localStorage.setItem('VIEW_STATE', '');
};

export const manageFiltersFromStorage = () => {
  let currentfilterApplied = [];
  const filterApply = getSessionStorageItem('MCNM_FILTERS_APPLIED');
  if (filterApply) {
    currentfilterApplied = JSON.parse(filterApply);
  }
  return currentfilterApplied;
};

export const capitalizeFirstLetter = (text: string) => {
  return text?.charAt(0)?.toUpperCase() + text?.slice(1);
};

export const convertMetricsValueInUnits = (
  value: number,
  toFixed: number = 3
) => {
  return parseFloat((Number(value) / Math.pow(1000, 1)).toFixed(toFixed));
};

export const getBrowserType = () => {
  if (
    (navigator.userAgent.indexOf('Opera') ||
      navigator.userAgent.indexOf('OPR')) !== -1
  ) {
    return 'Opera';
  } else if (navigator.userAgent.indexOf('Edg') !== -1) {
    return 'Edge';
  } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
    return 'Chrome';
  } else if (navigator.userAgent.indexOf('Safari') !== -1) {
    return 'Safari';
  } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
    return 'Firefox';
  }

  return 'unknown';
};

export const getCookie = (cName: string) => {
  const name = cName + '=';
  const cDecoded = decodeURIComponent(document.cookie); //to be careful
  const cArr = cDecoded.split('; ');
  let res;
  cArr.forEach(val => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  });
  return res;
};

export const isFunctionalCookieAccepted = () => {
  const cookiesSelected = getCookie('notice_gdpr_prefs') ?? '';
  const cookiesList = cookiesSelected?.split(':');
  return (
    Array.isArray(cookiesList) &&
    cookiesList?.length > 0 &&
    cookiesList[0]?.split('|').includes('1')
  );
};

export const setSessionStorageItem = (key: string, value: string) => {
  try {
    window.sessionStorage.setItem(key, value);
  } catch (error) {
    console.log(error);
  }
};

export const getSessionStorageItem = (key: string) => {
  try {
    return window.sessionStorage.getItem(key);
  } catch (error) {
    console.log(error);
  }
};

//To get undique labels from labels and discovered_labels
export const getCombinedLabelsForFilter = (
  labels: string[] | null,
  discoverdLabels: string[] | null
) => {
  let combineLabelsList: any = [];

  Array.isArray(labels) &&
    labels.forEach(label => {
      combineLabelsList.push(label);
    });

  Array.isArray(discoverdLabels) &&
    discoverdLabels.forEach(discoverdLabel => {
      combineLabelsList.push(discoverdLabel);
    });

  return combineLabelsList;
};

/**
 * Exports data in csv format
 * @param data
 * @param fileName
 */
export const exportCSV = <T>(data: T[], fileName: string) => {
  if (!data || data.length === 0) return;
  const titleKeys = Object.keys(data[0] as object);
  const refinedData = [];
  refinedData.push(titleKeys);

  data.forEach((item: any) => {
    refinedData.push(Object.values(item));
  });

  let csvContent = '';

  refinedData.forEach(row => {
    csvContent += row.join(',') + '\n';
  });

  const url = window.URL.createObjectURL(new Blob([csvContent]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
