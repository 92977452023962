import React, { useEffect, useState } from 'react';
import { TextArea, TextInput } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import AddLabels from '../../../components/AddLabels/AddLabels';
import './EditRoleDetails.scss';

const EditRoleDetailsForm = (props: {
  onChange: (name: string, value: any) => void;
  formData: any;
}) => {
  const { onChange, formData } = props;
  const { t } = useTranslation('roleDetails');

  return (
    <div className='edit-role-form'>
      <TextInput
        id='editRoleName'
        className='form-name-field'
        name='name'
        labelText={t<string>('editRoleDetails.form.nameField.label')}
        placeholder={t<string>('editRoleDetails.form.nameField.placeHolder')}
        value={formData.name.value}
        onChange={e => onChange('name', e.target.value)}
        invalid={formData.name.error}
        invalidText={formData.name.errorMessage}
        maxLength={60}
      />

      <TextArea
        id='editRoleDescription'
        name='description'
        className='form-description-field'
        autoComplete='off'
        labelText={t('editRoleDetails.form.descriptionField.label')}
        placeholder={t('editRoleDetails.form.descriptionField.placeHolder')}
        onChange={e => onChange('description', e.target.value)}
        value={formData.description.value}
        maxCount={300}
        enableCounter={true}
      />

      <AddLabels
        id='editRoleLabels'
        labelText={t('editRoleDetails.form.labelsField.label')}
        placeholder={t('editRoleDetails.form.labelsField.placeHolder')}
        onChange={data => onChange('labels', data)}
        btnText={t('editRoleDetails.form.labelsField.buttonText')}
        btnKind='secondary'
        tagType='green'
        defaultValues={formData.labels.value}
      />
    </div>
  );
};

export default EditRoleDetailsForm;
