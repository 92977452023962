import React, { useContext } from 'react';
import { ToastNotification } from 'carbon-components-react';
import { NotificationContext } from '../Context/NotificationProvider';

import './Notification.scss';

const Notification = () => {
  const notification = useContext(NotificationContext);
  const {
    kind = 'success',
    subtitle,
    title,
    timeout = 5000,
    lowContrast = true,
    customClassName = '',
  } = notification.details;

  return (
    <>
      {notification.notificationType === 'TOAST' && (
        <div className='toast-notification-container'>
          <ToastNotification
            kind={kind}
            subtitle={subtitle}
            title={title}
            lowContrast={lowContrast}
            onClose={notification.onClose as any}
            timeout={timeout}
            data-testid='toast-notification'
            className={customClassName}
          ></ToastNotification>
        </div>
      )}
    </>
  );
};

export default Notification;
