import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import MultiStepTearsheet from '../../../components/MultiStepTearSheet/MultiStepTearSheet';
import RoleDetails from './RoleDetails/RoleDetails';
import RolePermissions from './RolePermissions/RolePermissions';
import { getResourceGroups } from '../../../controllers/resourceGroupApi';
import { addRole } from '../../../controllers/roleApis';
import { NotificationContext } from '../../../components/Notifications/Context/NotificationProvider';

import { ResourceGroup, Role } from '../../../models/master';
import { removeExtraSpace } from '../../../lib/utils';
import InlineNotification from '../../../components/Notifications/Inline/Notification';
import {
  getResourceGroupColumns,
  getResourceTypePermissionsFormData,
  AccessProperty,
  ResourceGroupData,
} from './RolePermissions/config';

import { AxiosError } from 'axios';
import useAnalytics from '../../../lib/useAnalytics';
import './CreateRole.scss';
import analyticsData from '../../../lib/analyticsEventData';

interface Props {
  open: boolean;
  onClose: () => void;
  rolesData: Role[] | null;
  refreshData: () => void;
}

export interface FormData {
  name: {
    value: string;
    error: boolean;
    errorMessage: string;
  };
  description: {
    value: string;
  };
  labels: {
    value: string[];
  };
}

const defaultFormData = {
  name: {
    value: '',
    error: false,
    errorMessage: '',
  },
  description: {
    value: '',
  },
  labels: {
    value: [],
  },
};

const CreateRole: React.FC<Props> = ({
  open,
  onClose,
  rolesData,
  refreshData,
}) => {
  const [formData, setFormData] = useState<FormData>(defaultFormData);
  const [resourceTypePermissionsData, setResourceTypePermissionsData] =
    useState<any>({});
  const [resourceGroupPermissionsData, setResourceGroupPermissionsData] =
    useState<any>({});
  const [showFailNotification, toggleFailNotification] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [subTitleErrorMsg, setSubTitleErrorMsg] = useState('');

  const notification = useContext(NotificationContext);
  const { trackButtonClicked } = useAnalytics();

  const { t } = useTranslation('createRole');
  const resourceGroupColumns = getResourceGroupColumns(t)[0];

  useEffect(() => {
    fetchResourceGroups();
    fetchResourceTypes();
  }, []);

  const fetchResourceGroups = async () => {
    try {
      const groupsResponse = await getResourceGroups();
      const resourceGroups = groupsResponse.resource_groups;
      const applicationGroups = resourceGroups?.filter(
        (group: ResourceGroup) => group?.type === 'application'
      );
      const applicationGroupsTableData = applicationGroups.map(
        (applicationGroup: ResourceGroup) => {
          return { ...applicationGroup, ...resourceGroupColumns };
        }
      );
      let infraGroups = resourceGroups?.filter(
        (group: ResourceGroup) => group?.type === 'infrastructure'
      );
      const infrastructureGroupsTableData = infraGroups.map(
        (infraGroup: ResourceGroup) => {
          return { ...infraGroup, ...resourceGroupColumns };
        }
      );
      setResourceGroupPermissionsData({
        applicationGroup: applicationGroupsTableData,
        infrastructureGroup: infrastructureGroupsTableData,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const fetchResourceTypes = () => {
    const resourceTypePermissionsFormData =
      getResourceTypePermissionsFormData(t);
    setResourceTypePermissionsData(resourceTypePermissionsFormData[0]);
  };

  const closeTearsheet = () => {
    onClose();
    toggleFailNotification(false);
    setAuthError(false);
  };

  const handleOnChange = (name: string, value: any) => {
    if (name === 'name') {
      const nameEmpty = value?.trim()?.length > 0 ? false : true;

      const notUniqueName =
        rolesData &&
        rolesData.filter((role: any) => role?.name?.trim() === value?.trim())
          .length > 0
          ? true
          : false;

      const error = nameEmpty || notUniqueName;
      const message = nameEmpty
        ? t('nameEmpty')
        : notUniqueName
        ? t('nameExists')
        : '';

      setFormData({
        ...formData,
        [name]: {
          value,
          error: error,
          errorMessage: message,
        },
      });
    } else {
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: {
          value,
        },
      }));
    }
  };

  const checkFormValid = () => {
    return formData?.name?.value !== '' && !formData?.name?.error;
  };

  // All accesses are true for any resource, access_type should be '*'
  const checkWildCardAccess = (
    resourceAccessKeys: string[],
    resourceItem: AccessProperty
  ) => {
    return resourceAccessKeys?.every(
      (accessProperty: string) =>
        resourceItem[accessProperty as keyof AccessProperty].value === true
    );
  };

  const parsePermissionsData = () => {
    let permissions: any = [];
    let isWildCardAccessType = false;

    //parse resource type permissions data
    resourceTypePermissionsData &&
      Object.keys(resourceTypePermissionsData).forEach((typeHeader: string) => {
        const resourceTypes = resourceTypePermissionsData[typeHeader];
        const resourceKeys = Object.keys(resourceTypes);
        resourceKeys?.forEach((resource: string) => {
          const resourceItems = resourceTypes[resource];
          const resourceType = resourceItems.type;
          const resourceAccessKeys = Object.keys(
            resourceItems.accessProperties
          );
          isWildCardAccessType = checkWildCardAccess(
            resourceAccessKeys,
            resourceItems.accessProperties
          );
          isWildCardAccessType
            ? (permissions = [
                ...permissions,
                {
                  access_type: '*',
                  subject_resource_type: resourceType,
                },
              ])
            : resourceAccessKeys?.forEach((accessProperty: string) => {
                if (resourceItems.accessProperties[accessProperty].value) {
                  permissions = [
                    ...permissions,
                    {
                      access_type: accessProperty.toUpperCase(),
                      subject_resource_type: resourceType,
                    },
                  ];
                }
              });
        });
      });

    //parse resource group permissions data
    resourceGroupPermissionsData &&
      Object.keys(resourceGroupPermissionsData).forEach(
        (resourceHeader: string) => {
          const resourceGroups = resourceGroupPermissionsData[resourceHeader];
          resourceGroups &&
            resourceGroups.forEach((resource: ResourceGroupData) => {
              const groupKeys = Object.keys(resource.groups);
              const resourceKeys = Object.keys(resource.resources);

              isWildCardAccessType = checkWildCardAccess(
                groupKeys,
                resource.groups
              );
              isWildCardAccessType
                ? (permissions = [
                    ...permissions,
                    {
                      access_type: '*',
                      subject_resource_id: resource.resource_id,
                      subject_resource_type: 'resourcegroup',
                    },
                  ])
                : groupKeys.forEach((accessProperty: string) => {
                    if (
                      resource.groups[accessProperty as keyof AccessProperty]
                        .value
                    ) {
                      permissions = [
                        ...permissions,
                        {
                          access_type: accessProperty.toUpperCase(),
                          subject_resource_id: resource.resource_id,
                          subject_resource_type: 'resourcegroup',
                        },
                      ];
                    }
                  });

              isWildCardAccessType = checkWildCardAccess(
                resourceKeys,
                resource.resources
              );
              isWildCardAccessType
                ? (permissions = [
                    ...permissions,
                    {
                      access_type: '*',
                      subject_resource_group_id: resource.resource_id,
                      subject_resource_type: '*',
                    },
                  ])
                : resourceKeys.forEach((accessProperty: string) => {
                    if (
                      resource.resources[accessProperty as keyof AccessProperty]
                        .value
                    ) {
                      permissions = [
                        ...permissions,
                        {
                          access_type: accessProperty.toUpperCase(),
                          subject_resource_group_id: resource.resource_id,
                          subject_resource_type: '*',
                        },
                      ];
                    }
                  });
            });
        }
      );

    return permissions;
  };

  const handleSubmit = async () => {
    trackButtonClicked(
      analyticsData['Admin Roles'].events.createRoleBtn.props,
      analyticsData['Admin Roles'].events.createRoleBtn.event
    );

    const permissionsData = parsePermissionsData();

    const data = {
      ...formData,
      name: removeExtraSpace(formData.name.value),
      description: formData.description.value,
      labels: formData.labels.value,
      permissions: permissionsData,
    };

    try {
      const role = await addRole(data);

      // Trigger success toastbar
      notification.onTrigger('TOAST', {
        title: t('notification.title', { name: role.name }),
        subtitle: t('notification.subtitle'),
      });

      refreshData();
    } catch (error: any) {
      const err = error as AxiosError;
      const errorMessage: string =
        error.response !== undefined
          ? error.response['customErrorMessage']
          : '';

      if (err.response?.status === 403) {
        toggleFailNotification(true);
        setAuthError(true);
        return Promise.reject(() => console.log(error));
      }

      errorMessage.length > 0 && setSubTitleErrorMsg(errorMessage);
      toggleFailNotification(true);
    }
  };

  const handleErrorBarClose = () => {
    toggleFailNotification(false);
    setAuthError(false);
  };

  return (
    <div>
      <MultiStepTearsheet
        className='create-role-tearsheet'
        submitButtonText={t('submitButtonText')}
        cancelButtonText={t('cancelButtonText')}
        backButtonText={t('backButtonText')}
        nextButtonText={t('nextButtonText')}
        description={t('description')}
        title={t('title')}
        open={open}
        onClose={closeTearsheet}
        onRequestSubmit={handleSubmit}
      >
        <RoleDetails
          onChange={handleOnChange}
          formData={formData}
          formValid={checkFormValid()}
        >
          {showFailNotification && (
            <InlineNotification
              onClose={() => handleErrorBarClose() as any}
              kind={'error'}
              title={
                authError
                  ? (t('failureNotification.authTitle') as string)
                  : (t('failureNotification.title') as string)
              }
              subtitle={
                authError
                  ? (t('failureNotification.authSubtitle') as string)
                  : subTitleErrorMsg
              }
            />
          )}
        </RoleDetails>
        <RolePermissions
          resourceGroupPermissionsData={resourceGroupPermissionsData}
          updateResourceGroupPermissionsData={setResourceGroupPermissionsData}
          resourceTypePermissionsData={resourceTypePermissionsData}
          updateResourceTypePermissionsData={setResourceTypePermissionsData}
          disableSubmit={!parsePermissionsData()?.length}
        />
      </MultiStepTearsheet>
    </div>
  );
};

export default CreateRole;
