import React from 'react';
import { Accordion, AccordionItem } from 'carbon-components-react';
import './SidePanelDetailsComponent.scss';

type Props = {
  children?: React.ReactNode | React.ReactNode[];
  title: string | JSX.Element;
};

const SidePanelDetailsComponent: React.FC<Props> = ({ children, title }) => {
  return (
    <Accordion className='detailsAccordion'>
      <AccordionItem title={title} open={true}>
        {children}
      </AccordionItem>
    </Accordion>
  );
};
export default SidePanelDetailsComponent;
