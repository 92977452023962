import React from 'react';
import { CreateTearsheet } from '@carbon/ibm-products';

import './MultiStepTearSheet.scss';

interface Props {
  submitButtonText: string;
  cancelButtonText: string;
  backButtonText: string;
  nextButtonText: string;
  description: string;
  label?: string;
  dataTestId?: string;
  title: string | JSX.Element;
  open: boolean;
  isSubmitting?: boolean;
  onClose: () => void;
  onRequestSubmit?: () => void;
  className?: string;
  initialStep?: number;
}

const MultiStepTearSheetComponent: React.FC<Props> = ({
  submitButtonText,
  cancelButtonText,
  backButtonText,
  nextButtonText,
  description,
  label = '',
  dataTestId = 'multistep-tearsheet',
  title,
  open,
  onClose,
  onRequestSubmit = () => {},
  children,
  className = '',
  initialStep = 1,
  isSubmitting,
}) => {
  return (
    <CreateTearsheet
      className={`multistep-tearsheet-component-container ${className}`}
      submitButtonText={submitButtonText}
      cancelButtonText={cancelButtonText}
      backButtonText={backButtonText}
      nextButtonText={nextButtonText}
      description={description}
      label={label}
      title={title}
      open={open}
      onClose={onClose}
      onRequestSubmit={onRequestSubmit}
      initialStep={initialStep}
      data-testid={dataTestId}
      isSubmitting={isSubmitting}
    >
      {children}
    </CreateTearsheet>
  );
};

export default MultiStepTearSheetComponent;
