import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SkeletonPlaceholder,
  InlineNotificationProps,
  Button,
  Modal,
  TooltipDefinition,
} from 'carbon-components-react';

import AddApplication from '../ApplicationsContainer/AddApplication/AddApplication';
import DetailsCard from '../../components/DetailsCard/DetailsCard';
import ServicesTile from './ServicesTile/ServicesTile';
import Header from '../../components/Header/Header';
import DeploymentsTable from '../../components/DeploymentsTable/DeploymentsTable';
import {
  getApplication,
  getDeployments,
  getAllDeploymentInstance,
  deleteApplicationService,
  deleteApplication,
  getApplications,
} from '../../controllers/applicationApis';
import { getNetworkSegments } from '../../controllers/networksegmentsApi.js';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import {
  getDeploymentEnvs,
  getDeploymentEnvSubtypes,
  getPartitions,
} from '../../controllers/deploymentEnv.js';
import {
  ApplicationData,
  Service,
  ServiceData,
  Deployment,
  DeploymentData,
  DeploymentInstance,
  DeploymentInstanceData,
  ResourceGroup,
  DeploymentEnvironment,
  DeploymentEnvironmentSubtype,
  PolicyData,
  Partition,
  NetworkSegment,
  Error500Type,
} from '../../models/master';
import Notification from '../../components/Notifications/Inline/Notification';
import { NotificationContext } from '../../components/Notifications/Context/NotificationProvider';
import dateUtils from '../../lib/dates';
import { getResourceGroups } from '../../controllers/resourceGroupApi';
import { AxiosError } from 'axios';
import {
  getDeploymentEnv,
  getPartition,
} from '../../controllers/deploymentEnv';
import AddApplicationDeployment from './AddApplicationDeployment/AddApplicationDeployment';
import sortData from '../../lib/tableSort';
import { getPolicies } from '../../controllers/policyApi';
import useAnalytics from '../../lib/useAnalytics';
import Error500 from '../Errors/Error500';

import './ApplicationDetails.scss';
import analyticsData from '../../lib/analyticsEventData';
import GenericTruncateString from '../../components/GenericTruncateString/GenericTruncateString';
import {
  DEFAULT_NETWORK_SEGMENT_ID,
  defaultNetworkSegmentObject,
} from '../../lib/constants';

const defaultPermissionMap = {
  deploymentEnv: true,
};

const ApplicationDetailsPage: React.FC = () => {
  const { t } = useTranslation('applicationDetails');
  const [unmanagedToggle, setUnmanagedToggle] = useState(true);
  const [appDetailsData, setAppDetailsData] = useState<ApplicationData | null>(
    null
  );
  const [applicationsData, setApplicationsData] = useState<
    ApplicationData[] | null
  >(null);
  const [networkSegments, setNetworkSegments] = useState<
    NetworkSegment[] | null
  >(null);
  const [servicesData, setServicesData] = useState<ServiceData[] | []>([]);
  const [deploymentData, setDeploymentData] = useState<DeploymentData[] | null>(
    null
  );
  const [filteredDeploymentData, setFilteredData] = useState<
    DeploymentData[] | null
  >(null);
  const [deployments, setDeployments] = useState<Deployment[] | null>(null);
  const [depInstanceMap, setDepInstanceMap] = useState<
    Map<string, DeploymentInstanceData[]>
  >(new Map());
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('appId');
  const [appDataLoading, toggleAppDataLoading] = useState(false);
  const [deploymentDataLoading, toggleDeploymentDataLoading] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [notificationData, setNotificationData] =
    useState<InlineNotificationProps>({} as InlineNotificationProps);
  const [resourceGroupsData, setResourceGroupsData] = useState<
    ResourceGroup[] | null
  >(null);
  const [deleteApplicationOpenModal, toggleDeleteApplicationOpenModal] =
    useState(false);

  const [showAddApplication, setShowAddApplication] = useState(false);
  const [registerAppDeploymentModal, setRegisterAppDeploymentModal] =
    useState(false);

  const [deploymentEnvs, setDeploymentEnvs] = useState<
    DeploymentEnvironment[] | null
  >(null);
  const [deploymentEnvSubTypes, setDeploymentEnvSubTypes] = useState<
    DeploymentEnvironmentSubtype[] | null
  >(null);
  const [searchText, setSearchText] = useState('');
  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | 'NONE'>(
    'NONE'
  );
  const [policies, setPolicies] = useState<PolicyData[] | null>(null);
  const [disableButton, setDisableButton] = useState(false);
  const [clusterNetworkSegmentsMap, setClusterNetworkSegmentsMap] = useState<{
    [key: string]: string[];
  }>({});
  const [permissionMap, setPermissionMap] = useState(defaultPermissionMap);
  const [error500, setError500] = useState<null | Error500Type>(null);
  const [disableDeleteServiceButton, setDisableDeleteServiceButton] =
    useState(false);

  const notification = useContext(NotificationContext);
  const navigate = useNavigate();

  const { trackButtonClicked, pageViewed } = useAnalytics();

  const breadcrumbs = [
    {
      url: '/',
      label: t('home'),
    },
    {
      url: '/applications',
      label: t('appAndServices'),
    },
  ];

  const handleOnChange = (value: boolean) => {
    setUnmanagedToggle(value);
  };

  const deploymentHeaders = [
    {
      header: `${t('deployments.tableHeaders.environment')} / ${t(
        'deployments.tableHeaders.partition'
      )}`,
      key: 'environment',
      originalKey: 'name',
      sort: true,
    },
    {
      header: t('deployments.tableHeaders.instances'),
      key: 'instances',
      originalKey: 'instances',
      sort: true,
    },
    {
      header: t('deployments.tableHeaders.location'),
      key: 'location',
      originalKey: 'location',
      sort: true,
    },
    {
      header: t('deployments.tableHeaders.type'),
      key: 'type',
      originalKey: 'type',
      sort: true,
    },
    {
      header: t('deployments.tableHeaders.autoDiscovery'),
      key: 'autoDiscovery',
    },
  ];

  const getSelectedNwSegment = () => {
    if (Array.isArray(networkSegments) && appDetailsData?.network_segment_id) {
      if (appDetailsData?.network_segment_id === DEFAULT_NETWORK_SEGMENT_ID) {
        return defaultNetworkSegmentObject;
      }
      return networkSegments.find(
        networkSegment =>
          networkSegment.resource_id === appDetailsData?.network_segment_id
      );
    }

    return null;
  };

  const selectedNetworkSegment = useMemo(
    () => getSelectedNwSegment(),
    [networkSegments, appDetailsData?.network_segment_id]
  );

  const applicationDetailsLabels = () => {
    const labels: any = [];
    if (appDetailsData?.labels) {
      appDetailsData.labels.forEach((el: any) => {
        labels.push(el);
      });
    }
    if (appDetailsData?.discovered_labels) {
      appDetailsData.discovered_labels.forEach((el: any) => {
        labels.push({
          default: true,
          value: el,
        });
      });
    }
    return labels;
  };

  const formatAppDetailsData = (data: ApplicationData | null) => {
    const formattedData = [
      {
        key: 'name',
        value: data?.['name'],
      },
      {
        key: 'lastUpdated',
        value: dateUtils.getUserFriendlyDate(data?.updated_at),
      },
      {
        key: 'networkSegment',
        value:
          data?.network_segment_id === DEFAULT_NETWORK_SEGMENT_ID ? (
            <GenericTruncateString
              str={selectedNetworkSegment?.name ?? '-'}
              tableView={false}
              limit={16}
            />
          ) : (
            <div className='name-column-value'>
              <Link
                className='no-underline-link'
                to={`/networkSegmentDetails?nwSegId=${data?.network_segment_id}`}
              >
                <GenericTruncateString
                  str={selectedNetworkSegment?.name ?? '-'}
                  tableView={false}
                  limit={16}
                />
              </Link>
            </div>
          ),
      },
      {
        key: 'gatewaySet',
        value: selectedNetworkSegment?.compatibility_set
          ? t(selectedNetworkSegment.compatibility_set)
          : '—',
      },
      {
        key: 'appIdentity',
        value: data?.['app_identity'] ? data?.['app_identity'] : '—',
      },
      {
        key: 'resourceGroup',
        value: getResourceGroupName(data?.['resource_group_id']),
      },
      {
        key: 'description',
        value: data?.['description'] ? data?.['description'] : '—',
      },
      {
        key: 'labels',
        value: applicationDetailsLabels(),
      },
    ];

    return formattedData;
  };

  const formatServiceData = (data: Service[]) => {
    const serviceData: ServiceData[] = [];
    data?.map(item => {
      let obj = {
        id: item['resource_id'],
        name: item['name'],
        ports: item['ports'],
        labels: item['labels'],
        ip: 'NA',
      };
      serviceData.push(obj);
    });
    return serviceData;
  };

  const formatInstanceData = (data: DeploymentInstance[]) => {
    const instanceData: DeploymentInstanceData[] = [];
    data?.map(item => {
      let obj = {
        id: item['resource_id'],
        globalIp: 'NA',
        ip: item['ip_address'],
        created: item['created_at'],
      };
      instanceData.push(obj);
    });
    return instanceData;
  };

  const formatDeploymentData = (data: Deployment[] | null, view = 'all') => {
    try {
      const depData: DeploymentData[] = [];
      if (data != null && data.length > 0) {
        data?.map(item => {
          let environmentName: string;
          let environmentType: string;
          let partitionName: string;
          let partition: Partition;
          const fetchData = async () => {
            const instances = await getAllDeploymentInstance(
              id, // applicationID
              item['resource_id'], // deploymentID
              view
            );
            const environment = await getDeploymentEnv(item['depl_env_id']);

            if (item['depl_env_id'] && item['partition_id']) {
              partition = await getPartition(
                item['depl_env_id'],
                item['partition_id']
              );
            }
            partitionName = partition?.name;
            environmentName =
              partitionName != null && partitionName.length > 0
                ? environment?.name + ' / ' + partitionName
                : environment?.name;

            environmentType = environment?.type;
            depInstanceMap.set(
              item['resource_id'],
              formatInstanceData(instances?.insts)
            );
            const auto_discovery =
              partition?.auto_discover ?? environment?.auto_discover;
            setDepInstanceMap(depInstanceMap);
            let instance = depInstanceMap.get(item['resource_id']);
            let obj = {
              id: item['resource_id'],
              environment: {
                id: item['depl_env_id'],
                name: environmentName,
              },
              name: environmentName,
              type: environmentType,
              partition: partitionName,
              instances: instance?.length ?? 0,
              site: 'NA',
              resourceGroup: 'NA',
              subType: 'NA',
              autoDiscovery: auto_discovery,
              location: environment.location_name,
            };
            depData.push(obj);
            setDeploymentData([...depData]);
            if (deploymentDataLoading) {
              toggleDeploymentDataLoading(false);
            }
          };
          fetchData();
        });
      } else {
        setDeploymentData([]);
        if (deploymentDataLoading) {
          toggleDeploymentDataLoading(false);
        }
      }
    } catch (error) {
      // console.error(error);
    }
  };

  const handleAddService = (data: Service) => {
    const newService = {
      id: data.resource_id,
      name: data.name,
      ports: data.ports,
      labels: data.labels,
      ip: 'NA',
    };
    setServicesData([newService, ...servicesData]);
  };

  const handleUpdateService = (data: Service) => {
    const updatedServicesData = servicesData.map(service => {
      if (service.id === data.resource_id) {
        return {
          id: data.resource_id,
          name: data.name,
          ports: data.ports,
          labels: data.labels,
          ip: 'NA',
        };
      }
      return service;
    });
    setServicesData(updatedServicesData);
  };

  const requestDeleteApplication = () => {
    trackButtonClicked(
      analyticsData['Application Details'].events.deleteApp.props,
      analyticsData['Application Details'].events.deleteApp.event
    );
    toggleDeleteApplicationOpenModal(true);
  };

  const handleRemoveService = async (serviceId: string) => {
    try {
      toggleAppDataLoading(true);
      setDisableDeleteServiceButton(true);
      await deleteApplicationService(id, serviceId);
      setServicesData(servicesData.filter(service => service.id !== serviceId));
      // Trigger success toastbar
      notification.onTrigger('TOAST', {
        title: t('serviceNotificationMessages.successNotificationTitle'),
        subtitle: t('serviceNotificationMessages.removeServiceSuccess'),
      });
    } catch (error: any) {
      console.error(error);
      const err = error as AxiosError;
      const errorMessage: string =
        error.response !== undefined
          ? error.response['customErrorMessage']
          : '';
      let errorTitle: string = '',
        errorSubtitle: string = '';

      if (err.response?.status === 403) {
        errorTitle = `${t('serviceNotificationMessages.authErrorTitle')}`;
        errorSubtitle = t('serviceNotificationMessages.authErrorSubtitle');
      }

      if (err.response?.status !== 403 && errorMessage.length > 0) {
        errorTitle = `${t(
          'serviceNotificationMessages.errorNotificationTitle'
        )}`;
        errorSubtitle = errorMessage;
      }

      if (errorMessage.length === 0) {
        errorTitle = `${t(
          'serviceNotificationMessages.errorNotificationTitle'
        )}`;
        errorSubtitle = t('serviceNotificationMessages.removeServiceFail');
      }

      setNotificationData({
        kind: 'error',
        title: errorTitle,
        subtitle: errorSubtitle,
      });
      setOpenErrorModal(true);

      // We added this project.reject to prevent the modal closing when error occured.
      // But in latest version of '@carbon/ibm-products' the below line is not required and is throwing error.
      // return Promise.reject(() => console.log(error));
    } finally {
      toggleAppDataLoading(false);
      setDisableDeleteServiceButton(false);
    }
  };

  const handleRemoveApplication = async (id: string) => {
    toggleDeleteApplicationOpenModal(false);
    try {
      setDisableButton(true);
      const deleteAppname = appDetailsData?.name;
      toggleAppDataLoading(true);
      await deleteApplication(id);
      // Trigger success toastbar
      notification.onTrigger('TOAST', {
        title: t('applicationNotificationMessages.successNotificationTitle'),
        subtitle: t(
          'applicationNotificationMessages.removeNotificationMessage',
          {
            appNameDelete: deleteAppname,
          }
        ),
      });
      navigate(`/applications`);
    } catch (error: any) {
      console.error(error);
      let errorTitle: string = '',
        errorSubtitle: string = '';
      const err = error as AxiosError;
      const errorMessage: string = error.response['customErrorMessage'];

      if (err.response?.status === 403) {
        errorTitle = `${t('applicationNotificationMessages.authErrorTitle')}`;
        errorSubtitle = t('applicationNotificationMessages.authErrorSubtitle');
      }

      if (err.response?.status !== 403 && errorMessage.length > 0) {
        errorTitle = `${t(
          'applicationNotificationMessages.errorNotificationTitle'
        )}`;
        errorSubtitle = errorMessage;
      }

      if (errorMessage.length === 0) {
        errorTitle = `${t(
          'applicationNotificationMessages.errorNotificationTitle'
        )}`;
        errorSubtitle = t(
          'applicationNotificationMessages.removeApplicationFail'
        );
      }

      setNotificationData({
        kind: 'error',
        title: errorTitle,
        subtitle: errorSubtitle,
      });
      setOpenErrorModal(true);

      // We added this project.reject to prevent the modal closing when error occured.
      // But in latest version of '@carbon/ibm-products' the below line is not required and is throwing error.
      // return Promise.reject(() => console.log(error));
    } finally {
      setDisableButton(false);
      toggleAppDataLoading(false);
    }
  };

  const handleUpdateApplication = (data: ApplicationData) => {
    setAppDetailsData(data);
  };

  const onNotificationClose = (
    evt: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): any => {
    setOpenErrorModal(false);
  };

  const getResourceGroupName = (id: string | undefined) => {
    const resourceGroup =
      id && Array.isArray(resourceGroupsData)
        ? resourceGroupsData!.find(
            resourceGroup => resourceGroup.resource_id === id
          )
        : null;

    return resourceGroup ? resourceGroup.name : '';
  };

  const fetchDeploymentsData = async (id: string | null) => {
    let deploymentsData: Deployment[] = await getDeployments(id);
    setDeployments(deploymentsData);
    return deploymentsData;
  };

  const openEditApplicationModal = () => {
    trackButtonClicked(
      analyticsData['Application Details'].events.editApp.props,
      analyticsData['Application Details'].events.editApp.event
    );
    setShowAddApplication(true);
  };

  const onClose = () => {
    setShowAddApplication(false);
    fetchData();
    fetchDeploymentEnvironments();
  };

  const handleCloseService = () => {
    fetchData();
    fetchDeploymentEnvironments();
  };

  const createdApp = () => {
    setShowAddApplication(false);
  };

  const fetchData = async () => {
    try {
      toggleAppDataLoading(true);
      toggleDeploymentDataLoading(true);
      const appData: ApplicationData = await getApplication(
        id,
        false,
        true,
        true
      )
        .then(response => {
          return response;
        })
        .catch(error => {
          const err = error as AxiosError;

          if (err.response?.status === 404) {
            navigate('/404');
          }

          if (err.response!?.status >= 500) {
            setError500(err.response!?.status?.toString() as Error500Type);
          }
        });
      const deploymentsData: any = fetchDeploymentsData(id);
      setAppDetailsData(appData);
      setServicesData(formatServiceData(appData.services));
      toggleDeploymentDataLoading(true);
      formatDeploymentData(deploymentsData);
      getResourceGroups().then(response => {
        setResourceGroupsData(response.resource_groups as ResourceGroup[]);
      });
      // Since we are only checking policy.to_svc_name in service tile to disable the delete btn, we only need to fetch service information
      getPolicies(false, false).then(response => {
        setPolicies(response as PolicyData[]);
      });
      const allApplications = await getApplications(false, false);
      setApplicationsData(allApplications);
    } catch (error) {
      // console.error(error);
    } finally {
      toggleAppDataLoading(false);
    }
  };

  const fetchNwSegmentsList = async () => {
    try {
      const response = await getNetworkSegments();
      setNetworkSegments(response);
    } catch (error) {
      console.error(error);
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        console.error(error);
      }
    }
  };

  const fetchClusterNetworkSegments = async (clusterId: string) => {
    try {
      const response = await getPartitions(clusterId, 'managed');

      if (Array.isArray(response?.partitions)) {
        for (const namespace of response?.partitions) {
          if (clusterNetworkSegmentsMap[clusterId]) {
            clusterNetworkSegmentsMap[clusterId] = [
              ...clusterNetworkSegmentsMap[clusterId],
              namespace?.network_segment_id,
            ];
          } else {
            clusterNetworkSegmentsMap[clusterId] = [
              namespace?.network_segment_id,
            ];
          }
        }

        setClusterNetworkSegmentsMap(clusterNetworkSegmentsMap);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDeploymentEnvironments = async () => {
    try {
      const deploymentEnvs: DeploymentEnvironment[] = await getDeploymentEnvs(
        'managed',
        true
      );
      setDeploymentEnvs(deploymentEnvs);
      const deploymentEnvSubTypesData: DeploymentEnvironmentSubtype[] =
        await getDeploymentEnvSubtypes();

      setDeploymentEnvSubTypes(deploymentEnvSubTypesData);

      if (Array.isArray(deploymentEnvs)) {
        for (const deploymentEnv of deploymentEnvs) {
          if (deploymentEnv.type === 'cluster') {
            fetchClusterNetworkSegments(deploymentEnv.resource_id);
          }
        }
      }
    } catch (error: any) {
      toggleDeploymentDataLoading(false);
      if (error.response?.status === 403) {
        setPermissionMap(permissionMap => ({
          ...permissionMap,
          deploymentEnv: false,
        }));
      }
    }
  };

  const refreshDeploymentEnvironments = () => {
    setDeploymentEnvs(null);
    setDeploymentEnvSubTypes(null);
    fetchDeploymentEnvironments();
  };

  useEffect(() => {
    pageViewed('Application Details');
    fetchData();
    fetchDeploymentEnvironments();
    fetchNwSegmentsList();
  }, []);

  useEffect(() => {
    formatDeploymentData(deployments);
    // deployments !== null && toggleDeploymentDataLoading(false);
  }, [deployments]);

  useEffect(() => {
    if (appDetailsData !== null && resourceGroupsData !== null) {
      toggleAppDataLoading(false);
    }
  }, [appDetailsData, openErrorModal, resourceGroupsData]);

  const handleOpenAddAppDeployment = () => {
    trackButtonClicked(
      analyticsData['Application Details'].events.registerDeploymentBtn.props,
      analyticsData['Application Details'].events.registerDeploymentBtn.event
    );
    setRegisterAppDeploymentModal(true);
  };

  const updateApplicationDeployments = (deployment: Deployment) => {
    toggleDeploymentDataLoading(true);
    Array.isArray(deployments)
      ? setDeployments([deployment, ...deployments])
      : setDeployments([deployment]);
  };

  const applySearch = (searchText: string) => {
    if (Array.isArray(deploymentData)) {
      setSearchText(searchText);
      if (searchText === '' || searchText === null)
        setFilteredData(deploymentData);
      else {
        const filteredDataset = deploymentData?.filter(data =>
          data?.environment?.name
            ?.toLowerCase()
            .includes(searchText.toLowerCase())
        );
        setFilteredData(filteredDataset);
      }
    }
  };

  const handleTableSort = (
    data: { id: string; text: string },
    sortDirection: 'ASC' | 'DESC' | 'NONE'
  ) => {
    setSortDirection(sortDirection);
    setSortKey(data.id);
  };

  if (error500) {
    return <Error500 />;
  }

  return (
    <div className='application-details-container'>
      <Header
        title={appDetailsData ? appDetailsData.name : ''}
        breadcrumbs={breadcrumbs}
        loading={appDataLoading}
        // remove for now, functionality not complete
        //   toggle={{
        //     id: 'managed',
        //     state: unmanagedToggle,
        //     onChange: handleOnChange,
        //     labelA: 'Unmanaged',
        //     labelB: 'Managed',
        //   }}
      />
      <div className='application-details-section'>
        {openErrorModal ? (
          <Notification
            kind={notificationData?.kind}
            subtitle={notificationData?.subtitle}
            title={notificationData?.title}
            onClose={onNotificationClose}
          ></Notification>
        ) : null}
        {appDataLoading ? (
          <div className='tiles'>
            <SkeletonPlaceholder className='app-details-skeleton' />
            <SkeletonPlaceholder className='app-details-skeleton' />
          </div>
        ) : (
          <div className='tiles'>
            <DetailsCard
              data={formatAppDetailsData(appDetailsData)}
              isEditable={true}
              openEditModal={openEditApplicationModal}
              detailsCardName={t('detailsCardName')}
              type={'APPLICATION'}
            />
            <ServicesTile
              services={servicesData}
              applicationId={appDetailsData ? appDetailsData.resource_id : null}
              addService={handleAddService}
              updateService={handleUpdateService}
              removeService={handleRemoveService}
              onCloseService={handleCloseService}
              policiesList={policies}
              networkSegmentCompatibilitySet={
                selectedNetworkSegment?.compatibility_set ?? 'RHSI'
              }
              disableDeleteServiceButton={disableDeleteServiceButton}
            />
          </div>
        )}

        <DeploymentsTable
          headers={deploymentHeaders}
          rows={
            deploymentData
              ? searchText !== ''
                ? sortData(filteredDeploymentData ?? [], sortKey, sortDirection)
                : sortData(deploymentData ?? [], sortKey, sortDirection)
              : []
          }
          applicationId={appDetailsData?.resource_id}
          instanceMap={depInstanceMap}
          loading={deploymentDataLoading}
          style={{ borderBottom: 'none' }}
          id={id}
          onSearchCallBack={searchText => applySearch(searchText)}
          fetchDeploymentsData={(id: any) => fetchDeploymentsData(id)}
          openAddAppDeployment={handleOpenAddAppDeployment}
          sortRows={(
            data: { id: string; text: string },
            direction: 'ASC' | 'DESC' | 'NONE'
          ) => handleTableSort(data, direction)}
          error403Flag={!permissionMap['deploymentEnv']}
        />
        {servicesData?.length > 0 ||
        (Array.isArray(deploymentData) && deploymentData?.length > 0) ||
        (Array.isArray(policies) &&
          appDetailsData != null &&
          policies.filter(
            policy =>
              policy?.from?.application?.application_id ===
              appDetailsData?.resource_id
          ).length > 0) ? (
          <TooltipDefinition
            className='tooltipDeleteApplication'
            direction='top'
            tooltipText={t('tooltipDeleteApplicationText') as string}
          >
            <Button
              className='applicationDetailsDeleteDisabled'
              kind='danger--ghost'
            >
              {t('deleteApplication')}
            </Button>
          </TooltipDefinition>
        ) : (
          <Button
            className='applicationDetailsDelete'
            kind='danger--ghost'
            onClick={() => requestDeleteApplication()}
          >
            {t('deleteApplication')}
          </Button>
        )}
        <Modal
          className='delete-application-modal'
          danger
          modalHeading={t('applicationNotificationMessages.deleteModal.header')}
          onRequestClose={() => toggleDeleteApplicationOpenModal(false)}
          onRequestSubmit={() =>
            appDetailsData &&
            handleRemoveApplication(appDetailsData.resource_id)
          }
          primaryButtonText={t(
            'applicationNotificationMessages.deleteModal.confirm'
          )}
          secondaryButtonText={t(
            'applicationNotificationMessages.deleteModal.cancel'
          )}
          open={deleteApplicationOpenModal}
          size='sm'
          primaryButtonDisabled={disableButton}
        >
          {t('applicationNotificationMessages.deleteModal.body', {
            applicationName: appDetailsData?.name,
          })}
        </Modal>

        <AddApplication
          open={showAddApplication}
          onClose={() => onClose()}
          createdApp={() => createdApp()}
          mode={'EDIT'}
          applicationData={appDetailsData}
          updateAppData={handleUpdateApplication}
          applicationDepEnvsCount={deployments?.length}
          applicationList={applicationsData}
          networkSegmentList={networkSegments}
        />

        {
          <AddApplicationDeployment
            open={registerAppDeploymentModal}
            onClose={() => setRegisterAppDeploymentModal(false)}
            deploymentEnvData={deploymentEnvs}
            deploymentEnvSubtypesData={deploymentEnvSubTypes}
            resourceGroupsData={resourceGroupsData}
            triggerRefresh={() => refreshDeploymentEnvironments()}
            applicationId={id}
            updateApplicationDeployments={updateApplicationDeployments}
            appDeployments={deployments}
            appNetworkSegmentId={appDetailsData?.network_segment_id ?? ''}
            clusterNetworkSegmentsMap={clusterNetworkSegmentsMap}
          />
        }
      </div>
    </div>
  );
};

export default ApplicationDetailsPage;
