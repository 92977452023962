import { axiosInstance } from './api';

// Get all of the API keys for an MCNM user
export async function getAPIKeys() {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `/apikey`,
    });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

// Add a new API key for an MCNM user
export async function addAPIKey(keyName) {
  try {
    const response = await axiosInstance({
      method: 'POST',
      url: `/apikey`,
      data: { name: keyName },
    });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
// Delete an existing API key for an MCNM user
export async function deleteAPIKey(keyValue) {
  try {
    await axiosInstance({
      method: 'DELETE',
      url: `/apikey/${keyValue}`,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
}
