import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header/Header';
import RegisterSecret from './RegisterSecret/RegisterSecret';
import './SecretManagerContainer.scss';
import { TableProperties } from '../../components/GenericTableWithFilters/GenericTableWithFilters';
import SecretsTable from './SecretDetailsTable/SecretsTable';
import { getSecrets } from '../../controllers/tenantApis';
import { Error500Type, Secret, SecretManagerConfig } from '../../models/master';
import { DataTableSkeleton } from 'carbon-components-react';
import { AxiosError } from 'axios';
import { getSecretManagerConfiguration } from '../../controllers/tenantApis';
import useAnalytics from '../../lib/useAnalytics';
import analyticsData from '../../lib/analyticsEventData';

type LocationState = {
  fromPage: string;
};

const SecretsContainer = (props: TableProperties) => {
  const [resourceDataLoading, toggleResourceDataLoading] = useState(false);
  const [registerTearsheetOpen, setRegisterTearsheetOpen] = useState(false);
  const [secretsDetailsChanged, setSecretsDetailsChanged] = useState(false);
  const [secretManagerConfig, setSecretManagerConfig] =
    useState<SecretManagerConfig | null>(null);
  const [secretManagerError, setSecretManagerError] = useState<any>(null);
  const [secretManagerLoading, setSecretManagerLoading] = useState(false);

  const [secrets, setSecrets] = useState<Secret[] | null>(null);
  const [error500, setError500] = useState<null | Error500Type>(null);

  const { t } = useTranslation('secretManager');

  const location = useLocation();
  const state = location?.state as LocationState;
  const navigate = useNavigate();
  const { pageViewed, trackButtonClicked } = useAnalytics();

  useEffect(() => {
    pageViewed('Admin Secrets');
    fetchSecretList();
    getSecretManagerConfig();
  }, []);

  useEffect(() => {
    if (state?.fromPage === 'CLOUD') {
      openRegisterTearsheet();
    }
  }, [state]);

  useEffect(() => {
    if (secrets !== null) {
      toggleResourceDataLoading(false);
    }
  }, [secrets]);

  const fetchSecretList = async () => {
    toggleResourceDataLoading(true);
    try {
      const resp = await getSecrets();
      setSecrets(resp.secrets);

      if (error500) {
        setError500(null);
      }
    } catch (error) {
      console.error(error);
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        navigate('/403');
      }

      if (err.response!?.status >= 500) {
        setError500(err.response!?.status?.toString() as Error500Type);
      }
    } finally {
      toggleResourceDataLoading(false);
    }
  };

  const getSecretManagerConfig = async () => {
    setSecretManagerLoading(true);
    try {
      const secretManagerConfiguration = await getSecretManagerConfiguration();
      setSecretManagerConfig(secretManagerConfiguration);
      setSecretManagerError(null);
    } catch (error) {
      const err = error as AxiosError;
      console.log(err?.response);
      setSecretManagerError(err);
    } finally {
      setSecretManagerLoading(false);
    }
  };

  const openRegisterTearsheet = () => {
    trackButtonClicked(
      analyticsData['Admin Secrets'].events.launchRegSecret.props,
      analyticsData['Admin Secrets'].events.launchRegSecret.event
    );
    setRegisterTearsheetOpen(true);
  };

  const closeRegisterTearsheet = () => {
    setRegisterTearsheetOpen(false);
  };

  const onSecretCreate = () => {
    setSecretsDetailsChanged(!secretsDetailsChanged);
    setRegisterTearsheetOpen(false);
    fetchSecretList();
  };

  return (
    <div className='secret-manager-container'>
      <Header
        title={t('header')}
        subTitle={t('subheader')}
        breadcrumbs={[
          {
            url: '/',
            label: t('home'),
          },
        ]}
        actions={[
          {
            kind: 'primary',
            onClick: () => openRegisterTearsheet(),
            text: t('addSecret'),
            disabled: resourceDataLoading,
          },
        ]}
      />
      <RegisterSecret
        open={registerTearsheetOpen}
        mode='ADD'
        onClose={() => closeRegisterTearsheet()}
        onSecretCreate={() => onSecretCreate()}
        secrets={secrets}
        fromPage={state?.fromPage ?? ''}
      />
      <SecretsTable
        setRegisterTearsheetOpen={setRegisterTearsheetOpen}
        secretsDetailsChanged={secretsDetailsChanged}
        rowsData={secrets}
        refreshData={() => fetchSecretList()}
        secretManagerConfig={secretManagerConfig}
        error500Flag={!!error500}
      />
    </div>
  );
};

export default SecretsContainer;
