import React from 'react';
import {
  Tag,
  Tile,
  Link as CarbonLink,
  TooltipDefinition,
} from 'carbon-components-react';

import './ServiceTile.scss';
import { Edit16, TrashCan16 } from '@carbon/icons-react';
import { PolicyData } from '../../../../models/master';
import { useTranslation } from 'react-i18next';

interface ports {
  port_number: string;
  protocol: string;
}
interface Props {
  serviceId: string;
  name: string;
  ports: ports[];
  labels: string[] | null;
  openEditModal: (id: string) => void;
  removeService: (id: string, name: string) => void;
  onCloseService: () => void;
  policiesList: PolicyData[] | null;
}

const ServiceTile = (props: Props) => {
  const {
    serviceId,
    name,
    ports,
    labels,
    openEditModal,
    removeService,
    onCloseService,
    policiesList,
    ...rest
  } = props;

  const { t } = useTranslation('applicationDetails');

  const getPortMappedValues = (
    from: number,
    to: number,
    isTooltip?: boolean
  ) => {
    return ports.slice(from, to).map((port, index) => (
      <span key={port.port_number}>
        <span>{port.port_number}</span>
        <span className='protocol-spacing'>{t(port.protocol)}</span>
        {index < ports.length - 1 && !isTooltip && (
          <span className='ports-spacing'>{'|'}</span>
        )}
      </span>
    ));
  };
  const renderPorts = () => {
    if (ports.length > 0) {
      return (
        <div className='pairs'>
          {getPortMappedValues(0, 4)}
          {ports.length > 4 ? (
            <TooltipDefinition
              tooltipText={getPortMappedValues(4, ports.length, true)}
              direction='bottom'
            >
              {`+${ports.slice(4).length}`}
            </TooltipDefinition>
          ) : (
            ''
          )}
        </div>
      );
    }
  };

  const renderLabels = () => {
    if (!labels) {
      return <div className='labels no-labels' />;
    }
    if (!labels) {
      return <div className='labels no-labels' />;
    }
    if (labels.length > 3) {
      return (
        <div className='labels'>
          {labels.slice(0, 3).map(label => (
            <Tag type='green'>{label}</Tag>
          ))}
          <Tag type='green'>{`+${labels.slice(3).length}`}</Tag>
        </div>
      );
    } else {
      return (
        <div className='labels'>
          {labels.map(label => (
            <Tag type='green'>{label}</Tag>
          ))}
        </div>
      );
    }
  };
  return (
    <Tile
      className={
        'service-tile' +
        (labels && labels && labels.length > 0 ? ' labels' : '')
      }
      {...rest}
    >
      <div className='service-info'>
        <div className='service-name'>{name}</div>
        {renderPorts()}
        {renderLabels()}
      </div>
      <div className='action-buttons'>
        <CarbonLink
          className='edit-link'
          onClick={() => openEditModal(serviceId)}
        >
          <Edit16 className='edit-svg' />
        </CarbonLink>
        {Array.isArray(policiesList) &&
        policiesList.filter(
          policy => policy?.to?.service?.service_id === serviceId
        ).length > 0 ? (
          <TooltipDefinition
            className='tooltipDeleteService'
            tooltipText={t('servicesTile.deletePolicyText') as string}
            direction='top'
          >
            <CarbonLink className='delete-link disabled'>
              <TrashCan16 className='trash-can-svg' />
            </CarbonLink>
          </TooltipDefinition>
        ) : (
          <CarbonLink
            className='delete-link'
            onClick={() => removeService(serviceId, name)}
          >
            <TrashCan16 className='trash-can-svg' />
          </CarbonLink>
        )}
      </div>
    </Tile>
  );
};

export default ServiceTile;
