import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';
import GenericTableWithFilters from '../GenericTableWithFilters/GenericTableWithFilters';
import './DeploymentEnvsTable.scss';
import dateUtils from '../../lib/dates';
import { Link } from 'react-router-dom';
import LabelTag from '../LabelTag/LabelTag';
import { DeploymentEnvironment } from '../../models/master';
import images from '../../images/images.js';
import IconWithToolTip from '../IconWithToolTip/IconWithToolTip';
import { Filter } from '../FindAndFilterBar/FindAndFilterBar';

import GenericTruncateString from '../GenericTruncateString/GenericTruncateString';
import { filterTypes } from '../../lib/enums';

const DeploymentEnvsTable = (props: {
  filteredDataSet: any;
  elementCount: any;
  data: any;
  filteredDataCallback: (data: any) => void;
  filtersSelected: any;
  filtersAppliedCallback: (data: any) => void;
  filters: any;
  currentPageNumber: number;
  currentPageSize: number;
  onPageChange: (pageData: any) => void;
  rows: DeploymentEnvironment[] | null;
  onRefresh: () => void;
  sortRows(arg0: unknown, sortDirection: string): void;
  noDataCallBack: () => void;
  leftInlineFilters?: Filter[];
  visibilityFlag?: string;
  depEnvSubTypes: any;
  persistFilter?: boolean;
  error403Flag: boolean;
  error500Flag: boolean;
  dataLoading: boolean;
}) => {
  const { t } = useTranslation('deploymentEnvsContainer');
  const { rows, onRefresh } = props;
  const error403Flag = props.error403Flag;
  const error500Flag = props.error500Flag;

  const formatTags = (
    labels: string[] | null,
    discoverdLabels: string[] | null
  ) => {
    const labelsList: any = [];
    if (labels) {
      labels.forEach(el => {
        labelsList.push(el);
      });
    }
    if (discoverdLabels) {
      discoverdLabels.forEach(el => {
        labelsList.push({
          default: true,
          value: el,
        });
      });
    }
    return <LabelTag labelArray={labelsList} count={3} />;
  };

  const deploymentTableHeaders = [
    {
      key: 'name__format',
      originalKey: 'name',
      header: t('tableView.name'),
      sort: 'sortByName',
      style: { minWidth: '9.5rem' },
    },
    {
      key: 'cloud__format',
      originalKey: 'cloud_name',
      header: t('tableView.cloud'),
      sort: 'sortByCloud',
    },
    {
      key: 'location__format',
      originalKey: 'location_name',
      header: t('tableView.location'),
      sort: 'sortByLocation',
    },
    {
      key: 'type',
      originalKey: 'subTypeName',
      header: t('tableView.type'),
      sort: 'sortByType',
    },
    {
      key: filterTypes.ALLLABELS,
      originalKey: filterTypes.ALLLABELS,
      header: t('tableView.labels'),
    },
    {
      key: 'updated',
      originalKey: 'updated_at',
      header: t('tableView.lastUpdated'),
      sort: 'sortByUpdatedDate',
      style: { minWidth: '12.5rem' },
    },
  ];

  const setRowsData = () => {
    let formattedRows: {
      id: any;
      name: string;
      name__format: JSX.Element;
      cloud__format: JSX.Element;
      location__format: JSX.Element;
      updated: any;
      location: any;
      cloud: any;
      type: any;
      allLabels: JSX.Element | null | string;
    }[] = [];
    if (error403Flag) return [];
    if (error500Flag) return [];
    if (rows && rows.length === 0) return [];
    if (rows)
      rows.map((row: DeploymentEnvironment, index) => {
        formattedRows.push({
          id: row.resource_id,
          name: !!row.name ? row.name : '—',
          name__format: (
            <div className='name-column-value'>
              <div>
                <Link
                  className='no-underline-link'
                  to={`/deploymentEnvironmentDetails?deplId=${row.resource_id}`}
                >
                  <GenericTruncateString
                    str={row.name}
                    tableView={true}
                    tooltipDirection={
                      rows.length - 1 === index ||
                      (index + 1) % props.currentPageSize === 0
                        ? 'top'
                        : 'bottom'
                    }
                  />
                  {row?.is_discovered && (
                    <div className='icon'>
                      <IconWithToolTip
                        icon={images.AutoDiscoverdLockIconSmall()}
                        iconDescription={t('autoDiscoveredAccessLimited')}
                      />
                    </div>
                  )}

                  {row?.unmanaged && (
                    <div className='icon'>
                      <IconWithToolTip
                        icon={<div className='unmanaged-icon' />}
                        iconDescription={t('unmanagedDepEnv')}
                      />
                    </div>
                  )}
                </Link>
              </div>
            </div>
          ),
          cloud: !!row.cloud_name ? row.cloud_name : '—',
          cloud__format: (
            <div>
              <GenericTruncateString str={row.cloud_name} tableView={true} />
            </div>
          ),
          location: !!row.location_name ? row.location_name : '—',
          location__format: (
            <div>
              <GenericTruncateString str={row.location_name} tableView={true} />
            </div>
          ),
          type: !!row?.subTypeName ? row?.subTypeName : '—',
          allLabels:
            row.labels || row?.discovered_labels
              ? formatTags(row.labels, row?.discovered_labels)
              : '—',
          updated: !!row.updated_at
            ? dateUtils.getUserFriendlyDate(row.updated_at)
            : '—',
        });
        return 0;
      });
    else return null;
    return formattedRows;
  };

  const emptyStateData = {
    icon: images.DeploymentEnvironmentEmpty(),
    notFoundIcon: images.NotFoundLarge(),
    emptyStateHeader: t('emptyState.emptyContainerHeader'),
    emptyStateDescription: t('emptyState.emptyContainerDescription'),
    link: '/',
    buttonText: t('emptyState.buttonText'),
    click: props.noDataCallBack,
  };

  return (
    <GenericTableWithFilters
      id='deployment-env-table'
      dataTestId='deployment-env-table'
      rows={setRowsData()}
      data={props.filteredDataSet}
      headers={deploymentTableHeaders}
      isSortable={true}
      totalElementsCount={props.elementCount ? props.elementCount : 0}
      fullData={props.data}
      onTableRefresh={() => onRefresh()}
      filteredDataCallback={(data: any) => props.filteredDataCallback(data)}
      selectedFiltersVal={props.filtersSelected as any}
      setFilterApplied={(data: any) => props.filtersAppliedCallback(data)}
      filters={props.filters}
      currentPageNumber={props.currentPageNumber}
      currentPageSize={props.currentPageSize}
      onPageChange={(pageData: any) => props.onPageChange(pageData)}
      sortRows={(data, sortDirection) => props.sortRows(data, sortDirection)}
      emptyState={emptyStateData}
      leftInlineFilters={props.leftInlineFilters}
      visibilityFlag={props.visibilityFlag}
      persistFilter={props.persistFilter}
      render403Container={props.error403Flag}
      render500Container={props.error500Flag}
      tableDataLoading={props.dataLoading}
    />
  );
};

export default DeploymentEnvsTable;
