import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  DataTable,
  DataTableSkeleton,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableProps,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
} from 'carbon-components-react';
import {
  Add16,
  ChevronDown16,
  ChevronUp16,
  Edit16,
  Launch16,
} from '@carbon/icons-react';

import images from '../../images/images';
import dateUtils from '../../lib/dates';
import sortData from '../../lib/tableSort';
import useAnalytics from '../../lib/useAnalytics';
import analyticsData from '../../lib/analyticsEventData';

import Overflow from '../../components/Overflow/Overflow';
import Error403Card from '../../components/ErrorState/Error403Card';
import { VerticalEmptyState } from '../../components/EmptyState/EmptyState';
import HealthStatus from '../../components/GatewayStatus/HealthStatus';
import { HealthStatusType } from '../../components/GatewayStatus/config';
import GenericStatusField from '../../components/GenericStatusField/GenericStatusField';

import {
  DataTableHeader,
  DataTableRow,
  DataTableCell,
} from '../../models/dataTable';
import {
  DeploymentEnvironment,
  Gateway,
  Namespace,
  NetworkSegment,
} from '../../models/master';

import './CloudNameSpaceTable.scss';
import { VisibilityFlags } from '../../lib/enums';

interface NamespaceTableRow {
  id: any;
  name: JSX.Element;
  gateway: JSX.Element;
  networkSegment: JSX.Element;
  appGroup: JSX.Element;
  lastUpdated: JSX.Element | string;
  actionBtns: JSX.Element;
}

interface Props {
  isExpanded: boolean;
  deploymentEnv: DeploymentEnvironment;
  namespaces: Namespace[];
  gateways: Gateway[] | null;
  networksegments: NetworkSegment[];
  nameSpaceLoading: boolean;
  getResourceGroupName: (resourceGroupId: string) => string | JSX.Element;
  onRegisterNamespace: (deploymentEnv: DeploymentEnvironment) => void;
  onEditNamespace: (
    deploymentEnv: DeploymentEnvironment,
    namespace: Namespace
  ) => void;
  defaultPermissionMap: {
    location: boolean;
    deploymentEnv: boolean;
    namespace: boolean;
    networkSegmant: boolean;
    gateway: boolean;
    resourceGroup: boolean;
  };
}

const CloudNameSpaceTable = ({
  isExpanded,
  deploymentEnv,
  namespaces,
  gateways,
  networksegments,
  nameSpaceLoading,
  getResourceGroupName,
  onRegisterNamespace,
  onEditNamespace,
  defaultPermissionMap,
}: Props) => {
  const { t } = useTranslation('cloudDetails');

  const { trackButtonClicked } = useAnalytics();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [searchText, setSearchText] = useState('');
  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | 'NONE'>(
    'NONE'
  );

  const getGatewayDetails = (gatewayId: string) => {
    if (Array.isArray(gateways) && gatewayId) {
      const gateway = gateways.find(
        gateway => gateway.resource_id === gatewayId
      );

      return gateway;
    }

    return null;
  };

  const getNetworkSegmentName = (networkSegmentId: string) => {
    if (!defaultPermissionMap['networkSegmant']) {
      return getNotAuthorizedContent();
    }

    if (Array.isArray(networksegments) && networkSegmentId) {
      const networksegment = networksegments.find(
        networksegment => networksegment.resource_id === networkSegmentId
      );

      return networksegment?.name ?? '—';
    }

    return '—';
  };

  const getFilteredRows = () => {
    if (!defaultPermissionMap['namespace']) {
      return [];
    }

    if (!searchText) {
      return [...namespaces];
    }
    return namespaces?.filter(namespace =>
      namespace.name?.toLowerCase().includes(searchText?.toLowerCase())
    );
  };

  const handlePagination = (change: any) => {
    setPage(change.page);
    setPageSize(change.pageSize);
  };

  const getRowsToBeDisplayed = (rows: DataTableRow[]) => {
    if (page && pageSize)
      return rows?.slice((page - 1) * pageSize, page * pageSize);

    return rows;
  };

  const handleChangeSort = (index: number) => {
    const newSortDirection =
      sortKey === headers[index].originalKey
        ? sortDirection === 'ASC'
          ? 'DESC'
          : 'ASC'
        : 'ASC';
    setSortDirection(newSortDirection);

    const sortedKey =
      headers[index]?.originalKey !== undefined
        ? headers[index]?.originalKey ?? ''
        : '';
    setSortKey(sortedKey);
  };

  const handleNamespaceManageChange = (namespace: Namespace) => {
    trackButtonClicked(
      analyticsData['Cloud Details'].events.manageNamespace.props,
      analyticsData['Cloud Details'].events.manageNamespace.event
    );

    onEditNamespace(deploymentEnv, namespace);
  };

  const headers = [
    {
      header: t('namespaceTableHeaders.name'),
      key: 'name',
      originalKey: 'name',
      sort: true,
      style: { minWidth: '150px' },
    },
    {
      header: t('namespaceTableHeaders.gateway'),
      key: 'gateway',
      originalKey: 'gateway',
      sort: true,
    },
    {
      header: t('namespaceTableHeaders.networkSegment'),
      key: 'networkSegment',
      originalKey: 'networkSegment',
      sort: true,
    },
    {
      header: t('namespaceTableHeaders.appGroup'),
      key: 'appGroup',
      originalKey: 'appGroup',
      sort: true,
      style: { minWidth: '210px' },
    },
    {
      header: t('namespaceTableHeaders.lastUpdated'),
      key: 'lastUpdated',
      originalKey: 'updated_at',
      sort: true,
      style: { minWidth: '140px' },
    },
    {
      key: 'actionBtns',
      originalKey: '',
      header: '',
      sort: false,
    },
  ];

  const getNotAuthorizedContent = () => {
    return <GenericStatusField status='notAuthorised'></GenericStatusField>;
  };

  const getRowsData = () => {
    const filteredRows = getFilteredRows();

    const rows = sortData(filteredRows ?? [], sortKey, sortDirection);

    let formattedRows: NamespaceTableRow[] = [];

    if (Array.isArray(rows)) {
      rows.forEach((row: Namespace, index) => {
        const connectedGateway = getGatewayDetails(row.gateway_id);
        formattedRows.push({
          id: row.resource_id,
          name: (
            <Overflow
              align='start'
              toolTipDirection={index + 1 === rows.length ? 'top' : 'bottom'}
              key={`namespace-name-${row.resource_id}`}
            >
              <div
                className={`name ${
                  row.unmanaged
                    ? VisibilityFlags.UNMANAGED
                    : VisibilityFlags.MANAGED
                }`}
              >
                <div className='icon'>
                  {row.unmanaged
                    ? images.unmanagedIcon()
                    : images.managedIcon()}
                </div>
                <div>{row.name}</div>
              </div>
            </Overflow>
          ),
          gateway: row.unmanaged ? (
            <div className='unmanaged'>—</div>
          ) : (
            <Overflow
              align='start'
              toolTipDirection={index + 1 === rows.length ? 'top' : 'bottom'}
              key={`namespace-gateway-${row.resource_id}`}
            >
              <>
                {!connectedGateway &&
                  !defaultPermissionMap['gateway'] &&
                  getNotAuthorizedContent()}

                {!connectedGateway && defaultPermissionMap['gateway'] && (
                  <div>—</div>
                )}
                {connectedGateway && (
                  <div className='gateway'>
                    <HealthStatus
                      status={
                        connectedGateway?.health_status as HealthStatusType
                      }
                      gatewayName={connectedGateway?.name}
                      showLabel={false}
                    />
                    {connectedGateway?.name}
                  </div>
                )}
              </>
            </Overflow>
          ),
          networkSegment: row.unmanaged ? (
            <div className='unmanaged'>—</div>
          ) : (
            <Overflow
              align='start'
              toolTipDirection={index + 1 === rows.length ? 'top' : 'bottom'}
              key={`namespace-network_segment-${row.resource_id}`}
            >
              <div>{getNetworkSegmentName(row.network_segment_id)}</div>
            </Overflow>
          ),
          appGroup: row.unmanaged ? (
            <div className='unmanaged'>—</div>
          ) : (
            <Overflow
              align='start'
              toolTipDirection={index + 1 === rows.length ? 'top' : 'bottom'}
              key={`namespace-resource_group-${row.resource_id}`}
            >
              <div>{getResourceGroupName(row.app_resource_group_id ?? '')}</div>
            </Overflow>
          ),
          lastUpdated: row.unmanaged ? (
            <div className='unmanaged'>—</div>
          ) : (
            dateUtils.getUserFriendlyDate(row.updated_at) || '—'
          ),
          actionBtns: (
            <div className='table-button-group'>
              {!row.unmanaged && (
                <>
                  <Button
                    kind='ghost'
                    renderIcon={Launch16}
                    iconDescription={t('detailsPage')}
                    hasIconOnly
                    tooltipPosition='bottom'
                    onClick={e => {
                      e.stopPropagation();

                      trackButtonClicked(
                        analyticsData['Cloud Details'].events
                          .namespacedetailsLink.props,
                        analyticsData['Cloud Details'].events
                          .namespacedetailsLink.event
                      );

                      window.open(
                        window.location.origin +
                          process.env.PUBLIC_URL +
                          `/partitionDetails?depEnvId=${deploymentEnv.resource_id}&partitionId=${row.resource_id}`
                      );
                    }}
                  />
                  <Button
                    kind='ghost'
                    renderIcon={Edit16}
                    iconDescription={t('edit')}
                    hasIconOnly
                    tooltipPosition='bottom'
                    onClick={() => onEditNamespace(deploymentEnv, row)}
                  />
                </>
              )}

              {row.unmanaged && (
                <Button
                  kind='ghost'
                  onClick={() => handleNamespaceManageChange(row)}
                >
                  {t('manage')}
                </Button>
              )}
            </div>
          ),
        });
      });
    }

    return formattedRows;
  };

  const emptyState = () => {
    if (!defaultPermissionMap['namespace']) {
      return <Error403Card />;
    }

    const rows = getFilteredRows();

    if (rows.length === 0 && !nameSpaceLoading) {
      if (searchText.length) {
        return (
          <VerticalEmptyState
            icon={images.NotFoundLarge()}
            header={t('notFound.header')}
            description={t('notFound.description')}
            buttonText={t('notFound.text') ?? ''}
            link={false}
            additionalClassName='small'
            click={() => {}}
          />
        );
      } else {
        return (
          <VerticalEmptyState
            icon={images.namespaceEmptyLarge()}
            header={t('emptyState.namespace.emptyContainerHeader')}
            description={t('emptyState.namespace.emptyContainerDescription')}
            buttonText={t('emptyState.namespace.buttonText') ?? ''}
            link={defaultPermissionMap['networkSegmant']}
            additionalClassName='small'
            click={() => onRegisterNamespace(deploymentEnv)}
          />
        );
      }
    } else {
      return null;
    }
  };

  return (
    <div className='cloud-namespace-table' onClick={e => e.stopPropagation()}>
      {isExpanded && (
        <div className='namespace-table-session'>
          {nameSpaceLoading && (
            <div className='namespace-table-skeleton'>
              <DataTableSkeleton
                showHeader={false}
                rowCount={3}
                columnCount={5}
              />
            </div>
          )}

          {!nameSpaceLoading && (
            <div className='namespace-table-container'>
              <DataTable
                rows={getRowsData() as any}
                headers={headers}
                isSortable
              >
                {({
                  rows,
                  headers,
                  getHeaderProps,
                  getRowProps,
                  getTableProps,
                  getToolbarProps,
                  getTableContainerProps,
                }: {
                  rows: DataTableRow[];
                  headers: DataTableHeader[];
                  getTableProps: () => TableProps;
                  getHeaderProps: any;
                  getRowProps: any;
                  getToolbarProps: any;
                  getTableContainerProps: any;
                }) => (
                  <TableContainer {...getTableContainerProps()}>
                    {namespaces.length > 0 && (
                      <TableToolbar {...getToolbarProps()}>
                        <TableToolbarContent>
                          <TableToolbarSearch
                            expanded
                            onChange={e => setSearchText(e.target.value)}
                            placeholder={' '}
                          />
                          {(rows.length !== 0 || searchText.length > 0) &&
                            defaultPermissionMap['networkSegmant'] && (
                              <Button
                                kind='ghost'
                                className='add-namespace-button'
                                renderIcon={Add16}
                                onClick={() =>
                                  onRegisterNamespace(deploymentEnv)
                                }
                              >
                                <span className='text'>
                                  {t('emptyState.namespace.buttonText')}
                                </span>
                              </Button>
                            )}
                        </TableToolbarContent>
                      </TableToolbar>
                    )}
                    <Table {...getTableProps()}>
                      <TableHead>
                        <TableRow>
                          {headers.map(
                            (header: DataTableHeader, index: number) => (
                              <TableHeader
                                key={header.key}
                                {...getHeaderProps({
                                  header,
                                  isSortable: Boolean(header.sort),
                                })}
                                onClick={() =>
                                  header.sort
                                    ? handleChangeSort(index)
                                    : undefined
                                }
                                isSortHeader={Boolean(header.sort)}
                                sortDirection={
                                  headers[index].originalKey === sortKey
                                    ? sortDirection
                                    : 'NONE'
                                }
                                style={header?.style ? { ...header.style } : {}}
                                data-testid={`cloud-depl-namespace-table-head-${header?.key}`}
                              >
                                {header.header}
                              </TableHeader>
                            )
                          )}
                        </TableRow>
                      </TableHead>
                      {rows.length > 0 && (
                        <TableBody>
                          {rows.length > 0 &&
                            getRowsToBeDisplayed(rows)?.map(
                              (row: DataTableRow) => (
                                <TableRow
                                  {...getRowProps({ row })}
                                  key={row.id}
                                >
                                  {row.cells.map((cell: DataTableCell) => {
                                    return (
                                      <TableCell
                                        key={row.id + cell.id}
                                        data-testid={`cloud-depl-namespace-table-value-${cell.info.header}`}
                                      >
                                        {cell.value}
                                      </TableCell>
                                    );
                                  })}
                                </TableRow>
                              )
                            )}
                        </TableBody>
                      )}
                    </Table>
                    {rows.length > 5 && (
                      <Pagination
                        totalItems={rows.length}
                        backwardText={t(
                          'namespaceTableHeaders.pagination.prevPageBtn'
                        )}
                        forwardText={t(
                          'namespaceTableHeaders.pagination.nextPageBtn'
                        )}
                        itemsPerPageText={t(
                          'namespaceTableHeaders.pagination.itemsPerPage'
                        )}
                        itemRangeText={(min, max, totalLength) => {
                          return t('namespaceTableHeaders.pagination.itemKey', {
                            min: min,
                            max: max,
                            total: totalLength,
                          });
                        }}
                        pageSize={pageSize}
                        page={page}
                        pageRangeText={(current, total) =>
                          t('namespaceTableHeaders.pagination.pageKey', {
                            sum: total,
                          })
                        }
                        pageSizes={[5, 10, 25, 50]}
                        onChange={change => handlePagination(change)}
                      />
                    )}
                  </TableContainer>
                )}
              </DataTable>
              {getFilteredRows()?.length === 0 ? emptyState() : null}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CloudNameSpaceTable;
