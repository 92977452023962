import React from 'react';
import { useTranslation } from 'react-i18next';
import { ComponentProps } from '../config';
import IconWithToolTip from '../../../IconWithToolTip/IconWithToolTip';
import images from '../../../../images/images';

import './PartitionTooltip.scss';

const PartitionTooltip: React.FC<ComponentProps> = ({ node }) => {
  const { t } = useTranslation('topologyTooltipPartition');

  const getHeaderTitle = () => {
    if (node?.is_discovered) {
      return (
        <div className='header'>
          <span className='header-title'>{node?.name}</span>
          <span className='page-title-icon'>
            <IconWithToolTip
              icon={images.AutoDiscoverdLockIcon()}
              iconDescription={t('autoDiscoveredAccessLimited')}
            />
          </span>
        </div>
      );
    }

    return (
      <div className='header'>
        <span className='header-title'>{node?.name}</span>
      </div>
    );
  };

  const getClassName = () => {
    let unmanaged = '';

    if (node.unmanaged) {
      unmanaged = 'unmanaged';
    }

    return `topology-tooltip-partition ${unmanaged}`;
  };

  const applicationCount =
    node?._references?.filter(
      reference => reference._edgeType === 'PART_HAS_DEPL'
    ).length ?? 0;

  return (
    <div className={getClassName()}>
      {getHeaderTitle()}

      <div className='body'>
        <div className='label'>
          {t('applications')}: {applicationCount}
        </div>
        <div className='label'>
          {t('autodiscover')}:{' '}
          {node?.auto_discover ? t('applications') : t('off')}
        </div>
      </div>
    </div>
  );
};

export default PartitionTooltip;
