import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Accordion,
  AccordionItem,
  Column,
  Row,
  SkeletonPlaceholder,
} from 'carbon-components-react';
import './SkupperConfiguration.scss';
import { SkupperComputeProfile } from '../../../models/master';

interface SkupperSiteConfig {
  ingress: string;
  ingress_host: string;
  router_ingress_host: string;
  controller_ingress_host: string;
  ingress_annotations: string;
}

interface Props {
  skupperSiteConfig: SkupperSiteConfig | null;
  gatewaySizing: SkupperComputeProfile | null;
}

const SkupperConfiguration: React.FC<Props> = ({
  skupperSiteConfig,
  gatewaySizing,
}) => {
  const { t } = useTranslation('gatewayDetails');

  return (
    <Accordion className='configuration-settings-accordion' align='start'>
      <AccordionItem title='Configuration settings'>
        <Row className='configuration-settings-row'>
          <Column md={4} lg={5} className='field-div-col'>
            <div className='gateway-sizing field-div'>
              <span className='label'>
                {t('skupperConfiguration.gatewaySizing.label')}
              </span>
              <span className='value'>
                {gatewaySizing
                  ? `${
                      gatewaySizing?.profile?.charAt(0).toUpperCase() +
                      gatewaySizing?.profile?.slice(1)
                    } (CPU: ${gatewaySizing?.cpu_lim},MEM: ${
                      gatewaySizing?.mem_lim
                    })`
                  : '—'}
              </span>
            </div>
          </Column>
          <Column md={4} lg={5} className='field-div-col'>
            <div className='ingress-type field-div'>
              <span className='label'>
                {t('skupperConfiguration.ingressType.label')}
              </span>
              <span className='value'>{skupperSiteConfig?.ingress || '—'}</span>
            </div>
          </Column>
          <Column md={4} lg={5} className='field-div-col'>
            <div className='ingress-type field-div'>
              <span className='label'>
                {t('skupperConfiguration.ingressHostName.label')}
              </span>
              <span className='value'>
                {skupperSiteConfig?.ingress_host || '—'}
              </span>
            </div>
          </Column>
          <Column md={4} lg={5} className='field-div-col'>
            <div className='router-ingress-host-name field-div'>
              <span className='label'>
                {t('skupperConfiguration.routerIngressHostName.label')}
              </span>
              <span className='value'>
                {skupperSiteConfig?.router_ingress_host || '—'}
              </span>
            </div>
          </Column>
          <Column md={4} lg={5} className='field-div-col'>
            <div className='controller-ingress-host-name field-div'>
              <span className='label'>
                {t('skupperConfiguration.controllerIngressHostName.label')}
              </span>
              <span className='value'>
                {skupperSiteConfig?.controller_ingress_host || '—'}
              </span>
            </div>
          </Column>
          <Column md={4} lg={5} className='field-div-col'>
            <div className='ingress-annotations field-div'>
              <span className='label'>
                {t('skupperConfiguration.ingressAnnotations.label')}
              </span>
              <span className='value'>
                {skupperSiteConfig?.ingress_annotations || '—'}
              </span>
            </div>
          </Column>
        </Row>
      </AccordionItem>
    </Accordion>
  );
};

export default SkupperConfiguration;
