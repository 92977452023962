import images from '../../images/images';

export const resourceTypeIcon = {
  vpc: images.deploymentEnvIcon(),
  node: images.deploymentEnvIcon(),
  deploymentenv: images.deploymentEnvIcon(),
  cluster: images.deploymentEnvIcon(),
  cloud: images.cloudSingleIcon(),
  gateway: images.EdgeGatewayIcon(),
  app: images.applicationIcon(),
  policy: images.policyIcon(),
  location: images.LocationIcon(),
  service: images.serviceIconSmall(),
  partition: images.partitionIcon(),
  securitygroup: images.partitionIcon(),
  namespace: images.partitionIcon(),
};

export const getResourceTypeIcon = (
  resourceType: keyof typeof resourceTypeIcon
) => {
  return resourceTypeIcon[resourceType] ?? '';
};
