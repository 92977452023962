import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, TooltipDefinition } from 'carbon-components-react';
import images from '../../images/images';

import './GenericManageButton.scss';

interface Props {
  isCheckboxDisabled?: boolean;
  checked: boolean;
  showTooltip: boolean;
  labelText?: string;
  toolTipText?: string;
  id?: string;
  handleCheckbox: (checked: boolean) => void;
}

const GenericManageButton: React.FC<Props> = ({
  isCheckboxDisabled,
  checked,
  showTooltip,
  handleCheckbox,
  toolTipText,
  id = 'manageCheckbox',
  labelText,
}) => {
  const { t } = useTranslation('registerLocation');
  return (
    <div
      className={
        isCheckboxDisabled ? `manage-button-disabled` : 'manage-button'
      }
    >
      <Checkbox
        className='manage-button-checkbox'
        id={id}
        data-testid='manage-checkbox'
        checked={checked}
        onChange={(checked: boolean) => {
          handleCheckbox(checked);
        }}
        labelText={labelText || t('manage')}
        readOnly={isCheckboxDisabled}
        disabled={isCheckboxDisabled}
      />

      {showTooltip ? (
        <TooltipDefinition
          direction='bottom'
          className='manage-button-tooltip'
          align='end'
          tooltipText={toolTipText as string}
        >
          {images.InfoIconSvgTooltip()}
        </TooltipDefinition>
      ) : null}
    </div>
  );
};

export default GenericManageButton;
