import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  InlineNotification,
  ComboBox,
  TooltipDefinition,
  Row,
  Column,
  Dropdown,
  SkeletonPlaceholder,
} from 'carbon-components-react';
import { CreateTearsheetStep } from '@carbon/ibm-products';

import {
  DeploymentEnvironmentSubtype,
  Location,
} from '../../../../models/master';

import IconWithToolTip from '../../../../components/IconWithToolTip/IconWithToolTip';

import images from '../../../../images/images';
import { getDeploymentSubTypeIcon } from '../../../../lib/constants';
import { getThirdPartyCloudIconsDropdown } from '../../../Cloud/ThirdPartyCloudIcons';

import './CloudSelect.scss';

import { FormData, ModifiedCloud } from '../RegisterDeploymentEnv';
import GenericStatusField from '../../../../components/GenericStatusField/GenericStatusField';

interface Props {
  selectedDeplEnvSubtype: DeploymentEnvironmentSubtype | null;
  selectSubtypeCallback: (cloud: any) => void;
  toggleComplete: (val: boolean) => void;
  complete: boolean;
  subtypeList: DeploymentEnvironmentSubtype[] | null;
  selectedCloud: ModifiedCloud | null;
  cloudList: ModifiedCloud[];
  selectCloudCallback: (cloud: ModifiedCloud | null) => void;
  locations: Location[] | null;
  onChange: any;
  formData: FormData;
  loading: boolean;
  isEditMode?: boolean | string;
  hasLocationAuthorization: boolean;
  disableLocationDropdown: boolean;
}

const CloudSelect: React.FC<Props> = ({
  selectSubtypeCallback,
  selectedDeplEnvSubtype,
  subtypeList,
  toggleComplete,
  complete,
  cloudList,
  selectedCloud,
  selectCloudCallback,
  locations,
  onChange,
  formData,
  loading,
  isEditMode,
  hasLocationAuthorization = true,
  disableLocationDropdown,
}) => {
  const { t } = useTranslation('registerDeploymentEnv');
  const subTypeRef = useRef<any>(null);
  const locationDropdownRef = useRef<any>(null);

  const [filteredLocationList, setFilteredLocationList] = useState<
    Location[] | null
  >(null);

  useEffect(() => {
    if (selectedCloud != null && locations != null) {
      let filteredLocations = locations?.filter(
        (location: Location) => location?.cloud_id === selectedCloud.resource_id
      );
      if (Array.isArray(filteredLocations)) {
        setFilteredLocationList([...filteredLocations]);
      }
    }
  }, [selectedCloud, locations]);

  const subtypeSelected = (type: DeploymentEnvironmentSubtype) => {
    selectSubtypeCallback(type);
  };

  const selectCloud = (cloud: ModifiedCloud | null) => {
    selectCloudCallback(cloud ?? null);
  };

  return (
    <CreateTearsheetStep
      className='step-one select-cloud-container-tearsheet'
      hasFieldset={false}
      title={t('cloudSelect.title')}
      disableSubmit={
        selectedDeplEnvSubtype === null || formData?.location?.id === ''
      }
      onNext={() => toggleComplete(true)}
      includeStep={!isEditMode}
      data-testid='deployment-env-select-cloud-step'
    >
      <span data-testid='select-cloud-heading' className='heading'>
        {t('cloudSelect.selectCloud')}
      </span>
      {loading ? (
        <div className='loader-skeleton-container'>
          <Row className='skeleton-row'>
            <Column>
              <SkeletonPlaceholder className='cloud-select-skeleton' />
            </Column>
            <Column>
              <SkeletonPlaceholder className='cloud-select-skeleton' />
            </Column>
          </Row>
        </div>
      ) : (
        <>
          <div className='select-cloud'>
            {complete && cloudList.length > 0 && (
              <InlineNotification
                kind='warning'
                aria-label={t('cloudSelect.warningNotification.ariaLabel')}
                title={t('cloudSelect.warningNotification.title') as string}
                lowContrast
              />
            )}
            {cloudList.length === 0 && selectedCloud === null && (
              <InlineNotification
                kind='error'
                title={
                  t('cloudSelect.warningNotification.noCloudTitle') as string
                }
                lowContrast
                hideCloseButton={true}
              />
            )}
            {Array.isArray(subtypeList) &&
              subtypeList.length === 0 &&
              selectedCloud != null && (
                <InlineNotification
                  kind='error'
                  title={
                    t(
                      'cloudSelect.warningNotification.noSubTypesTitle'
                    ) as string
                  }
                  lowContrast
                  hideCloseButton={true}
                />
              )}
            {
              <>
                <Row>
                  <Column lg={8} md={4} sm={4} xlg={8} max={8}>
                    <Dropdown
                      className={`select-cloud-dropdown ${
                        cloudList.length === 0 && selectedCloud != null
                          ? 'disabled'
                          : 'enabled'
                      }`}
                      id={`select-cloud-dropdown-id ${
                        cloudList.length === 0 && selectedCloud != null
                          ? 'disabled'
                          : 'enabled'
                      }`}
                      selectedItem={selectedCloud}
                      renderSelectedItem={(selectedCloud: any) =>
                        selectedCloud.itemToRender
                      }
                      items={cloudList}
                      translateWithId={t}
                      itemToElement={(cloud: ModifiedCloud) => (
                        <div
                          className='option'
                          id={cloud?.resource_id}
                          key={cloud?.resource_id}
                        >
                          <span className='cloud-icon'>
                            {getThirdPartyCloudIconsDropdown(cloud?.type)}
                          </span>
                          <span className='cloud-name'>
                            {cloud?.name ?? '—'}
                          </span>
                        </div>
                      )}
                      onChange={(e: any) => {
                        selectCloud(e.selectedItem ?? null);
                        onChange('location', {
                          id: '',
                          name: '',
                          type: '',
                        });
                        locationDropdownRef?.current?.classList.add('focus-on');
                      }}
                      label={t('cloudSelect.selectCloudDropdown.label')}
                      titleText={t('cloudSelect.selectCloudDropdown.titleText')}
                      disabled={cloudList.length === 0 && selectedCloud != null}
                      invalid={cloudList.length === 0 && selectedCloud === null}
                      invalidText={t(
                        'cloudSelect.selectCloudDropdown.invalidText'
                      )}
                      data-testid='select-cloud-dropdown'
                    />
                  </Column>
                  <Column
                    lg={8}
                    md={4}
                    sm={4}
                    xlg={8}
                    max={8}
                    className='select-location-dropdown-column'
                  >
                    <div
                      className={`select-location-dropdown-container ${
                        selectedCloud != null &&
                        Array.isArray(filteredLocationList) &&
                        filteredLocationList.length > 0
                          ? 'focus-on'
                          : ''
                      }`}
                      ref={locationDropdownRef}
                    >
                      {hasLocationAuthorization ? (
                        <ComboBox
                          id='select-location-dropdown'
                          className={`select-location-dropdown ${
                            disableLocationDropdown ? 'disabled' : 'enabled'
                          }`}
                          selectedItem={formData?.location}
                          items={
                            filteredLocationList?.map((location: Location) => ({
                              name: location?.name ?? '',
                              id: location?.resource_id ?? '',
                              type: location?.type ?? '',
                            })) ?? []
                          }
                          translateWithId={t}
                          itemToString={item => item?.name || ''}
                          itemToElement={(location: any) => (
                            <div
                              className='location-option'
                              id={location?.resource_id}
                              key={location?.resource_id}
                            >
                              <span className='location-name'>
                                {location?.name ?? ''}
                              </span>
                              <span className='location-type'>
                                {location?.type
                                  ? location?.type[0].toUpperCase() +
                                      location?.type.slice(1) ?? ''
                                  : ''}
                              </span>
                            </div>
                          )}
                          onChange={e => {
                            onChange('location', e.selectedItem);
                            locationDropdownRef?.current?.classList.remove(
                              'focus-on'
                            );
                            subTypeRef?.current?.firstChild.classList.remove(
                              'focus-on'
                            );
                          }}
                          placeholder={t(
                            'cloudSelect.selectLocationDropdown.placeHolder'
                          )}
                          titleText={t(
                            'cloudSelect.selectLocationDropdown.titleText'
                          )}
                          invalid={
                            selectedCloud != null &&
                            filteredLocationList?.length === 0
                          }
                          invalidText={t(
                            'cloudSelect.selectLocationDropdown.invalidText'
                          )}
                          data-testid='select-location-dropdown'
                          readOnly={disableLocationDropdown}
                        />
                      ) : (
                        <div className='generic-notAuthorised-container'>
                          <span className='label'>
                            {t('cloudSelect.selectLocationDropdown.titleText')}
                          </span>
                          <div className='generic-status-container'>
                            <GenericStatusField status='notAuthorised'></GenericStatusField>
                          </div>
                        </div>
                      )}
                    </div>
                  </Column>
                </Row>
              </>
            }
          </div>
          {selectedCloud && (
            <div
              className='select-subtype-container cloud-subtype-container'
              data-testid='select-subtype-container'
            >
              {Array.isArray(subtypeList) && (
                <>
                  <span className='heading'>
                    {t('cloudSelect.selectEnvironment')}
                  </span>
                  <div className={`select-subtype `} ref={subTypeRef}>
                    {subtypeList.map(
                      (type: DeploymentEnvironmentSubtype, index) => (
                        <Column
                          lg={7}
                          xlg={5}
                          max={5}
                          sm={4}
                          md={4}
                          className={
                            selectedDeplEnvSubtype &&
                            selectedDeplEnvSubtype.type_name === type.type_name
                              ? 'cloud-card cloud-card-border'
                              : index === 0 &&
                                formData?.location?.id !== '' &&
                                selectedDeplEnvSubtype === null
                              ? 'cloud-card focus-on'
                              : 'cloud-card'
                          }
                          key={type.resource_id}
                          onClick={() => {
                            subtypeSelected(type);
                            subTypeRef?.current?.firstChild.classList.remove(
                              'focus-on'
                            );
                          }}
                        >
                          <div className='title'>
                            <div className='wrapInfo'>
                              <TooltipDefinition
                                tooltipText={type.type_name}
                                className='generic-truncate-string'
                              >
                                <div className='description-container'>
                                  <div
                                    className='description'
                                    data-testid='cloud-env-name'
                                  >
                                    {type.type_name}
                                  </div>
                                </div>
                              </TooltipDefinition>
                              <div className='info-icon'>
                                {type.description && (
                                  <IconWithToolTip
                                    icon={images.informationIcon()}
                                    iconDescription={type.description}
                                  />
                                )}
                              </div>
                            </div>
                            {selectedDeplEnvSubtype &&
                            selectedDeplEnvSubtype.type_name ===
                              type.type_name ? (
                              <div className='selected-cloud'>
                                {images.selectedTickIcon()}
                              </div>
                            ) : null}
                          </div>
                          <div className='cloud-icon'>
                            {getDeploymentSubTypeIcon[type?.type_code] ||
                              images.otherCloudIcon()}
                          </div>
                        </Column>
                      )
                    )}
                  </div>
                </>
              )}
            </div>
          )}
        </>
      )}
    </CreateTearsheetStep>
  );
};

export default CloudSelect;
