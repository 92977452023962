import { Column, Row } from 'carbon-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import images from '../../../images/images';
import './LegendNodes.scss';

const LegendNodes = () => {
  const { t } = useTranslation('legendContainer');
  return (
    <div className='topology-legends-main-section'>
      <Row>
        <Column lg={3}>
          <div className='nodes-headers'>{t('nodes.locations')} </div>
          <Row className='node-section-row'>
            <Column className='nodes-section-items'>
              <span className='nodes-location'>
                {images.locationLegendSvg()}
              </span>
              <span className='nodes-sub-section-label'>
                {t('nodes.location')}
              </span>
            </Column>
          </Row>
        </Column>

        <Column lg={6}>
          <div className='nodes-headers'>{t('nodes.environments')}</div>

          <Row className='node-section-row'>
            <Column className='nodes-section-items'>
              <span className='nodes-location'>
                {images.clusterLegendSvg()}
              </span>
              <span className='nodes-sub-section-label'>
                {t('nodes.cluster')}
              </span>
            </Column>
            <Column className='nodes-section-items'>
              <span className='nodes-location'>{images.nodeLegendSvg()}</span>
              <span className='nodes-sub-section-label'>{t('nodes.node')}</span>
            </Column>
          </Row>
          <Row className='node-section-row'>
            <Column className='nodes-section-items'>
              <span className='nodes-location'>{images.vpcLegendSvg()}</span>
              <span className='nodes-sub-section-label'>{t('nodes.vpc')}</span>
            </Column>
            <Column className='nodes-section-items'>
              <span className='nodes-location'>
                {images.partitionLegendSvg()}
              </span>
              <span className='nodes-sub-section-label'>
                {t('nodes.partition')}
              </span>
            </Column>
          </Row>
          <Row className='node-section-row'>
            <Column className='nodes-section-items'>
              <span className='nodes-location'>{images.infraLegendSvg()}</span>
              <span className='helper-column'>
                <span className='nodes-sub-section-label'>
                  {t('nodes.vpc')}
                </span>
                <div className='helper-text'>{t('nodes.infraOnly')}</div>
              </span>
            </Column>
          </Row>
        </Column>

        <Column lg={3}>
          <div className='nodes-headers'>{t('nodes.applications')}</div>
          <Row className='node-section-row'>
            <Column className='nodes-section-items'>
              <div className='nodes-location'>
                {images.applicationLegendSvg()}
              </div>
              <div className='nodes-sub-section-label'>
                {t('nodes.application')}
              </div>
            </Column>
          </Row>
          <Row className='node-section-row'>
            <Column className='nodes-section-items'>
              <div className='nodes-location'>{images.serviceLegendSvg()}</div>
              <div className='nodes-sub-section-label'>
                {t('nodes.service')}
              </div>
            </Column>
          </Row>
        </Column>

        <Column lg={3}>
          <div className='nodes-headers'>{t('nodes.gateways')}</div>
          <Row className='node-section-row'>
            <Column className='nodes-section-items'>
              <div className='nodes-location'>{images.edgeLegendSvg()}</div>
              <div className='nodes-sub-section-label'>{t('nodes.edge')}</div>
            </Column>
          </Row>
          <Row className='node-section-row'>
            <Column className='nodes-section-items'>
              <div className='nodes-location'>{images.waypointLegendSvg()}</div>
              <div className='nodes-sub-section-label'>{t('nodes.wp')}</div>
            </Column>
          </Row>
        </Column>
      </Row>
    </div>
  );
};

export default LegendNodes;
