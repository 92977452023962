import { useQuery } from '@tanstack/react-query';
import { getPolicies } from '../controllers/policy';
import { QueryOptions, queryDefaultOptions } from './queryDefaults';
import { QueryKeys } from '../lib/enums';

interface PolicyDataParams {
  fetchServices: boolean;
  fetchApplications: boolean;
  count?: number;
}

export const usePoliciesData = (
  params: PolicyDataParams,
  options?: QueryOptions
) => {
  return useQuery({
    queryKey: [QueryKeys.POLICIES],
    queryFn: () =>
      getPolicies(params.fetchServices, params.fetchApplications, params.count),
    ...queryDefaultOptions,
    ...options,
  });
};
