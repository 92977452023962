import { Column, Row } from 'carbon-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import images from '../../../images/images';
import './LegendConnections.scss';

const Legendconnections = () => {
  const { t } = useTranslation('legendContainer');
  return (
    <Row className='topology-legend-connection-section-row'>
      <Column className='connections-section-items'>
        <div className='connections-sub-section-label'>
          {t('connections.active')}
        </div>
        <div className='connections-active'>{images.activeLegendSvg()}</div>
        <div className='connections-active2'>{images.activeLegendSvg()}</div>
      </Column>
      <Column className='connections-section-items'>
        <div className='connections-sub-section-label'>
          {t('connections.relational')}
        </div>
        <div className='connections-active'>{images.relationalLegendSvg()}</div>
      </Column>
      <Column className='connections-section-items'>
        <div className='connections-sub-section-label'>
          {t('connections.policy')}
        </div>
        <div className='connections-active'>{images.policyLegendSvg()}</div>
      </Column>
      <Column className='connections-section-items'>
        <div className='connections-sub-section-label'>
          {t('connections.gateway')}
        </div>
        <div className='connections-active'>{images.activeLegendSvg()}</div>
        <div className='connections-active1'>{images.activeLegendSvg()}</div>
        <div className='connections-active1'>{images.activeLegendSvg()}</div>
      </Column>
    </Row>
  );
};

export default Legendconnections;
