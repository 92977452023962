import React from 'react';
import { Row, Column, Dropdown, Button } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import { Add16 } from '@carbon/icons-react';
import {
  ApplicationData,
  DeploymentEnvironment,
  PartitionData,
  ServiceData,
} from '../../../../models/master';

import AutofillSearch from '../../../../components/AutofillSearch/AutofillSearch';
import './ConnectionAccessPoliciesFilter.scss';

interface Filter {
  key: string;
  type: 'multi' | 'single';
  values: string[];
  mdWidth?: number;
  lgWidth?: number;
  label: string;
}

interface DropdownOption {
  type: string;
  suggestionTitle: string;
  suggestionOptions:
    | ApplicationData[]
    | DeploymentEnvironment[]
    | ServiceData[]
    | PartitionData[]
    | null;
}

const ConnectionAccessPoliciesFilter = (props: {
  data: DropdownOption[];
  onSelectCallback?: (
    data:
      | ApplicationData[]
      | DeploymentEnvironment[]
      | ServiceData[]
      | PartitionData[]
      | []
  ) => void;
  onViewChange: (view: string) => void;
  actionButton?:
    | {
        text: string;
        actionButtonCallback: () => void;
      }
    | undefined;
  leftInlineFilters?: Filter[];
  onViewAllBtnClick: () => void;
  onOptionAdd: (item: any, type: any) => void;
  filterView: string;
}) => {
  const { t } = useTranslation('connectionAccessPolicyFilter');
  const { data, leftInlineFilters, onViewChange, filterView } = props;

  return (
    <div className='policiesFilter'>
      <Row className='filterContainer'>
        <div className='leftFiltersContainer'>
          {(Array.isArray(leftInlineFilters)
            ? leftInlineFilters
            : [
                {
                  key: t('view'),
                  type: '',
                  values: [
                    t('leftFilter.dropdownOptionAll'),
                    ...data
                      ?.map(item => item.suggestionTitle)
                      .filter(
                        (value, index, self) => self.indexOf(value) === index
                      ),
                  ],
                  label: t('leftFilter.label') as string,
                },
              ]
          ).map((filter, index) => (
            <div className='dropdown' key={`leftfilter${index}`}>
              <Dropdown
                id='inline'
                titleText={filter.label}
                label={t('filter') as string}
                items={filter.values}
                selectedItem={filterView}
                itemToString={item => item}
                type='default'
                onChange={view => {
                  onViewChange(view.selectedItem as any);
                }}
              />
            </div>
          ))}
        </div>

        <Column className='searchBar'>
          <AutofillSearch
            suggestions={data as any}
            onClick={(i: any, t: string) => {
              props.onOptionAdd(i, t);
            }}
          ></AutofillSearch>
        </Column>
        <Button
          kind='ghost'
          className='add-button'
          onClick={() => props.onViewAllBtnClick()}
          renderIcon={Add16}
        >
          {t('viewAllOption')}{' '}
        </Button>
      </Row>
    </div>
  );
};
export default ConnectionAccessPoliciesFilter;
