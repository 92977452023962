import React, { useEffect, useState } from 'react';
import { SkeletonPlaceholder } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import {
  DeploymentEnvironmentSubtype,
  Cloud,
  Vpc,
  Cluster,
  Node,
} from '../../../../models/master';
import { ComponentProps } from '../config';
import './DeploymentEnvironmentTooltip.scss';
import { getDeploymentEnvSubtypes } from '../../../../controllers/deploymentEnv';
import {
  getCloud,
  getCloudCluster,
  getCloudNode,
  getCloudVpc,
} from '../../../../controllers/cloudApis';
import IconWithToolTip from '../../../IconWithToolTip/IconWithToolTip';
import images from '../../../../images/images';

const DeploymentEnvironmentTooltip: React.FC<ComponentProps> = ({ node }) => {
  const { t } = useTranslation('topologyTooltipDeploymentEnvironment');
  const [loading, setLoading] = useState(false);
  const [depEnvSubtypeName, setDepEnvSubtypeName] = useState<string>('');
  const [autoDiscover, setAutoDiscover] = useState<string>('');
  const [cloudType, setCloudType] = useState<string>('');
  const [nodeType, setNodeType] = useState<string>('');

  useEffect(() => {
    const typeOfNode = node?.uniqueId.split('-')[0];
    setNodeType(typeOfNode);

    const fetchData = async () => {
      try {
        setLoading(true);

        const type = node?.resource_id?.split('-')[0];

        const autoDiscoverValue = getAutoDiscover(
          type,
          node?.auto_discover ?? false
        );
        setAutoDiscover(autoDiscoverValue);

        let deploymentEnvData: Vpc | Cluster | Node | undefined;
        if (type === 'cluster') {
          deploymentEnvData = await getCloudCluster(
            node.cloud_id,
            node.resource_id
          );
        } else if (type === 'vpc') {
          deploymentEnvData = await getCloudVpc(
            node.cloud_id,
            node.resource_id
          );
        } else if (type === 'node') {
          deploymentEnvData = await getCloudNode(
            node.cloud_id,
            node.resource_id
          );
        }

        if (deploymentEnvData) {
          const cloudData: Cloud = await getCloud(deploymentEnvData?.cloud_id);
          setCloudType(cloudData?.type);

          const deploymentEnvSubTypes: DeploymentEnvironmentSubtype[] =
            await getDeploymentEnvSubtypes();
          if (deploymentEnvSubTypes) {
            const depEnvSubtypeData = deploymentEnvSubTypes.find(
              subtype => subtype.type_code === deploymentEnvData?.type
            );

            depEnvSubtypeData != null &&
              setDepEnvSubtypeName(depEnvSubtypeData?.type_name);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [node]);

  const getAutoDiscover = (type: string, autoDiscover: boolean) => {
    let autoDiscoverValue: string;
    switch (type) {
      case 'vpc':
        autoDiscoverValue = autoDiscover ? t('appsSecurityGroup') : t('off');
        break;
      case 'cluster':
        autoDiscoverValue = autoDiscover ? t('namespace') : t('off');
        break;
      case 'node':
        autoDiscoverValue = autoDiscover ? t('application') : t('off');
        break;
      default:
        autoDiscoverValue = t('off');
        break;
    }
    return autoDiscoverValue;
  };

  const getHeaderTitle = () => {
    if (node?.is_discovered) {
      return (
        <div className='header'>
          <span className='header-title'>{node?.name}</span>
          <span className='page-title-icon'>
            <IconWithToolTip
              icon={images.AutoDiscoverdLockIcon()}
              iconDescription={t('autoDiscoveredAccessLimited')}
            />
          </span>
        </div>
      );
    }

    return (
      <div className='header'>
        <span className='header-title'>{node.name}</span>
      </div>
    );
  };

  const getClassName = () => {
    let unmanaged = '';

    if (node.unmanaged) {
      unmanaged = 'unmanaged';
    }

    return `topology-tooltip-deploymentenv ${unmanaged}`;
  };

  return (
    <div className={getClassName()}>
      {getHeaderTitle()}
      {loading && (
        <div className='skeleton-container'>
          <SkeletonPlaceholder className='service-skeleton'></SkeletonPlaceholder>
          <SkeletonPlaceholder className='service-skeleton'></SkeletonPlaceholder>
          <SkeletonPlaceholder className='service-skeleton'></SkeletonPlaceholder>
          <SkeletonPlaceholder className='service-skeleton'></SkeletonPlaceholder>
        </div>
      )}
      {!loading && (
        <div className='body'>
          <div className='data-field'>
            <div className='field-label'> {t('depEnvSubtypeName')}:</div>
            <div className='field-value'>{depEnvSubtypeName}</div>
          </div>
          {cloudType && (
            <div className='data-field'>
              <div className='field-label'>{t('cloud')}:</div>
              <div className='field-value'>{cloudType}</div>
            </div>
          )}
          {autoDiscover && (
            <div className='data-field'>
              <div className='field-label'>{t('autodiscover')}:</div>
              <div className='field-value'>
                {autoDiscover !== t('off') && (
                  <div className='autoDiscoverIcon'>
                    {images.autoDiscoverLabelIcon()}
                  </div>
                )}
                {autoDiscover}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DeploymentEnvironmentTooltip;
