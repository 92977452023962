import React, { useState, useContext, useEffect } from 'react';
//import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';

import AddDetails, { FormDataStep1 } from './AddDetails';
import AllowConnections from './AllowConnections/AllowConnections';
import MultiStepTearSheet from '../../../components/MultiStepTearSheet/MultiStepTearSheet';
import InlineNotification from '../../../components/Notifications/Inline/Notification';
import { NotificationContext } from '../../../components/Notifications/Context/NotificationProvider';
import useAnalytics from '../../../lib/useAnalytics';
import { addPolicy } from '../../../controllers/policyApi';
import { getResourceGroups } from '../../../controllers/resourceGroupApi';
import { getNetworkSegments } from '../../../controllers/networksegmentsApi';
import { removeExtraSpace } from '../../../lib/utils';
import {
  ApplicationData,
  PartitionData,
  NetworkSegments,
  PolicyData,
  ResourceGroup,
} from '../../../models/master';
import PolicyOptions from '../ConnectionAccessPoliciesOptions/PolicyOptions';
import {
  FormData,
  Item,
  ServiceData,
  SelectedType,
  TableTypes,
} from '../config';

import './CreatePolicy.scss';
import analyticsData from '../../../lib/analyticsEventData';
import { NetworkSegmentCompatibilitySet } from '../../../lib/enums';
import { DEFAULT_NETWORK_SEGMENT_ID } from '../../../lib/constants';

interface Props {
  open: boolean;
  onClose: () => void;
  onAdd: () => void;
  onRefresh: (t: TableTypes) => void;
  applications: ApplicationData[] | null;
  services: ServiceData[] | null;
  resourceGroups: ResourceGroup[] | null;
  partitions: PartitionData[] | null;
  policyList: PolicyData[] | [] | null;
  partitionPermission?: boolean;
  applicationPermission?: boolean;
  servicePermission?: boolean;
}

const CreatePolicy: React.FC<Props> = ({
  open,
  onClose,
  onAdd,
  onRefresh,
  applications,
  services,
  resourceGroups,
  partitions,
  policyList,
  partitionPermission,
  applicationPermission,
  servicePermission,
}) => {
  const form = {
    step1: {
      name: {
        value: '',
        error: false,
        errorMessage: '',
      },
      description: {
        value: '',
        error: false,
        errorMessage: '',
      },
      resourceGroup: {
        value: {
          resource_id: 'default-app',
          name: 'Default_Application_Group',
        },
        error: false,
        errorMessage: '',
      },
      networkSegment: {
        value: null,
        error: false,
        errorMessage: '',
      },
      resourceGroupList: [],
      nwSegmentList: [],
      labels: {
        value: [],
        error: false,
        errorMessage: '',
      },
    },
    step2: {
      from: [],
      to: [],
    },
  };

  const [formData, setFormData] = useState<{
    step1: FormDataStep1;
    step2: FormData;
  }>(form);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [subTitleErrorMsg, setSubTitleErrorMsg] = useState('');

  const { t } = useTranslation('createPolicy');

  const notification = useContext(NotificationContext);
  const [viewAllOptions, setViewAllOptions] = useState(false);
  const [selected, setSelected] = useState<SelectedType>('from');
  const [fromSelectedItems, setFromSelectedItems] = useState<Item[]>([]);
  const [toSelectedItems, setToSelectedItems] = useState<Item[]>([]);
  const [fromItems, setFromItems] = useState<Item[]>([]);
  const [toItems, setToItems] = useState<Item[]>([]);
  const [isNwSegmentListLoading, setNwSegmentListLoading] = useState(false);
  const [encryptedPathStatus, setEncryptedPathStatus] =
    useState<boolean>(false);

  const { trackButtonClicked } = useAnalytics();

  useEffect(() => {
    if (open) fetchLists();
  }, [open]);

  useEffect(() => {
    if (
      formData?.step1?.networkSegment?.value?.compatibility_set ===
      NetworkSegmentCompatibilitySet.ServiceInterconnect
    ) {
      setSelected('to');
      setFromSelectedItems([
        {
          id: formData?.step1?.networkSegment?.value?.resource_id ?? '',
          label: formData?.step1?.networkSegment?.value?.name,
          type: 'nwSegments',
        },
      ]);
      setFromItems([
        {
          id: formData?.step1?.networkSegment?.value?.resource_id ?? '',
          label: formData?.step1?.networkSegment?.value?.name,
          type: 'nwSegments',
        },
      ]);
    } else {
      setSelected('from');
      setFromSelectedItems([]);
      setFromItems([]);
    }
  }, [formData?.step1?.networkSegment?.value]);

  const fetchLists = async () => {
    await fetchNwSegmentsList();
    getResourceGroups().then(response => {
      setFormData(form => ({
        ...form,
        step1: {
          ...form.step1,
          resourceGroupList: response.resource_groups?.filter(
            (group: { type: string }) => group?.type === 'application'
          ),
        },
      }));
    });
  };

  const fetchNwSegmentsList = async () => {
    try {
      setNwSegmentListLoading(true);
      const response = await getNetworkSegments();
      setFormData(form => ({
        ...form,
        step1: {
          ...form.step1,
          nwSegmentList: response,
        },
      }));
    } catch (error) {
      console.error(error);
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        console.error(error);
      }
    } finally {
      setNwSegmentListLoading(false);
    }
  };

  useEffect(() => {
    handleStep2({ from: fromItems, to: toItems });
  }, [fromItems, toItems]);

  const handleStep2 = (data: FormData) => {
    setFormData(form => ({
      ...form,
      step2: data,
    }));
  };

  const checkFieldValid = (name: string, value: any, networkSegment?: any) => {
    let errorMessage = '';

    switch (name) {
      case 'name':
        const valueEmpty =
          value === '' || value === null || value === undefined ? true : false;
        const networkSegmentVal = networkSegment
          ? networkSegment
          : formData?.step1?.networkSegment?.value;
        const filteredPolicyList =
          policyList?.filter(
            (policy: PolicyData) =>
              policy?.network_segment_id === networkSegmentVal?.resource_id &&
              policy.name === value
          ) ?? [];
        const notUnique = filteredPolicyList?.length > 0 ? true : false;
        errorMessage = valueEmpty
          ? t('nameError')
          : notUnique
          ? t('nameExists')
          : '';
        break;
      case 'resourceGroup':
        if (value === '' || value === null || value === undefined)
          errorMessage = t('resourceGroupError');
        break;
      case 'networkSegment':
        if (value === '' || value === null || value === undefined)
          errorMessage = t('networkSegmentError');
        break;
    }

    return errorMessage;
  };

  const handleOnChange = (e: { target: { name: string; value: any } }) => {
    // Clear error snackbar if present
    if (showErrorSnackbar) {
      setShowErrorSnackbar(false);
      setAuthError(false);
    }
    const name = e.target.name;
    const value = e?.target?.value;

    let errorMessage = checkFieldValid(name, value);
    if (name === 'networkSegment') {
      if (
        e?.target?.value?.resource_id !==
        formData.step1.networkSegment?.value?.resource_id
      ) {
        setFromItems([]);
        setFromSelectedItems([]);
        setToItems([]);
        setToSelectedItems([]);
      }
      let nameError = checkFieldValid('name', formData.step1.name.value, value);
      setFormData(prevState => ({
        ...prevState,
        [`step1`]: {
          ...prevState[`step1`],
          [name]: {
            value,
            error: !!errorMessage,
            errorMessage,
          },
          name: {
            value: formData.step1.name.value,
            error: !!nameError,
            errorMessage: nameError,
          },
        },
      }));
    } else
      setFormData(prevState => ({
        ...prevState,
        [`step1`]: {
          ...prevState[`step1`],
          [name]: {
            value,
            error: !!errorMessage,
            errorMessage,
          },
        },
      }));

    name === 'useEncryptedPath' && setEncryptedPathStatus(value);
  };

  const checkFormValid = () => {
    let isFormValid = true;
    let nameError = checkFieldValid('name', formData.step1.name.value);
    let resourceGroupErr = checkFieldValid(
      'resourceGroup',
      formData.step1.resourceGroup.value
    );
    let networkSegmentErr = checkFieldValid(
      'networkSegment',
      formData.step1.networkSegment.value
    );
    if (
      nameError.length > 0 ||
      resourceGroupErr.length > 0 ||
      networkSegmentErr.length > 0
    ) {
      isFormValid = false;
    }
    if (nameError.length > 0) {
      isFormValid = false;
    }

    return isFormValid;
  };

  const handlePolicySubmit = async () => {
    trackButtonClicked(
      analyticsData['Policies'].events.createPolicy.props,
      analyticsData['Policies'].events.createPolicy.event
    );
    try {
      // Clear error snackbar if present
      if (showErrorSnackbar) {
        setShowErrorSnackbar(false);
        setAuthError(false);
      }
      const value = formData.step1.resourceGroup?.value;
      const type = formData.step2?.from?.[0]?.type;
      const policyDetails = {
        name: removeExtraSpace(formData?.step1?.name?.value),
        description: formData?.step1?.description?.value,
        // Note : Remove Resource Group for Experimental release.
        resource_group_id: value?.resource_id,
        resourceGroup: formData.step1.resourceGroup.value,
        labels: formData?.step1?.labels?.value,
        network_segment_id:
          formData?.step1?.networkSegment?.value?.resource_id ?? '',
        from:
          type === 'applications'
            ? {
                application: {
                  application_id: formData.step2.from[0].id,
                },
                type: 'application',
              }
            : type === 'partitions'
            ? {
                deployment_env: {
                  deployment_env_id: formData.step2.from[0]?.parentId,
                },
                partition: {
                  partition_id: formData.step2.from[0].id,
                },
                type: 'partition',
              }
            : type === 'nwSegments'
            ? {
                network_segment: {
                  network_segment_id: formData.step2.from[0].id,
                },
                type: 'networkSegment',
              }
            : {},
        to: {
          service: {
            // application_id: formData.step2.to[0]?.application_id,
            service_id: formData.step2.to[0].id
              ? formData.step2.to[0].id.split('+')[0]
              : '',
          },
          type: 'service',
        },
        action: 'ALLOW',
      };

      const policy = await addPolicy(policyDetails);
      // Refreshes data on successful policy addition
      onClose();
      onAdd();

      // Display Toast notification on successful policy creation
      notification.onTrigger('TOAST', {
        title: t('toast.title'),
        subtitle: t('toast.subtitle', { name: policy.name }),
      });
    } catch (error: any) {
      const err = error as AxiosError;
      const errorMessage: string =
        error.response !== undefined
          ? error.response['customErrorMessage']
          : '';
      // TODO: We need to refactor error handling mechanism given below.
      // Right now only handling duplicate policy name assuming that all 422 errors are caused by it,
      // since we are not getting any proper unique error key.
      if (err.response?.status === 422) {
        setAuthError(false);
        setShowErrorSnackbar(true);
        errorMessage.length > 0 && setSubTitleErrorMsg(errorMessage);
        return Promise.reject(() => console.log(error));
      }

      if (err.response?.status === 403) {
        setAuthError(true);
        setShowErrorSnackbar(true);
        return Promise.reject(() => console.log(error));
      }

      errorMessage.length > 0
        ? setSubTitleErrorMsg(errorMessage)
        : setSubTitleErrorMsg(t('error.genericSubTitle'));

      setShowErrorSnackbar(true);
      setAuthError(false);
      return Promise.reject(() => console.log(error));
    }
  };

  const handleErrorBarClose = () => {
    setShowErrorSnackbar(false) as any;
    setAuthError(false);
  };

  const renderErrorSnackbar = () => (
    <div className='connection-access-policy-error-snackbar'>
      <InlineNotification
        kind='error'
        title={
          authError
            ? (t('error.authTitle') as string)
            : subTitleErrorMsg.length > 0
            ? t('error.genericTitle')
            : (t('error.title') as string)
        }
        subtitle={
          authError
            ? t('error.authSubtitle')
            : subTitleErrorMsg.length > 0
            ? subTitleErrorMsg
            : t('error.subtitle')
        }
        onClose={() => handleErrorBarClose() as any}
      />
    </div>
  );

  const onViewAllOptions = (open: boolean, type: SelectedType) => {
    setViewAllOptions(open);
    setSelected(type);
  };

  const onRemoveOptions = (data: Item, type: SelectedType) => {
    if (type === 'from') {
      setFromItems(items => items.filter(item => item.id !== data.id));
      setFromSelectedItems(items => items.filter(item => item.id !== data.id));
    } else {
      setToItems(items => items.filter(item => item.id !== data.id));
      setToSelectedItems(items => items.filter(item => item.id !== data.id));
    }
  };

  const onCloseViewOptions = (saveOption: boolean = false) => {
    if (saveOption)
      if (selected === 'from') {
        setFromItems([...fromSelectedItems]);
      } else {
        setToItems([...toSelectedItems]);
      }
    else {
      if (selected === 'from') {
        setFromSelectedItems([...fromItems]);
      } else {
        setToSelectedItems([...toItems]);
      }
    }
    onViewAllOptions(false, selected);
  };

  const handleCheckbox = (value: boolean, data: Item) => {
    if (selected === 'from') {
      value
        ? setFromSelectedItems(items => [...items, data])
        : setFromSelectedItems(items =>
            items.filter(item => item.id !== data.id)
          );
    } else {
      value
        ? setToSelectedItems([...[], data])
        : setToSelectedItems(items =>
            items.filter(item => item.id !== data.id)
          );
    }
  };

  const onAddOptions = (data: Item) => {
    if (selected === 'from') {
      setFromItems([]);
      setFromSelectedItems([]);
      setFromItems(items => [...items, data]);
      setFromSelectedItems(items => [...items, data]);
    } else {
      setToItems([]);
      setToSelectedItems([]);
      setToItems(items => [...items, data]);
      setToSelectedItems(items => [...items, data]);
    }
  };

  const handleSelect = (type: SelectedType) => {
    setSelected(type);
  };

  const getTableData = (type: TableTypes) => {
    switch (type) {
      case 'applications': {
        if (Array.isArray(applications)) {
          const filteredAppData = applications?.filter(
            app =>
              app?.network_segment_id ===
                formData?.step1?.networkSegment?.value?.resource_id ||
              (app?.network_segment_id === '' &&
                formData?.step1?.networkSegment?.value?.resource_id ===
                  DEFAULT_NETWORK_SEGMENT_ID)
          );
          const appData = filteredAppData.map(app => {
            return {
              id: app.resource_id,
              name: app.name,
              resourceGroupName: resourceGroups?.filter(
                resourceGroup =>
                  resourceGroup?.resource_id === app?.resource_group_id
              )[0]?.name,
              resourceGroups: app.resource_group_id,
              services: app.services.length,
              labels: app.labels,
            };
          });
          return appData;
        }
        return null;
      }
      case 'partitions': {
        const partitionFilter = partitions?.filter(
          partition =>
            partition?.network_segment_id ===
              formData?.step1?.networkSegment?.value?.resource_id ||
            partition?.type === 'securitygroup' ||
            (partition?.network_segment_id === '' &&
              formData?.step1?.networkSegment?.value?.resource_id ===
                'default-network-segment')
        );
        const partitionData = partitionFilter?.map(partition => {
          return {
            ...partition,
            resourceGroupName: resourceGroups?.filter(
              resourceGroup =>
                resourceGroup?.resource_id === partition?.resource_group_id
            )[0]?.name,
            typeName: t(partition.type),
          };
        });
        return partitionData || null;
      }
      case 'services': {
        if (Array.isArray(services)) {
          return services.filter(
            service =>
              service?.network_segment_id ===
                formData?.step1?.networkSegment?.value?.resource_id ||
              (service?.network_segment_id === '' &&
                formData?.step1?.networkSegment?.value?.resource_id ===
                  'default-network-segment')
          );
        }
        return null;
      }
      case 'nwSegments': {
        if (Array.isArray(formData?.step1?.nwSegmentList)) {
          const nwSegData = formData?.step1?.nwSegmentList.map(segment => {
            return {
              id: segment.resource_id,
              resource_id: segment.resource_id,
              name: segment.name,
              resourceGroupName: resourceGroups?.filter(
                resourceGroup =>
                  resourceGroup?.resource_id === segment?.resource_group_id
              )[0]?.name,
              resource_group_id: segment.resource_group_id,
              compatibility_set: segment.compatibility_set,
              labels: segment.labels,
            };
          });
          return nwSegData;
        }
        return null;
      }
    }
  };

  return (
    <>
      <MultiStepTearSheet
        open={open}
        onClose={onClose}
        onRequestSubmit={handlePolicySubmit}
        submitButtonText={t('submitButtonText')}
        cancelButtonText={t('cancelButtonText')}
        backButtonText={t('backButtonText')}
        nextButtonText={t('nextButtonText')}
        description={t('description')}
        title={t('title')}
        className='create-policy-tearsheet'
      >
        <AddDetails
          formData={formData.step1}
          onChange={handleOnChange}
          checkFormValid={checkFormValid()}
          isNwSegmentListLoading={isNwSegmentListLoading}
        >
          {showErrorSnackbar && renderErrorSnackbar()}
        </AddDetails>
        <AllowConnections
          fromItems={fromItems}
          toItems={toItems}
          selected={selected}
          onViewAllOptions={onViewAllOptions}
          onRemoveOptions={onRemoveOptions}
          onAddOptions={onAddOptions}
          onSelect={handleSelect}
          getTableData={getTableData}
          step1FormData={formData.step1}
          viewAllOptions={viewAllOptions}
        />
      </MultiStepTearSheet>
      <PolicyOptions
        open={viewAllOptions}
        fromItems={fromItems}
        toItems={toItems}
        selected={selected}
        fromSelectedItems={fromSelectedItems}
        toSelectedItems={toSelectedItems}
        onRefresh={onRefresh}
        onClose={onCloseViewOptions}
        onOptionsChange={handleCheckbox}
        getTableData={getTableData}
        partitionPermission={partitionPermission}
        applicationPermission={applicationPermission}
        servicePermission={servicePermission}
      />
    </>
  );
};

export default CreatePolicy;
