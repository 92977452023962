import React from 'react';
import { detailsData } from '../../../models/sidePanel';
import './SidePanelTableComponent.scss';
import {
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
} from 'carbon-components-react';

type header = {
  key: string;
  header: string;
  linkCallback?: (row: any) => void;
  icon?: React.ReactNode;
};
type Props = {
  displayLabels?: detailsData[];
  headers: header[];
  rows: any[];
};

const SidePanelTableComponent: React.FC<Props> = ({ headers, rows }) => {
  const headerWithIcon = (icon: React.ReactNode, text: string) => {
    return (
      <>
        <div className='row-icon'>
          <div>{icon}</div> <div>{text}</div>
        </div>
      </>
    );
  };
  return (
    <Table className='sidePanelTableComponent'>
      <TableHead className='sidePanelTable-header'>
        <TableRow className='sidePanelTable-header-row'>
          {headers.map(header => {
            return (
              <TableHeader id={header.key} key={header.key}>
                {header.header}
              </TableHeader>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, index) => {
          return (
            <TableRow key={index}>
              {headers.map(header => {
                if (header.key !== 'id') {
                  return (
                    <TableCell
                      className={header.linkCallback ? 'row-link' : ''}
                      onClick={() =>
                        header.linkCallback && header.linkCallback(row)
                      }
                      key={header.key}
                    >
                      {header.icon
                        ? headerWithIcon(header.icon, row[header.key])
                        : row[header.key]}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
export default SidePanelTableComponent;
