import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { NotificationContext } from '../../../components/Notifications/Context/NotificationProvider';
import InlineNotification from '../../../components/Notifications/Inline/Notification';
import {
  addRolePermission,
  addRolePermissions,
  deleteRolePermission,
} from '../../../controllers/roleApis';
import {
  Permission,
  ResourceGroup,
  Role,
  ResourceTypeCategoryMapInterface,
  ResourceGroupCategoryMapInterface,
} from '../../../models/master.js';
import { AxiosError } from 'axios';
import EditPermissionTable from './EditPermissionTable';
import './EditPermission.scss';
import WideTearsheet from '../../../components/WideTearsheet/WideTearsheet';

interface PropsInterface {
  rows?: Permission[] | null;
  style?: any;
  resourceGroupsData: ResourceGroup[] | null;
  roleDetails: Role | null;
  isEditable?: boolean;
  open: boolean;
  roleId: string | null;
  onClose: () => void;
  refreshDetailsData: () => void;
  resourceTypeCategoryMap: ResourceTypeCategoryMapInterface[];
  updateResourceTypeMapState: (map: ResourceTypeCategoryMapInterface[]) => void;
  updateResourceGroupMapState: (
    map: ResourceGroupCategoryMapInterface[]
  ) => void;
  resourceGroupCategoryMap: ResourceGroupCategoryMapInterface[];
  permissionData: Permission[] | null;
  allPermissionData: Permission[] | null;
  tabIndex: number;
}

const EditPermission: React.FC<PropsInterface> = ({
  roleDetails,
  open,
  roleId,
  onClose,
  refreshDetailsData,
  resourceTypeCategoryMap,
  updateResourceTypeMapState,
  updateResourceGroupMapState,
  resourceGroupCategoryMap,
  permissionData,
  allPermissionData,
  tabIndex,
}) => {
  const [showFailNotification, toggleFailNotification] = useState(false);
  const [errorType, setErrorType] = useState('default');
  const [subTitleErrorMsg, setSubTitleErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation('roleDetails');
  const notification = useContext(NotificationContext);

  const deletePermissionIDSet = (resource: any, type: any) => {
    let deletedPermission: Permission[] | null;
    if (type === 'resourceType') {
      deletedPermission =
        permissionData != null
          ? permissionData.filter(
              (permission: Permission) =>
                permission.access_type === resource.access_type &&
                permission.subject_resource_type ===
                  resource.subject_resource_type &&
                permission.subject_resource_id === '*'
            )
          : null;

      if (
        Array.isArray(deletedPermission) &&
        deletedPermission.length === 1 &&
        deletedPermission[0]?.resource_id != null
      ) {
        return deletedPermission[0].resource_id;
      }
    } else if (type === 'resourceAccessType') {
      deletedPermission =
        permissionData != null
          ? permissionData.filter((permission: Permission) => {
              if (
                permission.access_type === resource.access_type &&
                permission.subject_resource_type ===
                  resource.subject_resource_type &&
                permission.subject_resource_id === resource.subject_resource_id
              ) {
                return true;
              } else {
                return false;
              }
            })
          : null;

      if (
        Array.isArray(deletedPermission) &&
        deletedPermission.length === 1 &&
        deletedPermission[0]?.resource_id != null
      ) {
        return deletedPermission[0].resource_id;
      }
    } else {
      deletedPermission =
        permissionData != null
          ? permissionData.filter((permission: Permission) => {
              if (
                permission.access_type === resource.access_type &&
                permission.subject_resource_type ===
                  resource.subject_resource_type &&
                permission.subject_resource_group_id ===
                  resource.subject_resource_group_id
              ) {
                return true;
              } else {
                return false;
              }
            })
          : null;

      if (
        Array.isArray(deletedPermission) &&
        deletedPermission.length === 1 &&
        deletedPermission[0]?.resource_id != null
      ) {
        return deletedPermission[0].resource_id;
      }
    }
  };

  const addPermissionIDSet = (resource: any, type: any) => {
    let addPermission: Permission[] | null;
    if (type === 'resourceType') {
      addPermission =
        allPermissionData != null
          ? allPermissionData.filter(
              (permission: Permission) =>
                permission.access_type === resource.access_type &&
                permission.subject_resource_type ===
                  resource.subject_resource_type &&
                permission.subject_resource_id === '*'
            )
          : null;
    } else if (type === 'resourceAccessType') {
      addPermission =
        allPermissionData != null
          ? allPermissionData.filter((permission: Permission) => {
              if (
                permission.access_type === resource.access_type &&
                permission.subject_resource_type ===
                  resource.subject_resource_type &&
                permission.subject_resource_id === resource.subject_resource_id
              ) {
                return true;
              } else {
                return false;
              }
            })
          : null;
    } else {
      addPermission =
        allPermissionData != null
          ? allPermissionData.filter((permission: Permission) => {
              if (
                permission.access_type === resource.access_type &&
                permission.subject_resource_type ===
                  resource.subject_resource_type &&
                permission.subject_resource_group_id ===
                  resource.subject_resource_group_id
              ) {
                return true;
              } else {
                return false;
              }
            })
          : null;
    }

    if (
      Array.isArray(addPermission) &&
      addPermission.length === 1 &&
      addPermission[0]?.resource_id != null
    ) {
      return addPermission[0].resource_id;
    } else {
      return null;
    }
  };

  const enableSaveButton = () => {
    if (
      isPermissionsChangedResourceType() ||
      isPermissionsChangedResourceGroup()
    ) {
      return true;
    } else return false;
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      let permissions_data: any = [],
        delete_permission_set: any = [],
        add_permission_set: any = [],
        add_permission_id: string | null;

      resourceTypeCategoryMap.forEach(resourceType => {
        if (resourceType.display === true) {
          Object.keys(resourceType.edited_access_type).forEach(key => {
            switch (key) {
              case 'all':
                if (resourceType.edited_access_type.all.status === 'edited') {
                  if (resourceType.edited_access_type.all.value) {
                    add_permission_id = addPermissionIDSet(
                      {
                        access_type: '*',
                        subject_resource_type: resourceType.id,
                      },
                      'resourceType'
                    );
                    add_permission_id == null
                      ? permissions_data.push({
                          access_type: '*',
                          subject_resource_type: resourceType.id,
                        })
                      : add_permission_set.push(add_permission_id);

                    Object.keys(resourceType.edited_access_type).forEach(
                      key => {
                        resourceType.edited_access_type[
                          key as keyof ResourceTypeCategoryMapInterface['edited_access_type']
                        ].value &&
                          resourceType.edited_access_type[
                            key as keyof ResourceTypeCategoryMapInterface['edited_access_type']
                          ].status === 'default' &&
                          delete_permission_set.push(
                            deletePermissionIDSet(
                              {
                                access_type: key.toUpperCase(),
                                subject_resource_type: resourceType.id,
                              },
                              'resourceType'
                            )
                          );
                      }
                    );
                  } else {
                    delete_permission_set.push(
                      deletePermissionIDSet(
                        {
                          access_type: '*',
                          subject_resource_type: resourceType.id,
                        },
                        'resourceType'
                      )
                    );
                    Object.keys(resourceType.edited_access_type).forEach(
                      key => {
                        if (
                          resourceType.edited_access_type[
                            key as keyof ResourceTypeCategoryMapInterface['edited_access_type']
                          ].value
                        ) {
                          add_permission_id = addPermissionIDSet(
                            {
                              access_type: key.toUpperCase(),
                              subject_resource_type: resourceType.id,
                            },
                            'resourceType'
                          );
                          add_permission_id == null
                            ? permissions_data.push({
                                access_type: key.toUpperCase(),
                                subject_resource_type: resourceType.id,
                              })
                            : add_permission_set.push(add_permission_id);
                        }
                      }
                    );
                  }
                }
                break;
              case 'create':
                if (
                  !resourceType.edited_access_type.all.value &&
                  resourceType.edited_access_type.create.status === 'edited'
                )
                  if (resourceType.edited_access_type.create.value) {
                    add_permission_id = addPermissionIDSet(
                      {
                        access_type: 'CREATE',
                        subject_resource_type: resourceType.id,
                      },
                      'resourceType'
                    );
                    add_permission_id == null
                      ? permissions_data.push({
                          access_type: 'CREATE',
                          subject_resource_type: resourceType.id,
                        })
                      : add_permission_set.push(add_permission_id);
                  } else {
                    resourceType.edited_access_type.all.status !== 'edited' &&
                      delete_permission_set.push(
                        deletePermissionIDSet(
                          {
                            access_type: 'CREATE',
                            subject_resource_type: resourceType.id,
                          },
                          'resourceType'
                        )
                      );
                  }
                break;
              case 'read':
                if (
                  !resourceType.edited_access_type.all.value &&
                  resourceType.edited_access_type.read.status === 'edited'
                ) {
                  if (resourceType.edited_access_type.read.value) {
                    add_permission_id = addPermissionIDSet(
                      {
                        access_type: 'READ',
                        subject_resource_type: resourceType.id,
                      },
                      'resourceType'
                    );
                    add_permission_id == null
                      ? permissions_data.push({
                          access_type: 'READ',
                          subject_resource_type: resourceType.id,
                        })
                      : add_permission_set.push(add_permission_id);
                  } else {
                    resourceType.edited_access_type.all.status !== 'edited' &&
                      delete_permission_set.push(
                        deletePermissionIDSet(
                          {
                            access_type: 'READ',
                            subject_resource_type: resourceType.id,
                          },
                          'resourceType'
                        )
                      );
                  }
                }

                break;
              case 'update':
                if (
                  !resourceType.edited_access_type.all.value &&
                  resourceType.edited_access_type.update.status === 'edited'
                ) {
                  if (resourceType.edited_access_type.update.value) {
                    add_permission_id = addPermissionIDSet(
                      {
                        access_type: 'UPDATE',
                        subject_resource_type: resourceType.id,
                      },
                      'resourceType'
                    );
                    add_permission_id == null
                      ? permissions_data.push({
                          access_type: 'UPDATE',
                          subject_resource_type: resourceType.id,
                        })
                      : add_permission_set.push(add_permission_id);
                  } else {
                    resourceType.edited_access_type.all.status !== 'edited' &&
                      delete_permission_set.push(
                        deletePermissionIDSet(
                          {
                            access_type: 'UPDATE',
                            subject_resource_type: resourceType.id,
                          },
                          'resourceType'
                        )
                      );
                  }
                }
                break;
              case 'delete':
                if (
                  !resourceType.edited_access_type.all.value &&
                  resourceType.edited_access_type.delete.status === 'edited'
                ) {
                  if (resourceType.edited_access_type.delete.value) {
                    add_permission_id = addPermissionIDSet(
                      {
                        access_type: 'DELETE',
                        subject_resource_type: resourceType.id,
                      },
                      'resourceType'
                    );
                    add_permission_id == null
                      ? permissions_data.push({
                          access_type: 'DELETE',
                          subject_resource_type: resourceType.id,
                        })
                      : add_permission_set.push(add_permission_id);
                  } else {
                    resourceType.edited_access_type.all.status !== 'edited' &&
                      delete_permission_set.push(
                        deletePermissionIDSet(
                          {
                            access_type: 'DELETE',
                            subject_resource_type: resourceType.id,
                          },
                          'resourceType'
                        )
                      );
                  }
                }
                break;
              case 'admin':
                if (
                  !resourceType.edited_access_type.all.value &&
                  resourceType.edited_access_type.admin.status === 'edited'
                ) {
                  if (resourceType.edited_access_type.admin.value) {
                    add_permission_id = addPermissionIDSet(
                      {
                        access_type: 'ADMIN',
                        subject_resource_type: resourceType.id,
                      },
                      'resourceType'
                    );
                    add_permission_id == null
                      ? permissions_data.push({
                          access_type: 'ADMIN',
                          subject_resource_type: resourceType.id,
                        })
                      : add_permission_set.push(add_permission_id);
                  } else {
                    resourceType.edited_access_type.all.status !== 'edited' &&
                      delete_permission_set.push(
                        deletePermissionIDSet(
                          {
                            access_type: 'ADMIN',
                            subject_resource_type: resourceType.id,
                          },
                          'resourceType'
                        )
                      );
                  }
                }
                break;
              default:
                break;
            }
          });
        }
      });

      resourceGroupCategoryMap.forEach(resourceGroupMapObj => {
        Object.keys(resourceGroupMapObj.edited_resource_access_type).forEach(
          key => {
            switch (key) {
              case 'all':
                if (
                  resourceGroupMapObj.edited_resource_access_type.all.status ===
                  'edited'
                ) {
                  if (
                    resourceGroupMapObj.edited_resource_access_type.all.value
                  ) {
                    add_permission_id = addPermissionIDSet(
                      {
                        access_type: '*',
                        subject_resource_id: resourceGroupMapObj.id,
                        subject_resource_type: 'resourcegroup',
                      },
                      'resourceAccessType'
                    );
                    add_permission_id == null
                      ? permissions_data.push({
                          access_type: '*',
                          subject_resource_id: resourceGroupMapObj.id,
                          subject_resource_type: 'resourcegroup',
                        })
                      : add_permission_set.push(add_permission_id);

                    Object.keys(
                      resourceGroupMapObj.edited_resource_access_type
                    ).forEach(key => {
                      resourceGroupMapObj.edited_resource_access_type[
                        key as keyof ResourceGroupCategoryMapInterface['edited_resource_access_type']
                      ].value &&
                        resourceGroupMapObj.edited_resource_access_type[
                          key as keyof ResourceGroupCategoryMapInterface['edited_resource_access_type']
                        ].status === 'default' &&
                        delete_permission_set.push(
                          deletePermissionIDSet(
                            {
                              access_type: key.toUpperCase(),
                              subject_resource_id: resourceGroupMapObj.id,
                              subject_resource_type: 'resourcegroup',
                            },
                            'resourceAccessType'
                          )
                        );
                    });
                  } else {
                    delete_permission_set.push(
                      deletePermissionIDSet(
                        {
                          access_type: '*',
                          subject_resource_id: resourceGroupMapObj.id,
                          subject_resource_type: 'resourcegroup',
                        },
                        'resourceAccessType'
                      )
                    );
                    Object.keys(
                      resourceGroupMapObj.edited_resource_access_type
                    ).forEach(key => {
                      if (
                        resourceGroupMapObj.edited_resource_access_type[
                          key as keyof ResourceGroupCategoryMapInterface['edited_resource_access_type']
                        ].value
                      ) {
                        add_permission_id = addPermissionIDSet(
                          {
                            access_type: key.toUpperCase(),
                            subject_resource_id: resourceGroupMapObj.id,
                            subject_resource_type: 'resourcegroup',
                          },
                          'resourceAccessType'
                        );
                        add_permission_id == null
                          ? permissions_data.push({
                              access_type: key.toUpperCase(),
                              subject_resource_id: resourceGroupMapObj.id,
                              subject_resource_type: 'resourcegroup',
                            })
                          : add_permission_set.push(add_permission_id);
                      }
                    });
                  }
                }

                break;
              case 'read':
                if (
                  !resourceGroupMapObj.edited_resource_access_type.all.value &&
                  resourceGroupMapObj.edited_resource_access_type.read
                    .status === 'edited'
                ) {
                  if (
                    resourceGroupMapObj.edited_resource_access_type.read.value
                  ) {
                    add_permission_id = addPermissionIDSet(
                      {
                        access_type: 'READ',
                        subject_resource_id: resourceGroupMapObj.id,
                        subject_resource_type: 'resourcegroup',
                      },
                      'resourceAccessType'
                    );
                    add_permission_id == null
                      ? permissions_data.push({
                          access_type: 'READ',
                          subject_resource_id: resourceGroupMapObj.id,
                          subject_resource_type: 'resourcegroup',
                        })
                      : add_permission_set.push(add_permission_id);
                  } else {
                    resourceGroupMapObj.edited_resource_access_type.all
                      .status !== 'edited' &&
                      delete_permission_set.push(
                        deletePermissionIDSet(
                          {
                            access_type: 'READ',
                            subject_resource_id: resourceGroupMapObj.id,
                            subject_resource_type: 'resourcegroup',
                          },
                          'resourceAccessType'
                        )
                      );
                  }
                }
                break;
              case 'update':
                if (
                  !resourceGroupMapObj.edited_resource_access_type.all.value &&
                  resourceGroupMapObj.edited_resource_access_type.update
                    .status === 'edited'
                ) {
                  if (
                    resourceGroupMapObj.edited_resource_access_type.update.value
                  ) {
                    add_permission_id = addPermissionIDSet(
                      {
                        access_type: 'UPDATE',
                        subject_resource_id: resourceGroupMapObj.id,
                        subject_resource_type: 'resourcegroup',
                      },
                      'resourceAccessType'
                    );
                    add_permission_id == null
                      ? permissions_data.push({
                          access_type: 'UPDATE',
                          subject_resource_id: resourceGroupMapObj.id,
                          subject_resource_type: 'resourcegroup',
                        })
                      : add_permission_set.push(add_permission_id);
                  } else {
                    resourceGroupMapObj.edited_resource_access_type.all
                      .status !== 'edited' &&
                      delete_permission_set.push(
                        deletePermissionIDSet(
                          {
                            access_type: 'UPDATE',
                            subject_resource_id: resourceGroupMapObj.id,
                            subject_resource_type: 'resourcegroup',
                          },
                          'resourceAccessType'
                        )
                      );
                  }
                }
                break;
              case 'delete':
                if (
                  !resourceGroupMapObj.edited_resource_access_type.all.value &&
                  resourceGroupMapObj.edited_resource_access_type.delete
                    .status === 'edited'
                ) {
                  if (
                    resourceGroupMapObj.edited_resource_access_type.delete.value
                  ) {
                    add_permission_id = addPermissionIDSet(
                      {
                        access_type: 'DELETE',
                        subject_resource_id: resourceGroupMapObj.id,
                        subject_resource_type: 'resourcegroup',
                      },
                      'resourceAccessType'
                    );
                    add_permission_id == null
                      ? permissions_data.push({
                          access_type: 'DELETE',
                          subject_resource_id: resourceGroupMapObj.id,
                          subject_resource_type: 'resourcegroup',
                        })
                      : add_permission_set.push(add_permission_id);
                  } else {
                    resourceGroupMapObj.edited_resource_access_type.all
                      .status !== 'edited' &&
                      delete_permission_set.push(
                        deletePermissionIDSet(
                          {
                            access_type: 'DELETE',
                            subject_resource_id: resourceGroupMapObj.id,
                            subject_resource_type: 'resourcegroup',
                          },
                          'resourceAccessType'
                        )
                      );
                  }
                }

                break;
              case 'admin':
                if (
                  !resourceGroupMapObj.edited_resource_access_type.all.value &&
                  resourceGroupMapObj.edited_resource_access_type.admin
                    .status === 'edited'
                ) {
                  if (
                    resourceGroupMapObj.edited_resource_access_type.admin.value
                  ) {
                    add_permission_id = addPermissionIDSet(
                      {
                        access_type: 'ADMIN',
                        subject_resource_id: resourceGroupMapObj.id,
                        subject_resource_type: 'resourcegroup',
                      },
                      'resourceAccessType'
                    );
                    add_permission_id == null
                      ? permissions_data.push({
                          access_type: 'ADMIN',
                          subject_resource_id: resourceGroupMapObj.id,
                          subject_resource_type: 'resourcegroup',
                        })
                      : add_permission_set.push(add_permission_id);
                  } else {
                    resourceGroupMapObj.edited_resource_access_type.all
                      .status !== 'edited' &&
                      delete_permission_set.push(
                        deletePermissionIDSet(
                          {
                            access_type: 'ADMIN',
                            subject_resource_id: resourceGroupMapObj.id,
                            subject_resource_type: 'resourcegroup',
                          },
                          'resourceAccessType'
                        )
                      );
                  }
                }
                break;
              default:
                break;
            }
          }
        );

        Object.keys(
          resourceGroupMapObj.edited_resource_group_access_type
        ).forEach(key => {
          switch (key) {
            case 'all':
              if (
                resourceGroupMapObj.edited_resource_group_access_type.all
                  .status === 'edited'
              ) {
                if (
                  resourceGroupMapObj.edited_resource_group_access_type.all
                    .value
                ) {
                  add_permission_id = addPermissionIDSet(
                    {
                      access_type: '*',
                      subject_resource_type: '*',
                      subject_resource_group_id: resourceGroupMapObj.id,
                    },
                    'resourceGroupAccessType'
                  );
                  add_permission_id == null
                    ? permissions_data.push({
                        access_type: '*',
                        subject_resource_type: '*',
                        subject_resource_group_id: resourceGroupMapObj.id,
                      })
                    : add_permission_set.push(add_permission_id);

                  Object.keys(
                    resourceGroupMapObj.edited_resource_group_access_type
                  ).forEach(key => {
                    resourceGroupMapObj.edited_resource_group_access_type[
                      key as keyof ResourceGroupCategoryMapInterface['edited_resource_group_access_type']
                    ].value &&
                      resourceGroupMapObj.edited_resource_group_access_type[
                        key as keyof ResourceGroupCategoryMapInterface['edited_resource_group_access_type']
                      ].status === 'default' &&
                      delete_permission_set.push(
                        deletePermissionIDSet(
                          {
                            access_type: key.toUpperCase(),
                            subject_resource_group_id: resourceGroupMapObj.id,
                            subject_resource_type: '*',
                          },
                          'resourceGroupAccessType'
                        )
                      );
                  });
                } else {
                  delete_permission_set.push(
                    deletePermissionIDSet(
                      {
                        access_type: '*',
                        subject_resource_group_id: resourceGroupMapObj.id,
                        subject_resource_type: '*',
                      },
                      'resourceGroupAccessType'
                    )
                  );
                  Object.keys(
                    resourceGroupMapObj.edited_resource_group_access_type
                  ).forEach(key => {
                    if (
                      resourceGroupMapObj.edited_resource_group_access_type[
                        key as keyof ResourceGroupCategoryMapInterface['edited_resource_group_access_type']
                      ].value
                    ) {
                      add_permission_id = addPermissionIDSet(
                        {
                          access_type: key.toUpperCase(),
                          subject_resource_group_id: resourceGroupMapObj.id,
                          subject_resource_type: '*',
                        },
                        'resourceGroupAccessType'
                      );
                      add_permission_id == null
                        ? permissions_data.push({
                            access_type: key.toUpperCase(),
                            subject_resource_group_id: resourceGroupMapObj.id,
                            subject_resource_type: '*',
                          })
                        : add_permission_set.push(add_permission_id);
                    }
                  });
                }
              }

              break;
            case 'create':
              if (
                !resourceGroupMapObj.edited_resource_group_access_type.all
                  .value &&
                resourceGroupMapObj.edited_resource_group_access_type.create
                  .status === 'edited'
              ) {
                if (
                  resourceGroupMapObj.edited_resource_group_access_type.create
                    .value
                ) {
                  add_permission_id = addPermissionIDSet(
                    {
                      access_type: 'CREATE',
                      subject_resource_group_id: resourceGroupMapObj.id,
                      subject_resource_type: '*',
                    },
                    'resourceGroupAccessType'
                  );
                  add_permission_id == null
                    ? permissions_data.push({
                        access_type: 'CREATE',
                        subject_resource_group_id: resourceGroupMapObj.id,
                        subject_resource_type: '*',
                      })
                    : add_permission_set.push(add_permission_id);
                } else {
                  resourceGroupMapObj.edited_resource_group_access_type.all
                    .status !== 'edited' &&
                    delete_permission_set.push(
                      deletePermissionIDSet(
                        {
                          access_type: 'CREATE',
                          subject_resource_group_id: resourceGroupMapObj.id,
                          subject_resource_type: '*',
                        },
                        'resourceGroupAccessType'
                      )
                    );
                }
              }
              break;
            case 'read':
              if (
                !resourceGroupMapObj.edited_resource_group_access_type.all
                  .value &&
                resourceGroupMapObj.edited_resource_group_access_type.read
                  .status === 'edited'
              ) {
                if (
                  resourceGroupMapObj.edited_resource_group_access_type.read
                    .value
                ) {
                  add_permission_id = addPermissionIDSet(
                    {
                      access_type: 'READ',
                      subject_resource_group_id: resourceGroupMapObj.id,
                      subject_resource_type: '*',
                    },
                    'resourceGroupAccessType'
                  );
                  add_permission_id == null
                    ? permissions_data.push({
                        access_type: 'READ',
                        subject_resource_group_id: resourceGroupMapObj.id,
                        subject_resource_type: '*',
                      })
                    : add_permission_set.push(add_permission_id);
                } else {
                  resourceGroupMapObj.edited_resource_group_access_type.all
                    .status !== 'edited' &&
                    delete_permission_set.push(
                      deletePermissionIDSet(
                        {
                          access_type: 'READ',
                          subject_resource_group_id: resourceGroupMapObj.id,
                          subject_resource_type: '*',
                        },
                        'resourceGroupAccessType'
                      )
                    );
                }
              }
              break;
            case 'update':
              if (
                !resourceGroupMapObj.edited_resource_group_access_type.all
                  .value &&
                resourceGroupMapObj.edited_resource_group_access_type.update
                  .status === 'edited'
              ) {
                if (
                  resourceGroupMapObj.edited_resource_group_access_type.update
                    .value
                ) {
                  add_permission_id = addPermissionIDSet(
                    {
                      access_type: 'UPDATE',
                      subject_resource_group_id: resourceGroupMapObj.id,
                      subject_resource_type: '*',
                    },
                    'resourceGroupAccessType'
                  );
                  add_permission_id == null
                    ? permissions_data.push({
                        access_type: 'UPDATE',
                        subject_resource_group_id: resourceGroupMapObj.id,
                        subject_resource_type: '*',
                      })
                    : add_permission_set.push(add_permission_id);
                } else {
                  resourceGroupMapObj.edited_resource_group_access_type.all
                    .status !== 'edited' &&
                    delete_permission_set.push(
                      deletePermissionIDSet(
                        {
                          access_type: 'UPDATE',
                          subject_resource_group_id: resourceGroupMapObj.id,
                          subject_resource_type: '*',
                        },
                        'resourceGroupAccessType'
                      )
                    );
                }
              }
              break;
            case 'delete':
              if (
                !resourceGroupMapObj.edited_resource_group_access_type.all
                  .value &&
                resourceGroupMapObj.edited_resource_group_access_type.delete
                  .status === 'edited'
              ) {
                if (
                  resourceGroupMapObj.edited_resource_group_access_type.delete
                    .value
                ) {
                  add_permission_id = addPermissionIDSet(
                    {
                      access_type: 'DELETE',
                      subject_resource_group_id: resourceGroupMapObj.id,
                      subject_resource_type: '*',
                    },
                    'resourceGroupAccessType'
                  );
                  add_permission_id == null
                    ? permissions_data.push({
                        access_type: 'DELETE',
                        subject_resource_group_id: resourceGroupMapObj.id,
                        subject_resource_type: '*',
                      })
                    : add_permission_set.push(add_permission_id);
                } else {
                  resourceGroupMapObj.edited_resource_group_access_type.all
                    .status !== 'edited' &&
                    delete_permission_set.push(
                      deletePermissionIDSet(
                        {
                          access_type: 'DELETE',
                          subject_resource_group_id: resourceGroupMapObj.id,
                          subject_resource_type: '*',
                        },
                        'resourceGroupAccessType'
                      )
                    );
                }
              }
              break;
            case 'admin':
              if (
                !resourceGroupMapObj.edited_resource_group_access_type.all
                  .value &&
                resourceGroupMapObj.edited_resource_group_access_type.admin
                  .status === 'edited'
              ) {
                if (
                  resourceGroupMapObj.edited_resource_group_access_type.admin
                    .value
                ) {
                  add_permission_id = addPermissionIDSet(
                    {
                      access_type: 'ADMIN',
                      subject_resource_group_id: resourceGroupMapObj.id,
                      subject_resource_type: '*',
                    },
                    'resourceGroupAccessType'
                  );
                  add_permission_id == null
                    ? permissions_data.push({
                        access_type: 'ADMIN',
                        subject_resource_group_id: resourceGroupMapObj.id,
                        subject_resource_type: '*',
                      })
                    : add_permission_set.push(add_permission_id);
                } else {
                  resourceGroupMapObj.edited_resource_group_access_type.all
                    .status !== 'edited' &&
                    delete_permission_set.push(
                      deletePermissionIDSet(
                        {
                          access_type: 'ADMIN',
                          subject_resource_group_id: resourceGroupMapObj.id,
                          subject_resource_type: '*',
                        },
                        'resourceGroupAccessType'
                      )
                    );
                }
              }
              break;
            default:
              break;
          }
        });
      });

      delete_permission_set.length > 0 &&
        (await Promise.allSettled(
          delete_permission_set.map(
            async (permission: any) =>
              await deleteRolePermission(roleId, permission)
          )
        ));

      add_permission_set.length > 0 &&
        (await Promise.allSettled(
          add_permission_set.map(
            async (permission: any) =>
              await addRolePermission(roleId, permission)
          )
        ));

      permissions_data.length > 0 &&
        (await addRolePermissions(roleId, {
          permission_definitions: permissions_data,
        }));

      // Trigger success toastbar
      notification.onTrigger('TOAST', {
        title: t('editPermission.successNotification.title', {
          roleName: roleDetails?.name ?? '',
        }),
        subtitle: t('editPermission.successNotification.subtitle'),
      });

      refreshDetailsData();
    } catch (error: any) {
      console.log(error);
      const err = error as AxiosError;
      toggleFailNotification(true);
      if (err.response?.status === 403) {
        setErrorType('auth');
        setSubTitleErrorMsg(
          t('editPermission.failureNotification.authSubtitle')
        );
      } else {
        setErrorType('default');
        setSubTitleErrorMsg(t('editPermission.failureNotification.subtitle'));
      }

      const errorMessage: string =
        error.response != null ? error.response['customErrorMessage'] : '';
      errorMessage.length > 0 && setSubTitleErrorMsg(errorMessage);
      toggleFailNotification(true);
    } finally {
      setLoading(false);
    }
  };

  const isPermissionsChangedResourceGroup = () => {
    let changed = resourceGroupCategoryMap?.some(
      rt =>
        (rt != null &&
          Object.values(rt.edited_resource_access_type).some(property => {
            return property.status === 'edited';
          })) ||
        (rt != null &&
          Object.values(rt.edited_resource_group_access_type).some(property => {
            return property.status === 'edited';
          }))
    );
    return changed;
  };

  const isPermissionsChangedResourceType = () => {
    let changed = resourceTypeCategoryMap?.some(rt =>
      Object.values(rt.edited_access_type).some(property => {
        return property.status === 'edited';
      })
    );

    return changed;
  };

  const resetPermissions = () => {
    let markGroupReadAccess: boolean = false;
    resourceTypeCategoryMap != null &&
      updateResourceTypeMapState(
        resourceTypeCategoryMap.map(resourceTypeCategory => {
          resourceTypeCategory.edited_access_type.all = Object.assign(
            {},
            {
              value: resourceTypeCategory.access_type.all,
              status: 'default',
              disabled: resourceTypeCategory.edited_access_type.all.disabled,
            }
          );
          resourceTypeCategory.edited_access_type.admin = Object.assign(
            {},
            {
              value: resourceTypeCategory.access_type.admin,
              status: 'default',
              disabled: resourceTypeCategory.edited_access_type.admin.disabled,
            }
          );
          resourceTypeCategory.edited_access_type.create = Object.assign(
            {},
            {
              value: resourceTypeCategory.access_type.create,
              status: 'default',
              disabled: resourceTypeCategory.edited_access_type.create.disabled,
            }
          );
          resourceTypeCategory.edited_access_type.read = Object.assign(
            {},
            {
              value: resourceTypeCategory.access_type.read,
              status: 'default',
              disabled: resourceTypeCategory.edited_access_type.read.disabled,
            }
          );
          resourceTypeCategory.edited_access_type.update = Object.assign(
            {},
            {
              value: resourceTypeCategory.access_type.update,
              status: 'default',
              disabled: resourceTypeCategory.edited_access_type.update.disabled,
            }
          );
          resourceTypeCategory.edited_access_type.delete = Object.assign(
            {},
            {
              value: resourceTypeCategory.access_type.delete,
              status: 'default',
              disabled: resourceTypeCategory.edited_access_type.delete.disabled,
            }
          );

          return resourceTypeCategory;
        })
      );

    resourceGroupCategoryMap != null &&
      updateResourceGroupMapState(
        resourceGroupCategoryMap.map(resourceGroupCategory => {
          resourceGroupCategory.edited_resource_access_type.all = Object.assign(
            {},
            {
              value: resourceGroupCategory.resource_access_type.all,
              status: 'default',
            }
          );
          resourceGroupCategory.edited_resource_access_type.read =
            Object.assign(
              {},
              {
                value: resourceGroupCategory.resource_access_type.read,
                status: 'default',
              }
            );
          resourceGroupCategory.edited_resource_access_type.update =
            Object.assign(
              {},
              {
                value: resourceGroupCategory.resource_access_type.update,
                status: 'default',
              }
            );
          resourceGroupCategory.edited_resource_access_type.delete =
            Object.assign(
              {},
              {
                value: resourceGroupCategory.resource_access_type.delete,
                status: 'default',
              }
            );
          resourceGroupCategory.edited_resource_access_type.admin =
            Object.assign(
              {},
              {
                value: resourceGroupCategory.resource_access_type.admin,
                status: 'default',
              }
            );

          resourceGroupCategory.edited_resource_group_access_type.all =
            Object.assign(
              {},
              {
                value: resourceGroupCategory.resource_group_access_type.all,
                status: 'default',
              }
            );

          resourceGroupCategory.edited_resource_group_access_type.create =
            Object.assign(
              {},
              {
                value: resourceGroupCategory.resource_group_access_type.create,
                status: 'default',
              }
            );

          resourceGroupCategory.edited_resource_group_access_type.read =
            Object.assign(
              {},
              {
                value: resourceGroupCategory.resource_group_access_type.read,
                status: 'default',
              }
            );
          resourceGroupCategory.edited_resource_group_access_type.update =
            Object.assign(
              {},
              {
                value: resourceGroupCategory.resource_group_access_type.update,
                status: 'default',
              }
            );
          resourceGroupCategory.edited_resource_group_access_type.delete =
            Object.assign(
              {},
              {
                value: resourceGroupCategory.resource_group_access_type.delete,
                status: 'default',
              }
            );
          resourceGroupCategory.edited_resource_group_access_type.admin =
            Object.assign(
              {},
              {
                value: resourceGroupCategory.resource_group_access_type.admin,
                status: 'default',
              }
            );

          markGroupReadAccess = Object.keys(
            resourceGroupCategory.edited_resource_group_access_type
          ).some(key =>
            key !== 'all'
              ? resourceGroupCategory.edited_resource_group_access_type[
                  key as keyof ResourceGroupCategoryMapInterface['edited_resource_group_access_type']
                ].value === true
              : false
          );

          resourceGroupCategory.edited_resource_access_type.read.value =
            markGroupReadAccess;
          resourceGroupCategory.edited_resource_access_type.read.status =
            resourceGroupCategory.resource_access_type.read ===
            markGroupReadAccess
              ? 'default'
              : 'edited';
          resourceGroupCategory.edited_resource_access_type.read.disabled =
            markGroupReadAccess;

          return resourceGroupCategory;
        })
      );
  };

  const handleResourceTypeChange = (
    val: any,
    state: any,
    elem: any,
    resource_id: any
  ) => {
    let access_type = elem.target.value,
      markAllTrue: boolean = false;
    const selectedResourceTypeCategory = resourceTypeCategoryMap?.find(
      (rt: ResourceTypeCategoryMapInterface) => rt.id === resource_id
    );

    if (selectedResourceTypeCategory) {
      switch (access_type) {
        case 'create':
          selectedResourceTypeCategory.edited_access_type.create.value = val;
          selectedResourceTypeCategory.edited_access_type.create.status =
            selectedResourceTypeCategory.access_type.create === val
              ? 'default'
              : 'edited';
          break;
        case 'read':
          selectedResourceTypeCategory.edited_access_type.read.value = val;
          selectedResourceTypeCategory.edited_access_type.read.status =
            selectedResourceTypeCategory.access_type.read === val
              ? 'default'
              : 'edited';
          break;

        case 'update':
          selectedResourceTypeCategory.edited_access_type.update.value = val;
          selectedResourceTypeCategory.edited_access_type.update.status =
            selectedResourceTypeCategory.access_type.update === val
              ? 'default'
              : 'edited';
          break;
        case 'delete':
          selectedResourceTypeCategory.edited_access_type.delete.value = val;
          selectedResourceTypeCategory.edited_access_type.delete.status =
            selectedResourceTypeCategory.access_type.delete === val
              ? 'default'
              : 'edited';
          break;
        case 'admin':
          selectedResourceTypeCategory.edited_access_type.admin.value = val;
          selectedResourceTypeCategory.edited_access_type.admin.status =
            selectedResourceTypeCategory.access_type.admin === val
              ? 'default'
              : 'edited';
          break;

        default:
          break;
      }
      markAllTrue = Object.keys(
        selectedResourceTypeCategory.edited_access_type
      ).every(key =>
        key !== 'all'
          ? selectedResourceTypeCategory.edited_access_type[
              key as keyof ResourceTypeCategoryMapInterface['access_type']
            ].value === true
          : true
      );
      selectedResourceTypeCategory.edited_access_type.all.value = markAllTrue;
      selectedResourceTypeCategory.edited_access_type.all.status =
        selectedResourceTypeCategory.access_type.all === markAllTrue
          ? 'default'
          : 'edited';
    }

    if (resourceTypeCategoryMap != null) {
      updateResourceTypeMapState(
        resourceTypeCategoryMap.map(resourceTypeCategory => {
          if (selectedResourceTypeCategory?.id === resourceTypeCategory?.id) {
            return selectedResourceTypeCategory;
          }
          return resourceTypeCategory;
        })
      );
    }
  };

  const handleResourceGroupChange = (
    val: any,
    state: any,
    elem: any,
    resource_id: any,
    pass_resource_group_id: boolean
  ) => {
    let access_type = elem.target.value,
      markAllTrue: boolean = false,
      markGroupReadAccess: boolean = false;

    const selectedResourceGroupCategory = Object.assign(
      {},
      resourceGroupCategoryMap?.find(
        (rt: ResourceGroupCategoryMapInterface) => rt.id === resource_id
      )
    );
    if (selectedResourceGroupCategory) {
      if (pass_resource_group_id) {
        switch (access_type) {
          case 'create':
            selectedResourceGroupCategory.edited_resource_group_access_type.create.value =
              val;
            selectedResourceGroupCategory.edited_resource_group_access_type.create.status =
              selectedResourceGroupCategory.resource_group_access_type
                .create === val
                ? 'default'
                : 'edited';
            break;

          case 'read':
            selectedResourceGroupCategory.edited_resource_group_access_type.read.value =
              val;
            selectedResourceGroupCategory.edited_resource_group_access_type.read.status =
              selectedResourceGroupCategory.resource_group_access_type.read ===
              val
                ? 'default'
                : 'edited';
            break;

          case 'update':
            selectedResourceGroupCategory.edited_resource_group_access_type.update.value =
              val;
            selectedResourceGroupCategory.edited_resource_group_access_type.update.status =
              selectedResourceGroupCategory.resource_group_access_type
                .update === val
                ? 'default'
                : 'edited';
            break;
          case 'delete':
            selectedResourceGroupCategory.edited_resource_group_access_type.delete.value =
              val;
            selectedResourceGroupCategory.edited_resource_group_access_type.delete.status =
              selectedResourceGroupCategory.resource_group_access_type
                .delete === val
                ? 'default'
                : 'edited';
            break;
          case 'admin':
            selectedResourceGroupCategory.edited_resource_group_access_type.admin.value =
              val;
            selectedResourceGroupCategory.edited_resource_group_access_type.admin.status =
              selectedResourceGroupCategory.resource_group_access_type.admin ===
              val
                ? 'default'
                : 'edited';
            break;

          default:
            break;
        }
        markAllTrue = Object.keys(
          selectedResourceGroupCategory.edited_resource_group_access_type
        ).every(key =>
          key !== 'all'
            ? selectedResourceGroupCategory.edited_resource_group_access_type[
                key as keyof ResourceGroupCategoryMapInterface['edited_resource_group_access_type']
              ].value === true
            : true
        );

        selectedResourceGroupCategory.edited_resource_group_access_type.all.value =
          markAllTrue;
        selectedResourceGroupCategory.edited_resource_group_access_type.all.status =
          selectedResourceGroupCategory.resource_group_access_type.all ===
          markAllTrue
            ? 'default'
            : 'edited';

        markGroupReadAccess = Object.keys(
          selectedResourceGroupCategory.edited_resource_group_access_type
        ).some(key =>
          key !== 'all'
            ? selectedResourceGroupCategory.edited_resource_group_access_type[
                key as keyof ResourceGroupCategoryMapInterface['edited_resource_group_access_type']
              ].value === true
            : false
        );

        selectedResourceGroupCategory.edited_resource_access_type.read.value =
          markGroupReadAccess;
        selectedResourceGroupCategory.edited_resource_access_type.read.status =
          selectedResourceGroupCategory.resource_access_type.read ===
          markGroupReadAccess
            ? 'default'
            : 'edited';
        selectedResourceGroupCategory.edited_resource_access_type.read.disabled =
          markGroupReadAccess;
      } else {
        switch (access_type) {
          case 'read':
            selectedResourceGroupCategory.edited_resource_access_type.read.value =
              val;
            selectedResourceGroupCategory.edited_resource_access_type.read.status =
              selectedResourceGroupCategory.resource_access_type.read === val
                ? 'default'
                : 'edited';
            break;

          case 'update':
            selectedResourceGroupCategory.edited_resource_access_type.update.value =
              val;
            selectedResourceGroupCategory.edited_resource_access_type.update.status =
              selectedResourceGroupCategory.resource_access_type.update === val
                ? 'default'
                : 'edited';
            break;
          case 'delete':
            selectedResourceGroupCategory.edited_resource_access_type.delete.value =
              val;
            selectedResourceGroupCategory.edited_resource_access_type.delete.status =
              selectedResourceGroupCategory.resource_access_type.delete === val
                ? 'default'
                : 'edited';
            break;
          case 'admin':
            selectedResourceGroupCategory.edited_resource_access_type.admin.value =
              val;
            selectedResourceGroupCategory.edited_resource_access_type.admin.status =
              selectedResourceGroupCategory.resource_access_type.admin === val
                ? 'default'
                : 'edited';
            break;

          default:
            break;
        }
        markAllTrue = Object.keys(
          selectedResourceGroupCategory.edited_resource_access_type
        ).every(key =>
          key !== 'all'
            ? selectedResourceGroupCategory.edited_resource_access_type[
                key as keyof ResourceGroupCategoryMapInterface['edited_resource_access_type']
              ].value === true
            : true
        );
        selectedResourceGroupCategory.edited_resource_access_type.all.value =
          markAllTrue;
        selectedResourceGroupCategory.edited_resource_access_type.all.status =
          selectedResourceGroupCategory.resource_access_type.all === markAllTrue
            ? 'default'
            : 'edited';
      }
    }

    if (resourceTypeCategoryMap != null) {
      updateResourceGroupMapState(
        resourceGroupCategoryMap.map(resourceGroupCategory => {
          if (selectedResourceGroupCategory?.id === resourceGroupCategory?.id) {
            return selectedResourceGroupCategory;
          }
          return resourceGroupCategory;
        })
      );
    }
  };

  return (
    <WideTearsheet
      className='edit-permission-details-tearsheet'
      title={t('editPermission.tearsheet.title')}
      open={open}
      actions={[
        {
          kind: 'primary',
          label: t('editPermission.tearsheet.submitButtonText'),
          onClick: () => handleSubmit(),
          disabled: !enableSaveButton(),
          loading: loading,
        },
        {
          kind: 'secondary',
          label: t('editPermission.tearsheet.resetButtonText'),
          onClick: () => {
            toggleFailNotification(false);
            resetPermissions();
            setLoading(false);
          },
        },
        {
          kind: 'ghost',
          label: t('editPermission.tearsheet.cancelButtonText'),
          onClick: () => {
            onClose();
            toggleFailNotification(false);
            refreshDetailsData();
            setLoading(false);
          },
        },
      ]}
    >
      {showFailNotification && (
        <InlineNotification
          onClose={() => toggleFailNotification(false) as any}
          kind={'error'}
          title={
            errorType === 'default'
              ? (t('editPermission.failureNotification.title') as string)
              : (t('editPermission.failureNotification.authTitle') as string)
          }
          subtitle={subTitleErrorMsg}
        />
      )}
      <EditPermissionTable
        tabIndex={tabIndex}
        resourceTypeCategoryMap={resourceTypeCategoryMap}
        resourceGroupCategoryMap={resourceGroupCategoryMap}
        handleResourceTypeChange={handleResourceTypeChange}
        handleResourceGroupChange={handleResourceGroupChange}
      />
    </WideTearsheet>
  );
};

export default EditPermission;
