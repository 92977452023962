import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { Link } from 'react-router-dom';
import {
  SkeletonPlaceholder,
  Tab,
  Tabs,
  TooltipDefinition,
} from 'carbon-components-react';
import CustomSidePanel from '../../../CustomSidePanel/CustomSidePanel';
import SidePanelDetailsCard from '../../../CustomSidePanel/SidePanelDetailsComponent/SidePanelDetailsCard';
import SidePanelDetailsComponent from '../../../CustomSidePanel/SidePanelDetailsComponent/SidePanelDetailsComponent';
import SidePanelTableComponent from '../../../CustomSidePanel/TableComponent/SidePanelTableComponent';
import { NodeSidePaneDetailsComponentProps } from '../config';
import {
  ApplicationData,
  Deployment,
  DeploymentData,
  DeploymentEnvDetailsNameSpaceAndSecurityTableData,
  DeploymentEnvironment,
  NetworkSegments,
  Partition,
  ResourceGroup,
  Service,
} from '../../../../models/master';
import { Application16 } from '@carbon/icons-react';
import IconWithToolTip from '../../../IconWithToolTip/IconWithToolTip';
import images from '../../../../images/images';

import {
  getApplication,
  getDeployments,
} from '../../../../controllers/applicationApis';
import { getResourceGroup } from '../../../../controllers/resourceGroupApi';
import {
  getDeploymentEnv,
  getPartition,
} from '../../../../controllers/deploymentEnv';
import SidePanelEmptyState from '../../../../components/CustomSidePanel/SidePanelEmptyState/SidePanelEmptyState';
import { getNetworkSegmentDetails } from '../../../../controllers/networksegmentsApi';

import './ApplicationDetails.scss';
import { DEFAULT_NETWORK_SEGMENT_ID } from '../../../../lib/constants';

interface ServiceData {
  name: string;
  ports: any;
}

const defaultPermissionMap = {
  app: true,
  service: true,
  resourceGroup: true,
  deploymentEnv: true,
  partitions: true,
  networkSegments: true,
};

const defaultServerErrorMap = {
  app: '',
  deploymentEnv: '',
};

const ApplicationDetails: React.FC<NodeSidePaneDetailsComponentProps> = ({
  node,
  open,
  onClose,
  sidePanelWidth,
  handleSidePanelWidth,
  handleHeaderWidth,
  initialHeaderWidth,
  headerWidth,
}) => {
  const { t } = useTranslation('topologySidePanelAppDetails');

  const [loading, setLoading] = useState(false);
  const [appDetailsData, setAppDetailsData] = useState<ApplicationData | null>(
    null
  );
  const [deploymentEnvData, setDeploymentEnvData] =
    useState<DeploymentEnvironment | null>(null);
  const [partitionData, setPartitionData] =
    useState<DeploymentEnvDetailsNameSpaceAndSecurityTableData | null>(null);
  const [resourceGroup, setResourceGroup] = useState<ResourceGroup | null>(
    null
  );
  const [servicesData, setServicesData] = useState<ServiceData[] | null>(null);
  const [networkSegmentsData, setNetworkSegmentsData] =
    useState<NetworkSegments | null>(null);
  const [deploymentData, setDeploymentData] = useState<DeploymentData[] | null>(
    null
  );
  const [permissionMap, setPermissionMap] = useState(defaultPermissionMap);
  const [serverErrorMap, setServerErrorMap] = useState(defaultServerErrorMap);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        let appData: ApplicationData | null = null;
        try {
          appData = await getApplication(
            node.application_id,
            false,
            true,
            true
          );
          setAppDetailsData(appData);

          if (!appData?.servicePermission) {
            setPermissionMap(permissionMap => ({
              ...permissionMap,
              service: false,
            }));
          }

          if (appData) {
            setServicesData(formatServiceData(appData.services));
          }
        } catch (error) {
          const err = error as AxiosError;
          if (
            err?.response?.status === 403 &&
            err?.response?.statusText === 'Forbidden'
          ) {
            setPermissionMap(permissionMap => ({
              ...permissionMap,
              app: false,
              service: false,
            }));
          }

          const errorCode = err.response!?.status;
          if (errorCode >= 500) {
            setServerErrorMap(permissionMap => ({
              ...permissionMap,
              app: errorCode.toString(),
            }));
          }
        }

        if (appData?.resource_group_id) {
          try {
            const resourceGroup: ResourceGroup = await getResourceGroup(
              appData.resource_group_id
            );
            setResourceGroup(resourceGroup);
          } catch (error) {
            const err = error as AxiosError;
            if (
              err?.response?.status === 403 &&
              err?.response?.statusText === 'Forbidden'
            ) {
              setPermissionMap(permissionMap => ({
                ...permissionMap,
                resourceGroup: false,
              }));
            }
          }
        }
        try {
          const deploymentsData: Deployment[] = await getDeployments(
            appData?.resource_id
          );
          formatDeploymentData(deploymentsData);
        } catch (error) {
          const err = error as AxiosError;
          if (
            err?.response?.status === 403 &&
            err?.response?.statusText === 'Forbidden'
          ) {
            setPermissionMap(permissionMap => ({
              ...permissionMap,
              deploymentsData: false,
            }));
          }
        }

        if (appData?.network_segment_id) {
          try {
            const networkSegment: NetworkSegments =
              await getNetworkSegmentDetails(appData.network_segment_id);
            setNetworkSegmentsData(networkSegment);
          } catch (error) {
            const err = error as AxiosError;
            if (
              err?.response?.status === 403 &&
              err?.response?.statusText === 'Forbidden'
            ) {
              setPermissionMap(permissionMap => ({
                ...permissionMap,
                networkSegments: false,
              }));
            }
          }
        }

        if (node.depl_env_id) {
          let deploymentEnv: DeploymentEnvironment | null = null;
          try {
            deploymentEnv = await getDeploymentEnv(node.depl_env_id);
            setDeploymentEnvData(deploymentEnv);
          } catch (error) {
            const err = error as AxiosError;
            if (
              err?.response?.status === 403 &&
              err?.response?.statusText === 'Forbidden'
            ) {
              setPermissionMap(permissionMap => ({
                ...permissionMap,
                deploymentEnv: false,
              }));
            }

            const errorCode = err.response!?.status;
            if (errorCode >= 500) {
              setServerErrorMap(permissionMap => ({
                ...permissionMap,
                deploymentEnv: errorCode.toString(),
              }));
            }
          }

          if (
            deploymentEnv &&
            (deploymentEnv.type === 'cluster' ||
              deploymentEnv.type === 'vpc') &&
            node.partition_id
          ) {
            try {
              const partition: DeploymentEnvDetailsNameSpaceAndSecurityTableData =
                await getPartition(node.depl_env_id, node.partition_id);
              setPartitionData(partition);
            } catch (error) {
              const err = error as AxiosError;
              if (
                err?.response?.status === 403 &&
                err?.response?.statusText === 'Forbidden'
              ) {
                setPermissionMap(permissionMap => ({
                  ...permissionMap,
                  partitions: false,
                }));
              }
            }
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleDeplEnvClick = (data: DeploymentEnvironment | null) => {
    window.open(
      window.location.origin +
        process.env.PUBLIC_URL +
        `/deploymentEnvironmentDetails?deplId=${data?.resource_id}`
    );
  };

  const handlePartitionClick = (
    data: DeploymentEnvDetailsNameSpaceAndSecurityTableData | null
  ) => {
    const partitionLink =
      partitionData?.type === 'securitygroup'
        ? `/partitionDetails?depEnvId=${data?.vpc_id}&partitionId=${data?.resource_id}`
        : `/partitionDetails?depEnvId=${data?.cluster_id}&partitionId=${data?.resource_id}`;
    window.open(
      window.location.origin + process.env.PUBLIC_URL + partitionLink
    );
  };

  const handleNetworkSegmentClick = (data: NetworkSegments | null) => {
    window.open(
      window.location.origin +
        process.env.PUBLIC_URL +
        `/networkSegmentDetails?nwSegId=${networkSegmentsData?.resource_id}`
    );
  };

  const formatAppDetailsData = () => {
    const formattedData = [
      {
        label: t('networkSegment'),
        value: networkSegmentsData?.name,
        valueType:
          networkSegmentsData?.resource_id === DEFAULT_NETWORK_SEGMENT_ID
            ? 'displayField'
            : 'link',
        linkCallback: () => handleNetworkSegmentClick(networkSegmentsData),
        notAuthorized:
          !permissionMap['app'] || !permissionMap['networkSegments'],
      },
      {
        label: t('deploymentEnvironment'),
        value: deploymentEnvData?.name,
        valueType: 'link',
        linkCallback: () => handleDeplEnvClick(deploymentEnvData),
        notAuthorized: !permissionMap['deploymentEnv'],
      },
      {
        label: t('resourceGroup'),
        value: resourceGroup?.name,
        valueType: 'displayField',
        notAuthorized: !permissionMap['app'] || !permissionMap['resourceGroup'],
      },
      {
        label: t('labels'),
        value: appDetailsData?.labels,
        valueType: 'labelsList',
        notAuthorized: !permissionMap['app'],
      },
    ];

    if (partitionData) {
      formattedData.splice(2, 0, {
        label:
          deploymentEnvData?.type === 'vpc'
            ? t('securityGroup')
            : t('namespace'),
        value: partitionData?.name,
        valueType: 'link',
        linkCallback: () => handlePartitionClick(partitionData),
        notAuthorized: !permissionMap['partitions'],
      });
    }

    return formattedData;
  };

  const getPortMappedValues = (
    ports: any,
    from: number,
    to: number,
    isTooltip?: boolean
  ) => {
    return ports.slice(from, to).map((port: any, index: number) => (
      <span key={port.port_number}>
        <span className='port-number-spacing'>{port.port_number}</span>
        <span className='protocol-spacing'>{port.protocol}</span>
        {index < ports.length - 1 && !isTooltip && <span>, </span>}
      </span>
    ));
  };

  const renderPorts = (ports: any) => {
    if (ports.length > 0) {
      return (
        <div className='pairs'>
          {getPortMappedValues(ports, 0, 2)}
          {ports.length > 2 ? (
            <TooltipDefinition
              tooltipText={getPortMappedValues(ports, 2, ports.length, true)}
              direction='bottom'
            >
              {`+${ports.slice(2).length}`}
            </TooltipDefinition>
          ) : (
            ''
          )}
        </div>
      );
    }
  };

  const formatServiceData = (data: Service[]) => {
    return data?.map(item => ({
      name: item.name,
      ports: renderPorts(item.ports),
    }));
  };

  const formatDeploymentData = (deployments: any) => {
    try {
      const depData: DeploymentData[] = [];
      if (deployments != null && deployments.length > 0) {
        deployments?.map((item: any) => {
          let environmentName: string;
          let partitionName: string;
          let partition: Partition;
          const fetchData = async () => {
            try {
              const environment = await getDeploymentEnv(item['depl_env_id']);

              if (item['depl_env_id'] && item['partition_id']) {
                partition = await getPartition(
                  item['depl_env_id'],
                  item['partition_id']
                );
              }
              partitionName = partition?.name;
              environmentName =
                partitionName != null && partitionName.length > 0
                  ? environment?.name + ' / ' + partitionName
                  : environment?.name;
              let obj = {
                id: item['resource_id'],
                environment: {
                  id: item['depl_env_id'],
                  name: environmentName,
                },
                partition: partitionName,
              };
              depData.push(obj);
              setDeploymentData([...depData]);
            } catch (error) {
              console.error(error);
            }
          };
          fetchData();
        });
      } else {
        setDeploymentData(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formatOtherDepl = () => {
    let rows: {
      name: JSX.Element | undefined;
    }[] = [];
    if (deploymentData) {
      deploymentData?.forEach(depEnv => {
        rows.push({
          name: (
            <div className='sidepanel-table-link'>
              <Link
                to={`/applicationDeplDetails?appId=${appDetailsData?.resource_id}&deplId=${depEnv?.id}`}
                target='_blank'
                className='no-underline-link hyperLink'
              >
                {depEnv?.environment?.name}
              </Link>
            </div>
          ),
        });
      });
    }
    return rows;
  };

  const getHeaderTitle = () => {
    if (appDetailsData?.is_discovered) {
      return (
        <div className='header-title'>
          <span>
            <Application16 /> {node.name}{' '}
          </span>
          <span className='page-title-icon'>
            <IconWithToolTip
              icon={images.AutoDiscoverdLockIcon()}
              iconDescription={t('autoDiscoveredAccessLimited')}
            />
          </span>
        </div>
      );
    }

    if (node) {
      return (
        <div>
          <Application16 /> {node.name}
        </div>
      );
    }

    return '';
  };

  const getLink = () => {
    if (node) {
      return `/ApplicationDetails?appId=${node.application_id}` ?? ' ';
    }

    return '';
  };

  const title = getHeaderTitle();
  const link = getLink();

  return (
    <div className='topology-application-details-container'>
      <CustomSidePanel
        open={open}
        onClose={onClose}
        title={title}
        link={link}
        sidePanelWidth={sidePanelWidth}
        handleSidePanelWidth={handleSidePanelWidth}
        handleHeaderWidth={handleHeaderWidth}
        initialHeaderWidth={initialHeaderWidth}
        headerWidth={headerWidth}
      >
        <Tabs>
          <Tab id='topology-details-tab-1' label={t('details')}>
            <SidePanelDetailsComponent title={t('applicationDetails')}>
              {loading && (
                <div className='skeleton-container'>
                  <SkeletonPlaceholder className='app-details-skeleton' />
                  <SkeletonPlaceholder className='app-details-skeleton' />
                </div>
              )}
              {!loading && (
                <SidePanelDetailsCard
                  data={formatAppDetailsData()}
                  notAuthorized={
                    !permissionMap['deploymentEnv'] && !permissionMap['app']
                  }
                  error500={!!serverErrorMap['app']}
                />
              )}
            </SidePanelDetailsComponent>

            <SidePanelDetailsComponent
              title={`${t('services')} ${
                Array.isArray(servicesData)
                  ? '(' + servicesData.length + ')'
                  : ''
              }`}
            >
              {loading && (
                <div className='skeleton-container'>
                  <SkeletonPlaceholder className='app-details-skeleton' />
                  <SkeletonPlaceholder className='app-details-skeleton' />
                </div>
              )}
              {!loading && servicesData && servicesData.length > 0 ? (
                <SidePanelTableComponent
                  headers={[
                    { key: 'name', header: t('name') },
                    { key: 'ports', header: t('port') },
                  ]}
                  rows={servicesData}
                />
              ) : !loading ? (
                <SidePanelEmptyState
                  icon={images.noServicesSmallIcon()}
                  header={t('emptyState.header')}
                  message={t('emptyState.message')}
                  btnText={t('emptyState.btnText')}
                  btnUrl={`/ApplicationDetails?appId=${node.application_id}`}
                  resourceType='SERVICE'
                  notAuthorized={
                    !permissionMap['app'] || !permissionMap['service']
                  }
                  error500={!!serverErrorMap['app']}
                />
              ) : null}
            </SidePanelDetailsComponent>

            <SidePanelDetailsComponent
              title={`${t('otherDeployments')} ${
                Array.isArray(deploymentData)
                  ? '(' + deploymentData?.length + ')'
                  : ''
              }`}
            >
              {loading && (
                <div className='skeleton-container'>
                  <SkeletonPlaceholder className='app-details-skeleton' />
                  <SkeletonPlaceholder className='app-details-skeleton' />
                </div>
              )}
              {!loading && deploymentData ? (
                <SidePanelTableComponent
                  headers={[{ key: 'name', header: t('DeplEnvAndPartition') }]}
                  rows={formatOtherDepl()}
                />
              ) : !loading ? (
                <SidePanelEmptyState
                  icon={images.noAppDeploymentSmallIcon()}
                  header={t('emptyState.deployments.header')}
                  message={t('emptyState.deployments.message')}
                  btnText={t('emptyState.deployments.btnText')}
                  btnUrl={`/ApplicationDetails?appId=${node.application_id}`}
                  resourceType='ENVIRONMENT'
                  notAuthorized={
                    !permissionMap['app'] || !permissionMap['deploymentEnv']
                  }
                  error500={!!serverErrorMap['deploymentEnv']}
                />
              ) : null}
            </SidePanelDetailsComponent>
          </Tab>
        </Tabs>
      </CustomSidePanel>
    </div>
  );
};

export default ApplicationDetails;
