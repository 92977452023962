import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateTearsheetStep } from '@carbon/ibm-products';
import { Grid, Row, Column } from 'carbon-components-react';

import ConnectionAccessPolicyStateView from '../ConnectionAccessPolicyStateView/ConnectionAccessPolicyStateView';
import ConnectionAccessPoliciesFilter from '../ConnectionAccessPoliciesFilter/ConnectionAccessPoliciesFilter';
import SelectedDataCard from './SelectedDataCard/SelectedDataCard';
import {
  ConnectionTypesItems,
  DropdownData,
  Item,
  SelectedType,
  TableTypes,
  getTabConfig,
} from '../../config';
import { FormDataStep1 } from '../AddDetails';
import images from '../../../../images/images.js';

import './AllowConnections.scss';
import { NetworkSegmentCompatibilitySet } from '../../../../lib/enums';

interface Props {
  selected: SelectedType;
  fromItems: Item[];
  toItems: Item[];
  viewAllOptions: boolean;
  onViewAllOptions: (open: boolean, type: SelectedType) => void;
  onRemoveOptions: (data: Item, type: SelectedType) => void;
  onAddOptions: (data: Item) => void;
  onSelect: (type: SelectedType) => void;
  getTableData: (type: TableTypes) => ConnectionTypesItems;
  step1FormData: FormDataStep1;
}

const AllowConnections: React.FC<Props> = ({
  selected,
  fromItems,
  toItems,
  viewAllOptions,
  onViewAllOptions,
  onRemoveOptions,
  onAddOptions,
  onSelect,
  getTableData,
  step1FormData,
}) => {
  const [suggestionOptions, setSuggestionOptions] = useState<DropdownData[]>(
    []
  );
  const [filterView, setFilterView] = useState('All');

  const { t } = useTranslation('createPolicyAllowConnections');

  const tabs = getTabConfig(selected, t);

  useEffect(() => {
    filteredSuggestionOptions();
  }, []);

  useEffect(() => {
    filteredSuggestionOptions();
  }, [filterView]);

  useEffect(() => {
    filteredSuggestionOptions();
  }, [selected]);

  const handleSelect = (type: SelectedType) => {
    setFilterView('All');
    onSelect(type);
  };

  const handleRemove = (data: Item, type: SelectedType) => {
    if (selected !== type) handleSelect(type);
    onRemoveOptions(data, type);
  };

  const getSuggestionOptions = (tabs: any) => {
    const dropDownData: DropdownData[] = [];
    if (Array.isArray(tabs)) {
      tabs.map(tab => {
        const optionValues = getTableData(tab.name as TableTypes) as any;
        const options =
          optionValues && Array.isArray(optionValues)
            ? optionValues.filter((option: any) => {
                return (selected === 'from' ? fromItems : toItems).every(
                  (item: any) => {
                    return item.id !== option.id;
                  }
                );
              })
            : [];
        dropDownData.push({
          type: tab.name,
          suggestionTitle: tab.label,
          suggestionOptions: options,
          icon: tab.icon,
          suggestionDisplayKey: 'name',
          filterKey: 'name',
        });
      });
    }

    return dropDownData;
  };

  const filteredSuggestionOptions = () => {
    let options: DropdownData[] = getSuggestionOptions(tabs);
    let filteredOptions: DropdownData[] = [];
    filterView === 'All'
      ? (filteredOptions = options)
      : (filteredOptions = options.filter(
          item => item.suggestionTitle === filterView
        ));
    setSuggestionOptions(filteredOptions);
  };

  const addSelectedItem = (item: any, type: any, valueColumn = 'name') => {
    const data = {
      id: item.id,
      label: (item as any)[valueColumn],
      type,
      parentId: type === 'partitions' ? (item as any).deploymentEnvId : '',
    };
    onAddOptions(data);
  };

  return (
    <React.Fragment>
      <CreateTearsheetStep
        title={t('title')}
        // subtitle={t('subtitle')}
        hasFieldset={false}
        className='connection-access-policy-add-connection'
        disableSubmit={fromItems.length === 0 || toItems.length === 0}
        onNext={() => {}}
      >
        <Grid>
          <Row>
            <Column md={8}>
              <div className='segmentInfo'>
                <div className='segment-details-container'>
                  <div className='segmentValue'>
                    <div className='label'>{t('networkSegment')}</div>
                    <div className='value'>
                      {step1FormData?.networkSegment?.value?.name ?? ''}
                    </div>
                  </div>
                  <div className='gatewaySetValue'>
                    <div className='label'>{t('gatewaySet')}</div>
                    <div className='value'>
                      {t(
                        step1FormData?.networkSegment?.value
                          ?.compatibility_set ?? ''
                      )}
                    </div>
                  </div>
                </div>
                {t('subtitle')}
              </div>
            </Column>
          </Row>
          <Row>
            <Column sm={2}>
              <ConnectionAccessPolicyStateView
                selected={selected}
                fromItems={fromItems}
                toItems={toItems}
                onSelect={handleSelect}
                onRemove={handleRemove}
                disableFrom={
                  step1FormData?.networkSegment?.value?.compatibility_set ===
                  NetworkSegmentCompatibilitySet.ServiceInterconnect
                }
              />
            </Column>
          </Row>
          <Row className='filterContainer'>
            <Column sm={4}>
              <ConnectionAccessPoliciesFilter
                data={suggestionOptions as any}
                onViewAllBtnClick={() => onViewAllOptions(true, selected)}
                onOptionAdd={(value: any, type: any) =>
                  addSelectedItem(value, type)
                }
                onViewChange={(view: string) => setFilterView(view)}
                leftInlineFilters={[
                  {
                    key: t('view'),
                    type: 'single',
                    values: [
                      t('leftFilter.dropdownOptionAll'),
                      ...getSuggestionOptions(tabs)
                        ?.map(item => item.suggestionTitle)
                        .filter(
                          (value, index, self) => self.indexOf(value) === index
                        ),
                    ],
                    label: t('leftFilter.label') as string,
                  },
                ]}
                filterView={filterView}
              />
            </Column>
          </Row>
          <Row className='selectedDataViewContainer'>
            <Column sm={4}>
              <div className='selectedDataWrapper'>
                <div className='fromContainer'>
                  <SelectedDataCard
                    heading={'from'}
                    selected={selected}
                    selectedData={fromItems}
                    onRemove={handleRemove}
                    selectedSegment={step1FormData?.networkSegment?.value}
                    onViewAllBtnClick={() => {
                      handleSelect('from');
                      onViewAllOptions(true, 'from');
                    }}
                    onSelect={handleSelect}
                    btnDisabled={viewAllOptions}
                    disableFrom={
                      step1FormData?.networkSegment?.value
                        ?.compatibility_set === 'RHSI'
                    }
                  />
                </div>
                <div className='connectionIcon'>{images.ConnectionIcon()}</div>
                <div className='toContainer'>
                  <SelectedDataCard
                    heading={'to'}
                    selected={selected}
                    selectedData={toItems}
                    onRemove={handleRemove}
                    onViewAllBtnClick={() => {
                      handleSelect('to');
                      onViewAllOptions(true, 'to');
                    }}
                    onSelect={handleSelect}
                    btnDisabled={viewAllOptions}
                  />
                </div>
              </div>
            </Column>
          </Row>
        </Grid>
      </CreateTearsheetStep>
    </React.Fragment>
  );
};

export default AllowConnections;
