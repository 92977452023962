import React, { useState } from 'react';
import './StatsRow.scss';

interface Props {
  statsList: {
    key: string;
    title: string;
    value: number;
    icon?: JSX.Element;
  }[];
  onClick: (key: string) => void;
}

const StatsRow: React.FC<Props> = props => {
  const [isHovered, handleHoverState] = useState<{
    isHovered: boolean;
    index: string | null;
  }>({
    isHovered: false,
    index: null,
  });
  return (
    <div className='headercontainer'>
      {props?.statsList.map(item => {
        return (
          <div
            className={
              'item' +
              (isHovered.isHovered && isHovered.index === item.key
                ? ' hovered'
                : '')
            }
            onClick={() => (item.value ? props.onClick(item.key) : null)}
            key={item.key}
            onMouseEnter={() =>
              item.value
                ? handleHoverState({
                    isHovered: true,
                    index: item.key,
                  })
                : null
            }
            onMouseLeave={() =>
              item.value
                ? handleHoverState({
                    isHovered: false,
                    index: null,
                  })
                : null
            }
          >
            <div className='title'>{item.title}</div>
            <div className='value'>
              {item && item.icon ? (
                <div className='icon'>{item.icon}</div>
              ) : null}
              {item.value}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default StatsRow;
