import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Column,
  Tile,
  Button,
  SkeletonPlaceholder,
  TooltipDefinition,
  Modal,
  Link as CarbonLink,
} from 'carbon-components-react';
import Header from '../../components/Header/Header';
import DetailsCard from '../../components/DetailsCard/DetailsCard';
import EdgeService24 from '@carbon/icons-react/lib/edge-service/24';
import Application24 from '@carbon/icons-react/lib/application/24';
import images from '../../images/images';
import './ConnectionAccessPolicyDetails.scss';
import {
  getPolicy,
  deletePolicy,
  getPolicies,
} from '../../controllers/policyApi';
import { useEffect, useState } from 'react';
import { NotificationContext } from '../../components/Notifications/Context/NotificationProvider';
import { useSearchParams } from 'react-router-dom';
import LabelTag from '../../components/LabelTag/LabelTag';
import dateUtils from '../../lib/dates';
import {
  Policy,
  ApplicationData,
  Service,
  Deployment,
  ResourceGroup,
  AppliedFilter,
  PartitionData,
  Error500Type,
} from '../../models/master';
import { useNavigate } from 'react-router-dom';
import EditPolicyDetails from './ConnectionAccessPoliciesEdit/EditPolicyDetails';
import { Edit16 } from '@carbon/icons-react';
import EditPolicyConnection from './ConnectionAccessPoliciesEdit/EditPolicyConnection';
import { getResourceGroups } from '../../controllers/resourceGroupApi';
import { getDeploymentEnvs } from '../../controllers/deploymentEnv';
import { getApplications } from '../../controllers/applicationApis';
import { AxiosError } from 'axios';
import { TableTypes } from './config';
import { getNetworkSegmentDetails } from '../../controllers/networksegmentsApi';
import useAnalytics from '../../lib/useAnalytics';
import analyticsData from '../../lib/analyticsEventData';
import Error500 from '../Errors/Error500';
import {
  DEFAULT_NETWORK_SEGMENT_ID,
  defaultNetworkSegmentObject,
} from '../../lib/constants';

interface PolicyData extends Policy {
  appData: ApplicationData;
  serviceData: Service;
  partitionData: PartitionData;
}

interface ServiceData extends Service {
  id: string;
  applicationName: string;
}

const ConnectionAccessPolicyDetails = () => {
  const { t } = useTranslation('connectionAccessPolicyDetails');
  const defaultPermissionMap = {
    networkSegment: true,
  };

  const [policyDataLoading, togglePolicyDataLoading] = useState(false);
  const [policyData, setPolicyData] = useState<any>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const policyId = searchParams.get('policyId');
  const [openDeletePolicy, setOpenDeletePolicy] = useState<boolean>(false);
  const [showEditPolicyDetails, setShowEditPolicyDetails] =
    useState<boolean>(false);
  const [showEditPolicyConnectionDetails, setShowEditPolicyConnectionDetails] =
    useState<boolean>(false);
  const notification = useContext(NotificationContext);
  const [applications, setApplications] = useState<ApplicationData[] | null>(
    null
  );
  const [deployments, setDeployments] = useState<Deployment[] | null>(null);
  const [resourceGroups, setResourceGroups] = useState<ResourceGroup[] | null>(
    null
  );
  const [services, setServices] = useState<ServiceData[] | null>(null);
  const [disableButton, setDisableButton] = useState(false);
  const [networkSegment, setNetworkSegment] = useState<any>(null);
  const [policyList, setPolicyList] = useState<any>(null);
  const [error500, setError500] = useState<null | Error500Type>(null);
  const [permissionMap, setPermissionMap] = useState(defaultPermissionMap);

  const navigate = useNavigate();
  const { trackButtonClicked } = useAnalytics();

  // Since right now we disabled the option to edit From and To part of a policy, so we no longer need to fetch these.
  // useEffect(() => {
  //   fetchApplications();
  //   fetchDeployments();
  // }, []);

  useEffect(() => {
    fetchResourceGroups();
  }, []);

  const fetchData = async () => {
    togglePolicyDataLoading(true);
    try {
      let res = await getPolicy(policyId);
      setPolicyData(res);
    } catch (error) {
      const err = error as AxiosError;

      if (err.response?.status === 404) {
        navigate('/404');
      }

      if (err.response!?.status >= 500) {
        setError500(err.response!?.status?.toString() as Error500Type);
      }
    } finally {
      togglePolicyDataLoading(false);
    }
  };

  const fetchPolicies = async () => {
    try {
      let response = await getPolicies(false, false);
      setPolicyList(response);
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    fetchNwSegment(policyData?.network_segment_id);
  }, [policyData]);

  useEffect(() => {
    fetchData();
  }, [policyId, showEditPolicyConnectionDetails, showEditPolicyDetails]);

  useEffect(() => {
    fetchPolicies();
  }, []);
  //f (policyData?.serviceData===undefined){

  const handleRefresh = (type: TableTypes) => {
    switch (type) {
      case 'applications': {
        setApplications(null);
        return fetchApplications();
      }
      case 'services': {
        setServices(null);
        return fetchApplications();
      }
    }
  };

  const fetchResourceGroups = async () => {
    const resourceGroupResponse: { resource_groups: ResourceGroup[] } | any =
      await getResourceGroups();
    setResourceGroups(resourceGroupResponse?.resource_groups ?? []);
  };

  const fetchApplications = async () => {
    try {
      const response: ApplicationData[] = await getApplications();

      const services = [];
      for (const application of response) {
        const applicationService = application?.services.map(srv => ({
          ...srv,
          id: `${srv.resource_id}+${application?.app_identity}`,
          ports: srv.ports,
          applicationName: application?.name,
          application_id: application?.resource_id,
        }));
        services?.push(...applicationService);
      }

      setApplications(response);

      setServices(services);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDeployments = async () => {
    try {
      const response = await getDeploymentEnvs();
      const deploymentEnv = response.map((depEnv: any) => ({
        ...depEnv,
        id: `${depEnv?.resource_id}`,
      }));

      setDeployments(deploymentEnv);
    } catch (error) {
      console.error(error);
    }
  };

  const getPortMappedValues = (
    from: number,
    to: number,
    isTooltip?: boolean
  ) => {
    return policyData?.serviceData?.ports
      .slice(from, to)
      .map((port: any, index: any) => (
        <span key={port?.port_number}>
          <span>{port?.port_number}</span>
          <span className='protocol-spacing'>{port?.protocol}</span>
          {index < policyData?.serviceData?.ports?.length - 1 && !isTooltip && (
            <span className='ports-spacing'>{'|'}</span>
          )}
        </span>
      ));
  };

  const renderPolicyPorts = () => {
    const ports = policyData?.serviceData?.ports;
    if (ports && ports?.length > 0) {
      return (
        <div className='policy-ports'>
          {getPortMappedValues(0, 3)}
          {ports.length > 3 ? (
            <TooltipDefinition
              tooltipText={getPortMappedValues(3, ports?.length, true) || ''}
              direction='bottom'
            >
              {`+${ports?.slice(3).length}`}
            </TooltipDefinition>
          ) : (
            ''
          )}
        </div>
      );
    }
  };

  const DeletePolicyModalClose = () => {
    setOpenDeletePolicy(false);
  };

  const openDeletePolicyModal = () => {
    trackButtonClicked(
      analyticsData['Policy Details'].events.deletePolicy.props,
      analyticsData['Policy Details'].events.deletePolicy.event
    );
    setOpenDeletePolicy(true);
  };

  const showEditPolicyModal = () => {
    trackButtonClicked(
      analyticsData['Policy Details'].events.editPolicyDetails.props,
      analyticsData['Policy Details'].events.editPolicyDetails.event
    );
    setShowEditPolicyDetails(true);
  };

  const editedPolicy = () => {
    setShowEditPolicyDetails(false);
    setShowEditPolicyConnectionDetails(false);
    togglePolicyDataLoading(true);
  };

  const showEditPolicyConnectionModal = () => {
    trackButtonClicked(
      analyticsData['Policy Details'].events.editPolicyConnection.props,
      analyticsData['Policy Details'].events.editPolicyConnection.event
    );
    setShowEditPolicyConnectionDetails(true);
  };

  const handleUpdatePolicy = (data: PolicyData) => {
    setPolicyData(data);
  };

  const handleUpdateConnPolicy = (data: PolicyData) => {
    const fetchData = async () => {
      let res = await getPolicy(policyId);
      setPolicyData(res);
    };
    fetchData();
  };

  const proceedDeletePolicy = async () => {
    try {
      setDisableButton(true);
      await deletePolicy(policyId);
      notification.onTrigger('TOAST', {
        title: t('success'),
        subtitle: t('successMsg'),
      });
      navigate('/connectionAccessPolicies');
    } catch (error: any) {
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        notification.onTrigger('TOAST', {
          title: t('authTitleError'),
          kind: 'error',
          subtitle: t('authSubtitleError'),
        });
      } else {
        const errorMessage: string =
          error.response !== undefined
            ? error.response['customErrorMessage']
            : '';
        notification.onTrigger('TOAST', {
          title: t('error'),
          kind: 'error',
          subtitle: errorMessage.length > 0 ? errorMessage : t('errorMsg'),
        });
      }
    } finally {
      setDisableButton(false);
      setOpenDeletePolicy(false);
    }
  };

  const fromData =
    policyData?.from?.application?.application_id !== ''
      ? policyData?.appData
      : policyData?.from?.partition?.partition_id !== ''
      ? policyData?.partitionData
      : undefined;
  const fetchNwSegment = async (id: string) => {
    try {
      if (id) {
        const response = await getNetworkSegmentDetails(id);
        setNetworkSegment(response);
      }
    } catch (error) {
      const err = error as AxiosError;
      console.error(err);
      if (
        err?.response?.status === 403 &&
        err?.response?.statusText === 'Forbidden'
      ) {
        setPermissionMap(permissionMap => ({
          ...permissionMap,
          networkSegment: false,
        }));
      }
    }
  };

  if (error500) {
    return <Error500 />;
  }

  return (
    <div className='connection-access-policy-details'>
      <Header
        loading={policyDataLoading}
        title={policyData?.name ?? ''}
        breadcrumbs={[
          {
            url: '/',
            label: t('home'),
          },
          {
            url: '/connectionAccessPolicies',
            label: t('connectionAccessPolicies'),
          },
        ]}
      />
      <div className='canvas'>
        <Column lg={4} className='details-card-container'>
          {policyDataLoading ? (
            <SkeletonPlaceholder className='policy-details-skeleton' />
          ) : (
            <DetailsCard
              type={'POLICY'}
              data={[
                {
                  key: 'name',
                  value: policyData?.name,
                },
                {
                  key: 'created',
                  value: dateUtils.getUserFriendlyDate(policyData?.created_at),
                },
                {
                  key: 'networkSegment',
                  value: networkSegment?.name,
                },
                {
                  key: 'gatewaySet',
                  value: networkSegment?.compatibility_set
                    ? t(`${networkSegment?.compatibility_set}`)
                    : '—',
                },
                {
                  key: 'resourceGroup',
                  value: resourceGroups
                    ? resourceGroups?.find(
                        resource =>
                          resource?.resource_id ===
                          policyData?.resource_group_id
                      )?.name
                    : '',
                },
                {
                  key: 'action',
                  value: t('allow') as string,
                },
                {
                  key: 'lastUpdated',
                  value: dateUtils.getUserFriendlyDate(policyData?.updated_at),
                },
                {
                  key: 'labels',
                  value: policyData?.labels,
                },
                {
                  key: 'description',
                  value: policyData?.description,
                },
              ]}
              openEditModal={showEditPolicyModal}
              detailsCardName={t('detailsCardName')}
              isEditable={true}
              authorizationMap={permissionMap}
            />
          )}
          {
            <div className='delete-policy'>
              <Button
                kind='danger--ghost'
                disabled={policyDataLoading}
                onClick={() => {
                  openDeletePolicyModal();
                }}
              >
                {t('deletePolicy')}
              </Button>
            </div>
          }
        </Column>
        <div className='from-to-connections-container'>
          <div className='from-to-connections-titlebar'>
            <div className='policy-title'>{t('title')}</div>
          </div>
          <div className='from-to-connections-subtitlebar'>
            {t('policySubTitle')}
          </div>
          <div className='from-to-connections'>
            <Column lg={7} className='connections'>
              <div className='title'>{t('from')}</div>
              {policyDataLoading ? (
                <SkeletonPlaceholder className='connection-from-skeleton' />
              ) : (
                policyData && (
                  <Tile className='connection-from'>
                    <div className='from-container'>
                      <div>
                        {policyData?.network_segment_id !== '' &&
                        policyData?.network_segment_id !==
                          DEFAULT_NETWORK_SEGMENT_ID ? (
                          <>
                            <Row className='name-and-icon'>
                              {policyData?.network_segment_id ? (
                                <>
                                  <div className='tile-icon'>
                                    {images.networkSegmentLargeSvg()}
                                  </div>
                                  <div
                                    className='policy-header'
                                    title={networkSegment?.name}
                                  >
                                    {networkSegment?.name}
                                  </div>
                                </>
                              ) : (
                                '—'
                              )}
                            </Row>
                            <Row className='app-description'>
                              {t('fromNetworkSegment')}
                            </Row>
                          </>
                        ) : (
                          <>
                            <Row className='name-and-icon'>
                              {fromData ? (
                                <>
                                  <div className='tile-icon'>
                                    {policyData?.from?.application
                                      .application_id !== '' ? (
                                      <Application24 className='icon' />
                                    ) : (
                                      images.partitionMediumIcon()
                                    )}
                                  </div>
                                  <div
                                    className='policy-header'
                                    title={fromData?.name}
                                  >
                                    {fromData?.name}
                                  </div>
                                </>
                              ) : (
                                '—'
                              )}
                            </Row>
                            <Row className='app-description'>
                              {fromData?.description}
                            </Row>

                            <Row>
                              <div className='labels-container'>
                                {fromData?.labels &&
                                Array.isArray(fromData?.labels) &&
                                fromData?.labels.length > 0 ? (
                                  <LabelTag
                                    labelArray={fromData?.labels as string[]}
                                    count={3}
                                  ></LabelTag>
                                ) : (
                                  ''
                                )}
                              </div>
                            </Row>
                          </>
                        )}
                      </div>
                    </div>
                  </Tile>
                )
              )}
            </Column>
            <div className='connector'>
              <div>{images.policyConnectionIcon()}</div>
            </div>
            <Column lg={7} className='connections'>
              <div className='title'>{t('to')}</div>
              {policyDataLoading ? (
                <SkeletonPlaceholder className='connection-to-skeleton' />
              ) : (
                policyData && (
                  <Tile className='connection-to'>
                    <div className='to-container'>
                      <Row className='name-and-icon'>
                        {policyData?.serviceData?.name ? (
                          <>
                            <div className='tile-icon'>
                              <EdgeService24 className='icon' />
                            </div>
                            <div
                              className='policy-header'
                              title={policyData?.serviceData?.name}
                            >
                              {policyData?.serviceData?.name}
                            </div>
                          </>
                        ) : (
                          '—'
                        )}
                      </Row>
                      <Row>{renderPolicyPorts()}</Row>
                    </div>
                  </Tile>
                )
              )}
            </Column>
          </div>
        </div>
      </div>
      <Modal
        open={openDeletePolicy}
        danger
        modalHeading={t('deleteMsg', { policyName: policyData?.name })}
        modalLabel={t('deletePolicy')}
        primaryButtonText={t('delete')}
        secondaryButtonText={t('cancel')}
        preventCloseOnClickOutside={true}
        onRequestSubmit={() => {
          proceedDeletePolicy();
        }}
        onRequestClose={() => {
          DeletePolicyModalClose();
        }}
        className='deletePolicyModel'
        size='sm'
        primaryButtonDisabled={disableButton}
      />

      {showEditPolicyDetails && policyData && (
        <EditPolicyDetails
          open={showEditPolicyDetails}
          onClose={() => setShowEditPolicyDetails(false)}
          policyDetailsData={policyData}
          updatePolicyData={handleUpdatePolicy}
          onRefresh={() => editedPolicy()}
          editedPolicy={() => editedPolicy()}
          networkSegment={networkSegment}
          hasNetworkSegmentAuth={permissionMap.networkSegment}
          policyList={policyList}
        />
      )}

      {showEditPolicyConnectionDetails && policyData && (
        <EditPolicyConnection
          open={true}
          onClose={() => setShowEditPolicyConnectionDetails(false)}
          policyDetailsData={policyData}
          onRefresh={(t: TableTypes) => handleRefresh(t)}
          applications={applications}
          services={services}
          resourceGroups={resourceGroups}
          editedPolicy={() => editedPolicy()}
          updatePolicyConnData={handleUpdateConnPolicy}
        />
      )}
    </div>
  );
};

export default ConnectionAccessPolicyDetails;
