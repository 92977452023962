import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { registerNamespace } from '../../controllers/partitionApi';
import { NotificationContext } from '../../components/Notifications/Context/NotificationProvider';
import { removeExtraSpace } from '../../lib/utils';
import { AxiosError } from 'axios';
import { Modal } from '@carbon/react';

import { getSecrets } from '../../controllers/tenantApis';
import MultiStepTearsheet from '../../components/MultiStepTearSheet/MultiStepTearSheet';
import {
  DeploymentEnvDetailsNameSpaceAndSecurityTableData,
  Gateway,
  GatewayIngressTypes,
  LinkedAgent,
  NetworkSegments,
  ResourceGroup,
  SkupperComputeProfile,
} from '../../models/master';
import { getNetworkSegments } from '../../controllers/networksegmentsApi';
import { getResourceGroups } from '../../controllers/resourceGroupApi';
import { getAgentList } from '../../controllers/agentApi';
import {
  getGatewayRemoteConnections,
  getGateways,
  addGateway,
  updateGateway,
  getGatewayIngressTypes,
  getRhsiComputeProfile,
} from '../../controllers/gateawayApis';
import { getNamespaceDetails } from '../../controllers/deploymentEnv';
import CreateNetworkSegment from '../NetworkSegmentsContainer/CreateNetworkSegment/CreateNetworkSegment';
import {
  getPartitions,
  updateNamespaceData,
  updatePartitionNamespace,
} from '../../controllers/deploymentEnv';
import analyticsData from '../../lib/analyticsEventData';
import useAnalytics from '../../lib/useAnalytics';
import {
  GatewaySubTypes,
  NetworkSegmentCompatibilitySet,
  QueryKeys,
} from '../../lib/enums';
import { inValidateListCacheFn } from '../../lib/invalidateQueriesFunctions';

import './RegisterNamespace.scss';
import DeployGateway from './DeployGateway';
import NamespaceDetailsForm from './NamespaceDetailsForm';
import { useDeploymentEnvSubtypes } from '../../hooks/useDeploymentEnvs';
import {
  DEFAULT_NETWORK_SEGMENT_ID,
  defaultNetworkSegmentObject,
} from '../../lib/constants';

const defaultFormValue = {
  name: {
    value: '',
    error: false,
    errorMessage: '',
  },
  labels: {
    value: [],
    error: false,
    errorMessage: '',
  },

  // the key in the secrets manager for the credentials to this node/cluster
  credentials_key: {
    id: '',
    name: '',
    error: false,
    errorMessage: '',
  },
  auto_discover: {
    value: false,
    error: false,
    errorMessage: '',
  },
  managedStatus: {
    value: true,
    error: false,
    errorMessage: '',
  },
  secretsName: {
    value: '',
    error: false,
    errorMessage: '',
  },
  description: {
    value: '',
    error: false,
    errorMessage: '',
  },
  networkSegment: {
    value: null,
    error: false,
    errorMessage: '',
  },
  appResourceGroup: {
    value: null,
    error: false,
    errorMessage: '',
  },
  gatewayName: {
    value: '',
    error: false,
    errorMessage: '',
  },
  agent_node: {
    value: null,
    error: false,
    errorMessage: '',
  },
  gatewayId: {
    value: '',
    isDisconnected: false,
    error: false,
    errorMessage: '',
  },
  infrastructureGroup: {
    value: null,
    error: false,
    errorMessage: '',
  },
  gatewayDetailsData: null,
  install_method: 'fromAgent',
};

const requiredFields = ['name', 'networkSegment'];

interface Props {
  open: boolean;
  hasConnectedPolicy?: boolean;
  onClose: () => void;
  partitonType: any;
  deploymentdata: any;
  creatPartition: () => void;
  mode: 'ADD' | 'EDIT';
  appsDeployed?: any[] | null;
  partitionData?: any;
  registerNamespaceId?: string;
}

const defaultPermissionMap = {
  networkSegments: true,
};

const RegisterNamespace: React.FC<Props> = ({
  open,
  onClose,
  partitonType,
  deploymentdata,
  creatPartition,
  mode,
  appsDeployed,
  partitionData,
  hasConnectedPolicy = false,
  registerNamespaceId = 'namespace',
}) => {
  const [simulatedDelay] = useState(650);
  const [formData, setFormData] = useState<any>(defaultFormValue);
  const [initialformData, setInitialFormDataEdit] =
    useState<any>(defaultFormValue);
  const [showFailNotification, toggleFailNotification] = useState(false);
  const [showUpdateFailNotification, toggleUpdateFailNotification] =
    useState(false);
  const [changed, setChanged] = useState(false);
  const { t } = useTranslation('registerNamespace');
  const notification = useContext(NotificationContext);
  const [authError, setAuthError] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [gatewayFailNotification, toggleGatewayFailNotification] =
    useState(false);
  const [gatewayAuthError, setGatewayAuthError] = useState(false);
  const [createGatewayLoader, setCreateGatewayLoader] = useState(false);
  const [secrets, setSecrets] = useState(null);
  const [nwSegmentsDataLoading, setNwSegmentsDataLoading] = useState(false);
  const [editDataLoading, isEditDataLoading] = useState(false);
  const [networkSegments, setNetworkSegments] = useState<
    NetworkSegments[] | null
  >(null);
  const [permissionMap, setPermissionMap] = useState(defaultPermissionMap);
  const [openCreateNetworkSegment, toggleCreateNetworkSegment] =
    useState(false);
  const [openNoGatewayConnectedModal, setNoGatewayConnectedModal] =
    useState(false);
  const [isLoading, setLoading] = useState(false);
  const [openGatewayContinueModal, toggleGatewayContinueModal] =
    useState(false);
  const [openDisconnectGatewayModal, toggleDisconnectGatewayModal] =
    useState(false);
  const [existingCompactibilitySet, setExistingCompactibilitySet] =
    useState('');
  const [resourceGroupsData, setResourceGroupsData] = useState<
    ResourceGroup[] | null
  >(null);
  const [appResourceGroups, setAppResourceGroups] = useState<
    ResourceGroup[] | null
  >(null);
  const [gatewayConnected, setGatewayConnected] = useState<Gateway | null>(
    null
  );
  const [gatewayConnectedRemoteConnections, setGatewayRemoteConnections] =
    useState<any>([]);
  const [partitionListData, setPartitionListData] = useState<any>(null);
  const [agentList, setAgentList] = useState<LinkedAgent | null>(null);
  const [agentError, setAgentError] = useState<any>({
    error: false,
    errorMsg: '',
  });
  const [gatewayList, setGatewayList] = useState<Gateway[] | []>([]);
  const [gatewayNames, setGatewayNames] = useState<any>([]);
  const [gwIngressTypes, setGwIngressTypes] = useState<GatewayIngressTypes[]>(
    []
  );
  const [gwRhsiComputeProfiles, setGwRhsiComputeProfiles] = useState<
    SkupperComputeProfile[]
  >([]);

  // Confirmation Modal states
  const [status, setStatus] = useState('inactive');
  const [description, setDescription] = useState('Submitting...');

  const { trackButtonClicked } = useAnalytics();
  let submitInProgress = false;

  const fetchSecrets = async () => {
    try {
      const response = await getSecrets();
      setSecrets(response?.secrets);
    } catch (error: any) {
      console.log(error);
    }
  };

  // Queries deployment environment subtypes
  const { data: allDepEnvSubTypes } = useDeploymentEnvSubtypes({
    staleTime: Infinity,
    enabled: open,
  });

  useEffect(() => {
    if (mode === 'EDIT') updateFormData();
  }, [
    partitionData,
    partitionListData,
    networkSegments,
    gatewayConnected,
    resourceGroupsData,
  ]);

  const fetchGatewayList = async () => {
    let gateways = null;
    try {
      gateways = await getGateways();
      let connectedGateway = gateways?.find(
        (gateway: Gateway) => gateway?.resource_id === partitionData?.gateway_id
      );
      setGatewayConnected(connectedGateway);
    } catch (error) {
      setGatewayConnected(null);
    } finally {
      setLoading(false);
    }
    return gateways;
  };

  const fetchExistingGatewayList = async (gatewaysData: Gateway[]) => {
    let unavailableGateways: any[] = [];
    let filteredGatewayList: Gateway[] = [];
    const gatewayNames = gatewaysData?.map((gateway: Gateway) => gateway.name);
    setGatewayNames(gatewayNames);

    filteredGatewayList = gatewaysData?.filter(
      (gateway: any) => gateway?.subtype === GatewaySubTypes.RHSI_EDGE
    );

    if (Array.isArray(filteredGatewayList)) {
      await filteredGatewayList?.map(async gateway => {
        if (
          gateway.deployed_in_type === 'namespace' &&
          gateway.cloud_id &&
          gateway.deployed_in_depl_env_id &&
          gateway.deployed_in_partition_id
        ) {
          try {
            const response = await getNamespaceDetails(
              gateway.cloud_id,
              gateway.deployed_in_depl_env_id,
              gateway.deployed_in_partition_id
            );

            if (response.gateway_id) {
              unavailableGateways.push(response.gateway_id);
            }
          } catch (error) {
            console.error(error);
          } finally {
            // Filtering the gateways that already support a namespace
            filteredGatewayList = filteredGatewayList?.filter(
              (gateway: any) =>
                !unavailableGateways?.includes(gateway?.resource_id)
            );
            setGatewayList(filteredGatewayList);
          }
        }
      });
      setGatewayList(filteredGatewayList);
    }
  };

  const fetchRemoteConnections = async (gatewayId: string) => {
    try {
      const remoteGwConnections = await getGatewayRemoteConnections(gatewayId);
      setGatewayRemoteConnections(remoteGwConnections ?? []);
    } catch (e) {
      console.log('error in remote connection fetch', e);
    } finally {
      isEditDataLoading(false);
    }
  };

  const fetchAgentList = async () => {
    try {
      setAgentError({
        error: false,
        errorMsg: '',
      });
      const response: LinkedAgent = await getAgentList();
      if (!(response?.available && response?.available?.length > 0))
        setAgentError({
          error: true,
          errorMsg: t('connectGateway.agentNotAvailable'),
        });
      setAgentList(
        response ?? {
          gateways: [],
          available: [],
        }
      );
    } catch (error: any) {
      const err = error as AxiosError;
      setAgentList({
        gateways: [],
        available: [],
      });
      if (err.response?.status === 403) {
        setAgentError({
          error: true,
          errorMsg: t('connectGateway.agentauthorization'),
        });
      } else
        setAgentError({
          error: true,
          errorMsg: t('connectGateway.agentError'),
        });
    }
  };

  const updateFormData = async () => {
    isEditDataLoading(true);
    const networkSegment =
      partitionData?.network_segment_id === DEFAULT_NETWORK_SEGMENT_ID
        ? defaultNetworkSegmentObject
        : networkSegments?.find(
            (rg: any) => rg.resource_id === partitionData?.network_segment_id
          ) || null;
    const appResource = appResourceGroups?.find(
      (rg: any) =>
        rg.resource_id ===
        (partitionData?.app_resource_group_id ?? 'default-app')
    );
    const fData = {
      ...formData,
      name: { ...formData.name, value: partitionData?.name },
      labels: {
        ...formData.labels,
        value: Array.isArray(partitionData?.labels)
          ? [...partitionData?.labels]
          : null,
      },

      // the key in the secrets manager for the credentials to this node/cluster
      credentials_key: {
        ...formData.credentials_key,
        id: partitionData?.credentials_key ?? '',
        name: partitionData?.credentials_key ?? '',
      },
      auto_discover: {
        ...formData?.auto_discover,
        value: partitionData?.auto_discover,
      },
      managedStatus: {
        ...formData?.managedStatus,
        value: !partitionData?.unmanaged,
      },
      secretsName: {
        ...formData.secretsName,
        value: partitionData?.credentials_key ?? '',
      },
      description: {
        ...formData.description,
        value: partitionData?.description ?? '',
      },
      networkSegment: {
        ...formData.networkSegment,
        value: networkSegment,
      },
      appResourceGroup: {
        ...formData.appResourceGroup,
        value: appResource,
      },
      gatewayName: {
        ...formData.gatewayName,
        value: gatewayConnected?.name,
      },
      agent_node: {
        value: null,
        error: false,
        errorMessage: '',
      },
      gatewayId: {
        ...formData.gatewayId,
        value: partitionData?.gateway_id,
        isDisconnected: false,
      },
      infrastructureGroup: {
        value: resourceGroupsData?.find(
          (rg: any) =>
            rg.resource_id ===
            (gatewayConnected?.resource_group_id ||
              networkSegment?.resource_group_id)
        ),
        error: false,
        errorMessage: '',
      },
    };

    setInitialFormDataEdit(fData);
    setFormData(fData);

    if (mode === 'EDIT') {
      handleOnChange('managedStatus', true);
    }
    if (partitionData?.gateway_id)
      await fetchRemoteConnections(partitionData?.gateway_id);
    else isEditDataLoading(false);
  };

  const fetchNwSegmentsList = async (
    setFormValue: boolean,
    fetchNamespaceList: boolean = false,
    nwSegId: string = ''
  ) => {
    setNwSegmentsDataLoading(true);
    try {
      const response = await getNetworkSegments();
      let namespaceList: any;
      if (fetchNamespaceList) namespaceList = await fetchPartitionList();
      else namespaceList = partitionListData;
      const currentPartitionDataForEdit =
        namespaceList?.data?.length === 1
          ? namespaceList.data[0]?.resource_id === partitionData?.resource_id
          : null;

      const existingNetworkSegment = !currentPartitionDataForEdit
        ? response?.find(
            (nwSeg: NetworkSegments) =>
              nwSeg?.resource_id === namespaceList?.data[0]?.network_segment_id
          )
        : null;
      const existingCompactibilitySet =
        existingNetworkSegment?.compatibility_set ?? '';
      setExistingCompactibilitySet(existingCompactibilitySet);
      const filteredSegments = existingCompactibilitySet
        ? response?.filter(
            (nwSeg: NetworkSegments) =>
              nwSeg?.compatibility_set === existingCompactibilitySet
          )
        : response;
      setNetworkSegments(filteredSegments);
      if (setFormValue) {
        const selectedNetworkSegment =
          nwSegId === DEFAULT_NETWORK_SEGMENT_ID
            ? defaultNetworkSegmentObject
            : filteredSegments.find(
                (nwSeg: NetworkSegments) => nwSeg?.resource_id === nwSegId
              );
        setFormData((prevState: any) => ({
          ...prevState,
          networkSegment: {
            value: selectedNetworkSegment,
            error: false,
            errorMessage: '',
          },
          infrastructureGroup: {
            value:
              resourceGroupsData?.find(
                (rg: ResourceGroup) =>
                  rg?.resource_id === selectedNetworkSegment?.resource_group_id
              ) ?? null,
            error: false,
            errorMessage: '',
          },
        }));
      }
    } catch (error) {
      console.error(error);
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        setPermissionMap(permissionMap => ({
          ...permissionMap,
          networkSegments: false,
        }));
      }
    } finally {
      setNwSegmentsDataLoading(false);
    }
  };

  const setGatewayDetails = async (gatewayFormData: any) => {
    if (gatewayFormData?.install_method === 'existingGateway') {
      const namespaceNameError = checkFieldValidation(
        'name',
        gatewayFormData?.gateway_node?.namespace || formData?.name?.value
      );
      setFormData((prevState: any) => ({
        ...prevState,
        gatewayDetailsData: gatewayFormData,
        gatewayId: {
          value: gatewayFormData?.gateway?.resource_id,
          error: false,
          errorMessage: '',
          isDisconnected: false,
        },
        gatewayName: {
          value: gatewayFormData?.gateway?.name,
          error: false,
          errorMessage: '',
        },
        agent_node: {
          value: gatewayFormData?.gateway_node,
          error: false,
          errorMessage: '',
        },
        name: {
          value:
            gatewayFormData?.gateway_node?.namespace || formData?.name?.value,
          error: !!namespaceNameError,
          errorMessage: namespaceNameError,
        },
        managedStatus: {
          value: true,
          error: false,
          errorMessage: '',
        },
        auto_discover: {
          value: true,
          error: false,
          errorMessage: '',
        },
        install_method: gatewayFormData?.install_method,
      }));
      toggleGatewayContinueModal(false);
    } else {
      const namespaceDuplicateNameError = checkFieldValidation(
        'name',
        gatewayFormData?.namespace_name
      );
      setFormData((prevState: any) => ({
        ...prevState,
        gatewayDetailsData: gatewayFormData,
        gatewayName: {
          value: removeExtraSpace(gatewayFormData?.gateway_name),
          error: false,
          errorMessage: '',
        },
        name: {
          value: gatewayFormData?.namespace_name,
          error: !!namespaceDuplicateNameError,
          errorMessage: namespaceDuplicateNameError,
        },
        agent_node: {
          value: gatewayFormData?.agent_node,
          error: false,
          errorMessage: '',
        },
        managedStatus: {
          value: true,
          error: false,
          errorMessage: '',
        },
        auto_discover: {
          value: true,
          error: false,
          errorMessage: '',
        },
        install_method: gatewayFormData?.install_method,
      }));
    }
  };

  const fetchResourceGroups = async () => {
    try {
      const response = await getResourceGroups();
      const resourceData = response.resource_groups as ResourceGroup[];

      const filteredInfraResourceGroups = resourceData?.filter(
        resource => resource.type === 'infrastructure'
      );
      const filteredAppResourceGroups = resourceData?.filter(
        resource => resource.type === 'application'
      );
      setAppResourceGroups(filteredAppResourceGroups);
      setResourceGroupsData(filteredInfraResourceGroups);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchPartitionList = async () => {
    let listData;
    try {
      const partition: {
        partitions: DeploymentEnvDetailsNameSpaceAndSecurityTableData[];
      } | null = await getPartitions(deploymentdata?.resource_id, 'all');
      listData =
        partition && partition?.partitions && partition?.partitions?.length > 0
          ? {
              count: partition?.partitions?.length,
              partitionType: 'namespace',
              data: partition?.partitions,
            }
          : { count: 0, data: [] };
      await setPartitionListData(listData);
    } catch (error) {
      console.log(error);
      listData = {
        count: 0,
        partitionType: 'namespace',
        data: [],
      };
      setPartitionListData(listData);
    }
    return listData;
  };

  const fetchGwIngressTypes = async () => {
    try {
      const gwIngressTypes = await getGatewayIngressTypes();
      Array.isArray(gwIngressTypes) && setGwIngressTypes(gwIngressTypes);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRhsiGwComputeProfiles = async () => {
    try {
      const computeProfiles = await getRhsiComputeProfile();
      Array.isArray(computeProfiles) &&
        setGwRhsiComputeProfiles(computeProfiles);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (open) {
      setLoading(true);
      toggleGatewayFailNotification(false);
      toggleUpdateFailNotification(false);
      toggleFailNotification(false);
      fetchData();
    }
  }, [open]);

  const fetchData = async () => {
    setLoadingData(true);
    await fetchNwSegmentsList(false, true);
    await fetchResourceGroups();
    const gateways = await fetchGatewayList();
    await fetchGwIngressTypes();
    await fetchRhsiGwComputeProfiles();
    await fetchGatewayandAgentList(gateways);
    setLoadingData(false);
    await fetchSecrets();
  };

  const fetchGatewayandAgentList = async (gatewaysData: any) => {
    await fetchExistingGatewayList(gatewaysData);
    await fetchAgentList();
  };

  const closeTearsheet = () => {
    toggleCreateNetworkSegment(false);
  };

  const refreshNetworkSegmentList = (nwSegmentResourceId = '') => {
    fetchNwSegmentsList(true, false, nwSegmentResourceId);
  };

  const isFormValid = () => {
    for (const field of requiredFields) {
      const value = formData[field].value;
      const trimmedValue = typeof value === 'string' ? value.trim() : value;
      if (checkFieldValidation(field, trimmedValue)) {
        return false;
      }
    }
    return true;
  };

  const cancelForm = () => {
    onClose();
    setFormData(defaultFormValue);
    toggleFailNotification(false);
    toggleGatewayFailNotification(false);
    toggleUpdateFailNotification(false);
    setAuthError(false);
  };

  const checkFieldValidation = (name: string, value: any) => {
    let errorMessage = '';
    switch (name) {
      case 'name':
        const valueEmpty =
          value === '' || value === null || value === undefined ? true : false;
        const filteredPartitionList =
          mode === 'ADD'
            ? partitionListData?.data?.filter(
                (partition: any) => partition.name === value
              ) ?? []
            : partitionListData?.data?.filter(
                (partition: any) =>
                  partition.name === value &&
                  partition.name !== partitionData?.name
              ) ?? [];
        const notUnique = filteredPartitionList?.length > 0 ? true : false;
        errorMessage = valueEmpty
          ? t('validation.name.required')
          : notUnique
          ? t('validation.name.alreadyExists')
          : '';
        break;
    }
    return errorMessage;
  };

  const handleOnChange = (name: string, value: any) => {
    if (!changed) setChanged(true);
    const errorMessage = checkFieldValidation(name, value);
    if (name === 'install_method')
      setFormData((prevState: any) => ({
        ...prevState,
        name: {
          value: '',
          error: false,
          errorMessage: '',
        },
        gatewayName: {
          value: '',
          error: false,
          errorMessage: '',
        },
        agent_node: {
          value: null,
          error: false,
          errorMessage: '',
        },
        gatewayId: {
          value: '',
          isDisconnected: false,
          error: false,
          errorMessage: '',
        },
        infrastructureGroup: {
          value: null,
          error: false,
          errorMessage: '',
        },
        auto_discover: {
          value: false,
          error: false,
          errorMessage: '',
        },
        gatewayDetailsData: null,
        install_method: value,
      }));
    if (name === 'networkSegment') {
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: {
          value,
          error: !!errorMessage,
          errorMessage,
        },
        infrastructureGroup: {
          value:
            resourceGroupsData?.find(
              (rg: ResourceGroup) =>
                rg?.resource_id === value?.resource_group_id
            ) ?? null,
          error: !!errorMessage,
          errorMessage,
        },
      }));
    } else if (name === 'managedStatus') {
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: {
          value,
          error: !!errorMessage,
          errorMessage,
        },
      }));

      // We want to turn off auto discovery when we unmanage that namespace
      if (value === false) {
        setFormData((prevState: any) => ({
          ...prevState,
          auto_discover: {
            value: false,
            error: false,
            errorMessage: '',
          },
        }));
      }
    } else
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: {
          value,
          error: !!errorMessage,
          errorMessage,
        },
      }));
  };

  const handleAxonNamespaceRegister = async () => {
    try {
      const partition_data = {
        name: removeExtraSpace(formData?.name?.value),
        labels: formData?.labels?.value,
        auto_discover: formData?.auto_discover?.value,
        unmanaged: !formData?.managedStatus?.value,
        is_discovered: false,
        network_segment_id: formData?.networkSegment?.value?.resource_id,
        // credentials_key: formData?.auto_discover?.value
        //   ? formData?.secretsName?.value.name
        //   : '',
      };

      const nameSpaceData = await registerNamespace(
        deploymentdata.cloud_id,
        deploymentdata.resource_id,
        partition_data
      );

      notification.onTrigger('TOAST', {
        title: t('successNotification.title'),
        subtitle: t('successNotification.subtitle', {
          deploymentEnvName: deploymentdata.name,
          nameSpaceName: nameSpaceData.name,
        }),
      });

      setChanged(false);
      creatPartition();
      setFormData(defaultFormValue);
      toggleFailNotification(false);
      setAuthError(false);

      inValidateListCacheFn(QueryKeys.CLOUD_NAMESPACES);
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        toggleFailNotification(true);
        setAuthError(true);
        // We added this project.reject to prevent the modal closing when error occured.
        // But in latest version of '@carbon/ibm-products' the below line is not required and is throwing error.
        // To prevent closing of modal we need to return promise rejection
        // return Promise.reject(() => console.error(err));
      }

      console.log(error);
      toggleFailNotification(true);
      // We added this project.reject to prevent the modal closing when error occured.
      // But in latest version of '@carbon/ibm-products' the below line is not required and is throwing error.
      // return Promise.reject(() => console.log(error));
    }
  };

  const createGateway = async () => {
    try {
      const namespaceDuplicateNameError = checkFieldValidation(
        'name',
        formData?.agent_node?.value?.namespace
      );
      const gateway_data = {
        name: removeExtraSpace(formData?.gatewayName?.value),
        subtype: 'RHSI-EDGE',
        type: 'edge',
        resource_group_id:
          formData?.infrastructureGroup?.value?.resource_id ||
          formData?.networkSegment?.value?.resource_group_id,
        open_horizon_node_id: formData?.agent_node?.value?.id,
        n_instances: 1,
        intended_compute_profile:
          formData?.gatewayDetailsData?.rhsi_compute_profile?.profile,
        site_configuration: JSON.stringify({
          ingress:
            formData?.gatewayDetailsData?.skupper_site_configuration
              ?.ingress_type?.value,
          ingress_host:
            formData?.gatewayDetailsData?.skupper_site_configuration
              ?.ingress_hostname?.value,
          router_ingress_host:
            formData?.gatewayDetailsData?.skupper_site_configuration
              ?.router_ingress_hostname?.value,
          controller_ingress_host:
            formData?.gatewayDetailsData?.skupper_site_configuration
              ?.controller_ingress_hostname?.value,
          ingress_annotations:
            formData?.gatewayDetailsData?.skupper_site_configuration
              ?.ingress_annotations?.value,
        }),
      };
      const gateway = await addGateway(gateway_data);
      setFormData((prevState: any) => ({
        ...prevState,
        gatewayId: {
          value: gateway?.resource_id,
          error: false,
          errorMessage: '',
          isDisconnected: false,
        },
        gatewayName: {
          value: removeExtraSpace(formData?.gateway_name),
          error: false,
          errorMessage: '',
        },
        name: {
          value: formData?.namespace_name,
          error: !!namespaceDuplicateNameError,
          errorMessage: namespaceDuplicateNameError,
        },
        agent_node: {
          value: formData?.agent_node,
          error: false,
          errorMessage: '',
        },
        managedStatus: {
          value: true,
          error: false,
          errorMessage: '',
        },
      }));
      notification.onTrigger('TOAST', {
        title: t('gatewaySuccessNotification.title'),
        subtitle: t('gatewaySuccessNotification.subtitle', {
          gatewayName: gateway.name,
        }),
      });
      return gateway;
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        notification.onTrigger('TOAST', {
          title: t('gatewayFailureNotification.authTitle') as string,
          kind: 'error',
          subtitle: t('gatewayFailureNotification.authSubtitle') as string,
        });
        toggleGatewayFailNotification(true);
        setGatewayAuthError(true);
        // We added this project.reject to prevent the modal closing when error occured.
        // But in latest version of '@carbon/ibm-products' the below line is not required and is throwing error.
        // To prevent closing of modal we need to return promise rejection
        // return Promise.reject(() => console.error(err));
      }

      console.log(error);
      notification.onTrigger('TOAST', {
        title: t('gatewayFailureNotification.title') as string,
        kind: 'error',
        subtitle: t('gatewayFailureNotification.subtitle') as string,
      });
      toggleGatewayFailNotification(true);
      return null;
    } finally {
      setCreateGatewayLoader(false);
    }
  };

  const handleRhsiNamespaceRegister = async () => {
    try {
      submitInProgress = true;
      let gateway = null;
      if (
        formData?.install_method !== 'existingGateway' &&
        formData?.gatewayName?.value
      )
        gateway = await createGateway();
      const partition_data = {
        name: removeExtraSpace(formData?.name?.value),
        labels: formData?.labels?.value,
        auto_discover: formData?.auto_discover?.value,
        unmanaged: !formData?.managedStatus?.value,
        is_discovered: false,
        network_segment_id: formData?.networkSegment?.value?.resource_id,
        gateway_id:
          formData?.install_method === 'existingGateway'
            ? formData?.gatewayId?.value
            : gateway?.resource_id,
        app_resource_group_id: formData?.appResourceGroup?.value?.resource_id,
      };

      const nameSpaceData = await registerNamespace(
        deploymentdata.cloud_id,
        deploymentdata.resource_id,
        partition_data
      );

      notification.onTrigger('TOAST', {
        title: t('successNotification.title'),
        subtitle: t('successNotification.subtitle', {
          deploymentEnvName: deploymentdata.name,
          nameSpaceName: nameSpaceData.name,
        }),
      });

      if (formData?.gatewayId?.value || gateway?.resource_id) {
        const updatedGatewayData = {
          name: removeExtraSpace(formData?.gatewayName?.value),
          subtype: 'RHSI-EDGE',
          type: 'edge',
          resource_group_id:
            formData?.infrastructureGroup?.value?.resource_id ||
            formData?.networkSegment?.value?.resource_group_id,
          open_horizon_node_id: formData?.agent_node?.value?.id,
          cloud_id: deploymentdata?.cloud_id,
          location_id: deploymentdata?.location_id,
          deployed_in_type: 'namespace',
          deployed_in_depl_env_id: deploymentdata?.resource_id,
          deployed_in_partition_id: nameSpaceData?.resource_id,
        };
        try {
          const gatewayVal = await updateGateway(
            gateway?.resource_id || formData?.gatewayId?.value,
            updatedGatewayData
          );
          notification.onTrigger('TOAST', {
            kind: 'info',
            customClassName: 'gatewayDeploySuccessToast',
            title: t('gatewayDeployingSuccess.title'),
            subtitle: t('gatewayDeployingSuccess.subtitle', {
              gatewayName: formData?.gatewayName?.value,
              nameSpaceName: formData?.name?.value,
            }),
          });
        } catch (e) {
          console.log('Gateway update failed');
        }
      }
      setChanged(false);
      creatPartition();
      setFormData(defaultFormValue);
      toggleFailNotification(false);
      setAuthError(false);
      submitInProgress = false;

      inValidateListCacheFn(QueryKeys.CLOUD_NAMESPACES);
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        toggleFailNotification(true);
        setAuthError(true);
        // We added this project.reject to prevent the modal closing when error occured.
        // But in latest version of '@carbon/ibm-products' the below line is not required and is throwing error.
        // To prevent closing of modal we need to return promise rejection
        // return Promise.reject(() => console.error(err));
      }

      console.log(error);
      toggleFailNotification(true);
      // We added this project.reject to prevent the modal closing when error occured.
      // But in latest version of '@carbon/ibm-products' the below line is not required and is throwing error.
      // return Promise.reject(() => console.log(error));
    } finally {
      setNoGatewayConnectedModal(false);
      resetStatus();
    }
  };

  const handleAxonNamespaceEdit = async () => {
    try {
      if (partitionData) {
        const { cloud_id, cluster_id, resource_id } = partitionData;
        const data = {
          ...partitionData,
          name: removeExtraSpace(formData?.name?.value),
          labels: formData?.labels?.value,
          auto_discover: formData?.auto_discover?.value,
          unmanaged: !formData?.managedStatus?.value,
          is_discovered: false,
          network_segment_id: formData?.networkSegment?.value?.resource_id,
        };
        const updatedPartitionData = await updatePartitionNamespace(
          cloud_id,
          cluster_id,
          resource_id,
          data
        );
        notification.onTrigger('TOAST', {
          title: t('updateNamespaceSuccessNotification.title'),
          subtitle: t('updateNamespaceSuccessNotification.subtitle', {
            deploymentEnvName: deploymentdata.name,
            nameSpaceName: updatedPartitionData.name,
          }),
        });

        setChanged(false);
        creatPartition();
        toggleUpdateFailNotification(false);
        setAuthError(false);

        inValidateListCacheFn(QueryKeys.CLOUD_NAMESPACES);
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        toggleUpdateFailNotification(true);
        setAuthError(true);
        // We added this project.reject to prevent the modal closing when error occured.
        // But in latest version of '@carbon/ibm-products' the below line is not required and is throwing error.
        // To prevent closing of modal we need to return promise rejection
        // return Promise.reject(() => console.error(err));
      }

      console.log(error);
      toggleUpdateFailNotification(true);
    } finally {
      submitInProgress = false;
    }
  };

  const handleRhsiNamespaceEdit = async () => {
    try {
      submitInProgress = true;
      let gateway;
      if (formData?.gatewayName?.value && !partitionData?.gateway_id) {
        if (formData?.install_method !== 'existingGateway')
          gateway = await createGateway();
      }
      if (partitionData) {
        const { cloud_id, cluster_id, resource_id } = partitionData;
        let data = {
          ...partitionData,
          name: removeExtraSpace(formData?.name?.value),
          labels: formData?.labels?.value,
          auto_discover: formData?.auto_discover?.value,
          unmanaged: !formData?.managedStatus?.value,
          is_discovered: false,
          network_segment_id: formData?.networkSegment?.value?.resource_id,
          gateway_id: gateway
            ? gateway?.resource_id
            : formData?.gatewayId?.value,
        };
        if (!formData?.gatewayId?.value && data['router_site_cor_id'])
          data['router_site_cor_id'] = '';
        const updatedPartitionData = await updateNamespaceData(
          cloud_id,
          cluster_id,
          resource_id,
          data
        );

        notification.onTrigger('TOAST', {
          title: t('updateNamespaceSuccessNotification.title'),
          subtitle: t('updateNamespaceSuccessNotification.subtitle', {
            deploymentEnvName: deploymentdata.name,
            nameSpaceName: updatedPartitionData.name,
          }),
        });
        if (formData?.gatewayName?.value && !partitionData?.gateway_id) {
          try {
            let updatedGatewayData = {
              name: formData?.gatewayName?.value || gatewayConnected?.name,
              subtype: 'RHSI-EDGE',
              type: 'edge',
              resource_group_id:
                formData?.infrastructureGroup?.value?.resource_id,
              open_horizon_node_id: formData?.agent_node?.value?.id,
              cloud_id: deploymentdata?.cloud_id,
              location_id: deploymentdata?.location_id,
              deployed_in_type: 'namespace',
              deployed_in_depl_env_id: deploymentdata?.resource_id,
              deployed_in_partition_id: partitionData?.resource_id,
            };
            if (formData?.gatewayId?.isDisconnected)
              updatedGatewayData = {
                ...updatedGatewayData,
                deployed_in_type: '',
                deployed_in_depl_env_id: '',
                deployed_in_partition_id: '',
              };
            await updateGateway(
              gateway ? gateway?.resource_id : formData?.gatewayId?.value,
              updatedGatewayData
            );
            notification.onTrigger('TOAST', {
              kind: 'info',
              customClassName: 'gatewayDeploySuccessToast',
              title: t('gatewayDeployingSuccess.title'),
              subtitle: t('gatewayDeployingSuccess.subtitle', {
                gatewayName:
                  formData?.gatewayName?.value || gatewayConnected?.name,
                nameSpaceName: updatedPartitionData.name,
              }),
            });
          } catch (e) {
            console.log('Gateway update failed');
          }
        }

        setChanged(false);
        creatPartition();
        toggleUpdateFailNotification(false);
        setAuthError(false);

        inValidateListCacheFn(QueryKeys.CLOUD_NAMESPACES);
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        toggleUpdateFailNotification(true);
        setAuthError(true);
        // We added this project.reject to prevent the modal closing when error occured.
        // But in latest version of '@carbon/ibm-products' the below line is not required and is throwing error.
        // To prevent closing of modal we need to return promise rejection
        // return Promise.reject(() => console.error(err));
      }

      console.log(error);
      toggleUpdateFailNotification(true);
    } finally {
      submitInProgress = false;
      toggleDisconnectGatewayModal(false);
      toggleGatewayContinueModal(false);
      resetStatus();
    }
  };

  const handleSubmit = async () => {
    if (mode === 'ADD') {
      if (
        formData?.networkSegment?.value?.compatibility_set ===
        NetworkSegmentCompatibilitySet.Axon
      ) {
        handleAxonNamespaceRegister();
      } else {
        if (!formData?.gatewayName?.value) setNoGatewayConnectedModal(true);
        else handleRhsiNamespaceRegister();
      }
    }
    if (mode === 'EDIT') {
      trackButtonClicked(
        analyticsData['Deployment Environment Details'].events.updateNamespace
          .props,
        analyticsData['Deployment Environment Details'].events.updateNamespace
          .event
      );
      if (
        formData?.networkSegment?.value?.compatibility_set ===
        NetworkSegmentCompatibilitySet.Axon
      ) {
        handleAxonNamespaceEdit();
      } else {
        if (
          formData?.gatewayId?.value !== partitionData?.gateway_id &&
          formData?.gatewayId?.isDisconnected
        ) {
          toggleDisconnectGatewayModal(true);
        } else handleRhsiNamespaceEdit();
      }
    }
  };

  const handleErrorBarClose = () => {
    toggleFailNotification(false);
    setAuthError(false);
  };

  const handleGatewayErrorBarClose = () => {
    toggleGatewayFailNotification(false);
    setGatewayAuthError(false);
  };

  const handleNamespaceUpdateErrorBarClose = () => {
    toggleUpdateFailNotification(false);
    setAuthError(false);
  };

  const disableSaveButton = () => {
    if (mode === 'EDIT' && partitionData?.unmanaged) {
      return false;
    }
    if (JSON.stringify(initialformData) !== JSON.stringify(formData)) {
      return !isFormValid();
    } else return true;
  };

  const openContinue = () => {
    toggleGatewayContinueModal(true);
  };

  const disConnectGateway = () => {
    setFormData((prevState: any) => ({
      ...prevState,
      gatewayName: {
        value: '',
        error: false,
        errorMessage: '',
      },
      gatewayId: {
        value: '',
        error: false,
        isDisconnected: true,
        errorMessage: '',
      },
      auto_discover: {
        value: false,
        error: false,
        errorMessage: '',
      },
    }));
  };

  const resetStatus = () => {
    setStatus('inactive');
    setDescription('Submitting...');
  };

  return (
    <div>
      <div>
        <MultiStepTearsheet
          className='register-namespace-tearsheet'
          title={mode === 'ADD' ? t('titleNamespace') : t('editNamespace')}
          description={''}
          submitButtonText={
            mode === 'ADD' ? t('submitButtonText') : t('saveChangesBtn')
          }
          cancelButtonText={t('cancelButtonText')}
          backButtonText={t('backButtonText')}
          nextButtonText={t('nextButtonText')}
          open={open}
          onClose={() => {
            cancelForm();
            setChanged(false);
            setAuthError(false);
          }}
          onRequestSubmit={
            mode === 'ADD' &&
            !formData?.gatewayName?.value &&
            formData?.networkSegment?.value?.compatibility_set ===
              NetworkSegmentCompatibilitySet.ServiceInterconnect
              ? () =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      // reject the promise to keep the tearsheet in open state
                      reject();
                      submitInProgress = true;
                      setNoGatewayConnectedModal(true);
                    }, simulatedDelay);
                  })
              : mode === 'EDIT' &&
                formData?.gatewayId?.value !== partitionData?.gateway_id &&
                formData?.gatewayId?.isDisconnected &&
                formData?.networkSegment?.value?.compatibility_set ===
                  NetworkSegmentCompatibilitySet.ServiceInterconnect
              ? () =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      // reject the promise to keep the tearsheet in open state
                      reject();
                      submitInProgress = true;
                      toggleDisconnectGatewayModal(true);
                    }, simulatedDelay);
                  })
              : mode === 'EDIT' &&
                appsDeployed &&
                appsDeployed?.length > 0 &&
                !partitionData?.gateway_id &&
                formData?.gatewayName?.value &&
                formData?.networkSegment?.value?.compatibility_set ===
                  NetworkSegmentCompatibilitySet.ServiceInterconnect
              ? () =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      // reject the promise to keep the tearsheet in open state
                      reject();
                      submitInProgress = true;
                      toggleGatewayContinueModal(true);
                    }, simulatedDelay);
                  })
              : () =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      // reject the promise to keep the tearsheet in open state
                      reject();
                      submitInProgress = true;
                      handleSubmit();
                    }, simulatedDelay);
                  })
          }
          initialStep={1}
          isSubmitting={submitInProgress}
        >
          <DeployGateway
            formData={formData}
            onChange={handleOnChange}
            secrets={secrets}
            partitionType={partitonType}
            deploymentdata={deploymentdata}
            networkSegments={networkSegments}
            openCreateSegment={() => toggleCreateNetworkSegment(true)}
            disConnectGateway={() => disConnectGateway()}
            existingCompactibilitySet={existingCompactibilitySet}
            appsDeployed={appsDeployed}
            mode={mode}
            partitionData={partitionData}
            gatewayConnectedRemoteConnections={
              gatewayConnectedRemoteConnections
            }
            agentList={agentList}
            gatewayList={gatewayList}
            namespaceSelected={(data: any) => setGatewayDetails(data)}
            resourceGroups={resourceGroupsData}
            handleChange={handleOnChange}
            createGatewayLoader={createGatewayLoader}
            namespaceList={partitionListData?.data}
            gatewayNames={gatewayNames}
            gwIngressTypes={gwIngressTypes}
            gwRhsiComputeProfiles={gwRhsiComputeProfiles}
            agentError={agentError}
            nwSegmentsDataLoading={nwSegmentsDataLoading}
            dataLoading={loadingData}
          />
          <NamespaceDetailsForm
            id={registerNamespaceId}
            formData={formData}
            onChange={handleOnChange}
            secrets={secrets}
            partitionType={partitonType}
            deploymentdata={deploymentdata}
            existingCompactibilitySet={existingCompactibilitySet}
            appsDeployed={appsDeployed}
            mode={mode}
            partitionData={partitionData}
            gatewayConnectedRemoteConnections={
              gatewayConnectedRemoteConnections
            }
            hasConnectedPolicy={hasConnectedPolicy}
            appResourceGroups={appResourceGroups}
            isSubmitDisabled={
              mode === 'ADD'
                ? !isFormValid() || nwSegmentsDataLoading
                : disableSaveButton()
            }
            showFailNotification={showFailNotification}
            gatewayFailNotification={gatewayFailNotification}
            showUpdateFailNotification={showUpdateFailNotification}
            handleErrorBarClose={handleErrorBarClose}
            handleGatewayErrorBarClose={handleGatewayErrorBarClose}
            handleNamespaceUpdateErrorBarClose={
              handleNamespaceUpdateErrorBarClose
            }
            authError={authError}
            gatewayAuthError={gatewayAuthError}
            allDepEnvSubTypes={allDepEnvSubTypes ?? []}
            isSubmitting={submitInProgress}
          />
          {openCreateNetworkSegment && (
            <CreateNetworkSegment
              open={openCreateNetworkSegment}
              onClose={closeTearsheet}
              networkSegments={networkSegments}
              groups={resourceGroupsData}
              fromPage='registerNamespace'
              refreshData={(nwSegmentResourceId?: string) =>
                refreshNetworkSegmentList(nwSegmentResourceId)
              }
              className='create-network-segment'
              actionType='ADD'
            />
          )}

          <Modal
            className='no-gateway-modal'
            modalHeading={t('noGatewayConnected.header')}
            onRequestClose={() => {
              setNoGatewayConnectedModal(false);
            }}
            onRequestSubmit={() => {
              setStatus('active');
              // setNoGatewayConnectedModal(false);
              handleRhsiNamespaceRegister();
            }}
            primaryButtonText={t('noGatewayConnected.continue')}
            secondaryButtonText={t('noGatewayConnected.cancel')}
            open={openNoGatewayConnectedModal}
            size='sm'
            loadingStatus={status}
            loadingDescription={description}
          >
            {t('noGatewayConnected.body')}
          </Modal>
          <Modal
            className='disconnect-gateway-modal'
            modalHeading={t('disConnectGateway.header')}
            onRequestClose={() => toggleDisconnectGatewayModal(false)}
            onRequestSubmit={() => {
              setStatus('active');
              handleRhsiNamespaceEdit();
            }}
            primaryButtonText={t('disConnectGateway.continue')}
            secondaryButtonText={t('disConnectGateway.cancel')}
            open={openDisconnectGatewayModal}
            size='sm'
            loadingStatus={status}
            loadingDescription={description}
          >
            {t('disConnectGateway.body')}
          </Modal>
          <Modal
            className='continue-gateway-modal'
            danger
            modalHeading={t('form.connectedGateway.header')}
            onRequestClose={() => toggleGatewayContinueModal(false)}
            onRequestSubmit={() => {
              setStatus('active');
              handleRhsiNamespaceEdit();
            }}
            primaryButtonText={t('form.connectedGateway.continue')}
            secondaryButtonText={t('form.connectedGateway.cancel')}
            open={openGatewayContinueModal}
            size='sm'
            loadingStatus={status}
            loadingDescription={description}
          >
            {t('form.connectedGateway.body')}
          </Modal>
        </MultiStepTearsheet>
      </div>
    </div>
  );
};

export default RegisterNamespace;
