import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddLabels from '../../../components/AddLabels/AddLabels';
import {
  Row,
  Column,
  TextInput,
  Button,
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  TableProps,
  ComboBox,
} from 'carbon-components-react';
import { Add16, TrashCan16 } from '@carbon/icons-react';

import { Port } from '../../../models/master';
import {
  DataTableHeader,
  DataTableRow,
  DataTableCell,
} from '../../../models/dataTable';

import './ServiceForm.scss';

interface PortForm {
  port_number: {
    value: string;
    error: boolean;
    errorMessage: string;
  };
  protocol: {
    value: {
      label: string;
      value: string;
    };
    error: boolean;
    errorMessage: string;
  };
}

export interface PortData {
  port_number: string;
  protocol: string;
}

export interface FormData {
  name: {
    value: string;
    error: boolean;
    errorMessage: string;
  };
  ports: PortData[];
  labels: {
    value: string[] | null;
    error: boolean;
    errorMessage: string;
  };
}

interface Props {
  open: boolean;
  formData: FormData;
  onChange: (name: string, value: any) => void;
  onAddPort: (data: PortData) => void;
  onRemovePort: (index: number, data: PortData) => void;
  allPortNumbers: Port[];
}

const defaultFormValue = {
  port_number: {
    value: '',
    error: false,
    errorMessage: '',
  },

  protocol: {
    value: {
      label: '',
      value: '',
    },
    error: false,
    errorMessage: '',
  },
};

const requiredFields = ['port_number', 'protocol'];

const AddServices: React.FC<Props> = ({
  open,
  formData,
  onChange,
  onAddPort,
  onRemovePort,
  allPortNumbers,
}) => {
  const [portForm, setPortForm] = useState<PortForm>(defaultFormValue);
  const [allPorts, setAllPorts] = useState<Port[]>(allPortNumbers);
  const { name, ports, labels } = formData;
  const { t } = useTranslation('serviceForm');

  useEffect(() => {
    // To reset the add port form if user click cancel button.
    setPortForm(defaultFormValue);
  }, [open]);

  const headers = [
    {
      header: t('table.port'),
      key: 'port_number',
    },
    {
      header: t('table.protocol'),
      key: 'protocol',
    },
    {
      header: '',
      key: 'remove',
    },
  ];

  const protocolList = [
    {
      label: t('tcp'),
      value: 'TCP',
    },
    {
      label: t('udp'),
      value: 'UDP',
    },
  ];

  const checkFieldValidation = (
    name: string,
    value: any,
    duplicateValidation?: boolean
  ) => {
    let errorMessage = '';
    switch (name) {
      case 'port_number':
        errorMessage = !value
          ? t('validation.port')
          : duplicateValidation
          ? t('validation.existingPort')
          : value < 1 || value > 65535
          ? t('validation.invalidPortRegex')
          : '';
        break;
      case 'protocol':
        errorMessage = !value ? t('validation.protocol') : '';
        break;
    }
    return errorMessage;
  };

  const isFormValid = () => {
    for (const field of requiredFields) {
      const value = (portForm as any)[field].value;
      const trimmedValue = typeof value === 'string' ? value.trim() : value;
      if (checkFieldValidation(field, trimmedValue)) {
        return false;
      }
    }
    if (portForm?.port_number?.value && portForm?.protocol?.value?.value) {
      return true;
    } else {
      return false;
    }
  };

  const handleOnChange = (
    name: string,
    value: any,
    duplicateValidation?: boolean
  ) => {
    const errorMessage = checkFieldValidation(name, value, duplicateValidation);

    setPortForm((prevState: any) => ({
      ...prevState,
      [name]: {
        value,
        error: !!errorMessage,
        errorMessage,
      },
    }));
  };

  const handleSubmit = () => {
    const checkDuplicatePorts = allPorts.find(
      port =>
        port.port_number === portForm.port_number.value &&
        port.protocol === portForm.protocol.value.value
    );
    let invalid = false;
    if (checkDuplicatePorts) {
      invalid = true;
    }

    handleOnChange('port_number', portForm.port_number.value, invalid);
    if (!invalid) {
      onAddPort({
        port_number: portForm.port_number.value,
        protocol: portForm.protocol.value.value,
      });
      setAllPorts([
        ...allPorts,
        {
          port_number: portForm.port_number.value,
          protocol: portForm.protocol.value.value,
        },
      ]);
      setPortForm(defaultFormValue);
    }
  };

  const handleRemovePort = (index: number, port: PortData) => {
    const removePort = allPorts.filter(portVal => {
      return (
        portVal.port_number !== port.port_number ||
        portVal.protocol !== port.protocol
      );
    });
    setAllPorts(removePort);
    onRemovePort(index, port);
  };

  const getTableRows = () => {
    return ports.map((port, index) => ({
      ...port,
      protocol: t(port.protocol),
      id: '' + index,
      remove: (
        <div className='remove-button-container'>
          <Button
            kind='ghost'
            size='small'
            onClick={() => handleRemovePort(index, port)}
            iconDescription=' '
            renderIcon={TrashCan16}
          />
        </div>
      ),
    }));
  };

  return (
    <div className='service-form'>
      <Row>
        <Column>
          <TextInput
            data-modal-primary-focus
            id='serviceName'
            value={name.value}
            name='name'
            autoComplete='off'
            onChange={e => onChange('name', e.target.value)}
            labelText={t('labels.name') as string}
            placeholder={t('placeholder.name')}
            invalid={name.error}
            invalidText={name.errorMessage}
            maxLength={60}
          />
        </Column>
      </Row>
      <Row className='port-form'>
        <Column md={2}>
          <TextInput
            id='servicePort'
            name='port'
            type='number'
            autoComplete='off'
            onChange={e => handleOnChange('port_number', e.target.value)}
            onKeyDown={evt =>
              (evt.key === 'e' || evt.key === 'E') && evt.preventDefault()
            }
            value={portForm.port_number.value}
            labelText={t('labels.port') as string}
            placeholder={t('placeholder.port')}
            invalid={portForm.port_number.error}
            invalidText={portForm.port_number.errorMessage}
            max={70000}
          />
        </Column>
        <Column md={4}>
          <ComboBox
            id='serviceProtocol'
            name='protocol'
            className='protocol'
            selectedItem={portForm.protocol?.value as any}
            onChange={data => {
              handleOnChange('protocol', data?.selectedItem);
            }}
            items={protocolList ?? []}
            itemToString={item => (item ? item.label : '')}
            translateWithId={t}
            titleText={t('labels.protocol')}
            placeholder={t('placeholder.protocol')}
            invalid={portForm.protocol.error}
            invalidText={portForm.protocol.errorMessage}
          />
        </Column>
        <Column md={2} className='button'>
          <Button
            kind='secondary'
            size='md'
            disabled={!isFormValid()}
            renderIcon={Add16}
            onClick={handleSubmit}
          >
            {t('addPortBtn')}
          </Button>
        </Column>
      </Row>

      {Array.isArray(ports) && ports.length > 0 && (
        <Row className='port-table-container'>
          <DataTable headers={headers} rows={getTableRows()}>
            {({
              rows,
              headers,
              getTableProps,
              getHeaderProps,
              getRowProps,
            }: {
              rows: DataTableRow[];
              headers: DataTableHeader[];
              getTableProps: () => TableProps;
              getHeaderProps: any;
              getRowProps: any;
            }) => (
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header: DataTableHeader) => (
                      <TableHeader
                        key={header.key}
                        {...getHeaderProps({ header })}
                      >
                        {header.header}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row: DataTableRow) => (
                    <TableRow key={row.id} {...getRowProps({ row })}>
                      {row.cells.map((cell: DataTableCell) => (
                        <TableCell key={cell.id}>{cell.value}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </DataTable>
        </Row>
      )}

      <Row>
        <Column>
          <AddLabels
            id='serviceLabels'
            wraperClass='service-form-add-labels'
            labelText={t('labels.labels')}
            placeholder={t('placeholder.labels')}
            onChange={data => onChange('labels', data)}
            btnText={t('addLabelBtn')}
            btnKind='secondary'
            tagType='green'
            defaultValues={labels.value}
          />
        </Column>
      </Row>
    </div>
  );
};

export default AddServices;
