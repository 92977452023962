import React, { useEffect, useRef } from 'react';
import { Layers16 } from '@carbon/icons-react';
import { Checkbox, RadioButton } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import images from '../../../images/images';

import './Options.scss';
import useAnalytics from '../../../lib/useAnalytics';
import analyticsData from '../../../lib/analyticsEventData';

export interface SelectedLayers {
  applicationConnections: boolean;
  policy: boolean;
  unmanaged: boolean;
  gateways: boolean;
  gatewayConnections: boolean;
}

export type SupportedOptions = 'layers' | 'expandTo';

interface Props {
  selectedOption: SupportedOptions | null;
  onToggleOptionSelection: (value: SupportedOptions | null) => void;
  selectedLayers: SelectedLayers;
  onToggleLayerSelection: (type: keyof SelectedLayers, value: boolean) => void;
  selectedExpansionLevel: number;
  onToggleNodeSelction: (level: number) => void;
}

const Options: React.FC<Props> = ({
  selectedOption,
  onToggleOptionSelection,
  selectedLayers,
  onToggleLayerSelection,
  selectedExpansionLevel,
  onToggleNodeSelction,
}) => {
  const { t } = useTranslation('topologyCanvasView');
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { trackButtonClicked } = useAnalytics();

  useEffect(() => {
    // Note this method cannot detect click inside canvas svg.
    // So we handled that click outside this component.
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current?.contains(event?.target as Node)
      ) {
        onToggleOptionSelection(null);
      }
    }

    window.document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={wrapperRef} className='option-container'>
      <div className={'left-panel'}>
        <div
          className={`layers-icon${
            selectedOption === 'layers' ? '-show-shadow' : ''
          }`}
          title={t('options.layers.layers')}
          onClick={() => {
            trackButtonClicked(
              analyticsData['Topology'].events.viewFilter.props,
              analyticsData['Topology'].events.viewFilter.event
            );
            onToggleOptionSelection(
              selectedOption === 'layers' ? null : 'layers'
            );
          }}
        >
          <Layers16 />
        </div>
        <div
          className={`expandTo-icon${
            selectedOption === 'expandTo' ? '-show-shadow' : ''
          }`}
          title={t('options.expandTo.title')}
          onClick={() => {
            trackButtonClicked(
              analyticsData['Topology'].events.expandTo.props,
              analyticsData['Topology'].events.expandTo.event
            );
            onToggleOptionSelection(
              selectedOption === 'expandTo' ? null : 'expandTo'
            );
          }}
        >
          {images.expandToIcon()}
        </div>
      </div>
      {selectedOption && (
        <div className={`stroke-hider-${selectedOption}`}></div>
      )}
      <div
        className='right-panel layers'
        style={
          selectedOption === 'layers'
            ? { display: 'block' }
            : { display: 'none' }
        }
      >
        {selectedOption === 'layers' && (
          <div className='layers-panel'>
            <div className='header-label'>
              {t('options.layers.applicationView')}
            </div>
            <div className='checkboxes'>
              <Checkbox
                id={'application-connections'}
                className={'layers-checkbox'}
                labelText={t('options.layers.applicationConnections')}
                checked={selectedLayers['applicationConnections']}
                onChange={(value: boolean) =>
                  onToggleLayerSelection('applicationConnections', value)
                }
              />
              <Checkbox
                id={'policy-connections'}
                className={'layers-checkbox'}
                labelText={t('options.layers.policyConnections')}
                checked={selectedLayers['policy']}
                onChange={(value: boolean) =>
                  onToggleLayerSelection('policy', value)
                }
              />
            </div>
            <div className='header-label-infra'>
              {t('options.layers.infrastructureView')}
            </div>
            <div className='checkboxes'>
              <Checkbox
                id={'gateways'}
                className={'layers-checkbox'}
                labelText={t('options.layers.gateways')}
                checked={selectedLayers['gateways']}
                onChange={(value: boolean) => {
                  onToggleLayerSelection('gateways', value);
                }}
              ></Checkbox>
              <div className='gateway-conn'>
                <Checkbox
                  id={'gateway-connections'}
                  className={'layers-checkbox-gwConn'}
                  labelText={t('options.layers.gatewayConnections')}
                  checked={selectedLayers['gatewayConnections']}
                  onChange={(value: boolean) => {
                    onToggleLayerSelection('gatewayConnections', value);
                  }}
                />
              </div>
              <Checkbox
                id={'unmanaged-resources'}
                className={'layers-checkbox'}
                labelText={t('options.layers.unmanagedResources')}
                checked={selectedLayers['unmanaged']}
                onChange={(value: boolean) =>
                  onToggleLayerSelection('unmanaged', value)
                }
              />
            </div>
          </div>
        )}
      </div>

      <div
        className='right-panel expand-to'
        style={
          selectedOption === 'expandTo'
            ? { display: 'block' }
            : { display: 'none' }
        }
      >
        {selectedOption === 'expandTo' && (
          <div className='expand-to-panel'>
            <div className='header-label'>{t('options.expandTo.title')}</div>
            <div className='radiobuttons'>
              <RadioButton
                id={'location'}
                labelText={t('options.expandTo.locations')}
                checked={selectedExpansionLevel >= 1}
                onClick={() => {
                  onToggleNodeSelction(1);
                }}
              />
              <RadioButton
                id={'deploymentEnvs'}
                labelText={t('options.expandTo.deploymentEnvs')}
                checked={selectedExpansionLevel >= 2}
                onClick={() => {
                  onToggleNodeSelction(2);
                }}
              />
              <RadioButton
                id={'partitions'}
                labelText={t('options.expandTo.partitions')}
                checked={selectedExpansionLevel >= 3}
                onClick={() => onToggleNodeSelction(3)}
              />
              <RadioButton
                id={'applications'}
                labelText={t('options.expandTo.applications')}
                checked={selectedExpansionLevel >= 4}
                onClick={() => onToggleNodeSelction(4)}
              />
              <RadioButton
                id={'services'}
                labelText={t('options.expandTo.services')}
                checked={selectedExpansionLevel >= 5}
                onClick={() => onToggleNodeSelction(5)}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Options;
