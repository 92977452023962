import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

import {
  addApplication,
  updateApplication,
} from '../../../controllers/applicationApis.js';
import { getResourceGroups } from '../../../controllers/resourceGroupApi.js';
import { getNetworkSegments } from '../../../controllers/networksegmentsApi.js';

import WideTearsheet from '../../../components/WideTearsheet/WideTearsheet';
import AddApplicationForm from './AddApplicationForm';
import InlineNotification from '../../../components/Notifications/Inline/Notification';
import { NotificationContext } from '../../../components/Notifications/Context/NotificationProvider';
import { removeExtraSpace } from '../../../lib/utils';
import {
  nameRegexPattern,
  alphaNumbericNoSpaceRegexPattern,
} from '../../../lib/regex.js';
import { ApplicationData, NetworkSegment } from '../../../models/master';
import useAnalytics from '../../../lib/useAnalytics';

import './AddApplication.scss';
import analyticsData from '../../../lib/analyticsEventData.js';
import {
  DEFAULT_NETWORK_SEGMENT_ID,
  defaultNetworkSegmentObject,
} from '../../../lib/constants';

const defaultFormValue = {
  name: {
    value: '',
    error: false,
    errorMessage: '',
  },
  resourceGroup: {
    value: {
      resource_id: 'default-app',
      name: 'Default_Application_Group',
    },
    error: false,
    errorMessage: '',
  },
  appIdentity: {
    value: '',
    error: false,
    errorMessage: '',
  },
  labels: {
    value: [],
    error: false,
    errorMessage: '',
  },
  description: {
    value: '',
    error: false,
    errorMessage: '',
  },
  networkSegment: {
    value: null,
    error: false,
    errorMessage: '',
  },
};

const requiredFields = [
  'name',
  'resourceGroup',
  'appIdentity',
  'networkSegment',
];

interface Props {
  open: boolean;
  onClose: () => void;
  createdApp: () => void;
  mode?: 'ADD' | 'EDIT';
  applicationData?: ApplicationData | null;
  updateAppData?: (data: ApplicationData) => void;
  applicationList?: ApplicationData[] | null;
  applicationDepEnvsCount?: number;
  networkSegmentList?: NetworkSegment[] | null;
}

const AddApplication: React.FC<Props> = ({
  open,
  onClose,
  createdApp,
  mode,
  applicationData,
  updateAppData,
  applicationList = [],
  applicationDepEnvsCount,
  networkSegmentList = null,
}) => {
  const [formData, setFormData] = useState<any>(defaultFormValue);
  const [resourceGroupList, setResourceGroupList] = useState<any>([]);
  const [showFailNotification, toggleFailNotification] = useState(false);
  const [changed, setChanged] = useState(false);
  const { t } = useTranslation('addApplication');
  const navigate = useNavigate();
  const notification = useContext(NotificationContext);
  const [authError, setAuthError] = useState(false);
  const [subTitleErrorMsg, setSubTitleErrorMsg] = useState('');
  const [networkSegments, setNetworkSegments] = useState<
    NetworkSegment[] | null
  >(networkSegmentList);

  const { trackButtonClicked } = useAnalytics();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getResourceGroups().then(response => {
      let getAppResourceGroup = response.resource_groups?.filter(
        (resourceGroup: any) => resourceGroup?.type === 'application'
      );
      setResourceGroupList(getAppResourceGroup);
    });

    if (mode === 'ADD') {
      fetchNwSegmentsList();
    }
  }, []);

  useEffect(() => {
    if (mode == 'EDIT') {
      checkValueChanged();
    }
  }, [formData]);

  useEffect(() => {
    if (mode === 'EDIT' && Array.isArray(networkSegmentList)) {
      setNetworkSegments(networkSegmentList);
    }
  }, [networkSegmentList]);

  const fetchNwSegmentsList = async () => {
    try {
      const response = await getNetworkSegments();
      setNetworkSegments(response);
    } catch (error) {
      console.error(error);
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        console.error(error);
      }
    }
  };

  const isFormValid = () => {
    for (const field of requiredFields) {
      const value = formData[field]?.value;
      if (checkFieldValidation(field, value)) {
        return false;
      }
    }
    return true;
  };

  const setEditFormData = () => {
    if (mode === 'EDIT' && applicationData) {
      const resourceGroupSelected = resourceGroupList?.find(
        (rg: any) => rg?.resource_id === applicationData?.resource_group_id
      );
      const networkSegmentSelected =
        applicationData?.network_segment_id === DEFAULT_NETWORK_SEGMENT_ID
          ? defaultNetworkSegmentObject
          : networkSegments?.find(
              (nwSegment: NetworkSegment) =>
                nwSegment?.resource_id ===
                (applicationData?.network_segment_id ?? '')
            );

      const updatedFormData = {
        name: {
          ...formData.name,
          value: applicationData?.name,
        },
        resourceGroup: {
          ...formData.resourceGroup,
          value: resourceGroupSelected
            ? resourceGroupSelected
            : resourceGroupList?.find(
                (rg: any) => rg?.resource_id === 'default-app'
              ),
        },
        networkSegment: {
          ...formData.networkSegment,
          value: networkSegmentSelected ? networkSegmentSelected : '',
        },
        appIdentity: {
          ...formData.appIdentity,
          value: applicationData?.app_identity,
        },
        labels: {
          ...formData.labels,
          value: applicationData?.labels,
        },
        description: {
          ...formData.description,
          value: applicationData?.description,
        },
      };

      setFormData(updatedFormData);
    }
  };

  useEffect(() => {
    setEditFormData();
  }, [mode, applicationData, resourceGroupList]);

  const cancelForm = () => {
    setFormData(defaultFormValue);
    onClose();
  };

  const checkFieldValidation = (
    name: string,
    value: any,
    networkSegment?: any
  ) => {
    let errorMessage = '';
    const networkSegmentVal = networkSegment
      ? networkSegment
      : formData?.networkSegment?.value;
    switch (name) {
      case 'name':
        const valueEmpty =
          value === '' || value === null || value === undefined ? true : false;
        let filteredAppList;
        if (mode === 'EDIT') {
          filteredAppList =
            applicationList?.filter(
              (app: ApplicationData) =>
                app.name !== applicationData?.name &&
                app?.network_segment_id === networkSegmentVal?.resource_id &&
                app.name === value
            ) ?? [];
        } else {
          filteredAppList =
            applicationList?.filter(
              (app: ApplicationData) =>
                app?.network_segment_id === networkSegmentVal?.resource_id &&
                app.name === value
            ) ?? [];
        }
        const notUnique = filteredAppList?.length > 0 ? true : false;
        errorMessage = valueEmpty
          ? t('validation.name.required')
          : notUnique
          ? t('validation.name.alreadyExists')
          : '';
        break;
      case 'resourceGroup':
        errorMessage = !value ? t('validation.resourceGroup.required') : '';
        break;
      case 'networkSegment':
        errorMessage = !value ? t('validation.networkSegment.required') : '';
        break;
      case 'appIdentity':
        if (networkSegmentVal?.compatibility_set !== 'RHSI') {
          const pattern = nameRegexPattern();
          errorMessage = !value
            ? t('validation.appIdentity.required')
            : pattern.test(value)
            ? ''
            : t('validation.appIdentity.regexFail');
        } else {
          if (value) {
            const pattern = alphaNumbericNoSpaceRegexPattern();
            errorMessage = pattern.test(value)
              ? ''
              : t('validation.appIdentity.rshiRegexFail');
          }
        }
        break;
    }
    return errorMessage;
  };

  const handleOnChange = (name: string, value: any) => {
    if (mode === 'ADD' && !changed) {
      setChanged(true);
    }

    const errorMessage = checkFieldValidation(name, value);

    if (name === 'networkSegment') {
      const nameError = checkFieldValidation(
        'name',
        formData.name.value,
        value
      );
      const appIdentityError = checkFieldValidation(
        'appIdentity',
        formData?.appIdentity?.value?.trim(),
        value
      );

      setFormData((prevState: any) => ({
        ...prevState,
        [name]: {
          value,
          error: !!errorMessage,
          errorMessage,
        },
        name: {
          value: formData.name.value,
          error: !!nameError,
          errorMessage: nameError,
        },
        appIdentity: {
          value: formData.appIdentity.value,
          error: !!appIdentityError,
          errorMessage: appIdentityError,
        },
      }));
    } else
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: {
          value,
          error: !!errorMessage,
          errorMessage,
        },
      }));
  };

  const checkValueChanged = () => {
    if (
      applicationData?.name === formData?.name.value &&
      applicationData?.app_identity === formData?.appIdentity.value &&
      applicationData?.resource_group_id ===
        formData?.resourceGroup?.value?.resource_id &&
      applicationData?.network_segment_id ===
        formData?.networkSegment?.value?.resource_id &&
      applicationData?.description === formData?.description.value &&
      applicationData?.labels === formData?.labels.value
    ) {
      setChanged(false);
    } else {
      setChanged(true);
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const app_data = {
        name: removeExtraSpace(formData?.name?.value),
        description: formData?.description?.value,
        app_identity: formData?.appIdentity?.value?.trim(),
        labels: formData?.labels?.value,
        resource_group_id: formData?.resourceGroup?.value?.resource_id,
        network_segment_id: formData?.networkSegment?.value?.resource_id,
      };
      if (mode === 'ADD') {
        trackButtonClicked(
          analyticsData['Applications'].events.registerApp.props,
          analyticsData['Applications'].events.registerApp.event
        );
        const application = await addApplication(app_data);
        notification.onTrigger('ACTION', {
          title: t('successNotification.title'),
          subtitle: t('successNotification.subtitle'),
          action: t('successNotification.button'),
          onActionClick: () => {
            navigate(`/applicationDetails?appId=${application?.resource_id}`);
          },
        });
      } else {
        const application: ApplicationData = await updateApplication(
          applicationData?.resource_id,
          app_data
        );

        if (updateAppData) {
          updateAppData(application);
        }
        notification.onTrigger('TOAST', {
          title: t('successEditNotification.title'),
          subtitle: t('successEditNotification.subtitle'),
        });
      }
      setChanged(false);

      // close modal and fetch new data in parent
      // TODO: navigate to card view when functionality is added
      createdApp();
    } catch (error: any) {
      const err = error as AxiosError;
      const errorMessage: string =
        error.response !== undefined
          ? error.response['customErrorMessage']
          : '';
      if (err.response?.status === 403) {
        toggleFailNotification(true);
        setAuthError(true);

        // We added this project.reject to prevent the modal closing when error occured.
        // But in latest version of '@carbon/ibm-products' the below line is not required and is throwing error.
        // return Promise.reject(() => console.log(error));
      }

      errorMessage.length > 0 && setSubTitleErrorMsg(errorMessage);
      console.log(error);
      toggleFailNotification(true);
      // We added this project.reject to prevent the modal closing when error occured.
      // But in latest version of '@carbon/ibm-products' the below line is not required and is throwing error.
      // return Promise.reject(() => console.log(error));
    } finally {
      setLoading(false);
    }
  };

  const handleErrorBarClose = () => {
    toggleFailNotification(false);
    setAuthError(false);
  };

  return (
    <WideTearsheet
      className='add-application-tearsheet'
      title={mode === 'ADD' ? t('title') : t('editFormTitle')}
      description={mode === 'ADD' ? t('description') : t('editDescription')}
      open={open}
      actions={[
        {
          kind: 'primary',
          label:
            mode === 'ADD' ? t('submitButtonText') : t('submitEditButtonText'),
          onClick: () => handleSubmit(),
          disabled: !isFormValid() || !changed,
          loading: loading,
        },
        {
          kind: 'secondary',
          label: t('cancelButtonText'),
          onClick: () => {
            cancelForm();
            toggleFailNotification(false);
            setChanged(false);
            setAuthError(false);
          },
        },
      ]}
    >
      {showFailNotification && (
        <InlineNotification
          onClose={() => handleErrorBarClose() as any}
          kind={'error'}
          title={
            authError
              ? (t('failureNotification.authTitle') as string)
              : mode === 'ADD'
              ? (t('failureNotification.title') as string)
              : (t('failureEditNotification.title') as string)
          }
          subtitle={
            authError
              ? (t('failureNotification.authSubtitle') as string)
              : subTitleErrorMsg.length > 0
              ? subTitleErrorMsg
              : (t('failureEditNotification.subtitle') as string)
          }
        />
      )}
      <AddApplicationForm
        formData={formData}
        onChange={handleOnChange}
        resourceGroupList={resourceGroupList}
        networkSegments={networkSegments}
        applicationDepEnvsCount={applicationDepEnvsCount}
        mode={mode}
      />
    </WideTearsheet>
  );
};

export default AddApplication;
