import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Search16 } from '@carbon/icons-react';
import { Search } from 'carbon-components-react';

import images from '../../images/images';
import Overflow from '../../components/Overflow/Overflow';
import useOutsideAlerter from '../../hooks/useOutsideAlerter';
import IconWithToolTip from '../../components/IconWithToolTip/IconWithToolTip';
import { VerticalEmptyState } from '../../components/EmptyState/EmptyState';

import {
  DeploymentEnvironment,
  Location,
  Namespace,
} from '../../models/master';

import './CloudSearch.scss';

interface FilteredDeplEnv {
  location: Location;
  deplEnv: DeploymentEnvironment;
}

interface FilteredNamespace {
  location: Location;
  cluster: DeploymentEnvironment;
  namespace: Namespace;
}

interface locationIdMap {
  [key: string]: Location;
}

interface ClusterIdMap {
  [key: string]: DeploymentEnvironment;
}

interface Props {
  locations: Location[];
  deploymentEnvs: DeploymentEnvironment[] | null;
  getDeplEnvSubTypeName: (deploymentEnv: DeploymentEnvironment) => string;
  namespaces: Namespace[] | undefined;
  onLocationSearch: (data: Location) => void;
  onDeplEnvSearch: (location: Location, deplEnv: DeploymentEnvironment) => void;
  onNamespaceSearch: (
    location: Location,
    cluster: DeploymentEnvironment,
    namespace: Namespace
  ) => void;
}

const CloudSearch = ({
  locations,
  deploymentEnvs,
  getDeplEnvSubTypeName,
  namespaces,
  onLocationSearch,
  onDeplEnvSearch,
  onNamespaceSearch,
}: Props) => {
  const { t } = useTranslation('cloudDetails');

  const searchContainerRef = useRef(null);

  const [searchInput, setSearchInput] = useState('');
  const [cursor, setCursor] = useState(0);
  const [locationIdMap, setLocationIdMap] = useState<locationIdMap>({});
  const [clusterIdMap, setClusterIdMap] = useState<ClusterIdMap>({});

  useEffect(() => {
    if (Array.isArray(locations)) {
      const locationIdMap: locationIdMap = {};

      for (const location of locations) {
        locationIdMap[location.resource_id] = location;
      }

      setLocationIdMap(locationIdMap);
    }
  }, [locations]);

  useEffect(() => {
    if (Array.isArray(deploymentEnvs)) {
      const clusterIdMap: ClusterIdMap = {};

      for (const deploymentEnv of deploymentEnvs) {
        if (deploymentEnv.type === 'cluster') {
          clusterIdMap[deploymentEnv.resource_id] = deploymentEnv;
        }
      }

      setClusterIdMap(clusterIdMap);
    }
  }, [deploymentEnvs]);

  useOutsideAlerter(searchContainerRef, () => {
    setSearchInput('');
  });

  const handleLocationSearch = (location: Location) => {
    setCursor(0);
    setSearchInput('');
    onLocationSearch(location);
  };

  const handleNamespaceSearch = (
    location: Location,
    cluster: DeploymentEnvironment,
    namespace: Namespace
  ) => {
    setCursor(0);
    setSearchInput('');
    onNamespaceSearch(location, cluster, namespace);
  };

  const handleDeplEnvSearch = (
    location: Location,
    deplEnv: DeploymentEnvironment
  ) => {
    setCursor(0);
    setSearchInput('');
    onDeplEnvSearch(location, deplEnv);
  };

  const makeBold = (item: string) => {
    const re = new RegExp(searchInput?.trim(), 'gi');
    return item?.replace(re, '<b>' + searchInput?.trim() + '</b>');
  };

  const getFilteredLocations = () => {
    const filteredLocation: Location[] = [];

    if (searchInput) {
      for (const location of locations) {
        if (
          location.name
            ?.toLocaleLowerCase()
            ?.includes(searchInput.trim().toLocaleLowerCase())
        ) {
          filteredLocation.push(location);

          if (filteredLocation.length >= 5) break;
        }
      }
    }

    return filteredLocation;
  };

  const fileredLocations = getFilteredLocations();

  const getfilteredDeplEnvs = () => {
    const filteredDeplEnvs: FilteredDeplEnv[] = [];

    if (searchInput && Array.isArray(deploymentEnvs)) {
      for (const deplEnv of deploymentEnvs) {
        if (
          deplEnv.name
            ?.toLocaleLowerCase()
            ?.includes(searchInput.trim().toLocaleLowerCase())
        ) {
          const location = locationIdMap[deplEnv.location_id];
          if (location) {
            filteredDeplEnvs.push({ location, deplEnv });
          }

          if (filteredDeplEnvs.length >= 5) break;
        }
      }
    }

    return filteredDeplEnvs;
  };

  const filteredDeplEnvs = getfilteredDeplEnvs();

  const getfilteredNamespaces = () => {
    const filteredNamespaces: FilteredNamespace[] = [];

    if (searchInput && Array.isArray(namespaces)) {
      for (const namespace of namespaces) {
        if (
          namespace.name
            ?.toLocaleLowerCase()
            ?.includes(searchInput.trim().toLocaleLowerCase())
        ) {
          const cluster = clusterIdMap[namespace.cluster_id];

          if (cluster) {
            const location = locationIdMap[cluster.location_id];

            if (location) {
              filteredNamespaces.push({
                location,
                cluster,
                namespace,
              });
            }
          }

          if (filteredDeplEnvs.length >= 5) break;
        }
      }
    }

    return filteredNamespaces;
  };

  const filteredNamespaces = getfilteredNamespaces();

  const handleKeyDown = (e: any) => {
    // Up arrow pressed
    if (e.keyCode === 38) {
      if (cursor > 0) {
        setCursor(prevState => prevState - 1);
      }
    }

    // Down arrow pressed
    if (e.keyCode === 40) {
      if (
        cursor <
        fileredLocations.length +
          filteredDeplEnvs.length +
          filteredNamespaces.length -
          1
      ) {
        setCursor(prevState => prevState + 1);
      }
    }

    // Enter key  pressed
    if (e.keyCode === 13) {
      if (fileredLocations.length && cursor < fileredLocations.length) {
        const location = fileredLocations[cursor];

        if (location) {
          handleLocationSearch(location);
        }
      }
      if (
        filteredDeplEnvs.length &&
        cursor < fileredLocations.length + filteredDeplEnvs.length
      ) {
        const deplEnv = filteredDeplEnvs[cursor - fileredLocations.length];

        if (deplEnv) {
          handleDeplEnvSearch(deplEnv.location, deplEnv.deplEnv);
        }
      } else {
        const namespace =
          filteredNamespaces[
            cursor - (fileredLocations.length + filteredDeplEnvs.length)
          ];

        if (namespace) {
          handleNamespaceSearch(
            namespace.location,
            namespace.cluster,
            namespace.namespace
          );
        }
      }
    }
  };

  const renderLocationList = () => (
    <div className='session'>
      <h4 className='heading'>{t('locations')}</h4>

      {fileredLocations.map((location, index) => (
        <div
          className={`list ${cursor === index ? 'active' : ''}`}
          onClick={() => {
            handleLocationSearch(location);
          }}
        >
          <div className='icon'>
            {location.unmanaged ? images.unmanagedIcon() : images.managedIcon()}
          </div>
          <Overflow
            align='start'
            toolTipDirection='bottom'
            key={`location-list-${location.resource_id}`}
          >
            <div
              className='title'
              dangerouslySetInnerHTML={{ __html: makeBold(location.name) }}
            ></div>
          </Overflow>

          <div className='address-session'>
            {location.type && (
              <div>({t(`locationTypes.${location.type}`)})</div>
            )}
            <div className='name'>{location.city}</div>
            <div className='name'>{location.country}</div>
          </div>
        </div>
      ))}
    </div>
  );

  const renderDeplEnvList = () => (
    <div className='session'>
      <h4 className='heading'>{t('deploymentEnvs')}</h4>

      {filteredDeplEnvs.map(({ location, deplEnv }, index) => (
        <div
          className={`list ${
            cursor === index + fileredLocations.length ? 'active' : ''
          }`}
          onClick={() => {
            handleDeplEnvSearch(location, deplEnv);
          }}
        >
          <Overflow
            align='start'
            toolTipDirection='bottom'
            key={`deplenv-list-location-name-${deplEnv?.resource_id}`}
          >
            <div className='title'>{location?.name}</div>
          </Overflow>
          <div className='seperator'>/</div>
          <div className='icon'>
            {deplEnv?.unmanaged ? images.unmanagedIcon() : images.managedIcon()}
          </div>
          <Overflow
            align='start'
            toolTipDirection='bottom'
            key={`deplenv-list-deplenv-name-${deplEnv?.resource_id}`}
          >
            <div
              className='title'
              dangerouslySetInnerHTML={{
                __html: makeBold(deplEnv?.name),
              }}
            ></div>
          </Overflow>
          <div className='subtype'>({getDeplEnvSubTypeName(deplEnv)})</div>
        </div>
      ))}
    </div>
  );

  const renderNamespaceList = () => (
    <div className='session'>
      <h4 className='heading'>{t('nameSpaceText')}</h4>

      {filteredNamespaces.map(({ location, cluster, namespace }, index) => (
        <div
          className={`list ${
            cursor === index + fileredLocations.length + filteredDeplEnvs.length
              ? 'active'
              : ''
          }`}
          onClick={() => {
            handleNamespaceSearch(location, cluster, namespace);
          }}
        >
          <Overflow
            align='start'
            toolTipDirection='bottom'
            key={`namespace-list-location-name-${namespace?.resource_id}`}
          >
            <div className='title'>{location?.name}</div>
          </Overflow>
          <div className='seperator'>/</div>
          <Overflow
            align='start'
            toolTipDirection='bottom'
            key={`namespace-list-cluster-name-${namespace?.resource_id}`}
          >
            <div className='title'>{cluster?.name}</div>
          </Overflow>
          <div className='seperator'>/</div>
          <div className='icon'>
            {namespace?.unmanaged
              ? images.unmanagedIcon()
              : images.managedIcon()}
          </div>
          <Overflow
            align='start'
            toolTipDirection='bottom'
            key={`namespace-list-namespace-name-${namespace?.resource_id}`}
          >
            <div
              className='title'
              dangerouslySetInnerHTML={{
                __html: makeBold(namespace?.name),
              }}
            ></div>
          </Overflow>
        </div>
      ))}
    </div>
  );

  return (
    <div className='cloud-search' ref={searchContainerRef}>
      <Search
        size='lg'
        labelText={''}
        className='tableSearch'
        onChange={e => setSearchInput(e.target.value)}
        value={searchInput}
        renderIcon={
          <IconWithToolTip icon={<Search16 />} iconDescription={t('search')} />
        }
        onKeyDown={handleKeyDown}
      />

      {searchInput &&
        fileredLocations.length === 0 &&
        filteredDeplEnvs.length === 0 &&
        filteredNamespaces.length === 0 && (
          <VerticalEmptyState
            icon={images.NotFoundLarge()}
            header={t('notFound.header')}
            description={t('notFound.description')}
            buttonText={t('notFound.text') ?? ''}
            link={false}
            additionalClassName='small'
            click={() => {}}
          />
        )}

      {searchInput &&
        (fileredLocations.length > 0 ||
          filteredDeplEnvs.length > 0 ||
          filteredNamespaces.length > 0) && (
          <div className='search-container'>
            {fileredLocations.length > 0 && renderLocationList()}
            {filteredDeplEnvs.length > 0 && renderDeplEnvList()}
            {filteredNamespaces.length > 0 && renderNamespaceList()}
          </div>
        )}
    </div>
  );
};

export default CloudSearch;
