import { useQuery, useMutation } from '@tanstack/react-query';
import {
  getAllLocations,
  getLocations,
  getLocationDetails,
  updateLocation,
  createLocation,
  deleteLocation,
} from '../controllers/location';
import { QueryOptions, queryDefaultOptions } from './queryDefaults';
import { MutationKeys, QueryKeys, VisibilityFlags } from '../lib/enums';

export const useLocations = (
  view: string = VisibilityFlags.ALL,
  options?: QueryOptions
) => {
  return useQuery({
    queryKey: [QueryKeys.LOCATIONS, view],
    queryFn: () => getAllLocations(view),
    ...queryDefaultOptions,
    ...options,
    ...{ staleTime: 1000 * 30 },
  });
};

export const useLocationDetails = (
  cloudId: string,
  locationId: string,
  view = VisibilityFlags.ALL,
  options?: QueryOptions
) => {
  return useQuery({
    queryKey: [QueryKeys.LOCATIONSDETAILS, cloudId, locationId],
    queryFn: () => getLocationDetails(cloudId, locationId, view),
    ...queryDefaultOptions,
    ...options,
    staleTime: 0,
    cacheTime: 0,
  });
};

export const useCreateLocationMutation = (options?: QueryOptions) => {
  return useMutation({
    mutationKey: [MutationKeys.LOCATIONADD],
    mutationFn: createLocation,
    ...queryDefaultOptions,
    ...options,
  });
};

export const useDeleteLocationMutation = (options?: QueryOptions) => {
  return useMutation({
    mutationKey: [MutationKeys.LOCATIONDELETE],
    mutationFn: deleteLocation,
    ...queryDefaultOptions,
    ...options,
  });
};

export const useUpdateLocation = (options?: QueryOptions) => {
  return useMutation({
    mutationKey: [MutationKeys.LOCATIONUPDATE],
    mutationFn: updateLocation,
    ...queryDefaultOptions,
    ...options,
  });
};

export const useLocationsUnderCloud = (
  cloudId: string,
  view = 'all',
  options?: QueryOptions
) => {
  return useQuery({
    queryKey: [QueryKeys.LOCATIONSUNDERCLOUD, cloudId, view],
    queryFn: () => getLocations(cloudId, view),
    ...queryDefaultOptions,
    ...options,
  });
};
