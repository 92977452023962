import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header/Header';
import CreateRole from './CreateRole/CreateRole';
import { FlexGrid } from '@carbon/react';
import {
  Row,
  Column,
  Button,
  ContentSwitcher,
  SkeletonPlaceholder,
} from 'carbon-components-react';
import { List32, Grid32 } from '@carbon/icons-react';
import SortDropDown from '../../components/SortDropdown/SortDropDown';
import FindAndFilterBar from '../../components/FindAndFilterBar/FindAndFilterBar';
import sortData from '../../lib/tableSort';
import { Role, AppliedFilter, Error500Type } from '../../models/master';
import images from '../../images/images';
import { CardEmptyState } from '../../components/CardEmptyState/CardEmptyState';
import RolesTable from './RolesTable/RolesTable';
import RoleCard from './RoleCard/RoleCard';
import { getRoles } from '../../controllers/roleApis';
import './RolesContainer.scss';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import useAnalytics from '../../lib/useAnalytics';
import analyticsData from '../../lib/analyticsEventData';
import Error500Card from '../../components/ErrorState/Error500Card';

type View = 'table' | 'card';

const RolesContainer = () => {
  const { t } = useTranslation('roles');

  const viewStateValue = localStorage.getItem('VIEW_STATE') as View;
  const [view, setView] = useState<View>(
    viewStateValue ? viewStateValue : 'table'
  );

  const [currentPageNumber, setPageNumber] = useState(1);
  const [currentPageSize, setPageSize] = useState(25);
  const [rolesData, setRolesData] = useState<Role[] | null>(null);
  const [resourceDataLoading, toggleResourceDataLoading] = useState(false);
  const [filteredData, setFilteredData] = useState<Role[] | []>([]);
  const [filterApplied, setFilterApplied] = useState<AppliedFilter[] | []>([]);
  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | 'NONE'>(
    'NONE'
  );
  const [openCreateRoleTearsheet, toggleCreateRoleTearsheet] = useState(false);
  const [error500, setError500] = useState<null | Error500Type>(null);

  const navigate = useNavigate();
  const { pageViewed, trackButtonClicked } = useAnalytics();

  const refreshData = async () => {
    try {
      setRolesData(null);
      setSortKey('');
      setSortDirection('NONE');
      toggleResourceDataLoading(true);
      const roles = await getRoles();
      setRolesData(roles?.roles as any[]);

      if (error500) {
        setError500(null);
      }
    } catch (error) {
      const err = error as AxiosError;
      toggleResourceDataLoading(false);
      setRolesData([]);
      if (err.response?.status === 403) {
        navigate('/403');
      }

      if (err.response!?.status >= 500) {
        setError500(err.response!?.status?.toString() as Error500Type);
      }
      console.error(err);
    } finally {
      toggleResourceDataLoading(false);
    }
  };

  const closeTearsheet = () => {
    toggleCreateRoleTearsheet(false);
  };

  const openTearsheet = () => {
    toggleCreateRoleTearsheet(true);
    trackButtonClicked(
      analyticsData['Admin Roles'].events.createRole.props,
      analyticsData['Admin Roles'].events.createRole.event
    );
  };

  const handleSort = (data: { id: string; text: string }) => {
    if (rolesData) {
      if (data.id === 'atoz') {
        setSortDirection('ASC');
        const sortedResourceGroups = Array.isArray(rolesData)
          ? rolesData.sort((a, b) =>
              a.name?.trim()?.toLowerCase() > b.name?.trim()?.toLowerCase()
                ? 1
                : -1
            )
          : [];
        setRolesData([...sortedResourceGroups]);
      } else {
        setSortDirection('DESC');
        const sortedResourceGroups = Array.isArray(rolesData)
          ? rolesData.sort((a, b) =>
              a.name?.trim()?.toLowerCase() < b.name?.trim()?.toLowerCase()
                ? 1
                : -1
            )
          : [];
        setRolesData([...sortedResourceGroups]);
      }
    }
  };

  const renderFilter = () => {
    return (
      <FindAndFilterBar
        data={rolesData}
        filteredDataCallback={data => setFilteredData(data as Role[] | [])}
        filteredData={
          rolesData
            ? filterApplied?.length > 0
              ? filteredData
              : rolesData
            : null
        }
        filtersApplied={filterApplied as any}
        filtersAppliedCallback={data => setFilterApplied(data)}
        filters={getRolesFilters() as any}
        onRefresh={() => refreshData()}
        persistFilter
      />
    );
  };
  const setPageChange = (pageData: { page: number; pageSize: number }) => {
    setPageNumber(pageData.page);
    setPageSize(pageData.pageSize);
  };

  const handleTableSort = (
    data: { id: string; text: string },
    sortDirection: 'ASC' | 'DESC' | 'NONE'
  ) => {
    setSortDirection(sortDirection);
    setSortKey(data.id);
  };

  const getRolesFilters = () => [
    {
      key: 'labels',
      type: 'multi',
      label: t('filters.labels'),
      placeHolderText: t('filters.labelsPlaceholder'),
      values: [
        ...Array.from(
          new Set((rolesData ? rolesData : []).map(role => role?.labels).flat())
        ),
      ],
    },
  ];

  useEffect(() => {
    pageViewed('Admin Roles');
    toggleResourceDataLoading(true);
    refreshData();
  }, []);

  return (
    <div className='roles-container'>
      <Header
        title={t('header')}
        subTitle={t('subheader')}
        breadcrumbs={[
          {
            url: '/',
            label: t('home'),
          },
        ]}
        actions={[
          {
            kind: 'primary',
            onClick: () => openTearsheet(),
            text: t('createRole'),
          },
        ]}
      />
      {openCreateRoleTearsheet && (
        <CreateRole
          open={openCreateRoleTearsheet}
          onClose={closeTearsheet}
          rolesData={rolesData}
          refreshData={refreshData}
        />
      )}
      <div className='page-content'>
        <div className='role-switcher'>
          {view !== 'table' ? (
            <>
              {resourceDataLoading ? (
                <div className='skeleton-sort-drop-down'>
                  <SkeletonPlaceholder className={'sorting-skeleton'} />
                </div>
              ) : (
                <SortDropDown
                  id='role-sort-dropdown'
                  size='lg'
                  onSort={handleSort}
                  sortDir={sortDirection}
                />
              )}
            </>
          ) : null}
          <ContentSwitcher className='view-switcher'>
            <Button
              className={
                'switch-button' + (view === 'table' ? ' selected' : '')
              }
              onClick={() => {
                setView('table');
                localStorage.setItem('VIEW_STATE', 'table');
              }}
              renderIcon={List32}
              hasIconOnly
              tooltipPosition='bottom'
              iconDescription={t('table')}
            />
            <Button
              className={'switch-button' + (view === 'card' ? ' selected' : '')}
              onClick={() => {
                setView('card');
                localStorage.setItem('VIEW_STATE', 'card');
              }}
              renderIcon={Grid32}
              hasIconOnly
              tooltipPosition='bottom'
              iconDescription={t('card')}
            />
          </ContentSwitcher>
        </div>
        <div className='body'>
          {view === 'table' ? (
            <RolesTable
              currentPageNumber={currentPageNumber}
              currentPageSize={currentPageSize}
              sortRows={(
                data: { id: string; text: string },
                direction: 'ASC' | 'DESC' | 'NONE'
              ) => handleTableSort(data, direction)}
              onPageChange={(pageData: { page: number; pageSize: number }) =>
                setPageChange(pageData)
              }
              rows={
                rolesData
                  ? filterApplied?.length > 0
                    ? sortData(filteredData, sortKey, sortDirection)
                    : sortData(rolesData, sortKey, sortDirection)
                  : null
              }
              elementCount={
                rolesData
                  ? filterApplied?.length > 0
                    ? filteredData?.length
                    : rolesData?.length
                  : 0
              }
              filteredDataSet={
                rolesData
                  ? filterApplied?.length > 0
                    ? filteredData
                    : rolesData
                  : null
              }
              filteredDataCallback={data => {
                data && setFilteredData(data as Role[] | []);
                setPageNumber(1);
              }}
              data={rolesData}
              filtersSelected={filterApplied as any}
              filtersAppliedCallback={data => setFilterApplied(data)}
              filters={getRolesFilters()}
              onRefresh={() => refreshData()}
              error500Flag={!!error500}
            />
          ) : error500 ? (
            <Error500Card />
          ) : resourceDataLoading ? (
            <div>
              {renderFilter()}
              <Row className='skeleton-card-view'>
                <Column>
                  <SkeletonPlaceholder className='resouce-group-skeleton' />
                </Column>
                <Column>
                  <SkeletonPlaceholder className='resouce-group-skeleton' />
                </Column>
                <Column>
                  <SkeletonPlaceholder className='resouce-group-skeleton' />
                </Column>
                <Column>
                  <SkeletonPlaceholder className='resouce-group-skeleton' />
                </Column>
              </Row>
            </div>
          ) : (
            <div>
              {rolesData && rolesData?.length >= 0 ? renderFilter() : null}
              <div className='card-view'>
                <FlexGrid>
                  <Row className='resource-card-alignment'>
                    {rolesData ? (
                      (filterApplied?.length > 0 ? filteredData : rolesData)
                        ?.length > 0 ? (
                        (filterApplied?.length > 0
                          ? filteredData
                          : rolesData
                        ).map(role => (
                          <Column
                            lg={4}
                            md={4}
                            className='resource-card'
                            key={role?.resource_id}
                          >
                            <RoleCard
                              roleId={role?.resource_id}
                              resourceName={role?.name}
                              identities={role?.identities}
                              tags={role?.labels}
                              updated={role?.updated_at}
                            />
                          </Column>
                        ))
                      ) : (
                        <CardEmptyState
                          filterApplied={filterApplied}
                          emptyState={{
                            icon: images.noRoleIcon(),
                            header: t('emptyState.emptyContainerHeader'),
                            description: t(
                              'emptyState.emptyContainerDescription'
                            ),
                            buttonText: t('emptyState.buttonText'),
                            link: true,
                          }}
                        />
                      )
                    ) : (
                      <div className='no-resource-group'></div>
                    )}
                  </Row>
                </FlexGrid>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RolesContainer;
