import axios from 'axios';
import { MAPBOX_ACCESS_TOKEN } from './config';

// get all topology resouces
export async function getAddressLocation (data) {
  try {
    const response = await axios({
      method: 'GET',
      url: `https://api.mapbox.com/geocoding/v5/mapbox.places/${data}.json?access_token=${MAPBOX_ACCESS_TOKEN}`
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
