import React from 'react';
import { Button } from 'carbon-components-react';
import { Add16 } from '@carbon/icons-react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import images from '../../../images/images';

import './SidePanelEmptyState.scss';

type Props = {
  icon: JSX.Element;
  header: string;
  message: string;
  btnText?: string;
  btnUrl?: string;
  resourceType?: string;
  disableBtn?: boolean;
  notAuthorized?: boolean;
  showBtn?: string;
  error500?: boolean;
};

const SidePanelEmptyState: React.FC<Props> = ({
  icon,
  header,
  btnText,
  message,
  btnUrl,
  resourceType,
  disableBtn = false,
  notAuthorized = false,
  showBtn = false,
  error500 = false,
}) => {
  const navigate = useNavigate();

  const { t } = useTranslation('customSidePanel');

  const onRegisterButtonClick = () => {
    if (btnUrl && resourceType) {
      navigate(btnUrl, {
        state: { resourceType: resourceType },
      });
    }
  };

  const getIcon = () => {
    if (notAuthorized) {
      return images.notAuthorizedIcon();
    }

    if (error500) {
      return images.error500SmallIcon();
    }

    return icon;
  };

  const getHeader = () => {
    if (notAuthorized) {
      return t('notAuthorized.header');
    }

    if (error500) {
      return t('error500.header');
    }

    return header;
  };

  const getMessage = () => {
    if (notAuthorized) {
      return t('notAuthorized.message');
    }

    if (error500) {
      return t('error500.message');
    }

    return message;
  };

  return (
    <div
      className={
        !error500
          ? 'side-panel-empty-state'
          : 'side-panel-empty-state error-500'
      }
    >
      <span>
        <div>{getIcon()}</div>
      </span>
      <span>
        <div className='header'>{getHeader()}</div>
        <div className='message'>{getMessage()}</div>
      </span>
      {!notAuthorized && showBtn && (
        <Button
          className='action-button'
          size='small'
          kind='tertiary'
          renderIcon={Add16}
          disabled={disableBtn}
          onClick={e => {
            onRegisterButtonClick();
          }}
        >
          {btnText}
        </Button>
      )}
    </div>
  );
};

export default SidePanelEmptyState;
