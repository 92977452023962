import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GatewayInstance, AppliedFilter } from '../../../../models/master';
import GenericTableWithFilters from '../../../../components/GenericTableWithFilters/GenericTableWithFilters';
import images from '../../../../images/images.js';
import sortData from '../../../../lib/tableSort';
import HealthStatus from '../../../../components/GatewayStatus/HealthStatus';
import { HealthStatusType } from '../../../../components/GatewayStatus/config';
import { getGatewayInstance } from '../../../../controllers/gateawayApis';

import './GatewayInstanceTable.scss';

interface TableRows {
  id: any;
  health: JSX.Element | undefined | string;
  version: string;
  role: string;
  profile: string;
  wan: string;
  privateIp: string;
  loopbackIp: string;
}

interface Props {
  gatewayID: string | null;
  gwDetailsData: any;
}

const GatewayInstanceTable = (props: Props) => {
  const { t } = useTranslation('gatewayInstanceDetails');

  const { gatewayID, gwDetailsData } = props;

  const tableHeaders = () => {
    const formattedData = [
      {
        key: 'health',
        originalKey: 'health',
        sort: 'sortByThis_gw_health',
        header: t('tableLabels.health'),
      },

      {
        key: 'version',
        originalKey: 'version',
        sort: 'sortByThis_gw_version',
        header: t('tableLabels.version'),
      },
      {
        key: 'role',
        originalKey: 'role',
        sort: 'sortByThis_gw_role',
        header: t('tableLabels.role'),
      },
    ];

    if (gwDetailsData?.subtype !== 'RHSI-EDGE') {
      formattedData.splice(
        4,
        0,
        {
          key: 'profile',
          originalKey: 'profile',
          sort: 'sortByThis_gw_profile',
          header: t('tableLabels.profile'),
        },
        {
          key: 'wan',
          originalKey: 'wan',
          sort: 'sortByThis_gw_wan',
          header: t('tableLabels.wan'),
        },
        {
          key: 'privateIp',
          originalKey: 'privateIp',
          sort: 'sortByThis_gw_pvt_privateIp',
          header: t('tableLabels.privateIp'),
        },
        {
          key: 'loopbackIp',
          originalKey: 'loopbackIp',
          sort: 'sortByThis_gw_loopbackIp',
          header: t('tableLabels.loopbackIp'),
        }
      );
    }
    return formattedData;
  };

  const [currentPageNumber, setPageNumber] = useState(1);
  const [currentPageSize, setPageSize] = useState(10);
  const [instanceDataCount, setInstanceDataCount] = useState(0);
  const [tableData, setTableData] = useState<GatewayInstance[] | null>(null);
  const [filteredData, setFilteredData] = useState<GatewayInstance[] | []>([]);
  const [instanceDataLoading, setInstanceDataLoading] = useState(false);
  const [filterApplied, setFilterApplied] = useState<AppliedFilter[] | []>([]);
  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | 'NONE'>(
    'NONE'
  );
  const handleTableSort = (
    data: { id: string; text: string },
    sortDirection: 'ASC' | 'DESC' | 'NONE'
  ) => {
    setSortDirection(sortDirection);
    setSortKey(data.id);
  };

  const refreshData = async () => {
    try {
      setInstanceDataLoading(true);
      setTableData(null);
      setFilterApplied([]);
      setFilteredData([]);
      setSortKey('');
      setSortDirection('NONE');

      const response = await getGatewayInstance(gatewayID);

      setInstanceDataCount(response.gatewayinstances?.length); // Data count
      setTableData(response.gatewayinstances);
    } catch (e) {
      setInstanceDataCount(0);
      console.error(e);
    } finally {
      setInstanceDataLoading(false);
    }
  };

  useEffect(() => {
    if (gatewayID) refreshData();
  }, [gatewayID]);

  const setRowsData = (data: GatewayInstance[]) => {
    let formattedRows: TableRows[] = [];
    if (data && data.length === 0) return [];
    if (data)
      data.map((row: GatewayInstance) => {
        formattedRows.push({
          id: row?.resource_id,
          health:
            row?.health_status && row?.health_status !== '' ? (
              <HealthStatus
                status={row?.health_status as HealthStatusType}
                showLabel={true}
                gatewayName={gwDetailsData?.name}
              />
            ) : (
              '—'
            ),
          version: row?.version && row?.version !== '' ? row?.version : '—',
          role: row?.role && row?.role !== '' ? row?.role : '—',
          profile:
            row?.compute_profile && row?.compute_profile !== ''
              ? row?.compute_profile
              : '—',
          wan: row?.public_ip && row?.public_ip !== '' ? row?.public_ip : '—',
          privateIp:
            row?.private_ip && row?.private_ip !== '' ? row?.private_ip : '—',
          loopbackIp:
            row?.loopback_ip && row?.loopback_ip !== ''
              ? row?.loopback_ip
              : '—',
        });
        return 0;
      });
    else return null;
    return formattedRows;
  };

  const setPageChange = (pageData: { page: number; pageSize: number }) => {
    setPageNumber(pageData.page);
    setPageSize(pageData.pageSize);
  };

  const getEmptyState = () => {
    return {
      icon: images.noAppInstancesSmallIcon(),
      notFoundIcon: images.NotFoundLarge(),
      emptyStateHeader: t('noInstance'),
      emptyStateDescription: t('noInstanceDesc'),
      link: false,
      buttonText: t('RegisterInstance'),
      click: () => {},
    };
  };

  return (
    <div className='gateway-instance-table'>
      {Array.isArray(tableData) && (
        <GenericTableWithFilters
          tableDataLoading={instanceDataLoading}
          title={t('tableLabels.title')}
          id='instance-gateway-table'
          paginationClass={instanceDataCount < 9 ? 'pagination-hide' : ''}
          rows={
            tableData
              ? filterApplied.length > 0
                ? setRowsData(sortData(filteredData, sortKey, sortDirection))
                : setRowsData(sortData(tableData, sortKey, sortDirection))
              : null
          }
          data={
            tableData
              ? filterApplied.length > 0
                ? filteredData
                : tableData
              : null
          }
          headers={tableHeaders()}
          sortRows={(
            data: { id: string; text: string },
            direction: 'ASC' | 'DESC' | 'NONE'
          ) => handleTableSort(data, direction)}
          isSortable={true}
          totalElementsCount={
            tableData
              ? filterApplied.length > 0
                ? filteredData.length
                : tableData.length
              : 0
          }
          fullData={tableData}
          onTableRefresh={() => refreshData()}
          showRefresh={false}
          showSearch={false}
          currentPageNumber={currentPageNumber}
          currentPageSize={currentPageSize}
          onPageChange={(pageData: any) => setPageChange(pageData)}
          emptyState={getEmptyState()}
          selectedFiltersVal={filterApplied}
          setFilterApplied={data => {
            setFilterApplied(data);
          }}
          filteredDataCallback={(data: GatewayInstance[]) => {
            setFilteredData(data as GatewayInstance[] | []);
            setPageNumber(1);
          }}
        />
      )}
    </div>
  );
};

export default GatewayInstanceTable;
