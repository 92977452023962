import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DataTable,
  TableContainer,
  TableToolbar,
  TableToolbarSearch,
  Button,
  TableToolbarContent,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  DataTableSkeleton,
  TableProps,
  Pagination,
  TooltipDefinition,
} from 'carbon-components-react';
import { Add16, TrashCan16 } from '@carbon/icons-react';
import { Identity } from '../../../models/master';
import {
  DataTableHeader,
  DataTableRow,
  DataTableCell,
} from '../../../models/dataTable';
import images from '../../../images/images';
import { VerticalEmptyState } from '../../../components/EmptyState/EmptyState';
import IconWithToolTip from '../../../components/IconWithToolTip/IconWithToolTip';
import './RoleDetailsIdentityTable.scss';

interface PropsInterface {
  rows: any[] | null;
  headers: DataTableHeader[];
  loading: boolean;
  style?: any;
  openAssignIdentity?: () => void;
  sortRows(arg0: unknown, direction: string): void;
  identities?: Identity[] | null;
  removeIdentity: (identityId: string, identityName: string) => void;
}

const RoleDetailsIdentityTable = (Props: PropsInterface) => {
  const {
    rows,
    headers,
    loading,
    style,
    openAssignIdentity,
    sortRows,
    identities,
    removeIdentity,
  } = Props;

  const { t } = useTranslation('roleDetails');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState('');
  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | 'NONE'>(
    'NONE'
  );
  const [filteredIdentities, setFilteredIdentities] = useState<
    Identity[] | null
  >(null);

  useEffect(() => {
    let filteredIdentitiesData =
      identities != null &&
      identities.filter((identity: Identity) => {
        return (
          rows != null &&
          rows.every((assignedIdentity: Identity) => {
            return assignedIdentity.resource_id !== identity.resource_id;
          })
        );
      });
    Array.isArray(filteredIdentitiesData) &&
      setFilteredIdentities(filteredIdentitiesData);
  }, []);

  useEffect(() => {
    let filteredIdentitiesData =
      identities != null &&
      identities.filter((identity: Identity) => {
        return (
          rows != null &&
          rows.every((assignedIdentity: Identity) => {
            return assignedIdentity.resource_id !== identity.resource_id;
          })
        );
      });
    Array.isArray(filteredIdentitiesData) &&
      setFilteredIdentities(filteredIdentitiesData);
  }, [rows, identities]);

  const getFilteredRows = () => {
    if (!searchText && Array.isArray(rows)) {
      return [...rows];
    }

    if (searchText && Array.isArray(rows))
      return rows?.filter(identity =>
        identity?.name?.toLowerCase().includes(searchText?.toLowerCase())
      );

    return [];
  };

  const handlePagination = (change: any) => {
    setPage(change.page);
    setPageSize(change.pageSize);
  };

  const applySearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleChangeSort = (index: number) => {
    const newSortDirection =
      sortKey === headers[index].originalKey
        ? sortDirection === 'ASC'
          ? 'DESC'
          : 'ASC'
        : 'ASC';
    setSortDirection(newSortDirection);
    const sortedKey =
      headers[index]?.originalKey !== undefined
        ? headers[index]?.originalKey ?? ''
        : '';
    setSortKey(sortedKey);
    sortRows(
      {
        id: headers[index].originalKey,
        text: headers[index].header,
      },
      newSortDirection
    );
  };

  const getEmptyStateConfig = () => {
    if (searchText.length > 0)
      return (
        <VerticalEmptyState
          icon={images.NotFoundLarge()}
          header={t('identityTableNoResult.header')}
          description={t('identityTableNoResult.description')}
          additionalClassName='small'
        />
      );
    else
      return (
        <VerticalEmptyState
          icon={images.noIdentityIcon()}
          header={t('identityTableEmptyState.header')}
          description={t('identityTableEmptyState.description')}
          buttonText={t('addIdentity') ?? ''}
          link={true}
          additionalClassName='small'
          click={openAssignIdentity}
        />
      );
  };

  if (loading) {
    return (
      <div className='role-identities-table-container'>
        <DataTableSkeleton
          showHeader
          showToolbar
          rowCount={3}
          columnCount={7}
        />
      </div>
    );
  }
  return (
    <div className='role-identities-table-container'>
      <div
        className={
          'role-identities-header' +
          (rows != null && rows.length > 0 ? '' : ' border')
        }
        style={style ? style : {}}
      >
        {rows != null &&
          t('roleIdentitiesTable.header', { count: rows.length })}
      </div>

      {rows != null && rows?.length > 0 && (
        <DataTable rows={getFilteredRows()} headers={headers} isSortable={true}>
          {({
            rows,
            headers,
            getHeaderProps,
            getRowProps,
            getTableProps,
            getToolbarProps,
            getTableContainerProps,
          }: {
            rows: DataTableRow[];
            headers: DataTableHeader[];
            getTableProps: () => TableProps;
            getHeaderProps: any;
            getRowProps: any;
            getToolbarProps: any;
            getTableContainerProps: any;
          }) => (
            <TableContainer {...getTableContainerProps()}>
              <TableToolbar {...getToolbarProps()}>
                <TableToolbarContent>
                  <TableToolbarSearch
                    expanded
                    onChange={e => applySearch(e)}
                    placeholder={t('roleIdentitiesTable.searchPlaceholder')}
                  />
                  {Array.isArray(filteredIdentities) &&
                  filteredIdentities.length > 0 ? (
                    <Button
                      kind='ghost'
                      className='add-identity-button'
                      renderIcon={Add16}
                      onClick={openAssignIdentity}
                    >
                      <span className='text'>
                        {t('roleIdentitiesTable.registerIdentity')}
                      </span>
                    </Button>
                  ) : (
                    <TooltipDefinition
                      tooltipText={
                        t(
                          'roleIdentitiesTable.addIdentityDisabledToolTip'
                        ) as string
                      }
                      direction='bottom'
                    >
                      <Button
                        kind='ghost'
                        className='add-identity-button disabled'
                        renderIcon={Add16}
                        onClick={openAssignIdentity}
                        disabled
                      >
                        <span className='text'>
                          {t('roleIdentitiesTable.registerIdentity')}
                        </span>
                      </Button>
                    </TooltipDefinition>
                  )}
                </TableToolbarContent>
              </TableToolbar>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header: DataTableHeader, index: number) => (
                      <TableHeader
                        key={header.key}
                        {...getHeaderProps({
                          header,
                          isSortable: Boolean(header.sort),
                        })}
                        onClick={() =>
                          header.sort ? handleChangeSort(index) : undefined
                        }
                        isSortHeader={Boolean(header.sort)}
                        sortDirection={
                          headers[index].originalKey === sortKey
                            ? sortDirection
                            : 'NONE'
                        }
                      >
                        {header.header}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getFilteredRows().length > 0 &&
                    rows.map((row: DataTableRow, index: number) => (
                      <TableRow {...getRowProps({ row })} key={row.id}>
                        {row.cells.map((cell: DataTableCell) => {
                          let actionBtnId = `${row.id}:actionBtn`;
                          if (actionBtnId == cell.id) {
                            return (
                              <TableCell key={row.id + cell.id}>
                                <div
                                  className='delete-action-button'
                                  onClick={() => {
                                    removeIdentity(
                                      row.id,
                                      row?.cells[0]?.value?.props?.children
                                        ?.props?.str
                                    );
                                  }}
                                >
                                  <IconWithToolTip
                                    icon={<TrashCan16 />}
                                    iconDescription={t('remove.toolTip')}
                                  />
                                </div>
                              </TableCell>
                            );
                          }
                          return (
                            <TableCell key={row.id + cell.id}>
                              {cell.value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              {getFilteredRows().length > 0 && (
                <Pagination
                  totalItems={rows.length}
                  itemsPerPageText={t(
                    'roleIdentitiesTable.pagination.itemsPerPage'
                  )}
                  itemRangeText={(min, max, totalLength) => {
                    return t('roleIdentitiesTable.pagination.itemKey', {
                      min: min,
                      max: max,
                      total: totalLength,
                    });
                  }}
                  pageSize={pageSize}
                  page={page}
                  pageRangeText={(current, total) =>
                    t('roleIdentitiesTable.pagination.pageKey', { sum: total })
                  }
                  pageSizes={[10, 25, 50, 100, 250]}
                  onChange={change => handlePagination(change)}
                />
              )}
            </TableContainer>
          )}
        </DataTable>
      )}
      {getFilteredRows().length === 0 ? getEmptyStateConfig() : null}
    </div>
  );
};

export default RoleDetailsIdentityTable;
