import React from 'react';
import './CardEmptyState.scss';
import { AppliedFilter } from '../../models/master';
import { VerticalEmptyState } from '../EmptyState/EmptyState';
import images from '../../images/images';
import { useTranslation } from 'react-i18next';

const CardEmptyState = (props: {
  filterApplied: AppliedFilter[] | [];
  emptyState: {
    icon: JSX.Element;
    header: string;
    description: string;
    buttonText?: string;
    link?: string | boolean;
    click?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    dropdown?: {
      label: string;
      items: any;
      dropdownClick: (item: any) => void;
    };
  };
}) => {
  const { filterApplied, emptyState } = props;
  const { t } = useTranslation('cardEmptyState');

  return (
    <div className='no-data-container'>
      {filterApplied.length <= 0 ? (
        <VerticalEmptyState
          icon={emptyState.icon}
          header={emptyState.header}
          description={emptyState.description}
          link={emptyState?.link}
          buttonText={emptyState?.buttonText}
          click={emptyState?.click}
          dropdown={emptyState?.dropdown}
          dropdownClick={
            emptyState && emptyState?.dropdown
              ? (item: any) => {
                  return emptyState?.dropdown
                    ? emptyState?.dropdown.dropdownClick(item)
                    : null;
                }
              : () => {}
          }
        />
      ) : (
        <VerticalEmptyState
          icon={images.NotFoundLarge()}
          header={t('notFound.header')}
          description={t('notFound.description')}
          link={false}
          buttonText={t('notFound.text')}
        />
      )}
    </div>
  );
};

export { CardEmptyState };
