import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { SkeletonPlaceholder, Tabs, Tab } from 'carbon-components-react';
import CustomSidePanel from '../../../CustomSidePanel/CustomSidePanel';
import SidePanelDetailsCard from '../../../CustomSidePanel/SidePanelDetailsComponent/SidePanelDetailsCard';
import SidePanelDetailsComponent from '../../../CustomSidePanel/SidePanelDetailsComponent/SidePanelDetailsComponent';
import SidePanelTableComponent from '../../../CustomSidePanel/TableComponent/SidePanelTableComponent';
import { EdgeSidePaneDetailsComponentProps } from '../config';
import {
  ApplicationData,
  DeploymentEnvDetailsNameSpaceAndSecurityTableData,
  DeploymentEnvironment,
  ResourceGroup,
  Service,
} from '../../../../models/master';
import images from '../../../../images/images';

import {
  getApplication,
  getApplicationService,
} from '../../../../controllers/applicationApis';
import { getResourceGroup } from '../../../../controllers/resourceGroupApi';
import {
  getDeploymentEnv,
  getPartition,
} from '../../../../controllers/deploymentEnv';

import './ApplicationService.scss';
import SidePanelEmptyState from '../../../CustomSidePanel/SidePanelEmptyState/SidePanelEmptyState';

const defaultPermissionMap = {
  app: true,
  service: true,
  resourceGroup: true,
  deploymentEnv: true,
  partitions: true,
};

const defaultServerErrorMap = {
  app: '',
  deploymentEnv: '',
};

const ApplicationService: React.FC<EdgeSidePaneDetailsComponentProps> = ({
  source,
  target,
  open,
  onClose,
  sidePanelWidth,
  handleSidePanelWidth,
  handleHeaderWidth,
  initialHeaderWidth,
  headerWidth,
}) => {
  const { t } = useTranslation('topologySidePanelAppService');

  const [loading, setLoading] = useState(false);

  const [deploymentEnvData, setDeploymentEnvData] =
    useState<DeploymentEnvironment | null>(null);
  const [partitionData, setPartitionData] =
    useState<DeploymentEnvDetailsNameSpaceAndSecurityTableData | null>(null);
  const [resourceGroup, setResourceGroup] = useState<ResourceGroup | null>(
    null
  );
  const [servicesData, setServicesData] = useState<Service | null>(null);
  const [permissionMap, setPermissionMap] = useState(defaultPermissionMap);
  const [serverErrorMap, setServerErrorMap] = useState(defaultServerErrorMap);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        let appData: ApplicationData | null = null;
        try {
          appData = await getApplication(
            source.application_id,
            false,
            false,
            true
          );
        } catch (error) {
          const err = error as AxiosError;
          if (
            err?.response?.status === 403 &&
            err?.response?.statusText === 'Forbidden'
          ) {
            setPermissionMap(permissionMap => ({
              ...permissionMap,
              app: false,
              resourceGroup: false,
            }));
          }

          const errorCode = err.response!?.status;
          if (errorCode >= 500) {
            setServerErrorMap(permissionMap => ({
              ...permissionMap,
              app: errorCode.toString(),
            }));
          }
        }

        if (appData?.resource_group_id) {
          try {
            const resourceGroup: ResourceGroup = await getResourceGroup(
              appData.resource_group_id
            );
            setResourceGroup(resourceGroup);
          } catch (error) {
            const err = error as AxiosError;
            if (
              err?.response?.status === 403 &&
              err?.response?.statusText === 'Forbidden'
            ) {
              setPermissionMap(permissionMap => ({
                ...permissionMap,
                resourceGroup: false,
              }));
            }
          }
        }

        if (target?.service_uniqueId) {
          try {
            const serviceData: Service = await getApplicationService(
              source.application_id,
              target.service_uniqueId,
              true
            );
            setServicesData(serviceData);
          } catch (error) {
            const err = error as AxiosError;
            if (
              err?.response?.status === 403 &&
              err?.response?.statusText === 'Forbidden'
            ) {
              setPermissionMap(permissionMap => ({
                ...permissionMap,
                service: false,
              }));
            }

            const errorCode = err.response!?.status;
            if (errorCode >= 500) {
              setServerErrorMap(permissionMap => ({
                ...permissionMap,
                app: errorCode.toString(),
              }));
            }
          }
        }

        if (source.depl_env_id) {
          let deploymentEnv: DeploymentEnvironment | null = null;
          try {
            deploymentEnv = await getDeploymentEnv(source.depl_env_id);
            setDeploymentEnvData(deploymentEnv);
          } catch (error) {
            const err = error as AxiosError;
            if (
              err?.response?.status === 403 &&
              err?.response?.statusText === 'Forbidden'
            ) {
              setPermissionMap(permissionMap => ({
                ...permissionMap,
                deploymentEnv: false,
              }));
            }

            const errorCode = err.response!?.status;
            if (errorCode >= 500) {
              setServerErrorMap(permissionMap => ({
                ...permissionMap,
                deploymentEnv: errorCode.toString(),
              }));
            }
          }

          if (
            deploymentEnv &&
            (deploymentEnv.type === 'cluster' ||
              deploymentEnv.type === 'vpc') &&
            source.partition_id
          ) {
            try {
              const partition: DeploymentEnvDetailsNameSpaceAndSecurityTableData =
                await getPartition(source.depl_env_id, source.partition_id);
              setPartitionData(partition);
            } catch (error) {
              const err = error as AxiosError;
              if (
                err?.response?.status === 403 &&
                err?.response?.statusText === 'Forbidden'
              ) {
                setPermissionMap(permissionMap => ({
                  ...permissionMap,
                  partitions: false,
                }));
              }
            }
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleDeplEnvClick = (data: DeploymentEnvironment | null) => {
    window.open(
      window.location.origin +
        process.env.PUBLIC_URL +
        `/deploymentEnvironmentDetails?deplId=${data?.resource_id}`
    );
  };

  const handlePartitionClick = (
    data: DeploymentEnvDetailsNameSpaceAndSecurityTableData | null
  ) => {
    const partitionLink =
      partitionData?.type === 'securitygroup'
        ? `/partitionDetails?depEnvId=${data?.vpc_id}&partitionId=${data?.resource_id}`
        : `/partitionDetails?depEnvId=${data?.cluster_id}&partitionId=${data?.resource_id}`;
    window.open(
      window.location.origin + process.env.PUBLIC_URL + partitionLink
    );
  };

  const formatAppDetailsData = () => {
    const formattedData = [
      {
        label: t('deploymentEnvironment'),
        value: deploymentEnvData?.name,
        valueType: 'link',
        linkCallback: () => handleDeplEnvClick(deploymentEnvData),
        notAuthorized: !permissionMap['deploymentEnv'],
      },
      {
        label: t('resourceGroup'),
        value: resourceGroup?.name,
        valueType: 'displayField',
        notAuthorized: !permissionMap['app'] || !permissionMap['resourceGroup'],
      },
    ];

    if (partitionData) {
      formattedData.splice(1, 0, {
        label:
          deploymentEnvData?.type === 'vpc'
            ? t('securityGroup')
            : t('namespace'),
        value: partitionData?.name,
        valueType: 'link',
        linkCallback: () => handlePartitionClick(partitionData),
        notAuthorized: !permissionMap['partitions'],
      });
    }

    return formattedData;
  };

  const getHeaderTitle = () => {
    if (source && target) {
      const AppServiceIcon = images.AppServiceIcon;
      return (
        <div className='header-title'>
          <AppServiceIcon /> {source.name} : {target.name}
        </div>
      );
    }

    return '';
  };

  const getLink = () => {
    if (source) {
      return `/ApplicationDetails?appId=${source.application_id}` ?? ' ';
    }

    return '';
  };

  const title = getHeaderTitle();
  const link = getLink();

  return (
    <div className='topology-application-service-container'>
      <CustomSidePanel
        open={open}
        onClose={onClose}
        title={title}
        link={link}
        sidePanelWidth={sidePanelWidth}
        handleSidePanelWidth={handleSidePanelWidth}
        handleHeaderWidth={handleHeaderWidth}
        initialHeaderWidth={initialHeaderWidth}
        headerWidth={headerWidth}
      >
        <Tabs>
          <Tab id='topology-details-tab-1' label={t('details')}>
            <SidePanelDetailsComponent title={source?.name ?? ''}>
              {loading && (
                <div className='skeleton-container'>
                  <SkeletonPlaceholder className='app-details-skeleton' />
                  <SkeletonPlaceholder className='app-details-skeleton' />
                </div>
              )}
              {!loading && (
                <SidePanelDetailsCard
                  data={formatAppDetailsData()}
                  notAuthorized={
                    !permissionMap['deploymentEnv'] &&
                    !permissionMap['resourceGroup']
                  }
                  error500={!!serverErrorMap['deploymentEnv']}
                />
              )}
            </SidePanelDetailsComponent>

            <SidePanelDetailsComponent title={target?.name ?? ''}>
              {loading && (
                <div className='skeleton-container'>
                  <SkeletonPlaceholder className='app-details-skeleton' />
                  <SkeletonPlaceholder className='app-details-skeleton' />
                </div>
              )}
              {!loading && servicesData ? (
                <SidePanelTableComponent
                  headers={[
                    { key: 'port_number', header: t('port') },
                    { key: 'protocol', header: t('protocol') },
                  ]}
                  rows={servicesData.ports}
                />
              ) : !loading ? (
                <SidePanelEmptyState
                  icon={images.noServicesSmallIcon()}
                  header={t('emptyState.header')}
                  message={t('emptyState.message')}
                  btnText={t('emptyState.btnText')}
                  btnUrl={`/ApplicationDetails?appId=${target.application_id}`}
                  resourceType='SERVICE'
                  notAuthorized={!permissionMap['service']}
                  error500={!!serverErrorMap['app']}
                />
              ) : null}
            </SidePanelDetailsComponent>
          </Tab>
        </Tabs>
      </CustomSidePanel>
    </div>
  );
};

export default ApplicationService;
