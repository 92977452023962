import React from 'react';
import { Button } from 'carbon-components-react';
import './IconWithToolTip.scss';

const IconWithToolTip: React.FC<{
  iconDescription: string;
  icon: React.ReactElement;
  hasText?: boolean;
}> = ({ iconDescription, icon, hasText = false }) => {
  return (
    <div className='icon-with-tooltip'>
      <Button
        hasIconOnly
        iconDescription={iconDescription}
        kind='ghost'
        tooltipPosition='bottom'
      >
        {icon}
        {hasText && <div className='icon-text'>{iconDescription}</div>}
      </Button>
    </div>
  );
};

export default IconWithToolTip;
