import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SkeletonPlaceholder, Tab, Tabs } from 'carbon-components-react';
import { AxiosError } from 'axios';
import CustomSidePanel from '../../../CustomSidePanel/CustomSidePanel';
import SidePanelDetailsCard from '../../../CustomSidePanel/SidePanelDetailsComponent/SidePanelDetailsCard';
import SidePanelDetailsComponent from '../../../CustomSidePanel/SidePanelDetailsComponent/SidePanelDetailsComponent';
import SidePanelTableComponent from '../../../CustomSidePanel/TableComponent/SidePanelTableComponent';
import { NodeSidePaneDetailsComponentProps } from '../config';
import {
  Cloud,
  DeploymentEnvDetailsNameSpaceAndSecurityTableData,
  DeploymentEnvironment,
  NetworkSegments,
  ResourceGroup,
} from '../../../../models/master';
import IconWithToolTip from '../../../IconWithToolTip/IconWithToolTip';
import images from '../../../../images/images';
import SidePanelEmptyState from '../../../../components/CustomSidePanel/SidePanelEmptyState/SidePanelEmptyState';

import { getResourceGroups } from '../../../../controllers/resourceGroupApi';
import {
  getDeploymentEnv,
  getPartition,
} from '../../../../controllers/deploymentEnv';
import { getNetworkSegmentDetails } from '../../../../controllers/networksegmentsApi';
import { getCloud } from '../../../../controllers/cloudApis';

import './PartitionDetails.scss';
import { DEFAULT_NETWORK_SEGMENT_ID } from '../../../../lib/constants';

interface ApplicationData {
  name: string;
  application_id: string;
  resource_group_id: string;
}

type PartitionType = 'securitygroup' | 'namespace';

const defaultPermissionMap = {
  partitions: true,
  deploymentEnv: true,
  resourceGroup: true,
  app: true,
  networkSegments: true,
};

const defaultServerErrorMap = {
  partitions: '',
  deploymentEnv: '',
};

const PartitionDetails: React.FC<NodeSidePaneDetailsComponentProps> = ({
  node,
  open,
  onClose,
  sidePanelWidth,
  handleSidePanelWidth,
  handleHeaderWidth,
  initialHeaderWidth,
  headerWidth,
}) => {
  const { t } = useTranslation('topologySidepanelPartitionDetails');

  const partitionType = node?.resource_id?.split('-')[0] as PartitionType;

  const [loading, setLoading] = useState(false);
  const [partitionData, setPartitionData] =
    useState<DeploymentEnvDetailsNameSpaceAndSecurityTableData | null>(null);
  const [deploymentEnvData, setDeploymentEnvData] =
    useState<DeploymentEnvironment | null>(null);
  const [resourceGroupList, setResourceGroupList] = useState<
    ResourceGroup[] | null
  >(null);
  const [applications, setApplications] = useState<ApplicationData[] | null>(
    null
  );
  const [networkSegmentsData, setNetworkSegmentsData] =
    useState<NetworkSegments | null>(null);
  const [cloudDetailsData, setcloudDetailsData] = useState<Cloud | null>(null);
  const [permissionMap, setPermissionMap] = useState(defaultPermissionMap);
  const [serverErrorMap, setServerErrorMap] = useState(defaultServerErrorMap);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      let partition: DeploymentEnvDetailsNameSpaceAndSecurityTableData | null =
        null;

      const deplEnvId =
        partitionType === 'securitygroup' ? node['vpc_id'] : node['cluster_id'];

      try {
        partition = await getPartition(deplEnvId, node.resource_id);
        setPartitionData(partition);
      } catch (error) {
        const err = error as AxiosError;
        if (
          err?.response?.status === 403 &&
          err?.response?.statusText === 'Forbidden'
        ) {
          setPermissionMap(permissionMap => ({
            ...permissionMap,
            partitions: false,
          }));
        }

        const errorCode = err.response!?.status;
        if (errorCode >= 500) {
          setServerErrorMap(permissionMap => ({
            ...permissionMap,
            partitions: errorCode.toString(),
          }));
        }
      }

      try {
        const deploymentEnv: DeploymentEnvironment = await getDeploymentEnv(
          deplEnvId
        );
        setDeploymentEnvData(deploymentEnv);
      } catch (error) {
        const err = error as AxiosError;
        if (
          err?.response?.status === 403 &&
          err?.response?.statusText === 'Forbidden'
        ) {
          setPermissionMap(permissionMap => ({
            ...permissionMap,
            deploymentEnv: false,
          }));
        }

        const errorCode = err.response!?.status;
        if (errorCode >= 500) {
          setServerErrorMap(permissionMap => ({
            ...permissionMap,
            deploymentEnv: errorCode.toString(),
          }));
        }
      }

      try {
        const networkSegment: NetworkSegments = await getNetworkSegmentDetails(
          node?.network_segment_id
        );
        setNetworkSegmentsData(networkSegment);
      } catch (error) {
        const err = error as AxiosError;
        if (
          err?.response?.status === 403 &&
          err?.response?.statusText === 'Forbidden'
        ) {
          setPermissionMap(permissionMap => ({
            ...permissionMap,
            networkSegments: false,
          }));
        }
      }

      try {
        const cloud: Cloud = await getCloud(node?.cloud_id);
        setcloudDetailsData(cloud);
      } catch (error) {
        const err = error as AxiosError;
        if (
          err?.response?.status === 403 &&
          err?.response?.statusText === 'Forbidden'
        ) {
          setPermissionMap(permissionMap => ({
            ...permissionMap,
            cloud: false,
          }));
        }
      }

      try {
        const resourecGroups = await getResourceGroups(true);
        setResourceGroupList(resourecGroups?.resource_groups);
      } catch (error) {
        const err = error as AxiosError;
        if (
          err?.response?.status === 403 &&
          err?.response?.statusText === 'Forbidden'
        ) {
          setPermissionMap(permissionMap => ({
            ...permissionMap,
            resourceGroup: false,
          }));
        }
      }

      setDeployedApplications();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const setDeployedApplications = () => {
    const deployedApps = Array.isArray(node.children)
      ? node.children
          .filter(eachChild => eachChild._type === 'application')
          .map(data => ({
            name: data.name,
            application_id: data.application_id ?? '',
            resource_group_id: data.resource_group_id ?? '',
          }))
      : null;

    setApplications(deployedApps);
  };

  const getResourceGroupName = (resourceGroupId?: string) => {
    if (!permissionMap['resourceGroup']) {
      return t('notAuthorized');
    }

    const resourceGroupData =
      Array.isArray(resourceGroupList) && resourceGroupId
        ? resourceGroupList?.find(
            resource => resource.resource_id === resourceGroupId
          )
        : null;

    return resourceGroupData?.name;
  };

  const handleDeplEnvClick = (data: DeploymentEnvironment | null) => {
    window.open(
      window.location.origin +
        process.env.PUBLIC_URL +
        `/deploymentEnvironmentDetails?deplId=${data?.resource_id}`
    );
  };

  const handleNetSegmentEnvClick = (data: NetworkSegments | null) => {
    window.open(
      window.location.origin +
        process.env.PUBLIC_URL +
        `/networkSegmentDetails?nwSegId=${node?.network_segment_id}`
    );
  };

  const handleCloudClick = (data: Cloud | null) => {
    window.open(
      window.location.origin +
        process.env.PUBLIC_URL +
        `/cloudDetails?cloudId=${node?.cloud_id}`
    );
  };

  const getFormattedPartitionData = () => {
    const formattedData = [
      {
        label: t('networkSegment'),
        value: networkSegmentsData?.name,
        valueType:
          node?.network_segment_id === DEFAULT_NETWORK_SEGMENT_ID
            ? 'displayField'
            : 'link',
        linkCallback: () => handleNetSegmentEnvClick(networkSegmentsData),
        notAuthorized: !permissionMap['networkSegments'],
      },
      {
        label: t('autodiscover'),
        value: partitionData?.auto_discover ? (
          <div className='autodiscoverValue'>
            <div className='icon'>{images.autoDiscoverLabelIcon()}</div>
            <div className='text'>{t('applications')}</div>
          </div>
        ) : (
          <div className='autodiscoverValue'>{t('off')}</div>
        ),
        valueType: 'displayField',
        notAuthorized: !permissionMap['partitions'],
      },

      {
        label: t('cloud'),
        value: cloudDetailsData?.name,
        valueType: 'link',
        linkCallback: () => handleCloudClick(cloudDetailsData),
        notAuthorized: !permissionMap['partitions'],
      },

      {
        label: t('deploymentEnvironment'),
        value: deploymentEnvData?.name ?? '',
        valueType: 'link',
        linkCallback: () => handleDeplEnvClick(deploymentEnvData),
        notAuthorized: !permissionMap['deploymentEnv'],
      },
      {
        label: t('infrastructureGroup'),
        value: getResourceGroupName(deploymentEnvData?.resource_group_id) ?? '',
        valueType: 'displayField',
        notAuthorized:
          !permissionMap['deploymentEnv'] || !permissionMap['resourceGroup'],
      },
      {
        label: t('credentialKey'),
        value: partitionData?.credentials_key ?? '',
        valueType: 'displayField',
        notAuthorized: !permissionMap['partitions'],
      },
      {
        label: t('labels'),
        value: Array.isArray(partitionData?.labels)
          ? partitionData?.labels
          : [],
        valueType: 'labelsList',
        notAuthorized: !permissionMap['partitions'],
      },
    ];

    return formattedData;
  };

  const getFormattedAppData = () => {
    const rowsData: {
      name: JSX.Element;
      resourceGroup: string;
    }[] = [];

    let appPermission = true;

    if (Array.isArray(applications)) {
      applications.forEach(appData => {
        if (appData.name) {
          const appLink = `/ApplicationDetails?appId=${appData?.application_id}`;

          rowsData.push({
            name: (
              <div className='sidepanel-table-link'>
                <Link
                  to={appLink}
                  target='_blank'
                  className='no-underline-link hyperLink'
                >
                  {appData.name}
                </Link>
              </div>
            ),
            resourceGroup:
              getResourceGroupName(appData?.resource_group_id) ?? '',
          });
        } else {
          appPermission = false;
        }
      });
    }

    if (!appPermission && permissionMap['app']) {
      setPermissionMap(permissionMap => ({
        ...permissionMap,
        app: false,
      }));
    }

    return rowsData;
  };

  const getHeaderTitle = () => {
    if (partitionData?.is_discovered) {
      return (
        <div className='header-title'>
          <span>
            {images.partitionIcon()} {node.name}{' '}
          </span>
          <span className='page-title-icon'>
            <IconWithToolTip
              icon={images.AutoDiscoverdLockIcon()}
              iconDescription={t('autoDiscoveredAccessLimited')}
            />
          </span>
        </div>
      );
    }

    if (node) {
      return (
        <div>
          {images.partitionIcon()} {node.name}
        </div>
      );
    }

    return '';
  };

  const getLink = () => {
    if (node) {
      const partitionLink =
        partitionType === 'securitygroup'
          ? `/partitionDetails?depEnvId=${node?.vpc_id}&partitionId=${node?.resource_id}`
          : `/partitionDetails?depEnvId=${node?.cluster_id}&partitionId=${node?.resource_id}`;

      return partitionLink;
    }

    return '';
  };

  const title = getHeaderTitle();
  const link = getLink();
  const formattedAppData = getFormattedAppData();

  return (
    <div className='topology-partition-details-container'>
      <CustomSidePanel
        open={open}
        onClose={onClose}
        title={title}
        link={link}
        sidePanelWidth={sidePanelWidth}
        handleSidePanelWidth={handleSidePanelWidth}
        handleHeaderWidth={handleHeaderWidth}
        initialHeaderWidth={initialHeaderWidth}
        headerWidth={headerWidth}
      >
        <Tabs>
          <Tab id='topology-details-tab-1' label={t('details')}>
            <SidePanelDetailsComponent
              title={t('partitionDetails', {
                name:
                  partitionType === 'securitygroup'
                    ? t('securityGroup')
                    : t('namespace'),
              })}
            >
              {loading && (
                <div className='skeleton-container'>
                  <SkeletonPlaceholder className='app-details-skeleton' />
                  <SkeletonPlaceholder className='app-details-skeleton' />
                </div>
              )}
              {!loading && (
                <SidePanelDetailsCard
                  data={getFormattedPartitionData()}
                  notAuthorized={
                    !permissionMap['partitions'] &&
                    !permissionMap['deploymentEnv']
                  }
                  error500={
                    !!serverErrorMap['partitions'] ||
                    !!serverErrorMap['deploymentEnv']
                  }
                />
              )}
            </SidePanelDetailsComponent>

            <SidePanelDetailsComponent
              title={`${t('applications')} ${
                Array.isArray(formattedAppData) && formattedAppData.length
                  ? '(' + formattedAppData.length + ')'
                  : ''
              }`}
            >
              {loading && (
                <div className='skeleton-container'>
                  <SkeletonPlaceholder className='app-details-skeleton' />
                  <SkeletonPlaceholder className='app-details-skeleton' />
                </div>
              )}
              {!loading &&
              Array.isArray(formattedAppData) &&
              formattedAppData.length > 0 ? (
                <SidePanelTableComponent
                  headers={[
                    { key: 'name', header: t('name') },
                    { key: 'resourceGroup', header: t('applicationGroup') },
                  ]}
                  rows={formattedAppData}
                />
              ) : !loading ? (
                <SidePanelEmptyState
                  icon={images.SidePanelNoApplicationIcon()}
                  header={t('emptyState.header')}
                  message={t('emptyState.message')}
                  notAuthorized={
                    !permissionMap['app'] || !permissionMap['resourceGroup']
                  }
                />
              ) : null}
            </SidePanelDetailsComponent>
          </Tab>
        </Tabs>
      </CustomSidePanel>
    </div>
  );
};

export default PartitionDetails;
