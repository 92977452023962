import React from 'react';
import { CreateTearsheetStep } from '@carbon/ibm-products';
import {
  Column,
  Row,
  TextInput,
  TextArea,
  ComboBox,
  TooltipDefinition,
  Checkbox,
  SkeletonPlaceholder,
} from 'carbon-components-react';
import { useTranslation } from 'react-i18next';

import AddLabels from '../../../components/AddLabels/AddLabels';
import { NetworkSegments, ResourceGroup } from '../../../models/master';
import images from '../../../images/images';

import './AddDetails.scss';
import { NetworkSegmentCompatibilitySet } from '../../../lib/enums';

interface DefaultResourceGroup {
  resource_id: string;
  name: string;
}

export interface FormDataStep1 {
  name: {
    value: string;
    error: boolean;
    errorMessage: string;
  };
  description: {
    value: string;
    error: boolean;
    errorMessage: string;
  };
  resourceGroup: {
    value: ResourceGroup | null | DefaultResourceGroup;
    error: boolean;
    errorMessage: string;
  };
  labels: {
    value: string[];
    error: boolean;
    errorMessage: string;
  };
  resourceGroupList: {
    resource_id: string;
    name: string;
    description: string;
    type: string;
  }[];
  nwSegmentList: NetworkSegments[];
  networkSegment: {
    value: NetworkSegments | null;
    error: boolean;
    errorMessage: string;
  };
}

interface Props {
  formData: FormDataStep1;
  onChange: (e: { target: { name: string; value: any } }) => void;
  checkFormValid: boolean;
  isNwSegmentListLoading: boolean;
}

const AddDetails: React.FC<Props> = ({
  formData,
  onChange,
  checkFormValid,
  children,
  isNwSegmentListLoading,
}) => {
  const {
    name,
    description,
    resourceGroup,
    resourceGroupList,
    labels,
    nwSegmentList,
    networkSegment,
  } = formData;
  const { t } = useTranslation('createPolicy');

  return (
    <>
      {/* Render error snackbar */}
      {children}

      <CreateTearsheetStep
        className='connection-access-policy-add-details'
        title={t('addDetails.title')}
        subtitle={t('addDetails.subtitle')}
        disableSubmit={!checkFormValid}
      >
        <Row className='row'>
          <Column md={4}>
            <TextInput
              labelText={t('addDetails.name') as string}
              id='create-policy-name'
              value={name.value}
              name='name'
              placeholder={t('addDetails.PolicyNamePlaceholder')}
              onChange={onChange}
              invalid={name.error}
              invalidText={name.errorMessage}
              autoComplete='off'
              maxLength={60}
            />
          </Column>
          {/* Note : Remove Resource Group for Experimental release.*/}
          <Column md={4} sm={5} className='add-resource-group-col'>
            {/* <Select
              onChange={onChange}
              id='create-policy-resourceGroup'
              value={resourceGroup.value}
              name='resourceGroup'
              labelText={t('addDetails.appGroup')}
            >
              <SelectItem
                disabled
                hidden
                value={''}
                text={t('addDetails.resourceGroupText')}
              />
              {resourceGroupList?.map(item => {
                return (
                  <SelectItem
                    key={item.resource_id}
                    value={item.resource_id}
                    text={item.name}
                  />
                );
              })}
            </Select> */}
            <ComboBox
              id='create-policy-resourceGroup'
              name='resourceGroup'
              className='form-field'
              selectedItem={resourceGroup?.value as any}
              onChange={data =>
                onChange({
                  target: { name: 'resourceGroup', value: data.selectedItem },
                })
              }
              translateWithId={t}
              items={resourceGroupList ?? []}
              itemToString={item => (item ? item.name : '')}
              titleText={t('addDetails.appGroup')}
              placeholder={t('addDetails.resourceGroupText')}
              invalid={formData.resourceGroup.error}
              invalidText={formData.resourceGroup.errorMessage}
              data-testid={'add-resource-group-combo-box'}
            />
          </Column>
        </Row>
        <Row className='row'>
          <Column md={4} className='add-network-segment-col'>
            {isNwSegmentListLoading ? (
              <>
                <div className='form-field'>
                  {t('addDetails.networkSegment')}
                </div>
                <SkeletonPlaceholder className='create-policy-segment-skelton' />
              </>
            ) : (
              <ComboBox
                id='create-policy-networkSegment'
                name='networkSegment'
                className='form-field'
                selectedItem={networkSegment?.value as any}
                onChange={data =>
                  onChange({
                    target: {
                      name: 'networkSegment',
                      value: data.selectedItem,
                    },
                  })
                }
                translateWithId={t}
                items={nwSegmentList ?? []}
                itemToString={item =>
                  item ? `${item.name} (${t(item?.compatibility_set)})` : ''
                }
                titleText={t('addDetails.networkSegment')}
                placeholder={t('addDetails.networkSegmentPlaceholder')}
                invalid={formData.networkSegment.error}
                invalidText={formData.networkSegment.errorMessage}
                data-testid={'add-network-segment-combo-box'}
              />
            )}
          </Column>
        </Row>
        <Row className='row'>
          <Column md={8}>
            <AddLabels
              id='create-policy-labels'
              labelText={t('addDetails.labelName')}
              placeholder={t('addDetails.labelPlaceholder')}
              onChange={data =>
                onChange({ target: { name: 'labels', value: data } })
              }
              btnText={t('addDetails.labelbtnText')}
              btnKind='secondary'
              tagType='green'
              defaultValues={labels.value}
            />
          </Column>
        </Row>
        <Row className='row'>
          <Column md={8}>
            <TextArea
              id='create-policy-desciption'
              name='description'
              autoComplete='off'
              labelText={t('addDetails.description')}
              placeholder={t('addDetails.descriptionPlaceholder')}
              onChange={onChange}
              value={description.value}
              maxCount={300}
              enableCounter={true}
            />
          </Column>
        </Row>
      </CreateTearsheetStep>
    </>
  );
};

export default AddDetails;
