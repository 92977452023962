import { axiosInstance } from './api';
import { MCNM_NETWORK_API_URL } from './config';

export const getGateways = async (view = 'all') => {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/gateway?visibility=${view}`,
    });
    return response?.data?.gateways || [];
  } catch (error) {
    console.log('api error: ', error);
    throw error;
  }
};
