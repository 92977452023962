import React from 'react';
import images from '../../images/images';
import './AutoDiscoverLabel.scss';

interface Props {
  label?: string[] | undefined;
}

const AutoDiscoverLabel: React.FC<Props> = ({ label }) => {
  return (
    <div className='auto-discover-label'>
      {label
        ? label?.map((item: string, index) => {
            return (
              <div className='auto-discover-label-item' key={index}>
                <div>{images.autoDiscoverLabelIcon()}</div>
                <div className='value'>{item}</div>
              </div>
            );
          })
        : null}
    </div>
  );
};

export default AutoDiscoverLabel;
