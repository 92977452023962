import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getResourceGroups } from '../../../controllers/resourceGroupApi.js';
import InlineNotification from '../../../components/Notifications/Inline/Notification';
import { NotificationContext } from '../../../components/Notifications/Context/NotificationProvider';
import { removeExtraSpace } from '../../../lib/utils';
import { AxiosError } from 'axios';

import './RegisterSecret.scss';
import CreateSecretForm from './CreateSecretForm';
import WideTearsheet from '../../../components/WideTearsheet/WideTearsheet';
import {
  addSecret,
  getSecretManagerConfiguration,
  updateSecret,
} from '../../../controllers/tenantApis';
import { typeList, secretManagerList } from '../constants';
import { Secret, SecretManagerConfig } from '../../../models/master.js';
import { SkeletonPlaceholder } from 'carbon-components-react';
import useAnalytics from '../../../lib/useAnalytics';
import analyticsData from '../../../lib/analyticsEventData.js';
import { ResourceGroupTypes } from '../../../lib/enums';

export const secretStorageTypes = ['external', 'internal'];

const defaultFormValue = {
  name: {
    value: '',
    error: false,
    errorMessage: '',
  },
  resourceGroup: {
    value: {
      resource_id: 'default-infra',
      name: 'Default_Infrastructure_Group',
    },
    error: false,
    errorMessage: '',
  },
  secretManagerType: {
    value: secretManagerList[0],
    error: false,
    errorMessage: '',
  },
  secretType: {
    value: null,
    error: false,
    errorMessage: '',
  },
  apiKey: {
    value: '',
    error: false,
    errorMessage: '',
  },
  accessKeyId: {
    value: '',
    error: false,
    errorMessage: '',
  },
  secretAccessKey: {
    value: '',
    error: false,
    errorMessage: '',
  },
  path: {
    value: '',
    error: false,
    errorMessage: '',
  },
  k8sCA: {
    value: '',
    error: false,
    errorMessage: '',
  },
  k8sClientKey: {
    value: '',
    error: false,
    errorMessage: '',
  },
  k8sClientCert: {
    value: '',
    error: false,
    errorMessage: '',
  },
  k8sClientToken: {
    value: '',
    error: false,
    errorMessage: '',
  },
};

interface Props {
  open: boolean;
  onClose: () => void;
  onSecretCreate: () => void;
  mode?: 'ADD' | 'EDIT';
  secretData?: any;
  secrets: Secret[] | null;
  fromPage?: string;
}

const CreateSecret: React.FC<Props> = ({
  open,
  onClose,
  onSecretCreate,
  mode,
  secretData,
  secrets,
  fromPage = '',
}) => {
  const [formData, setFormData] = useState<any>(defaultFormValue);
  const [resourceGroupList, setResourceGroupList] = useState<any>([]);
  const [showFailNotification, toggleFailNotification] = useState(false);
  const { t } = useTranslation('secretManager');
  const notification = useContext(NotificationContext);
  const [authError, setAuthError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialFormDataEdit, setInitialFormDataEdit] =
    useState<any>(defaultFormValue);
  const [subTitleErrorMsg, setSubTitleErrorMsg] = useState('');
  const [secretManagerConfig, setSecretManagerConfig] =
    useState<SecretManagerConfig | null>(null);
  const [secretManagerError, setSecretManagerError] = useState<any>(null);
  const [secretManagerLoading, setSecretManagerLoading] = useState(false);
  const [strategyMismatch, setStrategyMismatch] = useState(false);
  const [strategyErrorMessage, setStrategyErrorMessage] = useState('');
  const navigate = useNavigate();
  const { trackButtonClicked } = useAnalytics();

  useEffect(() => {
    if (open) {
      let getInfraResourceGroup;
      getResourceGroups()
        .then(response => {
          getInfraResourceGroup = response.resource_groups.filter(
            (resourceGroup: any) =>
              resourceGroup.type === ResourceGroupTypes?.INFRASTRUCTURE
          );
          setResourceGroupList(getInfraResourceGroup);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      getSecretManagerConfig();
    }
  }, [open]);

  const getSecretManagerConfig = async () => {
    setSecretManagerLoading(true);
    try {
      const secretManagerConfiguration = await getSecretManagerConfiguration();
      if (mode === 'EDIT') {
        if (
          secretData.secrets_manager_type !==
          secretManagerConfiguration?.sm_type_strategy
        ) {
          setStrategyMismatch(true);
          secretManagerConfiguration?.sm_type_strategy === 'internal'
            ? setStrategyErrorMessage(t('disableEdit.interalStrategyisSet'))
            : setStrategyErrorMessage(t('disableEdit.externalStrategyisSet'));
        }
      }
      setSecretManagerConfig(secretManagerConfiguration);
      setSecretManagerError(null);
    } catch (error) {
      const err = error as AxiosError;
      console.log(err?.response);
      setSecretManagerError(err);
    } finally {
      setSecretManagerLoading(false);
    }
  };

  useEffect(() => {
    if (mode === 'EDIT') updateFomData();
  }, [secretData, resourceGroupList]);

  const updateFomData = () => {
    const typeValue = typeList.find(type => type.value === secretData?.type);
    const backendType = secretManagerList.find(
      backend => backend.value === secretData?.backend
    );
    const resourceName = resourceGroupList?.find(
      (rg: any) =>
        rg.resource_id === (secretData?.resource_group_id ?? 'default-infra')
    );
    const { name, resourceGroup, secretType, secretManagerType, path } =
      formData;
    const fData = {
      ...formData,
      name: {
        ...name,
        value: secretData?.name,
      },
      resourceGroup: {
        ...resourceGroup,
        value: {
          resource_id: secretData?.resource_group_id ?? 'default-infra',
          name: resourceName
            ? resourceName.name
            : 'Default_Infrastructure_Group',
        },
      },
      secretType: {
        ...secretType,
        value: typeValue ? typeValue : null,
      },
      secretManagerType: {
        ...secretManagerType,
        value: backendType
          ? backendType
          : secretData.secrets_manager_type === secretStorageTypes[0]
          ? secretManagerList[1]
          : secretManagerList[0],
      },
      apiKey: {
        value: '',
        error: false,
        errorMessage: '',
      },
      accessKeyId: {
        value: '',
        error: false,
        errorMessage: '',
      },
      secretAccessKey: {
        value: '',
        error: false,
        errorMessage: '',
      },
      // path: {
      //   ...path,
      //   value: secretData?.path,
      // },
    };
    setInitialFormDataEdit(fData);
    setFormData(fData);
  };

  const checkKeyCertExist = (cert: any, key: any) => {
    return (key && !cert) || (cert && !key);
  };

  const checkIfKubernetesValid = () => {
    let mandatoryFields = ['name', 'resourceGroup', 'secretType'];
    for (const field of mandatoryFields) {
      const value = formData[field].value;
      const trimmedValue = typeof value === 'string' ? value.trim() : value;
      if (checkFieldValidation(field, trimmedValue)) {
        return false;
      }
    }
    if (mode === 'EDIT') return true;
    else {
      const valid =
        (!checkFieldValidation('k8sClientKey', formData?.k8sClientKey?.value) &&
          !checkFieldValidation(
            'k8sClientCert',
            formData?.k8sClientCert?.value
          )) ||
        !checkFieldValidation(
          'k8sClientToken',
          formData?.k8sClientToken?.value
        );
      const keyCertError = checkKeyCertExist(
        formData?.k8sClientCert?.value,
        formData?.k8sClientKey?.value
      );
      return valid && !keyCertError;
    }
  };

  const isFormValid = () => {
    if (
      formData?.secretType?.value &&
      formData?.secretType?.value?.value === 'credentials-k8s' &&
      secretManagerConfig?.sm_type_strategy === secretStorageTypes[1]
    )
      return checkIfKubernetesValid();
    else {
      let mandatoryFields = ['name', 'resourceGroup', 'secretType'];
      if (
        mode !== 'EDIT' &&
        secretManagerConfig?.sm_type_strategy === secretStorageTypes[0]
      )
        mandatoryFields = ['name', 'resourceGroup', 'secretType', 'path'];
      else if (
        mode === 'EDIT' &&
        secretData.secrets_manager_type === secretStorageTypes[0]
      )
        mandatoryFields = ['name', 'resourceGroup', 'secretType'];
      else if (
        mode === 'EDIT' &&
        secretData.secrets_manager_type === secretStorageTypes[1] &&
        !formData.accessKeyId.value &&
        !formData.secretAccessKey.value
      )
        mandatoryFields = [
          'name',
          'resourceGroup',
          'secretType',
          'secretManagerType',
        ];
      else {
        if (formData.secretType?.value?.value === 'cloud-ibm')
          mandatoryFields = [
            'name',
            'resourceGroup',
            'secretType',
            'secretManagerType',
            'apiKey',
          ];
        else {
          mandatoryFields = [
            'name',
            'resourceGroup',
            'secretType',
            'secretManagerType',
            'accessKeyId',
            'secretAccessKey',
          ];
        }
      }
      for (const field of mandatoryFields) {
        const value = formData[field].value;
        const trimmedValue = typeof value === 'string' ? value.trim() : value;
        if (checkFieldValidation(field, trimmedValue)) {
          return false;
        }
      }
      return true && !secretManagerError;
    }
  };

  const cancelForm = () => {
    setFormData(null);
    setFormData(defaultFormValue);
    toggleFailNotification(false);
    onClose();
    setStrategyErrorMessage('');
    setStrategyMismatch(false);
  };

  const checkFieldValidation = (name: string, value: any) => {
    let errorMessage = '';
    const secretPathRegExp =
      /^(https?:\/\/)(www.)?(?!.*www)([\da-z][\da-z\.-]*)\.([a-z\.]{2,6})(?::\d{1,5})?(?!.*\/\/)([\/][\/a-z0-9A-Z-._~!$&'()*+,;=:@%?#]{1,})\/?$/;
    const secretNameRegExp =
      /^[A-Za-z0-9][A-Za-z0-9]*(?:_*-*\.*[A-Za-z0-9]+)*$/;

    switch (name) {
      case 'name':
        const valueEmpty = value === '';
        const notUnique =
          secretData?.name.trim() === value.trim()
            ? false
            : secrets?.find(
                (secret: { name: string }) =>
                  secret.name.trim() === value.trim()
              )
            ? true
            : false;

        const regexNameTest = secretNameRegExp.test(value);
        const error = valueEmpty || notUnique || !regexNameTest;
        const message = valueEmpty
          ? t('validation.name.required')
          : notUnique
          ? t('validation.name.notUnique')
          : !regexNameTest
          ? t('validation.name.invalid')
          : '';
        errorMessage = error ? message : '';
        break;
      case 'resourceGroup':
        errorMessage = !value ? t('validation.resourceGroup.required') : '';
        break;
      case 'secretType':
        errorMessage = !value ? t('validation.secretType.required') : '';
        break;
      case 'secretManagerType':
        errorMessage = !value ? t('validation.secretManagerType.required') : '';
        break;
      case 'apiKey':
        errorMessage = !value ? t('validation.apiKey.required') : '';
        break;
      case 'accessKeyId':
        errorMessage =
          (!value && mode !== 'EDIT') ||
          (mode === 'EDIT' && !value && formData?.secretAccessKey.value)
            ? t('validation.accessKeyId.required')
            : '';
        break;
      case 'secretAccessKey':
        errorMessage =
          (!value && mode !== 'EDIT') ||
          (mode === 'EDIT' && !value && formData?.accessKeyId.value)
            ? t('validation.secretAccessKey.required')
            : '';
        break;
      case 'k8sClientKey':
        errorMessage = !value ? t('validation.k8sClientKey.required') : '';
        break;
      case 'k8sClientCert':
        errorMessage = !value ? t('validation.k8sClientCert.required') : '';
        break;
      case 'k8sClientToken':
        errorMessage = !value ? t('validation.k8sClientToken.required') : '';
        break;
      case 'path':
        const regexPathTest = secretPathRegExp.test(value);
        errorMessage = !value
          ? t('validation.path.required')
          : !regexPathTest
          ? t('validation.path.invalid')
          : '';
        break;
    }
    return errorMessage;
  };

  const checkValueChanged = () => {
    let changed = false;
    Object.keys(formData).map(key => {
      if (key === 'resourceGroup') {
        if (
          formData[key].value?.resource_id !==
          initialFormDataEdit[key].value?.resource_id
        )
          changed = true;
      } else {
        if (formData[key].value !== initialFormDataEdit[key].value)
          changed = true;
      }
      return null;
    });
    return changed;
  };

  const handleOnChange = (name: string, value: any) => {
    // if (!changed) setChanged(true);
    const errorMessage = checkFieldValidation(name, value);
    if (
      name === 'secretType' &&
      formData.secretType?.value?.value !== value &&
      secretManagerConfig?.sm_type_strategy === secretStorageTypes[0]
    ) {
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: {
          value,
          error: !!errorMessage,
          errorMessage,
        },
        apiKey: {
          value: '',
          error: false,
          errorMessage: '',
        },
        accessKeyId: {
          value: '',
          error: false,
          errorMessage: '',
        },
        secretAccessKey: {
          value: '',
          error: false,
          errorMessage: '',
        },
      }));
    } else if (name === 'k8sClientKey' || name === 'k8sClientCert') {
      const keyVal =
        name === 'k8sClientKey' ? value : formData?.k8sClientKey?.value;
      const certVal =
        name === 'k8sClientCert' ? value : formData?.k8sClientCert?.value;
      const error = checkKeyCertExist(certVal, keyVal);
      const keyErrorMessage = checkFieldValidation('k8sClientKey', keyVal);
      const certErrorMessage = checkFieldValidation('k8sClientCert', certVal);
      setFormData((prevState: any) => ({
        ...prevState,
        k8sClientKey: {
          value: keyVal,
          error: !keyErrorMessage && error,
          errorMessage: keyErrorMessage,
        },
        k8sClientCert: {
          value: certVal,
          error: !certErrorMessage && error,
          errorMessage: certErrorMessage,
        },
      }));
    } else {
      if (mode === 'EDIT') {
        if (name === 'secretAccessKey' && !value)
          setFormData((prevState: any) => ({
            ...prevState,
            accessKeyId: {
              value: formData?.accessKeyId?.value,
              error: false,
              errorMessage: '',
            },
          }));
        if (name === 'accessKeyId' && !value)
          setFormData((prevState: any) => ({
            ...prevState,
            secretAccessKey: {
              value: formData?.secretAccessKey?.value,
              error: false,
              errorMessage: '',
            },
          }));
      }
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: {
          value,
          error: !!errorMessage,
          errorMessage,
        },
      }));
    }
  };

  const handleSubmit = async () => {
    try {
      trackButtonClicked(
        analyticsData['Admin Secrets'].events.registerSecretBtn.props,
        analyticsData['Admin Secrets'].events.registerSecretBtn.event
      );
      let secret_data: Secret = {
        backend: formData?.secretManagerType?.value?.value ?? '',
        name: removeExtraSpace(formData?.name?.value),
        resource_group_id: formData?.resourceGroup?.value.resource_id,
        type: formData?.secretType?.value?.value ?? '',
      };

      if (
        mode !== 'EDIT' &&
        secretManagerConfig?.sm_type_strategy === secretStorageTypes[0]
      )
        secret_data['path'] = formData?.path?.value;
      else if (
        mode === 'EDIT' &&
        secretData.secrets_manager_type === secretStorageTypes[0] &&
        formData?.path?.value
      ) {
        secret_data['path'] = formData?.path?.value;
      } else {
        if (
          formData.secretType?.value?.value === 'cloud-ibm' &&
          formData?.apiKey.value
        )
          secret_data['secret'] = {
            apikey: formData?.apiKey.value,
          };
        else if (
          formData.secretType?.value?.value === 'credentials-k8s' &&
          mode !== 'EDIT'
        ) {
          const secretData = {
            k8sCA: formData?.k8sCA?.value,
            k8sClientKey: formData?.k8sClientKey?.value,
            k8sClientCert: formData?.k8sClientCert?.value,
            k8sClientToken: formData?.k8sClientToken?.value,
          };
          secret_data['secret'] = secretData;
        } else {
          if (
            formData?.accessKeyId.value &&
            formData?.secretAccessKey.value &&
            formData.secretType?.value?.value !== 'credentials-k8s'
          )
            secret_data['secret'] = {
              accessKeyId: formData?.accessKeyId.value,
              secretAccessKey: formData?.secretAccessKey.value,
            };
        }
      }
      setLoading(true);

      if (mode === 'ADD') {
        const secret = await addSecret(secret_data);
        if (fromPage === 'CLOUD') {
          notification.onTrigger('ACTION', {
            title: t('successNotificationCloud.title'),
            subtitle: t('successNotificationCloud.subtitle', {
              name: secret_data.name,
            }),
            action: t('successNotificationCloud.button'),
            onActionClick: () => {
              navigate('/clouds', {
                state: { resourceType: 'CLOUD' },
              });
            },
          });
        } else
          notification.onTrigger('TOAST', {
            title: t('successNotification.title'),
            subtitle: t('successNotification.subtitle', {
              name: secret_data.name,
            }),
          });
        setFormData(defaultFormValue);
      } else if (mode === 'EDIT') {
        secret_data['secrets_manager_type'] = secretData.secrets_manager_type;
        const secretName = removeExtraSpace(formData?.name?.value);
        const updatedSecret = await updateSecret(secret_data, secretName);
        notification.onTrigger('TOAST', {
          title: t('successEditNotification.title'),
          subtitle: t('successEditNotification.subtitle', {
            name: updatedSecret.name,
          }),
        });
      }

      // close modal and fetch new data in parent
      // TODO: navigate to card view when functionality is added
      onSecretCreate();
    } catch (error: any) {
      const err = error as AxiosError;
      const errorMessage: string =
        error.response !== undefined
          ? error.response['customErrorMessage']
          : '';
      if (err.response?.status === 403) {
        toggleFailNotification(true);
        setAuthError(true);

        // We added this project.reject to prevent the modal closing when error occured.
        // But in latest version of '@carbon/ibm-products' the below line is not required and is throwing error.
        // return Promise.reject(() => console.log(error));
      }
      errorMessage.length > 0 && setSubTitleErrorMsg(errorMessage);

      console.log(error);
      toggleFailNotification(true);
      // We added this project.reject to prevent the modal closing when error occured.
      // But in latest version of '@carbon/ibm-products' the below line is not required and is throwing error.
      // return Promise.reject(() => console.log(error));
    } finally {
      setLoading(false);
      setStrategyErrorMessage('');
      setStrategyMismatch(false);
    }
  };

  const handleErrorBarClose = () => {
    toggleFailNotification(false);
    setAuthError(false);
    setSubTitleErrorMsg('');
  };

  return (
    <WideTearsheet
      className='create-secret-tearsheet'
      title={mode === 'ADD' ? t('addFormTitle') : t('editFormTitle')}
      description={
        mode === 'ADD' ? t('addFormDescription') : t('editDescription')
      }
      open={open}
      actions={[
        {
          kind: 'primary',
          label: mode === 'ADD' ? t('addBtn') : t('editButtonText'),
          onClick: () => handleSubmit(),
          disabled:
            mode === 'ADD'
              ? !isFormValid()
              : !(isFormValid() && checkValueChanged() && !strategyMismatch),
          loading: loading,
        },
        {
          kind: 'secondary',
          label: t('cancelButtonText'),
          onClick: () => {
            cancelForm();
            toggleFailNotification(false);
            setAuthError(false);
            setSubTitleErrorMsg('');
          },
        },
      ]}
    >
      <div id='register-secret-container'>
        {showFailNotification && (
          <InlineNotification
            onClose={() => handleErrorBarClose() as any}
            kind={'error'}
            title={
              authError
                ? (t('failureNotification.authTitle') as string)
                : mode === 'ADD'
                ? (t('failureNotification.title') as string)
                : (t('failureEditNotification.title') as string)
            }
            subtitle={
              authError
                ? (t('failureNotification.authSubtitle') as string)
                : subTitleErrorMsg.length > 0
                ? subTitleErrorMsg
                : mode === 'ADD'
                ? (t('failureNotification.subtitle') as string)
                : (t('failureEditNotification.subtitle') as string)
            }
          />
        )}
        {secretManagerError && (
          <div>
            <InlineNotification
              kind='error'
              aria-label=''
              title={t('secretManagerFailed')}
              subtitle={
                secretManagerError.response?.status === 403
                  ? t('authSecretSubtitle')
                  : secretManagerError.response?.customErrorMessage ?? ''
              }
              hideCloseButton={true}
              lowContrast
            />
          </div>
        )}
        {strategyMismatch && (
          <div>
            <InlineNotification
              kind='error'
              aria-label=''
              title={t('disableEdit.error')}
              subtitle={strategyErrorMessage}
              hideCloseButton={true}
              lowContrast
            />
          </div>
        )}
        {secretManagerLoading ? (
          <div className='data-loader'>
            <div className='skeleton-row'>
              <SkeletonPlaceholder className='secret-skeleton' />
              <SkeletonPlaceholder className='secret-skeleton' />
            </div>
            <div className='skeleton-row'>
              <SkeletonPlaceholder className='secret-skeleton' />
              <SkeletonPlaceholder className='secret-skeleton' />
            </div>
            <div className='skeleton-row'>
              <SkeletonPlaceholder className='secret-skeleton' />
              <SkeletonPlaceholder className='secret-skeleton' />
            </div>
          </div>
        ) : (
          <CreateSecretForm
            formData={formData}
            onChange={handleOnChange}
            resourceGroupList={resourceGroupList}
            modeType={mode}
            secretManagerConfig={
              mode === 'EDIT'
                ? secretData.secrets_manager_type
                : secretManagerConfig?.sm_type_strategy
            }
          />
        )}
      </div>
    </WideTearsheet>
  );
};

export default CreateSecret;
