import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  TextInput,
  RadioButtonGroup,
  RadioButton,
  TooltipDefinition,
  TextArea,
} from 'carbon-components-react';

import { RGForm } from './ResourceGroupsContainer';

const ResourceGroupForm = (props: {
  handleFormUpdate: (name: string, value: any) => void;
  formState: RGForm;
  editMode: boolean;
}) => {
  const { t } = useTranslation('resourceGroupsContainer');
  const { handleFormUpdate, formState, editMode } = props;
  return (
    <div className='add-rg-form'>
      <TextInput
        className='rg-name-input'
        labelText={t('modal.name.label') as string}
        id='rg-name-input'
        placeholder={t('modal.name.placeholder')}
        invalid={formState.name.invalid}
        invalidText={t('modal.name.invalid')}
        value={formState.name.value}
        onChange={e => handleFormUpdate('name', e.target.value)}
        maxLength={60}
      />
      <RadioButtonGroup
        legendText={t('modal.type.label')}
        name='group-type'
        className='rg-type-input'
        onChange={value => handleFormUpdate('type', value)}
        valueSelected={formState.type.value}
      >
        <RadioButton
          labelText={
            <TooltipDefinition
              className='infrastructure-tooltip'
              tooltipText={`${t('modal.type.infraTooltip') as string} ${
                editMode ? t('modal.type.typeDisabledTooltip') : ''
              }`}
            >
              {t('modal.type.infrastructure')}
            </TooltipDefinition>
          }
          value='infrastructure'
          id='infrastructure-radio-button'
          disabled={editMode}
        />
        <RadioButton
          labelText={
            <TooltipDefinition
              className='apps-tooltip'
              tooltipText={`${t('modal.type.appsTooltip') as string} ${
                editMode ? t('modal.type.typeDisabledTooltip') : ''
              }`}
            >
              {t('modal.type.apps')}
            </TooltipDefinition>
          }
          value='application'
          id='apps-radio-button'
          disabled={editMode}
        />
      </RadioButtonGroup>
      <TextArea
        className='rg-description-input'
        labelText={t('modal.description.label')}
        maxCount={300}
        placeholder={t('modal.description.placeholder')}
        enableCounter={true}
        value={formState.description}
        onChange={e => handleFormUpdate('description', e.target.value)}
      />
    </div>
  );
};

export default ResourceGroupForm;
