import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header/Header';
import images from '../../images/images';
import dateUtils from '../../lib/dates';
import { Error500Type, EventData } from '../../models/master';
import {
  deleteEvent,
  getEvent,
  updateEvent,
} from '../../controllers/eventsApis';
import { Launch16, Edit16 } from '@carbon/icons-react';

import {
  Button,
  Column,
  InlineNotificationProps,
  Modal,
  SkeletonPlaceholder,
  Tile,
  ButtonSkeleton,
} from 'carbon-components-react';

import { ComboButton, ComboButtonItem } from '@carbon/ibm-products';
import './EventDetails.scss';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { NotificationContext } from '../../components/Notifications/Context/NotificationProvider';
import Notification from '../../components/Notifications/Inline/Notification';
import LabelTag from '../../components/LabelTag/LabelTag';
import EditEvent from '../EditEvent/EditEvent';
import { AxiosError } from 'axios';
import useAnalytics from '../../lib/useAnalytics';
import {
  removeFiltersFromStorage,
  removeViewStateFromStorage,
} from '../../lib/utils';
import analyticsData from '../../lib/analyticsEventData';
import GenericResponsiveMiddleTruncation from '../../components/GenericResponsiveMiddleTruncation/GenericResponsiveMiddleTruncation';
import { VisibilityFlags, eventSeverity } from '../../lib/enums';
import Error500 from '../Errors/Error500';
import { getResourceTypeIcon, resourceTypeIcon } from '../Events/config';
import { getAllLocations } from '../../controllers/locationApis';

const EventDetails = () => {
  const { t } = useTranslation('eventDetails');
  const [eventDetailsData, setEventDetailsData] = useState<EventData | null>(
    null
  );
  const [eventDetailsDataLoading, toogleEventDetailsDataLoading] =
    useState(false);
  const [resourceLink, setResourceLink] = useState('');

  const [deleteEventOpenModal, toggleDeleteEventOpenModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [notificationData, setNotificationData] =
    useState<InlineNotificationProps>({} as InlineNotificationProps);
  const [openEditModal, setOpenEditModal] = useState(false);
  const notification = useContext(NotificationContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const eventId = searchParams.get('eventId');
  const [comboButtonText, setComboButtonText] = useState<string>();
  const [openCloseModal, setOpenCloseModal] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState(false);
  const [error500, setError500] = useState<null | Error500Type>(null);

  const navigate = useNavigate();
  const { trackButtonClicked, pageViewed } = useAnalytics();

  const fetchEventsDetails = async () => {
    toogleEventDetailsDataLoading(true);
    try {
      let resEventDetails: any;

      try {
        resEventDetails = await getEvent(eventId);
        setEventDetailsData(resEventDetails);
      } catch (error) {
        const err = error as AxiosError;
        if (err.response?.status === 404) {
          navigate('/404');
        }

        if (err.response!?.status >= 500) {
          setError500(err.response!?.status?.toString() as Error500Type);
        }
      }

      switch (resEventDetails?.resource_type) {
        case 'vpc':
        case 'node':
        case 'deploymentenv':
        case 'cluster': {
          let link = `/deploymentEnvironmentDetails?deplId=${resEventDetails?.resource_instance}`;
          setResourceLink(link);
          break;
        }
        case 'app': {
          let link = `/applicationDetails?appId=${resEventDetails?.resource_instance}`;
          setResourceLink(link);
          break;
        }
        case 'cloud': {
          let link = `/cloudDetails?cloudId=${resEventDetails?.resource_instance}`;
          setResourceLink(link);
          break;
        }
        case 'policy': {
          let link = `/connectionaccesspolicydetails?policyId=${resEventDetails?.resource_instance}`;
          setResourceLink(link);
          break;
        }
        case 'gateway': {
          let link = `/gatewayDetails?gatewayId=${resEventDetails?.resource_instance}`;
          setResourceLink(link);
          break;
        }
        case 'location': {
          const locationsList = await getAllLocations(VisibilityFlags.ALL);
          const location = locationsList?.find(
            (location: any) =>
              location.resource_id === resEventDetails.resource_instance
          );
          const locationCloudId = location?.cloud_id;
          let link = `/locationDetails?cloudId=${locationCloudId}&locationId=${resEventDetails?.resource_instance}`;
          setResourceLink(link);
          break;
        }
      }
    } catch (error) {
      console.error(error);
    }
    toogleEventDetailsDataLoading(false);
  };

  const requestDeleteEvent = () => {
    toggleDeleteEventOpenModal(true);
  };

  const getBreadCrumbs = () => {
    return [
      {
        url: '/',
        label: t('home'),
      },
      {
        url: '/event',
        label: t('events'),
      },
    ];
  };
  const getIcon = (severity: string) => {
    let icon;
    switch (severity) {
      case eventSeverity.CRITICAL:
        icon = <images.criticalStatusIcon />;
        break;
      case eventSeverity.MINOR:
        icon = <images.minorStatusIcon />;
        break;
      case eventSeverity.MAJOR:
        icon = <images.majorStatusIcon />;
        break;
      case eventSeverity.INFORMATION:
        icon = <images.infoSmallEventIcon />;
        break;
      case eventSeverity.CLEARED:
        icon = '';
        break;
      default:
        icon = '';
    }
    return icon;
  };

  const getTitle = () => {
    return (
      <div className='title-get-message'>
        {eventDetailsData?.severity
          ? getIcon(eventDetailsData?.severity)
          : null}
        {eventDetailsData?.message ? (
          <GenericResponsiveMiddleTruncation
            str={eventDetailsData?.message}
            hasTooltip={false}
            strLength={56}
          />
        ) : null}
      </div>
    );
  };

  const handleGatewayNavigate = () => {
    removeFiltersFromStorage();
    removeViewStateFromStorage();
  };

  const getSubTitle = () => {
    const Icon = getResourceTypeIcon(
      eventDetailsData?.resource_type as keyof typeof resourceTypeIcon
    );

    return (
      <div className=''>
        {dateUtils.getUserFriendlyDate(eventDetailsData?.created_at, true)}
      </div>
    );
  };

  const formatEventDetailsData = (data: EventData | null) => {
    const Icon = getResourceTypeIcon(
      data?.resource_type as keyof typeof resourceTypeIcon
    );

    const formattedData = [
      {
        key: 'eventMessage',
        value: data?.['message'],
      },
      {
        key: 'assignedUser',
        value: data?.['assigned_user'],
      },
      {
        key: 'resourceName',
        value: (
          <div className=''>
            {resourceLink ? (
              <Link
                to={resourceLink}
                target='_blank'
                className='no-underline-link link-card-title'
              >
                <span className='resource-icon'>{Icon}</span>
                <span className='depl-name-details-card'>
                  {data?.resource_name}
                </span>
                <span className='resource-icon-new-tab'>
                  <images.NewTabIcon />
                </span>
              </Link>
            ) : (
              <span className='resouce-name'>
                {Icon && <span className='icon'>{Icon}</span>}
                <span>{data?.resource_name}</span>
              </span>
            )}
          </div>
        ) as any,
      },
      {
        key: 'category',
        value: data?.['category'],
      },
      {
        key: 'comment',
        value: data?.['comment'] ?? t('none'),
      },
      {
        key: 'timeOfEvent',
        value: dateUtils.getUserFriendlyDate(data?.created_at, true),
      },
      {
        key: 'updated_at',
        value: dateUtils.getUserFriendlyDate(data?.updated_at, true),
      },
      {
        key: 'labels',
        value: data?.['labels'],
      },
    ];

    return formattedData;
  };

  const handleRemoveEvent = async (id: string) => {
    trackButtonClicked(
      analyticsData['Event Details'].events.deleteEvent.props,
      analyticsData['Event Details'].events.deleteEvent.event
    );
    toggleDeleteEventOpenModal(false);
    try {
      setDisableButton(true);
      const deleteEventname = eventDetailsData?.message;
      toogleEventDetailsDataLoading(true);
      await deleteEvent(id);
      // Trigger success toastbar
      notification.onTrigger('TOAST', {
        title: t('eventNotificationMessages.successNotificationTitle'),
        subtitle: t('eventNotificationMessages.removeNotificationMessage', {
          eventNameDelete: deleteEventname,
        }),
      });
      navigate(`/event`);
    } catch (error: any) {
      const err = error as AxiosError;
      const errorMessage: string =
        error.response !== undefined
          ? error.response['customErrorMessage']
          : '';
      let errorTitle: string = '',
        errorSubtitle: string = '';

      if (err.response?.status === 403) {
        errorTitle = `${t('eventNotificationMessages.authErrorTitle')}`;
        errorSubtitle = t('eventNotificationMessages.authErrorSubtitle');
      }

      if (err.response?.status !== 403 && errorMessage.length > 0) {
        errorTitle = `${t('eventNotificationMessages.errorNotificationTitle')}`;
        errorSubtitle = errorMessage;
      }

      if (errorMessage.length === 0) {
        errorTitle = `${t('eventNotificationMessages.errorNotificationTitle')}`;
        errorSubtitle = t('eventNotificationMessages.removeEventFail');
      }

      console.error(error);
      setNotificationData({
        kind: 'error',
        title: errorTitle,
        subtitle: errorSubtitle,
      });
      setOpenErrorModal(true);
      // We added this project.reject to prevent the modal closing when error occured.
      // But in latest version of '@carbon/ibm-products' the below line is not required and is throwing error.
      // return Promise.reject(() => console.log(error));
    } finally {
      setDisableButton(false);
      toogleEventDetailsDataLoading(false);
    }
  };

  const statusValue = () => {
    return eventDetailsData?.is_handled || eventDetailsData?.is_cleared
      ? t('status.closed')
      : eventDetailsData?.assigned_user
      ? t('status.assigned')
      : t('status.open');
  };

  const handleComboButton = (item: any) => {
    if (item === 'Close') {
      setOpenCloseModal(true);
      return;
    }
    setComboButtonText(item);
    trackButtonClicked(
      analyticsData['Event Details'].events.commentEvent.props,
      analyticsData['Event Details'].events.commentEvent.event
    );
    setOpenEditModal(true);
  };
  const handleEventClose = async () => {
    trackButtonClicked(
      analyticsData['Events'].events.assignEvents.props,
      analyticsData['Events'].events.assignEvents.event
    );
    const updateEventPayload = {
      ...eventDetailsData,
      assigned_user: eventDetailsData?.assigned_user,
      comment: eventDetailsData?.comment,
      is_handled: true,
      labels: eventDetailsData?.labels,
    };
    try {
      setDisableButton(true);
      await updateEvent(eventId, updateEventPayload);
      setOpenCloseModal(false);
      onSuccessfulUpdate();
    } catch (error: any) {
      handleErrorToast(error);
    } finally {
      setDisableButton(false);
    }
  };

  const handleSuccessToast = () => {
    notification.onTrigger('TOAST', {
      title: t('editEventNotification.successNotificationTitle'),
      subtitle: t('editEventNotification.editNotificationMessage', {
        eventNameEdit: eventDetailsData?.message,
      }),
    });
  };

  const handleErrorToast = (error: any) => {
    const err = error as AxiosError;
    const errorMessage: string =
      error.response !== undefined ? error.response['customErrorMessage'] : '';
    let errorTitle: string = '',
      errorSubtitle: string = '';

    if (err.response?.status === 403) {
      errorTitle = `${t('editEventNotification.authErrorTitle')}`;
      errorSubtitle = t('editEventNotification.authErrorSubtitle');
    }

    if (err.response?.status !== 403 && errorMessage.length > 0) {
      errorTitle = t('editEventNotification.errorNotificationTitle');
      errorSubtitle = errorMessage;
    }

    if (errorMessage.length === 0) {
      errorTitle = t('editEventNotification.errorNotificationTitle');
      errorSubtitle = t('editEventNotification.editEventFail');
    }

    notification.onTrigger('TOAST', {
      kind: 'error',
      title: errorTitle,
      subtitle: errorSubtitle,
    });
  };

  const dropDownButton = () => {
    const items = [];
    if (eventDetailsData?.is_handled) {
      items.push({ id: 'Reopen', text: t('editBtnText.reopen') });
    } else {
      if (eventDetailsData?.assigned_user) {
        items.push({ id: 'Close', text: t('editBtnText.close') });
        items.push({ id: 'Reassign', text: t('editBtnText.reassign') });
      } else {
        items.push({ id: 'Assign', text: t('editBtnText.assign') });
        items.push({ id: 'Close', text: t('editBtnText.close') });
      }
    }
    return (
      <ComboButton
        className={
          eventDetailsData?.assigned_user ? 'combo-btn-menu-reassign' : ''
        }
      >
        {items.map(item => {
          return (
            <ComboButtonItem
              onClick={() => {
                handleComboButton(item.id);
              }}
            >
              {item.text}
            </ComboButtonItem>
          );
        })}
      </ComboButton>
    );
  };

  const renderDropdownButton = () => {
    if (eventDetailsDataLoading) {
      return (
        <div className='status-button'>
          <ButtonSkeleton />
        </div>
      );
    }
    return (
      <div className='status-button'>
        <div className='text-status'>
          Status :<span> {statusValue()}</span>
        </div>
        {eventDetailsData?.is_cleared ? null : (
          <div className='dropdown-status-change'>{dropDownButton()}</div>
        )}
      </div>
    );
  };

  const onNotificationClose = (
    evt: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): any => {
    setOpenErrorModal(false);
  };

  useEffect(() => {
    pageViewed('Event Details');
    fetchEventsDetails();
  }, []);

  const onSuccessfulUpdate = () => {
    handleSuccessToast();
    fetchEventsDetails();
  };

  const closeTearsheet = () => {
    setOpenEditModal(false);
    fetchEventsDetails();
  };

  const getDetailsCardValues = (label: string) => {
    const cardValue = formatEventDetailsData(eventDetailsData).filter(obj => {
      if (obj.key === label) {
        return true;
      }
    });
    return cardValue[0];
  };

  if (error500) {
    return <Error500 />;
  }

  return (
    <div className='event-details'>
      <Header
        loading={eventDetailsDataLoading}
        breadcrumbs={getBreadCrumbs()}
        title={getTitle()}
        subTitle={getSubTitle()}
        renderCustomActions={renderDropdownButton}
      />
      <div className='event-details-card'>
        {openErrorModal ? (
          <Notification
            kind={notificationData?.kind}
            subtitle={notificationData?.subtitle}
            title={notificationData?.title}
            onClose={onNotificationClose}
          ></Notification>
        ) : null}
        <div className='event-card'>
          {eventDetailsDataLoading ? (
            <div className='page-content'>
              <SkeletonPlaceholder className='details-tile-skeleton' />
            </div>
          ) : (
            <Tile className='details-tile'>
              <div className='header'>
                <div className='text'>{t('eventDetails')}</div>
                <div className='edit'>
                  <Button
                    className={
                      eventDetailsData?.is_cleared
                        ? 'edit-link disabled-link'
                        : 'edit-link'
                    }
                    renderIcon={Edit16}
                    disabled={eventDetailsData?.is_cleared}
                    onClick={() => {
                      setComboButtonText('normal');
                      setOpenEditModal(true);
                    }}
                    iconDescription={t('edit')}
                    hasIconOnly
                    tooltipPosition='bottom'
                  ></Button>
                </div>
              </div>
              <div className='data-container'>
                <Column className='section' lg={8} md={4}>
                  <div className='bold-text'>
                    <div className='label'>
                      {t(getDetailsCardValues('eventMessage').key)}
                    </div>
                    <div className='value line-break'>
                      {getDetailsCardValues('eventMessage').value}
                    </div>
                  </div>
                  <div className='section-row section-second-row'>
                    <div className='section-row-item'>
                      <div className='label'>
                        {t(getDetailsCardValues('resourceName').key)}
                      </div>
                      <div className='value'>
                        {getDetailsCardValues('resourceName').value}
                      </div>
                    </div>
                    <div className='section-row-item'>
                      <div className='label'>
                        {t(getDetailsCardValues('category').key)}
                      </div>
                      <div className='value'>
                        {t(getDetailsCardValues('category').value)}
                      </div>
                    </div>
                  </div>
                  <div className='section-row section-second-row'>
                    <div className='section-row-item'>
                      <div className='label'>
                        {t(getDetailsCardValues('timeOfEvent').key)}
                      </div>
                      <div className='value'>
                        {getDetailsCardValues('timeOfEvent').value}
                      </div>
                    </div>
                    <div className='section-row-item'>
                      <div className='label'>
                        {t(getDetailsCardValues('updated_at').key)}
                      </div>
                      <div className='value'>
                        {getDetailsCardValues('updated_at').value}
                      </div>
                    </div>
                  </div>
                  <div className='section-second-row'>
                    <div className='label'>
                      {t(getDetailsCardValues('labels').key)}
                    </div>
                    <div className='value'>
                      {getDetailsCardValues('labels').value &&
                      getDetailsCardValues('labels').value.length > 0 ? (
                        <LabelTag
                          labelArray={
                            getDetailsCardValues('labels').value as string[]
                          }
                          count={3}
                        ></LabelTag>
                      ) : (
                        '—'
                      )}
                    </div>
                  </div>
                </Column>
                <Column className='section' lg={8} md={4}>
                  <div>
                    <div className='label'>
                      {t(getDetailsCardValues('assignedUser').key)}
                    </div>
                    <div className='value'>
                      {getDetailsCardValues('assignedUser').value
                        ? getDetailsCardValues('assignedUser').value
                        : '—'}
                    </div>
                  </div>
                  <div className='section-second-row'>
                    <div className='label'>
                      {t(getDetailsCardValues('comment').key)}
                    </div>
                    <div className='value line-break'>
                      {getDetailsCardValues('comment').value
                        ? getDetailsCardValues('comment').value
                        : '—'}
                    </div>
                  </div>
                </Column>
              </div>
            </Tile>
          )}
        </div>
      </div>

      {eventDetailsDataLoading ? (
        <SkeletonPlaceholder className='delete-btn-skeleton' />
      ) : (
        <Button
          className='eventDeteteButton'
          kind='danger--ghost'
          onClick={() => requestDeleteEvent()}
        >
          {t('deleteEventButton')}
        </Button>
      )}

      <Modal
        open={openCloseModal}
        danger
        modalHeading={t('close.modal.heading')}
        primaryButtonText={t('close.modal.closeBtn')}
        secondaryButtonText={t('close.modal.cancelBtn')}
        preventCloseOnClickOutside={true}
        onRequestSubmit={() => {
          handleEventClose();
        }}
        onRequestClose={() => {
          setOpenCloseModal(false);
        }}
        className='delete-event-modal'
        size='xs'
        primaryButtonDisabled={disableButton}
      >
        {t('close.modal.description', {
          eventMessage: eventDetailsData?.message,
        })}
      </Modal>
      <Modal
        className='delete-event-modal'
        danger
        modalHeading={t('eventNotificationMessages.deleteModal.header')}
        onRequestClose={() => toggleDeleteEventOpenModal(false)}
        onRequestSubmit={() =>
          eventDetailsData && handleRemoveEvent(eventDetailsData?.resource_id)
        }
        primaryButtonText={t('eventNotificationMessages.deleteModal.confirm')}
        secondaryButtonText={t('eventNotificationMessages.deleteModal.cancel')}
        open={deleteEventOpenModal}
        size='xs'
        primaryButtonDisabled={disableButton}
      >
        {t('eventNotificationMessages.deleteModal.body', {
          eventMessage: eventDetailsData?.message,
        })}
      </Modal>
      {openEditModal && eventDetailsData && (
        <EditEvent
          name={eventDetailsData?.message}
          comment={eventDetailsData?.comment}
          assigned={eventDetailsData?.assigned_user}
          labels={eventDetailsData?.labels}
          open={openEditModal}
          // onClose={() => {
          //   setOpenEditModal(false);
          // }}
          onClose={() => closeTearsheet()}
          saveChangesCallBack={onSuccessfulUpdate}
          updateErrorCallBack={handleErrorToast}
          eventId={eventDetailsData?.resource_id}
          isHandled={eventDetailsData.is_handled}
          buttonText={comboButtonText}
        />
      )}
    </div>
  );
};

export default EventDetails;
