import React from 'react';
import './IdentityTag.scss';
import ProfileIcon from '../ProfileIcon/ProfileIcon';

const IdentityTag: React.FC<{
  name: string;
  nameStyle?: any;
  showName?: boolean;
  mailId?: string;
  size?: 'md' | 'lg' | 'xlg';
}> = ({ name, showName, mailId, nameStyle, size = 'lg' }) => {
  return (
    <div className='identity-tag-container'>
      <ProfileIcon name={name} size={size} />
      <div className='details-box'>
        {showName ? (
          <div className='name' style={nameStyle}>
            {name}
          </div>
        ) : null}
        {mailId ? <div className='mail'>{mailId}</div> : null}
      </div>
    </div>
  );
};

export default IdentityTag;
