import React, { useState, useEffect, useContext } from 'react';
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SkeletonPlaceholder, DataTableSkeleton, Button } from '@carbon/react';
import { Modal } from 'carbon-components-react';
import Header from '../../components/Header/Header';
import DetailsCard from '../../components/DetailsCard/DetailsCard';
import { NotificationContext } from '../../components/Notifications/Context/NotificationProvider';
import { Error500Type, Identity, Role } from '../../models/master';
import {
  getIdentity,
  getIdentityRoles,
  deleteIdentity,
  deleteIdentityRole,
} from '../../controllers/identityApis';
import IdentityRolesTable from './IdentityRolesTable/IdentityRolesTable';
import sortData from '../../lib/tableSort';
import AddRoles from './AddRoles/AddRoles';
import EditIdentityDetails from './EditIdentityDetails/EditIdentityDetails';
import { AxiosError } from 'axios';
import './IdentityDetails.scss';
import useAnalytics from '../../lib/useAnalytics';
import analyticsData from '../../lib/analyticsEventData';
import Error500 from '../Errors/Error500';

type IdentityState = {
  breadcrumb?: any;
};

const IdentityDetails = () => {
  const { t } = useTranslation('identityDetails');

  const [identityDataLoading, toggleIdentityDataLoading] = useState(false);
  const [roleTableDataLoading, toggleRoleTableDataLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const identityId = searchParams.get('identity_id');
  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | 'NONE'>(
    'NONE'
  );

  const [identityDetails, setIdentityDetails] = useState<Identity | null>(null);
  const [IdentityRolesData, setIdentityRolesData] = useState<Role[] | null>(
    null
  );

  const [openAddRoleModal, setOpenAddRoleModal] = useState(false);
  const [showEditIdentityDetails, setShowEditIdentityDetails] =
    useState<boolean>(false);
  const [openDeleteIdentityModal, setOpenDeleteIdentityModal] =
    useState<boolean>(false);
  const [roleHasNotAuthorized, setRoleHasNotAuthorized] =
    useState<boolean>(false);
  const [disableButton, setDisableButton] = useState(false);
  const [error500, setError500] = useState<null | Error500Type>(null);

  const notification = useContext(NotificationContext);
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as IdentityState;
  const { trackButtonClicked } = useAnalytics();

  const identityDetailsLabels = () => {
    const labels: string[] = [];
    if (identityDetails?.labels) {
      identityDetails.labels.forEach(el => {
        labels.push(el);
      });
    }
    return labels;
  };

  const fetchIdentityRoles = async (identityId: string | null) => {
    try {
      toggleRoleTableDataLoading(true);
      const response = await getIdentityRoles(identityId);
      setIdentityRolesData(response);
    } catch (error) {
      const err = error as AxiosError;
      setIdentityRolesData(null);
      if (err.response?.status === 403) {
        setRoleHasNotAuthorized(true);
      }
    } finally {
      toggleRoleTableDataLoading(false);
    }
  };

  const refreshData = async () => {
    setSortKey('');
    setSortDirection('NONE');
    toggleIdentityDataLoading(true);
    //Identity and roles of identity data
    try {
      const identityData = await getIdentity(identityId);
      setIdentityDetails(identityData);
      fetchIdentityRoles(identityId);
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status === 404) {
        navigate('/404');
      }

      if (err.response!?.status >= 500) {
        setError500(err.response!?.status?.toString() as Error500Type);
      }
    } finally {
      toggleIdentityDataLoading(false);
    }
  };

  useEffect(() => {
    refreshData();
  }, []);

  const handleTableSort = (
    data: { id: string; text: string },
    sortDirection: 'ASC' | 'DESC' | 'NONE'
  ) => {
    setSortDirection(sortDirection);
    setSortKey(data.id);
  };

  const editBtnClick = () => {
    trackButtonClicked(
      analyticsData['Admin Identities'].events.editIdentity.props,
      analyticsData['Admin Identities'].events.editIdentity.event
    );
    setShowEditIdentityDetails(true);
  };

  const onAddRole = () => {
    setOpenAddRoleModal(false);
    refreshData();
  };

  const openAddRoleIdentityModal = () => {
    setOpenAddRoleModal(true);
  };

  const closeAddRoleIdentityModal = () => {
    setOpenAddRoleModal(false);
  };

  const closeEditIdentityDetailsModal = () => {
    setShowEditIdentityDetails(false);
  };

  const editFormData = {
    name: {
      value: identityDetails?.name,
      error: false,
      errorMessage: '',
    },
    description: {
      value: identityDetails?.description,
    },
    labels: {
      value: Array.isArray(identityDetails?.labels)
        ? identityDetails?.labels
        : null,
    },
  };

  const roleTableHeaders = [
    {
      key: 'name__format',
      originalKey: 'name',
      header: t('tableHeaders.roleName'),
      sort: true,
    },
    {
      key: 'labels',
      originalKey: 'labels',
      header: t('tableHeaders.labels'),
      style: { minWidth: '12.5rem' },
      sort: true,
    },
    {
      key: 'lastUpdated',
      originalKey: 'updated_at',
      header: t('tableHeaders.lastUpdated'),
      sort: true,
    },
    {
      key: 'actionBtn',
      originalKey: '',
      header: '',
      sort: false,
    },
  ];

  const getDeleteButton = () => {
    let accountDetails: any = window.localStorage.getItem(
      `ACCOUNT_DETAILS_${window.localStorage.getItem('SELECTED_ACCOUNT')}`
    );
    const userIdentity: string = JSON.parse(accountDetails)?.details?.sub;
    return !(identityDetails?.resource_id == userIdentity) ? (
      <div className='delete-identity'>
        <Button
          kind='danger--ghost'
          onClick={() => {
            setOpenDeleteIdentityModal(true);
          }}
        >
          {t('deleteIdentity')}
        </Button>
      </div>
    ) : null;
  };

  const submitDeleteRequest = async () => {
    try {
      setDisableButton(true);
      trackButtonClicked(
        analyticsData['Admin Identities'].events.deleteIdentity.props,
        analyticsData['Admin Identities'].events.deleteIdentity.event
      );
      await deleteIdentity(identityId);
      notification.onTrigger('TOAST', {
        title: t('delete.successNotification.title'),
        subtitle: t('delete.successNotification.description'),
      });
      navigate('/identities');
    } catch (error: any) {
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        authErrorNotification();
      }

      const errorMessage: string = error.response['customErrorMessage'];
      notification.onTrigger('TOAST', {
        title: t('delete.error.title'),
        kind: 'error',
        subtitle:
          errorMessage.length > 0
            ? errorMessage
            : t('delete.error.errorMsg', { name: identityDetails?.name ?? '' }),
      });
    } finally {
      setDisableButton(false);
      setOpenDeleteIdentityModal(false);
    }
  };

  const handleRemoveRoleSuccess = (roleName: string) => {
    notification.onTrigger('TOAST', {
      title: t('remove.successNotification.title'),
      subtitle: t('remove.successNotification.description', { role: roleName }),
    });
    fetchIdentityRoles(identityId);
  };

  const handleRemoveRoleError = (error: any) => {
    const errorMessage: string = error.response['customErrorMessage'];
    notification.onTrigger('TOAST', {
      title: t('remove.error.title'),
      kind: 'error',
      subtitle:
        errorMessage.length > 0 ? errorMessage : t('remove.error.errorMsg'),
    });
  };

  const authErrorNotification = () => {
    notification.onTrigger('TOAST', {
      title: t('delete.error.authErrorTitle'),
      kind: 'error',
      subtitle: t('delete.error.authErrorSubtitle'),
    });
  };

  const handleRemoveRole = async (roleId: string, roleName: string) => {
    toggleRoleTableDataLoading(true);
    try {
      const res = await deleteIdentityRole(identityId, roleId);
      res?.status === 204
        ? handleRemoveRoleSuccess(roleName)
        : handleRemoveRoleError(res);
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        authErrorNotification();
      } else {
        handleRemoveRoleError(err);
      }
      toggleRoleTableDataLoading(false);
    }
  };

  if (error500) {
    return <Error500 />;
  }

  return (
    <div className='identity-details'>
      <Header
        loading={identityDataLoading}
        title={identityDetails ? identityDetails.name : ''}
        breadcrumbs={
          state !== null && state.breadcrumb
            ? state.breadcrumb
            : [
                {
                  url: '/',
                  label: t('home'),
                },
                {
                  url: '/identities',
                  label: t('Identities'),
                },
              ]
        }
      />

      {identityDataLoading ? (
        <div className='page-content'>
          <SkeletonPlaceholder className='details-tile-skeleton' />
          <DataTableSkeleton />
        </div>
      ) : (
        <div className='page-content'>
          <DetailsCard
            type='IDENTITY_DETAILS'
            openEditModal={editBtnClick}
            isEditable={true}
            detailsCardName={t('detailsCardName')}
            data={[
              {
                key: 'name',
                value: identityDetails?.name,
              },
              {
                key: 'emailId',
                value: identityDetails?.resource_id,
              },
              {
                key: 'created',
                value: identityDetails?.created_at,
              },
              {
                key: 'updatedAt',
                value: identityDetails?.updated_at,
              },
              {
                key: 'description',
                value: identityDetails?.description,
              },
              {
                key: 'labels',
                value: identityDetailsLabels(),
              },
            ]}
          />

          <IdentityRolesTable
            rows={
              IdentityRolesData
                ? sortData(IdentityRolesData ?? [], sortKey, sortDirection)
                : []
            }
            roles={IdentityRolesData}
            headers={roleTableHeaders}
            loading={roleTableDataLoading}
            sortRows={(
              data: { id: string; text: string },
              direction: 'ASC' | 'DESC' | 'NONE'
            ) => handleTableSort(data, direction)}
            openAssignIdentity={() => {
              openAddRoleIdentityModal();
            }}
            identityId={identityId}
            roleHasNotAuthorized={roleHasNotAuthorized}
            removeRoleCallBack={handleRemoveRole}
          />
          {getDeleteButton()}
        </div>
      )}

      {openAddRoleModal && (
        <AddRoles
          open={openAddRoleModal}
          onClose={() => closeAddRoleIdentityModal()}
          identityId={identityId}
          addRoletoIdentity={() => onAddRole()}
        />
      )}

      <EditIdentityDetails
        open={showEditIdentityDetails}
        onClose={() => closeEditIdentityDetailsModal()}
        identityId={identityId}
        editIdentityDetails={identityDetails}
        selectedIdentityData={editFormData}
        refreshData={refreshData}
      />

      <Modal
        className='delete-identity-modal'
        size='xs'
        danger
        modalHeading={t('delete.modalTitle', {
          identity: identityDetails?.name ?? '',
        })}
        onRequestClose={() => setOpenDeleteIdentityModal(false)}
        onRequestSubmit={() => submitDeleteRequest()}
        primaryButtonText={t('delete.deleteButton')}
        secondaryButtonText={t('delete.cancelButton')}
        open={openDeleteIdentityModal}
        primaryButtonDisabled={disableButton}
      >
        {t('delete.description', { name: identityDetails?.name ?? '' })}
      </Modal>
    </div>
  );
};

export default IdentityDetails;
