import { TFunction } from 'react-i18next';
import { ResourceTypeCategoryMapInterface } from '../../models/master';

/* Some categories have been commented out. 
We are receiving 29 resource types from backend as on date. 
All are not required in UI. */

export const resourceTypeCategoryMap = (
  t: TFunction<'roleDetails', undefined>
) => {
  let config: ResourceTypeCategoryMapInterface[] = [
    {
      id: 'policy',
      category: 'Applications',
      display: true,
      label: t('resourceTypes.policy'),
      access_type: {
        all: false,
        admin: false,
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      edited_access_type: {
        all: { status: 'default', value: false, disabled: false },
        admin: {
          status: 'default',
          value: false,
          disabled: false,
          tooltip: t('editPermission.tooltips.checkbox.policy.admin'),
        },
        create: { status: 'default', value: false, disabled: false },
        read: { status: 'default', value: false, disabled: false },
        update: { status: 'default', value: false, disabled: false },
        delete: { status: 'default', value: false, disabled: false },
      },
      tooltip: t('editPermission.tooltips.resourcetype.policy'),
    },
    {
      id: 'app',
      category: 'Applications',
      display: true,
      label: t('resourceTypes.app'),
      access_type: {
        all: false,
        admin: false,
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      edited_access_type: {
        all: { status: 'default', value: false, disabled: false },
        admin: {
          status: 'default',
          value: false,
          disabled: false,
          tooltip: t('editPermission.tooltips.checkbox.app.admin'),
        },
        create: { status: 'default', value: false, disabled: false },
        read: { status: 'default', value: false, disabled: false },
        update: { status: 'default', value: false, disabled: false },
        delete: { status: 'default', value: false, disabled: false },
      },
      tooltip: t('editPermission.tooltips.resourcetype.app'),
    },
    {
      id: 'service',
      category: 'Applications',
      display: true,
      label: t('resourceTypes.service'),
      access_type: {
        all: false,
        admin: false,
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      edited_access_type: {
        all: { status: 'default', value: false, disabled: false },
        admin: { status: 'default', value: false, disabled: true },
        create: { status: 'default', value: false, disabled: false },
        read: { status: 'default', value: false, disabled: false },
        update: { status: 'default', value: false, disabled: false },
        delete: { status: 'default', value: false, disabled: false },
      },
    },
    {
      id: 'deployment',
      category: 'Applications',
      display: true,
      label: t('resourceTypes.deployment'),
      access_type: {
        all: false,
        admin: false,
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      edited_access_type: {
        all: { status: 'default', value: false, disabled: false },
        admin: { status: 'default', value: false, disabled: true },
        create: { status: 'default', value: false, disabled: false },
        read: { status: 'default', value: false, disabled: false },
        update: { status: 'default', value: false, disabled: false },
        delete: { status: 'default', value: false, disabled: false },
      },
    },
    {
      id: 'instance',
      category: 'Applications',
      display: true,
      label: t('resourceTypes.instance'),
      access_type: {
        all: false,
        admin: false,
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      edited_access_type: {
        all: { status: 'default', value: false, disabled: false },
        admin: { status: 'default', value: false, disabled: true },
        create: { status: 'default', value: false, disabled: false },
        read: { status: 'default', value: false, disabled: false },
        update: { status: 'default', value: false, disabled: false },
        delete: { status: 'default', value: false, disabled: false },
      },
    },

    {
      id: 'service_endpoint',
      category: 'Applications',
      display: true,
      label: t('resourceTypes.service_endpoint'),
      access_type: {
        all: false,
        admin: false,
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      edited_access_type: {
        all: { status: 'default', value: false, disabled: false },
        admin: { status: 'default', value: false, disabled: true },
        create: { status: 'default', value: false, disabled: false },
        read: { status: 'default', value: false, disabled: false },
        update: { status: 'default', value: false, disabled: false },
        delete: { status: 'default', value: false, disabled: false },
      },
    },
    {
      id: 'networksegment',
      category: 'Infrastructure',
      display: true,
      label: t('resourceTypes.networksegment'),
      access_type: {
        all: false,
        admin: false,
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      edited_access_type: {
        all: { status: 'default', value: false, disabled: false },
        admin: { status: 'default', value: false, disabled: true },
        create: { status: 'default', value: false, disabled: false },
        read: { status: 'default', value: false, disabled: false },
        update: { status: 'default', value: false, disabled: false },
        delete: { status: 'default', value: false, disabled: false },
      },
    },
    {
      id: 'cloud',
      category: 'Infrastructure',
      display: true,
      label: t('resourceTypes.cloud'),
      access_type: {
        all: false,
        admin: false,
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      edited_access_type: {
        all: { status: 'default', value: false, disabled: false },
        admin: {
          status: 'default',
          value: false,
          disabled: false,
          tooltip: t('editPermission.tooltips.checkbox.cloud.admin'),
        },
        create: { status: 'default', value: false, disabled: false },
        read: { status: 'default', value: false, disabled: false },
        update: { status: 'default', value: false, disabled: false },
        delete: { status: 'default', value: false, disabled: false },
      },
      tooltip: t('editPermission.tooltips.resourcetype.cloud'),
    },
    {
      id: 'location',
      category: 'Infrastructure',
      display: true,
      label: t('resourceTypes.location'),
      access_type: {
        all: false,
        admin: false,
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      edited_access_type: {
        all: { status: 'default', value: false, disabled: false },
        admin: { status: 'default', value: false, disabled: true },
        create: { status: 'default', value: false, disabled: false },
        read: { status: 'default', value: false, disabled: false },
        update: { status: 'default', value: false, disabled: false },
        delete: { status: 'default', value: false, disabled: false },
      },
      tooltip: t('editPermission.tooltips.resourcetype.location'),
    },
    {
      id: 'cluster',
      category: 'Infrastructure',
      display: true,
      label: t('resourceTypes.cluster'),
      access_type: {
        all: false,
        admin: false,
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      edited_access_type: {
        all: { status: 'default', value: false, disabled: false },
        admin: {
          status: 'default',
          value: false,
          disabled: false,
          tooltip: t('editPermission.tooltips.checkbox.cluster.admin'),
        },
        create: { status: 'default', value: false, disabled: false },
        read: { status: 'default', value: false, disabled: false },
        update: { status: 'default', value: false, disabled: false },
        delete: { status: 'default', value: false, disabled: false },
      },
      tooltip: t('editPermission.tooltips.resourcetype.cluster'),
    },
    {
      id: 'vpc',
      category: 'Infrastructure',
      display: true,
      label: t('resourceTypes.vpc'),
      access_type: {
        all: false,
        admin: false,
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      edited_access_type: {
        all: { status: 'default', value: false, disabled: false },
        admin: { status: 'default', value: false, disabled: true },
        create: { status: 'default', value: false, disabled: false },
        read: { status: 'default', value: false, disabled: false },
        update: { status: 'default', value: false, disabled: false },
        delete: { status: 'default', value: false, disabled: false },
      },
      tooltip: t('editPermission.tooltips.resourcetype.vpc'),
    },
    {
      id: 'node',
      category: 'Infrastructure',
      display: true,
      label: t('resourceTypes.node'),
      access_type: {
        all: false,
        admin: false,
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      edited_access_type: {
        all: { status: 'default', value: false, disabled: false },
        admin: { status: 'default', value: false, disabled: true },
        create: { status: 'default', value: false, disabled: false },
        read: { status: 'default', value: false, disabled: false },
        update: { status: 'default', value: false, disabled: false },
        delete: { status: 'default', value: false, disabled: false },
      },
      tooltip: t('editPermission.tooltips.resourcetype.node'),
    },
    {
      id: 'deploymentenv',
      category: 'Infrastructure',
      display: false,
      label: t('resourceTypes.deploymentenv'),
      access_type: {
        all: false,
        admin: false,
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      edited_access_type: {
        all: { status: 'default', value: false, disabled: false },
        admin: { status: 'default', value: false, disabled: false },
        create: { status: 'default', value: false, disabled: false },
        read: { status: 'default', value: false, disabled: false },
        update: { status: 'default', value: false, disabled: false },
        delete: { status: 'default', value: false, disabled: false },
      },
    },
    {
      id: 'identity',
      category: 'Manage',
      display: true,
      label: t('resourceTypes.identity'),
      access_type: {
        all: false,
        admin: false,
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      edited_access_type: {
        all: { status: 'default', value: false, disabled: false },
        admin: { status: 'default', value: false, disabled: true },
        create: { status: 'default', value: false, disabled: true },
        read: { status: 'default', value: false, disabled: false },
        update: { status: 'default', value: false, disabled: true },
        delete: { status: 'default', value: false, disabled: true },
      },
      tooltip: t('editPermission.tooltips.resourcetype.identity'),
    },

    {
      id: 'namespace',
      category: 'Infrastructure',
      display: true,
      label: t('resourceTypes.namespace'),
      access_type: {
        all: false,
        admin: false,
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      edited_access_type: {
        all: { status: 'default', value: false, disabled: false },
        admin: {
          status: 'default',
          value: false,
          disabled: false,
          tooltip: t('editPermission.tooltips.checkbox.namespace.admin'),
        },
        create: { status: 'default', value: false, disabled: false },
        read: { status: 'default', value: false, disabled: false },
        update: { status: 'default', value: false, disabled: false },
        delete: { status: 'default', value: false, disabled: false },
      },
      tooltip: t('editPermission.tooltips.resourcetype.namespace'),
    },
    {
      id: 'network',
      category: 'Others',
      display: false,
      label: t('resourceTypes.network'),
      access_type: {
        all: false,
        admin: false,
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      edited_access_type: {
        all: { status: 'default', value: false, disabled: false },
        admin: { status: 'default', value: false, disabled: false },
        create: { status: 'default', value: false, disabled: false },
        read: { status: 'default', value: false, disabled: false },
        update: { status: 'default', value: false, disabled: false },
        delete: { status: 'default', value: false, disabled: false },
      },
    },
    {
      id: 'overlaynet',
      category: 'Manage',
      display: false,
      label: t('resourceTypes.overlaynet'),
      access_type: {
        all: false,
        admin: false,
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      edited_access_type: {
        all: { status: 'default', value: false, disabled: false },
        admin: { status: 'default', value: false, disabled: false },
        create: { status: 'default', value: false, disabled: false },
        read: { status: 'default', value: false, disabled: false },
        update: { status: 'default', value: false, disabled: false },
        delete: { status: 'default', value: false, disabled: false },
      },
    },
    {
      id: 'partition',
      category: 'Others',
      display: false,
      label: t('resourceTypes.partition'),
      access_type: {
        all: false,
        admin: false,
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      edited_access_type: {
        all: { status: 'default', value: false, disabled: false },
        admin: { status: 'default', value: false, disabled: false },
        create: { status: 'default', value: false, disabled: false },
        read: { status: 'default', value: false, disabled: false },
        update: { status: 'default', value: false, disabled: false },
        delete: { status: 'default', value: false, disabled: false },
      },
    },
    {
      id: 'permission',
      category: 'Manage',
      display: false,
      label: t('resourceTypes.permission'),
      access_type: {
        all: false,
        admin: false,
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      edited_access_type: {
        all: { status: 'default', value: false, disabled: false },
        admin: { status: 'default', value: false, disabled: false },
        create: { status: 'default', value: false, disabled: false },
        read: { status: 'default', value: false, disabled: false },
        update: { status: 'default', value: false, disabled: false },
        delete: { status: 'default', value: false, disabled: false },
      },
    },
    {
      id: 'resourcegroup',
      category: 'Manage',
      display: true,
      label: t('resourceTypes.resourcegroup'),
      access_type: {
        all: false,
        admin: false,
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      edited_access_type: {
        all: { status: 'default', value: false, disabled: false },
        admin: {
          status: 'default',
          value: false,
          disabled: false,
          tooltip: t('editPermission.tooltips.checkbox.resourcegroup.admin'),
        },
        create: { status: 'default', value: false, disabled: false },
        read: { status: 'default', value: false, disabled: false },
        update: { status: 'default', value: false, disabled: false },
        delete: { status: 'default', value: false, disabled: false },
      },
      tooltip: t('editPermission.tooltips.resourcetype.resourcegroup'),
    },
    {
      id: 'role',
      category: 'Manage',
      display: false,
      label: t('resourceTypes.role'),
      access_type: {
        all: false,
        admin: false,
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      edited_access_type: {
        all: { status: 'default', value: false, disabled: false },
        admin: { status: 'default', value: false, disabled: false },
        create: { status: 'default', value: false, disabled: false },
        read: { status: 'default', value: false, disabled: false },
        update: { status: 'default', value: false, disabled: false },
        delete: { status: 'default', value: false, disabled: false },
      },
      tooltip: t('editPermission.tooltips.resourcetype.role'),
    },
    {
      id: 'securitygroup',
      category: 'Infrastructure',
      display: true,
      label: t('resourceTypes.securitygroup'),
      access_type: {
        all: false,
        admin: false,
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      edited_access_type: {
        all: { status: 'default', value: false, disabled: false },
        admin: { status: 'default', value: false, disabled: true },
        create: { status: 'default', value: false, disabled: false },
        read: { status: 'default', value: false, disabled: false },
        update: { status: 'default', value: false, disabled: false },
        delete: { status: 'default', value: false, disabled: false },
      },
      tooltip: t('editPermission.tooltips.resourcetype.securitygroup'),
    },
    {
      id: 'gateway',
      category: 'Infrastructure',
      display: true,
      label: t('resourceTypes.gateway'),
      access_type: {
        all: false,
        admin: false,
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      edited_access_type: {
        all: { status: 'default', value: false, disabled: false },
        admin: {
          status: 'default',
          value: false,
          disabled: false,
          tooltip: t('editPermission.tooltips.checkbox.gateway.admin'),
        },
        create: { status: 'default', value: false, disabled: false },
        read: { status: 'default', value: false, disabled: false },
        update: { status: 'default', value: false, disabled: false },
        delete: { status: 'default', value: false, disabled: false },
      },
      tooltip: t('editPermission.tooltips.resourcetype.gateway'),
    },
    {
      id: 'remoteconnection',
      category: 'Infrastructure',
      display: true,
      label: t('resourceTypes.remoteconnection'),
      access_type: {
        all: false,
        admin: false,
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      edited_access_type: {
        all: { status: 'default', value: false, disabled: false },
        admin: { status: 'default', value: false, disabled: true },
        create: { status: 'default', value: false, disabled: false },
        read: { status: 'default', value: false, disabled: false },
        update: { status: 'default', value: false, disabled: false },
        delete: { status: 'default', value: false, disabled: false },
      },
    },
    {
      id: 'tenant',
      category: 'Manage',
      display: false,
      label: t('resourceTypes.tenant'),
      access_type: {
        all: false,
        admin: false,
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      edited_access_type: {
        all: { status: 'default', value: false, disabled: false },
        admin: { status: 'default', value: false, disabled: false },
        create: { status: 'default', value: false, disabled: false },
        read: { status: 'default', value: false, disabled: false },
        update: { status: 'default', value: false, disabled: false },
        delete: { status: 'default', value: false, disabled: false },
      },
    },
    {
      id: 'secret',
      category: 'Manage',
      display: true,
      label: t('resourceTypes.secret'),
      access_type: {
        all: false,
        admin: false,
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      edited_access_type: {
        all: { status: 'default', value: false, disabled: false },
        admin: { status: 'default', value: false, disabled: true },
        create: { status: 'default', value: false, disabled: false },
        read: { status: 'default', value: false, disabled: false },
        update: { status: 'default', value: false, disabled: false },
        delete: { status: 'default', value: false, disabled: false },
      },
      tooltip: t('editPermission.tooltips.resourcetype.secret'),
    },
    {
      id: 'subscriptionusage',
      category: 'Manage',
      display: true,
      label: t('resourceTypes.subscriptionUsage'),
      access_type: {
        all: false,
        admin: false,
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      edited_access_type: {
        all: { status: 'default', value: false, disabled: true },
        admin: { status: 'default', value: false, disabled: true },
        create: { status: 'default', value: false, disabled: true },
        read: { status: 'default', value: false, disabled: false },
        update: { status: 'default', value: false, disabled: true },
        delete: { status: 'default', value: false, disabled: true },
      },
      tooltip: t('editPermission.tooltips.resourcetype.subscriptionusage'),
    },
    {
      id: 'event',
      category: 'Manage',
      display: true,
      label: t('resourceTypes.event'),
      access_type: {
        all: false,
        admin: false,
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      edited_access_type: {
        all: { status: 'default', value: false, disabled: false },
        admin: { status: 'default', value: false, disabled: true },
        create: { status: 'default', value: false, disabled: false },
        read: { status: 'default', value: false, disabled: false },
        update: { status: 'default', value: false, disabled: false },
        delete: { status: 'default', value: false, disabled: false },
      },
    },
    {
      id: 'cloudlocation',
      category: 'Others',
      display: false,
      label: t('resourceTypes.cloudlocation'),
      access_type: {
        all: false,
        admin: false,
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      edited_access_type: {
        all: { status: 'default', value: false, disabled: false },
        admin: { status: 'default', value: false, disabled: false },
        create: { status: 'default', value: false, disabled: false },
        read: { status: 'default', value: false, disabled: false },
        update: { status: 'default', value: false, disabled: false },
        delete: { status: 'default', value: false, disabled: false },
      },
    },
    {
      id: 'deploymentenvset',
      category: 'Others',
      display: false,
      label: t('resourceTypes.deploymentenvset'),
      access_type: {
        all: false,
        admin: false,
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      edited_access_type: {
        all: { status: 'default', value: false, disabled: false },
        admin: { status: 'default', value: false, disabled: false },
        create: { status: 'default', value: false, disabled: false },
        read: { status: 'default', value: false, disabled: false },
        update: { status: 'default', value: false, disabled: false },
        delete: { status: 'default', value: false, disabled: false },
      },
    },
    {
      id: 'cloudtype',
      category: 'Infrastructure',
      display: false,
      label: t('resourceTypes.cloudtype'),
      access_type: {
        all: false,
        admin: false,
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      edited_access_type: {
        all: { status: 'default', value: false, disabled: false },
        admin: { status: 'default', value: false, disabled: false },
        create: { status: 'default', value: false, disabled: false },
        read: { status: 'default', value: false, disabled: false },
        update: { status: 'default', value: false, disabled: false },
        delete: { status: 'default', value: false, disabled: false },
      },
    },
    {
      id: 'deploymentenvsubtype',
      category: 'Others',
      display: false,
      label: t('resourceTypes.deploymentenvsubtype'),
      access_type: {
        all: false,
        admin: false,
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      edited_access_type: {
        all: { status: 'default', value: false, disabled: false },
        admin: { status: 'default', value: false, disabled: false },
        create: { status: 'default', value: false, disabled: false },
        read: { status: 'default', value: false, disabled: false },
        update: { status: 'default', value: false, disabled: false },
        delete: { status: 'default', value: false, disabled: false },
      },
    },
    {
      id: 'locationcoordinate',
      category: 'Others',
      display: false,
      label: t('resourceTypes.locationcoordinate'),
      access_type: {
        all: false,
        admin: false,
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      edited_access_type: {
        all: { status: 'default', value: false, disabled: false },
        admin: { status: 'default', value: false, disabled: false },
        create: { status: 'default', value: false, disabled: false },
        read: { status: 'default', value: false, disabled: false },
        update: { status: 'default', value: false, disabled: false },
        delete: { status: 'default', value: false, disabled: false },
      },
    },
    {
      id: 'hubsecret',
      category: 'Others',
      display: false,
      label: t('resourceTypes.hubsecret'),
      access_type: {
        all: false,
        admin: false,
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      edited_access_type: {
        all: { status: 'default', value: false, disabled: false },
        admin: { status: 'default', value: false, disabled: false },
        create: { status: 'default', value: false, disabled: false },
        read: { status: 'default', value: false, disabled: false },
        update: { status: 'default', value: false, disabled: false },
        delete: { status: 'default', value: false, disabled: false },
      },
    },
    {
      id: 'blank',
      category: 'Others',
      display: false,
      label: t('resourceTypes.blank'),
      access_type: {
        all: false,
        admin: false,
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      edited_access_type: {
        all: { status: 'default', value: false, disabled: false },
        admin: { status: 'default', value: false, disabled: false },
        create: { status: 'default', value: false, disabled: false },
        read: { status: 'default', value: false, disabled: false },
        update: { status: 'default', value: false, disabled: false },
        delete: { status: 'default', value: false, disabled: false },
      },
    },
    {
      id: 'tepolicypath',
      category: 'Others',
      display: false,
      label: t('resourceTypes.tepolicypath'),
      access_type: {
        all: false,
        admin: false,
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      edited_access_type: {
        all: { status: 'default', value: false, disabled: false },
        admin: { status: 'default', value: false, disabled: false },
        create: { status: 'default', value: false, disabled: false },
        read: { status: 'default', value: false, disabled: false },
        update: { status: 'default', value: false, disabled: false },
        delete: { status: 'default', value: false, disabled: false },
      },
    },
    {
      id: 'tetunnel',
      category: 'Others',
      display: false,
      label: t('resourceTypes.tetunnel'),
      access_type: {
        all: false,
        admin: false,
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      edited_access_type: {
        all: { status: 'default', value: false, disabled: false },
        admin: { status: 'default', value: false, disabled: false },
        create: { status: 'default', value: false, disabled: false },
        read: { status: 'default', value: false, disabled: false },
        update: { status: 'default', value: false, disabled: false },
        delete: { status: 'default', value: false, disabled: false },
      },
    },
    {
      id: 'gatewaydeploymentoperation',
      category: 'Others',
      display: false,
      label: t('resourceTypes.gatewaydeploymentoperation'),
      access_type: {
        all: false,
        admin: false,
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      edited_access_type: {
        all: { status: 'default', value: false, disabled: false },
        admin: { status: 'default', value: false, disabled: false },
        create: { status: 'default', value: false, disabled: false },
        read: { status: 'default', value: false, disabled: false },
        update: { status: 'default', value: false, disabled: false },
        delete: { status: 'default', value: false, disabled: false },
      },
    },
  ];

  return config;
};

export const resourceGroupsMap: any = {
  infrastructure: [],
  application: [],
};

export const permissionTableCategories = [
  { key: 'manage-category', name: 'Manage' },
  { key: 'infrastructure-category', name: 'Infrastructure' },
  { key: 'applications-category', name: 'Applications' },
];

export const resourceGroupCategories = (
  t: TFunction<'roleDetails', undefined>
) => [
  { key: 'infrastructure', name: t('resourceGroupCategories.infrastructure') },
  { key: 'application', name: t('resourceGroupCategories.application') },
];

export const tabConfig = (t: TFunction<'roleDetails', undefined>) => {
  return [
    {
      className: 'tab-resource-type',
      key: 'resource-type',
      label: t('rolePermissionTable.tab.resourceType'),
    },
    {
      className: 'tab-resource-group',
      key: 'resource-group',
      label: t('rolePermissionTable.tab.resourceGroup'),
    },
  ];
};

export const permitEditBasedOnRole = (name: string) => {
  let allow: boolean = true;

  switch (name) {
    case 'Admin':
      allow = false;
      break;
    case 'HubAdmin':
      allow = false;
      break;
    case 'RBACManager':
      allow = false;
      break;
    case 'CloudOps':
      allow = false;
      break;
    case 'DevOps':
      allow = false;
      break;
    default:
      allow = true;
      break;
  }

  return allow;
};
