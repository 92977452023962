import { TFunction } from 'react-i18next';
export interface RowTypeHeader {
  id: string;
  tableGroup: string;
  type?: string;
}
export interface AccessProperty {
  create: {
    value: boolean;
  };
  read: {
    value: boolean;
  };
  delete: {
    value: boolean;
  };
  update: {
    value: boolean;
  };
  admin: {
    value: boolean;
    tooltip?: string;
  };
}
export interface ResourceType {
  id: string;
  type: string;
  value: string;
  tooltip: string;
  accessProperties: AccessProperty;
}
export interface ResourceGroupData {
  resource_id: string;
  name: string;
  type: string;
  description: string;
  created_at: string;
  updated_at: string;
  groups: AccessProperty;
  resources: AccessProperty;
}

export const getHeadersForResourceTable = (
  t: TFunction<'createRole'>,
  type: string
) => {
  if (type === 'resourceGroup') {
    return [
      {
        key: 'tableGroup',
        header: '',
      },
      {
        key: 'create',
        header: t('rolePermissions.headers.create'),
        tooltipGroup: null,
        tooltipResource: t('rolePermissions.tooltips.createResource'),
      },
      {
        key: 'read',
        header: t('rolePermissions.headers.read'),
        tooltipGroup: t('rolePermissions.tooltips.readGroup'),
        tooltipResource: t('rolePermissions.tooltips.readResource'),
      },
      {
        key: 'update',
        header: t('rolePermissions.headers.update'),
        tooltipGroup: t('rolePermissions.tooltips.updateGroup'),
        tooltipResource: t('rolePermissions.tooltips.updateResource'),
      },
      {
        key: 'delete',
        header: t('rolePermissions.headers.delete'),
        tooltipGroup: t('rolePermissions.tooltips.deleteGroup'),
        tooltipResource: t('rolePermissions.tooltips.deleteResource'),
      },
      {
        key: 'admin',
        header: t('rolePermissions.headers.admin'),
        tooltipGroup: t('rolePermissions.tooltips.adminGroup'),
        tooltipResource: t('rolePermissions.tooltips.adminResource'),
      },
    ];
  }
  return [
    {
      key: 'tableGroup',
      header: '',
    },
    {
      key: 'create',
      header: t('rolePermissions.headers.create'),
      tooltipGroup: t('rolePermissions.tooltips.createGroup'),
      tooltipResource: t('rolePermissions.tooltips.createResource'),
    },
    {
      key: 'read',
      header: t('rolePermissions.headers.read'),
      tooltipGroup: t('rolePermissions.tooltips.readGroup'),
      tooltipResource: t('rolePermissions.tooltips.readResource'),
    },
    {
      key: 'update',
      header: t('rolePermissions.headers.update'),
      tooltipGroup: t('rolePermissions.tooltips.updateGroup'),
      tooltipResource: t('rolePermissions.tooltips.updateResource'),
    },
    {
      key: 'delete',
      header: t('rolePermissions.headers.delete'),
      tooltipGroup: t('rolePermissions.tooltips.deleteGroup'),
      tooltipResource: t('rolePermissions.tooltips.deleteResource'),
    },
    {
      key: 'admin',
      header: t('rolePermissions.headers.admin'),
      tooltipGroup: t('rolePermissions.tooltips.adminGroup'),
      tooltipResource: t('rolePermissions.tooltips.adminResource'),
    },
  ];
};

export const getDataByResourceType = (t: TFunction<'createRole'>) => [
  {
    id: 'manage',
    tableGroup: t('rolePermissions.manage'),
  },
  {
    id: 'infrastructure',
    tableGroup: t('rolePermissions.infrastructure'),
  },
  {
    id: 'applications',
    tableGroup: t('rolePermissions.apps'),
  },
];

export const getResourceTypePermissionsFormData = (
  t: TFunction<'createRole'>
) => [
  {
    manage: {
      allIdentities: {
        id: 'allIdentities',
        value: t('rolePermissions.identitites'),
        type: 'identity',
        tooltip: t('rolePermissions.tooltips.identitites'),
        accessProperties: {
          create: '',
          read: {
            value: false,
          },
          update: '',
          delete: '',
          admin: '',
        },
      },
      allResourceGroups: {
        id: 'allResourceGroups',
        value: t('rolePermissions.resourceGroup'),
        type: 'resourcegroup',
        tooltip: t('rolePermissions.tooltips.resourceGroup'),
        accessProperties: {
          create: {
            value: false,
          },
          read: {
            value: false,
          },
          update: {
            value: false,
          },
          delete: {
            value: false,
          },
          admin: {
            value: false,
            tooltip: t('rolePermissions.tooltips.adminResourceGroup'),
          },
        },
      },
      allSecrets: {
        id: 'allSecrets',
        value: t('rolePermissions.secret'),
        type: 'secret',
        tooltip: t('rolePermissions.tooltips.secret'),
        accessProperties: {
          create: {
            value: false,
          },
          read: {
            value: false,
          },
          update: {
            value: false,
          },
          delete: {
            value: false,
          },
          admin: '',
        },
      },
      subscriptionUsage: {
        id: 'subscriptionUsage',
        value: t('rolePermissions.subscriptionusage'),
        type: 'subscriptionusage',
        tooltip: t('rolePermissions.tooltips.subscriptionusage'),
        accessProperties: {
          create: '',
          read: {
            value: false,
          },
          update: '',
          delete: '',
          admin: '',
        },
      },
      allEvents: {
        id: 'allEvents',
        value: t('rolePermissions.events'),
        type: 'event',
        tooltip: t('rolePermissions.tooltips.events'),
        accessProperties: {
          create: {
            value: false,
          },
          read: {
            value: false,
          },
          update: {
            value: false,
          },
          delete: {
            value: false,
          },
          admin: '',
        },
      },
    },
    infrastructure: {
      allNetworkSegments: {
        id: 'allNetworkSegments',
        value: t('rolePermissions.networksegment'),
        type: 'networksegment',
        tooltip: t('rolePermissions.tooltips.networksegment'),
        accessProperties: {
          create: {
            value: false,
          },
          read: {
            value: false,
          },
          update: {
            value: false,
          },
          delete: {
            value: false,
          },
          admin: '',
        },
      },
      allClouds: {
        id: 'allClouds',
        value: t('rolePermissions.cloud'),
        type: 'cloud',
        tooltip: t('rolePermissions.tooltips.cloud'),
        accessProperties: {
          create: {
            value: false,
          },
          read: {
            value: false,
          },
          update: {
            value: false,
          },
          delete: {
            value: false,
          },
          admin: {
            value: false,
            tooltip: t('rolePermissions.tooltips.adminCloud'),
          },
        },
      },
      allLocations: {
        id: 'allLocations',
        value: t('rolePermissions.location'),
        type: 'location',
        tooltip: t('rolePermissions.tooltips.location'),
        accessProperties: {
          create: {
            value: false,
          },
          read: {
            value: false,
          },
          update: {
            value: false,
          },
          delete: {
            value: false,
          },
          admin: '',
        },
      },
      allClusters: {
        id: 'allClusters',
        value: t('rolePermissions.cluster'),
        type: 'cluster',
        tooltip: t('rolePermissions.tooltips.cluster'),
        accessProperties: {
          create: {
            value: false,
          },
          read: {
            value: false,
          },
          update: {
            value: false,
          },
          delete: {
            value: false,
          },
          admin: {
            value: false,
            tooltip: t('rolePermissions.tooltips.adminCluster'),
          },
        },
      },
      allVPCs: {
        id: 'allVPCs',
        value: t('rolePermissions.vpc'),
        type: 'vpc',
        tooltip: t('rolePermissions.tooltips.vpc'),
        accessProperties: {
          create: {
            value: false,
          },
          read: {
            value: false,
          },
          update: {
            value: false,
          },
          delete: {
            value: false,
          },
          admin: '',
        },
      },
      allNodes: {
        id: 'allNodes',
        value: t('rolePermissions.node'),
        type: 'node',
        tooltip: t('rolePermissions.tooltips.node'),
        accessProperties: {
          create: {
            value: false,
          },
          read: {
            value: false,
          },
          update: {
            value: false,
          },
          delete: {
            value: false,
          },
          admin: '',
        },
      },
      allNamespaces: {
        id: 'allNamespaces',
        value: t('rolePermissions.namespace'),
        type: 'namespace',
        tooltip: t('rolePermissions.tooltips.namespace'),
        accessProperties: {
          create: {
            value: false,
          },
          read: {
            value: false,
          },
          update: {
            value: false,
          },
          delete: {
            value: false,
          },
          admin: {
            value: false,
            tooltip: t('rolePermissions.tooltips.adminNamespace'),
          },
        },
      },
      allSecurityGroups: {
        id: 'allSecurityGroups',
        value: t('rolePermissions.securityGroup'),
        type: 'securitygroup',
        tooltip: t('rolePermissions.tooltips.securityGroup'),
        accessProperties: {
          create: {
            value: false,
          },
          read: {
            value: false,
          },
          update: {
            value: false,
          },
          delete: {
            value: false,
          },
          admin: '',
        },
      },
      allGateways: {
        id: 'allGateways',
        value: t('rolePermissions.gateway'),
        type: 'gateway',
        tooltip: t('rolePermissions.tooltips.gateway'),
        accessProperties: {
          create: {
            value: false,
          },
          read: {
            value: false,
          },
          update: {
            value: false,
          },
          delete: {
            value: false,
          },
          admin: {
            value: false,
            tooltip: t('rolePermissions.tooltips.adminGateways'),
          },
        },
      },
      allRemoteConnections: {
        id: 'allRemoteConnections',
        value: t('rolePermissions.remoteConnection'),
        type: 'remoteconnection',
        tooltip: t('rolePermissions.tooltips.remoteConnection'),
        accessProperties: {
          create: {
            value: false,
          },
          read: {
            value: false,
          },
          update: {
            value: false,
          },
          delete: {
            value: false,
          },
          admin: '',
        },
      },
    },
    applications: {
      allPolicies: {
        id: 'allPolicies',
        value: t('rolePermissions.policies'),
        type: 'policy',
        tooltip: t('rolePermissions.tooltips.policies'),
        accessProperties: {
          create: {
            value: false,
          },
          read: {
            value: false,
          },
          update: {
            value: false,
          },
          delete: {
            value: false,
          },
          admin: {
            value: false,
            tooltip: t('rolePermissions.tooltips.adminPolicies'),
          },
        },
      },
      allApplications: {
        id: 'allApplications',
        value: t('rolePermissions.applications'),
        type: 'app',
        tooltip: t('rolePermissions.tooltips.applications'),
        accessProperties: {
          create: {
            value: false,
          },
          read: {
            value: false,
          },
          update: {
            value: false,
          },
          delete: {
            value: false,
          },
          admin: {
            value: false,
            tooltip: t('rolePermissions.tooltips.adminApplication'),
          },
        },
      },
      allServices: {
        id: 'allServices',
        value: t('rolePermissions.services'),
        type: 'service',
        tooltip: t('rolePermissions.tooltips.services'),
        accessProperties: {
          create: {
            value: false,
          },
          read: {
            value: false,
          },
          update: {
            value: false,
          },
          delete: {
            value: false,
          },
          admin: '',
        },
      },
      allDeployments: {
        id: 'allDeployments',
        value: t('rolePermissions.deployments'),
        type: 'deployment',
        tooltip: t('rolePermissions.tooltips.deployments'),
        accessProperties: {
          create: {
            value: false,
          },
          read: {
            value: false,
          },
          update: {
            value: false,
          },
          delete: {
            value: false,
          },
          admin: '',
        },
      },
      allInstances: {
        id: 'allInstances',
        value: t('rolePermissions.instances'),
        type: 'instance',
        tooltip: t('rolePermissions.tooltips.instances'),
        accessProperties: {
          create: {
            value: false,
          },
          read: {
            value: false,
          },
          update: {
            value: false,
          },
          delete: {
            value: false,
          },
          admin: '',
        },
      },
      allServiceEndpoints: {
        id: 'allServiceEndpoints',
        value: t('rolePermissions.serviceEndpoints'),
        type: 'service_endpoint',
        tooltip: t('rolePermissions.tooltips.serviceEndpoints'),
        accessProperties: {
          create: {
            value: false,
          },
          read: {
            value: false,
          },
          update: {
            value: false,
          },
          delete: {
            value: false,
          },
          admin: '',
        },
      },
    },
  },
];

export const getDataByResourceGroup = (t: TFunction<'createRole'>) => [
  {
    id: 'infrastructureGroup',
    tableGroup: t('rolePermissions.infraGroup'),
    type: 'infrastructureGroup',
  },
  {
    id: 'applicationGroup',
    tableGroup: t('rolePermissions.appGroup'),
    type: 'applicationGroup',
  },
];

export const getResourceGroupColumns = (t: TFunction<'createRole'>) => [
  {
    groups: {
      create: '',
      read: {
        value: false,
      },
      update: {
        value: false,
      },
      delete: {
        value: false,
      },
      admin: {
        value: false,
      },
    },
    resources: {
      create: {
        value: false,
      },
      read: {
        value: false,
      },
      update: {
        value: false,
      },
      delete: {
        value: false,
      },
      admin: {
        value: false,
      },
    },
  },
];
