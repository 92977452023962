import { AxiosError } from 'axios';
import {
  ApplicationData,
  Deployment,
  Service,
  ServiceData,
} from '../models/master';
import { axiosInstance } from './api';
import { MCNM_APP_API_URL } from './config';

export const getApplications = async (
  fetchDeployments = false,
  fetchServices = false
) => {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_APP_API_URL}/application`,
    });
    // need to make separate API calls to get the services and deployments for each application
    const applicationsData = await Promise.all(
      response.data.apps.map(async (app: ApplicationData) => {
        const appId = app.resource_id;
        let deployments: Deployment[] = [];
        let services: ServiceData[] = [];
        let servicePermission = true;
        let deploymentPermission = true;
        if (fetchDeployments) {
          try {
            await getDeployments(appId).then(response => {
              deployments = response;
            });
          } catch (error) {
            const axiosError = error as AxiosError;
            if (axiosError.response?.status === 403) {
              console.log(error);
              deploymentPermission = false;
            }
          }
          app['deploymentCount'] =
            deployments && Array.isArray(deployments) ? deployments?.length : 0;
        }
        if (fetchServices) {
          try {
            await getApplicationServices(appId).then(response => {
              services = response?.svcs;
            });
          } catch (error) {
            const axiosError = error as AxiosError;
            if (axiosError.response?.status === 403) {
              console.log(error);
              servicePermission = false;
            }
          }
          app['serviceCount'] =
            services && Array.isArray(services) ? services?.length : 0;
        }
        return {
          ...app,
          services,
          deployments,
          servicePermission,
          deploymentPermission,
        };
      })
    );
    return applicationsData;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export async function getApplicationService(
  application_id: string,
  service_id: string
) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_APP_API_URL}/application/${application_id}/service/${service_id}`,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getApplicationServices(application_id: string) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_APP_API_URL}/application/${application_id}/service`,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getDeployments(application_id: string) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_APP_API_URL}/application/${application_id}/deployment`,
    });

    return response.data.deps;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getApplication(
  application_id: string,
  fetchDeployments = true,
  fetchServices = true
) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_APP_API_URL}/application/${application_id}`,
    });
    let deployments: Deployment[] = [];
    let services: Service[] = [];
    let servicePermission = true;
    let deploymentPermission = true;

    if (fetchServices) {
      try {
        const response = await getApplicationServices(application_id);
        services = response.svcs;
      } catch (error: any) {
        if (
          error?.response?.status === 403 &&
          error?.response?.statusText === 'Forbidden'
        ) {
          servicePermission = false;
        }
      }
    }

    if (fetchDeployments) {
      try {
        deployments = await getDeployments(application_id);
      } catch (error: any) {
        if (
          error?.response?.status === 403 &&
          error?.response?.statusText === 'Forbidden'
        ) {
          deploymentPermission = false;
        }
      }
    }

    return {
      ...response.data,
      services,
      deployments,
      servicePermission,
      deploymentPermission,
    };
  } catch (error) {
    throw error;
  }
}

// Get all app deployments that MCNM oversees
export async function getApplicationsDeployments() {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_APP_API_URL}/deployment`,
    });
    if (response.data.deps === null) {
      return [];
    } else {
      return response.data?.deps;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
}
