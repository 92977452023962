import React from 'react';
import { useTranslation } from 'react-i18next';

import { CreateTearsheetStep } from '@carbon/ibm-products';
import { Column, Row, TextInput, TextArea } from 'carbon-components-react';

import AddLabels from '../../../../components/AddLabels/AddLabels';
import { FormData } from '../CreateRole';

import './RoleDetails.scss';

interface Props {
  onChange: (name: string, value: any) => void;
  formData: FormData;
  formValid: boolean;
  children: any;
}

const RoleDetails: React.FC<Props> = ({
  onChange,
  formData,
  formValid,
  children,
}) => {
  const { t } = useTranslation('createRole');
  const { name, description } = formData;
  return (
    <>
      {/* Render error snackbar */}
      {children}

      <CreateTearsheetStep
        className='create-role-details-step'
        title={t('roleDetails.title')}
        subtitle={t('roleDetails.subtitle')}
        disableSubmit={!formValid}
      >
        <Row className='row'>
          <Column md={8}>
            <TextInput
              id='role-name'
              labelText={t('roleDetails.name.label')}
              placeholder={t('roleDetails.name.placeholder')}
              name='name'
              value={name.value}
              onChange={e => onChange('name', e.target.value)}
              maxLength={60}
              invalid={name.error}
              invalidText={name.errorMessage}
            />
          </Column>
        </Row>
        <Row className='row'>
          <Column md={8}>
            <TextArea
              id='role-description'
              labelText={t('roleDetails.description.label')}
              placeholder={t('roleDetails.description.placeholder')}
              name='description'
              value={description.value}
              onChange={e => onChange('description', e.target.value)}
              maxCount={300}
              enableCounter={true}
            />
          </Column>
        </Row>
        <Row className='row'>
          <Column md={8}>
            <AddLabels
              id='role-details-labels'
              labelText={t('roleDetails.labels.label')}
              placeholder={t('roleDetails.labels.placeholder')}
              onChange={data => onChange('labels', data)}
              btnText={t('roleDetails.labels.labelbtnText')}
              btnKind='secondary'
              tagType='green'
              defaultValues={formData.labels.value}
            />
          </Column>
        </Row>
      </CreateTearsheetStep>
    </>
  );
};

export default RoleDetails;
