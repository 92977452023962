import React from 'react';
import { useTranslation } from 'react-i18next';

import { CreateTearsheetStep } from '@carbon/ibm-products';
import { Tooltip } from '@carbon/react';

import { Information16 } from '@carbon/icons-react';
import { CloudType } from '../../../models/master';
import images from '../../../images/images';
import { getThirdPartyCloudIcons } from '../ThirdPartyCloudIcons';
import { SkeletonPlaceholder } from 'carbon-components-react';

interface Props {
  selectedCloud: any;
  onSelect: any;
  cloudTypes: CloudType[];
  onNext: () => void;
  onMount: () => void;
  isFetchingCloudTypes: boolean;
}

const CloudSelect: React.FC<Props> = ({
  selectedCloud,
  onSelect,
  cloudTypes,
  onNext,
  onMount,
  isFetchingCloudTypes,
}) => {
  const { t } = useTranslation('registerCloud');

  return (
    <CreateTearsheetStep
      className='cloud-selection-step'
      fieldsetLegendText={t('cloudSelect.fieldsetLegendText')}
      title={t('cloudSelect.title')}
      disableSubmit={selectedCloud === null}
      onNext={onNext}
      onMount={onMount}
    >
      <div className='cloud-select-container'>
        {isFetchingCloudTypes ? (
          <div className='skeleton-container'>
            <SkeletonPlaceholder className='skeleton-tile' />
            <SkeletonPlaceholder className='skeleton-tile' />
            <SkeletonPlaceholder className='skeleton-tile' />
            <SkeletonPlaceholder className='skeleton-tile' />
            <SkeletonPlaceholder className='skeleton-tile' />
          </div>
        ) : (
          cloudTypes?.map(cloud => {
            return (
              <div
                className={
                  selectedCloud && selectedCloud.code === cloud.code
                    ? 'cloud-card cloud-card-border'
                    : 'cloud-card'
                }
                key={cloud.code}
                onClick={() => onSelect(cloud)}
              >
                <div className='title'>
                  {cloud.name}
                  <Tooltip description={cloud.description} align='top'>
                    <button
                      className='cloud-desc-tooltip'
                      onClick={e => e.preventDefault()}
                    >
                      <Information16 />
                    </button>
                  </Tooltip>
                </div>
                {selectedCloud && selectedCloud.code === cloud.code && (
                  <div className='cloud-selected'>
                    {images.selectedTickIcon()}
                  </div>
                )}
                <div className='cloud-icon'>
                  {getThirdPartyCloudIcons(cloud?.code)}
                </div>
              </div>
            );
          })
        )}
      </div>
    </CreateTearsheetStep>
  );
};

export default CloudSelect;
