import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import GenericTableWithFilters from '../../../components/GenericTableWithFilters/GenericTableWithFilters';

import dateUtils from '../../../lib/dates';
import images from '../../../images/images.js';

import { Location, AppliedFilter } from '../../../models/master';

import './LocationsTable.scss';
import LabelTag from '../../../components/LabelTag/LabelTag';
import GenericTruncateString from '../../../components/GenericTruncateString/GenericTruncateString';
import IconWithToolTip from '../../../components/IconWithToolTip/IconWithToolTip';

interface TableRow {
  id: any;
  name: string;
  name__format: JSX.Element;
  type: string;
  cloud: string;
  cloud__format: JSX.Element;
  city: string;
  country: string;
  labels: JSX.Element | null | string;
  lastUpdated: string;
}

interface Filter {
  key: string;
  type: 'multi' | 'single';
  values: string[];
}

const LocationsTable = (props: {
  filteredDataCallback: (data: any) => void;
  filtersSelected: any;
  filtersAppliedCallback: (data: any) => void;
  filters: any;
  rows: Location[] | null;
  data: Location[] | null;
  filteredDataSet: Location[] | null;
  elementCount: number;
  onRefresh: () => void;
  currentPageNumber: number;
  currentPageSize: number;
  onPageChange: (pageData: any) => void;
  sortRows(arg0: unknown, direction: string): void;
  leftInlineFilters?: Filter[];
  visibilityFlag?: string;
  persistFilter?: boolean;
  typeList: any;
  error403Flag?: boolean;
  error500Flag: boolean;
  dataLoading: boolean;
}) => {
  const { t } = useTranslation('location');
  const { rows, onRefresh } = props;
  const error403Flag = props.error403Flag;
  const error500Flag = props.error500Flag;
  const tableRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const formatTags = (data: string[]) => {
    return <LabelTag labelArray={data} count={3} />;
  };

  const myheaders = [
    {
      key: 'name__format',
      originalKey: 'name',
      header: t('tableHeaders.name'),
      sort: true,
    },
    {
      key: 'cloud__format',
      originalKey: 'cloud_name',
      header: t('tableHeaders.cloud'),
      sort: true,
    },
    {
      key: 'type',
      originalKey: 'type',
      header: t('tableHeaders.type'),
      sort: true,
    },
    {
      key: 'city',
      originalKey: 'city',
      header: t('tableHeaders.city'),
      sort: true,
    },
    {
      key: 'country',
      originalKey: 'country',
      header: t('tableHeaders.country'),
      sort: true,
    },
    {
      key: 'labels',
      originalKey: 'name',
      header: t('tableHeaders.labels'),
    },
    {
      key: 'lastUpdated',
      originalKey: 'updated_at',
      header: t('tableHeaders.lastUpdated'),
      sort: true,
      style: { minWidth: '12.5rem' },
    },
  ];

  const getTypeValue = (row: Location) => {
    const typeValue = props.typeList.find(
      ({ code }: { code: string }) => code === row?.type
    );
    return !!typeValue?.label ? typeValue?.label : '—';
  };

  /**
   * @description - Function to format the raw data into table rows format.
   * @returns {TableRow[] | [] | null} - Returns the formatted data to be rendered as table rows.
   */
  const setRowsData = () => {
    let formattedRows: TableRow[] = [];
    if (error403Flag) return [];
    if (error500Flag) return [];
    if (rows && rows.length === 0) return [];
    if (rows)
      rows.map((row: Location) => {
        formattedRows.push({
          id: row.resource_id,
          name: !!row.name ? row.name : '—',
          name__format: (
            <div>
              <Link
                className='no-underline-link'
                to={`/locationDetails?cloudId=${row.cloud_id}&locationId=${row.resource_id}`}
              >
                <GenericTruncateString str={row.name} tableView={true} />
                {row?.unmanaged && (
                  <div className='icon'>
                    <IconWithToolTip
                      icon={<div className='unmanaged-icon' />}
                      iconDescription={t('unmanagedLocation')}
                    />
                  </div>
                )}
              </Link>
            </div>
          ),
          labels: !!row.labels ? formatTags(row.labels) : '—',
          cloud: !!row.cloud_name ? row.cloud_name : '—',
          cloud__format: (
            <div>
              <GenericTruncateString
                str={row.cloud_name ?? '-'}
                tableView={true}
              />
            </div>
          ),
          city: !!row.city ? row.city : '—',
          country: !!row.country ? row.country : '—',
          type: getTypeValue(row),
          lastUpdated: !!row.updated_at
            ? dateUtils.getUserFriendlyDate(row.updated_at)
            : '—',
        });
        return 0;
      });
    else return null;
    return formattedRows;
  };

  const emptyStateData = {
    icon: images.noLocationsLarge(),
    notFoundIcon: images.NotFoundLarge(),
    emptyStateHeader: t('emptyState.emptyContainerHeader'),
    emptyStateDescription: t('emptyState.emptyContainerDescription'),
    link: '/',
    buttonText: t('emptyState.buttonText'),
    click: () =>
      navigate('/locations', {
        state: { resourceType: 'LOCATION' },
      }),
  };

  return (
    <div className='locations-list-table' ref={tableRef}>
      <GenericTableWithFilters
        id='table-with-filter-panel'
        rows={setRowsData()}
        data={props.filteredDataSet}
        headers={myheaders}
        isSortable={true}
        totalElementsCount={props.elementCount ? props.elementCount : 0}
        fullData={props.data}
        onTableRefresh={() => onRefresh()}
        filteredDataCallback={(data: TableRow[]) =>
          props.filteredDataCallback(data)
        }
        selectedFiltersVal={props.filtersSelected as any}
        setFilterApplied={(data: AppliedFilter[]) =>
          props.filtersAppliedCallback(data)
        }
        filters={props.filters}
        currentPageNumber={props.currentPageNumber}
        currentPageSize={props.currentPageSize}
        onPageChange={(pageData: any) => props.onPageChange(pageData)}
        emptyState={emptyStateData}
        sortRows={(data, sortDirection) => props.sortRows(data, sortDirection)}
        leftInlineFilters={props.leftInlineFilters}
        visibilityFlag={props.visibilityFlag}
        persistFilter={props.persistFilter}
        render403Container={props.error403Flag}
        render500Container={props.error500Flag}
        tableDataLoading={props.dataLoading}
      />
    </div>
  );
};

export default LocationsTable;
