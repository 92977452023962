import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header/Header';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { gatewaySet } from './config';
import {
  SkeletonPlaceholder,
  ButtonSkeleton,
  Button,
  Modal,
  DataTableSkeleton,
} from 'carbon-components-react';
import {
  Error500Type,
  NetworkSegmentData,
  NetworkSegments,
} from '../../models/master';
import { getResourceGroups } from '../../controllers/resourceGroupApi';
import {
  getNetworkSegmentDetails,
  deleteNetworkSegment,
  getNetworkSegments,
} from '../../controllers/networksegmentsApi';
import DetailsCard from '../../components/DetailsCard/DetailsCard';
import { NotificationContext } from '../../components/Notifications/Context/NotificationProvider';
import { AxiosError } from 'axios';
import CreateNetworkSegment from '../NetworkSegmentsContainer/CreateNetworkSegment/CreateNetworkSegment';
import { ResourceGroup } from '../../models/master';
import Error500 from '../Errors/Error500';

import './NetworkSegmentDetails.scss';
import useAnalytics from '../../lib/useAnalytics';
import NetworkSegmentResourcesList from './NetworkSegmentResources/NetworkSegmentResourcesList';
import { NetworkSegmentCompatibilitySet } from '../../lib/enums';
import { DEFAULT_NETWORK_SEGMENT_ID } from '../../lib/constants';
import Error403Card from '../../components/ErrorState/Error403Card';

type NetworkSegmentState = {
  breadcrumb?: any;
};

const defaultPermissionMap = {
  resourceGroups: true,
  networkSegmentDetails: true,
  networkSegmentList: true,
};

const NetworkSegmentDetails = () => {
  const { t } = useTranslation('networkSegmentDetails');
  const [networkSegmentData, setNetworkSegmentData] =
    useState<NetworkSegmentData | null>(null);
  const [networkSegmentDataLoading, toggleNetworkSegmentDataLoading] =
    useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const networkSegmentId = searchParams.get('nwSegId');
  const [showEditNetworkSegment, setShowEditNetworkSegment] =
    useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [error500, setError500] = useState<null | Error500Type>(null);

  const notification = useContext(NotificationContext);
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as NetworkSegmentState;

  const [resourceGroupData, setResourceGroupsData] = useState<ResourceGroup[]>(
    []
  );
  const [networkSegments, setNetworkSegments] = useState<
    NetworkSegments[] | null
  >(null);
  const [permissionMap, setPermissionMap] = useState(defaultPermissionMap);
  const updateNetworkSegmentData = (networkSegmentData: NetworkSegmentData) => {
    setNetworkSegmentData(networkSegmentData);
  };

  const { pageViewed } = useAnalytics();

  const fetchResourceGroups = async () => {
    try {
      const response = await getResourceGroups(true);
      const resourceData = response.resource_groups as ResourceGroup[];
      setResourceGroupsData(resourceData ?? []);
    } catch (error) {
      console.error(error);
      const axiosErr = error as AxiosError;
      if (axiosErr?.response?.status === 403) {
        setPermissionMap(permissionMap => ({
          ...permissionMap,
          resourceGroups: false,
        }));
      }
    }
  };

  const fetchNwSegmentsList = async () => {
    try {
      const resp = await getNetworkSegments();
      setNetworkSegments(resp);
    } catch (error) {
      console.error(error);
      const axiosErr = error as AxiosError;
      if (axiosErr?.response?.status === 403) {
        setPermissionMap(permissionMap => ({
          ...permissionMap,
          networkSegmentList: false,
        }));
      }
    }
  };

  useEffect(() => {
    pageViewed('Network Segment Details');
    toggleNetworkSegmentDataLoading(true);
    refreshPage();
  }, []);

  useEffect(() => {
    networkSegmentData !== null
      ? toggleNetworkSegmentDataLoading(false)
      : toggleNetworkSegmentDataLoading(true);
  }, [networkSegmentData]);

  const refreshPage = async () => {
    try {
      setPermissionMap(defaultPermissionMap);
      setNetworkSegmentData(null);
      await fetchResourceGroups();
      await fetchNwSegmentsList();
      await getNetworkSegmentDetails(networkSegmentId)
        .then(data => {
          setNetworkSegmentData(data as NetworkSegmentData);
        })
        .catch((error: any) => {
          if (error?.response?.status === 404) {
            navigate('/404');
          }

          if (error?.response?.status === 403) {
            setPermissionMap(permissionMap => ({
              ...permissionMap,
              networkSegmentDetails: false,
            }));
          }

          if (error.response?.status >= 500) {
            setError500(error.response?.status?.toString());
          }
          console.log(error);
        });
    } catch (error: any) {
      console.log(error);
    } finally {
      toggleNetworkSegmentDataLoading(false);
    }
  };

  const onClose = () => {
    setShowEditNetworkSegment(false);
  };

  const editBtnClick = () => {
    setShowEditNetworkSegment(true);
  };

  const getDeleteButton = (disabled: boolean = false, className?: string) => (
    <div
      className='delete-network-segment'
      data-testid='network-segment-delete-button'
    >
      {
        <Button
          kind='danger--ghost'
          disabled={disabled}
          className={className}
          onClick={() => {
            setOpenDeleteModal(true);
          }}
        >
          {t('delete.deleteNetworkSegment')}
        </Button>
      }
    </div>
  );

  const submitDeleteRequest = async () => {
    try {
      setDisableButton(true);
      await deleteNetworkSegment(networkSegmentId);
      notification.onTrigger('TOAST', {
        title: t('delete.successNotification.title'),
        subtitle: t('delete.successNotification.description', {
          networkSegmentName: networkSegmentData?.name ?? '',
        }),
      });
      navigate('/networkSegments');
    } catch (error: any) {
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        notification.onTrigger('TOAST', {
          title: t('delete.error.authErrorTitle'),
          kind: 'error',
          subtitle: t('delete.error.authErrorSubtitle'),
        });
      }
      const errorMessage: string =
        error.response !== undefined
          ? error.response['customErrorMessage']
          : '';
      notification.onTrigger('TOAST', {
        title: t('delete.error.title'),
        kind: 'error',
        subtitle:
          errorMessage.length > 0
            ? errorMessage
            : t('delete.error.errorMsg', {
                networkSegmentName: networkSegmentData?.name ?? '',
              }),
      });
    } finally {
      setDisableButton(false);
      setOpenDeleteModal(false);
    }
  };

  const getResourceGroupName = (id: string | undefined) => {
    const resourceGroup =
      id && Array.isArray(resourceGroupData)
        ? resourceGroupData!.find(
            resourceGroup => resourceGroup.resource_id === id
          )
        : null;

    return resourceGroup ? resourceGroup.name : '-';
  };

  const getFormattedNetworkDetails = () => {
    let detailsData = [
      {
        key: 'name',
        value: networkSegmentData?.name ? networkSegmentData?.name : '—',
      },
      {
        key: 'gatewaySet',
        value: gatewaySet.find(
          (gateway: any) =>
            networkSegmentData?.compatibility_set === gateway.value
        )?.name,
      },
      {
        key: 'created',
        value: networkSegmentData?.created_at
          ? networkSegmentData?.created_at
          : '—',
      },
      {
        key: 'infrastructureGroup',
        value: getResourceGroupName(networkSegmentData?.['resource_group_id']),
      },
      {
        key: 'labels',
        value: networkSegmentData?.labels ? networkSegmentData?.labels : '—',
      },
    ];
    if (networkSegmentData?.compatibility_set === 'RHSI') {
      detailsData.splice(3, 0, {
        key: 'metricCollection',
        value: networkSegmentData?.flow_collector
          ? networkSegmentData?.flow_collector?.toString()
          : 'false',
      });
      detailsData.push({
        key: 'description',
        value: networkSegmentData?.description
          ? networkSegmentData?.description
          : '—',
      });
    } else {
      detailsData.splice(4, 0, {
        key: 'description',
        value: networkSegmentData?.description
          ? networkSegmentData?.description
          : '—',
      });
    }
    return detailsData;
  };

  const getInfraGroups = () => {
    return resourceGroupData?.filter(
      resource => resource.type === 'infrastructure'
    );
  };

  if (error500) {
    return <Error500 />;
  }

  return (
    <div
      className='network-segment-details'
      id='networkSegmentDetails'
      data-testid='network-segment-details-container'
    >
      <Header
        loading={networkSegmentDataLoading}
        title={networkSegmentData ? networkSegmentData.name : ''}
        breadcrumbs={
          state !== null && state.breadcrumb
            ? state.breadcrumb
            : [
                {
                  url: '/',
                  label: t('home'),
                },
                {
                  url: '/networkSegments',
                  label: t('networkSegments'),
                },
              ]
        }
        dataTestId='network-segment-header'
      />
      <div className='network-segment-details-container'>
        {networkSegmentDataLoading ? (
          <SkeletonPlaceholder className='details-tile-skeleton' />
        ) : !permissionMap['networkSegmentDetails'] ? (
          <Error403Card />
        ) : (
          <>
            <DetailsCard
              type='NETWORK_SEGMENT_DETAILS'
              openEditModal={editBtnClick}
              isEditable={
                networkSegmentData?.resource_id !== DEFAULT_NETWORK_SEGMENT_ID
                  ? true
                  : false
              }
              isDisabled={false}
              detailsCardName={t('detailsCardName')}
              data={getFormattedNetworkDetails()}
              dataTestId='network-segment-details-card'
            />
          </>
        )}
      </div>
      {networkSegmentId !== DEFAULT_NETWORK_SEGMENT_ID &&
      networkSegmentDataLoading ? (
        <DataTableSkeleton />
      ) : !permissionMap['networkSegmentDetails'] ? null : (
        networkSegmentData?.compatibility_set ===
          NetworkSegmentCompatibilitySet.ServiceInterconnect && (
          <div
            className='network-segment-resources-list-container'
            data-testid='ns-resources-list-container'
          >
            <NetworkSegmentResourcesList
              networkSegmentData={networkSegmentData}
              resourceGroups={resourceGroupData}
              resourceGroupPermission={permissionMap.resourceGroups}
            />
          </div>
        )
      )}
      {networkSegmentId !== DEFAULT_NETWORK_SEGMENT_ID ? (
        networkSegmentDataLoading ? (
          <ButtonSkeleton className='delete-network-segment' />
        ) : (
          getDeleteButton(disableButton, 'network-segment-details__btn--delete')
        )
      ) : null}
      {showEditNetworkSegment && (
        <CreateNetworkSegment
          open={showEditNetworkSegment}
          networkSegmentData={networkSegmentData}
          networkSegments={networkSegments}
          onClose={() => onClose()}
          groups={getInfraGroups()}
          refreshData={refreshPage}
          updateCallBack={(networkSegmentData: NetworkSegmentData) =>
            updateNetworkSegmentData(networkSegmentData)
          }
          actionType={'EDIT'}
          className={'edit-network-segment'}
        />
      )}
      <Modal
        className='delete-network-segment-modal'
        size='xs'
        danger
        modalHeading={t('delete.deleteNetworkSegment')}
        onRequestClose={() => setOpenDeleteModal(false)}
        onRequestSubmit={() => submitDeleteRequest()}
        primaryButtonText={t('delete.deleteButton')}
        secondaryButtonText={t('delete.cancelButton')}
        open={openDeleteModal}
        primaryButtonDisabled={disableButton}
        data-testid='delete-ng-modal'
      >
        {t('delete.description')}
      </Modal>
    </div>
  );
};

export default NetworkSegmentDetails;
