import { axiosInstance } from './api';
import { MCNM_RESOURCEGROUP_API_URL } from './config';

export const getResourceGroups = async () => {
  try {
    let response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_RESOURCEGROUP_API_URL}/resource_groups`,
    });
    return response?.data?.resource_groups ?? [];
  } catch (error) {
    console.log('api error: ', error);
    throw error;
  }
};
