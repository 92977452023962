import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableExpandHeader,
  TableExpandRow,
  TableExpandedRow,
  TableCell,
  TableContainer,
  TableToolbar,
  TableToolbarSearch,
  TableToolbarContent,
  TooltipDefinition,
  Checkbox,
} from 'carbon-components-react';
import {
  DataTableCell,
  DataTableHeader,
  DataTableRow,
} from '../../../../../models/dataTable';
import { ResourceGroup } from '../../../../../models/master';
import { RowTypeHeader, ResourceGroupData } from '../config';

import './RolePermissionsResourceGroupTable.scss';

export interface TableProps {
  rows: RowTypeHeader[];
  headers: DataTableHeader[];
  isSortable: boolean;
  resourceGroupPermissionsData: any;
  updateResourceGroupPermissionsData: any;
}

const RolePermissionsResourceGroupTable = (props: TableProps) => {
  const {
    rows,
    headers,
    resourceGroupPermissionsData,
    updateResourceGroupPermissionsData,
  } = props;

  const { t } = useTranslation('createRole');

  const [searchText, setSearchText] = useState('');
  const [filteredFormData, setFilteredFormData] = useState<any>({});

  const getResourceGroupData = (resourceGroup: any) => {
    if (!searchText) {
      return resourceGroupPermissionsData[resourceGroup.id] || {};
    }
    return filteredFormData[resourceGroup.id] || {};
  };

  const getResourceGroupDataFilter = (
    resourceGroup: any,
    checkFilter?: boolean
  ) => {
    if (!checkFilter) {
      return resourceGroupPermissionsData[resourceGroup.id] || {};
    } else {
      return filteredFormData[resourceGroup.id] || {};
    }
  };

  const createUpdatedData = (
    value: boolean,
    key: string,
    category: string,
    resourceGroup: any,
    resourceSubGroupType: any,
    checkFilter?: boolean
  ) => {
    const resourceGroupData = getResourceGroupDataFilter(
      resourceGroup,
      checkFilter
    );

    const updatedResourceGroupData =
      resourceGroupData &&
      resourceGroupData.map((resourceGroup: any) => {
        if (resourceGroup.resource_id === resourceSubGroupType?.resource_id) {
          if (category === 'resources') {
            let checkIfAnyTrue = false;
            let checkIfAllFalse = true;
            const allCategoryKeys = Object.keys(resourceGroup['resources']);
            allCategoryKeys.forEach(categoryKey => {
              if (
                categoryKey !== key &&
                resourceGroup['resources'][categoryKey].value
              ) {
                checkIfAnyTrue = true;
                checkIfAllFalse = false;
              }
            });
            if (checkIfAllFalse && value) {
              checkIfAllFalse = false;
            }
            if (!checkIfAnyTrue && value) {
              checkIfAnyTrue = true;
            }
            return {
              ...resourceGroup,
              [category]: {
                ...resourceGroup[category],
                [key]: {
                  ...resourceGroup[category][key],
                  value: value,
                },
              },
              groups: {
                ...resourceGroup['groups'],
                read: {
                  ...resourceGroup['groups']['read'],
                  value: checkIfAnyTrue && !checkIfAllFalse ? true : false,
                  showToolTip:
                    checkIfAnyTrue && !checkIfAllFalse ? true : false,
                },
              },
            };
          }

          return {
            ...resourceGroup,
            [category]: {
              ...resourceGroup[category],
              [key]: {
                ...resourceGroup[category][key],
                value: value,
              },
            },
          };
        }
        return resourceGroup;
      });
    const initialData = checkFilter
      ? filteredFormData
      : resourceGroupPermissionsData;
    const updatedData = {
      ...initialData,
      [resourceGroup.id]: updatedResourceGroupData,
    };

    return updatedData;
  };

  const handleCheckbox = (
    value: boolean,
    key: string,
    category: string,
    resourceGroup: any,
    resourceSubGroupType: ResourceGroup | null
  ) => {
    const updatedResourceGroupPermissionsData = createUpdatedData(
      value,
      key,
      category,
      resourceGroup,
      resourceSubGroupType
    );
    updateResourceGroupPermissionsData(updatedResourceGroupPermissionsData);

    if (searchText) {
      const updatedFilteredData = createUpdatedData(
        value,
        key,
        category,
        resourceGroup,
        resourceSubGroupType,
        true
      );
      setFilteredFormData(updatedFilteredData);
    }
  };

  const getExpandedRow = (row: DataTableRow, headers: DataTableHeader[]) => {
    const resourceGroupData = getResourceGroupData(row);
    return (
      row.isExpanded && (
        <TableExpandedRow
          className='row-expanded-table'
          key={`${row.id}-expanded`}
          colSpan={headers.length + 1}
        >
          <Table>
            <TableBody>
              {resourceGroupData?.map((resourceGroup: any) => {
                const groupKeys = Object.keys(resourceGroup.groups);
                const resourceKeys = Object.keys(resourceGroup.resources);

                return (
                  <>
                    <TableRow
                      key={resourceGroup.resource_id}
                      className='row-resource-group-name'
                    >
                      <TableCell>{resourceGroup?.name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className='row-resource-group-section'>
                        {' '}
                        <TooltipDefinition
                          tooltipText={t('rolePermissions.tooltips.group')}
                          align='center'
                        >
                          {t('rolePermissions.group')}
                        </TooltipDefinition>
                      </TableCell>
                      {groupKeys.map((groupKey: string) => {
                        return (
                          <TableCell
                            className='resource-group-check-box'
                            key={`${resourceGroup.resource_id}-group-${groupKey}`}
                          >
                            {resourceGroup.groups[groupKey].showToolTip ? (
                              <TooltipDefinition
                                tooltipText={t(
                                  'rolePermissions.tooltips.readGroupByResource'
                                )}
                                align='center'
                              >
                                <Checkbox
                                  id={`${row.id}-${resourceGroup.resource_id}-group-${groupKey}-check-box`}
                                  labelText=''
                                  checked={resourceGroup.groups[groupKey].value}
                                  disabled={true}
                                />
                              </TooltipDefinition>
                            ) : resourceGroup.groups[groupKey] ? (
                              <Checkbox
                                id={`${row.id}-${resourceGroup.resource_id}-group-${groupKey}-check-box`}
                                labelText=''
                                checked={resourceGroup.groups[groupKey].value}
                                onChange={(value: boolean) =>
                                  handleCheckbox(
                                    value,
                                    groupKey,
                                    'groups',
                                    row,
                                    resourceGroup
                                  )
                                }
                              />
                            ) : (
                              <></>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                    <TableRow>
                      <TableCell className='row-resource-group-section'>
                        {' '}
                        <TooltipDefinition
                          tooltipText={t('rolePermissions.tooltips.resources')}
                          align='center'
                        >
                          {t('rolePermissions.resources')}
                        </TooltipDefinition>
                      </TableCell>
                      {resourceKeys.map((resourceKey: string) => {
                        return (
                          <TableCell
                            className='resource-group-check-box'
                            key={`${resourceGroup.resource_id}-resource-${resourceKey}`}
                          >
                            <Checkbox
                              id={`${row.id}-${resourceGroup.resource_id}-resource-${resourceKey}-check-box`}
                              labelText=''
                              checked={
                                resourceGroup.resources[resourceKey].value
                              }
                              onChange={(value: boolean) =>
                                handleCheckbox(
                                  value,
                                  resourceKey,
                                  'resources',
                                  row,
                                  resourceGroup
                                )
                              }
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableExpandedRow>
      )
    );
  };

  const applySearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTextValue = e.target.value;
    setSearchText(searchTextValue);

    if (searchTextValue === '' || searchTextValue === null) {
      setFilteredFormData({});
    } else {
      let filteredFormData: any = {};
      const resourceGroupKeys = Object.keys(resourceGroupPermissionsData);
      resourceGroupKeys.forEach((resourceGroupKey: string) => {
        const resourceGroupData: ResourceGroupData[] =
          resourceGroupPermissionsData[resourceGroupKey];
        const filteredResourceGroupData = resourceGroupData.filter(
          (data: ResourceGroupData) =>
            data.name.toLowerCase().includes(searchTextValue.toLowerCase())
        );
        filteredFormData = {
          ...filteredFormData,
          [resourceGroupKey]: filteredResourceGroupData,
        };
      });
      setFilteredFormData(filteredFormData);
    }
  };

  const getResourceGroupDataCount = (resourceGroup: any) => {
    if (!searchText) {
      return (
        resourceGroupPermissionsData[resourceGroup.id] &&
        resourceGroupPermissionsData[resourceGroup.id].length
      );
    }
    return (
      filteredFormData[resourceGroup.id] &&
      filteredFormData[resourceGroup.id].length
    );
  };

  const getFilteredRows = () => {
    if (!searchText) {
      return rows;
    } else {
      const filteredFormDataKeys: any = Object.keys(filteredFormData);
      return rows.filter((row: any) => filteredFormDataKeys.includes(row.id));
    }
  };

  const getHeaderTooltip = (tooltipGroup: string, tooltipResource: string) => {
    return (
      <>
        {tooltipGroup && (
          <>
            Group: {tooltipGroup}
            <br />
          </>
        )}
        Resources: {tooltipResource}
      </>
    );
  };

  return (
    <div className='create-role-permission-resource-group-table'>
      <DataTable
        rows={getFilteredRows()}
        headers={headers}
        isSortable={props.isSortable}
      >
        {({
          rows,
          headers,
          getHeaderProps,
          getToolbarProps,
          getRowProps,
          getTableProps,
          getTableContainerProps,
        }: any) => (
          <TableContainer {...getTableContainerProps()}>
            <TableToolbar {...getToolbarProps()}>
              <TableToolbarContent>
                <TableToolbarSearch
                  className='role-search'
                  expanded
                  onChange={e => applySearch(e)}
                  placeholder={t('rolePermissions.search')}
                />
              </TableToolbarContent>
            </TableToolbar>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  <TableExpandHeader />
                  {headers.map((header: any) => {
                    return header?.tooltipGroup || header?.tooltipResource ? (
                      <TableHeader
                        className='resource-group-header'
                        {...getHeaderProps({ header })}
                      >
                        <TooltipDefinition
                          tooltipText={getHeaderTooltip(
                            header?.tooltipGroup,
                            header?.tooltipResource
                          )}
                          align='center'
                        >
                          {header.header}
                        </TooltipDefinition>
                      </TableHeader>
                    ) : (
                      <TableHeader
                        className='resource-group-header'
                        {...getHeaderProps({ header })}
                      >
                        {header.header}
                      </TableHeader>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row: DataTableRow) => {
                  return (
                    <React.Fragment key={row.id}>
                      <TableExpandRow {...getRowProps({ row })}>
                        {row.cells.map((cell: DataTableCell) => {
                          return (
                            <TableCell
                              className='role-permission-expand-cell'
                              key={cell.id}
                            >
                              {cell.value &&
                                `${cell.value} (${getResourceGroupDataCount(
                                  row
                                )})`}
                            </TableCell>
                          );
                        })}
                      </TableExpandRow>
                      {getExpandedRow(row, headers)}
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
    </div>
  );
};

export default RolePermissionsResourceGroupTable;
