import React, { useState } from 'react';
import {
  TextInput,
  Row,
  FormLabel,
  Toggle,
  TooltipIcon,
  Column,
  TextArea,
  InlineNotification,
} from 'carbon-components-react';
import { CreateTearsheetStep } from '@carbon/ibm-products';
import { useTranslation } from 'react-i18next';
import { Information16 } from '@carbon/icons-react';
import AddLabels from '../../components/AddLabels/AddLabels';
import './RegisterNamespace.scss';
import {
  Secret,
  ResourceGroup,
  DeploymentEnvironmentSubtype,
} from '../../models/master';
import { Dropdown } from '@carbon/react';
import images from '../../images/images';
import useAnalytics from '../../lib/useAnalytics';
import analyticsData from '../../lib/analyticsEventData';
import { NetworkSegmentCompatibilitySet } from '../../lib/enums';
import { getThirdPartyCloudIconsDropdown } from '../Cloud/ThirdPartyCloudIcons';
import GenericManageButton from '../../components/GenericManageButton/GenericManageButton';

const NamespaceDetailsForm = (props: {
  onChange: any;
  formData: any;
  secrets: Secret[] | null;
  appResourceGroups: ResourceGroup[] | null;
  partitionType: any;
  deploymentdata: any;
  existingCompactibilitySet: string;
  appsDeployed?: any;
  mode: 'ADD' | 'EDIT';
  partitionData?: any;
  gatewayConnectedRemoteConnections: any;
  hasConnectedPolicy: boolean;
  isSubmitDisabled: boolean;
  showFailNotification: boolean;
  gatewayFailNotification: boolean;
  showUpdateFailNotification: boolean;
  handleErrorBarClose: () => void;
  handleGatewayErrorBarClose: () => void;
  handleNamespaceUpdateErrorBarClose: () => void;
  authError: boolean;
  gatewayAuthError: boolean;
  isSubmitting: boolean;
  allDepEnvSubTypes: DeploymentEnvironmentSubtype[];
  id: string;
}) => {
  const {
    onChange,
    formData,
    secrets,
    partitionType,
    deploymentdata,
    existingCompactibilitySet,
    appsDeployed,
    partitionData,
    gatewayConnectedRemoteConnections,
    id,
  } = props;
  const { t } = useTranslation('registerNamespace');

  const { trackButtonClicked } = useAnalytics();

  const nameSpaceLabel = () => {
    return (
      <FormLabel className='label namespace-label'>
        {t('namespacedetails.namespaceName')}
        {deploymentdata?.auto_discover &&
        existingCompactibilitySet === NetworkSegmentCompatibilitySet.Axon &&
        props?.mode === 'EDIT' ? (
          <TooltipIcon
            className='namespace-tooltip'
            tooltipText={t('form.name.tooltipText')}
            direction='right'
          >
            {images.questionIconSvg()}
          </TooltipIcon>
        ) : null}
      </FormLabel>
    );
  };

  return (
    <CreateTearsheetStep
      className='namespace-details-tearsheet'
      fieldsetLegendText={
        <div className='tearsheet-header'>
          {t('namespaceDetails')}
          <GenericManageButton
            isCheckboxDisabled={
              formData.managedStatus.value &&
              (formData.gatewayName?.value ||
                props.hasConnectedPolicy ||
                (appsDeployed && appsDeployed.length > 0))
              // (gatewayConnectedRemoteConnections &&
              //   gatewayConnectedRemoteConnections.length > 0)
            }
            checked={formData.managedStatus.value}
            showTooltip={
              formData.managedStatus.value &&
              (formData.gatewayName?.value ||
                props.hasConnectedPolicy ||
                (appsDeployed && appsDeployed.length > 0))
            }
            key='managedStatus'
            toolTipText={t('manageTooltipDisabled')}
            id={`managedBtn-${id}`}
            handleCheckbox={(checked: boolean) => {
              onChange('managedStatus', checked);
            }}
          />
        </div>
      }
      title={t('defineNamespace')}
      disableSubmit={props.isSubmitting || props.isSubmitDisabled}
    >
      {props.showFailNotification && (
        <InlineNotification
          onClose={() => props.handleErrorBarClose() as any}
          kind={'error'}
          title={
            props.authError
              ? (t('failureNotification.authTitle') as string)
              : (t('failureNotification.title') as string)
          }
          subtitle={
            props.authError
              ? (t('failureNotification.authSubtitle') as string)
              : (t('failureNotification.subtitle') as string)
          }
        />
      )}
      {props.gatewayFailNotification && (
        <InlineNotification
          onClose={() => props.handleGatewayErrorBarClose() as any}
          kind={'error'}
          title={
            props.gatewayAuthError
              ? (t('gatewayFailureNotification.authTitle') as string)
              : (t('gatewayFailureNotification.title') as string)
          }
          subtitle={
            props.gatewayAuthError
              ? (t('gatewayFailureNotification.authSubtitle') as string)
              : (t('gatewayFailureNotification.subtitle') as string)
          }
        />
      )}
      {props.showUpdateFailNotification && (
        <InlineNotification
          onClose={() => props.handleNamespaceUpdateErrorBarClose() as any}
          kind={'error'}
          title={
            props.authError
              ? (t('namespaceUpdate.failureNotification.authTitle') as string)
              : (t('namespaceUpdate.failureNotification.title') as string)
          }
          subtitle={
            props.authError
              ? (t(
                  'namespaceUpdate.failureNotification.authSubtitle'
                ) as string)
              : (t('namespaceUpdate.failureNotification.subtitle') as string)
          }
        />
      )}
      <div className='register-namespace-form'>
        <Row className='row'>
          <Column md={3} className='namespacedetailsCol cloud-column'>
            <Dropdown
              id='namespace_cloud_name'
              className='form-field'
              titleText={t('namespacedetails.cloud') as string}
              readOnly
              selectedItem={props.allDepEnvSubTypes.find(
                (type: DeploymentEnvironmentSubtype) =>
                  type.type_code === deploymentdata?.subtype
              )}
              renderSelectedItem={(selectedType: any) => (
                <div className='nameSpaceVal'>
                  <span className='cloud-icon-cont'>
                    {getThirdPartyCloudIconsDropdown(
                      selectedType?.cloud_type_code
                    )}
                  </span>
                  <span className='cloud-name'>
                    {deploymentdata?.cloud_name ?? '—'}
                  </span>
                </div>
              )}
              items={props.allDepEnvSubTypes}
            />
          </Column>
          <Column md={3} className='namespacedetailsCol'>
            <TextInput
              className='form-field'
              labelText={t('namespacedetails.location') as string}
              value={deploymentdata?.location_name}
              id='namespace_location_name'
              readOnly
            />
          </Column>
        </Row>
        <Row className='row'>
          <Column md={3} className='namespacedetailsCol'>
            <TextInput
              className='form-field'
              labelText={t('namespacedetails.depenvName') as string}
              value={deploymentdata?.name}
              id='namespace_depEnv_name'
              readOnly
            />
          </Column>
          <Column md={3} className='namespacedetailsCol'>
            <TextInput
              id='addNamespaceName'
              className='form-field'
              name='name'
              placeholder={
                partitionType === 'namespace'
                  ? t('form.name.placeholder')
                  : t('form.name.placeholderSecurity')
              }
              labelText={nameSpaceLabel()}
              value={formData.name.value}
              onChange={e => onChange('name', e.target.value)}
              readOnly={
                (formData?.networkSegment?.value &&
                  formData?.networkSegment?.value?.compatibility_set ===
                    NetworkSegmentCompatibilitySet.ServiceInterconnect &&
                  (formData?.agent_node?.value ||
                    formData?.gatewayId?.value)) ||
                (deploymentdata?.auto_discover &&
                  existingCompactibilitySet ===
                    NetworkSegmentCompatibilitySet.Axon &&
                  props?.mode === 'EDIT')
              }
              invalid={formData.name.error}
              invalidText={formData.name.errorMessage}
              maxLength={60}
            />
          </Column>
        </Row>
        {formData?.networkSegment?.value &&
        formData?.networkSegment?.value?.compatibility_set ===
          NetworkSegmentCompatibilitySet.ServiceInterconnect &&
        (formData?.gatewayName?.value ||
          (!formData?.gatewayId?.isDisconnected &&
            partitionData?.gateway_id)) ? (
          <Row className='row autodiscoverIntoRow'>
            <Column md={3}>
              <Dropdown
                light
                items={props?.appResourceGroups ?? []}
                itemToString={(item: ResourceGroup) => item?.name}
                id={`select-appresource-dropdown`}
                selectedItem={formData.appResourceGroup?.value}
                onChange={(e: any) =>
                  onChange('appResourceGroup', e?.selectedItem)
                }
                label={t('form.autodiscoverInto.label')}
                titleText={t('form.autodiscoverInto.label')}
              />
            </Column>
          </Row>
        ) : null}

        {!(
          formData?.networkSegment?.value &&
          formData?.networkSegment?.value?.compatibility_set ===
            NetworkSegmentCompatibilitySet.ServiceInterconnect
        ) ? (
          <Row className='row autodiscover-row'>
            <Column md={3}>
              <div className='autodiscover-toggle-group'>
                <FormLabel className='label'>
                  {t('form.autoDiscover.label')}
                  <TooltipIcon
                    className='autodiscover-tooltip'
                    tooltipText={
                      partitionType === 'namespace'
                        ? (t('form.autoDiscover.tooltipText') as string)
                        : t('form.autoDiscover.tooltipTextSecurityGroup')
                    }
                  >
                    <Information16 />
                  </TooltipIcon>
                </FormLabel>
                <span className={'autodiscover-toggle-row'}>
                  <Toggle
                    className={'autodiscover-toggle'}
                    labelText={''}
                    id={`autodiscover-toggle-${id}`}
                    labelA={t('form.autoDiscover.off')}
                    labelB={t('form.autoDiscover.apps')}
                    toggled={formData.auto_discover.value}
                    onToggle={(val: any, id: string) => {
                      if (val)
                        trackButtonClicked(
                          analyticsData['Deployment Environment Details'].events
                            .autodiscoverApp.props,
                          analyticsData['Deployment Environment Details'].events
                            .autodiscoverApp.event
                        );
                      onChange('auto_discover', val);
                    }}
                    disabled={!formData.managedStatus.value}
                  />
                </span>
              </div>
            </Column>
          </Row>
        ) : null}
        <Row className='row'>
          <Column md={6}>
            <AddLabels
              id='registerNamespaceLabels'
              labelText={t('form.labels.label')}
              placeholder={t('form.labels.placeholder')}
              onChange={data => onChange('labels', data)}
              btnText={t('form.labels.buttonText')}
              btnKind='secondary'
              tagType='green'
              defaultValues={formData.labels.value}
            />
          </Column>
        </Row>
        <Row className='row'>
          <Column md={6} className='descriptionContainer'>
            <TextArea
              id='register-namespace-desciption'
              name='description'
              autoComplete='off'
              labelText={t('form.description.label')}
              placeholder={t('form.description.placeholder')}
              onChange={(e: { target: { value: any } }) =>
                onChange('description', e.target.value)
              }
              value={formData?.description.value}
              maxCount={300}
              enableCounter={true}
            />
          </Column>
        </Row>
      </div>
    </CreateTearsheetStep>
  );
};

export default NamespaceDetailsForm;
