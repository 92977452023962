export const handleInvalidateQueriesStorage = (queryClient: any) => {
  const invalidQueryList = JSON.parse(
    localStorage.getItem('invalidateQueriesList') ?? '[]'
  );
  const modifiedQueryList = invalidQueryList.map(
    (eachQueriesItem: { value: boolean; key: string }) => {
      if (eachQueriesItem?.value === true) {
        queryClient.invalidateQueries({
          queryKey: eachQueriesItem?.key,
        });
        return { ...eachQueriesItem, value: false };
      }
      return eachQueriesItem;
    }
  );
  localStorage.setItem(
    'invalidateQueriesList',
    JSON.stringify(modifiedQueryList)
  );
};

export const inValidateListCacheFn = (keyName: string) => {
  let invalidQueryList = JSON.parse(
    localStorage.getItem('invalidateQueriesList') ?? '[]'
  );
  const requiredQueryData = invalidQueryList?.find(
    (query: { key: string; value: string }) => query?.key === keyName
  );
  if (requiredQueryData)
    invalidQueryList = invalidQueryList?.map(
      (eachQueriesItem: { value: boolean; key: string }) => {
        if (eachQueriesItem?.key === keyName) {
          return { ...eachQueriesItem, value: true };
        }
        return eachQueriesItem;
      }
    );
  else invalidQueryList.push({ key: keyName, value: true });
  localStorage.setItem(
    'invalidateQueriesList',
    JSON.stringify(invalidQueryList)
  );
};
