import React, { useContext, useEffect, useRef } from 'react';
import { ActionableNotification } from '@carbon/react';
import { NotificationContext } from '../Context/NotificationProvider';

import './Notification.scss';

const Notification = () => {
  const notification = useContext(NotificationContext);
  const timer = useRef<ReturnType<typeof setInterval> | null>(null);

  const {
    kind = 'success',
    subtitle,
    title,
    action,
    onActionClick,
    timeout = 8000,
  } = notification.details;

  useEffect(() => {
    timer.current = setTimeout(() => {
      notification.onClose();
    }, timeout);

    // clear on component unmount
    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  });

  const handleActionButtonClick = () => {
    if (onActionClick) {
      onActionClick();
      notification.onClose();
    }
  };

  return (
    <>
      {notification.notificationType === 'ACTION' && (
        <div className='action-notification-container'>
          <ActionableNotification
            kind={kind}
            subtitle={subtitle}
            title={title}
            onClose={notification.onClose as any}
            actionButtonLabel={action}
            onActionButtonClick={handleActionButtonClick}
          ></ActionableNotification>
        </div>
      )}
    </>
  );
};

export default Notification;
