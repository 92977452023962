import { TFunction } from 'react-i18next';

export const axonAutoDeployAgreementText = (
  t: TFunction<'gatewaysContainer', undefined>,
  cloudType: string
) => {
  let agreementText: string = '';

  switch (cloudType) {
    case 'AWS':
      agreementText = t('awsAgreement');
      break;
    case 'IBM':
      agreementText = t('ibmAgreement');
      break;
  }

  return agreementText;
};
