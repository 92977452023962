import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInput, Toggle, ComboBox } from '@carbon/react';
import {
  FormLabel,
  TooltipIcon,
  InlineNotification,
  Button,
} from 'carbon-components-react';
import { Cloud32, Locked16, NewTab16 } from '@carbon/icons-react';
import { Secret } from '../../../models/master';
import { CloudTypes } from '../../../lib/enums';

import './EditCloudDetails.scss';

interface Props {
  selectedCloud: any;
  formData: any;
  groups: any[];
  onChange: any;
  formValid: boolean;
  error: boolean;
  errorType: string;
  secrets: Secret[] | null;
  onCloseError: () => void;
  showAutoDiscover: boolean;
  onMount: () => void;
  onRegisterSecret: () => void;
  openRegisterSecret: boolean;
  customErrorMsg: string;
}

const EditCloudDetails: React.FC<Props> = ({
  selectedCloud,
  formData,
  groups,
  onChange,
  error,
  errorType,
  secrets,
  onCloseError,
  showAutoDiscover,
  onMount,
  onRegisterSecret,
  openRegisterSecret = false,
  customErrorMsg,
}) => {
  const { t } = useTranslation('registerCloud');
  const {
    name,
    description,
    credentials_key,
    infrastructureGroup,
    auto_discover,
    ibm_cos_bucket_url,
  } = formData;

  useEffect(() => {
    if (!secrets || secrets.length < 0) {
      onMount();
    }
  }, [secrets]);

  return (
    <div className='edit-cloud-tearsheet'>
      {error && (
        <InlineNotification
          lowContrast={true}
          onClose={() => onCloseError() as any}
          kind={'error'}
          title={
            errorType === '403'
              ? (t('failureNotification.authErrorTitle') as string)
              : (t('failureNotification.updateTitle') as string)
          }
          subtitle={
            errorType === '403'
              ? (t('failureNotification.authErrorSubtitle') as string)
              : customErrorMsg.length > 0
              ? customErrorMsg
              : (t('failureNotification.subtitle') as string)
          }
        />
      )}
      {selectedCloud !== null && (
        <>
          <FormLabel>{t('editCloud.selectedCloudLabel')}</FormLabel>
          <div className='selected-cloud-card'>
            <span className='selected-cloud-icon'>
              <Cloud32 />
              <TooltipIcon
                className='selected-cloud-tooltip'
                tooltipText={t('editCloud.selectedCloudTooltipText') as string}
              >
                <span className='selected-cloud-name'>
                  {selectedCloud.providerName}
                </span>
              </TooltipIcon>
            </span>
            <TooltipIcon
              tooltipText={t('editCloud.lockIconTooltipText') as string}
            >
              <Locked16 />
            </TooltipIcon>
          </div>
        </>
      )}
      <TextInput
        className='form-item'
        id='edit-cloud-name'
        labelText={t('addDetails.name.label')}
        placeholder={t('addDetails.name.placeholder')}
        name='name'
        value={name.value}
        onChange={(e: any) => onChange('name', e.target.value)}
        invalid={name.error}
        invalidText={name.errorMessage}
        maxLength={60}
      />
      <TextInput
        className='form-item'
        id='edit-cloud-description'
        labelText={t('addDetails.description.label')}
        placeholder={t('addDetails.description.placeholder')}
        name='description'
        value={description}
        maxCount={300}
        enableCounter={true}
        onChange={(e: any) => onChange('description', e.target.value)}
      />
      {showAutoDiscover && (
        <div className='autodiscover-group'>
          <Toggle
            className='form-item'
            id='edit-cloud-autodiscover'
            labelText={t('addDetails.autodiscover.label')}
            labelA={t('addDetails.autodiscover.off')}
            labelB={t('addDetails.autodiscover.on')}
            toggled={auto_discover}
            disabled={
              !auto_discover && !(secrets != null && secrets.length > 0)
            }
            onToggle={(val: any) => onChange('auto_discover', val)}
          />

          {secrets != null && secrets.length > 0 ? (
            <span className='secret-input-field'>
              <ComboBox
                className='secretInput'
                disabled={!auto_discover}
                placeholder={t('addDetails.secret.placeholder')}
                id='edit-cloud-secret'
                titleText={t('addDetails.secret.label')}
                items={Array.isArray(secrets) ? secrets : []}
                itemToString={(item: any) => item?.name}
                translateWithId={t}
                onChange={(e: any) => {
                  onChange('credentials_key', e.selectedItem);
                }}
                selectedItem={credentials_key}
              />
              <div className='secret-helper-text'>
                {t('editCloud.secretHelperText')}
              </div>
            </span>
          ) : (
            <div className='noSecretsContent'>
              <span className='secretLabel'>{t('noSecret')}</span>
              <p className='no-secret-text'>{t('noSecretDescription')}</p>
              <Button
                id='register-secret-btn'
                onClick={() => onRegisterSecret()}
                disabled={openRegisterSecret}
              >
                <div className='secret-link'>
                  {t('registersSecretLink')}
                  <NewTab16 />
                </div>
              </Button>
            </div>
          )}
        </div>
      )}
      {auto_discover && selectedCloud.type === CloudTypes.IBM && (
        <TextInput
          className='form-item'
          id='edit-cloud-ibm_cos_bucket_url'
          labelText={t('addDetails.ibm_cos_bucket_url.label')}
          placeholder={t('addDetails.ibm_cos_bucket_url.placeholder')}
          name='ibm_cos_bucket_url'
          value={ibm_cos_bucket_url.value}
          autoComplete='off'
          onChange={(e: any) => onChange('ibm_cos_bucket_url', e.target.value)}
          invalid={ibm_cos_bucket_url.error}
          invalidText={ibm_cos_bucket_url.errorMessage}
        />
      )}
      <>
        <ComboBox
          className='form-item'
          titleText={t('addDetails.group.label')}
          name='infrastructureGroup'
          items={groups}
          itemToString={(item: any) => item?.name}
          selectedItem={infrastructureGroup}
          translateWithId={t}
          onChange={(e: any) => onChange('infrastructureGroup', e.selectedItem)}
        />
        <div className='helper-text'>
          {t('editCloud.infrastructureGroupHelperText')}
        </div>
      </>
    </div>
  );
};

export default EditCloudDetails;
