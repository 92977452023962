/*******************************************************************************
 * Licensed Materials - Property of IBM
 * (c) Copyright IBM Corporation 2019. All Rights Reserved.
 *
 * Note to U.S. Government Users Restricted Rights:
 * Use, duplication or disclosure restricted by GSA ADP Schedule
 * Contract with IBM Corp.
 *******************************************************************************/

import React from 'react';

import homeSvg from './home.svg';
import alarmSvg from './alarm.svg';
import applicationSvg from './application.svg';
import cloudSvg from './cloudy.svg';
import deploymentSvg from './deployment.svg';
import policySvg from './policy.svg';
import topologyPreview from './topology-preview.png';
import modalStep1 from './modal-step-1.png';
import modalStep2 from './modal-step-2.png';
import modalStep3 from './modal-step-3.png';
import noSearchResultsSvg from './no_search_results.svg';
import noServicesSvg from './no_service.svg';
import servicesLargeSvg from './service-large.svg';
import noCloudsSvg from './no_clouds.svg';
import policyConnection from './policy-connection.svg';
import noApplicationsSvg from './app_icon_empty.svg';
import noPoliciesSvg from './Shield_icon_empty.svg';
import partitionLargeSVG from './partition-large.svg';
import noDeploymentEnvsSvg from './deployment_environment_empty.svg';
import noAlertsSvg from './clock_icon_empty.svg';
import CAPolicySvg from './CA_policy.svg';
import DeploymentEnvSvg from './network--1.svg';
import DeploymentEnvBlueSvg from './network-blue.svg';
import NoDeploymentsSvg from './no-deployment.svg';
import AutoDiscoverdLockSvg from './auto-discovered-lock.svg';
import AutoDiscoverLabelSvg from './auto_discover_label.svg';
import selectedTick from './selectedTick.svg';
import informationSvg from './Information.svg';
import TopologyIconSvg from './topologyIcon.svg';
import NoResourceGroupLarge from './no-resource-group-large.svg';
import NoResourceGroupSmall from './no-resource-group-small.svg';
import NoNamespace from './no_namespace.svg';
import NoSecurityGroup from './no_security_group.svg';
import NoServiceSmall from './no_service_small.svg';
import NoDeploymentSmall from './no_deployment_small.svg';
import NoAppDeploymentSmall from './no_appDeployment_small.svg';
import NotFoundLarge from './not_found_large.svg';
import NotFoundSmall from './not_found_small.svg';
import LocationsIconSvg from './locationIcon.svg';
import CloudLocationSvg from './cloudLocation.svg';
import NoLocationsSmall from './no_locations_small.svg';
import NoLocationsLarge from './no_locations_large.svg';
import NoCloudLarge from './no_cloud_large.svg';
import NoCloudSmall from './no_cloud_small.svg';
import aws from './clouds/aws.svg';
import sidePanelSecurityGroupEmpty from './sidepanel_empty_securitygroup.svg';
import azure from './clouds/azure.svg';
import ibmCloud from './clouds/ibm_cloud.svg';
import cloudSingle from '../images/Cloud.svg';
import appService from './app_service.svg';
import EdgeGatewayIconSvg from './edge_gateway.svg';
import WaypointGatewayIconSvg from './waypoint_gateway.svg';
import autodiscoveryOn from './autodiscover_on.svg';
import autodiscoveryOff from './autodiscover_off.svg';
import cloudNameSvg from './cloud_name_icon.svg';
import toggleUpSvg from './toggle_up.svg';
import toggleDownSvg from './toggle_down.svg';
import secretsManagerSvg from './secrets_manager.svg';
import sidePanelNoConnectionSvg from './sidepanel_no_connection.svg';
import noLocationsSvg from './no_locations_svg.svg';
import connectionIconSvg from './connection.svg';
import noAPIKeys from './keys-bright.svg';
import operationalStatus from './operational_status.svg';
import barChartIcon from './barChartIcon.svg';
import lineChartIcon from './lineChartIcon.svg';
import barChartSelected from './barChartSelected.svg';
import lineChartSelected from './lineChartSelected.svg';
import majorStatus from './major_status.svg';
import minorStatus from './minor_status.svg';
import NoGatewaySmall from './no_gateway_small.svg';
import NoGatewayLarge from './nogateway_large.svg';
import newTabIcon from './new_tab.svg';
import criticalStatus from './critical_status.svg';
import infoSmallEventIcon from './infoEventsSmall.svg';
import NoEventsLarge from './no_events_large.svg';
import eventSvg from './events.svg';
import undefinedSvg from './undefined.svg';
import noRemoteConn from './no_remote_conn.svg';
import secretModuleIcon from './secret_nav_icon.svg';
import noSecretsSvg from '../images/no_secrets.svg';
import secretsNav from '../images/SecretsNav.svg';
import pendingStatus from './pendingStatus.svg';
import PartitionSvg from './partition.svg';
import notAuthorizedSvg from '../images/not_authorized.svg';
import EdgeApplicationSvg from './app-icon-monolight-EdgeApplicationManager.svg';
import ExpandToSvg from './expand_to.svg';
import NoCloudAccess from './no_cloud_access.svg';
import NoCloudDefined from './no_cloud_defined.svg';
import NoIdentityIcon from './no_identity.svg';
import noRoles from './no_roles_large.svg';
import notDeployed from './not_deployed.svg';
import unReachable from './unreachable.svg';
import InfoIcon from './infoicon.svg';
import noRolesSvg from './no_role.svg';
import NoServicesSmallIcon from './Services small.svg';
import NoServicesLargeIcon from './Services large.svg';
import NoAppDeploymentsSmallIcon from './app deployment small.svg';
import NoAppInstancesSmallIcon from './app instance small.svg';
import NoAppInstancesLargeIcon from './app instance large.svg';
import NoServiceEndpointSmallIcon from './service endpoint small.svg';
import NoServiceEndpointLargeIcon from './service endpoint large.svg';
import NoRemoteConnSmall from './remote connection small.svg';
import notAuthorizedLarge from './Not authorized large.svg';
import NetworkSegmentIconSvg from './network_segment.svg';
import NoTopologyData from './NoTopologyData.svg';
import NwSegmentIconSvg from './NwSegmentIcon.svg';
import InfoIconTransp from './Information_trans.svg';
import NetworkSegmentLarge from './NetworkSegments_Large.svg';
import DashboardHeroSvg from './dashboard_hero.svg';
import DashboardTopologyCardSvg from './topology_card_illustration.svg';
import DashboardTopologyCardSvg_Phase1 from './topology_card_phase1.svg';
import LoginSvg from './login_illustration.svg';
import InfraOnlyIcon from './Datastore.svg';
import QuestionIconSvg from './QuestionIcon.svg';
import RefreshIcon from './refresh_icon.svg';
import loadingStatus from './loading_status.svg';
import RedHatIconSvg from './gateways/redHat.svg';
import MeshIconSvg from './gateways/meshIcon.svg';
import VpcIconSvg from './vpc.svg';
import serviceIconSvg from './edge-service.svg';
import infoQuestionSvg from './InfoQuestion.svg';

import AwsCloud from './thirdPartyCloudIcons/aws.svg';
import IbmCloud from './thirdPartyCloudIcons/ibm.svg';
import MicrosoftCloud from './thirdPartyCloudIcons/microsoft.svg';
import GoogleCloud from './thirdPartyCloudIcons/google.svg';
import OtherCloud from './thirdPartyCloudIcons/other.svg';
import AksDep from './deploymentSubTypeIcons/aks.svg';
import AzureAksDep from './deploymentSubTypeIcons/azure-aks.svg';
import BaremetalDep from './deploymentSubTypeIcons/baremetal.svg';
import EksDep from './deploymentSubTypeIcons/eks.svg';
import IksDep from './deploymentSubTypeIcons/iks.svg';
import RosaDep from './deploymentSubTypeIcons/rosa.svg';
import VirtualMachineDep from './deploymentSubTypeIcons/virtualMachine.svg';
import Gke from './deploymentSubTypeIcons/gke.svg';
import VpcDep from './deploymentSubTypeIcons/vpc.svg';
import locationLegend from '../components/Topology/LegendsContainer/LegendIcons/locationLegend.svg';
import clusterLegend from '../components/Topology/LegendsContainer/LegendIcons/clusterLegend.svg';
import vpcLegend from '../components/Topology/LegendsContainer/LegendIcons/vpcLegend.svg';
import infraLegend from '../components/Topology/LegendsContainer/LegendIcons/vpcInfraLegend.svg';
import edgeLegend from '../components/Topology/LegendsContainer/LegendIcons/edgeGatewayLegend.svg';
import waypointLegend from '../components/Topology/LegendsContainer/LegendIcons/waypointGatewayLegend.svg';
import applicationLegend from '../components/Topology/LegendsContainer/LegendIcons/applicationLegend.svg';
import serviceLegend from '../components/Topology/LegendsContainer/LegendIcons/serviceLegend.svg';
import nodeLegend from '../components/Topology/LegendsContainer/LegendIcons/nodeLegend.svg';
import partitionLegend from '../components/Topology/LegendsContainer/LegendIcons/partitionLegend.svg';
import activeLegend from '../components/Topology/LegendsContainer/LegendIcons/activeLegend.svg';
import relationalLegend from '../components/Topology/LegendsContainer/LegendIcons/relationalLegend.svg';
import policyLegend from '../components/Topology/LegendsContainer/LegendIcons/policyLegend.svg';
import gatewayLegend from '../components/Topology/LegendsContainer/LegendIcons/gatewayLegend.svg';
import unmanagedLegend from '../components/Topology/LegendsContainer/LegendIcons/unmanaged.svg';
import alerts from '../components/Topology/LegendsContainer/LegendIcons/alerts.svg';
import appToService from '../components/Topology/LegendsContainer/LegendIcons/app to service connection.svg';
import autoDiscovered from '../components/Topology/LegendsContainer/LegendIcons/autodiscovered.svg';
import events from '../components/Topology/LegendsContainer/LegendIcons/events.svg';
import expandsTo from '../components/Topology/LegendsContainer/LegendIcons/Expand to.svg';
import gatewayToGateway from '../components/Topology/LegendsContainer/LegendIcons/gateway to gateway.svg';
import layers from '../components/Topology/LegendsContainer/LegendIcons/Layers.svg';
import notAuthorizedLock from '../components/Topology/LegendsContainer/LegendIcons/Not authorized.svg';
import topology from '../components/Topology/LegendsContainer/LegendIcons/topology.svg';
import singleClickLegend from '../components/Topology/LegendsContainer/LegendIcons/singleClickLegend.svg';
import shiftKeyLegend from '../components/Topology/LegendsContainer/LegendIcons/shiftKeyLegend.svg';
import doubleClickLegend from '../components/Topology/LegendsContainer/LegendIcons/doubleClickLegend.svg';
import error500Svg from './error500.svg';
import error500SmallSvg from './error500Small.svg';
import leftArrow from './Arrow--left.svg';
import rightArrow from './Arrow--right.svg';
import editPencilSvg from './EditPencil.svg';
import AwsCloudDropdown from './thirdPartyCloudIcons/awsDropdown.svg';
import IbmCloudDropdown from './thirdPartyCloudIcons/ibmDropdown.svg';
import MicrosoftCloudDropdown from './thirdPartyCloudIcons/microsoftDropdown.svg';
import GoogleCloudDropdown from './thirdPartyCloudIcons/googleDropdown.svg';
import OtherCloudDropdown from './thirdPartyCloudIcons/otherDropdown.svg';
import connectGatewayStepPng from './connectGatewayStep.png';
import majorIconSvg from './major.svg';
import checkMark from './Checkmark.svg';
import managedSvg from './managed.svg';
import unmanagedSvg from './unmanaged.svg';

const singleClickLegendIcon = (
  <img
    src={singleClickLegend}
    height='32px'
    width='89px'
    alt='single click button'
  />
);
const shiftKeyLegendIcon = (
  <img src={shiftKeyLegend} height='32px' width='32px' alt='shift key icon' />
);
const doubleClickLegendIcon = (
  <img
    src={doubleClickLegend}
    height='32px'
    width='95px'
    alt='double click button'
  />
);
const AksDepIcon = (
  <img
    src={AksDep}
    height='32px'
    width='32px'
    alt='deployment environment icon'
  />
);

const AzureAksIcone = (
  <img
    src={AzureAksDep}
    height='32px'
    width='32px'
    alt='deployment environment icon'
  />
);


const BaremetalDepIcon = (
  <img
    src={BaremetalDep}
    height='32px'
    width='32px'
    alt='deployment environment icon'
  />
);
const EksDepIcon = (
  <img
    src={EksDep}
    height='32px'
    width='32px'
    alt='deployment environment icon'
  />
);
const IksDepIcon = (
  <img
    src={IksDep}
    height='32px'
    width='32px'
    alt='deployment environment icon'
  />
);
const RosaDepIcon = (
  <img
    src={RosaDep}
    height='32px'
    width='32px'
    alt='deployment environment icon'
  />
);
const VirtualMachineDepIcon = (
  <img
    src={VirtualMachineDep}
    height='32px'
    width='32px'
    alt='deployment environment icon'
  />
);
const VpcDepIcon = (
  <img
    src={VpcDep}
    height='32px'
    width='32px'
    alt='deployment environment icon'
  />
);
const AwsCloudIcon = (
  <img
    src={AwsCloud}
    height='32px'
    width='32px'
    alt='deployment environment icon'
  />
);
const IbmCloudIcon = (
  <img
    src={IbmCloud}
    height='32px'
    width='32px'
    alt='deployment environment icon'
  />
);
const MicrosoftCloudIcon = (
  <img
    src={MicrosoftCloud}
    height='32px'
    width='32px'
    alt='deployment environment icon'
  />
);
const GoogleCloudIcon = (
  <img
    src={GoogleCloud}
    height='32px'
    width='32px'
    alt='deployment environment icon'
  />
);

const GkeIcon = <img src={Gke} height='32px' width='32px' alt='GKE icon' />;
const OtherCloudIcon = (
  <img
    src={OtherCloud}
    height='32px'
    width='32px'
    alt='deployment environment icon'
  />
);

const autoDiscoverLabelIcon = (
  <img
    src={AutoDiscoverLabelSvg}
    height='16px'
    width='16px'
    alt='deployment environment icon'
  />
);

const questionIconSvg = (
  <img src={QuestionIconSvg} height='14px' width='14px' alt='info' />
);
const noSecretsIcon = (
  <img src={noSecretsSvg} height='73px' width='64px' alt='no secrets icon' />
);
const secretsNavIcon = (
  <img src={secretsNav} height='16px' width='16px' alt='secrets nav icon' />
);
const cloudSingleIcon = (
  <img src={cloudSingle} height='16px' width='16px' alt='actual cloud icon' />
);

const deploymentEnvIcon = (
  <img
    src={DeploymentEnvSvg}
    height='16px'
    width='16px'
    alt='deployment environment icon'
  />
);

const deploymentEnvBlueIcon = (
  <img
    src={DeploymentEnvBlueSvg}
    height='14px'
    width='14px'
    alt='deployment environment blue icon'
  />
);

const autoDiscoverdLockIcon = (
  <img
    src={AutoDiscoverdLockSvg}
    height='16px'
    width='16px'
    alt='Autodiscovered lock icon'
  />
);

const autoDiscoverdLockIconSmall = (
  <img
    src={AutoDiscoverdLockSvg}
    height='13px'
    width='13px'
    alt='Autodiscovered lock icon'
  />
);

const noApplicationsIcon = (
  <img
    src={noApplicationsSvg}
    height='80px'
    width='80px'
    alt='no applications defined'
  />
);

const noPartitionsIcon = (
  <img
    src={partitionLargeSVG}
    height='80px'
    width='80px'
    alt='no partitions defined'
  />
);

const sidePanelNoApplicationIcon = (
  <img
    src={noApplicationsSvg}
    height='64px'
    width='64px'
    alt='no applications defined'
  />
);

const noPoliciesIcon = (
  <img
    src={noPoliciesSvg}
    alt='no policies defined'
    height='80px'
    width='80px'
  />
);

const noDeploymentEnvsIcon = (
  <img
    src={noDeploymentEnvsSvg}
    alt='no deployment environments defined'
    height='80px'
    width='80px'
  />
);

const noAlertsIcon = <img src={noAlertsSvg} alt='no alerts reported' />;

const noSearchResultsIcon = (
  <img src={noSearchResultsSvg} alt='no search results' />
);

const noServicesIcon = <img src={noServicesSvg} alt='no search results' />;
const servicesLargeIcon = (
  <img src={servicesLargeSvg} alt='no search results' />
);

const sidePanelNoService = (
  <img src={noServicesSvg} alt='no search results' height='64px' width='64px' />
);

const sidePanelNoConnection = (
  <img
    src={sidePanelNoConnectionSvg}
    alt='no search results'
    height='64px'
    width='64px'
  />
);
const noCloudsIcon = <img src={noCloudsSvg} alt='no search results' />;

const homeIcon = (
  <img src={homeSvg} alt='Home icon' height='16px' width='16px' />
);

const alarmIcon = (
  <img src={alarmSvg} alt='Alarm icon' height='16px' width='16px' />
);

const applicationIcon = (
  <img src={applicationSvg} alt='Application icon' height='16px' width='16px' />
);

const cloudIcon = (
  <img src={cloudSvg} alt='Cloud icon' height='16px' width='16px' />
);

const deploymentIcon = (
  <img src={deploymentSvg} alt='Cloud icon' height='16px' width='16px' />
);

const policyIcon = (
  <img src={policySvg} alt='Cloud icon' height='16px' width='16px' />
);

const toggleUp = (
  <img src={toggleUpSvg} alt='Cloud icon' height='16px' width='16px' />
);
const toggleDown = (
  <img src={toggleDownSvg} alt='Cloud icon' height='16px' width='16px' />
);

const topologyImg = <img src={topologyPreview} alt='Topology preview' />;

const policyConnectionIcon = (
  <img src={policyConnection} alt='Policy connection icon' />
);

const topologyModalStep1 = <img src={modalStep1} alt='Topology modal step 1' />;

const topologyModalStep2 = <img src={modalStep2} alt='Topology modal step 2' />;

const topologyModalStep3 = <img src={modalStep3} alt='Topology modal step 3' />;

const CAPolicy = (
  <img
    src={CAPolicySvg}
    height='16px'
    width='16px'
    alt='connection access policy svg'
  />
);

const noDeploymentsIcon = (
  <img src={NoDeploymentsSvg} alt='no deployments' height='80px' width='80px' />
);

const emptyServices = (
  <img
    src={NoServiceSmall}
    alt='no services defined'
    height='60px'
    width='60px'
  />
);

const loginImg = (
  <img src={LoginSvg} alt='Login image' height='980px' width='1080px' />
);

const topologyIcon = (
  <img src={TopologyIconSvg} alt='Topology icon' height='16px' width='16px' />
);

const selectedTickIcon = (
  <img src={selectedTick} alt='selected' height='17px' width='17px' />
);

const informationIcon = (
  <img src={informationSvg} alt='selected' height='17px' width='17px' />
);

const deploymentEnvironmentEmpty = (
  <img
    src={noDeploymentEnvsSvg}
    alt='no deployment environments defined'
    height='80px'
    width='80px'
  />
);

const resourceGroupEmpty = (
  <img
    src={NoResourceGroupLarge}
    alt='no resource group defined'
    height='80px'
    width='80px'
  />
);

const resourceGroupEmptySmall = (
  <img
    src={NoResourceGroupSmall}
    alt='no resource group defined'
    height='60px'
    width='60px'
  />
);

const namespaceEmpty = (
  <img
    src={NoNamespace}
    alt='no namespace defined'
    height='60px'
    width='60px'
  />
);

const namespaceEmptyLargeIcon = (
  <img
    src={NoNamespace}
    alt='no namespace defined'
    height='80px'
    width='80px'
  />
);

const sidePnaelNoNamespaceEmptyIcon = (
  <img
    src={NoNamespace}
    alt='no namespace defined'
    height='64px'
    width='64px'
  />
);

const sidePanelNoSecurityGroupEmptyIcon = (
  <img
    src={sidePanelSecurityGroupEmpty}
    alt='no security group defined'
    height='64px'
    width='64px'
  />
);
const securityGroupEmpty = (
  <img
    src={NoSecurityGroup}
    alt='no security group defined'
    height='60px'
    width='60px'
  />
);

const securityGroupEmptyLargeIcon = (
  <img
    src={NoSecurityGroup}
    alt='no security group defined'
    height='80px'
    width='80px'
  />
);

const sidePanelDeploymentEmpty = (
  <img
    src={NoDeploymentSmall}
    alt='no deployment defined'
    height='64px'
    width='64px'
  />
);
const noDeploymentEmptySmall = (
  <img
    src={NoDeploymentSmall}
    alt='no deployment defined'
    height='60px'
    width='60px'
  />
);

const noApplicationDeploymentEmptySmall = (
  <img
    src={NoAppDeploymentSmall}
    alt='no application deployment defined'
    height='60px'
    width='60px'
  />
);

const notFoundLarge = (
  <img src={NotFoundLarge} alt='search not found' height='80px' width='80px' />
);

const notFoundSmall = (
  <img src={NotFoundSmall} alt='search not found' height='64px' width='64px' />
);

const connectionIcon = (
  <img
    src={connectionIconSvg}
    alt='icon to show connection from provider to consumer'
    height='16px'
    width='72px'
  />
);

const locationIcon = (
  <img
    src={LocationsIconSvg}
    height='16px'
    width='16px'
    alt='locations menu icon'
  />
);

const cloudLocation = (
  <img
    src={CloudLocationSvg}
    height='16px'
    width='16px'
    alt='locations menu icon'
  />
);

const NoLocationsLargeIcon = (
  <img
    src={NoLocationsLarge}
    alt='No locations defined'
    height='80px'
    width='80px'
  />
);

const NoLocationsSmallIcon = (
  <img
    src={NoLocationsSmall}
    alt='No locations defined'
    height='64px'
    width='64px'
  />
);

const cloudEmptyLarge = (
  <img src={NoCloudLarge} alt='no cloud defined' height='80px' width='80px' />
);

const cloudEmptySmall = (
  <img src={NoCloudSmall} alt='no cloud defined' height='60px' width='60px' />
);

const awsIcon = (
  <img src={aws} height='14px' width='23.55px' alt='aws cloud icon' />
);

const azureIcon = (
  <img src={azure} height='32px' width='32px' alt='azure cloud icon' />
);

const ibmIcon = (
  <img src={ibmCloud} height='14.67px' width='17.33px' alt='ibm cloud icon' />
);

const appServiceIcon = (
  <img src={appService} height='32px' width='32px' alt='ibm cloud icon' />
);

const edgeGatewayIcon = (
  <img
    src={EdgeGatewayIconSvg}
    alt='Edge gateway icon'
    height='16px'
    width='16px'
  />
);

const waypointGatewayIcon = (
  <img
    src={WaypointGatewayIconSvg}
    alt='Edge gateway icon'
    height='16px'
    width='16px'
  />
);

const autoDiscoverOnIcon = (
  <img
    src={autodiscoveryOn}
    height='16px'
    width='16px'
    alt='autodiscover enabled'
  />
);

const autoDiscoverOffIcon = (
  <img
    src={autodiscoveryOff}
    height='16px'
    width='16px'
    alt='autodiscover not enabled'
  />
);

const networkSegmentIcon = (
  <img
    src={NetworkSegmentIconSvg}
    alt='Network segment icon'
    height='16px'
    width='16px'
  />
);

const cloudNameIcon = (
  <img src={cloudNameSvg} height='16px' width='16px' alt='cloud icon' />
);
const secretsManager = (
  <img src={secretsManagerSvg} alt='secrets manager svg' />
);

const topologyCardImage = (
  <img src={DashboardTopologyCardSvg} alt='topology banner svg' />
);

const topologyCardImage_Phase1 = (
  <img
    src={DashboardTopologyCardSvg_Phase1}
    alt='topology card svg for phase one'
  />
);

const noLocationsImg = (
  <img src={noLocationsSvg} alt='No locations svg' height='80px' width='80px' />
);

const noAPIKeysIcon = (
  <img src={noAPIKeys} height='64px' width='64px' alt='key icon' />
);

const operationalStatusIcon = (
  <img
    src={operationalStatus}
    height='16px'
    width='16px'
    alt='Operational status icon'
  />
);

const barChartSwitcher = (
  <img
    src={barChartIcon}
    height='16px'
    width='16px'
    alt='switch to bar chart view'
  />
);

const lineChartSwitcher = (
  <img
    src={lineChartIcon}
    height='16px'
    width='16px'
    alt='switch to line chart view'
  />
);

const barChartSelectedIcon = (
  <img
    src={barChartSelected}
    height='16px'
    width='16px'
    alt='switch to bar chart view'
  />
);

const lineChartSelectedIcon = (
  <img
    src={lineChartSelected}
    height='16px'
    width='16px'
    alt='switch to line chart view'
  />
);

const criticalStatusIcon = (
  <img
    src={criticalStatus}
    height='16px'
    width='16px'
    alt='critical status gateway icon'
  />
);

const majorStatusIcon = (
  <img
    src={majorStatus}
    height='16px'
    width='16px'
    alt='Major status gateway icon'
  />
);

const minorStatusIcon = (
  <img
    src={minorStatus}
    height='16px'
    width='16px'
    alt='Minor status gateway icon'
  />
);

const noGatewaySmallIcon = (
  <img
    src={NoGatewaySmall}
    height='64px'
    width='64px'
    alt='no gateway small icon'
  />
);
const noGatewayLargeIcon = (
  <img
    src={NoGatewayLarge}
    height='80px'
    width='80px'
    alt='no gateway large icon'
  />
);

const newTabSelectedIcon = (
  <img src={newTabIcon} height='16px' width='16px' alt='new tab icon' />
);

const infoSmallEventsIcon = (
  <img
    src={infoSmallEventIcon}
    height='16px'
    width='16px'
    alt='info event status icon'
  />
);

const NoEventsLargeIcon = (
  <img src={NoEventsLarge} alt='No events defined' height='80px' width='80px' />
);

const eventIcon = (
  <img src={eventSvg} alt='Event icon' height='16px' width='16px' />
);

const undefinedIcon = (
  <img src={undefinedSvg} alt='Event icon' height='16px' width='16px' />
);

const noRemoteConnIcon = (
  <img
    src={noRemoteConn}
    height='58px'
    width='80px'
    alt='no remote connection icon'
  />
);
const secretNavIcon = (
  <img src={secretsManagerSvg} alt='Secret icon' height='16px' width='16px' />
);

const pendingStatusIcon = (
  <img src={pendingStatus} alt='Secret icon' height='16px' width='16px' />
);

const partitionIcon = <img src={PartitionSvg} alt='Partition icon' />;
const partitionMediumIcon = (
  <img src={PartitionSvg} alt='Partition icon' height='24px' width='24px' />
);

const notAuthorizedIcon = (
  <img src={notAuthorizedSvg} alt='Not authorized' height='64px' width='64px' />
);

const edgeApplicationIcon = (
  <img
    src={EdgeApplicationSvg}
    alt='Edge application'
    height='16px'
    width='16px'
  />
);

const expandToIcon = (
  <img src={ExpandToSvg} height='14px' width='14px' alt='Expand to' />
);

const noCloudDefinedIcon = (
  <img src={NoCloudDefined} alt='No cloud defined' height='64px' width='64px' />
);

const noCloudAccessIcon = (
  <img
    src={NoCloudAccess}
    alt='Not authorized to view cloud list'
    height='64px'
    width='64px'
  />
);

const noRolesIcon = (
  <img src={noRoles} alt='No roles have been assigned to this identity' />
);
const NoServicesSmall = (
  <img
    src={NoServicesSmallIcon}
    alt='No Services Small Icon'
    height='64px'
    width='64px'
  />
);

const noIdentityIcon = (
  <img
    src={NoIdentityIcon}
    alt='No Identity list and card'
    height='64px'
    width='64px'
  />
);

const notDeployedIcon = (
  <img src={notDeployed} alt='Not deployed' height='16px' width='16px' />
);

const unReachableIcon = (
  <img src={unReachable} alt='Unreachable' height='16px' width='16px' />
);
const InfoIconSvg = (
  <img src={InfoIcon} alt='Info Icon' height='16px' width='16px' />
);
const NoServicesLarge = (
  <img
    src={NoServicesLargeIcon}
    alt='No Services Large Icon'
    height='80px'
    width='80px'
  />
);

const NoAppDeploymentsSmall = (
  <img
    src={NoAppDeploymentsSmallIcon}
    alt='No AppDeployments Small Icon'
    height='64px'
    width='64px'
  />
);

const NoAppInstancesSmall = (
  <img
    src={NoAppInstancesSmallIcon}
    alt='No AppInstances Small Icon'
    height='64px'
    width='64px'
  />
);

const NoAppInstancesLarge = (
  <img
    src={NoAppInstancesLargeIcon}
    alt='No AppInstances Large Icon'
    height='80px'
    width='80px'
  />
);

const NoServiceEndpointSmall = (
  <img
    src={NoServiceEndpointSmallIcon}
    alt='No Service Endpoint Small Icon'
    height='64px'
    width='64px'
  />
);

const NoServiceEndpointLarge = (
  <img
    src={NoServiceEndpointLargeIcon}
    alt='No Service Endpoint Large Icon'
    height='80px'
    width='80px'
  />
);

const NoRemoteConnSmallIcon = (
  <img
    src={NoRemoteConnSmall}
    alt='No Remote connection Large Icon'
    height='64px'
    width='64px'
  />
);

const notAuthorizedLargeIcon = (
  <img
    src={notAuthorizedLarge}
    alt='not Authorized Large Icon'
    height='80px'
    width='80px'
  />
);

const NoTopologyDataSvg = (
  <img src={NoTopologyData} alt='No Topology Data svg' />
);

const NwSegmentIconSvgElement = (
  <img src={NwSegmentIconSvg} alt='network segment svg' />
);
const InfoIconTranspSvg = (
  <img src={InfoIconTransp} alt='Information Icon' height='16px' width='16px' />
);

const RefreshIconSvg = (
  <img src={RefreshIcon} alt='Refresh Data svg' height='16px' width='16px' />
);

const NetworkSegmentLargeSvg = (
  <img
    className='networkSegmentLargeSvg'
    src={NetworkSegmentLarge}
    alt='NetworkSegmentLarge Icon'
    height='22px'
    width='22px'
  />
);
const dashboardHero = <img src={DashboardHeroSvg} alt='dashboard hero' />;

const InfraOnlyIconSvg = (
  <img src={InfraOnlyIcon} alt='Infra Only Icon' height='24px' width='24px' />
);
const loadingStatusIcon = (
  <img src={loadingStatus} alt='Loading' height='16px' width='16px' />
);
const RedHatIconSvgElement = (
  <img
    src={RedHatIconSvg}
    alt='red hat gateway icon '
    height='16px'
    width='16px'
  />
);

const MeshIconSvgElement = (
  <img src={MeshIconSvg} alt='mesh icon ' height='16px' width='16px' />
);

const VpcIconSvgElement = (
  <img
    src={VpcIconSvg}
    height='16px'
    width='16px'
    alt='deployment environment icon'
  />
);

const serviceIconSmall = (
  <img src={serviceIconSvg} alt='service icon' height='16px' width='16px' />
);

const locationLegendSvg = (
  <img
    src={locationLegend}
    alt='locationLegend Icon'
    height='40px'
    width='40px'
  />
);

const clusterLegendSvg = (
  <img
    src={clusterLegend}
    alt='locationLegend Icon'
    height='40px'
    width='40px'
  />
);
const vpcLegendSvg = (
  <img src={vpcLegend} alt='locationLegend Icon' height='40px' width='40px' />
);
const infraLegendSvg = (
  <img src={infraLegend} alt='locationLegend Icon' height='40px' width='40px' />
);
const nodeLegendSvg = (
  <img src={nodeLegend} alt='locationLegend Icon' height='40px' width='40px' />
);
const partitionLegendSvg = (
  <img
    src={partitionLegend}
    alt='locationLegend Icon'
    height='40px'
    width='40px'
  />
);
const edgeLegendSvg = (
  <img src={edgeLegend} alt='locationLegend Icon' height='40px' width='40px' />
);
const waypointLegendSvg = (
  <img
    src={waypointLegend}
    alt='locationLegend Icon'
    height='40px'
    width='40px'
  />
);
const applicationLegendSvg = (
  <img
    src={applicationLegend}
    alt='locationLegend Icon'
    height='40px'
    width='40px'
  />
);
const serviceLegendSvg = (
  <img
    src={serviceLegend}
    alt='locationLegend Icon'
    height='40px'
    width='40px'
  />
);
const activeLegendSvg = (
  <img src={activeLegend} alt='active Icon' height='2px' width='205px' />
);
const relationalLegendSvg = (
  <img
    src={relationalLegend}
    alt='relationalLegend Icon'
    height='5px'
    width='205px'
  />
);
const policyLegendSvg = (
  <img src={policyLegend} alt='policyLegend Icon' height='2px' width='205px' />
);
const gatewayLegendSvg = (
  <img
    src={gatewayLegend}
    alt='gatewayLegend Icon'
    height='3px'
    width='205px'
  />
);
const unmanagedLegendSvg = (
  <img src={unmanagedLegend} alt='unmanaged Icon' height='22px' width='22px' />
);
const alertsLegend = (
  <img src={alerts} alt='alerts Icon' height='24px' width='24px' />
);
const appToServiceLegend = (
  <img src={appToService} alt='appToService Icon' height='24px' width='24px' />
);
const autoDiscoveredLegend = (
  <img
    src={autoDiscovered}
    alt='autoDiscovered Icon'
    height='24px'
    width='24px'
  />
);
const eventsLegend = (
  <img src={events} alt='events Icon' height='24px' width='24px' />
);
const gatewayToGatewayLegend = (
  <img src={gatewayToGateway} alt='expandsTo Icon' height='24px' width='24px' />
);
const expandsToLegend = (
  <img src={expandsTo} alt='expandsTo Icon' height='24px' width='24px' />
);
const layersLegend = (
  <img src={layers} alt='layers Icon' height='24px' width='24px' />
);
const notAuthorizedLockLegend = (
  <img
    src={notAuthorizedLock}
    alt='notAuthorizedLock Icon'
    height='24px'
    width='24px'
  />
);
const topologyLegend = (
  <img src={topology} alt='topology Icon' height='24px' width='24px' />
);
const error500Icon = <img src={error500Svg} alt='Error 500' />;

const error500SmallIcon = <img src={error500SmallSvg} alt='Error 500' />;

const leftArrowSvg = (
  <img src={leftArrow} alt='leftArrow Icon' height='16px' width='16px' />
);

const rightArrowSvg = (
  <img src={rightArrow} alt='rightArrow Icon' height='16px' width='16px' />
);

const editPencilIcon = (
  <img src={editPencilSvg} alt='editIcon Icon' height='32px' width='32px' />
);

const infoQuestionIcon = (
  <img
    src={infoQuestionSvg}
    alt='infoQuestion Icon'
    height='16px'
    width='16px'
  />
);

const checkMarkIcon = (
  <img
    src={checkMark}
    className='check-mark-icon'
    alt='check mark Icon'
    height='16px'
    width='16px'
  />
);

const AwsCloudDropDownIcon = (
  <img src={AwsCloudDropdown} height='16px' width='16px' alt='AWS cloud icon' />
);
const IbmCloudDropDownIcon = (
  <img src={IbmCloudDropdown} height='16px' width='16px' alt='IBM cloud icon' />
);
const MicrosoftCloudDropDownIcon = (
  <img
    src={MicrosoftCloudDropdown}
    height='16px'
    width='16px'
    alt='Microsoft Azure cloud icon'
  />
);
const GoogleCloudDropDownIcon = (
  <img
    src={GoogleCloudDropdown}
    height='16px'
    width='16px'
    alt='Google cloud icon'
  />
);
const OtherCloudDropDownIcon = (
  <img
    src={OtherCloudDropdown}
    height='16px'
    width='16px'
    alt='Other cloud icon'
  />
);
const connectGatewayStep = (
  <img
    src={connectGatewayStepPng}
    alt='connectGateway Step'
    height='288px'
    width='376px'
  />
);
const majorIcon = (
  <img src={majorIconSvg} alt='Major event Icon' height='16px' width='16px' />
);

const managedIcon = (
  <img src={managedSvg} alt='Managed Icon' height='16px' width='16px' />
);

const unmanagedIcon = (
  <img src={unmanagedSvg} alt='Unmanaged Icon' height='16px' width='16px' />
);

export default {
  infoQuestionIconSvg() {
    return infoQuestionIcon;
  },
  doubleClickLegendSvg() {
    return doubleClickLegendIcon;
  },
  singleClickLegendSvg() {
    return singleClickLegendIcon;
  },
  shiftKeyLegendSvg() {
    return shiftKeyLegendIcon;
  },
  topologyLegend() {
    return topologyLegend;
  },
  notAuthorizedLockLegend() {
    return notAuthorizedLockLegend;
  },
  layersLegend() {
    return layersLegend;
  },
  gatewayToGatewayLegend() {
    return gatewayToGatewayLegend;
  },
  expandsToLegend() {
    return expandsToLegend;
  },
  eventsLegend() {
    return eventsLegend;
  },
  autoDiscoveredLegend() {
    return autoDiscoveredLegend;
  },
  appToServiceLegend() {
    return appToServiceLegend;
  },
  alertsLegend() {
    return alertsLegend;
  },
  unmanagedLegendSvg() {
    return unmanagedLegendSvg;
  },
  activeLegendSvg() {
    return activeLegendSvg;
  },
  relationalLegendSvg() {
    return relationalLegendSvg;
  },
  policyLegendSvg() {
    return policyLegendSvg;
  },
  gatewayLegendSvg() {
    return gatewayLegendSvg;
  },
  locationLegendSvg() {
    return locationLegendSvg;
  },
  clusterLegendSvg() {
    return clusterLegendSvg;
  },
  vpcLegendSvg() {
    return vpcLegendSvg;
  },
  infraLegendSvg() {
    return infraLegendSvg;
  },
  nodeLegendSvg() {
    return nodeLegendSvg;
  },
  partitionLegendSvg() {
    return partitionLegendSvg;
  },
  edgeLegendSvg() {
    return edgeLegendSvg;
  },
  waypointLegendSvg() {
    return waypointLegendSvg;
  },
  applicationLegendSvg() {
    return applicationLegendSvg;
  },
  serviceLegendSvg() {
    return serviceLegendSvg;
  },
  aksDepIcon() {
    return AksDepIcon;
  },
  azureAksIcon() {
    return AzureAksIcone
  },
  baremetalDepIcon() {
    return BaremetalDepIcon;
  },
  eksDepIcon() {
    return EksDepIcon;
  },
  iksDepIcon() {
    return IksDepIcon;
  },
  rosaDepIcon() {
    return RosaDepIcon;
  },
  virtualMachineDepIcon() {
    return VirtualMachineDepIcon;
  },
  vpcDepIcon() {
    return VpcDepIcon;
  },
  awsCloudIcon() {
    return AwsCloudIcon;
  },
  ibmCloudIcon() {
    return IbmCloudIcon;
  },
  microsoftCloudIcon() {
    return MicrosoftCloudIcon;
  },
  googleCloudIcon() {
    return GoogleCloudIcon;
  },
  gkeIcon() {
    return GkeIcon;
  },
  otherCloudIcon() {
    return OtherCloudIcon;
  },
  edgeApplication() {
    return edgeApplicationIcon;
  },
  noLocations() {
    return noLocationsImg;
  },
  secretsNavImg() {
    return secretsNavIcon;
  },
  topologyCardImage() {
    return topologyCardImage;
  },
  topologyCardImagePhase1() {
    return topologyCardImage_Phase1;
  },
  secretsManager() {
    return secretsManager;
  },
  CAPolicyIcon() {
    return CAPolicy;
  },
  homeIcon() {
    return homeIcon;
  },

  alarmIcon() {
    return alarmIcon;
  },

  applicationIcon() {
    return applicationIcon;
  },

  cloudIcon() {
    return cloudIcon;
  },

  deploymentIcon() {
    return deploymentIcon;
  },
  policyIcon() {
    return policyIcon;
  },
  heroIllustration() {
    return dashboardHero;
  },
  topologyImg() {
    return topologyImg;
  },
  noSearchResultsIcon() {
    return noSearchResultsIcon;
  },
  noServicesIcon() {
    return noServicesIcon;
  },
  noServicesLargeIcon() {
    return servicesLargeIcon;
  },
  noCloudsIcon() {
    return noCloudsIcon;
  },
  policyConnectionIcon() {
    return policyConnectionIcon;
  },
  topologyModalStep1() {
    return topologyModalStep1;
  },
  topologyModalStep2() {
    return topologyModalStep2;
  },
  topologyModalStep3() {
    return topologyModalStep3;
  },
  noApplicationsIcon() {
    return noApplicationsIcon;
  },
  noPartitionsIcon() {
    return noPartitionsIcon;
  },
  noPoliciesIcon() {
    return noPoliciesIcon;
  },
  noAlertsIcon() {
    return noAlertsIcon;
  },
  noDeploymentEnvsIcon() {
    return noDeploymentEnvsIcon;
  },
  deploymentEnvIcon() {
    return deploymentEnvIcon;
  },
  deploymentEnvBlueIcon() {
    return deploymentEnvBlueIcon;
  },
  noDeploymentsIcon() {
    return noDeploymentsIcon;
  },
  cloudSingleIcon() {
    return cloudSingleIcon;
  },
  AutoDiscoverdLockIcon() {
    return autoDiscoverdLockIcon;
  },
  autoDiscoverLabelIcon() {
    return autoDiscoverLabelIcon;
  },
  questionIconSvg() {
    return questionIconSvg;
  },
  emptyServicesIcon() {
    return emptyServices;
  },
  loginImg() {
    return loginImg;
  },
  AutoDiscoverdLockIconSmall() {
    return autoDiscoverdLockIconSmall;
  },
  selectedTickIcon() {
    return selectedTickIcon;
  },
  informationIcon() {
    return informationIcon;
  },
  topologyIcon() {
    return topologyIcon;
  },
  DeploymentEnvironmentEmpty() {
    return deploymentEnvironmentEmpty;
  },
  ResourceGroupEmpty() {
    return resourceGroupEmpty;
  },
  ResourceGroupEmptySmall() {
    return resourceGroupEmptySmall;
  },
  NamespaceEmpty() {
    return namespaceEmpty;
  },
  SecurityGroupEmpty() {
    return securityGroupEmpty;
  },
  NoDeploymentEmptySmall() {
    return noDeploymentEmptySmall;
  },
  NoApplicationDeploymentSmall() {
    return noApplicationDeploymentEmptySmall;
  },
  NotFoundLarge() {
    return notFoundLarge;
  },
  NotFoundSmall() {
    return notFoundSmall;
  },
  cloudLocation() {
    return cloudLocation;
  },
  ConnectionIcon() {
    return connectionIcon;
  },
  LocationIcon() {
    return locationIcon;
  },
  noLocationsLarge() {
    return NoLocationsLargeIcon;
  },
  noLocationsSmall() {
    return NoLocationsSmallIcon;
  },
  NoCloudLarge() {
    return cloudEmptyLarge;
  },
  NoCloudSmall() {
    return cloudEmptySmall;
  },
  GetAWSIcon() {
    return awsIcon;
  },
  GetIBMIcon() {
    return ibmIcon;
  },
  GetAZUREIcon() {
    return azureIcon;
  },
  AppServiceIcon() {
    return appServiceIcon;
  },
  EdgeGatewayIcon() {
    return edgeGatewayIcon;
  },
  WaypointGatewayIcon() {
    return waypointGatewayIcon;
  },
  AutoDiscoverOnIcon() {
    return autoDiscoverOnIcon;
  },
  AutoDiscoverOffIcon() {
    return autoDiscoverOffIcon;
  },
  CloudNameIcon() {
    return cloudNameIcon;
  },
  toggleUp() {
    return toggleUp;
  },
  toggleDown() {
    return toggleDown;
  },
  SidePanelDeploymentEmpty() {
    return sidePanelDeploymentEmpty;
  },
  SidePanelNoApplicationIcon() {
    return sidePanelNoApplicationIcon;
  },
  SidePnaelNoNamespaceEmptyIcon() {
    return sidePnaelNoNamespaceEmptyIcon;
  },
  SidePanelNoSecurityGroupEmptyIcon() {
    return sidePanelNoSecurityGroupEmptyIcon;
  },
  SidePanelNoServiceIcon() {
    return sidePanelNoService;
  },
  SidePanelNoConnectionIcon() {
    return sidePanelNoConnection;
  },
  noAPIKeysIcon() {
    return noAPIKeysIcon;
  },
  namespaceEmptyLarge() {
    return namespaceEmptyLargeIcon;
  },
  securityGroupEmptyLarge() {
    return securityGroupEmptyLargeIcon;
  },
  operationalStatusIcon() {
    return operationalStatusIcon;
  },
  BarChartSwitcher() {
    return barChartSwitcher;
  },
  LineChartSwitcher() {
    return lineChartSwitcher;
  },
  BarChartSelectedIcon() {
    return barChartSelectedIcon;
  },
  LineChartSelectedIcon() {
    return lineChartSelectedIcon;
  },
  majorStatusIcon() {
    return majorStatusIcon;
  },
  minorStatusIcon() {
    return minorStatusIcon;
  },
  noGatewaySmallIcon() {
    return noGatewaySmallIcon;
  },
  noGatewayLargeIcon() {
    return noGatewayLargeIcon;
  },
  NewTabIcon() {
    return newTabSelectedIcon;
  },
  criticalStatusIcon() {
    return criticalStatusIcon;
  },
  infoSmallEventIcon() {
    return infoSmallEventsIcon;
  },
  noEventsLarge() {
    return NoEventsLargeIcon;
  },
  eventIcon() {
    return eventIcon;
  },
  undefinedIcon() {
    return undefinedIcon;
  },
  NoRemoteConnIcon() {
    return noRemoteConnIcon;
  },
  SecretNavIcon() {
    return secretNavIcon;
  },
  NoSecretsIcon() {
    return noSecretsIcon;
  },
  pendingStatusIcon() {
    return pendingStatusIcon;
  },
  partitionIcon() {
    return partitionIcon;
  },
  partitionMediumIcon() {
    return partitionMediumIcon;
  },
  notAuthorizedIcon() {
    return notAuthorizedIcon;
  },
  expandToIcon() {
    return expandToIcon;
  },
  noCloudDefinedIcon() {
    return noCloudDefinedIcon;
  },
  noCloudAccessIcon() {
    return noCloudAccessIcon;
  },
  noRoleIcon() {
    return noRolesIcon;
  },
  noIdentityIcon() {
    return noIdentityIcon;
  },
  noServicesSmallIcon() {
    return NoServicesSmall;
  },
  noServicesLargeIcon() {
    return NoServicesLarge;
  },
  noAppDeploymentSmallIcon() {
    return NoAppDeploymentsSmall;
  },

  noAppInstancesLargeIcon() {
    return NoAppInstancesLarge;
  },
  noAppInstancesSmallIcon() {
    return NoAppInstancesSmall;
  },
  noServiceEndpointLargeIcon() {
    return NoServiceEndpointLarge;
  },
  noServiceEndpointSmallIcon() {
    return NoServiceEndpointSmall;
  },
  notDeployedIcon() {
    return notDeployedIcon;
  },
  unReachableIcon() {
    return unReachableIcon;
  },
  InfoIconSvgTooltip() {
    return InfoIconSvg;
  },
  NoRemoteConnSmallIcon() {
    return NoRemoteConnSmallIcon;
  },
  notAuthorizedSvg() {
    return notAuthorizedLargeIcon;
  },
  NetworkSegmentIcon() {
    return networkSegmentIcon;
  },
  NoTopologyDataSvg() {
    return NoTopologyDataSvg;
  },
  nwSegmentIconSvgElement() {
    return NwSegmentIconSvgElement;
  },
  infoIconTranspSvg() {
    return InfoIconTranspSvg;
  },
  networkSegmentLargeSvg() {
    return NetworkSegmentLargeSvg;
  },
  infraOnlyIconSvg() {
    return InfraOnlyIconSvg;
  },
  RefreshIconSvg() {
    return RefreshIconSvg;
  },
  loadingStatusIcon() {
    return loadingStatusIcon;
  },
  redHatIconSvgElement() {
    return RedHatIconSvgElement;
  },
  meshIconSvgElement() {
    return MeshIconSvgElement;
  },
  vpcIconSvg() {
    return VpcIconSvgElement;
  },
  serviceIconSmall() {
    return serviceIconSmall;
  },
  error500Icon() {
    return error500Icon;
  },
  error500SmallIcon() {
    return error500SmallIcon;
  },
  editIconSvg() {
    return editPencilIcon;
  },

  homeIcon() {
    return homeIcon;
  },

  alarmIcon() {
    return alarmIcon;
  },

  applicationIcon() {
    return applicationIcon;
  },

  cloudIcon() {
    return cloudIcon;
  },

  deploymentIcon() {
    return deploymentIcon;
  },
  policyIcon() {
    return policyIcon;
  },
  heroIllustration() {
    return dashboardHero;
  },
  topologyImg() {
    return topologyImg;
  },
  noSearchResultsIcon() {
    return noSearchResultsIcon;
  },
  noServicesIcon() {
    return noServicesIcon;
  },
  noServicesLargeIcon() {
    return servicesLargeIcon;
  },
  noCloudsIcon() {
    return noCloudsIcon;
  },
  policyConnectionIcon() {
    return policyConnectionIcon;
  },
  topologyModalStep1() {
    return topologyModalStep1;
  },
  topologyModalStep2() {
    return topologyModalStep2;
  },
  topologyModalStep3() {
    return topologyModalStep3;
  },
  noApplicationsIcon() {
    return noApplicationsIcon;
  },
  noPartitionsIcon() {
    return noPartitionsIcon;
  },
  noPoliciesIcon() {
    return noPoliciesIcon;
  },
  noAlertsIcon() {
    return noAlertsIcon;
  },
  noDeploymentEnvsIcon() {
    return noDeploymentEnvsIcon;
  },
  deploymentEnvIcon() {
    return deploymentEnvIcon;
  },
  deploymentEnvBlueIcon() {
    return deploymentEnvBlueIcon;
  },
  noDeploymentsIcon() {
    return noDeploymentsIcon;
  },
  cloudSingleIcon() {
    return cloudSingleIcon;
  },
  AutoDiscoverdLockIcon() {
    return autoDiscoverdLockIcon;
  },
  autoDiscoverLabelIcon() {
    return autoDiscoverLabelIcon;
  },
  questionIconSvg() {
    return questionIconSvg;
  },
  emptyServicesIcon() {
    return emptyServices;
  },
  loginImg() {
    return loginImg;
  },
  AutoDiscoverdLockIconSmall() {
    return autoDiscoverdLockIconSmall;
  },
  selectedTickIcon() {
    return selectedTickIcon;
  },
  informationIcon() {
    return informationIcon;
  },
  topologyIcon() {
    return topologyIcon;
  },
  DeploymentEnvironmentEmpty() {
    return deploymentEnvironmentEmpty;
  },
  ResourceGroupEmpty() {
    return resourceGroupEmpty;
  },
  ResourceGroupEmptySmall() {
    return resourceGroupEmptySmall;
  },
  NamespaceEmpty() {
    return namespaceEmpty;
  },
  SecurityGroupEmpty() {
    return securityGroupEmpty;
  },
  NoDeploymentEmptySmall() {
    return noDeploymentEmptySmall;
  },
  NoApplicationDeploymentSmall() {
    return noApplicationDeploymentEmptySmall;
  },
  NotFoundLarge() {
    return notFoundLarge;
  },
  NotFoundSmall() {
    return notFoundSmall;
  },
  cloudLocation() {
    return cloudLocation;
  },
  ConnectionIcon() {
    return connectionIcon;
  },
  LocationIcon() {
    return locationIcon;
  },
  noLocationsLarge() {
    return NoLocationsLargeIcon;
  },
  noLocationsSmall() {
    return NoLocationsSmallIcon;
  },
  NoCloudLarge() {
    return cloudEmptyLarge;
  },
  NoCloudSmall() {
    return cloudEmptySmall;
  },
  GetAWSIcon() {
    return awsIcon;
  },
  GetIBMIcon() {
    return ibmIcon;
  },
  GetAZUREIcon() {
    return azureIcon;
  },
  AppServiceIcon() {
    return appServiceIcon;
  },
  EdgeGatewayIcon() {
    return edgeGatewayIcon;
  },
  WaypointGatewayIcon() {
    return waypointGatewayIcon;
  },
  AutoDiscoverOnIcon() {
    return autoDiscoverOnIcon;
  },
  AutoDiscoverOffIcon() {
    return autoDiscoverOffIcon;
  },
  CloudNameIcon() {
    return cloudNameIcon;
  },
  toggleUp() {
    return toggleUp;
  },
  toggleDown() {
    return toggleDown;
  },
  SidePanelDeploymentEmpty() {
    return sidePanelDeploymentEmpty;
  },
  SidePanelNoApplicationIcon() {
    return sidePanelNoApplicationIcon;
  },
  SidePnaelNoNamespaceEmptyIcon() {
    return sidePnaelNoNamespaceEmptyIcon;
  },
  SidePanelNoSecurityGroupEmptyIcon() {
    return sidePanelNoSecurityGroupEmptyIcon;
  },
  SidePanelNoServiceIcon() {
    return sidePanelNoService;
  },
  SidePanelNoConnectionIcon() {
    return sidePanelNoConnection;
  },
  noAPIKeysIcon() {
    return noAPIKeysIcon;
  },
  namespaceEmptyLarge() {
    return namespaceEmptyLargeIcon;
  },
  securityGroupEmptyLarge() {
    return securityGroupEmptyLargeIcon;
  },
  operationalStatusIcon() {
    return operationalStatusIcon;
  },
  BarChartSwitcher() {
    return barChartSwitcher;
  },
  LineChartSwitcher() {
    return lineChartSwitcher;
  },
  BarChartSelectedIcon() {
    return barChartSelectedIcon;
  },
  LineChartSelectedIcon() {
    return lineChartSelectedIcon;
  },
  majorStatusIcon() {
    return majorStatusIcon;
  },
  minorStatusIcon() {
    return minorStatusIcon;
  },
  noGatewaySmallIcon() {
    return noGatewaySmallIcon;
  },
  noGatewayLargeIcon() {
    return noGatewayLargeIcon;
  },
  NewTabIcon() {
    return newTabSelectedIcon;
  },
  criticalStatusIcon() {
    return criticalStatusIcon;
  },
  infoSmallEventIcon() {
    return infoSmallEventsIcon;
  },
  noEventsLarge() {
    return NoEventsLargeIcon;
  },
  eventIcon() {
    return eventIcon;
  },
  undefinedIcon() {
    return undefinedIcon;
  },
  NoRemoteConnIcon() {
    return noRemoteConnIcon;
  },
  SecretNavIcon() {
    return secretNavIcon;
  },
  NoSecretsIcon() {
    return noSecretsIcon;
  },
  pendingStatusIcon() {
    return pendingStatusIcon;
  },
  partitionIcon() {
    return partitionIcon;
  },
  partitionMediumIcon() {
    return partitionMediumIcon;
  },
  notAuthorizedIcon() {
    return notAuthorizedIcon;
  },
  expandToIcon() {
    return expandToIcon;
  },
  noCloudDefinedIcon() {
    return noCloudDefinedIcon;
  },
  noCloudAccessIcon() {
    return noCloudAccessIcon;
  },
  noRoleIcon() {
    return noRolesIcon;
  },
  noIdentityIcon() {
    return noIdentityIcon;
  },
  noServicesSmallIcon() {
    return NoServicesSmall;
  },
  noServicesLargeIcon() {
    return NoServicesLarge;
  },
  noAppDeploymentSmallIcon() {
    return NoAppDeploymentsSmall;
  },

  noAppInstancesLargeIcon() {
    return NoAppInstancesLarge;
  },
  noAppInstancesSmallIcon() {
    return NoAppInstancesSmall;
  },
  noServiceEndpointLargeIcon() {
    return NoServiceEndpointLarge;
  },
  noServiceEndpointSmallIcon() {
    return NoServiceEndpointSmall;
  },
  notDeployedIcon() {
    return notDeployedIcon;
  },
  unReachableIcon() {
    return unReachableIcon;
  },
  InfoIconSvgTooltip() {
    return InfoIconSvg;
  },
  NoRemoteConnSmallIcon() {
    return NoRemoteConnSmallIcon;
  },
  notAuthorizedSvg() {
    return notAuthorizedLargeIcon;
  },
  NetworkSegmentIcon() {
    return networkSegmentIcon;
  },
  NoTopologyDataSvg() {
    return NoTopologyDataSvg;
  },
  nwSegmentIconSvgElement() {
    return NwSegmentIconSvgElement;
  },
  infoIconTranspSvg() {
    return InfoIconTranspSvg;
  },
  networkSegmentLargeSvg() {
    return NetworkSegmentLargeSvg;
  },
  infraOnlyIconSvg() {
    return InfraOnlyIconSvg;
  },
  RefreshIconSvg() {
    return RefreshIconSvg;
  },
  loadingStatusIcon() {
    return loadingStatusIcon;
  },
  redHatIconSvgElement() {
    return RedHatIconSvgElement;
  },
  meshIconSvgElement() {
    return MeshIconSvgElement;
  },
  vpcIconSvg() {
    return VpcIconSvgElement;
  },
  serviceIconSmall() {
    return serviceIconSmall;
  },
  error500Icon() {
    return error500Icon;
  },
  error500SmallIcon() {
    return error500SmallIcon;
  },
  editIconSvg() {
    return editPencilIcon;
  },
  leftArrowSvg() {
    return leftArrowSvg;
  },
  rightArrowSvg() {
    return rightArrowSvg;
  },
  awsCloudDropDownIcon() {
    return AwsCloudDropDownIcon;
  },
  ibmCloudDropDownIcon() {
    return IbmCloudDropDownIcon;
  },
  microsoftCloudDropDownIcon() {
    return MicrosoftCloudDropDownIcon;
  },
  googleCloudDropDownIcon() {
    return GoogleCloudDropDownIcon;
  },
  otherCloudDropDownIcon() {
    return OtherCloudDropDownIcon;
  },
  connectGatewayStepPng() {
    return connectGatewayStep;
  },
  majorIconSvg() {
    return majorIcon;
  },
  checkMarkIconSvg() {
    return checkMarkIcon;
  },
  managedIcon() {
    return managedIcon;
  },
  unmanagedIcon() {
    return unmanagedIcon;
  },
};
