import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextInput, TextArea } from 'carbon-components-react';
import AddLabels from '../../../components/AddLabels/AddLabels';

import './EditIdentityDetails.scss';

const EditIdentityDetailsForm = (props: { onChange: any; formData: any }) => {
  const { formData, onChange } = props;
  const { t } = useTranslation('editIdentityDetails');

  return (
    <div className='edit-identityDetails-form'>
      <TextInput
        id='editIdentityName'
        className='form-field'
        name='name'
        placeholder={t('form.identityName.placeholder')}
        labelText={t<string>('form.identityName.label')}
        value={formData.name.value}
        onChange={e => onChange('name', e.target.value)}
        invalid={formData.name.error}
        invalidText={formData.name.errorMessage}
        maxLength={60}
      />

      <TextArea
        id='addApplicationDescription'
        name='description'
        className='form-field'
        autoComplete='off'
        labelText={t('form.identityDescription.label')}
        placeholder={t('form.identityDescription.placeholder')}
        onChange={e => onChange('description', e.target.value)}
        value={formData.description.value}
        maxCount={300}
        enableCounter={true}
      />

      <AddLabels
        id='editIdentityLabels'
        labelText={t('form.labels.label')}
        placeholder={t('form.labels.placeholder')}
        onChange={data => onChange('labels', data)}
        btnText={t('form.labels.buttonText')}
        btnKind='secondary'
        tagType='green'
        defaultValues={formData.labels.value}
      />
    </div>
  );
};

export default EditIdentityDetailsForm;
