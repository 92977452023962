import React from 'react';
import { Link } from 'carbon-components-react';
import LabelTag from '../../LabelTag/LabelTag';
import { Locked16 } from '@carbon/icons-react';
import { TFunction, useTranslation } from 'react-i18next';
import SidePanelEmptyState from '../SidePanelEmptyState/SidePanelEmptyState';

import './SidePanelDetailsComponent.scss';

type iconData = {
  value: string;
  icon: any;
  valueType?: string;
  linkCallback?: () => void;
};

export type data = {
  label: string;
  value?: string | string[] | JSX.Element | any[];
  iconWithValue?: iconData[];
  linkCallback?: () => void;
  valueType: string;
  notAuthorized?: boolean;
};

type Props = {
  data: data[];
  notAuthorized?: boolean;
  error500?: boolean;
};

const addressValues = (address: any) => {
  return address.map((value: string) => {
    return <div className='value'>{value}</div>;
  });
};

const getFormattedValue = (
  data: any,
  t: TFunction<'customSidePanel', undefined>,
  notAuthorized = false
) => {
  if (notAuthorized) {
    return (
      <div className='not-authorized-icon'>
        <Locked16 /> <span className='value'> {t('notAuthorized.header')}</span>
      </div>
    );
  }

  if (Array.isArray(data)) {
    return data.length > 0 ? data : '—';
  }

  if (Array.isArray(data)) {
    return data.length > 0 ? data : '—';
  }

  if (typeof data == 'boolean') {
    return data;
  }

  return data ? data : '—';
};

const SidePanelDetailsCard: React.FC<Props> = ({
  data,
  notAuthorized = false,
  error500 = false,
}) => {
  const { t } = useTranslation('customSidePanel');

  if (notAuthorized) {
    return (
      <SidePanelEmptyState
        icon={<></>}
        header=''
        message=''
        notAuthorized={true}
      />
    );
  }

  if (error500) {
    return (
      <SidePanelEmptyState icon={<></>} header='' message='' error500={true} />
    );
  }

  return (
    <div className='sidePanelDetailsTab'>
      {data.map((item: data, index) => {
        if (item.valueType === 'displayField') {
          return (
            <div key={index}>
              <div className='label'>{item.label}</div>
              <div className='value'>
                {getFormattedValue(item.value, t, item.notAuthorized)}
              </div>
            </div>
          );
        } else if (item.valueType === 'displayFieldWithIcon') {
          return (
            <div key={index}>
              <div className='label'>{item.label}</div>
              <div className='iconRoot'>
                {item.iconWithValue?.map(iconItem => {
                  return (
                    <div className='iconWithValue'>
                      {iconItem.valueType ? (
                        <>
                          <div className='linkIcon'>
                            {' '}
                            {iconItem.value ? iconItem.icon : ''}
                          </div>
                          <div className='value'>
                            {iconItem.value ? (
                              <Link
                                className='hyperLink iconValueLink'
                                onClick={() =>
                                  iconItem.linkCallback &&
                                  iconItem.linkCallback()
                                }
                              >
                                {iconItem.value}
                              </Link>
                            ) : (
                              getFormattedValue(null, t, item.notAuthorized)
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          {iconItem.value && <div> {iconItem.icon}</div>}
                          {iconItem.value ? (
                            <div className='iconValue'>{iconItem.value}</div>
                          ) : (
                            <div>
                              {getFormattedValue(
                                iconItem.value,
                                t,
                                item.notAuthorized
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        } else if (item.valueType === 'labelsList') {
          return (
            <div key={index}>
              <div className='label'>{item.label}</div>
              <div className='value'>
                {Array.isArray(item.value) && !item.notAuthorized && (
                  <LabelTag
                    labelArray={item.value as string[]}
                    count={3}
                  ></LabelTag>
                )}
                {item.notAuthorized &&
                  getFormattedValue(null, t, item.notAuthorized)}
              </div>
            </div>
          );
        } else if (item.valueType === 'link') {
          return (
            <div key={index}>
              <div className='label'>{item.label}</div>
              <div className='value'>
                {item.value ? (
                  <Link
                    className='hyperLink'
                    onClick={() => item.linkCallback && item.linkCallback()}
                  >
                    {item.value}
                  </Link>
                ) : (
                  getFormattedValue(null, t, item.notAuthorized)
                )}
              </div>
            </div>
          );
        } else if (item.valueType === 'address') {
          return (
            <div key={index}>
              <div className='label'>{item.label}</div>
              {getFormattedValue(
                addressValues(item?.value),
                t,
                item.notAuthorized
              )}
            </div>
          );
        } else {
          return <></>;
        }
      })}
    </div>
  );
};

export default SidePanelDetailsCard;
