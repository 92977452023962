import React from 'react';
import { useTranslation } from 'react-i18next';
import images from '../../images/images';

import './TopologyEmptyState.scss';

const TopologyEmptyState: React.FC = () => {
  const { t } = useTranslation('topologyPage');

  return (
    <div className='topology-empty-page'>
      <div className='empty-image'>{images.NoTopologyDataSvg()}</div>
      <div className='dataset'>
        <div className='header'>{t('emptyState.header')}</div>
        <div className='description'>{t('emptyState.description')}</div>
      </div>
    </div>
  );
};

export default TopologyEmptyState;
