import { axiosInstance } from './api';
import { MCNM_AUTHORIZATION_API_URL } from './config';

//Get all of the identities that MCNM oversees
export async function getIdentities() {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_AUTHORIZATION_API_URL}/identity`,
    });
    return response.data?.identities ?? [];
  } catch (error) {
    console.log('error in GET identities', error);
    throw error;
  }
}

//Add a new identity that MCNM should oversee
export async function addIdentity(data) {
  try {
    const response = await axiosInstance({
      method: 'POST',
      url: `${MCNM_AUTHORIZATION_API_URL}/identity`,
      data,
    });
    return response.data;
  } catch (error) {
    console.log('Error in identity add', error);
    throw error;
  }
}

//Delete an existing identity that MCNM oversees
export async function deleteIdentity(identity_id) {
  try {
    const response = await axiosInstance({
      method: 'DELETE',
      url: `${MCNM_AUTHORIZATION_API_URL}/identity/${identity_id}`,
    });
    return response;
  } catch (error) {
    console.log('error in DELETE identity', error);
    throw error;
  }
}

//Get one of the identities that MCNM oversees
export async function getIdentity(identity_id) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_AUTHORIZATION_API_URL}/identity/${identity_id}`,
    });
    return response.data;
  } catch (error) {
    console.log('error in GET identity', error);
    throw error;
  }
}

//Update an existing identity that MCNM oversees.
export async function updateIdentity(identity_id, data) {
  try {
    const response = await axiosInstance({
      method: 'PUT',
      url: `${MCNM_AUTHORIZATION_API_URL}/identity/${identity_id}`,
      data,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Get all of the permissions for this identity that MCNM oversees
export async function getIdentityPermisssions(identity_id) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_AUTHORIZATION_API_URL}/identity/${identity_id}/permission`,
    });
    return response.data?.permissions ?? [];
  } catch (error) {
    console.log('error in GET permissions for an identity', error);
    throw error;
  }
}

//Remove all roles from an existing identity that MCNM oversees
export async function deleteIdentityRoles(identity_id) {
  try {
    const response = await axiosInstance({
      method: 'DELETE',
      url: `${MCNM_AUTHORIZATION_API_URL}/identity/${identity_id}/role`,
    });
    return response;
  } catch (error) {
    console.log('error in DELETE identity role', error);
    throw error;
  }
}

//Get all of the roles for this identity that MCNM oversees
export async function getIdentityRoles(identity_id) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_AUTHORIZATION_API_URL}/identity/${identity_id}/role`,
    });
    return response.data?.roles ?? [];
  } catch (error) {
    console.log('error in GET roles for an identity', error);
    throw error;
  }
}

//Add roles to an existing identity that MCNM oversees. An error is returned if any input role resource IDs are not found. Resource IDs for roles that are already bound to the identity are ignored.
export async function addIdentityRoles(identity_id, data) {
  try {
    const response = await axiosInstance({
      method: 'POST',
      url: `${MCNM_AUTHORIZATION_API_URL}/identity/${identity_id}/role`,
      data,
    });
    return response.data;
  } catch (error) {
    console.log('Error in identity roles add', error);
    throw error;
  }
}

//Remove a role from an existing identity that MCNM oversees
export async function deleteIdentityRole(identity_id, role_id) {
  try {
    const response = await axiosInstance({
      method: 'DELETE',
      url: `${MCNM_AUTHORIZATION_API_URL}/identity/${identity_id}/role/${role_id}`,
    });
    return response;
  } catch (error) {
    console.log('error in DELETE a role from an identity', error);
    throw error;
  }
}

//Add a defined role to an existing identity that MCNM oversees.
export async function addIdentityRole(identity_id, role_id, data = {}) {
  try {
    const response = await axiosInstance({
      method: 'POST',
      url: `${MCNM_AUTHORIZATION_API_URL}/identity/${identity_id}/role/${role_id}`,
      data,
    });
    return response.data;
  } catch (error) {
    console.log('Error in adding a role to an identity', error);
    throw error;
  }
}
