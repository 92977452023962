import { MCNM_TENANT_API_URL } from './config';
import { axiosInstance } from './api';

export async function getSecrets() {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_TENANT_API_URL}/secrets`,
    });
    if (response.data.secrets === null) {
      return {
        secrets: [],
      };
    }
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function addSecret(data) {
  try {
    const response = await axiosInstance({
      method: 'post',
      url: `${MCNM_TENANT_API_URL}/secrets`,
      data,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function updateSecret(data, secret_name) {
  try {
    const response = await axiosInstance({
      method: 'patch',
      url: `${MCNM_TENANT_API_URL}/secrets/${secret_name}`,
      data,
    });
    console.log(response.data.secrets);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

// delete an existing application that MCNM oversees
export async function deleteSecret(secret_id) {
  try {
    const response = await axiosInstance({
      method: 'DELETE',
      url: `${MCNM_TENANT_API_URL}/secrets/${secret_id}`,
    });
    console.log(response.data);
  } catch (error) {
    throw error;
  }
}

export async function getSecretManagerConfiguration() {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_TENANT_API_URL}/secrets_manager_configuration`,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
