import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { AxiosError } from 'axios';
import { Renew16 } from '@carbon/icons-react';
import {
  SkeletonPlaceholder,
  Tabs,
  Tab,
  Dropdown,
  OnChangeData,
  TooltipDefinition,
} from 'carbon-components-react';
import CustomSidePanel from '../../../CustomSidePanel/CustomSidePanel';
import SidePanelDetailsComponent from '../../../CustomSidePanel/SidePanelDetailsComponent/SidePanelDetailsComponent';
import { EdgeSidePaneDetailsComponentProps } from '../config';
import { ReactComponent as ChartAverageSvg } from '../../icons/chartAverage.svg';
import MetricsDetails, {
  ChartType,
} from '../../../../components/CustomSidePanel/MetricComponent/MetricComponent';
import dateUtil from '../../../../lib/dates';
import { getMetrics } from '../../../../controllers/metricsApis';
import images from '../../../../images/images';
import SidePanelEmptyState from '../../../CustomSidePanel/SidePanelEmptyState/SidePanelEmptyState';
import {
  AppMetric,
  Data,
  DEFAULT_HISTORY,
  DEFAULT_RESOLUTION,
  getDefaultHistoryResolution,
  getHistoryDropDown,
  getMetricsQuery,
  getResolutionDropDown,
  getTotalMetricsQuery,
  getTypeDropDown,
  Group,
  History,
  Resolution,
  typeToGroupMap,
  typeToAxisText,
  FLOWS_UNIT,
  LATENCY_UNIT,
} from './config';

import './ApplicationServiceConnection.scss';
import { convertMetricsValueInUnits } from '../../../../lib/utils';
import { NetworkSegmentCompatibilitySet } from '../../../../lib/enums';
import { METRICS_TIMESTAMP_FORMAT } from '../../../../lib/constants';

const ApplicationServiceConnection: React.FC<
  EdgeSidePaneDetailsComponentProps
> = ({
  source,
  target,
  open,
  onClose,
  sidePanelWidth,
  handleSidePanelWidth,
  handleHeaderWidth,
  initialHeaderWidth,
  headerWidth,
  networkSegments,
}) => {
  const { t, i18n } = useTranslation(
    'topologySidepanelApplicationServiceConnection'
  );
  moment.locale(i18n.language);

  const [totalLoading, setTotalLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState<Data[]>([]);
  const [hasFlowData, setHasFlowData] = useState<Data[]>([]);
  const [totalBytes, setTotalBytes] = useState('');
  const [totalPacketsCurrent, setTotalPacketsCurrent] = useState('');
  const [totalPackets24hour, setTotalPackets24hour] = useState('');
  const [totalFlows, setTotalFlows] = useState('');
  const [avgLatency, setAvgLatency] = useState('');
  const [selectedType, setSelectedType] = useState<{
    id: Group;
    text: string;
    xAxisText: string;
  }>({
    id: 'flp_connectionBytes',
    text: t('totalBytes'),
    xAxisText: t('kilobytesTransfered'),
  });
  const [selectedHistory, setSelectedHistory] =
    useState<History>(DEFAULT_HISTORY);
  const [selectedResolution, setSelectedResolution] =
    useState<Resolution>(DEFAULT_RESOLUTION);
  const [chartType, setChartType] = useState<ChartType>('multiLine');
  const [notAuthorized, setNotAuthorized] = useState(false);
  const historyDropDown = getHistoryDropDown(t);
  const resolutionDropDown = getResolutionDropDown(t, selectedHistory);

  const appName =
    source?.entityType === 'deployment' ? source.name : target.name;

  const appId =
    source?.entityType === 'deployment'
      ? source?.application_id
      : target?.application_id;

  const serviceName =
    source?.entityType === 'servicedeployment' ? source?.name : target?.name;
  const serviceId =
    source?.entityType === 'servicedeployment'
      ? source?.service_uniqueId
      : target?.service_uniqueId;

  const appNamespace =
    source?.entityType === 'deployment'
      ? source?.partition_id
      : target?.partition_id;

  const svcNamespace =
    source?.entityType === 'servicedeployment'
      ? source?.partition_id
      : target?.partition_id;

  const networkSegmentId =
    source?.entityType === 'deployment'
      ? source?.network_segment_id
      : target?.network_segment_id;

  const compatibilitySet = networkSegments?.find(
    (e: any) => e?.resource_id === networkSegmentId
  )?.compatibility_set;

  const typeDropDown = getTypeDropDown(t, compatibilitySet ?? '');

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    try {
      setTotalLoading(true);
      setDataLoading(true);
      await fetchTotalMetrics();
      await fetchMetrics(selectedType?.id, selectedHistory, selectedResolution);
    } catch (error) {
      const err = error as AxiosError;
      if (
        err?.response?.status === 403 &&
        err?.response?.statusText === 'Forbidden'
      ) {
        setNotAuthorized(true);
      }
    } finally {
      setTotalLoading(false);
      setDataLoading(false);
    }
  };

  const fetchTotalMetrics = async () => {
    try {
      const start = moment().subtract(1, 'd').toISOString();
      const end = moment().toISOString();
      if (compatibilitySet === NetworkSegmentCompatibilitySet.Axon) {
        const totalBytes =
          appId && serviceId && appNamespace && svcNamespace
            ? getTotalMetricsQuery(
                { appId, serviceId, appNamespace, svcNamespace },
                typeToGroupMap.totalBytes as Group
              )
            : '';
        const packetsQuery24hour = appId
          ? getTotalMetricsQuery(
              { appId },
              typeToGroupMap.totalPackets as Group,
              start
            )
          : '';
        const packetsQueryCurrent = appId
          ? getTotalMetricsQuery(
              { appId },
              typeToGroupMap.totalPackets as Group,
              end
            )
          : '';

        const results = await Promise.allSettled([
          getMetrics(totalBytes, false),
          getMetrics(packetsQuery24hour, false),
          getMetrics(packetsQueryCurrent, false),
        ]);
        if (Array.isArray(results)) {
          for (let i = 0; i < results.length; i++) {
            if (results[i].status === 'fulfilled') {
              const response = (results as any)[i].value;

              if (Array.isArray(response.data?.result)) {
                const metrics = response.data.result.find(
                  (metric: AppMetric) => metric.metric.svc_id === serviceId
                );
                if (Array.isArray(metrics?.value)) {
                  switch (i) {
                    case 0:
                      setTotalBytes(metrics?.value[1]);
                      break;
                    case 1:
                      setTotalPackets24hour(metrics?.value[1]);
                      break;
                    case 2:
                      setTotalPacketsCurrent(metrics?.value[1]);
                      break;
                    default:
                      break;
                  }
                }
              }
            }
          }
        }
      } else if (
        compatibilitySet === NetworkSegmentCompatibilitySet.ServiceInterconnect
      ) {
        const totalBytesQuery =
          appId && serviceId && appNamespace && svcNamespace
            ? getTotalMetricsQuery(
                { appId, serviceId, appNamespace, svcNamespace },
                typeToGroupMap.totalBytes as Group
              )
            : '';
        const flowCountQuery =
          appId && serviceId && appNamespace && svcNamespace
            ? getTotalMetricsQuery(
                {
                  appId,
                  serviceId,
                  appNamespace,
                  svcNamespace,
                },
                typeToGroupMap.totalFlows as Group
              )
            : '';

        const avgLatencyAppSvcQuery =
          appId && appNamespace && serviceId && svcNamespace
            ? getTotalMetricsQuery(
                {
                  appId,
                  serviceId,
                  appNamespace,
                  svcNamespace,
                },
                typeToGroupMap.avgLatency as Group
              )
            : '';
        const results = await Promise.allSettled([
          getMetrics(avgLatencyAppSvcQuery, false),
          getMetrics(totalBytesQuery, false),
          getMetrics(flowCountQuery, false),
        ]);

        if (Array.isArray(results)) {
          for (let i = 0; i < results.length; i++) {
            if (results[i].status === 'fulfilled') {
              const response = (results as any)[i].value;

              if (Array.isArray(response.data?.result)) {
                const metrics = response.data.result.find(
                  (metric: AppMetric) => metric.metric.svc_id === serviceId
                );

                if (Array.isArray(metrics?.value)) {
                  switch (i) {
                    case 0:
                      setAvgLatency(metrics?.value[1]);
                      break;
                    case 1:
                      setTotalBytes(metrics?.value[1]);
                      break;
                    case 2:
                      setTotalFlows(metrics?.value[1]);
                      break;
                    default:
                      break;
                  }
                }
              }
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getMetricsValue = (type: string, value: number) => {
    if (type === typeToGroupMap.avgLatency)
      return Number(value)
        ? convertMetricsValueInUnits(Number(value), 0)
        : null;
    else if (type === typeToGroupMap.totalFlows)
      return parseFloat(Number(value).toFixed(0));
    else return convertMetricsValueInUnits(Number(value));
  };

  const fetchMetrics = async (
    type: Group,
    history: History,
    resolution: Resolution
  ) => {
    try {
      setDataLoading(true);
      const query =
        appId && serviceId && appNamespace && svcNamespace
          ? getMetricsQuery(type, history, resolution, {
              appId,
              serviceId,
              appNamespace,
              svcNamespace,
            })
          : '';
      const response = await getMetrics(query);
      if (Array.isArray(response.data?.result)) {
        const metrics = response.data?.result?.find(
          (metric: AppMetric) => metric.metric.svc_id === serviceId
        );
        if (Array.isArray(metrics?.values)) {
          const transformedData = metrics?.values.map(
            (value: [number, string], index: number) => {
              const metricsValue = Number(value[1]) ? Number(value[1]) : null;
              return {
                id: index,
                group: t(type),
                key: moment.unix(value[0]).toDate(),
                value: metricsValue
                  ? getMetricsValue(type, metricsValue)
                  : metricsValue,
                displayTime: moment
                  .unix(value[0])
                  .format(METRICS_TIMESTAMP_FORMAT),
              };
            }
          );
          const hasDataFlow = transformedData.filter(
            (item: any) => item && item?.value !== null
          );
          setHasFlowData(hasDataFlow);
          return setData(transformedData);
        }
      }

      setData([]);
    } catch (error) {
      console.error(error);
    } finally {
      setDataLoading(false);
    }
  };

  const handleRefresh = () => {
    fetchMetrics(selectedType?.id, selectedHistory, selectedResolution);
  };

  const handleTypeChange = (data: {
    text: string;
    id: string;
    xAxisText: string;
  }) => {
    if (data?.id) {
      const group = (typeToGroupMap as any)[data?.id];

      const axisTextToShow = (typeToAxisText as any)[data?.id];
      setSelectedType({
        id: group,
        text: data?.text,
        xAxisText: axisTextToShow,
      });

      fetchMetrics(group, selectedHistory, selectedResolution);
    }
  };

  const handleHistoryChange = (
    e: OnChangeData<{
      text: string;
      id: string;
    }>
  ) => {
    if (e.selectedItem?.id) {
      const history = e.selectedItem.id as History;
      setSelectedHistory(history);

      const resolution = getDefaultHistoryResolution(history);
      setSelectedResolution(resolution);

      fetchMetrics(selectedType?.id, history, resolution);
    }
  };

  const handleResolutionChange = (
    e: OnChangeData<{
      text: string;
      id: string;
    }>
  ) => {
    if (e.selectedItem?.id) {
      const resolution = e.selectedItem.id as Resolution;
      setSelectedResolution(resolution);
      fetchMetrics(selectedType?.id, selectedHistory, resolution);
    }
  };

  const handleChartTypeChange = (value: ChartType) => {
    setChartType(value);
  };

  const renderTotalMetrics = () => {
    return (
      <div className='summary'>
        <div className='session'>
          <div className='header'>{t('metrics')}</div>
          <div className='header'>{t('total')}</div>
        </div>

        <div className='session'>
          <div className='value'>{t('kilobytes')}</div>
          <div className='value'>
            <ChartAverageSvg />
            {convertMetricsValueInUnits(Math.abs(Number(totalBytes)))}{' '}
          </div>
        </div>
        {compatibilitySet === NetworkSegmentCompatibilitySet.Axon && (
          <div className='session'>
            <div className='value'>{t('kilopackets')}</div>
            <div className='value'>
              <ChartAverageSvg />
              {convertMetricsValueInUnits(
                Math.abs(
                  Number(totalPacketsCurrent) - Number(totalPackets24hour)
                )
              )}{' '}
            </div>
          </div>
        )}
      </div>
    );
  };

  const getHeaderTitle = () => {
    if (source && target) {
      const srcLink = `/ApplicationDetails?appId=${source.application_id}`;
      const targetLink = `/ApplicationDetails?appId=${target.application_id}`;
      const AppServiceIcon = images.AppServiceIcon;

      return (
        <div className='header-title'>
          <div className='header-icon'>
            <AppServiceIcon />
          </div>
          <Link
            className='no-underline-link'
            to={''}
            onClick={() =>
              window.open(
                window.location.origin + process.env.PUBLIC_URL + srcLink
              )
            }
          >
            <TooltipDefinition
              tooltipText={appName}
              direction='bottom'
              className='generic-truncate-string'
              align='center'
            >
              <span className='truncate-last text-overflow-ellipsis'>
                {appName}
              </span>
            </TooltipDefinition>
          </Link>

          <span>{'  :  '}</span>

          <Link
            className='no-underline-link'
            to={''}
            onClick={() =>
              window.open(
                window.location.origin + process.env.PUBLIC_URL + targetLink
              )
            }
          >
            <TooltipDefinition
              tooltipText={serviceName}
              direction='bottom'
              className='generic-truncate-string'
              align='center'
            >
              <span className=' truncate-last text-overflow-ellipsis'>
                {serviceName}
              </span>
            </TooltipDefinition>
          </Link>
        </div>
      );
    }

    return '';
  };

  const title = getHeaderTitle();

  return (
    <div className='topology-application-service-connection-container'>
      <CustomSidePanel
        open={open}
        onClose={onClose}
        customTitle={title}
        sidePanelWidth={sidePanelWidth}
        handleSidePanelWidth={handleSidePanelWidth}
        handleHeaderWidth={handleHeaderWidth}
        initialHeaderWidth={initialHeaderWidth}
        headerWidth={headerWidth}
      >
        <Tabs>
          <Tab id='topology-details-tab-1' label={t('metrics')}>
            <SidePanelDetailsComponent title={t('appServiceTrafic')}>
              {totalLoading && (
                <div className='skeleton-container'>
                  <SkeletonPlaceholder className='app-details-skeleton' />
                </div>
              )}
              {!totalLoading && !notAuthorized ? (
                <div className='details-container'>
                  <div>
                    <div className='label'>{t('summary')}</div>
                    <div className='value'>
                      {moment().format('hh:mm a')}{' '}
                      {dateUtil.getCurrentTimeZone()} ({t('24Hrs')})
                    </div>
                    {compatibilitySet ===
                      NetworkSegmentCompatibilitySet.ServiceInterconnect && (
                      <>
                        <div className='label'>{t('flows')}</div>

                        <div className='value'>
                          {parseFloat(Number(totalFlows).toFixed(0))}{' '}
                          {FLOWS_UNIT}
                        </div>
                        <div className='label'>{t('avgLatency')}</div>
                        <div className='value'>
                          {convertMetricsValueInUnits(
                            Math.abs(Number(avgLatency)),
                            0
                          )}{' '}
                          {LATENCY_UNIT}
                        </div>
                      </>
                    )}
                  </div>
                  {renderTotalMetrics()}
                </div>
              ) : (
                <SidePanelEmptyState
                  icon={<></>}
                  header=''
                  message=''
                  notAuthorized={notAuthorized}
                />
              )}
            </SidePanelDetailsComponent>
            <SidePanelDetailsComponent title={t('intervalTraffic')}>
              {dataLoading && (
                <div className='skeleton-container'>
                  <SkeletonPlaceholder className='app-details-skeleton' />
                  <SkeletonPlaceholder className='app-details-skeleton' />
                  <SkeletonPlaceholder className='app-details-skeleton' />
                  <SkeletonPlaceholder className='app-details-skeleton' />
                </div>
              )}
              {!dataLoading && !notAuthorized ? (
                <div>
                  <MetricsDetails
                    hasFlow={hasFlowData && hasFlowData?.length > 0}
                    metricContainerId='metricContainerId'
                    metricTypeDropdownId='metricTypeDropdownId'
                    chartType={chartType}
                    onChartTypeChange={handleChartTypeChange}
                    data={data}
                    typeDropDownOptions={typeDropDown}
                    selectedType={{
                      text: selectedType?.text,
                      id: selectedType?.id,
                      xAxisText: selectedType?.xAxisText,
                    }}
                    typeDropdownTitile={t('dataType')}
                    chartComponentId='chartComponentId'
                    handleMetricTypeChange={handleTypeChange}
                    chartAxesMapping={{
                      multiLine: {
                        left: {
                          title: t(selectedType?.xAxisText),

                          mapsTo: 'value',
                        },
                        bottom: {
                          title: t('time'),
                          scaleType: 'time',
                          mapsTo: 'key',
                        },
                      },
                      groupedBar: {
                        left: {
                          title: t(selectedType?.xAxisText),
                          mapsTo: 'value',
                        },
                        bottom: {
                          title: t('time'),
                          scaleType: 'time',
                          mapsTo: 'key',
                        },
                      },
                    }}
                    bottomChildren={
                      <div className='dropdown-container'>
                        <div className='left'>
                          <Dropdown
                            id='history'
                            titleText={t('history')}
                            label={t('history')}
                            selectedItem={{
                              text: t(selectedHistory),
                              id: selectedHistory,
                            }}
                            items={historyDropDown}
                            itemToString={item =>
                              item ? item?.text ?? '' : ''
                            }
                            onChange={handleHistoryChange}
                          />
                        </div>
                        <div className='right'>
                          <Dropdown
                            id='resolution'
                            titleText={t('resolution')}
                            label={t('resolution')}
                            selectedItem={{
                              text: t(selectedResolution),
                              id: selectedResolution,
                            }}
                            items={resolutionDropDown}
                            itemToString={item =>
                              item ? item?.text ?? '' : ''
                            }
                            onChange={handleResolutionChange}
                          />
                        </div>
                        <div className='refresh'>
                          <span className='icon' onClick={handleRefresh}>
                            <Renew16 />
                          </span>
                        </div>
                      </div>
                    }
                  ></MetricsDetails>
                </div>
              ) : (
                <SidePanelEmptyState
                  icon={<></>}
                  header=''
                  message=''
                  notAuthorized={notAuthorized}
                />
              )}
            </SidePanelDetailsComponent>
          </Tab>
        </Tabs>
      </CustomSidePanel>
    </div>
  );
};

export default ApplicationServiceConnection;
