import GenericTableWithFilters from '../../GenericTableWithFilters/GenericTableWithFilters';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import {
  DeploymentEnvDetailsNameSpaceAndSecurityTableData,
  AppliedFilter,
  NetworkSegments,
} from '../../../models/master';
import images from '../../../images/images.js';
import IconWithToolTip from '../../IconWithToolTip/IconWithToolTip';
import './NamespaceAndSecurityGroupTable.scss';
import {
  OnChangeData,
  OverflowMenu,
  OverflowMenuItem,
} from 'carbon-components-react';
import { Link } from 'react-router-dom';
import { getPartitions } from '../../../controllers/deploymentEnv.js';
import dateUtils from '../../../lib/dates.js';
import sortData from '../../../lib/tableSort';
import { Filter } from '../../FindAndFilterBar/FindAndFilterBar';
import GenericTruncateString from '../../GenericTruncateString/GenericTruncateString';
import { VerticalEmptyState } from '../../EmptyState/EmptyState';
import {
  DEFAULT_NETWORK_SEGMENT_ID,
  defaultNetworkSegmentObject,
} from '../../../lib/constants';

interface TableRows {
  id: any;
  name: string;
  network_segment_name?: string;
  name__format?: JSX.Element;
  network_segment_format?: JSX.Element;
  auto_discovery: string;
  managed: string;
  last_updated: string;
  actionColumn?: JSX.Element;
}

interface tableProps {
  managed: boolean;
  type: string;
  networkSegmentList: NetworkSegments[] | null;
  depId: string | null;
  openRegisterPartitionModel: () => void;
}

const NamespaceAndSecurityGroupTable = (props: tableProps) => {
  const { t } = useTranslation('deploymentEnvDetails');
  const {
    managed,
    type,
    depId,
    networkSegmentList,
    openRegisterPartitionModel,
  } = props;

  const namespaceSecurityGroupHeaders = () => {
    const formattedData = [
      {
        key: 'name__format',
        originalKey: 'name',
        sort: 'sortByName',
        header: t('tableHeaders.name'),
      },
      {
        key: 'auto_discovery',
        originalKey: 'auto_discovery',
        sort: 'sortByAuto_discovery',
        header: t('tableHeaders.autoDiscovery'),
      },
      {
        key: 'managed',
        originalKey: 'managed',
        sort: 'sortByManaged',
        header: t('tableHeaders.managed'),
      },
      {
        key: 'last_updated',
        originalKey: 'updated_at',
        sort: 'sortByLast_updated',
        header: t('tableHeaders.lastUpdated'),
        style: { minWidth: '12.5rem' },
      },
    ];

    if (type === 'cluster') {
      formattedData.splice(1, 0, {
        key: 'network_segment_format',
        originalKey: 'network_segment_name',
        sort: 'sortByNetworkSegment',
        header: t('tableHeaders.networkSegment'),
      });
    }
    return formattedData;
  };

  const [currentPageNumber, setPageNumber] = useState(1);
  const [currentPageSize, setPageSize] = useState(10);
  const [tableData, setTableData] = useState<
    DeploymentEnvDetailsNameSpaceAndSecurityTableData[] | null
  >(null);
  const [filteredData, setFilteredData] = useState<
    DeploymentEnvDetailsNameSpaceAndSecurityTableData[] | []
  >([]);
  const [filterApplied, setFilterApplied] = useState<AppliedFilter[] | []>([]);
  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | 'NONE'>(
    'NONE'
  );
  const [visibilityFlag, setVisibilityFlag] = useState('managed');
  const [hasPartitionReadAccess, setHasPartitionReadAccess] =
    useState<boolean>(true);
  const handleTableSort = (
    data: { id: string; text: string },
    sortDirection: 'ASC' | 'DESC' | 'NONE'
  ) => {
    setSortDirection(sortDirection);
    setSortKey(data.id);
  };

  const getNetworkSegmentName = (resourceId: string) => {
    if (resourceId === DEFAULT_NETWORK_SEGMENT_ID) {
      return defaultNetworkSegmentObject.name;
    }
    return networkSegmentList && Array.isArray(networkSegmentList)
      ? networkSegmentList?.find(
          NetworkSegment => NetworkSegment.resource_id === resourceId
        )?.name ?? '—'
      : '—';
  };

  const refreshData = () => {
    setTableData(null);
    setSortKey('');
    setSortDirection('NONE');
    getPartitions(depId, visibilityFlag)
      .then((response: any) => {
        // As Api in not returning complete data pushing some dummy data
        const data: any = [];
        response.partitions?.forEach((item: any) => {
          item.managed = item.unmanaged ? t('no') : t('yes');
          item.resource_group = 'Deployment';
          if (item?.auto_discover) {
            item.auto_discovery = t('toggleOn');
          } else {
            item.auto_discovery = t('toggleOff');
          }
          item.resource_name = item.name;
          item.network_segment_name = getNetworkSegmentName(
            item?.network_segment_id
          );
          data.push(item);
        });
        setTableData(
          data as DeploymentEnvDetailsNameSpaceAndSecurityTableData[]
        );
      })
      .catch(error => {
        console.error(error);
        if (error.response?.status === 403) setHasPartitionReadAccess(false);
        setTableData([]);
      });
  };

  useEffect(() => {
    refreshData();
  }, []);

  const setRowsData = (
    data: DeploymentEnvDetailsNameSpaceAndSecurityTableData[]
  ) => {
    let formattedRows: TableRows[] = [];
    if (data && data.length === 0) return [];
    if (data)
      data.map((row: DeploymentEnvDetailsNameSpaceAndSecurityTableData) => {
        formattedRows.push({
          id: row.resource_id,
          name: row.name,
          name__format: (
            <div className='name-column-value'>
              <Link
                className='no-underline-link'
                to={`/partitionDetails?depEnvId=${depId}&partitionId=${row.resource_id}`}
              >
                <GenericTruncateString str={row.name} tableView={true} />
                {row?.is_discovered && (
                  <div className='icon'>
                    <IconWithToolTip
                      icon={images.AutoDiscoverdLockIconSmall()}
                      iconDescription={t('autoDiscoveredAccessLimited')}
                    />
                  </div>
                )}
              </Link>
            </div>
          ),
          network_segment_name: !!row.network_segment_name
            ? row.network_segment_name
            : '—',
          network_segment_format:
            row.network_segment_id === DEFAULT_NETWORK_SEGMENT_ID ? (
              <GenericTruncateString
                str={row?.network_segment_name as string}
                tableView={true}
              />
            ) : (
              <Link
                className='no-underline-link'
                to={`/networkSegmentDetails?nwSegId=${row.network_segment_id}`}
              >
                <GenericTruncateString
                  str={row?.network_segment_name as string}
                  tableView={true}
                />
              </Link>
            ),
          auto_discovery: row.auto_discovery,
          managed: row.managed,
          last_updated: dateUtils.getUserFriendlyDate(row.updated_at),
        });
        return 0;
      });
    else return null;
    return formattedRows;
  };

  const setPageChange = (pageData: { page: number; pageSize: number }) => {
    setPageNumber(pageData.page);
    setPageSize(pageData.pageSize);
  };

  const actionButtonShow = () => {
    if (managed) {
      if (type === 'cluster') {
        if (
          tableData &&
          tableData.length > 0 &&
          tableData[0].network_segment_id === DEFAULT_NETWORK_SEGMENT_ID
        )
          return;

        return {
          text: t('addNamespaces'),
          actionButtonCallback: openRegisterPartitionModel,
        };
      } else if (type === 'vpc') {
        return {
          text: t('addSecurityGroup'),
          actionButtonCallback: openRegisterPartitionModel,
        };
      }
    } else {
      return undefined;
    }
  };

  const getFilters = () => {
    let filters = [
      {
        key: 'resource_name',
        label: t('tableHeaders.name'),
        type: 'multi',
        placeHolderText: t('filters.namePlaceholder'),
        values: [
          ...Array.from(
            new Set(
              (tableData ? tableData : [])
                .map(namespace => namespace?.resource_name)
                .flat()
            )
          ),
        ],
      },
      {
        key: 'auto_discovery',
        label: t('tableHeaders.autoDiscovery'),
        type: 'multi',
        placeHolderText: t('filters.autodiscoveryPlaceholder'),
        values: [t('toggleOn'), t('toggleOff')],
      },
    ];
    if (type === 'cluster') {
      filters.push({
        key: 'network_segment_name',
        label: t('namespaceNetworkSegment'),
        type: 'multi',
        placeHolderText: t('filters.segmentPlaceholder'),
        values: [
          ...Array.from(
            new Set(
              (tableData ? tableData : [])
                .map(namespace => namespace?.network_segment_name)
                .flat()
            )
          ),
        ],
      });
    }

    return filters;
  };

  const leftInlineFilters: Filter[] = [
    {
      key: 'view',
      label: t('view'),
      type: 'single',
      values: [
        {
          value: 'all',
          label: t('all'),
        },
        {
          value: 'managed',
          label: t('managed'),
        },
        {
          value: 'unmanaged',
          label: t('unmanaged'),
        },
      ],
      filterCallback: (e: OnChangeData<any>) => {
        if (e.selectedItem) {
          setVisibilityFlag(e.selectedItem?.value);
          applyVisibilityFlag(e.selectedItem?.value);
        }
      },
    },
  ];

  const applyVisibilityFlag = (flag: string) => {
    getPartitions(depId, flag)
      .then((response: any) => {
        // As Api in not returning complete data pushing some dummy data
        const data: any = [];
        response.partitions?.forEach((item: any) => {
          item.managed = item.unmanaged ? t('no') : t('yes');
          item.resource_group = 'Deployment';
          if (item?.auto_discover) {
            item.auto_discovery = t('toggleOn');
          } else {
            item.auto_discovery = t('toggleOff');
          }
          item.resource_name = item.name;
          item.network_segment_name = getNetworkSegmentName(
            item?.network_segment_id
          );
          data.push(item);
        });
        setTableData(
          data as DeploymentEnvDetailsNameSpaceAndSecurityTableData[]
        );
      })
      .catch(error => console.error(error));
  };

  const actionButtonCallback = () => {};
  const titleText = () => {
    if (type === 'cluster') {
      return t('namespaceTableHeader', {
        count: tableData?.length ? tableData?.length : 0,
      });
    } else {
      return t('securityGroupTableHeader', {
        count: tableData?.length ? tableData?.length : 0,
      });
    }
  };
  const descriptionText = () => {
    if (type === 'cluster') {
      return t('namespaceTableDescription', { type: type });
    } else {
      return t('securityGroupDescription', { type: type });
    }
  };

  const getEmptyState = () => {
    if (type === 'cluster') {
      return {
        icon: images.namespaceEmptyLarge(),
        notFoundIcon: images.NotFoundLarge(),
        emptyStateHeader: t('emptyState.namespace.emptyContainerHeader'),
        emptyStateDescription: t(
          'emptyState.namespace.emptyContainerDescription'
        ),
        link: false, // need to be made true on implementing add security group functionality
        buttonText: t('emptyState.namespace.buttonText'),
        click: () => {},
      };
    } else {
      return {
        icon: images.namespaceEmptyLarge(),
        notFoundIcon: images.NotFoundLarge(),
        emptyStateHeader: t('emptyState.securityGroup.emptyContainerHeader'),
        emptyStateDescription: t(
          'emptyState.securityGroup.emptyContainerDescription'
        ),
        link: false, // need to be made true on implementing add namespace functionality
        buttonText: t('emptyState.securityGroup.buttonText'),
        click: () => {},
      };
    }
  };

  return (
    <div
      className='namespace_security_generic_table'
      data-testid='namespace-and-security-group-table-container'
    >
      {hasPartitionReadAccess ? (
        <GenericTableWithFilters
          title={titleText()}
          description={descriptionText()}
          id='namespace-and-resource-group-table'
          rows={
            tableData
              ? filterApplied.length > 0
                ? setRowsData(sortData(filteredData, sortKey, sortDirection))
                : setRowsData(sortData(tableData, sortKey, sortDirection))
              : null
          }
          leftInlineFilters={leftInlineFilters}
          visibilityFlag={visibilityFlag}
          // isRowsExpandable={true}
          data={
            tableData
              ? filterApplied.length > 0
                ? filteredData
                : tableData
              : null
          }
          headers={namespaceSecurityGroupHeaders()}
          sortRows={(
            data: { id: string; text: string },
            direction: 'ASC' | 'DESC' | 'NONE'
          ) => handleTableSort(data, direction)}
          isSortable={true}
          totalElementsCount={
            tableData
              ? filterApplied.length > 0
                ? filteredData.length
                : tableData.length
              : 0
          }
          fullData={tableData}
          onTableRefresh={() => refreshData()}
          filters={
            tableData && tableData.length > 0 ? (getFilters() as any) : []
          }
          showRefresh={(tableData && tableData?.length > 0) || false}
          showSearch={(tableData && tableData?.length > 0) || false}
          currentPageNumber={currentPageNumber}
          currentPageSize={currentPageSize}
          onPageChange={(pageData: any) => setPageChange(pageData)}
          emptyState={getEmptyState()}
          selectedFiltersVal={filterApplied}
          setFilterApplied={data => {
            setFilterApplied(data);
          }}
          filteredDataCallback={(
            data: DeploymentEnvDetailsNameSpaceAndSecurityTableData[]
          ) => {
            data &&
              setFilteredData(
                data as DeploymentEnvDetailsNameSpaceAndSecurityTableData[] | []
              );
            setPageNumber(1);
          }}
          actionButton={actionButtonShow()}
          dataTestId='namespace-and-security-group-table'
        />
      ) : (
        <div className='emptyContainer'>
          <div className='emptyContainerCol'>
            <VerticalEmptyState
              additionalClassName='dataNoAccess'
              icon={images.noCloudAccessIcon()}
              header={t('notAuthorizedHeader')}
              description={t('notAuthorizedDescription')}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default NamespaceAndSecurityGroupTable;
