import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import GenericStatusField from '../../../components/GenericStatusField/GenericStatusField';
import GenericTableWithFilters from '../../../components/GenericTableWithFilters/GenericTableWithFilters';
import images from '../../../images/images.js';
import dateUtils from '../../../lib/dates';

import {
  Cloud,
  AppliedFilter,
  CloudData,
  ResourceGroup,
  CloudType,
} from '../../../models/master';

import { Loading } from 'carbon-components-react';
import GenericTruncateString from '../../../components/GenericTruncateString/GenericTruncateString';
import './CloudTable.scss';

interface TableRow {
  id: any;
  name: string;
  name__format: JSX.Element;
  is_private: boolean;
  type_Format: string;
  type: string;
  autodiscovery: boolean;
  autodiscovery__format: JSX.Element | null;
  infrastructureGroup: string | JSX.Element;
  autoDiscoveryRun: JSX.Element | null | string;
  lastUpdated: string;
}

const CloudTable = (props: {
  filteredDataCallback: (data: any) => void;
  filtersSelected: any;
  filtersAppliedCallback: (data: any) => void;
  filters: any;
  rows: CloudData[] | null;
  data: CloudData[] | null;
  filteredDataSet: CloudData[] | null;
  elementCount: number;
  onRefresh: () => void;
  currentPageNumber: number;
  currentPageSize: number;
  onPageChange: (pageData: any) => void;
  sortRows(arg0: unknown, direction: string): void;
  resourceGroupsData: ResourceGroup[] | null;
  persistFilter?: boolean;
  error403Flag: boolean;
  error500Flag: boolean;
  dataLoading: boolean;
  cloudTypes: CloudType[] | null;
}) => {
  const { t } = useTranslation('cloudsContainer');
  const navigate = useNavigate();
  const { rows, onRefresh } = props;
  const error403Flag = props.error403Flag;
  const error500Flag = props.error500Flag;
  const tableRef = useRef<HTMLDivElement>(null);

  const myheaders = [
    {
      key: 'name__format',
      originalKey: 'name',
      header: t('tableHeaders.name'),
      sort: true,
      style: { minWidth: '5.5rem' },
    },
    {
      key: 'type',
      originalKey: 'type',
      header: t('tableHeaders.provider'),
      sort: true,
    },
    {
      key: 'autodiscovery__format',
      originalKey: 'autodiscoverValue',
      header: t('tableHeaders.autodiscovery'),
      sort: true,
    },
    {
      key: 'type_Format',
      originalKey: 'cloud_type',
      header: t('tableHeaders.type'),
      sort: true,
    },
    {
      key: 'infrastructureGroup',
      originalKey: 'resource_name',
      header: t('tableHeaders.infrastructureGroup'),
      sort: true,
    },
    {
      key: 'autoDiscoveryRun',
      originalKey: 'last_discovery_completed_at',
      header: t('tableHeaders.autodiscoveryRun'),
      sort: true,
      style: { minWidth: '10.5rem' },
    },
    {
      key: 'lastUpdated',
      originalKey: 'updated_at',
      header: t('tableHeaders.lastUpdated'),
      sort: true,
      style: { minWidth: '10.5rem' },
    },
  ];
  const lastDiscoverdAt = (cloudData: Cloud | null) => {
    if (cloudData?.auto_discover) {
      const dateValues = cloudData?.last_discovery_completed_at?.split('-');
      if (dateValues && dateValues[0] === '0001') {
        return <p>&mdash;</p>;
      } else {
        return (
          <div className='auto-discover-label'>
            <div className='auto-discover-label-item '>
              {discoveryStatus(cloudData?.discovery_status)}

              <div className='value'>
                {dateUtils.getUserFriendlyDate(
                  cloudData?.last_discovery_completed_at
                )}
              </div>
            </div>
          </div>
        );
      }
    } else {
      return <p>&mdash;</p>;
    }
  };
  const discoveryStatus = (status: string | undefined) => {
    let icon;
    switch (status) {
      case 'completed':
        icon = images.autoDiscoverLabelIcon();
        break;
      case 'in-progress':
        icon = <Loading active small withOverlay={false} />;
        break;
      case 'pending':
        icon = images.pendingStatusIcon();
        break;
      case 'errored':
        icon = images.criticalStatusIcon();
        break;
      default:
        break;
    }
    return <div className={`${status}-icon`}>{icon}</div>;
  };

  const fetchDiscoveryStatus = (status: string | undefined) => {
    let value;
    switch (status) {
      case 'completed':
        value = t('completed');
        break;
      case 'in-progress':
        value = t('in-progress');
        break;
      case 'pending':
        value = t('pending');
        break;
      case 'errored':
        value = t('errored');
        break;
      default:
        value = '—';
        break;
    }
    return <div className='value'>{value}</div>;
  };

  const getProviderName = (type: string) => {
    const cloudProvider = props.cloudTypes?.find(
      (cloud: CloudType) => cloud.code === type
    );
    return cloudProvider?.name ?? '-';
  };

  /**
   * @description - Function to format the raw data into table rows format.
   * @returns {TableRow[] | [] | null} - Returns the formatted data to be rendered as table rows.
   */
  const setRowsData = () => {
    let formattedRows: TableRow[] = [];
    if (error403Flag) return [];
    if (error500Flag) return [];
    if (rows && rows.length === 0) return [];
    if (rows)
      rows.map((row: CloudData) => {
        formattedRows.push({
          id: row.resource_id,
          name: !!row.name ? row.name : '—',
          name__format: (
            <Link
              className='no-underline-link'
              to={`/cloudDetails?cloudId=${row.resource_id}`}
            >
              <div className='name'>
                {
                  <GenericTruncateString
                    tableView={true}
                    str={row.name}
                    maxLength={25}
                    limit={11}
                  />
                }
              </div>
            </Link>
          ),
          type: getProviderName(row.type),
          autodiscovery: row.auto_discover, //to be changed
          autodiscovery__format: (
            <div className='auto-discover-label'>
              <div className='auto-discover-label-item '>
                {row?.auto_discover && row?.discovery_status
                  ? discoveryStatus(row?.discovery_status) //row?.discovery_status
                  : null}

                {row?.auto_discover
                  ? fetchDiscoveryStatus(row?.discovery_status)
                  : t('off')}
              </div>
            </div>
          ),
          is_private: row.is_private,
          type_Format: !!row?.cloud_type ? row?.cloud_type : '—',
          infrastructureGroup: !row.resourceGroupsPermission ? (
            <GenericStatusField status='notAuthorised'></GenericStatusField>
          ) : row?.resource_name ? (
            row?.resource_name
          ) : (
            <span>&#8212;</span>
          ),
          autoDiscoveryRun: lastDiscoverdAt(row),
          lastUpdated: !!row.updated_at
            ? dateUtils.getUserFriendlyDate(row.updated_at)
            : '—',
        });
        return 0;
      });
    else return null;
    return formattedRows;
  };

  const emptyStateData = {
    icon: images.NoCloudLarge(),
    notFoundIcon: images.NotFoundLarge(),
    emptyStateHeader: t('emptyState.emptyContainerHeader'),
    emptyStateDescription: t('emptyState.emptyContainerDescription'),
    link: '/',
    buttonText: t('emptyState.buttonText'),
    click: () =>
      navigate('/clouds', {
        state: { resourceType: 'CLOUD' },
      }),
  };

  return (
    <div className='cloud-table' ref={tableRef}>
      <GenericTableWithFilters
        id='table-with-filter-panel'
        rows={setRowsData()}
        data={props.filteredDataSet}
        headers={myheaders}
        isSortable={true}
        totalElementsCount={props.elementCount ? props.elementCount : 0}
        fullData={props.data}
        onTableRefresh={() => onRefresh()}
        filteredDataCallback={(data: TableRow[]) =>
          props.filteredDataCallback(data)
        }
        selectedFiltersVal={props.filtersSelected as any}
        setFilterApplied={(data: AppliedFilter[]) =>
          props.filtersAppliedCallback(data)
        }
        filters={props.filters}
        currentPageNumber={props.currentPageNumber}
        currentPageSize={props.currentPageSize}
        onPageChange={(pageData: any) => props.onPageChange(pageData)}
        emptyState={emptyStateData}
        sortRows={(data, sortDirection) => props.sortRows(data, sortDirection)}
        persistFilter={props.persistFilter}
        render403Container={props.error403Flag}
        render500Container={props.error500Flag}
        tableDataLoading={props.dataLoading}
      />
    </div>
  );
};

export default CloudTable;
