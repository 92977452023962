import React, { useEffect, useState } from 'react';
import { Location, Position, Resouce } from '../types';
import { ComponentConfig, getComponentConfig } from './config';

import './TooltipContainer.scss';

interface Props {
  node: Location | Resouce;
  position: Position;
}

const TooltipContainer: React.FC<Props> = ({ node, position }) => {
  const [componentConfig, setComponentConfig] =
    useState<ComponentConfig | null>(null);

  useEffect(() => {
    if (node) {
      const config = getComponentConfig(node._type, node.label);

      if (config && config.component) {
        setComponentConfig(config as any);
      }
    }
  }, [node]);

  const getPosition = () => {
    const container = document.querySelector('.topology-container');
    let left = position.x + 75;
    let top = position.y + 30;

    if (container) {
      const { width, height } = container.getBoundingClientRect();

      // If tooltip has no space to render at right
      if (width - position.x < 200) {
        left = position.x - 150;
      }

      // If tooltip has no space to render at bottom
      if (height - position.y < 50) {
        top = position.y;
      }

      // If tooltip has no space to render at top
      if (position.y < 50) {
        top = position.y + 75;
      }
    }

    return {
      left: left + 'px',
      top: top + 'px',
    };
  };

  const Component = componentConfig ? componentConfig.component : null;

  return (
    <>
      {node && Component && (
        <div className='topology-tooltip-container' style={getPosition()}>
          <Component node={node} />
        </div>
      )}
    </>
  );
};

export default TooltipContainer;
