import { MCNM_METRICS_API_URL } from './config';
import { axiosInstance } from './api';

// Get metrics based on thanos query
export async function getMetrics(query = '', range = true) {
  const metric_url = range
    ? `${MCNM_METRICS_API_URL}/query_range?query=${query}`
    : `${MCNM_METRICS_API_URL}/query?query=${query}`;

  try {
    const response = await axiosInstance({
      method: 'GET',
      url: metric_url,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
