import { useQuery } from '@tanstack/react-query';
import { getResourceGroups } from '../controllers/resourceGroup';
import { QueryOptions, queryDefaultOptions } from './queryDefaults';
import { QueryKeys } from '../lib/enums';

export const useResourceGroupsData = (options?: QueryOptions) => {
  return useQuery({
    queryKey: [QueryKeys.RESOURCEGROUPS],
    queryFn: getResourceGroups,
    ...queryDefaultOptions,
    ...options,
  });
};
