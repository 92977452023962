import react, { useState, useEffect } from 'react';
import { Partition } from '../../../../models/master';
import {
  RadioButton,
  SkeletonPlaceholder,
  Search,
  Button,
} from 'carbon-components-react';
import { getPartitions } from '../../../../controllers/deploymentEnv';
import './PartitionsTable.scss';
import images from '../../../../images/images.js';
import { useTranslation } from 'react-i18next';
import { Add16 } from '@carbon/icons-react';
import { DEFAULT_NETWORK_SEGMENT_ID } from '../../../../lib/constants';

interface Props {
  deployementEnv: {
    depEnvid: string;
    type: string;
    resourceGroupId: string;
    autoDiscover?: boolean;
    depEnvName: string;
  };
  setAppDeploymentData: any;
  appNetworkSegmentId: string;
}

const PartitionsTable: React.FC<Props> = ({
  deployementEnv,
  setAppDeploymentData,
  appNetworkSegmentId,
}) => {
  const [partitions, setPartition] = useState<Partition[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const { t } = useTranslation('addApplicationDeploymentDetails');
  const [partitionId, setSelectedPartitionId] = useState(null);

  const fetchData = async () => {
    try {
      const partitionData = await getPartitions(
        deployementEnv.depEnvid,
        'managed'
      );
      if (partitionData && Array.isArray(partitionData?.partitions)) {
        setPartition(partitionData?.partitions);
      }
    } catch (error) {
      //   console.error(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchData();
    setSelectedPartitionId(null);
  }, [deployementEnv]);

  useEffect(() => {
    Array.isArray(partitions) ? setLoading(false) : setLoading(true);
  }, [partitions]);

  const handleRadiobtnClick = (evt: any) => {
    let partitionData = JSON.parse(evt.target.value);
    setSelectedPartitionId(partitionData.partitionId);
    setAppDeploymentData(
      deployementEnv.type,
      partitionData.autoDiscover,
      deployementEnv.depEnvid,
      deployementEnv.resourceGroupId,
      deployementEnv.depEnvName,
      partitionData.partitionId
    );
  };

  const getFilteredPartitions = () => {
    const isDefaultNetworkSegment =
      appNetworkSegmentId === DEFAULT_NETWORK_SEGMENT_ID ||
      appNetworkSegmentId === '';

    const filteredPartitions = partitions?.filter(partition => {
      if (partition.type === 'namespace') {
        return (
          partition?.network_segment_id === appNetworkSegmentId ||
          (partition?.network_segment_id === '' && isDefaultNetworkSegment)
        );
      }
      return true;
    });

    if (!searchText) {
      return filteredPartitions;
    }

    return filteredPartitions?.filter(partition =>
      partition?.name?.toLowerCase().includes(searchText?.toLowerCase())
    );
  };

  const getEmptyState = () => {
    let emptyStateData;
    if (deployementEnv.type === 'cluster') {
      emptyStateData = {
        icon: images.NamespaceEmpty(),
        notFoundIcon: images.NotFoundSmall(),
        emptyStateHeader: t('emptyState.namespace.emptyContainerHeader'),
        emptyStateDescription: t(
          'emptyState.namespace.emptyContainerDescription'
        ),
        link: false, // need to be made true on implementing add security group functionality
        buttonText: t('emptyState.namespace.buttonText'),
        click: () => {},
      };
    } else {
      emptyStateData = {
        icon: images.SecurityGroupEmpty(),
        notFoundIcon: images.NotFoundSmall(),
        emptyStateHeader: t('emptyState.securityGroup.emptyContainerHeader'),
        emptyStateDescription: t(
          'emptyState.securityGroup.emptyContainerDescription'
        ),
        link: false, // need to be made true on implementing add namespace functionality
        buttonText: t('emptyState.securityGroup.buttonText'),
        click: () => {},
      };
    }
    return (
      <div className='empty-state'>
        <div className='empty-state-icon'>
          {Array.isArray(emptyStateData.icon)
            ? emptyStateData.icon.map(eachIcon => eachIcon)
            : emptyStateData.icon}
        </div>
        <div className='header'>{emptyStateData.emptyStateHeader}</div>
        <div className='description'>
          {emptyStateData.emptyStateDescription}
        </div>
        {emptyStateData.link ? (
          <Button
            className='action-button'
            size='small'
            kind='tertiary'
            renderIcon={Add16}
            onClick={emptyStateData.click}
          >
            {emptyStateData.buttonText}
          </Button>
        ) : null}
      </div>
    );
  };

  return (
    <>
      {loading ? (
        <div className='data-loader'>
          <div className='skeleton-row'>
            <SkeletonPlaceholder className='app-deployment-skeleton' />
            <SkeletonPlaceholder className='app-deployment-skeleton' />
          </div>
          <div className='skeleton-row'>
            <SkeletonPlaceholder className='app-deployment-skeleton' />
            <SkeletonPlaceholder className='app-deployment-skeleton' />
          </div>
          <div className='skeleton-row'>
            <SkeletonPlaceholder className='app-deployment-skeleton' />
            <SkeletonPlaceholder className='app-deployment-skeleton' />
          </div>
        </div>
      ) : (
        <div className='partition-table'>
          <Search
            size='lg'
            labelText={'search'}
            id='partition-table-search'
            className='tableSearch'
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
          />
          {deployementEnv.type === 'vpc' && (
            <div className='partition-option' key='deploy-to-vpc'>
              <RadioButton
                value={JSON.stringify({
                  autoDiscover: deployementEnv.autoDiscover,
                  partitionId: 'deploy-to-vpc',
                })}
                onClick={e => handleRadiobtnClick(e)}
                checked={partitionId === 'deploy-to-vpc'}
              />
              <div className='partition-option-fields'>
                <div className='partition-name'>
                  {t('partitionTable.vpcDirectDeploymentLabel')}
                </div>
              </div>
            </div>
          )}

          {getFilteredPartitions()?.map((partition: Partition) => {
            let partitionData = {
              autoDiscover: partition.auto_discover,
              partitionId: partition.resource_id,
            };
            let checked = partition.resource_id === partitionId;
            return (
              <div className='partition-option' key={partition.resource_id}>
                <RadioButton
                  value={JSON.stringify(partitionData)}
                  onClick={e => handleRadiobtnClick(e)}
                  checked={checked}
                />
                <div className='partition-option-fields'>
                  <div className='partition-name'>{partition.name}</div>{' '}
                  {deployementEnv.type === 'cluster' && (
                    <div className='partition-label'>{`${t(
                      'partitionTable.clusterOptionLabel'
                    )}${' '}(${
                      partition.auto_discover
                        ? t('partitionTable.on')
                        : t('partitionTable.off')
                    })`}</div>
                  )}
                  {deployementEnv.type === 'vpc' && (
                    <div className='partition-label'>
                      {t('partitionTable.vpcOptionLabel')}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
          {getFilteredPartitions()?.length === 0 && searchText.length > 0 && (
            <div className='empty-state'>
              <div className='empty-state-icon'>{images.NotFoundLarge()}</div>
              <div className='header'>{t('emptyState.header')}</div>
              <div className='description'>{t('emptyState.description')}</div>
            </div>
          )}
          {getFilteredPartitions()?.length === 0 &&
            !searchText &&
            getEmptyState()}
        </div>
      )}
    </>
  );
};

export default PartitionsTable;
