import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  ClickableTile,
  Row,
  Column,
  TooltipDefinition,
} from 'carbon-components-react';
import dateUtils from '../../../lib/dates';
import { Role } from '../../../models/master';
import LabelTag, { TagType } from '../../../components/LabelTag/LabelTag';
import GenericTruncateString from '../../../components/GenericTruncateString/GenericTruncateString';

import './IdentityCards.scss';

const IdentityCards = ({
  identityId,
  identityName,
  updated_at,
  roles,
  emailId,
  tags,
}: {
  identityId: string;
  identityName: string;
  tags: string[] | TagType[];
  roles: Role[];
  emailId: string;
  updated_at: any;
}) => {
  const { t } = useTranslation('identity');
  const navigate = useNavigate();

  const getRolesMappedValues = (
    from: number,
    to: number,
    isTooltip?: boolean
  ) => {
    return roles.slice(from, to).map((role: Role, index: any) => (
      <span className='names' key={role.resource_id}>
        <span>{role.name}</span>
        {index < roles.length - 1 && !isTooltip && (
          <span className='roles-spacing'>{', '}</span>
        )}
      </span>
    ));
  };

  const renderRolesName = () => {
    if (roles.length > 0) {
      return (
        <div className='roles-names'>
          {getRolesMappedValues(0, 2)}
          {roles.length > 2 ? (
            <TooltipDefinition
              tooltipText={getRolesMappedValues(2, roles.length, true)}
              direction='bottom'
              align='center'
            >
              {`+${roles.slice(2).length}`}
            </TooltipDefinition>
          ) : null}
        </div>
      );
    }
    return '—';
  };

  return (
    <ClickableTile
      className='identities-card'
      onClick={() =>
        identityId && navigate(`/IdentityDetails?identity_id=${identityId}`)
      }
    >
      <Row className='header'>
        <Column className='name-row'>
          <div className='name' title={identityName ?? ''}>
            {!!identityName ? (
              <GenericTruncateString
                str={identityName}
                maxLength={25}
                limit={11}
              />
            ) : (
              '_'
            )}
          </div>
        </Column>
      </Row>
      <Row className='data'>
        <Column>
          <div className='label-text'>{t('cardSubheading.emailid')}</div>
          <div className='name'>{!!emailId ? emailId : '—'}</div>
        </Column>
      </Row>

      <Row className='data'>
        <Column>
          <div className='label-text'>{t('cardSubheading.roles')}</div>
          <div className='name'>{renderRolesName() || <p>&mdash;</p>}</div>
        </Column>
      </Row>
      <Row className='tags'>
        <Column className='label-col'>
          {Array.isArray(tags) && tags.length > 0 ? (
            <LabelTag labelArray={tags} singleLine={true} />
          ) : (
            ''
          )}
        </Column>
      </Row>
      <Row className='card-footer'>
        <Column>
          <div className='helper-text'>
            {t('cardSubheading.updated', {
              date: !!updated_at
                ? dateUtils.getUserFriendlyDate(updated_at)
                : '—',
            })}
          </div>
        </Column>
      </Row>
    </ClickableTile>
  );
};

export default IdentityCards;
