import React, { useContext, useEffect, useState } from 'react';
import { Add16, TrashCan16 } from '@carbon/icons-react';
import {
  Button,
  Column,
  Row,
  TextInput,
  Link as CarbonLink,
} from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import { CardEmptyState } from '../../components/CardEmptyState/CardEmptyState';
import GenericTableWithFilters from '../../components/GenericTableWithFilters/GenericTableWithFilters';
import images from '../../images/images';
import dateUtils from '../../lib/dates';
import { ipRegexPattern } from '../../lib/regex';
import { DeploymentInstance } from '../../models/master';
import InlineNotification from '../../components/Notifications/Inline/Notification';
import { NotificationContext } from '../../components/Notifications/Context/NotificationProvider';
import { AxiosError } from 'axios';
import { addDeploymentInstance } from '../../controllers/applicationApis';

import './InstanceApplicationDepl.scss';
import { VerticalEmptyState } from '../../components/EmptyState/EmptyState';
import useAnalytics from '../../lib/useAnalytics';
import analyticsData from '../../lib/analyticsEventData';

const defaultFormValue = {
  ip_address: {
    value: '',
    error: false,
    errorMessage: '',
  },
};

const requiredFields = ['ip_address'];

const InstanceApplicationDepl = (props: {
  instanceData: DeploymentInstance[] | null;
  onRefresh: () => void;
  removeInstance: (instanceId: any) => void;
  hasFilter?: boolean;
  hasPagination?: boolean;
  filteredDataSet?: any;
  elementCount?: any;
  filteredDataCallback: (data: any) => void;
  filtersSelected?: any;
  filtersAppliedCallback: (data: any) => void;
  filters?: any;
  currentPageNumber?: number;
  currentPageSize?: number;
  onPageChange: (pageData: any) => void;
  sortRows(arg0: unknown, sortDirection: string): void;
  applicationId: string | null;
  deploymentId: string | null;
  onRegisterInstance: (instance: DeploymentInstance) => void;
  resourceGroupId: string | null;
}) => {
  const { t } = useTranslation('applicationDeploymentDetails');

  const notification = useContext(NotificationContext);

  const { onRefresh, removeInstance } = props;

  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState(defaultFormValue);
  const [loading, setLoading] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState<{
    errorType: string;
    display: boolean;
  }>({ errorType: 'default', display: false });

  const { trackButtonClicked } = useAnalytics();

  useEffect(() => {
    if (Array.isArray(props.instanceData) && props.instanceData.length) {
      setShowForm(true);
    } else setShowForm(false);
  }, [props.instanceData]);

  const instanceHeaders = [
    {
      key: 'local_ip',
      originalKey: 'local_ip',
      header: t('instances.localIp'),
    },
    {
      key: 'global_ip',
      originalKey: 'global_ip',
      header: t('instances.globalIp'),
    },
    {
      key: 'updated',
      originalKey: 'updated',
      header: t('instances.updated'),
    },
    {
      key: 'deleteInstance',
      originalKey: 'updated',
    },
  ];

  const setRowsData = () => {
    let formattedRows: {
      id: string;
      local_ip: any;
      global_ip: string;
      updated: any;
      deleteInstance: JSX.Element;
    }[] = [];
    if (props.instanceData && props.instanceData.length === 0) return [];
    if (props.instanceData)
      props.instanceData.map((row: DeploymentInstance) => {
        formattedRows.push({
          id: row.resource_id,
          local_ip: row.ip_address,
          global_ip: row.consumer_ip,
          updated: dateUtils.getUserFriendlyDate(row.updated_at),
          deleteInstance: (
            <div className='instance-delete-icon'>
              {' '}
              <div className='icon'>
                <CarbonLink
                  className='delete-link'
                  onClick={() => {
                    trackButtonClicked(
                      analyticsData['Application Deployment Details'].events
                        .deleteInstance.props,
                      analyticsData['Application Deployment Details'].events
                        .deleteInstance.event
                    );
                    removeInstance(row);
                  }}
                >
                  <TrashCan16 className='trash-can-svg' />
                </CarbonLink>
              </div>
            </div>
          ),
        });
        return 0;
      });
    else return null;
    return formattedRows;
  };

  const emptyStateData = {
    icon: images.noPoliciesIcon(),
    notFoundIcon: images.NotFoundLarge(),
    emptyStateHeader: t('instances.emptyState.emptyContainerHeader'),
    emptyStateDescription: t('instances.emptyState.emptyContainerDescription'),
    link: '/',
    buttonText: t('instances.emptyState.buttonText'),
  };

  const checkDuplicateIp = (ip: string) => {
    const trimmedIP = ip?.trim();
    const instance = props.instanceData?.find(
      instance => instance.ip_address === trimmedIP
    );

    return instance ? true : false;
  };

  const checkFieldValidation = (name: string, value: any) => {
    let errorMessage = '';
    switch (name) {
      case 'ip_address':
        const regexPattern = ipRegexPattern();
        errorMessage = !value
          ? t('instances.validation.ip_address.required')
          : !regexPattern.test(value)
          ? t('instances.validation.ip_address.invalidRegex')
          : checkDuplicateIp(value)
          ? t('instances.validation.ip_address.duplicate')
          : '';
        break;
    }
    return errorMessage;
  };

  const handleOnChange = (name: string, value: any) => {
    const errorMessage = checkFieldValidation(name, value);

    setFormData((prevState: any) => ({
      ...prevState,
      [name]: {
        value,
        error: !!errorMessage,
        errorMessage,
      },
    }));
  };

  const isFormValid = () => {
    for (const field of requiredFields) {
      const value = (formData as any)[field].value;
      const trimmedValue = typeof value === 'string' ? value.trim() : value;
      if (checkFieldValidation(field, trimmedValue)) {
        return false;
      }
    }

    return true;
  };

  const handleSubmit = async () => {
    try {
      trackButtonClicked(
        analyticsData['Application Deployment Details'].events.registerInstance
          .props,
        analyticsData['Application Deployment Details'].events.registerInstance
          .event
      );
      setLoading(true);
      const data = {
        ip_address: formData.ip_address?.value?.trim(),
        resource_group_id: props.resourceGroupId,
      };

      const instanceData = await addDeploymentInstance(
        props.applicationId,
        props.deploymentId,
        data
      );

      props.onRegisterInstance(instanceData as any);

      setFormData(defaultFormValue);

      // Trigger success toastbar
      notification.onTrigger('TOAST', {
        title: t('instances.success.title'),
        subtitle: t('instances.success.subtitle', { ip: data.ip_address }),
      });
    } catch (error: any) {
      console.error(error);
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        setShowErrorSnackbar({ errorType: '403', display: true });
      } else {
        setShowErrorSnackbar({ errorType: 'default', display: true });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCloseErrorBar = () => {
    setShowErrorSnackbar({ errorType: 'default', display: false });
  };

  return (
    <div className='appliction-deployment-instance-card'>
      <div className='header-container'>
        <div className='header'>{t('instances.header')}</div>
        {!showForm && (
          <Button
            kind='ghost'
            className='add-deployment-button'
            renderIcon={Add16}
            onClick={() => setShowForm(true)}
          >
            <span className='text'>{t('instances.emptyState.buttonText')}</span>
          </Button>
        )}
      </div>
      {showForm ? (
        <div className='local-ip-and-register'>
          <Row>
            <Column lg={10} md={8}>
              {showErrorSnackbar?.display && (
                <InlineNotification
                  onClose={() => handleCloseErrorBar() as any}
                  kind={'error'}
                  title={
                    showErrorSnackbar?.errorType === '403'
                      ? (t('instances.error.authTitle') as string)
                      : (t('instances.error.title') as string)
                  }
                  subtitle={
                    showErrorSnackbar?.errorType === '403'
                      ? (t('instances.error.authSubtitle') as string)
                      : (t('instances.error.subtitle') as string)
                  }
                />
              )}
            </Column>
          </Row>
          <Row className='section'>
            <Column lg={8} md={6}>
              <TextInput
                labelText={t('localip') as string}
                id='ip_address'
                name={t('localip')}
                placeholder={t('inputPlaceholder')}
                autoComplete='off'
                maxLength={45}
                value={formData?.ip_address?.value}
                onChange={e => handleOnChange('ip_address', e.target.value)}
                invalid={formData?.ip_address?.error}
                invalidText={formData?.ip_address?.errorMessage}
              />
            </Column>
            <Column lg={4} md={2}>
              <div className='btn-action'>
                <Button
                  className='registerLocalIp'
                  kind='primary'
                  size='md'
                  disabled={!isFormValid() || loading}
                  onClick={handleSubmit}
                >
                  {t('registerButton')} <Add16 />
                </Button>
              </div>
            </Column>
          </Row>
          {Array.isArray(props.instanceData) &&
            props.instanceData.length > 0 && (
              <Row>
                <Column lg={10}>
                  <div className='table-container'>
                    <GenericTableWithFilters
                      id='instance-application-depl-table'
                      rows={setRowsData()}
                      data={props.filteredDataSet}
                      headers={instanceHeaders}
                      isSortable={false}
                      totalElementsCount={
                        props.elementCount ? props.elementCount : 0
                      }
                      fullData={props.filteredDataSet}
                      onTableRefresh={() => onRefresh()}
                      filteredDataCallback={(data: any) =>
                        props.filteredDataCallback(data)
                      }
                      selectedFiltersVal={props.filteredDataSet as any}
                      setFilterApplied={(data: any) =>
                        props.filtersAppliedCallback(data)
                      }
                      filters={props.filteredDataSet}
                      currentPageNumber={props.currentPageNumber}
                      currentPageSize={props.currentPageSize}
                      onPageChange={(pageData: any) =>
                        props.onPageChange(pageData)
                      }
                      emptyState={emptyStateData}
                      sortRows={(data, sortDirection) =>
                        props.sortRows(data, sortDirection)
                      }
                      hasFilter={true}
                      hasPagination={false}
                    />
                  </div>
                </Column>
              </Row>
            )}
        </div>
      ) : (
        <VerticalEmptyState
          icon={images.noAppInstancesLargeIcon()}
          header={t('instances.emptyState.emptyContainerHeader')}
          description={t('instances.emptyState.emptyContainerDescription')}
        />
      )}
    </div>
  );
};

export default InstanceApplicationDepl;
