import axios from 'axios';
import { MCNM_API_URL, fetchToken } from './config';
import customHistory from '../history';

export const baseURL =
  process.env.NODE_ENV !== 'development'
    ? `${process.env.PUBLIC_URL}/${MCNM_API_URL}`
    : `${MCNM_API_URL}`;

export const axiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.request.use(
  function (config) {
    const token = fetchToken();

    if (token) {
      config.headers.Authorization = `${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    let errorMessageStatus = '',
      errorMessageErr = '',
      errorMessage = '';

    if (error.response && error.response.status === 401) {
      // If we not already logged out
      if (window.localStorage.getItem('SELECTED_ACCOUNT')) {
        window.sessionStorage.setItem(
          'LOGIN_REDIRECT_URL',
          window.location.pathname + window.location.search
        );
        return customHistory.push('/logout?error=invalidToken');
      }
    } //else if (error.response && error.response.status === 404) {
    // if (!customHistory.location.pathname.includes('event'))
    //   return customHistory.push('/404');}
    else if (error.response.data) {
      //Get error message returned from DB in api response and display in UI
      if (
        typeof error?.response?.data?.status === 'string' ||
        error?.response?.data?.status instanceof String
      ) {
        errorMessageStatus =
          error?.response?.data?.status.length > 0
            ? error?.response?.data?.status[0].toUpperCase() +
              error?.response?.data?.status.slice(1) +
              '.'
            : '';
      }
      if (
        typeof error.response?.data?.error === 'string' ||
        error.response?.data?.error instanceof String
      ) {
        errorMessageErr =
          error?.response?.data?.error.length > 0
            ? error?.response?.data?.error[0].toUpperCase() +
              error?.response?.data?.error.slice(1) +
              '.'
            : '';
      }

      error.response[
        'customErrorMessage'
      ] = `${errorMessageStatus} ${errorMessageErr}`;
    } else if (
      typeof error?.response?.statusText === 'string' ||
      error?.response?.statusText instanceof String
    ) {
      // Get error message returned from DB in api response and display in UI
      errorMessage = error?.response?.statusText;
      error.response['customErrorMessage'] =
        errorMessage.length > 0
          ? errorMessage[0].toUpperCase() + errorMessage.slice(1) + '.'
          : '';
    }

    return Promise.reject(error);
  }
);
