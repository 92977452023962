import moment from 'moment';
import { axiosInstance } from './api';
import { MCNM_APP_API_URL, MCNM_APP_SUBSCRIPTION_USAGE_URL } from './config';

// Gets current month usages
export const getSubscriptionUsage = async () => {
  const currDay = moment().format('YYYYMMDD');
  const startDay = moment().startOf('month').format('YYYYMMDD');
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_APP_SUBSCRIPTION_USAGE_URL}/subscription_usage?from_date=${startDay}&to_date=${currDay}`,
    });
    return response?.data;
  } catch (error) {
    console.log('Error: ', error);
    throw error;
  }
};

// Gets previous months usages
export const getUsageHistory = async () => {
  const toMonth = moment().subtract(1, 'month').format('YYYYMM');
  const fromMonth = moment().subtract(12, 'month').format('YYYYMM');

  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_APP_SUBSCRIPTION_USAGE_URL}/usage_history?from=${fromMonth}&to=${toMonth}`,
    });
    return response?.data?.usage_history ?? [];
  } catch (error) {
    console.log('Error: ', error);
    throw error;
  }
};
