import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SkeletonPlaceholder,
  Modal,
  TooltipDefinition,
  Button,
} from 'carbon-components-react';
import { deleteRole, getRole } from '../../controllers/roleApis';
import {
  getIdentities,
  deleteIdentityRole,
} from '../../controllers/identityApis';
import Header from '../../components/Header/Header';
import DetailsCard from '../../components/DetailsCard/DetailsCard';
import { useEffect, useState } from 'react';
import {
  ResourceGroup,
  Role,
  Permission,
  Identity,
  ResourceTypeCategoryMapInterface,
  ResourceGroupCategoryMapInterface,
  Error500Type,
} from '../../models/master';
import './RoleDetails.scss';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import GenericTruncateString from '../../components/GenericTruncateString/GenericTruncateString';
import RoleDetailsIdentityTable from './RoleDetailsIdentityTable/RoleDetailsIdentityTable';
import { permitEditBasedOnRole, resourceGroupsMap } from './config';
import RoleDetailsPermissionTable from './RoleDetailsPermissionTable/RoleDetailsPermissionTable';
import { getResourceGroups } from '../../controllers/resourceGroupApi';
import { AxiosError } from 'axios';
import { NotificationContext } from '../../components/Notifications/Context/NotificationProvider';
import sortData from '../../lib/tableSort';
import AssignIdentity from './AssignIdentity/AssignIdentity';
import EditRoleDetails from './EditRoleDetails/EditRoleDetails';
import EditPermission from './EditPermission/EditPermission';
import LabelTag from '../../components/LabelTag/LabelTag';
import { getPermissions } from '../../controllers/permissionApi';
import useAnalytics from '../../lib/useAnalytics';
import analyticsData from '../../lib/analyticsEventData';
import Error500 from '../Errors/Error500';

const RoleDetails = () => {
  const { t } = useTranslation('roleDetails');
  const [searchParams, setSearchParams] = useSearchParams();
  const roleId = searchParams.get('roleId');
  const notification = useContext(NotificationContext);
  const navigate = useNavigate();
  const { trackButtonClicked } = useAnalytics();

  const [roleDetailsDataLoading, toggleRoleDetailsDataLoading] =
    useState(false);
  const [roleDetailsData, setRoleDetailsData] = useState<Role | null>(null);
  const [rolePermissionData, setRolePermissionData] = useState<
    Permission[] | null
  >(null);
  const [roleIdentityData, setRoleIdentityData] = useState<Identity[] | null>(
    []
  );
  const [resourceGroups, setResourceGroups] = useState<ResourceGroup[] | null>(
    null
  );
  const [identityData, setIdentityData] = useState<Identity[] | null>([]);
  const [permissionData, setPermissionData] = useState<Permission[] | null>([]);
  const [error500, setError500] = useState<null | Error500Type>(null);

  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | 'NONE'>(
    'NONE'
  );

  const [openDeleteRoleModal, setOpenDeleteRoleModal] = useState(false);
  const [openAssignIdentityModal, setOpenAssignIdentityModal] =
    useState<boolean>(false);
  const [openEditRoleForm, setOpenEditRoleForm] = useState<boolean>(false);
  const [openEditPermissionTable, setOpenEditPermissionTable] =
    useState<boolean>(false);

  const [resourceTypeMap, setResourceTypeMap] = useState<
    ResourceTypeCategoryMapInterface[] | null
  >();

  const [resourceGroupMap, setResourceGroupMap] = useState<
    ResourceGroupCategoryMapInterface[] | null
  >();
  const [disableButton, setDisableButton] = useState(false);

  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    refreshData();
  }, []);

  useEffect(() => {
    if (roleDetailsData !== null) {
      toggleRoleDetailsDataLoading(false);
    }
  }, [roleDetailsData]);

  const storeResourceTypeMap = (map: ResourceTypeCategoryMapInterface[]) => {
    setResourceTypeMap(map);
  };

  const storeResourceGroupMap = (map: ResourceGroupCategoryMapInterface[]) => {
    setResourceGroupMap(map);
  };

  const refreshData = () => {
    toggleRoleDetailsDataLoading(true);
    const fetchData = async () => {
      try {
        const rolesData = await getDetailsByRoleId();
        const resourceGroupsData = await getResourceGroups();
        const permissionsData = await getPermissions();
        setRoleDetailsData(rolesData);
        setRolePermissionData(rolesData.permissions);
        setRoleIdentityData(rolesData.identities);
        setResourceGroups(resourceGroupsData.resource_groups);
        setPermissionData(permissionsData.permissions);
        Array.isArray(resourceGroupsData) &&
          resourceGroupsData.forEach((resourceGroup: ResourceGroup) => {
            resourceGroupsMap[resourceGroup.type].push(
              resourceGroup.resource_id
            );
          });

        const allIdentities = await getIdentities();
        setIdentityData(allIdentities);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  };
  const getDetailsByRoleId = async () => {
    try {
      return await getRole(roleId);
    } catch (error) {
      const err = error as AxiosError;

      if (err.response?.status === 404) navigate('/404');

      if (err.response!?.status >= 500) {
        setError500(err.response!?.status?.toString() as Error500Type);
      }
    }
  };

  const roleDetailsLabels = () => {
    const labels: any = [];
    if (roleDetailsData?.labels) {
      roleDetailsData.labels.forEach((label: string) => {
        labels.push(label);
      });
    }

    return labels;
  };

  const formatIdentitiesData = (data: Identity[] | null) => {
    const identityFormattedData: any = [];

    if (data != null && data?.length > 0) {
      data?.forEach((item: Identity) => {
        let obj = {
          created_at: item.created_at,
          description: item.description,
          labels: item.labels ? formatTags(item.labels) : '—',
          name: item.name,
          name__format: (
            <Link
              className='no-underline-link'
              to={`/IdentityDetails?identity_id=${item.resource_id}`}
            >
              <GenericTruncateString str={item?.name} tableView={true} />
            </Link>
          ),
          id: item.resource_id,
          roles: item.roles,
          updated_at: item.updated_at,
        };
        identityFormattedData.push(obj);
      });
      return identityFormattedData;
    } else {
      return [];
    }
  };

  const formatTags = (data: string[]) => {
    return <LabelTag labelArray={data} count={3} />;
  };

  const identityTableHeaders = [
    {
      header: `${t('roleIdentitiesTable.tableHeaders.name')}`,
      key: 'name__format',
      originalKey: 'name',
      sort: true,
    },
    {
      header: t('roleIdentitiesTable.tableHeaders.email'),
      key: 'id',
      originalKey: 'resource_id',
      sort: true,
    },
    {
      header: t('roleIdentitiesTable.tableHeaders.label'),
      key: 'labels',
      originalKey: 'labels',
    },
    {
      key: 'actionBtn',
      originalKey: '',
      header: '',
      sort: false,
    },
  ];

  const authErrorNotification = () => {
    notification.onTrigger('TOAST', {
      title: t('delete.error.authErrorTitle'),
      kind: 'error',
      subtitle: t('delete.error.authErrorSubtitle'),
    });
  };

  const submitDeleteRequest = async () => {
    try {
      trackButtonClicked(
        analyticsData['Admin Roles'].events.deleteRole.props,
        analyticsData['Admin Roles'].events.deleteRole.event
      );
      setDisableButton(true);
      await deleteRole(roleId);
      notification.onTrigger('TOAST', {
        title: t('delete.successNotification.title'),
        subtitle: t('delete.successNotification.description', {
          roleName: roleDetailsData?.name ?? '',
        }),
      });
      navigate('/roles');
    } catch (error: any) {
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        authErrorNotification();
      }

      const errorMessage: string = error.response['customErrorMessage'];
      notification.onTrigger('TOAST', {
        title: t('delete.error.title'),
        kind: 'error',
        subtitle:
          errorMessage.length > 0
            ? errorMessage
            : t('delete.error.errorMsg', {
                roleName: roleDetailsData?.name ?? '',
              }),
      });
    } finally {
      setDisableButton(false);
      setOpenDeleteRoleModal(false);
    }
  };

  const renderDeleteBtn = (disabled: boolean) => (
    <div className='delete-role'>
      <Button
        kind='danger--ghost'
        className={disabled ? 'delete-role-disabled' : ''}
        onClick={() => {
          disabled
            ? setOpenDeleteRoleModal(false)
            : setOpenDeleteRoleModal(true);
        }}
      >
        {t('delete.deleteRole')}
      </Button>
    </div>
  );

  const handleTableSort = (
    data: { id: string; text: string },
    sortDirection: 'ASC' | 'DESC' | 'NONE'
  ) => {
    setSortDirection(sortDirection);
    setSortKey(data.id);
  };

  const handleRemoveIdentitySuccess = (identityName: string) => {
    notification.onTrigger('TOAST', {
      title: t('remove.successNotification.title'),
      subtitle: t('remove.successNotification.description', {
        identity: identityName,
      }),
    });
    refreshData();
  };

  const handleRemoveIdentityError = () => {
    // toggleRoleTableDataLoading(false);
    notification.onTrigger('TOAST', {
      title: t('remove.error.title'),
      kind: 'error',
      subtitle: t('remove.error.errorMsg'),
    });
  };

  const handleRemoveIdentity = async (
    identityId: string,
    identityName: string
  ) => {
    try {
      const res = await deleteIdentityRole(identityId, roleId);
      res?.status === 204
        ? handleRemoveIdentitySuccess(identityName)
        : handleRemoveIdentityError();
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        authErrorNotification();
      } else {
        handleRemoveIdentityError();
      }
    }
  };

  if (error500) {
    return <Error500 />;
  }

  return (
    <div className='role-details-page'>
      <Header
        loading={roleDetailsDataLoading}
        title={roleDetailsData?.name ?? ''}
        breadcrumbs={[
          {
            url: '/',
            label: t('home'),
          },
          {
            url: '/roles',
            label: t('roleDetails'),
          },
        ]}
      />
      <div className='page-content'>
        <div className='role-details-card'>
          <div className='role-details-card-container'>
            {roleDetailsDataLoading ? (
              <>
                <SkeletonPlaceholder className='role-details-skeleton' />
              </>
            ) : (
              <DetailsCard
                type='ROLE_DETAILS'
                isEditable={
                  roleDetailsData != null
                    ? permitEditBasedOnRole(roleDetailsData.resource_id)
                    : true
                }
                openEditModal={() => {
                  setOpenEditRoleForm(true);
                  trackButtonClicked(
                    analyticsData['Admin Roles'].events.editRole.props,
                    analyticsData['Admin Roles'].events.editRole.event
                  );
                }}
                detailsCardName={t('detailsCardName')}
                data={[
                  {
                    key: 'rolename',
                    value: (
                      <div className='name'>
                        <GenericTruncateString
                          tableView={true}
                          str={roleDetailsData?.name ?? ''}
                          maxLength={25}
                          limit={11}
                        />
                      </div>
                    ),
                  },
                  {
                    key: 'created',
                    value: roleDetailsData?.created_at,
                  },
                  {
                    key: 'updatedAt',
                    value: roleDetailsData?.updated_at,
                  },
                  {
                    key: 'description',
                    value: roleDetailsData?.description,
                  },
                  {
                    key: 'labels',
                    value: roleDetailsLabels(),
                  },
                ]}
              />
            )}
          </div>
        </div>
        <RoleDetailsPermissionTable
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
          rows={rolePermissionData}
          resourceGroupsData={resourceGroups}
          loading={roleDetailsDataLoading}
          isEditable={
            roleDetailsData != null
              ? permitEditBasedOnRole(roleDetailsData.resource_id)
              : true
          }
          openEditPermission={() => {
            setOpenEditPermissionTable(true);
          }}
          updateResourceTypeMapState={(
            map: ResourceTypeCategoryMapInterface[]
          ) => storeResourceTypeMap(map)}
          updateResourceGroupMapState={(
            map: ResourceGroupCategoryMapInterface[]
          ) => storeResourceGroupMap(map)}
        />
        <RoleDetailsIdentityTable
          rows={
            roleIdentityData
              ? formatIdentitiesData(
                  sortData(roleIdentityData ?? [], sortKey, sortDirection)
                )
              : []
          }
          identities={identityData}
          headers={identityTableHeaders}
          loading={roleDetailsDataLoading}
          sortRows={(
            data: { id: string; text: string },
            direction: 'ASC' | 'DESC' | 'NONE'
          ) => handleTableSort(data, direction)}
          openAssignIdentity={() => {
            setOpenAssignIdentityModal(true);
          }}
          removeIdentity={handleRemoveIdentity}
        />
        {roleIdentityData && roleIdentityData.length > 0 ? (
          <TooltipDefinition
            tooltipText={t('delete.tooltipText') as string}
            direction='bottom'
          >
            {renderDeleteBtn(true)}
          </TooltipDefinition>
        ) : (
          renderDeleteBtn(false)
        )}
        <Modal
          className='delete-role-modal'
          size='xs'
          danger
          modalHeading={t('delete.modalTitle')}
          modalLabel={t('delete.modalLabel', {
            roleName: roleDetailsData?.name ?? '',
          })}
          onRequestClose={() => setOpenDeleteRoleModal(false)}
          onRequestSubmit={() => submitDeleteRequest()}
          primaryButtonText={t('delete.deleteButton')}
          secondaryButtonText={t('delete.cancelButton')}
          open={openDeleteRoleModal}
          primaryButtonDisabled={disableButton}
        >
          {t('delete.description', {
            roleName: roleDetailsData?.name ?? '',
          })}
        </Modal>
        {openEditPermissionTable && (
          <EditPermission
            open={openEditPermissionTable}
            tabIndex={tabIndex}
            roleId={roleId}
            rows={rolePermissionData}
            resourceGroupsData={resourceGroups}
            onClose={() => setOpenEditPermissionTable(false)}
            permissionData={rolePermissionData}
            allPermissionData={permissionData}
            refreshDetailsData={() => {
              refreshData();
              setOpenEditPermissionTable(false);
            }}
            roleDetails={roleDetailsData}
            resourceTypeCategoryMap={
              Array.isArray(resourceTypeMap) ? resourceTypeMap : []
            }
            resourceGroupCategoryMap={
              Array.isArray(resourceGroupMap) ? resourceGroupMap : []
            }
            updateResourceTypeMapState={(
              map: ResourceTypeCategoryMapInterface[]
            ) => storeResourceTypeMap(map)}
            updateResourceGroupMapState={(
              map: ResourceGroupCategoryMapInterface[]
            ) => storeResourceGroupMap(map)}
          />
        )}
        {openAssignIdentityModal && (
          <AssignIdentity
            open={openAssignIdentityModal}
            roleId={roleId}
            identities={identityData}
            assignedIdentities={roleIdentityData}
            onClose={() => setOpenAssignIdentityModal(false)}
            refreshDetailsData={() => {
              refreshData();
              setOpenAssignIdentityModal(false);
            }}
          />
        )}
        {openEditRoleForm && (
          <EditRoleDetails
            open={openEditRoleForm}
            roleId={roleId}
            onClose={() => {
              setOpenEditRoleForm(false);
            }}
            refreshDetailsData={() => {
              refreshData();
              setOpenEditRoleForm(false);
            }}
            roleDetails={roleDetailsData}
          />
        )}
      </div>
    </div>
  );
};

export default RoleDetails;
