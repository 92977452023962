import React from 'react';
import { Button } from 'carbon-components-react';
import { SubtractAlt16 } from '@carbon/icons-react';
import { useTranslation } from 'react-i18next';

import { IconType, getIcon } from './config';
import { NetworkSegments } from '../../../../models/master';
import images from '../../../../images/images';

import './Card.scss';
import { NetworkSegmentCompatibilitySet } from '../../../../lib/enums';

interface Props {
  label: string;
  type: IconType;
  onRemove: () => void;
  selectedSegment?: NetworkSegments | null;
}

const Card: React.FC<Props> = ({ label, type, onRemove, selectedSegment }) => {
  const Icon = getIcon(type);

  const { t } = useTranslation('connectionAccessPolicyStateView');

  return selectedSegment &&
    selectedSegment?.compatibility_set ===
      NetworkSegmentCompatibilitySet.ServiceInterconnect ? (
    <div className='fromCard'>
      <div className='label'>
        <div className='icon'>{images.nwSegmentIconSvgElement()}</div>
        <div className='text'>{label}</div>
      </div>
      <div className='subtext'>{t('networkSegemntSublabel')}</div>
    </div>
  ) : (
    <div className='card'>
      <div className='label'>
        <div className='icon'>{Icon && <Icon />}</div>
        <div className='text'>{label}</div>
      </div>
      <Button
        className='remove-icon'
        kind='ghost'
        size='small'
        onClick={onRemove}
        iconDescription=' '
        renderIcon={SubtractAlt16}
      />
    </div>
  );
};

export default Card;
