import { SkeletonText } from 'carbon-components-react';
import React from 'react';
import PageSubTitle from './PageSubTitle';
import PageTitle from './PageTitle';
import './PageTitle.scss';

type LearnMore = { url: string; label: string };

interface TitleProperties {
  title: React.ReactElement | string;
  subTitle?: JSX.Element | string;
  loadingContent?: boolean;
  headerIcon?: React.ReactElement | boolean;
  learnMoreLinkPage?: LearnMore;
}

const PageTitleComponent: React.FC<TitleProperties> = ({
  title,
  subTitle,
  loadingContent,
  children,
  headerIcon,
  learnMoreLinkPage,
}) => {
  const pageSubtitleInfo = children || subTitle;
  return (
    <div className='title-container'>
      {loadingContent ? (
        <SkeletonText className='skeleton-header' />
      ) : (
        <PageTitle title={title} headerIcon={headerIcon} />
      )}
      {pageSubtitleInfo &&
        (loadingContent ? (
          <SkeletonText className='subtitle-skeleton' />
        ) : (
          <PageSubTitle>
            {pageSubtitleInfo}{' '}
            {learnMoreLinkPage ? (
              <a
                className='learnMoreLink'
                href={learnMoreLinkPage.url}
                rel='noreferrer'
                target='_blank'
              >
                {learnMoreLinkPage.label}
              </a>
            ) : (
              ''
            )}
          </PageSubTitle>
        ))}
    </div>
  );
};

export default PageTitleComponent;
