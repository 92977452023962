import { ClickableTile, Column, Loading, Row } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import GenericStatusField from '../../../components/GenericStatusField/GenericStatusField';
import GenericTruncateString from '../../../components/GenericTruncateString/GenericTruncateString';
import images from '../../../images/images';
import dateUtils from '../../../lib/dates';
import { getThirdPartyCloudIcons } from '../ThirdPartyCloudIcons';
import './CloudCards.scss';

const CloudCards = ({
  cloudId,
  cloudName,
  type,
  autodiscover,
  discoveryStatus,
  provider,
  providerName,
  updated,
  infrastructureGroup,
  resourceGroupsPermission,
}: {
  cloudId: string;
  cloudName: string;
  type: boolean;
  provider: string;
  providerName: string;
  autodiscover: boolean;
  discoveryStatus: string | undefined;
  updated: string;
  infrastructureGroup: string;
  resourceGroupsPermission: boolean;
}) => {
  const { t } = useTranslation('cloudsContainer');
  const navigate = useNavigate();

  const discoveryStatusIcon = (status: string) => {
    let icon;
    switch (status) {
      case 'completed':
        icon = images.autoDiscoverLabelIcon();
        break;
      case 'in-progress':
        icon = <Loading active small withOverlay={false} />;
        break;
      case 'pending':
        icon = images.pendingStatusIcon();
        break;
      case 'errored':
        icon = images.criticalStatusIcon();
        break;
      default:
        break;
    }
    return <div className={`${status}-icon`}>{icon}</div>;
  };

  const fetchDiscoveryStatus = (status: string | undefined) => {
    let value;

    switch (status) {
      case 'completed':
        value = t('completed');
        break;
      case 'in-progress':
        value = t('in-progress');
        break;
      case 'pending':
        value = t('pending');
        break;
      case 'errored':
        value = t('errored');
        break;
      default:
        value = '—';
        break;
    }

    return value;
  };

  return (
    <ClickableTile
      className='cloud-card'
      onClick={() => cloudId && navigate(`/cloudDetails?cloudId=${cloudId}`)}
    >
      <Row className='header'>
        <Column>
          <div
            className='name'
            title={providerName.length >= 20 ? '—' : providerName}
          >
            {!!cloudName ? (
              <>
                <GenericTruncateString
                  str={cloudName}
                  maxLength={20}
                  limit={8}
                />
                {provider ? (
                  <div className='icon'>
                    {getThirdPartyCloudIcons(provider)}
                  </div>
                ) : null}
              </>
            ) : (
              '—'
            )}
          </div>
        </Column>
      </Row>
      <Row className='data'>
        <Column>
          <div className='label-text'>{t('cardSubheading.autodiscovery')}</div>
          <div className='name'>
            <div className='auto-discover-label'>
              <div className='auto-discover-label-item '>
                {autodiscover && discoveryStatus
                  ? discoveryStatusIcon(discoveryStatus)
                  : null}
                <div className='value'>
                  {autodiscover
                    ? fetchDiscoveryStatus(discoveryStatus)
                    : t('off')}
                </div>
              </div>
            </div>
          </div>
        </Column>
      </Row>

      <Row className='data'>
        <Column>
          <div className='label-text'>{t('cardSubheading.type')}</div>
          <div className='name'>{type ? t('onPrem') : t('public')}</div>
        </Column>
      </Row>
      <Row className='data'>
        <Column>
          <div className='label-text'>
            {t('cardSubheading.infrastructureGroup')}
          </div>
          <div className='name'>
            {!resourceGroupsPermission ? (
              <GenericStatusField status='notAuthorised'></GenericStatusField>
            ) : !!infrastructureGroup ? (
              infrastructureGroup
            ) : (
              '—'
            )}
          </div>
        </Column>
      </Row>
      <Row className='data'>
        <Column>
          <div className='helper-text'>
            {t('updated', {
              date: !!updated ? dateUtils.getUserFriendlyDate(updated) : '—',
            })}
          </div>
        </Column>
      </Row>
    </ClickableTile>
  );
};

export default CloudCards;
