import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DataTable,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  Pagination,
  DataTableSkeleton,
  TableExpandRow,
  TableExpandedRow,
  TableExpandHeader,
} from 'carbon-components-react';

import FindAndFilterBar from '../FindAndFilterBar/FindAndFilterBar';
import GenericTableTitle from '../GenericTableTitle/GenericTableTitle';
import { VerticalEmptyState } from '../EmptyState/EmptyState';
import {
  ApplicationData,
  DeploymentEnvironment,
  PolicyData,
  AppliedFilter,
} from '../../models/master';
import './GenericTableWithFilters.scss';
import Error403Card from '../ErrorState/Error403Card';
import Error500Card from '../ErrorState/Error500Card';

interface Header {
  key: string;
  header: string;
  isSortable?: boolean;
  sort?: string;
  originalKey: string;
}

interface Filter {
  key: string;
  type: 'multi' | 'single';
  label?: string;
  values: string[];
}

export interface PageData {
  page: number;
  pageSize: number;
}

export interface TableProperties {
  data: ApplicationData[] | DeploymentEnvironment[] | PolicyData[] | null | any;
  fullData:
    | ApplicationData[]
    | DeploymentEnvironment[]
    | PolicyData[]
    | null
    | any;
  filteredDataCallback(arg0: unknown): void;
  sortRows(arg0: unknown, direction: string): void;
  selectedFiltersVal?: any;
  setFilterApplied(data: AppliedFilter[]): void;
  persistFilter?: boolean;
  filters?: Filter[];
  leftInlineFilters?: Filter[];
  leftInlineMultiSelectFilter?: Filter[];
  visibilityFlag?: string;
  onTableRefresh(): void;
  id?: string;
  dataTestId?: string;
  headers: Header[] | any;
  rows: Array<any> | null;
  isSortable?: boolean;
  hasFilter?: boolean;
  hasPagination?: boolean;
  showRefresh?: boolean;
  showSearch?: boolean;
  totalElementsCount: number;
  paginationClass?: string;
  paginationSizeSelections?: Array<number>;
  currentPageNumber?: number;
  currentPageSize?: number;
  onPageChange: (pageData: any) => void;
  isRowsExpandable?: boolean;
  rowsExpansionContent?: Array<any>;
  title?: string;
  description?: string;
  actionButton?:
    | {
        text: string;
        actionButtonCallback: () => void;
      }
    | undefined;
  emptyState: {
    icon: JSX.Element | JSX.Element[];
    notFoundIcon: JSX.Element;
    emptyStateHeader?: string;
    emptyStateDescription?: string;
    buttonText?: string;
    link?: boolean | string | undefined;
    click?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    dropdown?: {
      label: string;
      items: any;
    };
    dropdownClick?: any;
  };
  render403Container?: boolean;
  tableDataLoading?: boolean;
  render500Container?: boolean;
}

const style = {
  marginBottom: '0px',
  height: '350px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

const GenericTableWithFilters = (props: TableProperties) => {
  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | 'NONE'>(
    'NONE'
  );

  const [sortTitle, setSortTitle] = useState<string>('');

  const { t } = useTranslation('genericTableWithFilters');

  /**
   * @description - Sorting function which is called on clicking sortable table headers
   * @param index {number} - Index of the header which is clicked.
   */

  const handleChangeSort = (index: number) => {
    const newSortDirection =
      sortKey === props.headers[index].originalKey
        ? sortDirection === 'ASC'
          ? 'DESC'
          : 'ASC'
        : 'ASC';

    setSortDirection(newSortDirection);

    const newSortDirectionTitle =
      sortKey === props.headers[index].originalKey
        ? sortTitle === t('sorting.ascending')
          ? t('sorting.descending')
          : t('sorting.ascending')
        : t('sorting.ascending');

    setSortTitle(newSortDirectionTitle);
    const sortedKey =
      props.headers[index].originalKey !== undefined
        ? props.headers[index].originalKey
        : '';
    setSortKey(sortedKey);
    props.sortRows(
      {
        id: props.headers[index].originalKey,
        text: props.headers[index].header,
      },
      newSortDirection
    );
  };

  /**
   * @description - Function to render each header columns
   * @param headers - The header list.
   * @param getHeaderProps - Function to get header properties
   * @returns {string} - The table header columns
   */
  const renderTableHeader = (
    headers: { sort: any; header: any }[],
    getHeaderProps: (headerObj: {
      header: { sort: any; header: any };
      isSortable: boolean;
    }) => any
  ) => {
    const tableHeaders = headers.map(
      (
        header: {
          sort: string;
          header: any;
          style?: { minWidth: string };
        },
        index: number
      ) => {
        return props.headers[index].originalKey !== 'rowsExpansionContent' ? (
          <TableHeader
            {...getHeaderProps({
              header,
              isSortable: Boolean(header.sort),
            })}
            onClick={() => (header.sort ? handleChangeSort(index) : undefined)}
            title={
              props.headers[index].originalKey === sortKey
                ? sortTitle
                : Boolean(header.sort)
                ? t('sorting.sorting')
                : ''
            }
            isSortHeader={Boolean(header.sort)}
            sortDirection={
              props.headers[index].originalKey === sortKey
                ? sortDirection
                : 'NONE'
            }
            style={header?.style ? { ...header.style } : {}}
            data-testid='table-header'
          >
            {header.header}
          </TableHeader>
        ) : null;
      }
    );
    return tableHeaders;
  };

  /**
   * @description - Function to calculate the rows to be displayed based on pagination value.
   * @param rows {Array} - The entire rows that has be display.
   * @returns {Array} - The rows to be displayed in the view.
   */
  const getRowsToBeDisplayed = (rows: any) => {
    if (
      (props.hasPagination ? props.hasPagination : true) &&
      props.currentPageNumber &&
      props.currentPageSize
    )
      return rows.slice(
        (props.currentPageNumber - 1) * props.currentPageSize,
        props.currentPageNumber * props.currentPageSize
      );
    return rows;
  };

  const checkIfExpandedData = (id: string) => {
    const key = id.split(':')[1];
    return key === 'rowsExpansionContent' ? true : false;
  };

  /**
   * @description - Function to return find and filter component if it is required in the table.
   * @returns {JSX.Element | null} - Renders search and filter component.
   */

  const renderFilterSection = () => (
    <FindAndFilterBar
      leftInlineFilters={props.leftInlineFilters}
      leftInlineMultiSelectFilter={props.leftInlineMultiSelectFilter}
      data={props.fullData}
      filteredData={props.data}
      filteredDataCallback={data =>
        props.filteredDataCallback(data as Array<any> | [])
      }
      filtersApplied={props.selectedFiltersVal as any}
      filtersAppliedCallback={data => props.setFilterApplied(data)}
      filters={props.filters ? props.filters : []}
      actionButton={props.actionButton ? props.actionButton : undefined}
      onRefresh={() => props.onTableRefresh()}
      showRefresh={props.showRefresh !== undefined ? props.showRefresh : true}
      showSearch={props.showSearch !== undefined ? props.showSearch : true}
      visibilityFlag={props.visibilityFlag}
      persistFilter={props.persistFilter}
    />
  );

  /**
   * @Description - Function to render table title and description
   * @returns {JSX.Element | null} - Renders Title component
   */
  const renderTitleAndDescription = () => {
    if (props.title && props.description) {
      return (
        <GenericTableTitle
          title={props.title}
          description={props.description}
        />
      );
    } else if (props.title) {
      return <GenericTableTitle title={props.title} />;
    } else {
      return null;
    }
  };

  const getEmptyStateConfig = () => {
    if (props.selectedFiltersVal?.length > 0 && props.rows?.length === 0)
      return {
        icon: props.emptyState.notFoundIcon,
        emptyStateHeader: t('notFound.header'),
        emptyStateDescription: t('notFound.description'),
        link: false,
        buttonText: t('notFound.text'),
        click: () => {
          props.setFilterApplied([]);
          props.filteredDataCallback([]);
        },
        dropdown: props.emptyState?.dropdown ?? null,
        dropdownClick: props.emptyState?.dropdownClick ?? null,
      };
    else return props.emptyState;
  };

  /**
   * @description - Function to render Empty container when the data list array is empty.
   * @returns {JSX.Element} - Renders no data container.
   */
  const renderNoDataContainer = () => {
    const emptyStateData = getEmptyStateConfig();

    if (emptyStateData?.dropdown != null) {
    }

    return (
      <div className='emptyContainer'>
        <div className='emptyContainerCol'>
          <VerticalEmptyState
            icon={emptyStateData?.icon}
            header={
              emptyStateData.emptyStateHeader ?? t('emptyContainerHeader')
            }
            description={
              emptyStateData.emptyStateDescription ??
              t('emptyContainerDescription')
            }
          />
        </div>
      </div>
    );
  };

  const render403Container = () => {
    return (
      <div className='emptyContainer'>
        <div className='emptyContainerCol'>
          <Error403Card />
        </div>
      </div>
    );
  };

  const render500Container = () => {
    return (
      <div className='emptyContainer'>
        <div className='emptyContainerCol'>
          <Error500Card />
        </div>
      </div>
    );
  };

  /**
   * @description - Function to render pagination component.
   * @returns {JSX.Element | null} - Renders Pagination component.
   */
  const renderPagination = () =>
    props.rows &&
    props.rows.length !== 0 &&
    (props.hasPagination ? props.hasPagination : true) ? (
      <Pagination
        backwardText={t('prevPageBtn')}
        forwardText={t('nextPageBtn')}
        itemsPerPageText={t('pageItemsNumberText')}
        page={props.currentPageNumber}
        pageNumberText={t('pageNumberText')}
        pageSize={props.currentPageSize ? props.currentPageSize : 10}
        pageSizes={
          props.paginationSizeSelections
            ? props.paginationSizeSelections
            : [10, 25, 50, 100]
        }
        totalItems={props.totalElementsCount}
        onChange={val => props.onPageChange(val)}
        className={
          props.paginationClass ? props.paginationClass : 'tablePagination'
        }
        itemRangeText={(min, max, totalLength) => {
          return t('itemRange', {
            min: min,
            max: max,
            total: totalLength,
          });
        }}
        pageRangeText={() =>
          t('pageRange', {
            sum: Math.ceil(
              props.totalElementsCount /
                (props.currentPageSize ? props.currentPageSize : 10)
            ),
          })
        }
      />
    ) : null;

  return !props.rows || props?.tableDataLoading ? (
    <div>
      {renderFilterSection()}
      <DataTableSkeleton
        headers={props.headers}
        showToolbar={false}
        showHeader={false}
      />
    </div>
  ) : (
    <React.Fragment>
      {renderTitleAndDescription()}
      {renderFilterSection()}
      <DataTable
        rows={props.rows}
        headers={props.headers}
        isSortable={props.isSortable}
      >
        {({
          rows,
          headers,
          getHeaderProps,
          getTableProps,
          getRowProps,
        }: any) => (
          <TableContainer>
            <Table
              {...getTableProps()}
              id={props.id}
              data-testid={props.dataTestId}
            >
              <TableHead>
                <TableRow>
                  {(props.isRowsExpandable ? props.isRowsExpandable : false) ? (
                    <TableExpandHeader id='expand' />
                  ) : null}
                  {renderTableHeader(headers, getHeaderProps)}
                </TableRow>
              </TableHead>
              {rows.length === 0 ? null : (
                <TableBody>
                  {getRowsToBeDisplayed(rows).map(
                    (
                      row: {
                        id: React.Key | null | undefined;
                        cells: any[];
                      },
                      index: number
                    ) => {
                      return (
                        props.isRowsExpandable ? props.isRowsExpandable : false
                      ) ? (
                        <React.Fragment key={row.id}>
                          <TableExpandRow
                            expandHeader='expand'
                            {...getRowProps({ row })}
                          >
                            {row.cells.map(cell =>
                              checkIfExpandedData(cell.id) ? null : (
                                <TableCell key={cell.id}>
                                  {cell.value}
                                </TableCell>
                              )
                            )}
                          </TableExpandRow>
                          <TableExpandedRow
                            colSpan={headers.length + 1}
                            className='demo-expanded-td'
                          >
                            {row.cells.map(cell =>
                              checkIfExpandedData(cell.id) ? (
                                <div>{cell.value}</div>
                              ) : null
                            )}
                          </TableExpandedRow>
                        </React.Fragment>
                      ) : (
                        <TableRow key={row.id}>
                          {row.cells.map(cell => (
                            <TableCell key={cell.id}>{cell.value}</TableCell>
                          ))}
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        )}
      </DataTable>
      {props.render403Container ? render403Container() : null}
      {props.render500Container ? render500Container() : null}
      {!props.render403Container &&
      !props.render500Container &&
      props.rows.length === 0
        ? renderNoDataContainer()
        : null}
      {renderPagination()}
    </React.Fragment>
  );
};

export default GenericTableWithFilters;
