import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Edit16, TrashCan16 } from '@carbon/icons-react';

import Overflow from '../../../components/Overflow/Overflow';
import GenericTableWithFilters from '../../../components/GenericTableWithFilters/GenericTableWithFilters';

import dateUtils from '../../../lib/dates';
import { capitalizeFirstLetter } from '../../../lib/utils';
import images from '../../../images/images.js';

import { ResourceGroup, AppliedFilter } from '../../../models/master';

import './ResourceGroupsTable.scss';
import GenericTruncateString from '../../../components/GenericTruncateString/GenericTruncateString';
import IconWithToolTip from '../../../components/IconWithToolTip/IconWithToolTip';

interface TableRow {
  id: any;
  name: JSX.Element;
  type: string;
  descriptionFormatted: JSX.Element;
  lastUpdated: string;
  actionBtns: JSX.Element;
}

const ResourceGroupsTable = (props: {
  filteredDataCallback: (data: any) => void;
  filtersSelected: any;
  filtersAppliedCallback: (data: any) => void;
  filters: any;
  rows: ResourceGroup[] | null;
  data: ResourceGroup[] | null;
  filteredDataSet: ResourceGroup[] | null;
  elementCount: number;
  onRefresh: () => void;
  currentPageNumber: number;
  currentPageSize: number;
  onPageChange: (pageData: any) => void;
  sortRows(arg0: unknown, direction: string): void;
  noDataCallBack: () => void;
  persistFilter?: boolean;
  removeResourceGroup: (id: string, name: string) => void;
  editResourceGroup: (id: string) => void;
  error500Flag: boolean;
}) => {
  const { t } = useTranslation('resourceGroupsContainer');
  const { rows, onRefresh, removeResourceGroup, editResourceGroup } = props;
  const tableRef = useRef<HTMLDivElement>(null);

  const error500Flag = props.error500Flag;

  const myheaders = [
    {
      key: 'name',
      originalKey: 'name',
      header: t('tableHeaders.name'),
      sort: true,
    },
    {
      key: 'type',
      originalKey: 'type',
      header: t('tableHeaders.type'),
      sort: true,
    },
    {
      key: 'descriptionFormatted',
      originalKey: 'description',
      header: t('tableHeaders.description'),
      style: { minWidth: '12.5rem' },
    },
    {
      key: 'lastUpdated',
      originalKey: 'updated_at',
      header: t('tableHeaders.lastUpdated'),
      sort: true,
      style: { minWidth: '12.5rem' },
    },
    {
      key: 'actionBtns',
      originalKey: '',
      header: '',
      sort: false,
      style: { minWidth: '3.125rem' },
    },
  ];

  /**
   * @description - Function to format the raw data into table rows format.
   * @returns {TableRow[] | [] | null} - Returns the formatted data to be rendered as table rows.
   */
  const setRowsData = () => {
    let formattedRows: TableRow[] = [];
    if (error500Flag) return [];
    if (rows && rows.length === 0) return [];
    if (rows)
      rows.map((row: ResourceGroup) => {
        formattedRows.push({
          id: row.resource_id,
          name: <GenericTruncateString str={row?.name} tableView={true} />,
          type: capitalizeFirstLetter(row?.type),
          descriptionFormatted: row.description ? (
            <Overflow>
              <div className='resource-name'>{row.description || '—'}</div>
            </Overflow>
          ) : (
            <>—</>
          ),
          lastUpdated: dateUtils.getUserFriendlyDate(row.updated_at) || '—',
          actionBtns: (
            <div className='action-buttons'>
              <div
                className={`edit-resource-group ${
                  row.resource_id === 'default-infra' ||
                  row.resource_id === 'default-app'
                    ? 'disabled'
                    : ''
                } `}
                onClick={() => {
                  row.resource_id !== 'default-infra' &&
                    row.resource_id !== 'default-app' &&
                    editResourceGroup(row.resource_id);
                }}
              >
                <IconWithToolTip
                  icon={<Edit16 />}
                  iconDescription={
                    row.resource_id === 'default-infra' ||
                    row.resource_id === 'default-app'
                      ? (t('tableRowBtns.disabledUpdateBtn') as string)
                      : (t('tableRowBtns.updateResourceGroupBtn') as string)
                  }
                />
              </div>

              <div
                className={`delete-resource-group ${
                  row.resource_id === 'default-infra' ||
                  row.resource_id === 'default-app'
                    ? 'disabled'
                    : ''
                } `}
                onClick={() => {
                  row.resource_id !== 'default-infra' &&
                    row.resource_id !== 'default-app' &&
                    removeResourceGroup(row.resource_id, row.name);
                }}
              >
                <IconWithToolTip
                  icon={<TrashCan16 />}
                  iconDescription={
                    row.resource_id === 'default-infra' ||
                    row.resource_id === 'default-app'
                      ? (t('tableRowBtns.disabledDeleteBtn') as string)
                      : (t('tableRowBtns.deleteResourceGroupBtn') as string)
                  }
                />
              </div>
            </div>
          ),
        });
        return 0;
      });
    else return null;
    return formattedRows;
  };

  const emptyStateData = {
    icon: images.ResourceGroupEmpty(),
    notFoundIcon: images.NotFoundLarge(),
    emptyStateHeader: t('emptyState.emptyContainerHeader'),
    emptyStateDescription: t('emptyState.emptyContainerDescription'),
    link: '/',
    buttonText: t('emptyState.buttonText'),
    click: () => props.noDataCallBack(),
  };

  return (
    <div className='resource-groups-table' ref={tableRef}>
      <GenericTableWithFilters
        id='table-with-filter-panel'
        rows={setRowsData()}
        data={props.filteredDataSet}
        headers={myheaders}
        isSortable={true}
        totalElementsCount={props.elementCount ? props.elementCount : 0}
        fullData={props.data}
        onTableRefresh={() => onRefresh()}
        filteredDataCallback={(data: TableRow[]) =>
          props.filteredDataCallback(data)
        }
        selectedFiltersVal={props.filtersSelected as any}
        setFilterApplied={(data: AppliedFilter[]) =>
          props.filtersAppliedCallback(data)
        }
        filters={props.filters}
        currentPageNumber={props.currentPageNumber}
        currentPageSize={props.currentPageSize}
        onPageChange={(pageData: any) => props.onPageChange(pageData)}
        emptyState={emptyStateData}
        sortRows={(data, sortDirection) => props.sortRows(data, sortDirection)}
        persistFilter={props.persistFilter}
        render500Container={props.error500Flag}
      />
    </div>
  );
};

export default ResourceGroupsTable;
