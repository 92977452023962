import React, { createContext, useState } from 'react';
import { NotificationKind } from 'carbon-components-react';

interface Details {
  kind?: NotificationKind;
  title: string;
  subtitle: string;
  timeout?: number;
  action?: any;
  onActionClick?: Function;
  lowContrast?: boolean;
  customClassName?: string;
}

type NotificationType = 'TOAST' | 'ACTION';

const defaultValue: Details = {
  kind: 'success',
  title: '',
  subtitle: '',
  timeout: 5000,
  lowContrast: true,
  customClassName: '',
};

export const NotificationContext = createContext({
  details: defaultValue,
  notificationType: '',
  onTrigger: (type: NotificationType, data: Details) => {},
  onClose: () => {},
});

const NotificationProvider: React.FC = ({ children }) => {
  const [details, setDetails] = useState<Details>(defaultValue);
  const [notificationType, setNotificationType] = useState<
    NotificationType | ''
  >('');

  const handleTriggerNotification = (type: NotificationType, data: Details) => {
    setNotificationType('');
    setDetails(data);
    setNotificationType(type);
  };

  const handleCloseNotification = () => {
    setDetails(defaultValue);
    setNotificationType('');
  };

  return (
    <NotificationContext.Provider
      value={{
        details,
        notificationType,
        onTrigger: handleTriggerNotification,
        onClose: handleCloseNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
