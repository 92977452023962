import { TFunction } from 'react-i18next';
import images from '../../images/images';

export type ProceduralStatusType =
  | 'notdeployed'
  | 'deploying'
  | 'provisioning'
  | 'operational'
  | 'terminating'
  | 'terminated'
  | 'errored'
  | 'redeploying'
  | 'promoting'
  | 'pending'
  | 'demoting'
  | 'upgrading'
  | 'post-upgrade-soaking'
  | 'post-upgrade-undeploying'
  | 'deployed';

export type HealthStatusType =
  | 'operational'
  | 'minor'
  | 'critical'
  | 'unreachable'
  | 'notdeployed'
  | 'pending'
  | 'errored';

export const getProceduralStatusConfig = (
  t: TFunction<'gatewayStatus', undefined>,
  status: ProceduralStatusType
) => {
  const config = {
    deploying: {
      icon: images.loadingStatusIcon(),
      label: t('deploying'),
      loadingIcon: true,
    },
    provisioning: {
      icon: images.loadingStatusIcon(),
      label: t('provisioning'),
      loadingIcon: true,
    },
    terminating: {
      icon: images.loadingStatusIcon(),
      label: t('terminating'),
      loadingIcon: true,
    },
    redeploying: {
      icon: images.loadingStatusIcon(),
      label: t('redeploying'),
      loadingIcon: true,
    },
    promoting: {
      icon: images.loadingStatusIcon(),
      label: t('promoting'),
      loadingIcon: true,
    },
    demoting: {
      icon: images.loadingStatusIcon(),
      label: t('demoting'),
      loadingIcon: true,
    },
    upgrading: {
      icon: images.loadingStatusIcon(),
      label: t('upgrading'),
      loadingIcon: true,
    },
    'post-upgrade-soaking': {
      icon: images.loadingStatusIcon(),
      label: t('post-upgrade-soaking'),
      loadingIcon: true,
    },
    'post-upgrade-undeploying': {
      icon: images.loadingStatusIcon(),
      label: t('post-upgrade-undeploying'),
      loadingIcon: true,
    },
    notdeployed: {
      icon: images.notDeployedIcon(),
      label: t('notdeployed'),
      loadingIcon: false,
    },
    operational: {
      icon: null,
      label: '—',
      loadingIcon: false,
    },
    terminated: {
      icon: images.notDeployedIcon(),
      label: t('terminated'),
      loadingIcon: false,
    },
    errored: {
      icon: images.majorStatusIcon(),
      label: t('major'),
      loadingIcon: false,
    },
    pending: {
      icon: images.pendingStatusIcon(),
      label: t('pending'),
      loadingIcon: false,
    },
    deployed: {
      icon: null,
      label: t('deployed'),
      loadingIcon: false,
    },
  };

  return config[status];
};

export const getHealthStatusConfig = (
  t: TFunction<'gatewayStatus', undefined>,
  status: HealthStatusType
) => {
  const config = {
    operational: {
      icon: images.operationalStatusIcon(),
      label: t('operational'),
    },
    minor: {
      icon: images.minorStatusIcon(),
      label: t('minor'),
    },
    critical: {
      icon: images.criticalStatusIcon(),
      label: t('critical'),
    },
    unreachable: {
      icon: images.unReachableIcon(),
      label: t('unreachable'),
    },
    notdeployed: {
      icon: images.notDeployedIcon(),
      label: t('notdeployed'),
    },
    pending: {
      icon: images.pendingStatusIcon(),
      label: t('pending'),
    },
    errored: {
      icon: images.majorStatusIcon(),
      label: t('major'),
    },
  };

  return config[status];
};
