import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Tile,
  Row,
  Button as CarbonButton,
  SkeletonPlaceholder,
  SkeletonText,
} from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import { Loading } from '@carbon/react';

import dateUtils from '../../lib/dates';
import images from '../../images/images';

import { EmptyState } from '../EmptyState/EmptyState';
import { CompactSuggestedTable } from '../SuggestedTable/SuggestedTable';
import PolicyTargetField from '../PolicyTargetField/PolicyTargetField';
import StatsRow from '../../pages/StatsRow';
import GenericStatusField from '../GenericStatusField/GenericStatusField';
import useAnalytics from '../../lib/useAnalytics';
import analyticsData from '../../lib/analyticsEventData';
import { setSessionStorageItem } from '../../lib/utils';
import HealthStatus from '../GatewayStatus/HealthStatus';

import './OverviewTile.scss';

interface Resource {
  resource_id: string;
  name: string;
  updated_at: string;
  [key: string]: any;
}

const getDiscoveryStatusIcon = (status: string) => {
  let icon;

  switch (status) {
    case 'completed':
      icon = images.autoDiscoverLabelIcon();
      break;
    case 'in-progress':
      icon = <Loading active small withOverlay={false} />;
      break;
    case 'pending':
      icon = images.pendingStatusIcon();
      break;
    case 'errored':
      icon = images.criticalStatusIcon();
      break;
    default:
      break;
  }
  return icon;
};

const OverviewTile = (props: {
  resourceType?: string;
  header: string;
  data?: Resource[] | null;
  emptyState: {
    icon: JSX.Element;
    header: string;
    description: string;
    buttonText?: string;
    buttonArrow?: boolean;
    link?: string;
  };
  addText?: string;
  loading?: boolean;
  viewAll?: boolean;
  statsList?: {
    key: string;
    title: string;
    value: number;
    icon?: JSX.Element;
  }[];
  rows?: {
    id: string;
    name: string;
    status: 'Starting' | 'Active' | 'Disabled';
  }[];
  headers?: { key: string; header: string }[];
  permission: boolean;
  serverError: boolean;
}) => {
  const { t } = useTranslation('overviewTile');
  const navigate = useNavigate();
  const { trackButtonClicked } = useAnalytics();
  const {
    header,
    data,
    emptyState,
    addText,
    resourceType,
    loading,
    statsList,
    permission,
    serverError,
    ...rest
  } = props;
  const viewAll = data ? data.length > 0 : false;

  const linkPath = () => {
    switch (resourceType) {
      case 'APPLICATION':
        return {
          link: '/applications',
          analyticsData: analyticsData['Home'].events.registerAppLink.props,
          event: analyticsData['Home'].events.registerAppLink.event,
        };
      case 'POLICY':
        return {
          link: '/connectionAccessPolicies',
          analyticsData: analyticsData['Home'].events.createPolicyLink.props,
          event: analyticsData['Home'].events.createPolicyLink.event,
        };
      case 'ENVIRONMENT':
        return {
          link: '/deploymentEnvironments',
          analyticsData: analyticsData['Home'].events.registerEnvLink.props,
          event: analyticsData['Home'].events.registerEnvLink.event,
        };
      case 'GATEWAY':
        return {
          link: '/gateways',
          analyticsData: analyticsData['Home'].events.registerGatewayLink.props,
          event: analyticsData['Home'].events.registerGatewayLink.event,
        };
      case 'CLOUD':
        return {
          link: '/clouds',
          analyticsData: analyticsData['Home'].events.registerCloudLink.props,
          event: analyticsData['Home'].events.registerCloudLink.event,
        };
      default:
        return {
          link: '/locations',
          analyticsData:
            analyticsData['Home'].events.registerLocationLink.props,
          event: analyticsData['Home'].events.registerLocationLink.event,
        };
    }
  };

  const getNotAuthorizedContent = () => {
    return <GenericStatusField status='notAuthorised'></GenericStatusField>;
  };

  const navigateToAddModal = () => {
    const data = linkPath();
    trackButtonClicked(data?.analyticsData, data?.event);
    navigate(data?.link, {
      state: { resourceType: resourceType, navigateBack: true },
    });
  };

  const getRows = () => {
    // TODO: Add a single if block for environment to add the middle column on design
    // TODO: Add single if block for alerts
    if (resourceType === 'APPLICATION') {
      return (
        Array.isArray(data) &&
        data.map(resource => ({
          name: {
            text: resource.name,
            link: `/applicationDetails?appId=${resource.resource_id}`,
          },
          id: resource.resource_id,
          link: '/',
          group: resource.resourceGroupPermission
            ? resource.resourceGroupName
            : getNotAuthorizedContent(),
          lastUpdated: t('updated', {
            updatedDate: dateUtils.getUserFriendlyDate(
              resource.updated_at,
              false
            ),
          }),
        }))
      );
    } else if (resourceType === 'ENVIRONMENT') {
      return (
        Array.isArray(data) &&
        data.map(resource => ({
          name: {
            text: resource.name,
            link: `/deploymentEnvironmentDetails?deplId=${resource.resource_id}`,
          },
          id: resource.resource_id,
          link: '/',
          type: resource.subtypeName,
          lastUpdated: t('updated', {
            updatedDate: dateUtils.getUserFriendlyDate(
              resource.updated_at,
              false
            ),
          }),
        }))
      );
    } else if (resourceType === 'POLICY') {
      return (
        Array.isArray(data) &&
        data.map((resource, index) => ({
          name: {
            text: resource.name,
            link: `/connectionAccessPolicyDetails?policyId=${resource.resource_id}`,
          },
          id: resource.resource_id,
          link: '/',
          // TODO: 'type' value will come from api, 'label' can be array of policy targets
          toSvc: resource.servicePermission ? (
            <PolicyTargetField
              label={resource.to_svc_name}
              toolTipDirection={index === 2 ? 'top' : 'bottom'}
              type='service'
              to_label={t('toSvc')}
            />
          ) : (
            getNotAuthorizedContent()
          ),
          lastUpdated: t('updated', {
            updatedDate: dateUtils.getUserFriendlyDate(
              resource.updated_at,
              false
            ),
          }),
        }))
      );
    } else if (resourceType === 'LOCATION') {
      return (
        Array.isArray(data) &&
        data.map((resource, index) => ({
          name: {
            text: resource.name,
            link: `/locationDetails?cloudId=${resource.cloud_id}&locationId=${resource.resource_id}`,
          },
          id: resource.resource_id,
          link: '/',
          cloudName: resource.cloud_name,
          lastUpdated: t('updated', {
            updatedDate: dateUtils.getUserFriendlyDate(
              resource.updated_at,
              false
            ),
          }),
        }))
      );
    } else if (resourceType === 'GATEWAY') {
      return (
        Array.isArray(data) &&
        data.map((resource, index) => ({
          name: {
            text: resource.name,
            icon:
              resource.type === 'edge'
                ? images.EdgeGatewayIcon()
                : images.WaypointGatewayIcon(),
            link: `/gatewayDetails?gatewayId=${resource.resource_id}`,
          },
          id: resource.resource_id,
          link: '/',
          health_status: resource.health_status ? (
            <HealthStatus
              status={resource.health_status}
              gatewayName={resource.name}
            />
          ) : (
            ''
          ),
          location: resource.locationPermission
            ? resource.location_name
            : getNotAuthorizedContent(),
        }))
      );
    } else if (resourceType === 'CLOUD') {
      return (
        Array.isArray(data) &&
        data.map((resource, index) => ({
          name: {
            text: resource.name,
            link: `/cloudDetails?cloudId=${resource.resource_id}`,
          },
          id: resource.resource_id,
          link: '/',
          autoDiscover: {
            icon: resource.auto_discover
              ? getDiscoveryStatusIcon(resource.discovery_status)
              : null,
            label: resource.auto_discover
              ? t(`discoveryStatus.${resource.discovery_status}`)
              : t('discoveryStatus.off'),
          },
          lastUpdated: t('updated', {
            updatedDate: dateUtils.getUserFriendlyDate(
              resource.updated_at,
              false
            ),
          }),
        }))
      );
    }
  };

  const handleStatsRowClick = (key: string) => {
    if (resourceType === 'GATEWAY') {
      // setSessionStorageItem('MCNM_VISIBILITY_FLAG', key);
      if (key === 'edge')
        navigate(
          '/gateways?type_name=Mesh edge gateway&type_name=Service Interconnect edge gateway'
        );
      else if (key === 'waypoint')
        navigate('/gateways?type_name=Mesh waypoint');
      else navigate('/gateways');
    } else {
      navigate(linkPath()?.link);
    }
  };

  if (loading) {
    return (
      <Tile className='overview-tile-skeleton'>
        <SkeletonText className='header-skeleton' />
        <div className='stats-row-skeleton'>
          <div className='stat'>
            <SkeletonText className='subheader-text' />
            <SkeletonPlaceholder className='data-placeholder' />
          </div>
          <div className='stat'>
            <SkeletonText className='subheader-text' />
            <SkeletonPlaceholder className='data-placeholder' />
          </div>
          <div className='stat'>
            <SkeletonText className='subheader-text' />
            <SkeletonPlaceholder className='data-placeholder' />
          </div>
        </div>

        <SkeletonText className='table-header-skeleton' />
        <SkeletonPlaceholder className='table-skeleton' />
      </Tile>
    );
  }

  return (
    <Tile className={'overview-tile'} {...rest}>
      <div className={'header-row' + (viewAll ? ' view-all' : '')}>
        <div className='header-text'>{header}</div>
        {viewAll && (
          <RouterLink to={linkPath()?.link}>
            <CarbonButton className='view-all-button' kind='ghost'>
              {t('viewAll')}
            </CarbonButton>
          </RouterLink>
        )}
      </div>
      {!permission ? (
        <div className='empty-state-container'>
          <EmptyState
            icon={images.notAuthorizedSvg()}
            header={t('403Error.403header')}
            description={t('403Error.403description')}
          />
        </div>
      ) : serverError ? (
        <div className='empty-state-container'>
          <EmptyState
            icon={images.error500Icon()}
            header={t('500Error.500header')}
            description={t('500Error.500description')}
          />
        </div>
      ) : data && data.length > 0 ? (
        <div className='overview-tile-content'>
          <StatsRow
            statsList={statsList ?? []}
            onClick={(key: string) => handleStatsRowClick(key)}
          />
          <div className='suggested-text'>{t('suggested')}</div>
          <CompactSuggestedTable rowCount={3} rows={getRows()} />
          <Row className='button-row'>
            {resourceType === 'CLOUD' && (
              <CarbonButton
                className='add-resource-button'
                kind='ghost'
                onClick={navigateToAddModal}
              >
                {addText}
              </CarbonButton>
            )}
          </Row>
        </div>
      ) : (
        <div className='empty-state-container'>
          <EmptyState
            icon={emptyState.icon}
            header={emptyState.header}
            description={emptyState.description}
            buttonText={resourceType === 'CLOUD' ? emptyState.buttonText : ''}
            click={navigateToAddModal}
            link={resourceType === 'CLOUD' ? emptyState.link : ''}
          />
        </div>
      )}
    </Tile>
  );
};

export default OverviewTile;
