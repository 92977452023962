import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AxiosError } from 'axios';
import {
  SkeletonPlaceholder,
  Tab,
  Tabs,
  TooltipDefinition,
} from 'carbon-components-react';
import CustomSidePanel from '../../../CustomSidePanel/CustomSidePanel';
import SidePanelDetailsCard from '../../../CustomSidePanel/SidePanelDetailsComponent/SidePanelDetailsCard';
import SidePanelDetailsComponent from '../../../CustomSidePanel/SidePanelDetailsComponent/SidePanelDetailsComponent';
import SidePanelTableComponent from '../../../CustomSidePanel/TableComponent/SidePanelTableComponent';
import { NodeSidePaneDetailsComponentProps } from '../config';
import {
  ApplicationData,
  DeploymentEnvDetailsNameSpaceAndSecurityTableData,
  DeploymentEnvironment,
  NetworkSegments,
  ResourceGroup,
} from '../../../../models/master';
import IconWithToolTip from '../../../IconWithToolTip/IconWithToolTip';
import images from '../../../../images/images';
import {
  getResourceGroup,
  getResourceGroups,
} from '../../../../controllers/resourceGroupApi';
import {
  getDeploymentEnv,
  getDeploymentEnvSubtypes,
  getPartitions,
} from '../../../../controllers/deploymentEnv';
import {
  getApplications,
  getApplicationsDeployments,
} from '../../../../controllers/applicationApis';
import SidePanelEmptyState from '../../../../components/CustomSidePanel/SidePanelEmptyState/SidePanelEmptyState';
import { getNetworkSegments } from '../../../../controllers/networksegmentsApi';

import './DeploymentEnvironments.scss';
import {
  DEFAULT_NETWORK_SEGMENT_ID,
  defaultNetworkSegmentObject,
} from '../../../../lib/constants';

const defaultPermissionMap = {
  appDeployments: true,
  partitions: true,
  deploymentEnv: true,
  resourceGroup: true,
};

const defaultServerErrorMap = {
  appDeployments: '',
  partitions: '',
  deploymentEnv: '',
};

const DeploymentEnvironmentDetails: React.FC<
  NodeSidePaneDetailsComponentProps
> = ({
  node,
  open,
  onClose,
  sidePanelWidth,
  handleSidePanelWidth,
  handleHeaderWidth,
  initialHeaderWidth,
  visibility = 'managed',
  headerWidth,
}) => {
  const { t } = useTranslation('topologySidePanelDeploymentEnv');

  const [loading, setLoading] = useState(false);
  const [deploymentEnvData, setDeploymentEnvData] =
    useState<DeploymentEnvironment | null>(null);
  const [partitionData, setPartitionData] = useState<
    DeploymentEnvDetailsNameSpaceAndSecurityTableData[] | null
  >(null);
  const [resourceGroup, setResourceGroup] = useState<ResourceGroup | null>(
    null
  );
  const [networkSegments, setNetworkSegments] = useState<
    NetworkSegments[] | null
  >(null);
  const [resourceGroupList, setResourceGroupList] = useState<
    ResourceGroup[] | null
  >(null);
  const [deployments, setDeployments] = useState<any | null>(null);
  const [depEnvSubTypes, setDepEnvSubTypes] = useState<any>(null);
  const [appsData, setAppsData] = useState<ApplicationData[] | null>(null);
  const [permissionMap, setPermissionMap] = useState(defaultPermissionMap);
  const [serverErrorMap, setServerErrorMap] = useState(defaultServerErrorMap);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const type = node?.resource_id?.split('-')[0];
        let deploymentEnv: DeploymentEnvironment | null = null;
        try {
          deploymentEnv = await getDeploymentEnv(node.uniqueId);
          setDeploymentEnvData(deploymentEnv);
        } catch (error) {
          const err = error as AxiosError;
          if (
            err?.response?.status === 403 &&
            err?.response?.statusText === 'Forbidden'
          ) {
            setPermissionMap(permissionMap => ({
              ...permissionMap,
              deploymentEnv: false,
            }));
          }

          const errorCode = err.response!?.status;
          if (errorCode >= 500) {
            setServerErrorMap(permissionMap => ({
              ...permissionMap,
              deploymentEnv: errorCode.toString(),
            }));
          }
        }

        const array = [
          getDeploymentEnvSubtypes(),
          getApplications(),
          getResourceGroups(true),
        ];

        const results = await Promise.allSettled(array);

        if (Array.isArray(results)) {
          for (let i = 0; i < results.length; i++) {
            if (results[i].status === 'fulfilled') {
              const response = (results as any)[i].value;

              if (i === 0) {
                setDepEnvSubTypes(response);
              }

              if (i === 1) {
                setAppsData(response);
              }

              if (i === 2) {
                setResourceGroupList(response?.resource_groups);
              }
            }
          }
        }

        if ((type === 'node' || type === 'vpc') && node.uniqueId) {
          try {
            const deployments: any = await getApplicationsDeployments();
            setDeployments(deployments);
          } catch (error) {
            const err = error as AxiosError;
            if (
              err?.response?.status === 403 &&
              err?.response?.statusText === 'Forbidden'
            ) {
              setPermissionMap(permissionMap => ({
                ...permissionMap,
                appDeployments: false,
              }));
            }

            const errorCode = err.response!?.status;
            if (errorCode >= 500) {
              setServerErrorMap(permissionMap => ({
                ...permissionMap,
                appDeployments: errorCode.toString(),
              }));
            }
          }
        }

        if ((type === 'cluster' || type === 'vpc') && node.uniqueId) {
          try {
            const partition: {
              partitions: DeploymentEnvDetailsNameSpaceAndSecurityTableData[];
            } | null = await getPartitions(node.uniqueId, visibility);
            setPartitionData(
              partition && partition?.partitions.length > 0
                ? partition?.partitions
                : []
            );
          } catch (error) {
            const err = error as AxiosError;
            if (
              err?.response?.status === 403 &&
              err?.response?.statusText === 'Forbidden'
            ) {
              setPermissionMap(permissionMap => ({
                ...permissionMap,
                partitions: false,
              }));
            }

            const errorCode = err.response!?.status;
            if (errorCode >= 500) {
              setServerErrorMap(permissionMap => ({
                ...permissionMap,
                partitions: errorCode.toString(),
              }));
            }
          }
        }

        if (type === 'cluster') {
          try {
            const networkSegment = await getNetworkSegments();
            setNetworkSegments(networkSegment);
          } catch (error) {
            const err = error as AxiosError;
            if (
              err?.response?.status === 403 &&
              err?.response?.statusText === 'Forbidden'
            ) {
              setPermissionMap(permissionMap => ({
                ...permissionMap,
                networkSegments: false,
              }));
            }
          }
        }

        if (deploymentEnv) {
          try {
            const resourceGroup: ResourceGroup = await getResourceGroup(
              deploymentEnv.resource_group_id
            );
            setResourceGroup(resourceGroup);
          } catch (error) {
            const err = error as AxiosError;
            if (
              err?.response?.status === 403 &&
              err?.response?.statusText === 'Forbidden'
            ) {
              setPermissionMap(permissionMap => ({
                ...permissionMap,
                resourceGroup: false,
              }));
            }
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleLocationClick = (data: DeploymentEnvironment | null) => {
    window.open(
      window.location.origin +
        process.env.PUBLIC_URL +
        `/locationDetails?cloudId=${data?.cloud_id}&locationId=${data?.location_id}`
    );
  };

  const handleCloudClick = (data: DeploymentEnvironment | null) => {
    window.open(
      window.location.origin +
        process.env.PUBLIC_URL +
        `/cloudDetails?cloudId=${data?.cloud_id}`
    );
  };

  const handleGatewayClick = (data: DeploymentEnvironment | null) => {
    window.open(
      window.location.origin +
        process.env.PUBLIC_URL +
        `/gatewayDetails?gatewayId=${data?.gateway_id}`
    );
  };

  const formatEnvDetailsData = (headers: any) => {
    const gatewayData = node?.children?.filter(
      childComponent => childComponent.uniqueId === node.gateway_id
    );
    const selectedDepEnvSubType = depEnvSubTypes?.filter(
      (depEnv: { type_code: string | undefined }) =>
        depEnv.type_code === deploymentEnvData?.subtype
    );
    const type =
      selectedDepEnvSubType && selectedDepEnvSubType?.length > 0
        ? selectedDepEnvSubType[0].type_name
        : '';
    const formattedData = [
      {
        label: t('type'),
        value: type,
        valueType: 'displayField',
        notAuthorized: false,
      },
      {
        label: t('autodiscover'),
        value: deploymentEnvData?.auto_discover ? (
          <div className='autodiscoverValue'>
            <div className='icon'>{images.autoDiscoverLabelIcon()}</div>
            <div className='text'>{headers.partitionTitle}</div>
          </div>
        ) : (
          <div className='autodiscoverValue'>{t('off')}</div>
        ),
        valueType: 'displayField',
        notAuthorized: !permissionMap['deploymentEnv'],
      },
      {
        label: t('resourceGroup'),
        value: resourceGroup?.name,
        valueType: 'displayField',
        notAuthorized:
          !permissionMap['deploymentEnv'] || !permissionMap['resourceGroup'],
      },
      {
        label: t('cloud'),
        value: deploymentEnvData?.cloud_name ?? '',
        valueType: 'link',
        linkCallback: () => handleCloudClick(deploymentEnvData),
        notAuthorized: !permissionMap['deploymentEnv'],
      },
      {
        label: t('organization'),
        value: deploymentEnvData?.organization,
        valueType: 'displayField',
        notAuthorized: !permissionMap['deploymentEnv'],
      },
      {
        label: t('location'),
        value: deploymentEnvData?.location_name,
        valueType: 'link',
        linkCallback: () => handleLocationClick(deploymentEnvData),
        notAuthorized: !permissionMap['deploymentEnv'],
      },

      {
        label: t('gateway'),
        value:
          gatewayData && gatewayData.length > 0 ? gatewayData[0]?.name : null,
        valueType: 'link',
        linkCallback: () => handleGatewayClick(deploymentEnvData),
        notAuthorized: false,
      },
      {
        label: t('labels'),
        value: deploymentEnvData?.labels,
        valueType: 'labelsList',
        notAuthorized: !permissionMap['deploymentEnv'],
      },
    ];
    if (deploymentEnvData?.type === 'cluster')
      formattedData.splice(6, 0, {
        label: t('multizone'),
        value: deploymentEnvData?.is_multi_zone ?? false ? t('yes') : t('no'),
        valueType: 'displayField',
        notAuthorized: !permissionMap['deploymentEnv'],
      });

    return formattedData;
  };

  const getAppsList = (deployedApps: any) => {
    return (
      <span className='listingContainer'>
        {deployedApps.map((app: any, index: number) => (
          <span className='eachApp'>{app.name}</span>
        ))}
      </span>
    );
  };

  const renderAppsDetails = (deployedApps: any) => {
    if (deployedApps?.length > 0) {
      return (
        <TooltipDefinition
          tooltipText={getAppsList(deployedApps)}
          direction='bottom'
        >
          {deployedApps.length}
        </TooltipDefinition>
      );
    }
    return 0;
  };

  const getFormattedPartionData = () => {
    const rowsData: {
      name: JSX.Element | undefined;
      deployedApps: number | JSX.Element;
      networkSegmentName: JSX.Element | undefined;
      deployedAppsCount: number;
    }[] = [];
    partitionData?.map(eachPartition => {
      const partition = node.children?.find(
        eachChild =>
          eachChild?.resource_id === eachPartition.resource_id &&
          eachChild._type === 'partition'
      );
      const deployedApps = partition
        ? partition.children?.filter(
            eachChild =>
              eachChild?.partition_id === eachPartition.resource_id &&
              eachChild._type === 'application'
          )
        : [];

      const partitionLink =
        eachPartition?.type === 'securitygroup'
          ? `/partitionDetails?depEnvId=${eachPartition?.vpc_id}&partitionId=${eachPartition?.resource_id}`
          : `/partitionDetails?depEnvId=${eachPartition?.cluster_id}&partitionId=${eachPartition?.resource_id}`;
      rowsData.push({
        name: (
          <div className='sidepanel-table-link'>
            <Link
              to={partitionLink}
              target='_blank'
              className='no-underline-link hyperLink'
            >
              {eachPartition.name}
            </Link>
          </div>
        ),
        deployedApps: renderAppsDetails(deployedApps),
        networkSegmentName: getNwSgName(partition?.network_segment_id),
        deployedAppsCount: deployedApps?.length ?? 0,
      });
      return null;
    });
    return rowsData;
  };

  const getNwSgName = (id: any) => {
    let name: any = '';
    if (id === DEFAULT_NETWORK_SEGMENT_ID) {
      name = defaultNetworkSegmentObject?.name;
      return name;
    } else {
      name =
        networkSegments?.find((item: any) => item?.resource_id === id)?.name ??
        '—';
      return (
        <Link
          to={`/networkSegmentDetails?nwSegId=${id}`}
          target='_blank'
          className='no-underline-link hyperLink'
        >
          {name}
        </Link>
      );
    }
  };

  const getFormattedAppData = (filteredAppsData: any) => {
    const rowsData: {
      name: JSX.Element;
      resourceGroup: string;
    }[] = [];
    filteredAppsData?.map((eachData: any) => {
      const deployment = deployments?.find(
        (deployment: any) => deployment.resource_id === eachData.uniqueId
      );
      const appData = appsData?.find(
        (app: ApplicationData) => app.resource_id === deployment.application_id
      );
      const resourceGroupData =
        Array.isArray(resourceGroupList) && appData
          ? resourceGroupList?.find(
              resource => resource.resource_id === appData.resource_group_id
            )
          : null;

      const appLink = `/ApplicationDetails?appId=${appData?.resource_id}`;

      rowsData.push({
        name: (
          <div className='sidepanel-table-link'>
            <Link
              to={appLink}
              target='_blank'
              className='no-underline-link hyperLink'
            >
              {eachData.name}
            </Link>
          </div>
        ),
        resourceGroup: resourceGroupData ? resourceGroupData?.name : '',
      });
      return null;
    });
    return rowsData;
  };

  const getHeaderTitle = () => {
    if (deploymentEnvData?.is_discovered) {
      return (
        <div className='header-title'>
          <span>
            {images.deploymentEnvIcon()} {node.name}{' '}
          </span>
          <span className='page-title-icon'>
            <IconWithToolTip
              icon={images.AutoDiscoverdLockIcon()}
              iconDescription={t('autoDiscoveredAccessLimited')}
            />
          </span>
        </div>
      );
    }

    if (node) {
      return (
        <div>
          {images.deploymentEnvIcon()} {node.name}
        </div>
      );
    }

    return '';
  };

  const getLink = () => {
    if (node) {
      return `/deploymentEnvironmentDetails?deplId=${node.uniqueId}` ?? ' ';
    }

    return '';
  };
  const getDeploymentEmptyState = () => {
    if (node) {
      const type = node.uniqueId.split('-')[0];
      if (type === 'cluster') {
        return (
          <SidePanelEmptyState
            icon={images.namespaceEmptyLarge()}
            header={t('emptyState.namespace.header')}
            message={t('emptyState.namespace.message')}
            btnText={t('emptyState.namespace.btnText')}
            btnUrl={`/deploymentEnvironmentDetails?deplId=${node.uniqueId}`}
            resourceType='ENVIRONMENT'
            notAuthorized={!permissionMap['partitions']}
            error500={!!serverErrorMap['partitions']}
          />
        );
      } else if (type === 'vpc') {
        return (
          <SidePanelEmptyState
            icon={images.namespaceEmptyLarge()}
            header={t('emptyState.securityGroup.header')}
            message={t('emptyState.securityGroup.message')}
            btnText={t('emptyState.securityGroup.btnText')}
            btnUrl={`/deploymentEnvironmentDetails?deplId=${node.uniqueId}`}
            resourceType='ENVIRONMENT'
            notAuthorized={!permissionMap['partitions']}
            error500={!!serverErrorMap['partitions']}
          />
        );
      }
    }
  };
  const getDeplymentTypeTitle = () => {
    if (node) {
      const type = node.uniqueId.split('-')[0];
      if (type === 'cluster')
        return {
          typeHeader: t('clusterDetails'),
          partitionTitle: t('namespace'),
          showAppTable: false,
          showPartitionTable: true,
        };
      else if (type === 'vpc')
        return {
          typeHeader: t('vpcDetails'),
          partitionTitle: t('securityGroup'),
          showAppTable: true,
          showPartitionTable: true,
        };
      else if (type === 'node')
        return {
          typeHeader: t('nodeDetails'),
          partitionTitle: t('application'),
          showAppTable: true,
          showPartitionTable: false,
        };
    }

    return { typeHeader: '', partitionTitle: '', showAppTable: false };
  };

  const getAppToBeShown = () => {
    if (node && node?.children && Array.isArray(node?.children)) {
      const appWithoutPartition = node?.children.filter(
        childNode =>
          childNode._type === 'application' && childNode?.partition_id === ''
      );
      return appWithoutPartition.length ? appWithoutPartition : null;
    }
    return null;
  };

  const title = getHeaderTitle();
  const link = getLink();
  const subheaders = getDeplymentTypeTitle();
  const appWithoutPartition = getAppToBeShown();

  return (
    <div className='topology-dep-env-details-container'>
      <CustomSidePanel
        open={open}
        onClose={onClose}
        title={title}
        link={link}
        sidePanelWidth={sidePanelWidth}
        handleSidePanelWidth={handleSidePanelWidth}
        handleHeaderWidth={handleHeaderWidth}
        initialHeaderWidth={initialHeaderWidth}
        headerWidth={headerWidth}
      >
        <Tabs>
          <Tab id='topology-details-tab-1' label={t('details')}>
            <SidePanelDetailsComponent title={subheaders.typeHeader}>
              {loading && (
                <div className='skeleton-container'>
                  <SkeletonPlaceholder className='dep-env-details-skeleton' />
                  <SkeletonPlaceholder className='dep-env-details-skeleton' />
                </div>
              )}
              {!loading && (
                <SidePanelDetailsCard
                  data={formatEnvDetailsData(subheaders)}
                  notAuthorized={!permissionMap['deploymentEnv']}
                  error500={!!serverErrorMap['deploymentEnv']}
                />
              )}
            </SidePanelDetailsComponent>

            {subheaders.showAppTable ? (
              <SidePanelDetailsComponent
                title={`${t('application')} ${
                  Array.isArray(appWithoutPartition)
                    ? '(' + appWithoutPartition.length + ')'
                    : ''
                }`}
              >
                {loading && (
                  <div className='skeleton-container'>
                    <SkeletonPlaceholder className='dep-env-details-skeleton' />
                    <SkeletonPlaceholder className='dep-env-details-skeleton' />
                  </div>
                )}
                {!loading &&
                appWithoutPartition &&
                appWithoutPartition.length > 0 &&
                deployments &&
                resourceGroupList ? (
                  <SidePanelTableComponent
                    headers={[
                      {
                        key: 'name',
                        header: t('name'),
                      },
                      {
                        key: 'resourceGroup',
                        header: t('applicationGroup'),
                      },
                    ]}
                    rows={getFormattedAppData(appWithoutPartition)}
                  />
                ) : (
                  !loading && (
                    <SidePanelEmptyState
                      icon={images.SidePanelNoApplicationIcon()}
                      header={t('emptyState.application.header')}
                      message={t('emptyState.application.message')}
                      btnText={t('emptyState.application.btnText')}
                      btnUrl={`/deploymentEnvironmentDetails?deplId=${node.uniqueId}`}
                      resourceType='APPLICATION'
                      notAuthorized={!permissionMap['appDeployments']}
                      error500={!!serverErrorMap['appDeployments']}
                    />
                  )
                )}
              </SidePanelDetailsComponent>
            ) : null}
            {subheaders?.showPartitionTable ? (
              <SidePanelDetailsComponent
                title={`${subheaders?.partitionTitle} ${
                  Array.isArray(partitionData)
                    ? '(' + partitionData.length + ')'
                    : ''
                }`}
              >
                {loading && (
                  <div className='skeleton-container'>
                    <SkeletonPlaceholder className='dep-env-details-skeleton' />
                    <SkeletonPlaceholder className='dep-env-details-skeleton' />
                  </div>
                )}
                {!loading && partitionData && partitionData.length > 0 ? (
                  <SidePanelTableComponent
                    headers={[
                      {
                        key: 'name',
                        header: t('name'),
                      },
                      {
                        key:
                          deploymentEnvData?.type === 'cluster'
                            ? 'networkSegmentName'
                            : 'deployedApps',
                        header:
                          deploymentEnvData?.type === 'cluster'
                            ? t('networkSegment')
                            : t('applicationDeployments'),
                      },
                    ]}
                    rows={getFormattedPartionData()}
                  />
                ) : (
                  !loading && getDeploymentEmptyState()
                )}
              </SidePanelDetailsComponent>
            ) : null}
          </Tab>
        </Tabs>
      </CustomSidePanel>
    </div>
  );
};

export default DeploymentEnvironmentDetails;
