import { InlineNotification } from 'carbon-components-react';
import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import NarrowTearsheetComponent from '../../components/NarrowTearsheet/NarrowTearsheet';
import { NotificationContext } from '../../components/Notifications/Context/NotificationProvider';
import { updatePartition } from '../../controllers/deploymentEnv';
import { getResourceGroups } from '../../controllers/resourceGroupApi.js';
import { getCloud } from '../../controllers/cloudApis';
import { getSecrets } from '../../controllers/tenantApis';
import { removeExtraSpace } from '../../lib/utils';
import { DeploymentEnvironment, Cloud, Secret } from '../../models/master.js';
import { getConditionalSecrets } from '../../lib/utils';
import { AxiosError } from 'axios';
import EditPartitionForm from './EditPartitionForm';

import './EditPartititon.scss';
import useAnalytics from '../../lib/useAnalytics';

const defaultFormValue = {
  name: {
    value: '',
    error: false,
    errorMessage: '',
  },
  // resourceGroup: {
  //   value: null,
  //   error: false,
  //   errorMessage: '',
  // },
  appResourceGroup: {
    value: null,
    error: false,
    errorMessage: '',
  },
  labels: {
    value: [],
    error: false,
    errorMessage: '',
  },
  // the key in the secrets manager for the credentials to this node/cluster
  credentials_key: {
    id: '',
    name: '',
    error: false,
    errorMessage: '',
  },
  // api_end_point: {
  //   value: '',
  //   error: false,
  // },
  auto_discover: {
    value: false,
    error: false,
    errorMessage: '',
  },
};

const requiredFields = ['name'];

interface Props {
  open: boolean;
  partitionData: any;
  depEnvData: DeploymentEnvironment | null;
  onClose: () => void;
  refreshDetailsData: () => void;
  mode?: 'ADD' | 'EDIT' | 'CONFIGURE';
  handleNameSpace?: any;
}

const EditPartition: React.FC<Props> = ({
  open,
  onClose,
  refreshDetailsData,
  partitionData,
  depEnvData,
  mode,
  handleNameSpace,
}) => {
  const [formData, setFormData] = useState<any>(defaultFormValue);
  const [initialFormDataEdit, setInitialFormDataEdit] =
    useState<any>(defaultFormValue);
  const [resourceGroupList, setResourceGroupList] = useState<any>([]);
  const [showFailNotification, toggleFailNotification] = useState(false);
  const [secrets, setSecrets] = useState<Secret[] | null>(null);
  const [selectedCloud, setSelectedCloud] = useState<Cloud | null>(null);
  const [errorType, setErrorType] = useState('default');
  const [subTitleErrorMsg, setSubTitleErrorMsg] = useState('');
  const { t } = useTranslation('editPartition');
  const navigate = useNavigate();
  const notification = useContext(NotificationContext);
  const [loading, setLoading] = useState(false);

  const { trackButtonClicked } = useAnalytics();

  const fetchSecrets = async () => {
    try {
      const secrets = await getSecrets();

      const secretValues = getConditionalSecrets(
        secrets,
        selectedCloud && selectedCloud.type,
        partitionData?.type
      );
      setSecrets(secretValues);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (partitionData) {
      getCloud(partitionData?.cloud_id).then(response => {
        setSelectedCloud(response);
      });
      getResourceGroups()
        .then(response => {
          setResourceGroupList(response.resource_groups);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, []);

  useEffect(() => {
    if (selectedCloud) {
      fetchSecrets();
    }
  }, [selectedCloud]);

  const isFormValid = () => {
    if (
      formData.auto_discover.value === true &&
      formData.credentials_key.id === '' &&
      partitionData.is_discovered === false &&
      mode !== 'CONFIGURE'
    ) {
      return false;
    }

    // if (
    //   formData.auto_discover.value === true &&
    //   formData.api_end_point.value === ''
    // ) {
    //   return false;
    // }
    if (
      ((formData.auto_discover.value === true &&
        formData.appResourceGroup.value === '') ||
        (formData.auto_discover.value === true &&
          formData.appResourceGroup.value === null)) &&
      mode !== 'CONFIGURE'
    ) {
      return false;
    }
    for (const field of requiredFields) {
      const value = formData[field].value;
      const trimmedValue = typeof value === 'string' ? value.trim() : value;
      if (checkFieldValidation(field, trimmedValue)) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    if (mode === 'EDIT' && partitionData) {
      const updatedFormData = {
        name: {
          ...formData.name,
          value: partitionData.name,
        },
        // resourceGroup: {
        //   ...formData.resourceGroup,
        //   value: resourceGroupList.filter(
        //     (rg: any) => rg.resource_id === partitionData?.resource_group_id
        //   )[0],
        // },
        labels: {
          ...formData.labels,
          value: Array.isArray(partitionData.labels)
            ? [...partitionData.labels]
            : null,
        },
        credentials_key: {
          ...formData.credentials_key,
          id: partitionData?.credentials_key ?? '',
          name: partitionData?.credentials_key ?? '',
        },
        // api_end_point: {
        //   ...formData.api_end_point,
        //   value: partitionData?.api_end_point ?? '',
        // },
        auto_discover: {
          ...formData.auto_discover,
          value: partitionData?.auto_discover,
        },
        appResourceGroup: {
          ...formData.appResourceGroup,
          value: resourceGroupList.filter(
            (rg: any) => rg.resource_id === partitionData?.vendor_resource_id
          )[0],
        },
      };
      setInitialFormDataEdit(updatedFormData);
      setFormData(updatedFormData);
    }

    if (mode === 'CONFIGURE' && partitionData) {
      const updatedFormData = {
        name: {
          ...formData.name,
          value: partitionData.name,
        },
        // resourceGroup: {
        //   ...formData.resourceGroup,
        //   value: resourceGroupList.filter(
        //     (rg: any) => rg.resource_id === partitionData?.resource_group_id
        //   )[0],
        // },
        labels: {
          ...formData.labels,
          value: Array.isArray(partitionData.labels)
            ? [...partitionData.labels]
            : null,
        },
        credentials_key: {
          ...formData.credentials_key,
          id: partitionData?.credentials_key ?? '',
          name: partitionData?.credentials_key ?? '',
        },
        // api_end_point: {
        //   ...formData.api_end_point,
        //   value: partitionData?.api_end_point ?? '',
        // },
        auto_discover: {
          ...formData.auto_discover,
          value: true,
        },
        appResourceGroup: {
          ...formData.appResourceGroup,
          value: resourceGroupList.filter(
            (rg: any) => rg.resource_id === partitionData?.vendor_resource_id
          )[0],
        },
      };
      setInitialFormDataEdit(updatedFormData);
      setFormData(updatedFormData);
    }
  }, [mode, partitionData, resourceGroupList]);

  const checkFieldValidation = (name: string, value: any) => {
    let errorMessage = '';
    switch (name) {
      case 'name':
        errorMessage = !value ? t('validation.name.required') : '';
        break;
      case 'resourceGroup':
        errorMessage = !value ? t('validation.resourceGroup.required') : '';
        break;
      case 'credentials_key':
        errorMessage =
          value?.id === '' && formData.auto_discover.value === true
            ? t('validation.secret.required')
            : '';
        break;
    }
    return errorMessage;
  };

  const handleOnChange = (name: string, value: any) => {
    const errorMessage = checkFieldValidation(name, value);
    if (name === 'credentials_key') {
      setFormData({
        ...formData,
        [name]: {
          id: value?.name === null ? '' : value?.name ?? '',
          name: value?.name ?? '',
          error: formData.auto_discover && value === '',
          errorMessage: t('credentialsKeyEmpty'),
        },
      });
    } else
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: {
          value,
          error: !!errorMessage,
          errorMessage,
        },
      }));
  };

  const handleSubmit = async () => {
    trackButtonClicked({
      CTA: 'Save changes',
      action:
        partitionData?.type === 'namespace'
          ? 'Save changes button clicked from edit namespace modal'
          : 'Save changes button clicked from edit security group modal',
    });
    try {
      setLoading(true);
      const modifiedPartitionData = {
        name: removeExtraSpace(formData?.name?.value),
        credentials_key: partitionData.credentials_key,
        // api_end_point:
        //   formData?.auto_discover?.value === true
        //     ? formData?.api_end_point?.value
        //     : '',
        labels: formData?.labels?.value,
        // resource_group_id: formData?.resourceGroup?.value.resource_id,
        vendor_resource_id: partitionData.vendor_resource_id,
        auto_discover: formData?.auto_discover?.value,
        discovered_labels: partitionData.discovered_labels,
        is_discovered: partitionData.is_discovered,
        unmanaged: partitionData.unmanaged,
      };

      if (!partitionData.is_discovered && formData?.auto_discover?.value) {
        modifiedPartitionData.credentials_key =
          formData?.credentials_key?.id ?? '';
      }

      const partition = await updatePartition(
        partitionData?.type === 'namespace' ? 'cluster' : 'vpc',
        partitionData?.type,
        partitionData?.cloud_id,
        partitionData?.type === 'namespace'
          ? partitionData?.cluster_id
          : partitionData?.vpc_id,
        partitionData?.resource_id,
        modifiedPartitionData
      );

      // Trigger success toastbar
      notification.onTrigger('TOAST', {
        title: t('successNotification.title'),
        subtitle: t('successNotification.subtitle', {
          partitionType:
            partitionData?.type === 'namespace'
              ? t('namespace')
              : t('securityGroup'),
        }),
        // action: t('successNotification.button'),
      });
      // close modal and fetch new data in parent
      // TODO: navigate to card view when functionality is added
      mode === 'CONFIGURE' && handleNameSpace(partition);
      refreshDetailsData();
    } catch (error: any) {
      console.log(error);
      const err = error as AxiosError;
      toggleFailNotification(true);
      if (err.response?.status === 403) {
        setErrorType('auth');
        setSubTitleErrorMsg(t('failureNotification.authSubtitle'));
      } else {
        setErrorType('default');
        setSubTitleErrorMsg(t('failureNotification.subtitle'));
      }

      const errorMessage: string =
        error.response !== undefined
          ? error.response['customErrorMessage']
          : '';
      errorMessage.length > 0 && setSubTitleErrorMsg(errorMessage);
      toggleFailNotification(true);
      // We added this project.reject to prevent the modal closing when error occured.
      // But in latest version of '@carbon/ibm-products' the below line is not required and is throwing error.
      // return Promise.reject(() => console.log(error));
    } finally {
      setLoading(false);
    }
  };

  const enableSaveButton = () => {
    if (JSON.stringify(initialFormDataEdit) !== JSON.stringify(formData)) {
      return isFormValid();
    } else if (mode === 'CONFIGURE') {
      return true;
    } else return false;
  };

  return (
    <NarrowTearsheetComponent
      className={`edit-partition-tearsheet ${mode?.toLowerCase()}`}
      title={
        mode !== 'CONFIGURE'
          ? t('title', { partitionType: partitionData?.type })
          : t('configuresheet.title', {
              partitionType:
                partitionData?.type.charAt(0).toUpperCase() +
                partitionData?.type.slice(1),
            })
      }
      description={
        mode !== 'CONFIGURE'
          ? t('description', { partitionType: partitionData?.type })
          : t('configuresheet.description', {
              partitionType: partitionData?.type,
            })
      }
      open={open}
      actions={[
        {
          kind: 'primary',
          label: t('submitButtonText'),
          onClick: () => handleSubmit(),
          disabled: !enableSaveButton(),
          loading: loading,
        },
        {
          kind: 'secondary',
          label: t('cancelButtonText'),
          onClick: () => onClose(),
        },
      ]}
    >
      {showFailNotification && (
        <InlineNotification
          onClose={() => toggleFailNotification(false) as any}
          kind={'error'}
          title={
            errorType === 'default'
              ? (t('failureNotification.title', {
                  partitionType: partitionData.type,
                }) as string)
              : (t('failureNotification.authTitle') as string)
          }
          subtitle={subTitleErrorMsg}
        />
      )}
      <EditPartitionForm
        formData={formData}
        onChange={handleOnChange}
        resourceGroupList={resourceGroupList}
        partitionType={partitionData?.type}
        secrets={secrets}
        partitionData={partitionData}
        mode={mode}
      />
    </NarrowTearsheetComponent>
  );
};

export default EditPartition;
