import { ComponentType } from 'react';
import { Location, Resouce, ResouceType } from '../types';
import GatewayTooltip from './Gateway/GatewayTooltip';
import ApplicationTooltip from './Application/ApplicationTooltip';
import DeploymentEnvironmentTooltip from './DeploymentEnvironment/DeploymentEnvironmentTooltip';
import LocationTooltip from './Location/LocationTooltip';
import PartitionTooltip from './Partition/PartitionTooltip';
import DisabledTooltip from './Disabled/DisabledTooltip';

export interface ComponentProps {
  node: Location | Resouce;
}

export interface ComponentConfig {
  component: ComponentType<ComponentProps> | null;
}

export const getComponentConfig = (
  type: 'location' | ResouceType,
  label: string | undefined
) => {
  if (label && label === 'Not Authorized') {
    return {
      component: DisabledTooltip,
    };
  }

  const config = {
    location: {
      component: LocationTooltip,
    },
    deployment_env: {
      component: DeploymentEnvironmentTooltip,
    },
    application: {
      component: ApplicationTooltip,
    },
    service: {
      component: null,
    },
    gateway: {
      component: GatewayTooltip,
    },
    partition: {
      component: PartitionTooltip,
    },
  };

  return config[type] as ComponentConfig;
};
