import React, { useState, useEffect, useRef } from 'react';
import { SidePanel } from '@carbon/ibm-products';
import { Launch16, Link16 } from '@carbon/icons-react';
import { Link } from 'react-router-dom';

import { ReactComponent as CloudAlertingSvg } from './icons/cloudAlerting.svg';

import './CustomSidePanel.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  onClose: () => void;
  title?: string | React.ReactNode;
  link?: string;
  children?: React.ReactNode | React.ReactNode[];
  customTitle?: React.ReactNode;
  sidePanelWidth: string;
  handleSidePanelWidth: (width: string) => void;
  handleHeaderWidth: (width: number) => void;
  initialHeaderWidth: number | null;
  headerWidth: number | null;
}

const CustomSidePanel: React.FC<Props> = ({
  open = false,
  onClose,
  title = '',
  link = '',
  children,
  customTitle,
  sidePanelWidth,
  handleSidePanelWidth,
  handleHeaderWidth,
  initialHeaderWidth,
  headerWidth,
}) => {
  const maxWidth: string = `640px`;
  const minWidth: string = `320px`;
  const resizableElement: any = useRef();
  const dragBar: any = useRef();
  const { t } = useTranslation('customSidePanel');
  const [initialPos, setInitialPos] = useState<number | null>(0);
  const [initialSize, setInitialSize] = useState<number | null>(null);

  useEffect(() => {
    document.addEventListener(
      'dragover',
      function (event) {
        event.preventDefault();
      },
      false
    );
    resizableElement.current.style.width = sidePanelWidth;
    dragBar.current.style.right = sidePanelWidth;
    if (initialHeaderWidth != null)
      handleHeaderWidth(
        initialHeaderWidth - parseInt(resizableElement.current.style.width)
      );
    return () => {
      document.removeEventListener(
        'dragover',
        function (event) {
          event.preventDefault();
        },
        false
      );
    };
  }, []);

  useEffect(() => {
    document.addEventListener('mousemove', resize, false);
    document.addEventListener('mouseup', stopDrag, false);
  }, [initialPos, initialSize]);

  const resize = (e: any) => {
    if (!resizableElement.current) return;
    if (resizableElement.current != null && initialPos != null) {
      if (
        parseInt(`${initialSize}`) + parseInt(`${-e.clientX + initialPos}`) >=
        640
      ) {
        resizableElement.current.style.width = maxWidth;
        dragBar.current.style.right = maxWidth;
      } else if (
        parseInt(`${initialSize}`) + parseInt(`${-e.clientX + initialPos}`) <=
        320
      ) {
        resizableElement.current.style.width = minWidth;
        dragBar.current.style.right = minWidth;
      } else {
        resizableElement.current.style.width = `${
          parseInt(`${initialSize}`) + parseInt(`${-e.clientX + initialPos}`)
        }px`;
        dragBar.current.style.right = `${
          parseInt(`${initialSize}`) + parseInt(`${-e.clientX + initialPos}`)
        }px`;
      }
      if (headerWidth != null)
        handleHeaderWidth(
          window.innerWidth -
            48 -
            parseInt(resizableElement.current.style.width)
        );
    }
  };

  function stopDrag() {
    if (!resizableElement.current) return;
    handleSidePanelWidth(resizableElement.current.style.width);
    document.removeEventListener('mousemove', resize, false);
    document.removeEventListener('mouseup', stopDrag, false);
    dragBar.current.classList.remove('dragModeOn');
    dragBar.current.classList.remove('highlightBorder');
    dragBar.current.style.right = resizableElement.current.style.width;
  }

  const initial = (e: any) => {
    if (!resizableElement.current) return;
    setInitialPos(e.clientX);
    resizableElement.current != null &&
      setInitialSize(resizableElement.current.offsetWidth);

    if (initialPos != null) {
      resizableElement.current.style.width = `${
        parseInt(`${initialSize}`) + parseInt(`${-e.clientX + initialPos}`)
      }px`;
    }
    dragBar.current.classList.remove('highlightBorder');
    dragBar.current.classList.add('dragModeOn');
    dragBar.current.style.right = resizableElement.current.style.width;
  };

  return (
    <div
      className='side-panel-container'
      onMouseOver={() => {
        dragBar.current.classList.add('highlightBorder');
      }}
      onMouseLeave={() => {
        dragBar.current.classList.remove('highlightBorder');
      }}
    >
      <div id='Draggable' onMouseDown={initial} ref={dragBar} />
      <SidePanel
        ref={resizableElement}
        open={open}
        onRequestClose={() => onClose()}
        subtitle={
          <>
            {customTitle && customTitle}
            {!customTitle && (
              <Link
                className='no-underline-link'
                to={''}
                onClick={() =>
                  window.open(
                    window.location.origin + process.env.PUBLIC_URL + link
                  )
                }
              >
                {title}
              </Link>
            )}
          </>
        }
        className='side-panel-component'
        size='sm'
        // actionToolbarButtons={
        //   link && [
        //     {
        //       label: t('launch'),
        //       leading: false,
        //       icon: Launch16,
        //       onClick: () =>
        //         window.open(
        //           window.location.origin + process.env.PUBLIC_URL + link
        //         ),
        //     },
        //     {
        //       label: t('link'),
        //       leading: false,
        //       icon: Link16,
        //       onClick: () =>
        //         navigator.clipboard.writeText(
        //           window.location.origin + process.env.PUBLIC_URL + link
        //         ),
        //     },
        //     // {
        //     //   label: 'Events',
        //     //   leading: false,
        //     //   icon: CloudAlertingSvg,
        //     //   onClick: () => {},
        //     // },
        //   ]
        // }
      >
        {<>{children}</>}
      </SidePanel>
    </div>
  );
};

export default CustomSidePanel;
