import { Locked16 } from '@carbon/icons-react';
import { TFunction } from 'react-i18next';
export type genericStatusField = 'notAuthorised';

export const genericTableCardStatusConfig = (
  t: TFunction<'GenericStatusField', undefined>,
  status: genericStatusField
) => {
  const config = {
    notAuthorised: {
      label: t('notAuthorised'),
      icon: Locked16,
    },
  };
  return config[status];
};
