import React, { useEffect, useState } from 'react';
import {
  TextInput,
  Column,
  Row,
  Dropdown,
  Checkbox,
  InlineNotification,
  ComboBox,
} from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import AddLabels from '../../../../components/AddLabels/AddLabels';
import { TextArea } from '@carbon/react';
import { CreateTearsheetStep } from '@carbon/ibm-products';
import {
  Cloud,
  GatewayIngressTypes,
  Location,
  SkupperComputeProfile,
} from '../../../../models/master';
import { AxonConnectionMethod, Item } from '../RegisterGateway';
import './GatewayDetailsForm.scss';
import { GatewayType } from '../../GatewaysContainer';
import { axonAutoDeployAgreementText } from '../config';
import { GatewaySubTypes } from '../../../../lib/enums';

const GatewayDetailsForm = (props: {
  onChange?: any;
  onBlur?: any;
  formData?: any;
  resourceGroupList?: any;
  gatewayType: GatewayType | null;
  cloudsList: Cloud[] | null;
  locationsList: Location[] | null;
  axonConnectionMethod: AxonConnectionMethod;
  axonAutoCheckboxTicked: boolean;
  handleAxonAutoCheckbox: () => void;
  children?: any;
  selectedDepEnv: Item;
  enableIngress: boolean;
  handleSelectIngressCheckbox: (id: string) => void;
  isFormValid: boolean;
  clouds: Cloud[];
  gwComputeProfiles: any;
  gwRhsiComputeProfiles: SkupperComputeProfile[];
  gwIngressTypes: GatewayIngressTypes[];
  setToggleErrorNotification: any;
  toggleErrorNotification: any;
  optionalParamInValid: boolean;
  selectedGatewayType: GatewayType | null;
}) => {
  const {
    onChange,
    formData,
    resourceGroupList,
    onBlur,
    gatewayType,
    cloudsList,
    locationsList,
    axonConnectionMethod,
    axonAutoCheckboxTicked,
    handleAxonAutoCheckbox,
    children,
    selectedDepEnv,
    enableIngress,
    handleSelectIngressCheckbox,
    isFormValid,
    clouds,
    gwComputeProfiles,
    gwRhsiComputeProfiles,
    gwIngressTypes,
    toggleErrorNotification,
    setToggleErrorNotification,
    optionalParamInValid,
    selectedGatewayType,
  } = props;

  const { t } = useTranslation('gatewaysContainer');
  const [selectedCloud, setSelectedCloud] = useState<string | null>(null);
  const [filteredLocationList, setFilteredLocationList] = useState<
    any[] | null
  >(null);
  const [cloudType, setCloudType] = useState('');

  useEffect(() => {
    if (
      (Array.isArray(cloudsList) && cloudsList.length === 0) ||
      (Array.isArray(locationsList) && locationsList.length === 0)
    ) {
      setToggleErrorNotification(true);
    }
  }, []);

  useEffect(() => {
    if (
      Array.isArray(gwRhsiComputeProfiles) &&
      selectedGatewayType?.resource_id === GatewaySubTypes.RHSI_EDGE
    ) {
      let small = gwRhsiComputeProfiles.find(cp => cp.profile === 'small');
      onChange('rhsi_compute_profile', small ?? '');
    }
  }, [gwRhsiComputeProfiles, selectedGatewayType]);

  useEffect(() => {
    if (
      (Array.isArray(cloudsList) && cloudsList.length === 0) ||
      (Array.isArray(locationsList) && locationsList.length === 0)
    ) {
      setToggleErrorNotification(true);
    } else {
      setToggleErrorNotification(false);
    }
  }, [cloudsList, locationsList]);

  useEffect(() => {
    setSelectedCloud(null);
  }, [gatewayType]);

  useEffect(() => {
    if (selectedDepEnv.type.length > 0) {
      let filteredCloud;
      if (clouds != null) {
        filteredCloud = clouds.filter(
          (cloud: any) => cloud.resource_id === selectedDepEnv.cloud_id
        )[0];

        filteredCloud != null && setCloudType(filteredCloud.type);
      }
    }
  }, [clouds, selectedDepEnv]);

  useEffect(() => {
    if (selectedCloud != null) {
      let filteredLocations = locationsList?.filter(
        location => location.cloud_id === selectedCloud
      );
      if (Array.isArray(filteredLocations)) {
        filteredLocations.length === 0 && setToggleErrorNotification(true);
        filteredLocations.length > 0 && setToggleErrorNotification(false);
        setFilteredLocationList([...filteredLocations]);
      }
    }
  }, [selectedCloud]);

  return (
    <>
      <CreateTearsheetStep
        className='gateway-details-form-tearsheet'
        hasFieldset={false}
        title={t('gatewayDetailsForm.steptitle')}
        disableSubmit={() => {
          if (
            gatewayType?.resource_id === 'AXON-EDGE' ||
            gatewayType?.resource_id === 'AXON-WP'
          ) {
            if (axonConnectionMethod === 'auto') {
              if (!axonAutoCheckboxTicked) {
                return true;
              } else {
                if (isFormValid) {
                  return false;
                } else {
                  return true;
                }
              }
            } else {
              return !isFormValid;
            }
          } else {
            return !isFormValid || optionalParamInValid;
          }
        }}
      >
        <Row className='inline-error-notification-row'>
          {children}
          {toggleErrorNotification && (
            <InlineNotification
              onClose={() => setToggleErrorNotification(false) as any}
              kind={'error'}
              title={t('errorTitle')}
              subtitle={
                Array.isArray(cloudsList) && cloudsList.length === 0
                  ? t('cloudListEmptyError')
                  : Array.isArray(locationsList) && locationsList.length === 0
                  ? t('locationListEmptyError')
                  : Array.isArray(filteredLocationList) &&
                    filteredLocationList.length === 0
                  ? t('filteredLocationListEmptyError')
                  : null
              }
              lowContrast
            />
          )}
        </Row>

        <div className='gateway-details-form'>
          {(gatewayType?.resource_id === 'AXON-EDGE' ||
            gatewayType?.resource_id === 'AXON-WP') &&
            axonConnectionMethod === 'manual' && (
              <div className='axon-edge-manual-and-waypoint-form'>
                <Row className='row'>
                  <Column md={4}>
                    <TextInput
                      labelText={t('gatewayDetailsForm.name.label') as string}
                      id='gateway-name'
                      value={formData?.name.value}
                      name='name'
                      placeholder={t('gatewayDetailsForm.name.placeholder')}
                      onChange={e => onChange('name', e.target.value)}
                      invalid={formData?.name.error}
                      invalidText={formData?.name.errorMessage}
                      autoComplete='off'
                      maxLength={60}
                      onBlur={e => onBlur('name', e.target.value)}
                    />
                  </Column>
                  <Column md={4} className='infra-col'>
                    <Dropdown
                      id='gateway-resource-group'
                      className='resourceGroup'
                      selectedItem={formData?.resourceGroup?.value as any}
                      onChange={data =>
                        onChange('resourceGroup', data.selectedItem)
                      }
                      items={resourceGroupList ?? []}
                      itemToString={item => (item ? item.name : '')}
                      titleText={t('gatewayDetailsForm.resourceGroup.label')}
                      label={''}
                      placeholder={t(
                        'gatewayDetailsForm.resourceGroup.placeholder'
                      )}
                      translateWithId={t}
                    />
                  </Column>
                </Row>
                <Row className='row'>
                  <Column md={4}>
                    <ComboBox
                      id='gateway-cloud'
                      className='form-field'
                      items={cloudsList ?? []}
                      itemToString={(item: any) => (item ? item.name : '')}
                      onChange={(data: any) => {
                        data.selectedItem != null &&
                          setSelectedCloud(data.selectedItem.resource_id);
                        onChange('cloud', {
                          name: data.selectedItem?.name,
                          resource_id: data.selectedItem?.resource_id,
                        });
                        onChange('location', {
                          name: '',
                          resource_id: '',
                        });
                      }}
                      selectedItem={formData?.cloud?.value as any}
                      placeholder={
                        t('gatewayDetailsForm.cloud.placeholder') as string
                      }
                      titleText={t('gatewayDetailsForm.cloud.label') as string}
                      disabled={Object.values(selectedDepEnv).every(
                        (prop: any) => prop.length > 0
                      )}
                      translateWithId={t}
                    />
                  </Column>
                  <Column md={4}>
                    <ComboBox
                      id='gateway-location'
                      className='form-field'
                      items={filteredLocationList ?? []}
                      itemToString={item => (item ? item.name : '')}
                      onChange={data => {
                        onChange('location', {
                          name: data.selectedItem?.name,
                          resource_id: data.selectedItem?.resource_id,
                        });
                      }}
                      selectedItem={formData?.location?.value as any}
                      placeholder={
                        t('gatewayDetailsForm.location.placeholder') as string
                      }
                      titleText={t('gatewayDetailsForm.location.label')}
                      disabled={Object.values(selectedDepEnv).every(
                        (prop: any) => prop.length > 0
                      )}
                      translateWithId={t}
                    />
                  </Column>
                </Row>
                <Row className='row'>
                  <Column md={8}>
                    <AddLabels
                      id='gateway-labels'
                      labelText={t('gatewayDetailsForm.labelName')}
                      placeholder={t('gatewayDetailsForm.labelPlaceholder')}
                      onChange={data => onChange('labels', data)}
                      btnText={t('gatewayDetailsForm.labelbtnText')}
                      btnKind='secondary'
                      tagType='green'
                      defaultValues={formData?.labels?.value}
                    />
                  </Column>
                </Row>
                <Row className='row'>
                  <Column md={8}>
                    <TextArea
                      id='gateway-description'
                      name='description'
                      className='form-field-description'
                      autoComplete='off'
                      labelText={t('gatewayDetailsForm.description.label')}
                      placeholder={t(
                        'gatewayDetailsForm.description.placeholder'
                      )}
                      onChange={(e: any) =>
                        onChange('description', e?.target?.value)
                      }
                      value={formData?.description?.value}
                      maxLength={300}
                    />
                  </Column>
                </Row>
              </div>
            )}
          {(gatewayType?.resource_id === 'AXON-EDGE' ||
            gatewayType?.resource_id === 'AXON-WP') &&
            axonConnectionMethod === 'auto' && (
              <div className='axon-edge-auto-form'>
                <Row className='row'>
                  <Column md={4}>
                    <TextInput
                      labelText={t('gatewayDetailsForm.name.label') as string}
                      id='gateway-name'
                      value={formData?.name.value}
                      name='name'
                      placeholder={t('gatewayDetailsForm.name.placeholder')}
                      onChange={e => onChange('name', e.target.value)}
                      invalid={formData?.name.error}
                      invalidText={formData?.name.errorMessage}
                      autoComplete='off'
                      maxLength={60}
                      onBlur={e => onBlur('name', e.target.value)}
                    />
                  </Column>
                  <Column md={4}>
                    <Dropdown
                      light
                      id={`select-compute-profile`}
                      items={gwComputeProfiles}
                      selectedItem={formData.intended_compute_profile.value}
                      itemToString={(item: any) => item.profile_name}
                      onChange={(e: any) => {
                        onChange('intended_compute_profile', e.selectedItem);
                      }}
                      label={t(
                        'gatewayDetailsForm.intended_compute_profile.placeholder'
                      )}
                      titleText={t(
                        'gatewayDetailsForm.intended_compute_profile.titleText'
                      )}
                    />
                  </Column>
                </Row>
                <Row className='row'>
                  <Column md={8}>
                    <AddLabels
                      id='gateway-labels'
                      labelText={t('gatewayDetailsForm.labelName')}
                      placeholder={t('gatewayDetailsForm.labelPlaceholder')}
                      onChange={data => onChange('labels', data)}
                      btnText={t('gatewayDetailsForm.labelbtnText')}
                      btnKind='secondary'
                      tagType='green'
                      defaultValues={formData?.labels?.value}
                    />
                  </Column>
                </Row>
                <Row className='row'>
                  <Column md={8}>
                    <TextArea
                      id='gateway-description'
                      name='description'
                      autoComplete='off'
                      labelText={t('gatewayDetailsForm.description.label')}
                      placeholder={t(
                        'gatewayDetailsForm.description.placeholder'
                      )}
                      onChange={(e: any) =>
                        onChange('description', e?.target?.value)
                      }
                      value={formData?.description?.value}
                      maxLength={300}
                    />
                  </Column>
                </Row>
                {selectedDepEnv?.type.length > 0 && (
                  <Checkbox
                    checked={axonAutoCheckboxTicked}
                    id='axon-autoconnect-agreement-checkbox'
                    labelText={axonAutoDeployAgreementText(t, cloudType)}
                    onChange={handleAxonAutoCheckbox}
                  />
                )}
              </div>
            )}
          {gatewayType?.resource_id === 'RHSI-EDGE' && (
            <div className='rhsi-edge-form'>
              <Row className='row form-heading'>
                {t('gatewayDetailsForm.formHeading')}
              </Row>
              <Row className='row'>
                <Column md={4}>
                  <TextInput
                    labelText={
                      t(
                        'gatewayDetailsForm.connectedInNameSpaceLabel'
                      ) as string
                    }
                    id='gateway-namespace-name'
                    value={formData?.deployed_in_partition_id.value.name}
                    name='namespace-name'
                    maxLength={60}
                    readOnly
                  />
                </Column>
                <Column md={4}>
                  <TextInput
                    labelText={t('gatewayDetailsForm.name.label') as string}
                    id='gateway-name'
                    value={formData?.name.value}
                    name='name'
                    maxLength={60}
                    invalid={formData?.name.error}
                    invalidText={formData?.name.errorMessage}
                  />
                </Column>
              </Row>
              <Row className='row'>
                <Column md={4}>
                  <TextInput
                    labelText={
                      t('gatewayDetailsForm.deployedInNameSpaceLabel') as string
                    }
                    id='gateway-deployed-in-namespace-name'
                    value={formData?.deployed_in_partition_id.value.name}
                    name='deployed-in-namespace-name'
                    maxLength={60}
                    readOnly
                  />
                </Column>
              </Row>
              <div className='gateway-configuration-section'>
                <div className='heading'>
                  {t('gatewayDetailsForm.configurationForm.heading')}
                </div>
                <div className='description'>
                  <span className='descriptionText'>
                    {t('gatewayDetailsForm.configurationForm.description')}
                  </span>
                  <span className='link'>
                    <a
                      href='https://www.ibm.com/docs/en/SSTZ4DO/rhsi/rhsi-deploy-ui.html'
                      className='ingress-link'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {t('gatewayDetailsForm.ingressForm.descriptionLink')}
                    </a>
                  </span>
                </div>
                <Row className='gateway-sizing-section'>
                  <Column md={4}>
                    <Dropdown
                      light
                      id='select-gateway-sizing'
                      items={gwRhsiComputeProfiles.sort((p1, p2) =>
                        p1?.profile > p2?.profile ? -1 : 1
                      )}
                      selectedItem={formData?.rhsi_compute_profile?.value}
                      itemToString={(gatewaySizing: any) =>
                        `${
                          gatewaySizing?.profile?.charAt(0).toUpperCase() +
                          gatewaySizing?.profile?.slice(1)
                        } (${t('requests')}: CPU ${
                          gatewaySizing?.cpu_req
                        }, MEM ${gatewaySizing?.mem_req}, ${t('limits')}: CPU ${
                          gatewaySizing?.cpu_lim
                        }, MEM ${gatewaySizing?.mem_lim})`
                      }
                      onChange={(e: any) =>
                        onChange('rhsi_compute_profile', e.selectedItem)
                      }
                      label={t('gatewayDetailsForm.gatewaySizeDropdown.label')}
                      titleText={t(
                        'gatewayDetailsForm.gatewaySizeDropdown.title'
                      )}
                      invalid={gwRhsiComputeProfiles.length === 0}
                      invalidText={t('rhsiComputeProfileListEmptyError')}
                    />
                  </Column>
                </Row>
                {
                  <div className='gateway-configuration-ingress-form'>
                    <div className='ingress-form-heading'>
                      {t('gatewayDetailsForm.ingressForm.heading')}
                    </div>
                    <div className='ingress-form-description'>
                      <span className='descriptionText'>
                        {t('gatewayDetailsForm.ingressForm.description')}
                      </span>
                    </div>
                    <Row className='ingress-config-form'>
                      <Column md={4}>
                        <Dropdown
                          light
                          id='select-gateway-ingress-type'
                          items={gwIngressTypes}
                          selectedItem={
                            formData.skupper_site_configuration?.ingress_type
                              ?.value
                          }
                          itemToString={(item: any) => item.label}
                          onChange={(e: any) => {
                            onChange(
                              'skupper_site_configuration',
                              e.selectedItem,
                              'ingress_type'
                            );
                            handleSelectIngressCheckbox(e.selectedItem.id);
                          }}
                          label={t('gatewayDetailsForm.ingressType.label')}
                          titleText={t(
                            'gatewayDetailsForm.ingressType.titleText'
                          )}
                          invalid={gwIngressTypes.length === 0}
                          invalidText={t('ingressTypeListEmptyError')}
                        />
                      </Column>
                      {enableIngress && (
                        <>
                          <Column md={4}>
                            <TextInput
                              id='gateway-ingress-hostname'
                              light
                              onChange={(e: any) =>
                                onChange(
                                  'skupper_site_configuration',
                                  e.target.value,
                                  'ingress_hostname'
                                )
                              }
                              value={
                                formData.skupper_site_configuration
                                  ?.ingress_hostname?.value
                              }
                              size='md'
                              labelText={t(
                                'gatewayDetailsForm.ingressHostname.label'
                              )}
                              placeholder={t(
                                'gatewayDetailsForm.ingressHostname.placeholder'
                              )}
                              onBlur={e =>
                                onBlur(
                                  'skupper_site_configuration',
                                  e.target.value,
                                  'ingress_hostname'
                                )
                              }
                              invalid={
                                formData?.skupper_site_configuration
                                  ?.ingress_hostname?.error
                              }
                              invalidText={
                                formData?.skupper_site_configuration
                                  ?.ingress_hostname?.errorMessage
                              }
                            />
                          </Column>
                          <Column md={4} id='router-ingress-hostname-col'>
                            <TextInput
                              id='router-ingress-hostname'
                              light
                              onChange={(e: any) =>
                                onChange(
                                  'skupper_site_configuration',
                                  e.target.value,
                                  'router_ingress_hostname'
                                )
                              }
                              value={
                                formData.skupper_site_configuration
                                  ?.router_ingress_hostname?.value
                              }
                              size='md'
                              labelText={t(
                                'gatewayDetailsForm.routerIngressHostname.label'
                              )}
                              placeholder={t(
                                'gatewayDetailsForm.routerIngressHostname.placeholder'
                              )}
                              onBlur={e =>
                                onBlur(
                                  'skupper_site_configuration',
                                  e.target.value,
                                  'router_ingress_hostname'
                                )
                              }
                              invalid={
                                formData?.skupper_site_configuration
                                  ?.router_ingress_hostname?.error
                              }
                              invalidText={
                                formData?.skupper_site_configuration
                                  ?.router_ingress_hostname?.errorMessage
                              }
                            />
                          </Column>
                          <Column md={4} id='controller-ingress-hostname-col'>
                            <TextInput
                              id='controller-ingress-hostname'
                              light
                              onChange={(e: any) =>
                                onChange(
                                  'skupper_site_configuration',
                                  e.target.value,
                                  'controller_ingress_hostname'
                                )
                              }
                              value={
                                formData.skupper_site_configuration
                                  ?.controller_ingress_hostname?.value
                              }
                              size='md'
                              labelText={t(
                                'gatewayDetailsForm.controllerIngressHostname.label'
                              )}
                              placeholder={t(
                                'gatewayDetailsForm.controllerIngressHostname.placeholder'
                              )}
                              onBlur={e =>
                                onBlur(
                                  'skupper_site_configuration',
                                  e.target.value,
                                  'controller_ingress_hostname'
                                )
                              }
                              invalid={
                                formData?.skupper_site_configuration
                                  ?.controller_ingress_hostname?.error
                              }
                              invalidText={
                                formData?.skupper_site_configuration
                                  ?.controller_ingress_hostname?.errorMessage
                              }
                            />
                          </Column>
                          <Column md={8} id='ingress-annotations-col'>
                            <TextInput
                              id='ingress-annotations'
                              light
                              onChange={(e: any) =>
                                onChange(
                                  'skupper_site_configuration',
                                  e.target.value,
                                  'ingress_annotations'
                                )
                              }
                              value={
                                formData.skupper_site_configuration
                                  ?.ingress_annotations?.value
                              }
                              size='md'
                              labelText={t(
                                'gatewayDetailsForm.ingressAnnotations.label'
                              )}
                              placeholder={t(
                                'gatewayDetailsForm.ingressAnnotations.placeholder'
                              )}
                              onBlur={e =>
                                onBlur(
                                  'skupper_site_configuration',
                                  e.target.value,
                                  'ingress_annotations'
                                )
                              }
                              invalid={
                                formData?.skupper_site_configuration
                                  ?.ingress_annotations?.error
                              }
                              invalidText={
                                formData?.skupper_site_configuration
                                  ?.ingress_annotations?.errorMessage
                              }
                            />
                          </Column>
                        </>
                      )}
                    </Row>
                  </div>
                }
              </div>
              <div className='optional-details'>
                <Row className='row form-heading'>
                  {t('gatewayDetailsForm.optionalDetailsHeading')}
                </Row>
                <Row className='row'>
                  <Column md={8}>
                    <AddLabels
                      id='gateway-labels'
                      labelText={t('gatewayDetailsForm.labelName')}
                      placeholder={t('gatewayDetailsForm.labelPlaceholder')}
                      onChange={data => onChange('labels', data)}
                      btnText={t('gatewayDetailsForm.labelbtnText')}
                      btnKind='secondary'
                      tagType='green'
                      defaultValues={formData?.labels?.value}
                    />
                  </Column>
                </Row>
                <Row className='row'>
                  <Column md={8}>
                    <TextArea
                      id='gateway-description'
                      name='description'
                      autoComplete='off'
                      labelText={t('gatewayDetailsForm.description.label')}
                      placeholder={t(
                        'gatewayDetailsForm.description.placeholder'
                      )}
                      onChange={(e: any) =>
                        onChange('description', e?.target?.value)
                      }
                      value={formData?.description?.value}
                      maxLength={300}
                    />
                  </Column>
                </Row>
              </div>
            </div>
          )}
        </div>
      </CreateTearsheetStep>
    </>
  );
};
export default GatewayDetailsForm;
