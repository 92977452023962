import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import GenericStatusField from '../../components/GenericStatusField/GenericStatusField';
import dateUtils from '../../lib/dates';
import { PolicyData } from '../../models/master';
import GenericTableWithFilters from '../GenericTableWithFilters/GenericTableWithFilters';
import LabelTag from '../LabelTag/LabelTag';
import PolicyTargetField from '../PolicyTargetField/PolicyTargetField';

import images from '../../images/images.js';
import { Filter } from '../FindAndFilterBar/FindAndFilterBar';
import GenericTruncateString from '../GenericTruncateString/GenericTruncateString';
import { IconType } from '../PolicyTargetField/config';
import './ConnectionAccessPolicyTable.scss';
import useAnalytics from '../../lib/useAnalytics';
import analyticsData from '../../lib/analyticsEventData';
import GenericResponsiveMiddleTruncation from '../GenericResponsiveMiddleTruncation/GenericResponsiveMiddleTruncation';
import { DEFAULT_NETWORK_SEGMENT_ID } from '../../lib/constants';

const ConnectionAccessPolicyTable = (props: {
  rows: PolicyData[] | null;
  onRefresh: () => void;
  filteredDataSet: any;
  elementCount: any;
  data: any;
  filteredDataCallback: (data: any) => void;
  filtersSelected: any;
  filtersAppliedCallback: (data: any) => void;
  filters: any;
  currentPageNumber: number;
  currentPageSize: number;
  onPageChange: (pageData: any) => void;
  sortRows(arg0: unknown, sortDirection: string): void;
  persistFilter?: boolean;
  error403Flag: boolean;
  error500Flag: boolean;
  leftInlineMultiSelectFilter?: Filter[];
}) => {
  const { rows, onRefresh } = props;
  const error403Flag = props.error403Flag;
  const error500Flag = props.error500Flag;
  const formatTags = (data: string[]) => {
    return <LabelTag labelArray={data} count={3} />;
  };
  const { t } = useTranslation('connectionAccessPolicies');
  const navigate = useNavigate();

  const { trackButtonClicked } = useAnalytics();

  const policyTableHeaders = [
    {
      key: 'name__format',
      originalKey: 'name',
      header: t('tableView.name'),
      sort: 'sortByName',
      style: { minWidth: '14rem' },
    },
    {
      key: 'network_segment_format',
      originalKey: 'network_segment_name',
      header: t('tableView.networkSegment'),
      sort: 'sortByNetworkSegment',
      style: { minWidth: '14rem' },
    },
    {
      key: 'resource_group_name',
      originalKey: 'resource_group_name',
      header: t('tableView.applicationGroup'),
      sort: 'sortByAppGroup',
    },
    {
      key: 'from',
      originalKey: 'from_app_name',
      header: t('tableView.from'),
      sort: 'sortByFrom',
    },
    {
      key: 'to',
      originalKey: 'to_svc_name',
      header: t('tableView.to'),
      sort: 'sortByTo',
    },
    {
      key: 'labels',
      originalKey: 'name',
      header: t('tableView.labels'),
    },
    {
      key: 'updated',
      originalKey: 'updated_at',
      header: t('tableView.lastUpdated'),
      sort: 'sortByUpdated',
      style: { minWidth: '9.5rem' },
    },
  ];

  const setRowsData = () => {
    let formattedRows: {
      id: any;
      name: string;
      network_segment_name: string;
      resource_group_name: JSX.Element | null | string;
      network_segment_format: JSX.Element | null | string;
      name__format: JSX.Element;
      updated: any;
      labels: JSX.Element | null | string;
      from: JSX.Element | null;
      to: JSX.Element | null;
    }[] = [];

    if (rows && rows.length === 0) return [];
    if (error403Flag) return [];
    if (error500Flag) return [];
    if (rows)
      rows.map((row: PolicyData) => {
        formattedRows.push({
          id: row.resource_id,
          name: !!row.name ? row.name : '—',
          name__format: (
            <div>
              {' '}
              <Link
                className='no-underline-link'
                to={`/connectionaccesspolicydetails?policyId=${row.resource_id}`}
              >
                {row?.name ? (
                  <GenericResponsiveMiddleTruncation str={row?.name} />
                ) : (
                  ''
                )}
              </Link>
            </div>
          ),
          network_segment_name: !!row.network_segment_name
            ? row.network_segment_name
            : '—',
          network_segment_format: !row.networkSegmentPermission ? (
            <GenericStatusField status={'notAuthorised'} />
          ) : row.network_segment_id === DEFAULT_NETWORK_SEGMENT_ID ? (
            <GenericTruncateString
              str={(row?.network_segment_name as string) ?? '—'}
              tableView={true}
              maxLength={30}
              limit={14}
            />
          ) : (
            <Link
              className='no-underline-link'
              to={`/networkSegmentDetails?nwSegId=${row.network_segment_id}`}
              onClick={() =>
                trackButtonClicked(
                  analyticsData['Policies'].events.openPolicy.props,
                  analyticsData['Policies'].events.openPolicy.event
                )
              }
            >
              <GenericTruncateString
                str={(row?.network_segment_name as string) ?? '—'}
                tableView={true}
                maxLength={30}
                limit={14}
              />
            </Link>
          ),
          labels: row.labels ? formatTags(row.labels) : '—',
          updated: !!row.updated_at
            ? dateUtils.getUserFriendlyDate(row.updated_at)
            : '—',
          // TODO: 'type' value will come from api, 'label' can be array of policy targets
          from: !row.fromPermission ? (
            <GenericStatusField status={'notAuthorised'} />
          ) : row.from_app_name ? (
            <PolicyTargetField
              label={row.from_app_name}
              type={row.from?.type as IconType}
            />
          ) : (
            <span>—</span>
          ),
          to: !row.servicePermission ? (
            <GenericStatusField status={'notAuthorised'} />
          ) : row.to_svc_name ? (
            <PolicyTargetField label={row.to_svc_name} type='service' />
          ) : (
            <span>—</span>
          ),
          resource_group_name: !row.resourceGroupsPermission ? (
            <GenericStatusField status={'notAuthorised'} />
          ) : (
            row?.resource_group_name ?? '—'
          ),
        });
        return 0;
      });
    else return null;
    return formattedRows;
  };

  const emptyStateData = {
    icon: images.noPoliciesIcon(),
    notFoundIcon: images.NotFoundLarge(),
    emptyStateHeader: t('emptyState.emptyContainerHeader'),
    emptyStateDescription: t('emptyState.emptyContainerDescription'),
    link: '/',
    buttonText: t('emptyState.buttonText'),
    click: () =>
      navigate('/connectionAccessPolicies', {
        state: { resourceType: 'POLICY' },
      }),
  };

  return (
    <div className='policy-list-table'>
      <GenericTableWithFilters
        id='connection-access-policy-table-with-filter-panel'
        rows={setRowsData()}
        data={props.filteredDataSet}
        headers={policyTableHeaders}
        isSortable={true}
        totalElementsCount={props.elementCount ? props.elementCount : 0}
        fullData={props.data}
        onTableRefresh={() => onRefresh()}
        filteredDataCallback={(data: any) => props.filteredDataCallback(data)}
        selectedFiltersVal={props.filtersSelected as any}
        setFilterApplied={(data: any) => props.filtersAppliedCallback(data)}
        filters={props.filters}
        currentPageNumber={props.currentPageNumber}
        currentPageSize={props.currentPageSize}
        onPageChange={(pageData: any) => props.onPageChange(pageData)}
        emptyState={emptyStateData}
        sortRows={(data, sortDirection) => props.sortRows(data, sortDirection)}
        persistFilter={props.persistFilter}
        render403Container={props.error403Flag}
        render500Container={props.error500Flag}
        leftInlineMultiSelectFilter={props.leftInlineMultiSelectFilter}
      />
    </div>
  );
};

export default ConnectionAccessPolicyTable;
