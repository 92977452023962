import { useTranslation } from 'react-i18next';
import { Dropdown, Tooltip } from '@carbon/react';
import {
  Row,
  Column,
  TextInput,
  TextArea,
  ComboBox,
} from 'carbon-components-react';
import { Information16 } from '@carbon/icons-react';
import AddLabels from '../../../components/AddLabels/AddLabels';
import { ResourceGroup } from '../../../models/master';
import { FormData } from './CreateNetworkSegment';
import { gatewaySet } from '../../NetworkSegmentDetails/config';
import './CreateNetworkSegment.scss';

interface Props {
  formData: FormData;
  onChange: (name: string, value: any) => void;
  groups: ResourceGroup[] | null;
  subHeader: string;
}

const NetworkSegmentForm: React.FC<Props> = ({
  formData,
  onChange,
  groups,
  subHeader,
}) => {
  const { t } = useTranslation('createNetworkSegment');

  return (
    <div className='network-segment-form'>
      <Row>
        <Column className='modal-header'>{subHeader}</Column>
      </Row>
      <Row>
        <Column>
          <TextInput
            id='create-network-segment-name'
            value={formData.name.value}
            name='segment-name'
            autoComplete='off'
            onChange={e => onChange('name', e.target.value)}
            labelText={t('create.name.label')}
            placeholder={t('create.name.placeholder')}
            invalid={formData.name.error}
            invalidText={formData.name.errorMessage}
            maxLength={60}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <div className='gateway-set-label'>
            <span>{t('create.gatewaySet.label')}</span>
            {/* <Tooltip label={t('create.gatewaySet.infoText')} align='bottom'>
              <button className='info-tooltip-text'>
                <Information16 />
              </button>
            </Tooltip> */}
          </div>
          <Dropdown
            id='create-segment-gateway-set'
            selectedItem={formData.gatewaySet}
            name='segment-gateway-set'
            itemToString={(item: any) => (item ? item.name : '')}
            onChange={() => {}}
            items={gatewaySet}
            readOnly
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <ComboBox
            light
            id='create-segment-resource-group'
            name='infrastructureGroup'
            selectedItem={formData?.infrastructureGroup?.value ?? ''}
            items={groups ?? []}
            itemToString={(item: ResourceGroup) => (item ? item.name : '')}
            titleText={t('create.infraGroup.label')}
            placeholder={t('create.infraGroup.placeholder')}
            onChange={(e: any) =>
              onChange('infrastructureGroup', e.selectedItem)
            }
            invalid={formData.infrastructureGroup.error}
            invalidText={formData.infrastructureGroup.errorMessage}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <AddLabels
            id='create-segment-add-labels'
            labelText={t('create.addLabels.label')}
            placeholder={t('create.addLabels.placeholder')}
            onChange={data => onChange('labels', data)}
            btnText={t('create.addLabels.buttonText')}
            btnKind='secondary'
            tagType='green'
            defaultValues={formData.labels.value}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <TextArea
            id='create-network-segment-description'
            labelText={t('create.description.label')}
            placeholder={t('create.description.placeholder')}
            name='description'
            value={formData.description.value}
            onChange={e => onChange('description', e.target.value)}
            maxCount={300}
            enableCounter={true}
          />
        </Column>
      </Row>
    </div>
  );
};

export default NetworkSegmentForm;
