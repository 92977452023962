import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonKind,
  Toggle,
  TooltipDefinition,
} from 'carbon-components-react';
import { InlineNotification } from '@carbon/react';

import { MAINTENANCE_END_WINDOW, MAINTENANCE_START_WINDOW } from '../../config';

import PageTitleComponent from '../Title/PageTitleComponent';
import Breadcrumbs from '../Title/Breadcrumbs';
import ButtonWithOptions from '../ButtonWithOptions/ButtonWithOptions';

import './Header.scss';
import dates from '../../lib/dates';

type Breadcrumb = { url: string; label: string };
type LearnMore = { url: string; label: string };

type Action = {
  kind: ButtonKind;
  onClick: () => void;
  text: string;
  disabled?: boolean;
  name?: string;
  toolTip?: string;
};

const Header = (props: {
  title: React.ReactElement | string;
  learnMoreLink?: LearnMore;
  breadcrumbs: Breadcrumb[];
  actions?: Action[];
  optionsButton?: any;
  subTitle?: React.ReactElement | string;
  headerIcon?: React.ReactElement | boolean;
  toggle?: {
    state: boolean;
    onChange: (val: boolean) => void;
    labelA: string;
    labelB: string;
    id: string;
    disabled: boolean;
  };
  loading?: boolean;
  renderCustomActions?: () => JSX.Element;
  dropdown?: {
    label: string;
    items: any;
  };
  dataTestId?: string;
}) => {
  const {
    title,
    breadcrumbs,
    actions,
    subTitle,
    toggle,
    loading,
    headerIcon,
    renderCustomActions,
    optionsButton,
    learnMoreLink,
    dataTestId,
  } = props;
  const { t } = useTranslation('header');
  const showMaintenanceBanner =
    window.localStorage.getItem('showMaintenanceBanner') === 'true'
      ? true
      : false;
  const showTechPreviewBanner =
    window.localStorage.getItem('showTechPreviewBanner') === 'true'
      ? true
      : false;

  const showMeshAgentUpgradeBanner =
    window.localStorage.getItem('showMeshAgentUpgradeBanner') === 'true'
      ? true
      : false;

  const closeBanner = (type: string) => {
    if (type === 'maintenance') {
      window.localStorage.setItem('showMaintenanceBanner', 'false');
    } else if (type === 'meshAgentUpgrade') {
      window.localStorage.setItem('showMeshAgentUpgradeBanner', 'false');
    } else {
      window.localStorage.setItem('showTechPreviewBanner', 'false');
    }
  };

  return (
    <div className='header-component' data-testid={dataTestId}>
      <div className='header-wrapper'>
        <Breadcrumbs breadCrumbs={breadcrumbs} loadingContent={loading} />
        <div className='header-container'>
          <PageTitleComponent
            title={title}
            subTitle={subTitle}
            headerIcon={headerIcon}
            loadingContent={loading}
            learnMoreLinkPage={learnMoreLink}
          />
          <div className='action-buttons'>
            {actions &&
              actions.map((action, index) =>
                action?.toolTip && action?.disabled ? (
                  <TooltipDefinition
                    tooltipText={action.toolTip}
                    direction='bottom'
                  >
                    <Button
                      key={index}
                      kind={action.kind}
                      className={`action-button action-btn-disabled`}
                      onClick={
                        action?.disabled ? () => {} : () => action.onClick()
                      }
                      data-testid='header-action-button'
                    >
                      {action.text}
                    </Button>
                  </TooltipDefinition>
                ) : (
                  <Button
                    key={index}
                    kind={action.kind}
                    disabled={action.disabled || loading}
                    className={`action-button`}
                    onClick={() => action.onClick()}
                    data-testid='header-action-button'
                  >
                    {action.text}
                  </Button>
                )
              )}
            {optionsButton && !loading && (
              <ButtonWithOptions
                btnText={optionsButton.text}
                items={optionsButton.items}
                onChange={optionsButton.onChange}
                disabled={optionsButton.disabled}
              />
            )}
            {toggle && (
              <Toggle
                id={toggle.id}
                labelA={toggle.labelA}
                labelB={toggle.labelB}
                size='sm'
                toggled={toggle.state}
                onToggle={val => toggle.onChange(val)}
                disabled={loading}
              />
            )}
            {renderCustomActions && renderCustomActions()}
          </div>
        </div>
      </div>
      <div className='notification-wrapper'>
        {showMaintenanceBanner ? (
          <div className='notification-container'>
            <InlineNotification
              lowContrast
              kind='warning'
              title={t('scheduledMaintenanceBanner.title')}
              subtitle={t('scheduledMaintenanceBanner.subtitle', {
                start: dates.getTimeWithDate(
                  MAINTENANCE_START_WINDOW,
                  t('scheduledMaintenanceBanner.utc')
                ),
                end: dates.getTimeWithDate(
                  MAINTENANCE_END_WINDOW,
                  t('scheduledMaintenanceBanner.utc')
                ),
              })}
              onClose={() => closeBanner('maintenance')}
            />
          </div>
        ) : null}
        {showMeshAgentUpgradeBanner ? (
          <div className='notification-container'>
            <InlineNotification
              lowContrast
              kind='info'
              title={t('meshAgentUpgradeBanner.title')}
              subtitle={t('meshAgentUpgradeBanner.subtitle')}
              onClose={() => closeBanner('meshAgentUpgrade')}
            />
          </div>
        ) : null}
        {showTechPreviewBanner ? (
          <div className='notification-container'>
            <InlineNotification
              lowContrast
              kind='info'
              title={t('techPreviewBanner.title')}
              subtitle={t('techPreviewBanner.subtitle')}
              onClose={() => closeBanner('techPreview')}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Header;
