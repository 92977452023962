import React from 'react';

import './LegendPanel.scss';
import LegendContainer from '../LegendsContainer/LegendContainer';

interface Props {
  legendCustomStyle: {
    [key: string]: string;
  };
  setLegendOpen: (open: boolean) => void;
  legendOpen: boolean;
  legendContainerStyle: {
    [key: string]: string;
  };
}

const LegendPanel: React.FC<Props> = ({
  legendCustomStyle,
  setLegendOpen,
  legendOpen,
  legendContainerStyle,
}) => {
  return (
    <>
      <div
        className='topology-legend-panel'
        style={legendCustomStyle ? legendCustomStyle : {}}
      >
        <LegendContainer
          legendOpen={legendOpen}
          setLegendOpen={setLegendOpen}
          legendContainerStyle={legendContainerStyle}
        />
      </div>
    </>
  );
};

export default LegendPanel;
