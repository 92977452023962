import { useMemo, useRef } from 'react';
import { useQueriesDataToPick } from './queryDefaults';

const shallowCompareObjects = <T extends {}>(a: any, b: any) => {
  return Object.keys(a).every(key => a[key] === b[key]);
};

const shallowCompareCollections = <T extends {}>(a: T[], b: T[]) => {
  return (
    a.length === b.length &&
    a.every((item, index) => shallowCompareObjects(item, b[index]))
  );
};

export const useMemoizedCollecionPick = <T extends {}, P extends keyof T>(
  array: T[]
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stablePickValue = useMemo(
    () => useQueriesDataToPick,
    [JSON.stringify(useQueriesDataToPick)]
  );
  const value = useMemo(
    () => array.map(item => pick(item, stablePickValue)),
    [array, stablePickValue]
  );
  const prevValue = useRef(value);
  return useMemo(() => {
    if (shallowCompareCollections(value, prevValue.current)) {
      return prevValue.current;
    }
    prevValue.current = value;
    return value;
  }, [value]);
};

const pick = <T extends { [key: string]: any }>(
  item: T,
  pickValues: readonly string[]
) => {
  let obj: { [key: string]: any } = {};
  pickValues.map(value => (obj[value] = item[value]));
  return obj;
};
