import React from 'react';
import { useTranslation } from 'react-i18next';
import { InlineLoading, TooltipDefinition } from 'carbon-components-react';
import { ProceduralStatusType, getProceduralStatusConfig } from './config';

import './GatewayStatus.scss';

interface Props {
  status: ProceduralStatusType;
  gatewayName: string;
  showLabel?: boolean;
  labelPrefix?: string;
  version?: string | number;
}

const ProceduralStatus: React.FC<Props> = ({
  status,
  gatewayName,
  showLabel = true,
  labelPrefix = '',
  version = '',
}) => {
  const { t } = useTranslation('gatewayStatus');

  const config = getProceduralStatusConfig(t, status);

  if (version && (!status || status === 'operational')) {
    return <span>{version}</span>;
  }

  if (config) {
    if (config.loadingIcon) {
      return (
        <div className='procedural-status-component'>
          <InlineLoading
            description={showLabel ? `${labelPrefix}${config.label}` : ''}
          />
        </div>
      );
    }

    const Icon = config.icon ?? null;

    if (
      status === 'errored' ||
      status === 'deployed' ||
      status === 'terminated'
    ) {
      return (
        <TooltipDefinition
          tooltipText={t('viewEvent')}
          direction='bottom'
          align='center'
        >
          <div
            className='procedural-status-component tooltip'
            onClick={e => {
              e.stopPropagation();
              window.open(
                window.location.origin +
                  process.env.PUBLIC_URL +
                  `/event?resource_name=${gatewayName}`
              );
            }}
          >
            {Icon && <span className='icon'>{Icon}</span>}
            {showLabel && (
              <span className='label'>{`${labelPrefix}${config.label}`}</span>
            )}
          </div>
        </TooltipDefinition>
      );
    }

    return (
      <div className='procedural-status-component'>
        {Icon && <span className='icon'>{Icon}</span>}
        {showLabel && (
          <span className='label'>{`${labelPrefix}${config.label}`}</span>
        )}
      </div>
    );
  }

  return <></>;
};

export default ProceduralStatus;
