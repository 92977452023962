import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from './Card';
import { IconType } from './config';

import './ConnectionAccessPolicyStateView.scss';

type SelectedType = 'from' | 'to';

interface Item {
  id: string;
  parentId?: string;
  label: string;
  type: IconType;
}

interface Props {
  selected: SelectedType;
  fromItems: Item[];
  toItems: Item[];
  disableFrom?: boolean;
  onSelect: (type: SelectedType) => void;
  onRemove: (item: Item, type: SelectedType) => void;
}

const ConnectionAccessPolicyStateView: React.FC<Props> = ({
  selected,
  fromItems,
  toItems,
  onSelect,
  onRemove,
  disableFrom = false,
}) => {
  const [hoverState, setHoverState] = useState('');

  const { t } = useTranslation('connectionAccessPolicyStateView');

  const fromItemsEmpty = Array.isArray(fromItems) && fromItems.length === 0;
  const toItemsEmpty = Array.isArray(toItems) && toItems.length === 0;
  const fromItemsNotEmpty = Array.isArray(fromItems) && fromItems.length > 0;

  const handleMouseEnter = (type: SelectedType) => {
    if (type === 'from' && fromItemsEmpty) {
      setHoverState('from');
    } else if (type === 'to' && toItemsEmpty) {
      setHoverState('to');
    }
  };

  const handleMouseLeave: React.MouseEventHandler<HTMLDivElement> = e => {
    setHoverState('');
  };

  const handleSelect = (type: SelectedType) => {
    // If From is clicked and is disabled and empty
    if (type === 'from' && selected === 'to' && !disableFrom) {
      return onSelect('from');
    }

    // If To is clicked and is disabled and empty and From has atleast one value
    if (type === 'to' && selected === 'from') {
      return onSelect('to');
    }
  };

  const getColumnClassName = (type: SelectedType) => {
    return selected === type
      ? 'column'
      : hoverState === type
      ? 'column column--disabled column--hovered'
      : 'column column--disabled';
  };

  return (
    <div className='connection-access-policy-state-view'>
      <div
        onMouseEnter={() => handleMouseEnter('from')}
        onMouseLeave={handleMouseLeave}
        className={getColumnClassName('from')}
        onClick={() => handleSelect('from')}
      >
        <div className={disableFrom ? 'rhis-title' : 'title'}>{t('from')}</div>
        <div className='item-container'>
          {/* {fromItemsEmpty && (
            <div className='placeholder'>
              {selected === 'from' ? t('select') : t('add')}
            </div>
          )} */}

          {/* {Array.isArray(fromItems) &&
            fromItems.map(item => (
              <Card
                key={item.id}
                label={item.label}
                type={item.type}
                onRemove={() => onRemove(item, 'from')}
              />
            ))} */}
        </div>
      </div>

      <div
        onMouseEnter={() => handleMouseEnter('to')}
        onMouseLeave={handleMouseLeave}
        className={getColumnClassName('to')}
        onClick={() => handleSelect('to')}
      >
        <div className={disableFrom ? 'rhis-title' : 'title'}>{t('to')}</div>
        <div className='item-container'>
          {/* To is not selected and empty and From has atleast one value */}
          {/* {toItemsEmpty && fromItemsNotEmpty && selected === 'from' && (
            <div className='placeholder'>{t('add')}</div>
          )} */}

          {/* To is selected and empty */}
          {/* {toItemsEmpty && selected === 'to' && (
            <div className='placeholder'>{t('select')}</div>
          )}

          {Array.isArray(toItems) &&
            toItems.map(item => (
              <Card
                key={item.id}
                label={item.label}
                type={item.type}
                onRemove={() => onRemove(item, 'to')}
              />
            ))} */}
        </div>
      </div>
    </div>
  );
};

export default ConnectionAccessPolicyStateView;
