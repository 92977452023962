import React, { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NotificationContext } from '../../components/Notifications/Context/NotificationProvider';

interface Props {
  onLogout: () => void;
}

const Logout: React.FC<Props> = ({ onLogout }) => {
  const { t } = useTranslation('logout');
  const [searchParams] = useSearchParams();
  const notification = useContext(NotificationContext);

  React.useEffect(() => {
    if (searchParams.get('error') === 'invalidToken') {
      // Trigger info toastbar
      notification.onTrigger('TOAST', {
        kind: 'info',
        lowContrast: false,
        title: t('invalidToken.title'),
        subtitle: t('invalidToken.subtitle'),
        timeout: 360000, // 1hr timeout
      });
    } else {
      // Trigger success toastbar
      notification.onTrigger('TOAST', {
        kind: 'success',
        lowContrast: false,
        title: t('success.title'),
        subtitle: '',
        timeout: 360000, // 1hr timeout
      });
    }

    onLogout();
  }, []);

  return <></>;
};

export default Logout;
