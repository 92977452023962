import React from 'react';
import { TextInput, ComboBox, TextArea, Tooltip } from '@carbon/react';
import { useTranslation } from 'react-i18next';

import AddLabels from '../../../components/AddLabels/AddLabels';
import { Column, Dropdown, Row } from 'carbon-components-react';
import { NetworkSegment } from '../../../models/master';
import { Help16 } from '@carbon/icons-react';

const AddApplicationForm = (props: {
  onChange: any;
  formData: any;
  resourceGroupList: any;
  networkSegments: NetworkSegment[] | null;
  applicationDepEnvsCount?: number;
  mode: string | undefined;
}) => {
  const {
    onChange,
    formData,
    resourceGroupList,
    networkSegments,
    applicationDepEnvsCount,
    mode,
  } = props;
  const { t } = useTranslation('addApplication');
  return (
    <div className='add-application-form'>
      <Row className='row'>
        <Column md={3}>
          <TextInput
            light
            id='addApplicationName'
            className='form-field'
            name='name'
            placeholder={t('form.name.placeholder')}
            labelText={t<string>('form.name.label')}
            value={formData.name.value}
            onChange={(e: any) => onChange('name', e.target.value)}
            invalid={formData.name.error}
            invalidText={formData.name.errorMessage}
            maxLength={60}
          />
        </Column>
        <Column md={3}>
          <ComboBox
            light
            id='addApplicationResourceGroup'
            name='resourceGroup'
            className='form-field'
            selectedItem={formData.resourceGroup.value}
            onChange={(data: any) =>
              onChange('resourceGroup', data.selectedItem)
            }
            items={resourceGroupList}
            itemToString={(item: any) => (item ? item.name : '')}
            titleText={t('form.resourceGroup.label')}
            translateWithId={t}
            placeholder={t('form.resourceGroup.placeholder')}
            invalid={formData.resourceGroup.error}
            invalidText={formData.resourceGroup.errorMessage}
          />
        </Column>
      </Row>
      <Row className='row'>
        <Column md={3}>
          <Dropdown
            light
            items={networkSegments ?? []}
            itemToString={(item: NetworkSegment) =>
              `${item?.name} (${t(item.compatibility_set)})`
            }
            id={`select-network-segment`}
            className={
              mode === 'EDIT' && applicationDepEnvsCount
                ? 'disabled-segment'
                : ''
            }
            selectedItem={formData?.networkSegment?.value ?? ''}
            titleText={
              <span className='segment-set-label'>
                <span>{`${t('form.networkSegment.label')} (${t(
                  'form.gatewaySet.label'
                )})`}</span>
                {mode === 'EDIT' && applicationDepEnvsCount ? (
                  <Tooltip
                    label={t('form.networkSegment.helpText')}
                    align='right'
                  >
                    <button className='info-tooltip-text'>
                      <Help16 />
                    </button>
                  </Tooltip>
                ) : null}
              </span>
            }
            onChange={(e: any) => onChange('networkSegment', e.selectedItem)}
            label={t('form.networkSegment.placeholder')}
            disabled={mode === 'EDIT' && applicationDepEnvsCount ? true : false}
          />
        </Column>
        <Column md={3}>
          <TextInput
            light
            id='addApplicationIdentity'
            name='identity'
            className='form-field'
            placeholder={t('form.identity.placeholder')}
            labelText={
              formData?.networkSegment?.value?.compatibility_set === 'RHSI'
                ? t('form.identity.labelOptional')
                : t('form.identity.label')
            }
            value={formData.appIdentity.value}
            onChange={(e: any) => onChange('appIdentity', e.target.value)}
            invalid={formData.appIdentity.error}
            invalidText={formData.appIdentity.errorMessage}
          />
        </Column>
      </Row>
      <Row className='row'>
        <Column md={6}>
          <AddLabels
            id='addApplicationLabels'
            labelText={t('form.labels.label')}
            placeholder={t('form.labels.placeholder')}
            onChange={data => onChange('labels', data)}
            btnText={t('form.labels.buttonText')}
            btnKind='secondary'
            tagType='green'
            defaultValues={formData.labels.value}
          />
        </Column>
      </Row>
      <Row className='row'>
        <Column md={6}>
          <TextArea
            light
            id='addApplicationDescription'
            name='description'
            className='form-field'
            autoComplete='off'
            labelText={t('form.description.label')}
            placeholder={t('form.description.placeholder')}
            onChange={(e: any) => onChange('description', e.target.value)}
            value={formData.description.value}
            maxCount={300}
            enableCounter={true}
          />
        </Column>
      </Row>
    </div>
  );
};

export default AddApplicationForm;
