import React from 'react';

import './Error403Card.scss';
import images from '../../images/images';
import { useTranslation } from 'react-i18next';

const Error500Card = () => {
  const { t } = useTranslation('error500Card');

  return (
    <div className='error403-state'>
      <div className='error403-state-icon'>{images.error500Icon()}</div>
      <div className='header'>{t('title')}</div>
      <div className='description'>{t('description')}</div>
    </div>
  );
};

export default Error500Card;
