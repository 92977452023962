import { HTTPErrorOther } from '@carbon/ibm-products';
import { useTranslation } from 'react-i18next';

export const Error500 = () => {
  const { t }: { t: any } = useTranslation('error500Page');

  return <HTTPErrorOther title={t('title')} description={t('description')} />;
};

export default Error500;
