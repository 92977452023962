import React from 'react';
import { useTranslation } from 'react-i18next';
import GenericTableWithFilters from '../../components/GenericTableWithFilters/GenericTableWithFilters';
import LabelTag from '../../components/LabelTag/LabelTag';
import { Link } from 'react-router-dom';
import {
  AppliedFilter,
  NetworkSegments,
  ResourceGroup,
} from '../../models/master';

import './NetworkSegmentsTable.scss';
import GenericTruncateString from '../../components/GenericTruncateString/GenericTruncateString';
import images from '../../images/images';
import { TooltipDefinition } from 'carbon-components-react';
import GenericStatusField from '../../components/GenericStatusField/GenericStatusField';

interface NwSegmentTableRows {
  id: any;
  name:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
  name__format: JSX.Element;
  gatewaySet: JSX.Element;
  infrastructureGroup: JSX.Element;
  description: JSX.Element;
  labels: JSX.Element | null | string;
  flow_collector: string;
}

const NetworkSegmentsTable = (props: {
  filteredDataCallback: (data: any) => void;
  filtersSelected: any;
  filtersAppliedCallback: (data: any) => void;
  filters: any;
  rows: NetworkSegments[] | null;
  data: NetworkSegments[] | null;
  filteredDataSet: NetworkSegments[] | null;
  elementCount: number;
  onRefresh: () => void;
  currentPageNumber: number;
  currentPageSize: number;
  onPageChange: (pageData: any) => void;
  sortRows(arg0: unknown, direction: string): void;
  resourceGroupsList: ResourceGroup[] | null;
  persistFilter?: boolean;
  error403Flag: boolean;
  error500Flag: boolean;
  resourceGroupPermission: boolean;
}) => {
  const { t } = useTranslation('networkSegments');
  const { rows, onRefresh } = props;
  const error403Flag = props.error403Flag;
  const error500Flag = props.error500Flag;
  const formatTags = (data: string[]) => {
    return <LabelTag labelArray={data} count={3} />;
  };

  const myheaders = [
    {
      key: 'name__format',
      header: t('tableHeaders.name'),
      sort: 'sortByName',
      originalKey: 'name',
    },
    {
      key: 'flow_collector',
      originalKey: 'flow_collector',
      header: t('tableHeaders.metricCollection'),
      style: { minWidth: '9rem' },
    },
    {
      key: 'gatewaySet',
      originalKey: 'gatewaySet',
      header: t('tableHeaders.gatewaySet'),
    },
    {
      key: 'infrastructureGroup',
      originalKey: 'infrastructureGroup',
      header: t('tableHeaders.infrastructureGroup'),
    },
    {
      key: 'labels',
      originalKey: 'labels',
      header: t('tableHeaders.labels'),
    },
    {
      key: 'description',
      originalKey: 'description',
      header: t('tableHeaders.description'),
    },
  ];

  const getResourceGroupName = (id: string) => {
    const resouceGroup =
      id &&
      Array.isArray(props.resourceGroupsList) &&
      props.resourceGroupsList.find(
        resouceGroup => resouceGroup.resource_id === id
      );

    return resouceGroup ? resouceGroup.name : '';
  };

  /**
   * @description - Function to format the raw data into table rows format.
   * @returns {NwSegmentTableRows[] | [] | null} - Returns the formatted data to be rendered as table rows.
   */

  const setRowsData = () => {
    let formattedRows: NwSegmentTableRows[] = [];
    if (error403Flag) return [];
    if (error500Flag) return [];
    if (Array.isArray(rows) && rows.length === 0) return [];
    if (rows)
      rows.map((row: NetworkSegments) => {
        formattedRows.push({
          id: row.resource_id,
          name: !!row.name ? row.name : '—',
          name__format: (
            <Link
              className='no-underline-link'
              to={`/networkSegmentDetails?nwSegId=${row.resource_id}`}
            >
              <GenericTruncateString str={row?.name} tableView={true} />
            </Link>
          ),
          gatewaySet: (
            <GenericTruncateString
              str={
                row?.compatibility_set ? t(`${row?.compatibility_set}`) : '—'
              }
              tableView={true}
              maxLength={20}
              limit={15}
            />
          ),
          infrastructureGroup: !props.resourceGroupPermission ? (
            <GenericStatusField status={'notAuthorised'} />
          ) : (
            <GenericTruncateString
              str={getResourceGroupName(row.resource_group_id)}
              tableView={true}
              maxLength={35}
              limit={20}
            />
          ),
          description: row.description ? (
            <TooltipDefinition
              tooltipText={row.description}
              className='generic-truncate-string'
            >
              <div className='description-container'>
                <div className='description'>{row.description}</div>
              </div>
            </TooltipDefinition>
          ) : (
            <>—</>
          ),
          labels: !!row.labels ? formatTags(row.labels) : '—',
          flow_collector: row?.flow_collector ? t('enabled') : '',
        });
        return 0;
      });
    else return null;
    return formattedRows;
  };

  const emptyStateData = {
    icon: <></>,
    notFoundIcon: images.NotFoundLarge(),
    emptyStateHeader: t('emptyState.emptyContainerHeader'),
    emptyStateDescription: t('emptyState.emptyContainerDescription'),
  };

  return (
    <GenericTableWithFilters
      id='table-with-filter-panel'
      rows={setRowsData()}
      data={props.filteredDataSet}
      headers={myheaders}
      isSortable={true}
      totalElementsCount={props.elementCount ? props.elementCount : 0}
      fullData={props.data}
      onTableRefresh={() => onRefresh()}
      filteredDataCallback={(data: NwSegmentTableRows[]) =>
        props.filteredDataCallback(data)
      }
      selectedFiltersVal={props.filtersSelected as any}
      setFilterApplied={(data: AppliedFilter[]) =>
        props.filtersAppliedCallback(data)
      }
      filters={props.filters}
      currentPageNumber={props.currentPageNumber}
      currentPageSize={props.currentPageSize}
      onPageChange={(pageData: any) => props.onPageChange(pageData)}
      emptyState={emptyStateData}
      sortRows={(data, sortDirection) => props.sortRows(data, sortDirection)}
      persistFilter={props.persistFilter}
      render403Container={props.error403Flag}
      render500Container={props.error500Flag}
    />
  );
};
export default NetworkSegmentsTable;
