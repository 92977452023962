import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import InlineNotification from '../../../components/Notifications/Inline/Notification';
import { NotificationContext } from '../../../components/Notifications/Context/NotificationProvider';
import { updatePolicy } from '../../../controllers/policyApi';
import { getResourceGroups } from '../../../controllers/resourceGroupApi';

import {
  ApplicationData,
  PartitionData,
  ResourceGroup,
  Service,
} from '../../../models/master';
import './EditPolicyConnection.scss';
import EditConnections from '../ConnectionAccessPoliciesEdit/EditConnections';
import WideTearsheet from '../../../components/WideTearsheet/WideTearsheet';
import { getDeploymentEnvsPartitions } from '../../../controllers/deploymentEnv';
import PolicyOptions from '../ConnectionAccessPoliciesOptions/PolicyOptions';
import { Item, SelectedType, TableTypes } from '../config';

interface ServiceData extends Service {
  id: string;
  applicationName: string;
}

interface Props {
  open: boolean;
  onClose: () => void;
  policyDetailsData?: any | null;
  onRefresh: (t: TableTypes) => void;
  applications: ApplicationData[] | null;
  services: ServiceData[] | null;
  resourceGroups: ResourceGroup[] | null;
  editedPolicy: () => void;
  updatePolicyConnData?: (data: any) => void;
}

const EditPolicyConnection: React.FC<Props> = ({
  open,
  onClose,
  policyDetailsData,
  onRefresh,
  applications,
  services,
  resourceGroups,
  updatePolicyConnData,
  editedPolicy,
}) => {
  const partitionPolicy = policyDetailsData?.from?.type === 'partition';
  const form = {
    step1: {
      name: {
        value: '',
        error: false,
        errorMessage: '',
      },
      description: {
        value: '',
        error: false,
        errorMessage: '',
      },
      resourceGroup: {
        value: '',
        error: false,
        errorMessage: '',
      },
      resourceGroupList: [],
      labels: {
        value: [],
        error: false,
        errorMessage: '',
      },
    },
    step2: {
      from: [
        {
          id: partitionPolicy
            ? policyDetailsData?.from?.partition?.partition_id
            : policyDetailsData?.from?.application?.application_id,
          label: partitionPolicy
            ? policyDetailsData?.partitionData?.name ?? ''
            : policyDetailsData?.appData?.name ?? '',
          type: partitionPolicy ? 'partitions' : 'applications',
          parentId: partitionPolicy
            ? policyDetailsData?.from?.deployment_env?.deployment_env_id
            : '',
        },
      ],
      to: [
        {
          id: policyDetailsData?.to?.service?.service_id,
          label: policyDetailsData?.serviceData?.name ?? '',
          type: 'services',
        },
      ],
    },
  };

  const [formData, setFormData] = useState<{
    step1: typeof form.step1;
    step2: any;
  }>(form);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);

  const { t } = useTranslation('editPolicy');

  const notification = useContext(NotificationContext);

  const [changed, setChanged] = useState(false);

  const [authError, setAuthError] = useState(false);
  const [showFailNotification, toggleFailNotification] = useState(false);
  const [subTitleErrorMsg, setSubTitleErrorMsg] = useState('');
  const [partitions, setPartitions] = useState<PartitionData[] | null>(null);
  const [viewAllOptions, setViewAllOptions] = useState(false);
  const [selected, setSelected] = useState<SelectedType>('from');
  const [fromSelectedItems, setFromSelectedItems] = useState<Item[]>(
    form.step2.from as Item[]
  );
  const [toSelectedItems, setToSelectedItems] = useState<Item[]>(
    form.step2.to as Item[]
  );
  const [fromItems, setFromItems] = useState<Item[]>(form.step2.from as Item[]);
  const [toItems, setToItems] = useState<Item[]>(form.step2.to as Item[]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getResourceGroups().then(response => {
      setFormData(form => ({
        ...form,
        step1: {
          ...form.step1,
          resourceGroupList: response.resource_groups?.filter(
            (group: { type: string }) => group?.type === 'application'
          ),
        },
      }));
    });
    if (open) fetchDeploymentEnvsPartitions();
  }, []);

  useEffect(() => {
    handleStep2({ from: fromItems, to: toItems });
  }, [fromItems, toItems]);

  const fetchDeploymentEnvsPartitions = async () => {
    try {
      const envsData = await getDeploymentEnvsPartitions();

      const partitions = [];
      for (const deplEnv of envsData) {
        const envPartitions = deplEnv.partitions.map((partition: any) => ({
          ...partition,
          id: `${partition.resource_id}`,
          resource_group_id: deplEnv.resource_group_id,
          resourceGroups: deplEnv.resource_group_id,
          deploymentEnvId: deplEnv.resource_id,
          cloud_name: deplEnv.cloud_name,
          location_name: deplEnv.location_name,
          environment: deplEnv.name,
        }));
        partitions.push(...envPartitions);
      }
      setPartitions(partitions as PartitionData[]);
      return partitions;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const handleStep2 = (data: any) => {
    if (showErrorSnackbar) {
      setShowErrorSnackbar(false);
      setAuthError(false);
    }
    const fromId = data?.from[0]?.id;
    const toId = data?.to[0]?.id;
    const toIdTrim = toId?.split('+')[0];
    if (
      (fromId !== policyDetailsData?.appData?.resource_id ||
        toIdTrim !== policyDetailsData?.to?.service?.service_id) &&
      fromId !== undefined &&
      toId !== undefined &&
      fromId !== null &&
      toId !== null
    ) {
      setChanged(true);
    } else {
      setChanged(false);
    }

    setFormData(form => ({
      ...form,
      step2: data,
    }));
  };

  const checkFieldValid = (name: string, value: any) => {
    let errorMessage = '';

    switch (name) {
      case 'name':
        if (value === '' || value === null || value === undefined)
          errorMessage = t('nameError');
        break;
      case 'resourceGroup':
        if (value === '' || value === null || value === undefined)
          errorMessage = t('resourceGroupError');
        break;
    }

    return errorMessage;
  };

  const checkFormValid = () => {
    let isFormValid = true;
    let nameError = checkFieldValid('name', formData.step1.name.value);
    let resourceGroupErr = checkFieldValid(
      'resourceGroup',
      formData.step1.resourceGroup.value
    );
    if (nameError.length > 0 || resourceGroupErr.length > 0) {
      isFormValid = false;
    }
    if (nameError.length > 0) {
      isFormValid = false;
    }

    return isFormValid;
  };

  const handlePolicySubmit = async () => {
    try {
      setLoading(true);
      const servId = formData.step2.to[0].id.split('+')[0];
      const servId_service = services?.filter(d => d.resource_id === servId);
      const type = formData.step2.from[0].type;
      // Clear error snackbar if present
      if (showErrorSnackbar) {
        setShowErrorSnackbar(false);
        setAuthError(false);
      }

      const policyDetails = {
        ...policyDetailsData,
        from:
          type === 'applications'
            ? {
                application: { application_id: formData.step2.from[0].id },
                type: 'application',
              }
            : type === 'partitions'
            ? {
                deployment_env: {
                  deployment_env_id: formData.step2.from[0].parentId,
                },
                partition: {
                  partition_id: formData.step2.from[0].id,
                },
                type: 'partition',
              }
            : {},
        to: {
          service: {
            application_id: servId_service
              ? servId_service[0].application_id
              : '',
            service_id: formData.step2.to[0].id
              ? formData.step2.to[0].id.split('+')[0]
              : '',
          },
          type: 'service',
        },
        action: 'ALLOW',
      };
      const policy = await updatePolicy(
        policyDetailsData.resource_id,
        policyDetails
      );
      // Refreshes data on successful policy addition
      if (updatePolicyConnData) {
        updatePolicyConnData(policy);
      }
      notification.onTrigger('TOAST', {
        title: t('toast.title'),
        subtitle: t('toast.subtitle', { name: policy.name }),
      });

      setChanged(false);

      // close modal and fetch new data in parent
      // TODO: navigate to card view when functionality is added
      editedPolicy();
    } catch (error: any) {
      const err = error as AxiosError;
      const errorMessage: string =
        error.response !== undefined
          ? error.response['customErrorMessage']
          : '';
      console.log(err);
      // TODO: We need to refactor error handling mechanism given below.
      // Right now only handling duplicate policy name assuming that all 422 errors are caused by it,
      // since we are not getting any proper unique error key.
      if (err.response?.status === 422) {
        setAuthError(false);
        toggleFailNotification(true);
        errorMessage.length > 0 && setSubTitleErrorMsg(errorMessage);
        // We added this project.reject to prevent the modal closing when error occured.
        // But in latest version of '@carbon/ibm-products' the below line is not required and is throwing error.
        // return Promise.reject(() => console.log(error));
      }

      if (err.response?.status === 403) {
        setAuthError(true);
        toggleFailNotification(true);
        // We added this project.reject to prevent the modal closing when error occured.
        // But in latest version of '@carbon/ibm-products' the below line is not required and is throwing error.
        // return Promise.reject(() => console.log(error));
      }

      errorMessage.length > 0 && setSubTitleErrorMsg(errorMessage);
      setAuthError(false);
      toggleFailNotification(true);
      // We added this project.reject to prevent the modal closing when error occured.
      // But in latest version of '@carbon/ibm-products' the below line is not required and is throwing error.
      // return Promise.reject(() => console.log(error));
    } finally {
      setLoading(false);
    }
  };

  const handleErrorBarClose = () => {
    toggleFailNotification(false);
    setAuthError(false);
  };

  const handleRefresh = (type: TableTypes) => {
    // TODO - in future, may have to move applications and services api calls here
    switch (type) {
      case 'partitions': {
        setPartitions(null);
        return fetchDeploymentEnvsPartitions();
      }
      default: {
        onRefresh(type);
      }
    }
  };

  const onViewAllOptions = (open: boolean, type: SelectedType) => {
    setViewAllOptions(open);
    setSelected(type);
  };

  const onRemoveOptions = (data: Item, type: SelectedType) => {
    if (type === 'from') {
      setFromItems(items => items.filter(item => item.id !== data.id));
      setFromSelectedItems(items => items.filter(item => item.id !== data.id));
    } else {
      setToItems(items => items.filter(item => item.id !== data.id));
      setToSelectedItems(items => items.filter(item => item.id !== data.id));
    }
  };

  const onCloseViewOptions = (saveOption: boolean = false) => {
    if (saveOption)
      if (selected === 'from') {
        setFromItems([...fromSelectedItems]);
      } else {
        setToItems([...toSelectedItems]);
      }
    else {
      if (selected === 'from') {
        setFromSelectedItems([...fromItems]);
      } else {
        setToSelectedItems([...toItems]);
      }
    }
    onViewAllOptions(false, selected);
  };

  const handleCheckbox = (value: boolean, data: Item) => {
    if (selected === 'from') {
      value
        ? setFromSelectedItems(items => [...items, data])
        : setFromSelectedItems(items =>
            items.filter(item => item.id !== data.id)
          );
    } else {
      value
        ? setToSelectedItems(items => [...items, data])
        : setToSelectedItems(items =>
            items.filter(item => item.id !== data.id)
          );
    }
  };

  const onAddOptions = (data: Item) => {
    if (selected === 'from') {
      setFromItems([]);
      setFromSelectedItems([]);
      setFromItems(items => [...items, data]);
      setFromSelectedItems(items => [...items, data]);
    } else {
      setToItems([]);
      setToSelectedItems([]);
      setToItems(items => [...items, data]);
      setToSelectedItems(items => [...items, data]);
    }
  };

  const handleSelect = (type: SelectedType) => {
    setSelected(type);
  };

  const getTableData = (type: TableTypes) => {
    switch (type) {
      case 'applications': {
        if (Array.isArray(applications)) {
          const appData = applications.map(app => {
            return {
              id: app.resource_id,
              name: app.name,
              resourceGroupName: resourceGroups?.filter(
                resourceGroup =>
                  resourceGroup?.resource_id === app?.resource_group_id
              )[0]?.name,
              resourceGroups: app.resource_group_id,
              services: app.services.length,
              labels: app.labels,
            };
          });
          return appData;
        }
        return null;
      }
      case 'partitions': {
        const partitionData = partitions?.map(partition => {
          return {
            ...partition,
            resourceGroupName: resourceGroups?.filter(
              resourceGroup =>
                resourceGroup?.resource_id === partition?.resource_group_id
            )[0]?.name,
            typeName: t(partition.type),
          };
        });
        return partitionData || null;
      }
      case 'services': {
        const serviceData = services?.map(service => {
          return {
            ...service,
            id: service.resource_id,
          };
        });

        return serviceData || null;
      }
      case 'nwSegments':
        return null;
    }
  };

  return (
    <div>
      <WideTearsheet
        title={t('conntitle')}
        description={t('conndescription')}
        open={open}
        actions={[
          {
            kind: 'primary',
            label: t('saveButtonText'),
            onClick: () => handlePolicySubmit(),
            disabled: !changed,
            loading: loading,
          },
          {
            kind: 'secondary',
            label: t('cancelButtonText'),
            onClick: () => {
              onClose();
              toggleFailNotification(false);
              setChanged(false);
              setAuthError(false);
            },
          },
        ]}
      >
        {showFailNotification && (
          <InlineNotification
            kind='error'
            title={
              authError
                ? (t('error.authTitle') as string)
                : (t('error.title') as string)
            }
            subtitle={
              authError
                ? (t('error.authSubtitle') as string)
                : subTitleErrorMsg.length > 0
                ? subTitleErrorMsg
                : (t('error.subtitle') as string)
            }
            onClose={() => handleErrorBarClose() as any}
          />
        )}
        <EditConnections
          fromItems={fromItems}
          toItems={toItems}
          selected={selected}
          viewAllOptions={viewAllOptions}
          onViewAllOptions={onViewAllOptions}
          onRemoveOptions={onRemoveOptions}
          onAddOptions={onAddOptions}
          onSelect={handleSelect}
          getTableData={getTableData}
        ></EditConnections>
      </WideTearsheet>
      <PolicyOptions
        open={viewAllOptions}
        fromItems={fromItems}
        toItems={toItems}
        selected={selected}
        fromSelectedItems={fromSelectedItems}
        toSelectedItems={toSelectedItems}
        onRefresh={onRefresh}
        onClose={onCloseViewOptions}
        onOptionsChange={handleCheckbox}
        getTableData={getTableData}
      />
    </div>
  );
};

export default EditPolicyConnection;
