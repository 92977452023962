import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Accordion,
  AccordionItem,
  Column,
  Tabs,
  Tab,
  Search,
  SkeletonText,
  SkeletonPlaceholder,
} from 'carbon-components-react';
import { Checkmark16, Search16, Edit16 } from '@carbon/icons-react';
import {
  Permission,
  ResourceGroup,
  ResourceTypeCategoryMapInterface,
  ResourceGroupCategoryMapInterface,
} from '../../../models/master';
import './RoleDetailsPermissionTable.scss';
import {
  permissionTableCategories,
  resourceGroupCategories,
  resourceTypeCategoryMap,
  tabConfig,
} from '../config';
import IconWithToolTip from '../../../components/IconWithToolTip/IconWithToolTip';

interface PropsInterface {
  rows?: Permission[] | null;
  loading?: boolean;
  style?: any;
  openEditPermission?: () => void;
  resourceGroupsData: ResourceGroup[] | null;
  isEditable: boolean;
  updateResourceTypeMapState: (map: ResourceTypeCategoryMapInterface[]) => void;
  updateResourceGroupMapState: (
    map: ResourceGroupCategoryMapInterface[]
  ) => void;
  tabIndex: number;
  setTabIndex: (index: number) => void;
}

const RoleDetailsPermissionTable = (Props: PropsInterface) => {
  const {
    rows,
    loading,
    style,
    openEditPermission,
    resourceGroupsData,
    isEditable,
    updateResourceTypeMapState,
    updateResourceGroupMapState,
    tabIndex,
    setTabIndex,
  } = Props;

  const { t } = useTranslation('roleDetails');
  const resourceTypeMapList = resourceTypeCategoryMap(t);
  const [searchText, setSearchText] = useState('');
  const [resourceTypeMap, setResourceTypeMap] = useState<
    ResourceTypeCategoryMapInterface[] | null
  >();
  const [resourceGroupMap, setResourceGroupMap] = useState<
    ResourceGroupCategoryMapInterface[] | null
  >();

  const hydrateResourceTypeMap = (
    resourceTypeMap: ResourceTypeCategoryMapInterface[]
  ) => {
    let markAllTrue: boolean = false;
    rows != null &&
      rows.forEach((permission: Permission) => {
        var foundIndex = resourceTypeMap.findIndex(
          (resource: ResourceTypeCategoryMapInterface) =>
            resource.id === permission.subject_resource_type &&
            (permission.subject_resource_id === '*' ||
              permission.subject_resource_id === '')
        );
        if (foundIndex > -1) {
          switch (permission.access_type) {
            case '*':
              Object.keys(resourceTypeMap[foundIndex].access_type).forEach(
                key => {
                  resourceTypeMap[foundIndex].access_type[
                    key as keyof ResourceTypeCategoryMapInterface['access_type']
                  ] = true;
                }
              );
              Object.keys(
                resourceTypeMap[foundIndex].edited_access_type
              ).forEach(key => {
                resourceTypeMap[foundIndex].edited_access_type[
                  key as keyof ResourceTypeCategoryMapInterface['edited_access_type']
                ].value = true;
              });

              break;
            case 'ADMIN':
              resourceTypeMap[foundIndex].access_type.admin = true;
              resourceTypeMap[foundIndex].edited_access_type.admin.value = true;
              break;

            case 'CREATE':
              resourceTypeMap[foundIndex].access_type.create = true;
              resourceTypeMap[foundIndex].edited_access_type.create.value =
                true;
              break;

            case 'READ':
              resourceTypeMap[foundIndex].access_type.read = true;
              resourceTypeMap[foundIndex].edited_access_type.read.value = true;
              break;

            case 'UPDATE':
              resourceTypeMap[foundIndex].access_type.update = true;
              resourceTypeMap[foundIndex].edited_access_type.update.value =
                true;
              break;

            case 'DELETE':
              resourceTypeMap[foundIndex].access_type.delete = true;
              resourceTypeMap[foundIndex].edited_access_type.delete.value =
                true;
              break;

            default:
              break;
          }

          markAllTrue =
            resourceTypeMap[foundIndex].access_type.all !== true &&
            Object.keys(resourceTypeMap[foundIndex].access_type).every(key =>
              key !== 'all'
                ? resourceTypeMap[foundIndex].access_type[
                    key as keyof ResourceTypeCategoryMapInterface['access_type']
                  ] === true
                : true
            );
          if (markAllTrue) {
            Object.keys(resourceTypeMap[foundIndex].access_type).forEach(
              key => {
                resourceTypeMap[foundIndex].access_type[
                  key as keyof ResourceTypeCategoryMapInterface['access_type']
                ] = true;
              }
            );
            Object.keys(resourceTypeMap[foundIndex].edited_access_type).forEach(
              key => {
                resourceTypeMap[foundIndex].edited_access_type[
                  key as keyof ResourceTypeCategoryMapInterface['edited_access_type']
                ].value = true;
              }
            );
          }
        }
      });
    updateResourceTypeMapState(resourceTypeMap);
    setResourceTypeMap(resourceTypeMap);
  };

  const hydrateResourceGroupMap = () => {
    let resourceGroupMapObj: ResourceGroupCategoryMapInterface = {
        id: '',
        category: '',
        label: '',
        resource_access_type: {
          all: false,
          admin: false,
          read: false,
          update: false,
          delete: false,
        },
        resource_group_access_type: {
          all: false,
          admin: false,
          create: false,
          read: false,
          update: false,
          delete: false,
        },
        edited_resource_access_type: {
          all: { status: 'default', value: false },
          admin: { status: 'default', value: false },
          read: { status: 'default', value: false },
          update: { status: 'default', value: false },
          delete: { status: 'default', value: false },
        },
        edited_resource_group_access_type: {
          all: { status: 'default', value: false },
          admin: { status: 'default', value: false },
          create: { status: 'default', value: false },
          read: { status: 'default', value: false },
          update: { status: 'default', value: false },
          delete: { status: 'default', value: false },
        },
      },
      resourceGroupMapObjArr: ResourceGroupCategoryMapInterface[] = [],
      markAllTrue: boolean = false,
      markGroupReadAccess: boolean = false;

    if (Array.isArray(resourceGroupsData) && resourceGroupsData.length > 0) {
      resourceGroupsData.forEach((rg: ResourceGroup) => {
        let index = resourceGroupMapObjArr?.findIndex(
          (rgMapObj: ResourceGroupCategoryMapInterface) =>
            rgMapObj.id === rg.resource_id
        );
        if (index > -1) {
          resourceGroupMapObj = Object.assign(
            {},
            resourceGroupMapObjArr[index]
          );
        } else {
          resourceGroupMapObj = Object.assign(
            {},
            {
              id: rg.resource_id,
              category: rg.type,
              label: rg.name,
              resource_access_type: {
                all: false,
                admin: false,
                read: false,
                update: false,
                delete: false,
              },
              resource_group_access_type: {
                all: false,
                admin: false,
                create: false,
                read: false,
                update: false,
                delete: false,
              },
              edited_resource_access_type: {
                all: { status: 'default', value: false },
                admin: { status: 'default', value: false },
                read: { status: 'default', value: false },
                update: { status: 'default', value: false },
                delete: { status: 'default', value: false },
              },
              edited_resource_group_access_type: {
                all: { status: 'default', value: false },
                admin: { status: 'default', value: false },
                create: { status: 'default', value: false },
                read: { status: 'default', value: false },
                update: { status: 'default', value: false },
                delete: { status: 'default', value: false },
              },
            }
          );
        }

        Array.isArray(rows) &&
          rows.length > 0 &&
          rows?.forEach((rolepermission: Permission) => {
            if (
              rolepermission.subject_resource_type === 'resourcegroup' ||
              rolepermission.subject_resource_type === '*'
            ) {
              if (rolepermission.subject_resource_id === rg.resource_id) {
                switch (rolepermission.access_type) {
                  case 'READ':
                    resourceGroupMapObj.resource_access_type.read = true;
                    resourceGroupMapObj.edited_resource_access_type.read.value =
                      true;
                    break;

                  case 'UPDATE':
                    resourceGroupMapObj.resource_access_type.update = true;
                    resourceGroupMapObj.edited_resource_access_type.update.value =
                      true;
                    break;

                  case 'DELETE':
                    resourceGroupMapObj.resource_access_type.delete = true;
                    resourceGroupMapObj.edited_resource_access_type.delete.value =
                      true;
                    break;

                  case 'ADMIN':
                    resourceGroupMapObj.resource_access_type.admin = true;
                    resourceGroupMapObj.edited_resource_access_type.admin.value =
                      true;
                    break;

                  case '*':
                    Object.keys(
                      resourceGroupMapObj.resource_access_type
                    ).forEach(key => {
                      resourceGroupMapObj.resource_access_type[
                        key as keyof ResourceGroupCategoryMapInterface['resource_access_type']
                      ] = true;
                    });
                    Object.keys(
                      resourceGroupMapObj.edited_resource_access_type
                    ).forEach(key => {
                      resourceGroupMapObj.edited_resource_access_type[
                        key as keyof ResourceGroupCategoryMapInterface['resource_access_type']
                      ].value = true;
                    });
                    break;
                  default:
                    break;
                }

                markAllTrue =
                  resourceGroupMapObj.resource_access_type.all !== true &&
                  Object.keys(resourceGroupMapObj.resource_access_type).every(
                    key =>
                      key !== 'all'
                        ? resourceGroupMapObj.resource_access_type[
                            key as keyof ResourceTypeCategoryMapInterface['access_type']
                          ] === true
                        : true
                  );
                if (markAllTrue) {
                  Object.keys(resourceGroupMapObj.resource_access_type).forEach(
                    key => {
                      resourceGroupMapObj.resource_access_type[
                        key as keyof ResourceTypeCategoryMapInterface['access_type']
                      ] = true;
                    }
                  );
                  Object.keys(
                    resourceGroupMapObj.edited_resource_access_type
                  ).forEach(key => {
                    resourceGroupMapObj.edited_resource_access_type[
                      key as keyof ResourceTypeCategoryMapInterface['edited_access_type']
                    ].value = true;
                  });
                }
              }

              if (rolepermission.subject_resource_group_id === rg.resource_id) {
                switch (rolepermission.access_type) {
                  case 'CREATE':
                    resourceGroupMapObj.resource_group_access_type.create =
                      true;
                    resourceGroupMapObj.edited_resource_group_access_type.create.value =
                      true;
                    break;

                  case 'READ':
                    resourceGroupMapObj.resource_group_access_type.read = true;
                    resourceGroupMapObj.edited_resource_group_access_type.read.value =
                      true;
                    break;

                  case 'UPDATE':
                    resourceGroupMapObj.resource_group_access_type.update =
                      true;
                    resourceGroupMapObj.edited_resource_group_access_type.update.value =
                      true;
                    break;

                  case 'DELETE':
                    resourceGroupMapObj.resource_group_access_type.delete =
                      true;
                    resourceGroupMapObj.edited_resource_group_access_type.delete.value =
                      true;
                    break;

                  case 'ADMIN':
                    resourceGroupMapObj.resource_group_access_type.admin = true;
                    resourceGroupMapObj.edited_resource_group_access_type.admin.value =
                      true;
                    break;

                  case '*':
                    Object.keys(
                      resourceGroupMapObj.resource_group_access_type
                    ).forEach(key => {
                      resourceGroupMapObj.resource_group_access_type[
                        key as keyof ResourceGroupCategoryMapInterface['resource_access_type']
                      ] = true;
                    });
                    Object.keys(
                      resourceGroupMapObj.edited_resource_group_access_type
                    ).forEach(key => {
                      resourceGroupMapObj.edited_resource_group_access_type[
                        key as keyof ResourceGroupCategoryMapInterface['resource_access_type']
                      ].value = true;
                    });

                    break;
                  default:
                    break;
                }
                markAllTrue =
                  resourceGroupMapObj.resource_group_access_type.all !== true &&
                  Object.keys(
                    resourceGroupMapObj.resource_group_access_type
                  ).every(key =>
                    key !== 'all'
                      ? resourceGroupMapObj.resource_access_type[
                          key as keyof ResourceTypeCategoryMapInterface['access_type']
                        ] === true
                      : true
                  );
                if (markAllTrue) {
                  Object.keys(
                    resourceGroupMapObj.resource_group_access_type
                  ).forEach(key => {
                    resourceGroupMapObj.resource_group_access_type[
                      key as keyof ResourceTypeCategoryMapInterface['access_type']
                    ] = true;
                  });
                  Object.keys(
                    resourceGroupMapObj.edited_resource_group_access_type
                  ).forEach(key => {
                    resourceGroupMapObj.edited_resource_group_access_type[
                      key as keyof ResourceTypeCategoryMapInterface['edited_access_type']
                    ].value = true;
                  });
                }

                markGroupReadAccess = Object.keys(
                  resourceGroupMapObj.edited_resource_group_access_type
                ).some(key =>
                  key !== 'all'
                    ? resourceGroupMapObj.edited_resource_group_access_type[
                        key as keyof ResourceGroupCategoryMapInterface['edited_resource_group_access_type']
                      ].value === true
                    : false
                );
                resourceGroupMapObj.edited_resource_access_type.read.value =
                  markGroupReadAccess;
                resourceGroupMapObj.edited_resource_access_type.read.status =
                  resourceGroupMapObj.resource_access_type.read ===
                  markGroupReadAccess
                    ? 'default'
                    : 'edited';
                resourceGroupMapObj.edited_resource_access_type.read.disabled =
                  markGroupReadAccess;
              }
            }
          });

        index > -1 &&
          resourceGroupMapObjArr.splice(index, 1, resourceGroupMapObj);
        index === -1 && resourceGroupMapObjArr?.push(resourceGroupMapObj);
      });
    }

    updateResourceGroupMapState(resourceGroupMapObjArr);
    setResourceGroupMap(resourceGroupMapObjArr);
  };

  useEffect(() => {
    hydrateResourceGroupMap();
  }, []);

  useEffect(() => {
    hydrateResourceTypeMap(resourceTypeMapList);
    hydrateResourceGroupMap();
  }, [rows, resourceGroupsData]);

  const applySearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const componentByResourceType = (category: string) => {
    let tableRow: any = [];
    if (rows != null) {
      resourceTypeMap != null &&
        resourceTypeMap
          .filter((resourceType: ResourceTypeCategoryMapInterface) =>
            resourceType.label.toLowerCase().includes(searchText.toLowerCase())
          )
          .forEach((resourceType: ResourceTypeCategoryMapInterface) => {
            if (
              resourceType.category === category &&
              resourceType.display === true
            ) {
              tableRow.push(
                <Row
                  className={`resource-type-row ${resourceType.id}`}
                  key={resourceType.id}
                >
                  <Column lg={6} md={3} className='resource-type-col'>
                    {resourceType.label}
                  </Column>
                  <Column lg={2} md={1} className='resource-type-col'>
                    {resourceType.access_type['all'] === true ||
                    resourceType.access_type['create'] === true ? (
                      <span className='checked'>
                        <Checkmark16 />
                      </span>
                    ) : (
                      ''
                    )}
                  </Column>
                  <Column lg={2} md={1} className='resource-type-col'>
                    {resourceType.access_type['all'] === true ||
                    resourceType.access_type['read'] === true ? (
                      <span className='checked'>
                        <Checkmark16 />
                      </span>
                    ) : (
                      ''
                    )}
                  </Column>
                  <Column lg={2} md={1} className='resource-type-col'>
                    {resourceType.access_type['all'] === true ||
                    resourceType.access_type['update'] === true ? (
                      <span className='checked'>
                        <Checkmark16 />
                      </span>
                    ) : (
                      ''
                    )}
                  </Column>
                  <Column lg={2} md={1} className='resource-type-col'>
                    {resourceType.access_type['all'] === true ||
                    resourceType.access_type['delete'] === true ? (
                      <span className='checked'>
                        <Checkmark16 />
                      </span>
                    ) : (
                      ''
                    )}
                  </Column>
                  <Column lg={2} md={1} className='resource-type-col'>
                    {resourceType.access_type['all'] === true ||
                    resourceType.access_type['admin'] === true ? (
                      <span className='checked'>
                        <Checkmark16 />
                      </span>
                    ) : (
                      ''
                    )}
                  </Column>
                </Row>
              );
            }
          });
    }

    return tableRow;
  };

  const componentByResourceGroup = (grouptype: string) => {
    let tableRow: any = [],
      resourceGroupTableData =
        resourceGroupMap != null &&
        resourceGroupMap.filter(
          (rg: ResourceGroupCategoryMapInterface) => rg.category === grouptype
        );
    Array.isArray(resourceGroupTableData) &&
      resourceGroupTableData
        .filter((rg: ResourceGroupCategoryMapInterface) =>
          rg.label.toLowerCase().includes(searchText.toLowerCase())
        )
        .forEach((rg: ResourceGroupCategoryMapInterface) => {
          tableRow.push(
            <Row className={`resource-group-row ${rg.id}`} key={rg.id}>
              <Column lg={4} md={2}>
                <span className='rg_name'>{rg.label}</span>
              </Column>
              <Column lg={2} md={1} className='resource-group-col'>
                <div className='labels'>
                  <span className='group-check'>
                    {t('rolePermissionTable.groupLabel')}
                  </span>
                  <span className='resources-check'>
                    {t('rolePermissionTable.resourcesLabel')}
                  </span>
                </div>
              </Column>
              <Column lg={2} md={1} className='resource-group-col'>
                <div className='group-check'></div>
                <div className='resources-check'>
                  {rg.resource_group_access_type.create === true ||
                  rg.resource_group_access_type.all === true ? (
                    <span className='checked'>
                      <Checkmark16 />
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </Column>
              <Column lg={2} md={1} className='resource-group-col'>
                <div className='group-check'>
                  {rg.resource_access_type.read === true ||
                  rg.resource_access_type.all === true ? (
                    <span className='checked'>
                      <Checkmark16 />
                    </span>
                  ) : (
                    ''
                  )}
                </div>
                <div className='resources-check'>
                  {rg.resource_group_access_type.read === true ||
                  rg.resource_group_access_type.all === true ? (
                    <span className='checked'>
                      <Checkmark16 />
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </Column>
              <Column lg={2} md={1} className='resource-group-col'>
                <div className='group-check'>
                  {rg.resource_access_type.update === true ||
                  rg.resource_access_type.all === true ? (
                    <span className='checked'>
                      <Checkmark16 />
                    </span>
                  ) : (
                    ''
                  )}
                </div>
                <div className='resources-check'>
                  {rg.resource_group_access_type.update === true ||
                  rg.resource_group_access_type.all === true ? (
                    <span className='checked'>
                      <Checkmark16 />
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </Column>
              <Column lg={2} md={1} className='resource-group-col'>
                <div className='group-check'>
                  {rg.resource_access_type.delete === true ||
                  rg.resource_access_type.all === true ? (
                    <span className='checked'>
                      <Checkmark16 />
                    </span>
                  ) : (
                    ''
                  )}
                </div>
                <div className='resources-check'>
                  {rg.resource_group_access_type.delete === true ||
                  rg.resource_group_access_type.all === true ? (
                    <span className='checked'>
                      <Checkmark16 />
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </Column>
              <Column lg={2} md={1} className='resource-group-col'>
                <div className='group-check'>
                  {rg.resource_access_type.admin === true ||
                  rg.resource_access_type.all === true ? (
                    <span className='checked'>
                      <Checkmark16 />
                    </span>
                  ) : (
                    ''
                  )}
                </div>
                <div className='resources-check'>
                  {rg.resource_group_access_type.admin === true ||
                  rg.resource_group_access_type.all === true ? (
                    <span className='checked'>
                      <Checkmark16 />
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </Column>
            </Row>
          );
        });

    return tableRow;
  };

  const tableHeaders = (tabKey: string) => {
    return (
      <div className='table-header-container'>
        <Column className='searchBar'>
          <Search
            size='lg'
            labelText={t('rolePermissionTable.searchPlaceholder') as string}
            id='role-permission-table-search'
            className='tableSearch'
            value={searchText}
            placeholder={t('rolePermissionTable.searchPlaceholder')}
            onChange={e => applySearch(e)}
            renderIcon={
              <IconWithToolTip
                icon={<Search16 />}
                iconDescription={t('rolePermissionTable.searchPlaceholder')}
              />
            }
          />
        </Column>
        <div className='header'>
          <Row>
            <Column lg={6} md={3}></Column>
            <Column lg={2} md={1}>
              {t('rolePermissionTable.tableHeaders.create')}
            </Column>
            <Column lg={2} md={1}>
              {t('rolePermissionTable.tableHeaders.read')}
            </Column>
            <Column lg={2} md={1}>
              {t('rolePermissionTable.tableHeaders.update')}
            </Column>
            <Column lg={2} md={1}>
              {t('rolePermissionTable.tableHeaders.delete')}
            </Column>
            <Column lg={2} md={1}>
              {t('rolePermissionTable.tableHeaders.admin')}
            </Column>
          </Row>
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className='role-permission-table-container'>
        <div className='customSkeleton'>
          <div className='tab-heading'>
            <SkeletonPlaceholder />
            <SkeletonPlaceholder />
          </div>
          <div className='heading-skeleton'>
            <SkeletonText lineCount={6} paragraph={true} />
          </div>
          <SkeletonText lineCount={4} paragraph={true} />
        </div>
      </div>
    );
  }

  return (
    <div className='role-permission-table-container'>
      <div className='role-permissions-header' style={style ? style : {}}>
        {<span className='text'>{t('rolePermissionTable.header')}</span>}
        <span className='permission-edit-icon'>
          {isEditable ? <Edit16 onClick={openEditPermission} /> : null}
        </span>
      </div>
      <Tabs selected={tabIndex} onSelectionChange={index => setTabIndex(index)}>
        {tabConfig(t).map((tab: any) => {
          return (
            <Tab className={tab.className} key={tab.key} label={tab.label}>
              {tableHeaders(tab.key)}
              {tab.key === 'resource-type' ? (
                <Accordion align='start' key={tab.key}>
                  {permissionTableCategories.map(category => (
                    <AccordionItem
                      title={category.name}
                      key={category.name}
                      open
                    >
                      {componentByResourceType(category.name)}
                    </AccordionItem>
                  ))}
                </Accordion>
              ) : (
                <Accordion align='start' key={tab.key}>
                  {resourceGroupCategories(t).map((category: any) => (
                    <AccordionItem
                      title={category.name}
                      key={category.name}
                      open
                    >
                      {componentByResourceGroup(category.key)}
                    </AccordionItem>
                  ))}
                </Accordion>
              )}
            </Tab>
          );
        })}
      </Tabs>
    </div>
  );
};

export default RoleDetailsPermissionTable;
