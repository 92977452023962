import { useTranslation } from 'react-i18next';
import { Button } from 'carbon-components-react';
import { Edit16, Launch16 } from '@carbon/icons-react';

import useAnalytics from '../../lib/useAnalytics';
import analyticsData from '../../lib/analyticsEventData';

import images from '../../images/images';
import Overflow from '../../components/Overflow/Overflow';

import { DeploymentEnvironment, Location } from '../../models/master';

import './LocationCard.scss';

interface Props {
  location: Location;
  deploymentEnvs: DeploymentEnvironment[];
  selected: boolean;
  onSelect: (location: Location) => void;
  onEdit: (location: Location) => void;
}

const LocationCard = ({
  location,
  deploymentEnvs,
  selected,
  onSelect,
  onEdit,
}: Props) => {
  const { t } = useTranslation('cloudDetails');

  const { trackButtonClicked } = useAnalytics();

  const handleManagedChange = (location: Location) => {
    trackButtonClicked(
      analyticsData['Cloud Details'].events.manageLocation.props,
      analyticsData['Cloud Details'].events.manageLocation.event
    );
    onEdit(location);
  };

  const total = deploymentEnvs?.length;
  const count = deploymentEnvs?.filter(
    deploymentEnv => !deploymentEnv.unmanaged
  )?.length;

  const selectedClass = selected ? 'selected' : '';
  const unmanagedClass = location.unmanaged ? 'unmanaged' : 'managed';

  return (
    <div
      id={`location-card-${location.resource_id}`}
      className={`cloud-location-card ${selectedClass} ${unmanagedClass}`}
      onClick={() => onSelect(location)}
    >
      <div className='title-session'>
        <div className='label-group'>
          <div className='icon'>
            {location.unmanaged ? images.unmanagedIcon() : images.managedIcon()}
          </div>
          <Overflow
            align='start'
            toolTipDirection='bottom'
            key={location.resource_id}
          >
            <h4 className='title'>{location.name}</h4>
          </Overflow>
          {location.unmanaged && (
            <h5 className='unmanaged-label'>{t('unmanaged')}</h5>
          )}
        </div>

        <div className='button-group'>
          {!location.unmanaged && (
            <>
              <Button
                kind='ghost'
                renderIcon={Launch16}
                iconDescription={t('detailsPage')}
                hasIconOnly
                tooltipPosition='bottom'
                onClick={e => {
                  e.stopPropagation();

                  trackButtonClicked(
                    analyticsData['Cloud Details'].events.locationLink.props,
                    analyticsData['Cloud Details'].events.locationLink.event
                  );

                  window.open(
                    window.location.origin +
                      process.env.PUBLIC_URL +
                      `/locationDetails?cloudId=${location.cloud_id}&locationId=${location.resource_id}`
                  );
                }}
              />
              <Button
                kind='ghost'
                renderIcon={Edit16}
                iconDescription={t('edit')}
                hasIconOnly
                tooltipPosition='bottom'
                onClick={() => onEdit(location)}
              />
            </>
          )}

          {location.unmanaged && (
            <Button kind='ghost' onClick={() => handleManagedChange(location)}>
              {t('manage')}
            </Button>
          )}
        </div>
      </div>

      <div className='address-session'>
        {location.type && <div>({t(`locationTypes.${location.type}`)})</div>}
        <div className='name'>{location.city}</div>
        <div className='name'>{location.country}</div>
      </div>

      <div className='depl-env-session'>
        <div>{t('deploymentEnvManageCount', { count, total })}</div>
      </div>
    </div>
  );
};

export default LocationCard;
