import React from 'react';
import { Button } from 'carbon-components-react';

import { TabList, TabPanels, TabPanel, Tab, Tabs } from '@carbon/react';
import { Close16 } from '@carbon/icons-react';
import LegendNodes from './LegendNodes';
import LegendConnections from './LegendConnections';
import LegendStatus from './LegendStatus';
import LegendToolsInd from './LegendToolsInd';
import LegendShortcuts from './LegendShortcuts';
import { useTranslation } from 'react-i18next';

import './LegendContainer.scss';

interface Props {
  legendOpen: boolean;
  setLegendOpen: (open: boolean) => void;
  legendContainerStyle: {
    [key: string]: string;
  };
}

const LegendContainer: React.FC<Props> = ({
  legendOpen,
  setLegendOpen,
  legendContainerStyle,
}) => {
  const { t } = useTranslation('legendContainer');
  return (
    <>
      {legendOpen && (
        <div
          className='topology-legend-container'
          style={legendContainerStyle ? legendContainerStyle : {}}
        >
          <div className='legend-tabs-main'>
            <Tabs>
              <TabList aria-label='Legend tabs'>
                <Tab className='legend-tab'>{t('tabs.nodes')}</Tab>
                <Tab className='legend-tab'>{t('tabs.connections')}</Tab>
                <Tab className='legend-tab'>{t('tabs.status')}</Tab>
                <Tab className='legend-tab'>{t('tabs.toolsInd')}</Tab>
                <Tab className='legend-tab'>{t('tabs.shortcuts')}</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <LegendNodes />
                </TabPanel>
                <TabPanel>
                  <LegendConnections />
                </TabPanel>
                <TabPanel>
                  <LegendStatus />
                </TabPanel>
                <TabPanel>
                  <LegendToolsInd />
                </TabPanel>
                <TabPanel>
                  <LegendShortcuts />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </div>
          <div className='legend-tabs-close'>
            <Button
              className='legend-tabs-close-btn'
              kind='ghost'
              renderIcon={Close16}
              iconDescription='Close'
              onClick={() => {
                setLegendOpen(false);
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default LegendContainer;
