import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import './SuggestedTable.scss';
import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  Button,
  TableProps,
  TooltipIcon,
} from 'carbon-components-react';
import { TrashCan16 } from '@carbon/icons-react';
import Overflow from '../Overflow/Overflow';
import {
  DataTableHeader,
  DataTableRow,
  DataTableCell,
  DataTableSize,
} from '../../models/dataTable';
import { DeploymentInstanceData } from '../../models/master';

interface Hover {
  isHovered: boolean;
  index: string | null;
}

const SuggestedTable: React.FC<{
  rows: DeploymentInstanceData[];
  headers: DataTableHeader[];
  size: DataTableSize;
}> = ({ rows, headers, size, ...rest }) => {
  const [isHovered, handleHoverState] = useState<Hover>({
    isHovered: false,
    index: null,
  });

  return (
    <DataTable size={size} rows={rows} headers={headers} {...rest}>
      {({
        rows,
        headers,
        getTableProps,
        getHeaderProps,
        getRowProps,
      }: {
        rows: DataTableRow[];
        headers: DataTableHeader[];
        getTableProps: () => TableProps;
        getHeaderProps: any;
        getRowProps: any;
      }) => (
        <Table {...getTableProps()}>
          {headers && headers.length > 0 && (
            <TableHead>
              <TableRow>
                {headers.map((header: DataTableHeader) => (
                  <TableHeader key={header.key} {...getHeaderProps({ header })}>
                    {header.header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {rows.map((row: DataTableRow) => (
              <TableRow key={row.id} {...getRowProps({ row })}>
                {row.cells.map((cell: DataTableCell) => {
                  if (cell.info.header === 'delete') {
                    return (
                      <TableCell key={cell.id}>
                        <Button
                          className={
                            'delete-button' +
                            (isHovered.isHovered && isHovered.index === row.id
                              ? ' hovered'
                              : '')
                          }
                          kind='ghost'
                          onMouseEnter={() =>
                            handleHoverState({
                              isHovered: true,
                              index: row.id,
                            })
                          }
                          onMouseLeave={() =>
                            handleHoverState({
                              isHovered: false,
                              index: null,
                            })
                          }
                        >
                          {isHovered.index === row.id && isHovered.isHovered ? (
                            <TrashCan16 className='trash-icon' />
                          ) : null}
                        </Button>
                      </TableCell>
                    );
                  } else
                    return <TableCell key={cell.id}>{cell.value}</TableCell>;
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </DataTable>
  );
};

type CompactRow =
  | false
  | {
      name: {
        text: string;
        link: string;
        icon?: JSX.Element;
      };
      id: string;
      link: string;
      lastUpdated?: string;
      status?: {
        text?: string;
        icon?: JSX.Element;
      };
      [key: string]: any;
    }[]
  | undefined;

const CompactSuggestedTable = ({
  rows,
  rowCount,
  ...rest
}: {
  rows: CompactRow;
  rowCount: number;
}) => {
  const [isHovered, handleHoverState] = useState<{
    isHovered: boolean;
    index: string | null;
  }>({
    isHovered: false,
    index: null,
  });
  return (
    <div className='compact-suggested-table-container'>
      <table className='compact-suggested-table'>
        {rows &&
          rows.slice(0, rowCount).map((row, index) => (
            <tr
              className={
                'row' +
                (isHovered.isHovered && isHovered.index === row.id
                  ? ' hovered'
                  : '')
              }
              onMouseEnter={() =>
                handleHoverState({
                  isHovered: true,
                  index: row.id,
                })
              }
              onMouseLeave={() =>
                handleHoverState({
                  isHovered: false,
                  index: null,
                })
              }
            >
              {Object.keys(row).map(key => {
                if (key === 'name') {
                  return (
                    <td className='col'>
                      <Overflow
                        align='start'
                        toolTipDirection={index === 2 ? 'top' : 'bottom'}
                      >
                        <span className='row-value-with-icon'>
                          {row[key].icon && row[key].icon}
                          <RouterLink to={row['name'].link} className='text'>
                            {row[key].text}
                          </RouterLink>
                        </span>
                      </Overflow>
                    </td>
                  );
                } else if (key === 'resourceInstance') {
                  return (
                    <td className='col'>
                      <span className='row-value-with-icon icon-tooltip'>
                        <TooltipIcon tooltipText={row[key].type}>
                          {row[key]?.icon}
                        </TooltipIcon>
                      </span>
                    </td>
                  );
                } else if (key === 'status' || key === 'autoDiscover') {
                  return (
                    <td className='col'>
                      <span className='row-value-with-icon'>
                        {row[key]?.icon}
                        <span className='text'>{row[key]?.label}</span>
                      </span>
                    </td>
                  );
                } else if (key !== 'id' && key !== 'link') {
                  return (
                    <td className='col'>
                      <Overflow
                        align='start'
                        toolTipDirection={index === 2 ? 'top' : 'bottom'}
                      >
                        <span className='row-value'>
                          {row[key] ? row[key as keyof typeof row] : '–'}
                        </span>
                      </Overflow>
                    </td>
                  );
                }
              })}
            </tr>
          ))}
      </table>
    </div>
  );
};

export { SuggestedTable, CompactSuggestedTable };
