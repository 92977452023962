import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { useQueryClient } from '@tanstack/react-query';
import {
  Row,
  Column,
  ContentSwitcher,
  Button,
  SkeletonPlaceholder,
  OnChangeData,
} from 'carbon-components-react';
import { List32, Grid32 } from '@carbon/icons-react';
import { FlexGrid } from '@carbon/react';

import { useResourceGroupsData } from '../../hooks/useResourceGroups';
import { useCloudsData } from '../../hooks/useClouds';
import { defaultStaleTime } from '../../hooks/queryDefaults';
import {
  useDeploymentEnvsData,
  useDeploymentEnvSubtypes,
} from '../../hooks/useDeploymentEnvs';
import {
  DeploymentEnvironment,
  AppliedFilter,
  Error500Type,
  ResourceData,
} from '../../models/master';

import dateUtils from '../../lib/dates';
import sortData from '../../lib/tableSort';
import {
  getSessionStorageItem,
  manageFiltersFromStorage,
  setSessionStorageItem,
  getCombinedLabelsForFilter,
} from '../../lib/utils';
import useAnalytics from '../../lib/useAnalytics';
import analyticsData from '../../lib/analyticsEventData';
import {
  filterTypes,
  QueryKeys,
  ResourceTypes,
  VisibilityFlags,
} from '../../lib/enums';
import images from '../../images/images';

import FindAndFilterBar from '../../components/FindAndFilterBar/FindAndFilterBar';
import { CardEmptyState } from '../../components/CardEmptyState/CardEmptyState';
import DeploymentEnvsTable from '../../components/DeploymentEnvsTable/DeploymentEnvsTable';
import ResourceCard from '../../components/ApplicationCard/ResourceCard';
import Header from '../../components/Header/Header';
import SortDropDown from '../../components/SortDropdown/SortDropDown';
import Error403Card from '../../components/ErrorState/Error403Card';
import Error500Card from '../../components/ErrorState/Error500Card';
import RegisterDeploymentEnv from './RegisterDeploymentEnv/RegisterDeploymentEnv';

import './DeploymentEnvsContainer.scss';

type LocationState = {
  resourceType: string;
  navigateBack: boolean;
};

type View = 'table' | 'card';

const DeploymentEnvsContainer = () => {
  const { t } = useTranslation('deploymentEnvsContainer');
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as LocationState;
  const queryClient = useQueryClient();

  const { trackButtonClicked, pageViewed } = useAnalytics();

  let viewStateValue = localStorage.getItem('VIEW_STATE') as View;
  const [view, setView] = useState<View>(
    viewStateValue ? viewStateValue : 'table'
  );

  const [deploymentEnvData, setDeploymentEnvData] = useState<
    DeploymentEnvironment[] | null
  >(null);
  const [filteredData, setFilteredData] = useState<
    DeploymentEnvironment[] | []
  >([]);
  const [filterApplied, setFilterApplied] = useState<AppliedFilter[] | []>([]);
  const [currentPageNumber, setPageNumber] = useState(1);
  const [currentPageSize, setPageSize] = useState(25);
  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | 'NONE'>(
    'NONE'
  );
  const [createTearsheetOpen, toggleCreateTearsheet] = useState(false);
  const [registerModeDeploymentEnv, setRegisterModeDeploymentEnv] =
    useState(false);
  const [depEnvRefetchingState, setDepEnvRefetchingState] = useState(false);
  let error500: null | Error500Type = null;

  let defaultPermissionMap = {
    deploymentEnvs: true,
    clouds: true,
    resourceGroups: true,
  };

  const leftInlineFilters: any = [
    {
      key: 'view',
      label: t('view'),
      type: '',
      values: [
        {
          value: VisibilityFlags.ALL,
          label: t('all'),
        },
        {
          value: VisibilityFlags.MANAGED,
          label: t('managed'),
        },
        {
          value: VisibilityFlags.UNMANAGED,
          label: t('unmanaged'),
        },
      ],
      filterCallback: (e: OnChangeData<any>) => {
        if (e.selectedItem !== visibilityFilterFlag) {
          setSessionStorageItem('MCNM_VISIBILITY_FLAG', e.selectedItem?.value);
        }
      },
    },
  ];

  const fetchEnvSubType = (
    depEnv: DeploymentEnvironment,
    depEnvSubTypes: any
  ) => {
    let subType = depEnv?.subtype ?? '';
    if (depEnv?.subtype === '' && depEnv?.type !== '') subType = depEnv?.type;
    const selectedDepEnvSubType = depEnvSubTypes?.find(
      (depEnv: { type_code: string | undefined }) =>
        depEnv?.type_code === subType
    );
    return selectedDepEnvSubType?.type_name ?? '—';
  };

  const getFormattedEnvData = (
    envList: DeploymentEnvironment[],
    subTypeList: any
  ) => {
    const formattedEnvList: DeploymentEnvironment[] = [];
    envList?.map((env: DeploymentEnvironment) => {
      env['subTypeName'] = fetchEnvSubType(env, subTypeList);
      formattedEnvList.push(env);
      return null;
    });
    return formattedEnvList;
  };

  // Queries deployment environment subtypes
  const { data: depEnvSubTypes } = useDeploymentEnvSubtypes({
    staleTime: Infinity,
  });

  // Queries deployment environment
  const visibilityFilterFlag =
    getSessionStorageItem('MCNM_VISIBILITY_FLAG') || VisibilityFlags.MANAGED;

  const {
    data: deploymentEnvironments,
    isLoading: loadingDeploymentEnvs,
    error: deploymentEnvError,
    isError: isDeploymentEnvError,
    isRefetching: isDepEnvListRefetching,
  } = useDeploymentEnvsData(
    visibilityFilterFlag.toLocaleLowerCase(),
    VisibilityFlags.ALL,
    {
      refetchOnWindowFocus: false,
      staleTime: defaultStaleTime,
    }
  );

  if (isDeploymentEnvError) {
    const error = deploymentEnvError as AxiosError;
    defaultPermissionMap.deploymentEnvs =
      error?.response?.status === 403 ? false : true;
    if (error.response!?.status >= 500) {
      error500 = error.response!?.status.toString() as Error500Type;
    }
  }
  const formatedDeploymentEnvironments = useMemo(() => {
    setDeploymentEnvData(
      getFormattedEnvData(
        deploymentEnvironments as DeploymentEnvironment[],
        depEnvSubTypes
      )
    );
  }, [deploymentEnvironments, depEnvSubTypes]);

  // Queries clouds
  const {
    data: cloudList,
    error: cloudError,
    isError: isCloudError,
  } = useCloudsData({
    refetchOnWindowFocus: false,
  });

  if (isCloudError) {
    const error = cloudError as AxiosError;
    defaultPermissionMap.clouds =
      error?.response?.status === 403 ? false : true;
  }

  // Queries resource groups
  const {
    data: resourceGroups,
    error: resourceGroupError,
    isError: isResourceGroupError,
    isLoading: isResourceGroupListLoading,
    refetch: refreshResourceGroupData,
  } = useResourceGroupsData({
    refetchOnWindowFocus: false,
  });

  if (isResourceGroupError) {
    const error = resourceGroupError as AxiosError;
    defaultPermissionMap.resourceGroups =
      error?.response?.status === 403 ? false : true;
  }

  useEffect(() => {
    if (!isDepEnvListRefetching) setDepEnvRefetchingState(false);
  }, [isDepEnvListRefetching]);

  useEffect(() => {
    if (state?.resourceType === ResourceTypes.ENVIRONMENT) {
      openTearsheet();
    }
  }, [state]);

  const refreshData = () => {
    setFilterApplied(manageFiltersFromStorage());
    setDepEnvRefetchingState(true);
    setSortKey('');
    setSortDirection('NONE');
    //clear previous 500 error & 403 error status
    if (error500) {
      error500 = null;
    }
    defaultPermissionMap = {
      deploymentEnvs: true,
      clouds: true,
      resourceGroups: true,
    };
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.DEPLOYMENTENVIRONMENTS],
    });
    refreshResourceGroupData();
  };

  const cardViewTags = (
    labels: string[] | null,
    discoverdLabels: string[] | null
  ) => {
    const labelsList: any = [];
    if (labels) {
      labels.forEach(el => {
        labelsList.push(el);
      });
    }
    if (discoverdLabels) {
      discoverdLabels.forEach(el => {
        labelsList.push({
          default: true,
          value: el,
        });
      });
    }
    return labelsList;
  };
  const handleSort = (data: { id: string; text: string }) => {
    if (Array.isArray(deploymentEnvData)) {
      setSortDirection('ASC');
      if (data.id === 'atoz') {
        const sortedDeployment = Array.isArray(deploymentEnvData)
          ? deploymentEnvData.sort((a, b) =>
              a.name?.trim()?.toLowerCase() > b.name?.trim()?.toLowerCase()
                ? 1
                : -1
            )
          : [];
        setDeploymentEnvData([...sortedDeployment]);
      } else {
        setSortDirection('DESC');
        const sortedDeployment = Array.isArray(deploymentEnvData)
          ? deploymentEnvData.sort((a, b) =>
              a.name?.trim()?.toLowerCase() < b.name?.trim()?.toLowerCase()
                ? 1
                : -1
            )
          : [];
        setDeploymentEnvData([...sortedDeployment]);
      }
    }
  };

  const handleTableSort = (
    data: { id: string; text: string },
    sortDirection: 'ASC' | 'DESC' | 'NONE'
  ) => {
    setSortDirection(sortDirection);
    setSortKey(data.id);
  };

  useEffect(() => {
    pageViewed('Deployment Environment');
  }, []);

  const getResourceGroupName = (resourceId: string) => {
    const resource = resourceGroups?.find(
      (resGroup: ResourceData) => resGroup?.resource_id === resourceId
    );
    return resource?.name ?? '—';
  };

  const renderFilter = () => {
    return (
      <FindAndFilterBar
        data={deploymentEnvData ? deploymentEnvData : []}
        filteredDataCallback={data =>
          setFilteredData(data as DeploymentEnvironment[] | [])
        }
        filtersApplied={filterApplied as any}
        filtersAppliedCallback={data => setFilterApplied(data)}
        filteredData={
          deploymentEnvData
            ? filterApplied.length > 0
              ? filteredData
              : deploymentEnvData
            : null
        }
        filters={[
          {
            key: 'cloud',
            type: 'single',
            label: t('filter.cloud'),
            placeHolderText: t('filter.cloudPlaceholder'),
            values: [
              ...Array.from(
                new Set(deploymentEnvData?.map(depenv => depenv.cloud_name))
              ),
            ],
          },
          {
            key: 'location',
            type: 'multi',
            label: t('filter.location'),
            placeHolderText: t('filter.locationPlaceholder'),
            values: [
              ...Array.from(
                new Set(deploymentEnvData?.map(depenv => depenv.location_name))
              ),
            ],
          },
          {
            key: 'subTypeName',
            label: t('filter.type'),
            placeHolderText: t('filter.typePlaceholder'),
            type: 'single',
            values: [
              ...Array.from(
                new Set(
                  deploymentEnvData?.map(depenv => depenv?.subTypeName ?? '')
                )
              ),
            ],
          },
          {
            key: filterTypes.ALLLABELS,
            type: 'multi',
            label: t('filter.labels'),
            placeHolderText: t('filter.labelsPlaceholder'),
            values: [
              ...Array.from(
                new Set(
                  deploymentEnvData
                    ?.map(depenv =>
                      getCombinedLabelsForFilter(
                        depenv?.labels,
                        depenv?.discovered_labels
                      )
                    )
                    .flat()
                )
              ),
            ],
          },
        ]}
        onRefresh={refreshData}
        leftInlineFilters={leftInlineFilters}
        visibilityFlag={visibilityFilterFlag}
        persistFilter
      />
    );
  };

  const setPageChange = (pageData: { page: number; pageSize: number }) => {
    setPageNumber(pageData.page);
    setPageSize(pageData.pageSize);
  };

  const closeTearsheet = () => {
    toggleCreateTearsheet(false);
    setRegisterModeDeploymentEnv(false);
    if (state?.navigateBack) {
      navigate(-1);
    }
  };

  const openTearsheet = () => {
    toggleCreateTearsheet(true);
    setRegisterModeDeploymentEnv(true);
  };

  const isDataLoading =
    isResourceGroupListLoading ||
    loadingDeploymentEnvs ||
    depEnvRefetchingState;

  return (
    <div className='deployment-envs-container'>
      <Header
        title={t('header')}
        subTitle={t('subheader')}
        breadcrumbs={[
          {
            url: '/',
            label: t('home'),
          },
        ]}
        actions={[
          {
            kind: 'primary',
            toolTip: cloudList
              ? defaultPermissionMap['clouds']
                ? t('noCloudsDefined')
                : t('noCloudAuthorization')
              : '',
            disabled: cloudList && cloudList?.length ? false : true,
            onClick: () => {
              trackButtonClicked(
                analyticsData['Deployment Environment'].events.registerDepEnvBtn
                  .props,
                analyticsData['Deployment Environment'].events.registerDepEnvBtn
                  .event
              );
              openTearsheet();
            },
            text: t('addEnvironment'),
          },
        ]}
      />
      <div className='page-content'>
        <div className='environments-switcher'>
          {view !== 'table' ? (
            <>
              {isDataLoading ? (
                <div className='skeleton-sort-drop-down'>
                  <SkeletonPlaceholder className={'sorting-skeleton'} />
                </div>
              ) : (
                <SortDropDown
                  id='development-sort-dropdown'
                  size='lg'
                  onSort={handleSort}
                  sortDir={sortDirection}
                />
              )}
            </>
          ) : null}
          <ContentSwitcher className='view-switcher'>
            <Button
              className={
                'switch-button table-button' +
                (view === 'table' ? ' selected' : '')
              }
              onClick={() => {
                setView('table');
                localStorage.setItem('VIEW_STATE', 'table');
              }}
              renderIcon={List32}
              hasIconOnly
              tooltipPosition='bottom'
              iconDescription={t('table')}
            />
            <Button
              className={
                'switch-button card-button' +
                (view === 'card' ? ' selected' : '')
              }
              onClick={() => {
                setView('card');
                localStorage.setItem('VIEW_STATE', 'card');
              }}
              renderIcon={Grid32}
              hasIconOnly
              tooltipPosition='bottom'
              iconDescription={t('card')}
            />
          </ContentSwitcher>
        </div>
        <div className='body'>
          {view === 'table' ? (
            <DeploymentEnvsTable
              depEnvSubTypes={depEnvSubTypes}
              currentPageNumber={currentPageNumber}
              currentPageSize={currentPageSize}
              onPageChange={pageData => setPageChange(pageData)}
              sortRows={(
                data: { id: string; text: string },
                direction: 'ASC' | 'DESC' | 'NONE'
              ) => handleTableSort(data, direction)}
              rows={
                Array.isArray(deploymentEnvData)
                  ? filterApplied.length > 0
                    ? sortData(filteredData, sortKey, sortDirection)
                    : sortData(deploymentEnvData, sortKey, sortDirection)
                  : null
              }
              elementCount={
                deploymentEnvData
                  ? filterApplied.length > 0
                    ? filteredData.length
                    : deploymentEnvData.length
                  : 0
              }
              filteredDataSet={
                deploymentEnvData
                  ? filterApplied.length > 0
                    ? filteredData
                    : deploymentEnvData
                  : null
              }
              filteredDataCallback={data => {
                data && setFilteredData(data as DeploymentEnvironment[] | []);
                setPageNumber(1);
              }}
              data={deploymentEnvData}
              filtersSelected={filterApplied as any}
              filtersAppliedCallback={data => setFilterApplied(data)}
              noDataCallBack={() => openTearsheet()}
              persistFilter
              filters={[
                {
                  key: 'cloud',
                  type: 'single',
                  label: t('filter.cloud'),
                  placeHolderText: t('filter.cloudPlaceholder'),
                  values: [
                    ...Array.from(
                      new Set(
                        deploymentEnvData?.map(depenv => depenv.cloud_name)
                      )
                    ),
                  ],
                },
                {
                  key: 'location',
                  type: 'multi',
                  label: t('filter.location'),
                  placeHolderText: t('filter.locationPlaceholder'),
                  values: [
                    ...Array.from(
                      new Set(
                        deploymentEnvData?.map(depenv => depenv?.location_name)
                      )
                    ),
                  ],
                },
                {
                  key: 'subTypeName',
                  label: t('filter.type'),
                  placeHolderText: t('filter.typePlaceholder'),
                  type: 'single',
                  values: [
                    ...Array.from(
                      new Set(
                        deploymentEnvData?.map(depenv => depenv?.subTypeName)
                      )
                    ),
                  ],
                },
                {
                  key: filterTypes.ALLLABELS,
                  type: 'multi',
                  label: t('filter.labels'),
                  placeHolderText: t('filter.labelsPlaceholder'),
                  values: [
                    ...Array.from(
                      new Set(
                        deploymentEnvData
                          ?.map(depenv =>
                            getCombinedLabelsForFilter(
                              depenv?.labels,
                              depenv?.discovered_labels
                            )
                          )
                          .flat()
                      )
                    ),
                  ],
                },
              ]}
              onRefresh={refreshData}
              leftInlineFilters={leftInlineFilters}
              visibilityFlag={visibilityFilterFlag}
              error403Flag={!defaultPermissionMap['deploymentEnvs']}
              error500Flag={!!error500}
              dataLoading={isDataLoading}
            />
          ) : !defaultPermissionMap['deploymentEnvs'] ? (
            <Error403Card />
          ) : error500 ? (
            <Error500Card />
          ) : isDataLoading ? (
            <div>
              {renderFilter()}
              <div className='skeleton-card-view'>
                <SkeletonPlaceholder className={'deployment-env-skeleton'} />
                <SkeletonPlaceholder className={'deployment-env-skeleton'} />
                <SkeletonPlaceholder className={'deployment-env-skeleton'} />
                <SkeletonPlaceholder className={'deployment-env-skeleton'} />
              </div>
            </div>
          ) : (
            <div>
              {renderFilter()}
              <div className='card-view' data-testid='deployment-env-card-view'>
                <FlexGrid>
                  <Row className='resource-card-alignment'>
                    {deploymentEnvData ? (
                      (filterApplied.length > 0
                        ? filteredData
                        : deploymentEnvData
                      ).length > 0 ? (
                        (filterApplied.length > 0
                          ? filteredData
                          : deploymentEnvData
                        ).map(environment => {
                          return (
                            <Column
                              lg={4}
                              md={4}
                              className='resource-card'
                              data-testid='deployment-env-resource-card'
                            >
                              <ResourceCard
                                key={environment.resource_id}
                                resourceType={'ENVIRONMENT'}
                                header={environment.name}
                                subheader={environment.type}
                                tags={cardViewTags(
                                  environment.labels,
                                  environment?.discovered_labels
                                )}
                                data={{
                                  location: environment?.location_name
                                    ? environment?.location_name
                                    : '—',
                                  type: environment?.subTypeName ?? '—',
                                  resourceGroupName:
                                    environment?.resource_group_id
                                      ? getResourceGroupName(
                                          environment?.resource_group_id
                                        )
                                      : '—',
                                  unmanaged: environment?.unmanaged as boolean,
                                }}
                                updated={dateUtils.getUserFriendlyDate(
                                  environment.updated_at
                                )}
                                path={`/deploymentEnvironmentDetails?deplId=${environment.resource_id}`}
                              />
                            </Column>
                          );
                        })
                      ) : (
                        <CardEmptyState
                          filterApplied={filterApplied}
                          emptyState={{
                            icon: images.DeploymentEnvironmentEmpty(),
                            header: t('emptyState.emptyContainerHeader'),
                            description: t(
                              'emptyState.emptyContainerDescription'
                            ),
                          }}
                        />
                      )
                    ) : (
                      <div className='no-environments'></div>
                    )}
                  </Row>
                </FlexGrid>
              </div>
            </div>
          )}
        </div>
      </div>
      <RegisterDeploymentEnv
        open={createTearsheetOpen}
        onClose={() => closeTearsheet()}
        refreshData={refreshData}
        hasCloudAuthorization={defaultPermissionMap['clouds']}
        deploymentEnvList={deploymentEnvData}
        deplEnvSubtypesList={depEnvSubTypes}
        cloudList={cloudList ?? []}
        isEditMode={false}
      />
    </div>
  );
};

export default DeploymentEnvsContainer;
