import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import CreateIdentity from './CreateIdentity/CreateIdentity';
import IdentityTable from '../Identities/IdentityTable/IdentityTable';
import SortDropDown from '../../components/SortDropdown/SortDropDown';
import {
  Button,
  Column,
  ContentSwitcher,
  Row,
  SkeletonPlaceholder,
} from 'carbon-components-react';
import { Grid32, List32 } from '@carbon/icons-react';
import { FlexGrid } from '@carbon/react';
import { getIdentities } from '../../controllers/identityApis';
import { AppliedFilter, Error500Type, IdentityData } from '../../models/master';
import sortData from '../../lib/tableSort';
import FindAndFilterBar from '../../components/FindAndFilterBar/FindAndFilterBar';
import { manageFiltersFromStorage } from '../../lib/utils';
import { CardEmptyState } from '../../components/CardEmptyState/CardEmptyState';
import images from '../../images/images';
import IdentityCards from './IdentityCards/IdentityCards';
import Error500Card from '../../components/ErrorState/Error500Card';

import './IdentitiesContainer.scss';
import { AxiosError } from 'axios';
import useAnalytics from '../../lib/useAnalytics';
import analyticsData from '../../lib/analyticsEventData';

type View = 'table' | 'card';

const IdentityContainer = () => {
  const { t } = useTranslation('identity');
  const [createTearsheetOpen, setCreateTearsheetOpen] = useState(false);

  const viewStateValue = localStorage.getItem('VIEW_STATE') as View;
  const [view, setView] = useState<'table' | 'card'>(
    viewStateValue ? viewStateValue : 'table'
  );

  const [loadingIdentityList, toggleLoadingIdentityList] = useState(false);
  const [identitiesData, setIdentitiesData] = useState<
    IdentityData[] | [] | null
  >(null);
  const [existingIdentities, setExistingIdentities] = useState<string[]>([]);
  const [filteredData, setFilteredData] = useState<IdentityData[] | []>([]);
  const [filterApplied, setFilterApplied] = useState<AppliedFilter[] | []>([]);
  const [currentPageNumber, setPageNumber] = useState(1);
  const [currentPageSize, setPageSize] = useState(25);
  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | 'NONE'>(
    'NONE'
  );
  const [error500, setError500] = useState<null | Error500Type>(null);

  const navigate = useNavigate();
  const { pageViewed, trackButtonClicked } = useAnalytics();

  const formatIdentities = (identities: IdentityData[]) => {
    identities.map((identity: IdentityData) => {
      const roles = identity?.roles?.map(
        (role: { name: string }) => role?.name
      );
      identity['rolesList'] = roles;

      return null;
    });
    return identities;
  };

  const refreshData = async () => {
    try {
      setIdentitiesData(null);
      setSortKey('');
      setSortDirection('NONE');
      setFilterApplied(manageFiltersFromStorage());
      const identityResponse = await getIdentities();
      const existingIdentitiesList = identityResponse?.map(
        (identity: IdentityData) => identity?.resource_id
      );
      setExistingIdentities(existingIdentitiesList);
      setIdentitiesData(formatIdentities(identityResponse));

      if (error500) {
        setError500(null);
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        navigate('/403');
      } else if (err.response?.status === 404) {
        setIdentitiesData([]);
      } else if (err.response!?.status >= 500) {
        setError500(err.response!?.status?.toString() as Error500Type);
      }
      console.error(err);
    } finally {
      toggleLoadingIdentityList(false);
    }
  };

  const handleSort = (data: { id: string; text: string }) => {
    if (identitiesData) {
      if (data.id === 'atoz') {
        setSortDirection('ASC');
        const sortedResourceGroups = Array.isArray(identitiesData)
          ? identitiesData.sort((a, b) =>
              a.name?.trim()?.toLowerCase() > b.name?.trim()?.toLowerCase()
                ? 1
                : -1
            )
          : [];
        setIdentitiesData([...sortedResourceGroups]);
      } else {
        setSortDirection('DESC');
        const sortedResourceGroups = Array.isArray(identitiesData)
          ? identitiesData.sort((a, b) =>
              a.name?.trim()?.toLowerCase() < b.name?.trim()?.toLowerCase()
                ? 1
                : -1
            )
          : [];
        setIdentitiesData([...sortedResourceGroups]);
      }
    }
  };

  const renderFilter = () => {
    return (
      <FindAndFilterBar
        data={identitiesData ? identitiesData : []}
        filteredDataCallback={data =>
          setFilteredData(data as IdentityData[] | [])
        }
        filteredData={
          identitiesData
            ? filterApplied.length > 0
              ? filteredData
              : identitiesData
            : null
        }
        filtersApplied={filterApplied as any}
        filtersAppliedCallback={data => setFilterApplied(data)}
        filters={[
          {
            key: 'rolesList',
            type: 'multi',
            label: t('filters.roles'),
            placeHolderText: t('filters.rolesPlaceholder'),
            values: [
              ...Array.from(
                new Set(
                  (identitiesData ? identitiesData : [])
                    .map(identity => identity?.rolesList)
                    .flat()
                )
              ),
            ],
          },
          {
            key: 'labels',
            type: 'multi',
            label: t('filters.labels'),
            placeHolderText: t('filters.labelsPlaceholder'),
            values: [
              ...Array.from(
                new Set(
                  (identitiesData ? identitiesData : [])
                    .map(identity => identity?.labels)
                    .flat()
                )
              ),
            ],
          },
        ]}
        onRefresh={() => refreshData()}
        persistFilter
      />
    );
  };

  const setPageChange = (pageData: { page: number; pageSize: number }) => {
    setPageNumber(pageData.page);
    setPageSize(pageData.pageSize);
  };

  const handleTableSort = (
    data: { id: string; text: string },
    sortDirection: 'ASC' | 'DESC' | 'NONE'
  ) => {
    setSortDirection(sortDirection);
    setSortKey(data.id);
  };

  const openCreateTearsheet = () => {
    trackButtonClicked(
      analyticsData['Admin Identities'].events.createIdentity.props,
      analyticsData['Admin Identities'].events.createIdentity.event
    );
    setCreateTearsheetOpen(true);
  };

  const closeCreateTearsheet = () => {
    setCreateTearsheetOpen(false);
  };

  useEffect(() => {
    pageViewed('Admin Identities');
    toggleLoadingIdentityList(true);
    refreshData();
  }, []);

  return (
    <div className='identity-container'>
      <Header
        title={t('header')}
        subTitle={t('subheader')}
        breadcrumbs={[
          {
            url: '/',
            label: t('home'),
          },
        ]}
        actions={[
          {
            kind: 'primary',
            onClick: () => openCreateTearsheet(),
            text: t('createIdentity'),
            disabled: loadingIdentityList,
          },
        ]}
      />
      <div className='page-content'>
        <div className='policies-switcher'>
          {view !== 'table' ? (
            <>
              {loadingIdentityList ? (
                <div className='skeleton-sort-drop-down'>
                  <SkeletonPlaceholder className={'sorting-skeleton'} />
                </div>
              ) : (
                <SortDropDown
                  id='identities-sort-dropdown'
                  size='lg'
                  onSort={handleSort}
                  sortDir={sortDirection}
                />
              )}
            </>
          ) : null}
          <ContentSwitcher className='view-switcher'>
            <Button
              className={
                'switch-button' + (view === 'table' ? ' selected' : '')
              }
              onClick={() => {
                setView('table');
                localStorage.setItem('VIEW_STATE', 'table');
              }}
              renderIcon={List32}
              hasIconOnly
              tooltipPosition='bottom'
              iconDescription={t('table')}
            />
            <Button
              className={'switch-button' + (view === 'card' ? ' selected' : '')}
              onClick={() => {
                setView('card');
                localStorage.setItem('VIEW_STATE', 'card');
              }}
              renderIcon={Grid32}
              hasIconOnly
              tooltipPosition='bottom'
              iconDescription={t('card')}
            />
          </ContentSwitcher>
        </div>
        <div className='body'>
          {view === 'table' ? (
            <IdentityTable
              rows={
                Array.isArray(identitiesData)
                  ? filterApplied.length > 0
                    ? sortData(filteredData, sortKey, sortDirection)
                    : sortData(identitiesData, sortKey, sortDirection)
                  : null
              }
              elementCount={
                identitiesData
                  ? filterApplied.length > 0
                    ? filteredData.length
                    : identitiesData.length
                  : 0
              }
              filteredDataSet={
                identitiesData
                  ? filterApplied.length > 0
                    ? filteredData
                    : identitiesData
                  : null
              }
              data={identitiesData}
              currentPageNumber={currentPageNumber}
              currentPageSize={currentPageSize}
              onPageChange={pageData => setPageChange(pageData)}
              sortRows={(
                data: { id: string; text: string },
                direction: 'ASC' | 'DESC' | 'NONE'
              ) => handleTableSort(data, direction)}
              filteredDataCallback={data =>
                data && setFilteredData(data as IdentityData[] | [])
              }
              filtersSelected={filterApplied as any}
              filtersAppliedCallback={data => setFilterApplied(data)}
              persistFilter
              filters={[
                {
                  key: 'rolesList',
                  type: 'multi',
                  label: t('filters.roles'),
                  placeHolderText: t('filters.rolesPlaceholder'),
                  values: [
                    ...Array.from(
                      new Set(
                        (identitiesData ? identitiesData : [])
                          .map(identity => identity?.rolesList)
                          .flat()
                      )
                    ),
                  ],
                },
                {
                  key: 'labels',
                  type: 'multi',
                  label: t('filters.labels'),
                  placeHolderText: t('filters.labelsPlaceholder'),
                  values: [
                    ...Array.from(
                      new Set(
                        (identitiesData ? identitiesData : [])
                          .map(identity => identity?.labels)
                          .flat()
                      )
                    ),
                  ],
                },
              ]}
              onRefresh={() => refreshData()}
              error500Flag={!!error500}
            />
          ) : error500 ? (
            <Error500Card />
          ) : loadingIdentityList ? (
            <div>
              {renderFilter()}

              <div className='skeleton-card-view'>
                <SkeletonPlaceholder className={'roles-card-skeleton'} />
                <SkeletonPlaceholder className={'roles-card-skeleton'} />
                <SkeletonPlaceholder className={'roles-card-skeleton'} />
                <SkeletonPlaceholder className={'roles-card-skeleton'} />
              </div>
            </div>
          ) : (
            <div>
              {renderFilter()}
              <div className='card-view'>
                <FlexGrid>
                  <Row className='resource-card-alignment'>
                    {identitiesData &&
                    (filterApplied.length > 0 ? filteredData : identitiesData)
                      .length > 0 ? (
                      (filterApplied.length > 0
                        ? filteredData
                        : identitiesData
                      ).map(identity => (
                        <Column lg={4} md={4} className='resource-card'>
                          <IdentityCards
                            identityId={identity.resource_id}
                            identityName={identity.name}
                            updated_at={identity.updated_at}
                            roles={identity.roles}
                            emailId={identity.resource_id}
                            tags={identity.labels}
                          />
                        </Column>
                      ))
                    ) : (
                      <CardEmptyState
                        filterApplied={filterApplied}
                        emptyState={{
                          icon: images.noIdentityIcon(),
                          header: t('emptyState.emptyContainerHeader'),
                          description: t(
                            'emptyState.emptyContainerDescription'
                          ),
                          buttonText: t('emptyState.buttonText'),
                          link: true,
                          click: () =>
                            navigate('/identities', {
                              state: { resourceType: 'IDENTITIES' },
                            }),
                        }}
                      />
                    )}
                  </Row>
                </FlexGrid>
              </div>
            </div>
          )}
        </div>
      </div>

      <CreateIdentity
        open={createTearsheetOpen}
        closeTearsheet={closeCreateTearsheet}
        refreshData={refreshData}
        identitiesList={existingIdentities}
        mode='ADD'
      />
    </div>
  );
};

export default IdentityContainer;
