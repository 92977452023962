import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import sha256 from 'crypto-js/sha256';

import { MCNM_AUTH_URL } from '../../controllers/config';
import { Modal, Dropdown, Row, Column } from 'carbon-components-react';
import './CallBackHandler.scss';
import useAnalytics from '../../lib/useAnalytics';
import { getCookie } from '../../lib/utils';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

const loginState = urlParams.get('state');
const loginCode = urlParams.get('code');

interface Props {
  onLogin: () => void;
}

const CallBackHandler: React.FC<Props> = ({ onLogin }) => {
  React.useEffect(() => {
    if (window.sessionStorage.getItem('LOGIN_REDIRECT_URL')) {
      window.sessionStorage.removeItem('LOGIN_REDIRECT_URL');
    }

    updateURLWithParams();
  }, []);

  const [showAccountModal, setShowAccountModal] = useState(false);
  const [accountNames, setAccountNames] = useState(['']);
  const accounts = accountNames;
  const { t }: { t: any } = useTranslation('callBackHandler');

  const { identifyUser } = useAnalytics();

  const redirectUrl = window.sessionStorage.getItem('LOGIN_REDIRECT_URL')
    ? window.sessionStorage.getItem('LOGIN_REDIRECT_URL') ?? '/'
    : '/';

  function handleAccountSelect(key: string) {
    window.localStorage.setItem('SELECTED_ACCOUNT', key);
    navigate(redirectUrl);
    onLogin();
  }

  const navigate = useNavigate();
  const updateURLWithParams = async () => {
    try {
      const response = await axios.get(
        `${MCNM_AUTH_URL}/token/?state=${loginState}&code=${loginCode}`
      );
      let emailId;
      if (Array.isArray(response.data)) {
        const authDetails = response?.data.map(account => ({
          account_name: account.account_name,
          details: jwt_decode(account.token),
          token: account.token,
          username: account.username,
          user_id: account.user_id,
          tenant_id: account.tenant_id,
          tenant_name: account.tenant_name,
          identity_uuid: account?.identity_uuid,
        }));
        authDetails.map(arr => {
          localStorage.setItem(
            `ACCOUNT_DETAILS_${arr.account_name}`,
            JSON.stringify(arr)
          );
          emailId = arr?.user_id ?? '';
        });
        localStorage.setItem('AUTH_DETAILS', JSON.stringify(authDetails));
        // if (
        //   getCookie('notice_preferences') === '1:' ||
        //   getCookie('notice_preferences') === '2:'
        // ) {
        //   const hashedEmail = sha256(emailId ?? '').toString();
        //   identifyUser(hashedEmail);
        // }
        if (response.data.length === 1) {
          window.localStorage.setItem(
            'SELECTED_ACCOUNT',
            response.data[0].account_name
          );
          navigate(redirectUrl);
          onLogin();
        } else {
          const getAccountNames = (acc: { account_name: any }) =>
            acc.account_name;
          const accountNames = response?.data.map(getAccountNames);
          setAccountNames(accountNames);
          setShowAccountModal(true);
        }
      }
    } catch (e: any) {
      console.log('Error caught', e);
      if (e) {
        navigate('/403');
      } else navigate(redirectUrl);
    }
  };

  return (
    <>
      <Modal
        open={showAccountModal}
        size='lg'
        className='account-modal'
        passiveModal
        modalHeading={t('modal.header')}
      >
        <div>
          <Row>
            <Column>
              <Dropdown
                id='inline'
                label={t('modal.select')}
                className='account-dropdown'
                type='inline'
                items={accounts}
                onChange={e => handleAccountSelect(e.selectedItem!)}
              />
            </Column>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default CallBackHandler;
