import { axiosInstance } from './api';
import { MCNM_AUTHORIZATION_API_URL } from './config';

//Get all of the roles that MCNM oversees
export async function getRoles() {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_AUTHORIZATION_API_URL}/role`,
    });
    return response.data;
  } catch (error) {
    console.log('error in GET Roles', error);
    throw error;
  }
}

//Add a new role that MCNM should oversee. Identities and permissions
export async function addRole(data) {
  try {
    const response = await axiosInstance({
      method: 'POST',
      url: `${MCNM_AUTHORIZATION_API_URL}/role`,
      data,
    });
    return response.data;
  } catch (error) {
    console.log('Error in adding role', error);
    throw error;
  }
}

//delete: Delete an existing role that MCNM oversees
export async function deleteRole(role_id) {
  try {
    const response = await axiosInstance({
      method: 'DELETE',
      url: `${MCNM_AUTHORIZATION_API_URL}/role/${role_id}`,
    });
    return response.data;
  } catch (error) {
    console.log('error in GET role', error);
    throw error;
  }
}

//Get one of the roles that MCNM oversees
export async function getRole(role_id) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_AUTHORIZATION_API_URL}/role/${role_id}`,
    });
    return response.data;
  } catch (error) {
    console.log('error in GET role', error);
    throw error;
  }
}

//Update an existing role that MCNM oversees. Identities and permissions

export async function updateRole(role_id, data) {
  try {
    const response = await axiosInstance({
      method: 'PUT',
      url: `${MCNM_AUTHORIZATION_API_URL}/role/${role_id}`,
      data: data,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

//Get all of the Identites for a given Role that MCNM oversees.
export async function getRoleIdentities(role_id) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_AUTHORIZATION_API_URL}/role/${role_id}/identity`,
    });
    return response.data;
  } catch (error) {
    console.log('error in GET identities for this role', error);
    throw error;
  }
}

//Add an identity to an existing role that MCNM oversees
export async function addRoleIdentity(role_id, identity_id, data) {
  try {
    const response = await axiosInstance({
      method: 'POST',
      url: `${MCNM_AUTHORIZATION_API_URL}/role/${role_id}/identity/${identity_id}`,
      data: data,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

//Get all of the permissions for this role that MCNM oversees
export async function getRolePermissions(role_id) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_AUTHORIZATION_API_URL}/role/${role_id}/permission`,
    });
    return response.data;
  } catch (error) {
    console.log('error in GET permissions for this role', error);
    throw error;
  }
}

//Add permissions to an existing role that MCNM oversees. Resource IDs for permissions that are already bound to the role are ignored.

export async function addRolePermissions(role_id, data) {
  try {
    const response = await axiosInstance({
      method: 'POST',
      url: `${MCNM_AUTHORIZATION_API_URL}/role/${role_id}/permission`,
      data: data,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

//Remove a permission from an existing role that MCNM oversees
export async function deleteRolePermission(role_id, permission_id) {
  try {
    const response = await axiosInstance({
      method: 'DELETE',
      url: `${MCNM_AUTHORIZATION_API_URL}/role/${role_id}/permission/${permission_id}`,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

//Add a defined permission to an existing role that MCNM oversees
export async function addRolePermission(role_id, permission_id) {
  try {
    const response = await axiosInstance({
      method: 'POST',
      url: `${MCNM_AUTHORIZATION_API_URL}/role/${role_id}/permission/${permission_id}`,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function deleteRolePermissions(role_id) {}
