import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  InlineNotification,
  SkeletonPlaceholder,
  Tag,
} from 'carbon-components-react';
import { CreateTearsheetStep } from '@carbon/ibm-products';
import images from '../../../../images/images';
import IconWithToolTip from '../../../../components/IconWithToolTip/IconWithToolTip';
import './GatewayTypeSelect.scss';
import { GatewayType } from '../../GatewaysContainer';

interface Props {
  selectedGatewayType: GatewayType | null;
  selectGatewayTypeCallback: (gatewaytype: GatewayType) => void;
  toggleComplete: (val: boolean) => void;
  complete: boolean;
  gatewayTypeList: GatewayType[] | null;
  loading: boolean;
}

const gatewayTypeReferenceOrder = ['AXON-EDGE', 'RHSI-EDGE', 'AXON-WP'];

const GatewaySelect: React.FC<Props> = ({
  selectGatewayTypeCallback,
  selectedGatewayType,
  gatewayTypeList,
  toggleComplete,
  complete,
  loading,
}) => {
  const { t } = useTranslation('gatewaysContainer');

  const typeSelected = (type: GatewayType) => {
    selectGatewayTypeCallback(type);
  };

  const gatewayName = (id: string) => {
    let gatewayName: string = '';

    switch (id) {
      case 'AXON-EDGE':
        gatewayName = t('axonEdgeGatewayName');
        break;
      case 'AXON-WP':
        gatewayName = t('axonWaypointGatewayName');
        break;
      case 'RHSI-EDGE':
        gatewayName = t('skupperEdgeGatewayName');
        break;
    }
    return gatewayName;
  };

  return (
    <CreateTearsheetStep
      className='select-gatewaytype-container'
      hasFieldset={false}
      title={t('selectGatewayTypeTearSheet.steptitle')}
      disableSubmit={
        selectedGatewayType === null ||
        (Array.isArray(gatewayTypeList) && gatewayTypeList.length === 0)
      }
      onNext={() => toggleComplete(true)}
    >
      {!loading && Array.isArray(gatewayTypeList) ? (
        <div className='select-gateway'>
          <span className='heading'>
            {t('selectGatewayTypeTearSheet.title')}
          </span>
          {complete && (
            <InlineNotification
              kind='warning'
              aria-label={t(
                'selectGatewayTypeTearSheet.warningNotification.aria-label'
              )}
              title={
                t(
                  'selectGatewayTypeTearSheet.warningNotification.title'
                ) as string
              }
              lowContrast
              onClose={() => toggleComplete(false) as any}
            />
          )}

          {
            <div className='gatewaytype-container-list'>
              <div className={'select-gatewaytype'}>
                {gatewayTypeList.length === 0 ? (
                  <InlineNotification
                    kind={'error'}
                    title={t('gatewayCompatibilitySetErrorTitle')}
                    subtitle={t('gatewayCompatibilitySetErrorSubTitle')}
                    lowContrast
                  />
                ) : (
                  gatewayTypeList
                    .sort((a, b) => {
                      return (
                        gatewayTypeReferenceOrder.indexOf(b.resource_id) -
                        gatewayTypeReferenceOrder.indexOf(a.resource_id)
                      );
                    })
                    .map((type: GatewayType) => (
                      <div
                        className='gatewayTypeCard'
                        key={type.resource_id}
                        onClick={() => typeSelected(type)}
                      >
                        <div className='title'>
                          <div className='wrapInfo'>
                            <div className='nameWrap'>
                              <span className='name'>
                                {gatewayName(type.resource_id)}
                              </span>
                              {type.resource_id === 'RHSI-EDGE' && (
                                <IconWithToolTip
                                  icon={images.informationIcon()}
                                  iconDescription={t(
                                    'skupperEdgeGatewayTooltip'
                                  )}
                                />
                              )}
                            </div>
                          </div>
                          {selectedGatewayType &&
                          selectedGatewayType.name === type.name ? (
                            <div className='selectedGatewayType'>
                              {images.selectedTickIcon()}
                            </div>
                          ) : null}
                        </div>
                        <div className='icon-container'>
                          {(type.resource_id === 'AXON-EDGE' ||
                            type.resource_id === 'AXON-WP') && (
                            <span className='mesh-icon icon'>
                              {images.meshIconSvgElement()}
                            </span>
                          )}
                          {type.resource_id === 'RHSI-EDGE' && (
                            <span className='redhat-icon icon'>
                              {images.redHatIconSvgElement()}
                            </span>
                          )}
                          {(type.resource_id === 'AXON-EDGE' ||
                            type.resource_id === 'RHSI-EDGE') && (
                            <span className='edge-gateway-icon icon'>
                              {images.EdgeGatewayIcon()}
                            </span>
                          )}
                          {type.resource_id === 'AXON-WP' && (
                            <span className='waypoint-gateway-icon icon'>
                              {images.WaypointGatewayIcon()}
                            </span>
                          )}
                        </div>
                      </div>
                    ))
                )}
              </div>
            </div>
          }
        </div>
      ) : (
        <div className='skeleton-container'>
          <SkeletonPlaceholder className='skeleton-tile' />
        </div>
      )}
    </CreateTearsheetStep>
  );
};

export default GatewaySelect;
