import React from 'react';
import { Dropdown } from 'carbon-components-react';

import './ButtonWithOptions.scss';

interface Props {
  id?: string;
  onChange: (labels: string[]) => void;
  btnText: string;
  items: any;
  disabled: boolean;
}

const ButtonWithOptions: React.FC<Props> = ({
  btnText,
  onChange,
  items,
  disabled,
}) => {
  return (
    <div className='button-options-component'>
      <Dropdown
        id='default'
        titleText=''
        label={btnText}
        items={items}
        selectedItem={null}
        onChange={e => onChange(e.selectedItem.value)}
        itemToString={(item: any) => (item ? item.label : '')}
        disabled={disabled}
      />
    </div>
  );
};

export default ButtonWithOptions;
