import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '@carbon/styles/css/styles.css';
import '@carbon/charts/styles.css';
import {
  Dropdown,
  SkeletonPlaceholder,
  TooltipDefinition,
} from 'carbon-components-react';
import './MetricComponent.scss';
import images from '../../../images/images';
import CustomCharts from '../CustomCharts/CustomCharts';

//To be removed when integrated
import { mockData, typeDrownDown } from './MockMetricData';
import moment from 'moment';
import { ToolbarControlTypes } from '@carbon/charts/interfaces';
import { exportCSV } from '../../../lib/utils';
import { DataTableHeader } from '../../../models/dataTable';

export interface DropdownItem {
  id: number | string;
  text: string;
  title?: string;
  xAxisText?: string;
}

interface chartColorStructure {
  pairing?: any;
  scale: any;
}

export type ChartType = 'multiLine' | 'groupedBar';

interface Props {
  hasFlow?: boolean;
  data?: any;
  typeDropdownTitile?: string;
  metricTypeDropdownId: string;
  chartType?: ChartType;
  typeDropDownOptions?: DropdownItem[];
  selectedType?: DropdownItem;
  handleMetricTypeChange?: (e: any) => void;
  children?: JSX.Element;
  chartAxesMapping?: any;
  legend?: any;
  chartHeight?: string;
  curveType?: string;
  chartComponentId: string;
  metricContainerId: string;
  chartItemsColor?: chartColorStructure;
  bottomChildren?: JSX.Element;
  onChartTypeChange?: (chartType: ChartType) => void;
}

const defaultMetricComponentValues = {
  chartAxesMapping: {
    multiLine: {
      left: {
        title: 'bytes transferred',
        mapsTo: 'value',
      },
      bottom: {
        title: 'time of day',
        scaleType: 'labels',
        mapsTo: 'key',
      },
    },
    groupedBar: {
      left: {
        mapsTo: 'value',
      },
      bottom: {
        scaleType: 'labels',
        mapsTo: 'key',
      },
    },
  },
  typeDropdownTitile: 'Data Type',
  metricTypeDropdownId: 'metric-type_dropdown',
  chartType: 'multiLine',
  chartHeight: '400px',
  legend: {
    position: 'top',
    orientation: 'horizontal',
  },
  chartItemsColor: {
    pairing: {
      option: 2,
    },
    scale: {
      'bytes in': '#005D5D',
      'bytes out': '#002D9C',
      'packets in': '#005D5D',
      'packets out': '#002D9C',
    },
  },
  curveType: 'curveMonotoneX',
};

const MetricsDetails: React.FC<Props> = ({
  data, // data should be made mandatory props in line 20 and assigned should be removed
  typeDropdownTitile = defaultMetricComponentValues.typeDropdownTitile,
  metricTypeDropdownId,
  typeDropDownOptions = typeDrownDown, //assignment should be removed
  selectedType = typeDrownDown[0],
  chartType = defaultMetricComponentValues.chartType,
  handleMetricTypeChange = (selected: any) => {},
  children,
  chartAxesMapping = defaultMetricComponentValues.chartAxesMapping,
  legend = defaultMetricComponentValues.legend,
  chartHeight = defaultMetricComponentValues.chartHeight,
  curveType = defaultMetricComponentValues.curveType,
  chartItemsColor = defaultMetricComponentValues.chartItemsColor,
  chartComponentId,
  metricContainerId,
  bottomChildren,
  onChartTypeChange,
  hasFlow = true,
}) => {
  const [chartTypeSelected, setChartTypeSelected] = useState(chartType);
  const { t, i18n } = useTranslation('metricComponent');
  const [metricChartLoading, setMetricChartLoading] = useState(false);
  const [showTableModal, setShowTableModal] = useState(false);
  moment.locale(i18n.language);
  const exportFileName = 'MetricChart.csv';

  const chartTableHeaders: DataTableHeader[] = [
    {
      key: 'group',
      header: t('group'),
    },
    {
      key: 'displayTime',
      header: chartAxesMapping[chartTypeSelected]?.bottom?.title,
    },
    {
      key: 'value',
      header: chartAxesMapping[chartTypeSelected]?.left?.title,
    },
  ];

  // Customize tooltip options
  const tooltipOptions = {
    customHTML: (data: any, markup: any) => {
      return `
      <ul class="multi-tooltip">
        <li>
          <div class="datapoint-tooltip">
            
            <div class="label">
            <p>${chartAxesMapping[chartTypeSelected]?.bottom?.title}</p>
            
            </div>
            <p class="value"/>${data[0]?.displayTime}</p>
          </div>
        </li>
        <li>
          <div class="datapoint-tooltip">
            <div class="label">
              <p>${chartAxesMapping[chartTypeSelected]?.left?.title}</p>
            </div>
            <p class="value">${data[0]?.value}</p>
          </div>
        </li>
        <li>
          <div class="datapoint-tooltip">
            <div class="tooltip-color tooltip-1-2-1"></div>
            <div class="label">
              <p>${t('group')}</p>
            </div>
            <p class="value">${data[0]?.group}</p>
          </div>
        </li>
      </ul>
      `;
    },
  };

  // Customize toolbar options
  const toolbarOptions = {
    enabled: true,
    controls: [
      {
        id: 'showDatatable',
        type: ToolbarControlTypes.CUSTOM,
        title: 'Data table',
        shouldBeDisabled: () => false,
        iconSVG: {
          content: `<path d="M4 6h18v2H4Z" /><path d="M4 12h18v2H4Z" /><path d="M4 18h18v2H4Z" /><path d="M4 24h18v2H4Z" /><path d="M26 6h2v2H26Z" /><path d="M26 12h2v2H26Z" /><path d="M26 18h2v2H26Z" /><path d="M26 24h2v2H26Z" />`,
        },
        clickFunction: () => {
          setShowTableModal(true);
        },
      },
      {
        type: ToolbarControlTypes.MAKE_FULLSCREEN,
      },
      {
        id: 'exporttocsv',
        type: ToolbarControlTypes.CUSTOM,
        text: t('exportCSV'),
        shouldBeDisabled: () => false,

        clickFunction: async () => {
          // Create export csv data based on the table header configuration.
          // This is to show exactly the same table header text in the csv file
          const exportData = data.map((obj: any) => {
            return {
              [t('group')]: obj.group,
              [chartAxesMapping[chartTypeSelected]?.bottom?.title]:
                obj.displayTime.replace(/,/g, ''),
              [chartAxesMapping[chartTypeSelected]?.left?.title]: obj.value,
            };
          });

          exportCSV(exportData, exportFileName);
        },
      },
      {
        type: ToolbarControlTypes.EXPORT_PNG,
        text: t('exportPNG'),
      },
      {
        type: ToolbarControlTypes.EXPORT_JPG,
        text: t('exportJPG'),
      },
    ],
  };

  useEffect(() => {
    setMetricChartLoading(false);
  }, [selectedType]);

  useEffect(() => {
    if (chartType !== chartTypeSelected) {
      setChartTypeSelected(chartType);
    }
  }, [chartType]);

  const handleChartTypeChange = (value: ChartType) => {
    setChartTypeSelected(value);

    if (typeof onChartTypeChange === 'function') {
      onChartTypeChange(value);
    }
  };

  const handleTableModalClose = () => {
    setShowTableModal(false);
  };

  return (
    <div className='metric-container' id={metricContainerId}>
      <div className='child-items'>{children}</div>
      <div className='selection-container'>
        <Dropdown
          id={metricTypeDropdownId}
          titleText={typeDropdownTitile}
          label={typeDropdownTitile}
          selectedItem={selectedType}
          items={typeDropDownOptions}
          itemToString={item => (item ? item?.text ?? '' : '')}
          onChange={e => {
            setMetricChartLoading(true);
            handleMetricTypeChange(e.selectedItem);
          }}
        />
        <div className='chart-switcher'>
          <TooltipDefinition
            tooltipText={t('lineChart')}
            direction='bottom'
            align='center'
          >
            <div
              className={`chart-type ${
                chartTypeSelected === 'multiLine' ? 'selected' : ''
              }`}
              onClick={() => handleChartTypeChange('multiLine')}
            >
              {chartTypeSelected === 'multiLine'
                ? images.LineChartSwitcher()
                : images.LineChartSelectedIcon()}
            </div>
          </TooltipDefinition>
          <TooltipDefinition
            tooltipText={t('barChart')}
            direction='bottom'
            align='center'
          >
            <div
              className={`chart-type ${
                chartTypeSelected === 'groupedBar' ? 'selected' : ''
              }`}
              onClick={() => handleChartTypeChange('groupedBar')}
            >
              {chartTypeSelected === 'groupedBar'
                ? images.BarChartSwitcher()
                : images.BarChartSelectedIcon()}
            </div>
          </TooltipDefinition>
        </div>
      </div>
      <div className='child-items'>{bottomChildren}</div>
      {metricChartLoading && (
        <div className='metric-skeleton-container'>
          <SkeletonPlaceholder className='chart-skeleton' />
          <SkeletonPlaceholder className='chart-skeleton' />
          <SkeletonPlaceholder className='chart-skeleton' />
        </div>
      )}
      {!metricChartLoading && hasFlow ? (
        <React.Fragment>
          <div id={chartComponentId}>
            <CustomCharts
              title={selectedType?.title ?? selectedType.text}
              data={data}
              chartType={chartTypeSelected}
              axes={chartAxesMapping[chartTypeSelected]}
              legend={legend}
              height={chartHeight}
              curveType={curveType}
              chartItemsColor={chartItemsColor}
              tooltip={tooltipOptions}
              toolbar={toolbarOptions}
              showTableModal={showTableModal}
              tableHeaders={chartTableHeaders}
              onCloseTableModal={handleTableModalClose}
              exportFileName={exportFileName}
            ></CustomCharts>
          </div>
        </React.Fragment>
      ) : !hasFlow ? (
        <React.Fragment>
          <div className='header'>{t('noFlows')}</div>
        </React.Fragment>
      ) : null}
    </div>
  );
};

export default MetricsDetails;
