import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import GenericTableWithFilters from '../../../components/GenericTableWithFilters/GenericTableWithFilters';
import dateUtils from '../../../lib/dates';
import images from '../../../images/images.js';
import { AppliedFilter } from '../../../models/master';
import LabelTag from '../../../components/LabelTag/LabelTag';
import GenericTruncateString from '../../../components/GenericTruncateString/GenericTruncateString';
import './RolesTable.scss';
import useAnalytics from '../../../lib/useAnalytics';
import analyticsData from '../../../lib/analyticsEventData';

interface TableRow {
  id: any;
  name: string;
  name__format: JSX.Element;
  identities: string;
  labels: any;
  lastUpdated: string;
}

const RolesTable = (props: {
  filteredDataCallback: (data: any) => void;
  filtersSelected: any;
  filtersAppliedCallback: (data: any) => void;
  filters: any;
  rows: any[] | null;
  data: any[] | null;
  filteredDataSet: any[] | null;
  elementCount: number;
  onRefresh: () => void;
  currentPageNumber: number;
  currentPageSize: number;
  onPageChange: (pageData: any) => void;
  sortRows(arg0: unknown, direction: string): void;
  error500Flag: boolean;
}) => {
  const { t } = useTranslation('roles');
  const { rows, onRefresh } = props;
  const tableRef = useRef<HTMLDivElement>(null);
  const { trackButtonClicked } = useAnalytics();

  const error500Flag = props.error500Flag;

  const formatTags = (data: string[]) => {
    return <LabelTag labelArray={data} count={2} />;
  };

  const myheaders = [
    {
      key: 'name__format',
      originalKey: 'name',
      header: t('tableHeaders.name'),
      sort: true,
    },
    {
      key: 'identities',
      originalKey: 'identities',
      header: t('tableHeaders.identities'),
      sort: true,
    },
    {
      key: 'labels',
      originalKey: 'labels',
      header: t('tableHeaders.labels'),
    },
    {
      key: 'lastUpdated',
      originalKey: 'updated_at',
      header: t('tableHeaders.lastUpdated'),
      sort: true,
    },
  ];

  /**
   * @description - Function to format the raw data into table rows format.
   * @returns {TableRow[] | [] | null} - Returns the formatted data to be rendered as table rows.
   */
  const setRowsData = () => {
    let formattedRows: TableRow[] = [];
    if (error500Flag) return [];
    if (rows && rows.length === 0) return [];
    if (rows)
      rows.map((row: any) => {
        formattedRows.push({
          id: row.resource_id,
          name: !!row.name ? row.name : '—',
          name__format: (
            <div>
              <Link
                className='no-underline-link'
                onClick={() => {
                  trackButtonClicked(
                    analyticsData['Admin Roles'].events.viewRoles.props,
                    analyticsData['Admin Roles'].events.viewRoles.event
                  );
                }}
                to={`/roleDetails?roleId=${row.resource_id}`}
              >
                <GenericTruncateString str={row.name} tableView={true} />
              </Link>
            </div>
          ),
          labels: row?.labels ? formatTags(row?.labels) : '—',
          identities: row.identities.length ?? '—',

          lastUpdated: !!row.updated_at
            ? dateUtils.getUserFriendlyDate(row.updated_at)
            : '—',
        });
        return 0;
      });
    else return null;
    return formattedRows;
  };

  const emptyStateData = {
    icon: images.noRoleIcon(),
    notFoundIcon: images.NotFoundLarge(),
    emptyStateHeader: t('emptyState.emptyContainerHeader'),
    emptyStateDescription: t('emptyState.emptyContainerDescription'),
    link: '/',
    buttonText: t('emptyState.buttonText'),
  };

  return (
    <div className='roles-list-table' ref={tableRef}>
      <GenericTableWithFilters
        id='table-with-filter-panel'
        rows={setRowsData()}
        data={props.filteredDataSet}
        headers={myheaders}
        isSortable={true}
        totalElementsCount={props.elementCount ? props.elementCount : 0}
        fullData={props.data}
        onTableRefresh={() => onRefresh()}
        filteredDataCallback={(data: TableRow[]) =>
          props.filteredDataCallback(data)
        }
        selectedFiltersVal={props.filtersSelected as any}
        setFilterApplied={(data: AppliedFilter[]) =>
          props.filtersAppliedCallback(data)
        }
        filters={props.filters}
        currentPageNumber={props.currentPageNumber}
        currentPageSize={props.currentPageSize}
        onPageChange={(pageData: any) => props.onPageChange(pageData)}
        emptyState={emptyStateData}
        sortRows={(data, sortDirection) => props.sortRows(data, sortDirection)}
        render500Container={props.error500Flag}
      />
    </div>
  );
};

export default RolesTable;
