import { DeploymentEnvironment, Namespace } from '../models/master';
import { axiosInstance } from './api';
import { MCNM_NETWORK_API_URL } from './config';

export async function getAllCloudNamespaces(
  cloudId: string,
  deploymentEnvironments: DeploymentEnvironment[] | null,
  view = 'all'
) {
  try {
    let namespaces: Namespace[] = [];

    if (Array.isArray(deploymentEnvironments)) {
      const clusters = deploymentEnvironments?.filter(
        deploymentEnvironment =>
          deploymentEnvironment.type === 'cluster' &&
          deploymentEnvironment.cloud_id === cloudId
      );

      for (const cluster of clusters) {
        try {
          const response = await axiosInstance({
            method: 'GET',
            url: `${MCNM_NETWORK_API_URL}/cloud/${cloudId}/cluster/${cluster.resource_id}/namespace?visibility=${view}`,
          });

          namespaces = [...namespaces, ...response.data.namespaces];
        } catch (error) {
          throw error;
        }
      }
    }

    return namespaces;
  } catch (error) {
    throw error;
  }
}
