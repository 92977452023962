import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tile, Button, Modal } from 'carbon-components-react';
import { Add16 } from '@carbon/icons-react';
import {
  PolicyData,
  Service,
  ServiceData,
  Port,
  CompatibilitySet,
} from '../../../models/master';
import images from '../../../images/images';
import ManageService from '../ManageService/ManageService';
import ServiceTile from './ServiceTile/ServiceTile';
import { VerticalEmptyState } from '../../../components/EmptyState/EmptyState';
import './ServicesTile.scss';
import { useLocation } from 'react-router-dom';
import useAnalytics from '../../../lib/useAnalytics';
import analyticsData from '../../../lib/analyticsEventData';
import debounce from 'lodash/debounce';

type LocationState = {
  resourceType: string;
};

interface Props {
  services: any[];
  applicationId: string | null;
  addService: (data: Service) => void;
  updateService: (data: Service) => void;
  removeService: (id: string) => void;
  onCloseService: () => void;
  policiesList: PolicyData[] | null;
  networkSegmentCompatibilitySet: CompatibilitySet;
  disableDeleteServiceButton: boolean;
}

const ServicesTile = (props: Props) => {
  const { t } = useTranslation('applicationDetails');
  const {
    services,
    applicationId,
    addService,
    updateService,
    removeService,
    onCloseService,
    policiesList,
    networkSegmentCompatibilitySet,
    disableDeleteServiceButton,
  } = props;

  const [openServiceModal, toggleServiceModal] = useState(false);
  const [mode, setMode] = useState<'ADD' | 'EDIT'>('ADD');
  const [selectedService, setSelectedService] = useState<ServiceData | null>(
    null
  );
  const [deleteServiceModalOpen, toggleDeleteServiceModal] = useState(false);
  const [deleteServiceId, setDeleteServiceId] = useState<string | null>(null);
  const [deleteServiceName, setDeleteServiceName] = useState<string | null>(
    null
  );

  const location = useLocation();
  const state = location.state as LocationState;

  const { trackButtonClicked } = useAnalytics();

  useEffect(() => {
    if (state?.resourceType === 'SERVICE') {
      toggleServiceModal(true);
    }
  }, [state]);

  const allPortNumbers: Port[] = services.reduce((acc, service) => {
    return acc.concat(
      service.ports.map((port: Port) => {
        return {
          port_number: port.port_number,
          protocol: port.protocol,
        };
      })
    );
  }, []);

  const openEditServiceModal = (serviceId: string) => {
    const service = services.find(service => service.id === serviceId);

    if (service) {
      setMode('EDIT');
      setSelectedService(service);
      toggleServiceModal(true);
    }
  };

  const confirmDeleteService = () => {
    trackButtonClicked(
      analyticsData['Application Details'].events.deleteService.props,
      analyticsData['Application Details'].events.deleteService.event
    );
    toggleDeleteServiceModal(false);
    deleteServiceId && removeService(deleteServiceId);
  };

  const requestDeleteService = (id: string, name: string) => {
    setDeleteServiceId(id);
    setDeleteServiceName(name);
    toggleDeleteServiceModal(true);
  };

  const openAddServiceModal = () => {
    setMode('ADD');
    setSelectedService(null);
    toggleServiceModal(true);
  };

  const closeServiceModal = () => {
    onCloseService();
    setMode('ADD');
    setSelectedService(null);
    toggleServiceModal(false);
  };
  return (
    <>
      <Tile className='services-tile'>
        <div className='header-container'>
          <div className='header'>
            {services.length > 0
              ? t('servicesTile.header.1', { count: services.length })
              : t('servicesTile.header.0')}
          </div>

          <Button
            className='add-service-button'
            kind='ghost'
            onClick={() => {
              trackButtonClicked(
                analyticsData['Application Details'].events.registerServiceLink
                  .props,
                analyticsData['Application Details'].events.registerServiceLink
                  .event
              );
              openAddServiceModal();
            }}
          >
            {t('servicesTile.addService')}
            <Add16 />
          </Button>
        </div>
        {services.length > 0 ? (
          <div className='services'>
            {services.map(service => (
              <ServiceTile
                key={service.id}
                serviceId={service.id}
                name={service.name}
                ports={service.ports}
                labels={service.labels}
                removeService={requestDeleteService}
                openEditModal={openEditServiceModal}
                onCloseService={closeServiceModal}
                policiesList={policiesList}
              />
            ))}
          </div>
        ) : (
          <VerticalEmptyState
            icon={images.noServicesSmallIcon()}
            header={t('servicesTile.emptyState.header')}
            description={`${t('servicesTile.emptyState.description')}`}
          />
        )}
        {applicationId && (
          <ManageService
            mode={mode}
            serviceData={selectedService ?? null}
            applicationId={applicationId}
            open={openServiceModal}
            onCloseService={closeServiceModal}
            addService={addService}
            updateService={updateService}
            allPortNumbers={allPortNumbers}
            networkSegmentCompatibilitySet={networkSegmentCompatibilitySet}
          />
        )}
      </Tile>
      <Modal
        className='delete-service-modal'
        danger
        modalHeading={t('servicesTile.deleteModal.header')}
        onRequestClose={() => toggleDeleteServiceModal(false)}
        onRequestSubmit={debounce(confirmDeleteService, 1000)}
        primaryButtonText={t('servicesTile.deleteModal.confirm')}
        secondaryButtonText={t('servicesTile.deleteModal.cancel')}
        open={deleteServiceModalOpen}
        size='sm'
        primaryButtonDisabled={disableDeleteServiceButton}
      >
        {t('servicesTile.deleteModal.body', {
          serviceName: deleteServiceName,
        })}
      </Modal>
    </>
  );
};

export default ServicesTile;
