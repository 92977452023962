import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  registerNamespace,
  registerSecurityGroup,
} from '../../../controllers/partitionApi';

import NarrowTearsheetComponent from '../../../components/NarrowTearsheet/NarrowTearsheet';
import RegisterPartitionForm from './RegisterPartitionForm';
import InlineNotification from '../../../components/Notifications/Inline/Notification';
import { NotificationContext } from '../../../components/Notifications/Context/NotificationProvider';
import { removeExtraSpace } from '../../../lib/utils';
import { AxiosError } from 'axios';

import './RegisterPartition.scss';
import { getSecrets } from '../../../controllers/tenantApis';

const defaultFormValue = {
  name: {
    value: '',
    error: false,
    errorMessage: '',
  },
  labels: {
    value: [],
    error: false,
    errorMessage: '',
  },

  // the key in the secrets manager for the credentials to this node/cluster
  credentials_key: {
    id: '',
    name: '',
    error: false,
    errorMessage: '',
  },
  auto_discover: {
    value: false,
    error: false,
    errorMessage: '',
  },
  managedStatus: {
    value: true,
    error: false,
    errorMessage: '',
  },
  secretsName: {
    value: '',
    error: false,
    errorMessage: '',
  },
};

const requiredFields = ['name', 'secretsName'];

interface Props {
  open: boolean;
  onClose: () => void;
  partitonType: any;
  deploymentdata: any;
  creatPartition: () => void;
}

const RegisterPartition: React.FC<Props> = ({
  open,
  onClose,
  partitonType,
  deploymentdata,
  creatPartition,
}) => {
  const [formData, setFormData] = useState<any>(defaultFormValue);
  const [showFailNotification, toggleFailNotification] = useState(false);
  const [changed, setChanged] = useState(false);
  const { t } = useTranslation('registerPartition');
  const notification = useContext(NotificationContext);
  const [authError, setAuthError] = useState(false);
  const [secrets, setSecrets] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchSecrets = async () => {
    try {
      await getSecrets().then(response => setSecrets(response.secrets));
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (open) fetchSecrets();
  }, [open]);

  const isFormValid = () => {
    for (const field of requiredFields) {
      const value = formData[field].value;
      const trimmedValue = typeof value === 'string' ? value.trim() : value;
      if (checkFieldValidation(field, trimmedValue)) {
        return false;
      }
    }
    return true;
  };

  const cancelForm = () => {
    onClose();
    setFormData(defaultFormValue);
    toggleFailNotification(false);
    setAuthError(false);
  };

  const checkFieldValidation = (name: string, value: any) => {
    let errorMessage = '';
    switch (name) {
      case 'name':
        errorMessage = !value ? t('validation.name.required') : '';
        break;
      case 'secretsName':
        errorMessage = formData?.auto_discover?.value
          ? !value
            ? t('validation.secretsName.required')
            : ''
          : '';
        break;
    }
    return errorMessage;
  };

  const handleOnChange = (name: string, value: any) => {
    if (!changed) setChanged(true);
    const errorMessage = checkFieldValidation(name, value);

    setFormData((prevState: any) => ({
      ...prevState,
      [name]: {
        value,
        error: !!errorMessage,
        errorMessage,
      },
    }));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const partition_data = {
        name: removeExtraSpace(formData?.name?.value),
        labels: formData?.labels?.value,
        auto_discover: formData?.auto_discover?.value,
        unmanaged: !formData?.managedStatus?.value,
        is_discovered: false,
        credentials_key: formData?.auto_discover?.value
          ? formData?.secretsName?.value.name
          : '',
      };

      let nameSpaceData, securityGroupData;
      if (partitonType === 'cluster') {
        nameSpaceData = await registerNamespace(
          deploymentdata.cloud_id,
          deploymentdata.resource_id,
          partition_data
        );
      } else if (partitonType === 'vpc') {
        securityGroupData = await registerSecurityGroup(
          deploymentdata.cloud_id,
          deploymentdata.resource_id,
          partition_data
        );
      }

      notification.onTrigger('TOAST', {
        title:
          partitonType === 'cluster'
            ? t('successNotification.title')
            : t('successNotification.titleSecurityGroup'),
        subtitle:
          partitonType === 'cluster'
            ? t('successNotification.subtitle', {
                deploymentEnvName: deploymentdata.name,
                nameSpaceName: nameSpaceData.name,
              })
            : t('successNotification.subtitleSecurityGroup', {
                deploymentEnvName: deploymentdata.name,
                securityGroupName: securityGroupData.name,
              }),
      });

      setChanged(false);
      creatPartition();
      setFormData(defaultFormValue);
      toggleFailNotification(false);
      setAuthError(false);
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        toggleFailNotification(true);
        setAuthError(true);
        // We added this project.reject to prevent the modal closing when error occured.
        // But in latest version of '@carbon/ibm-products' the below line is not required and is throwing error.
        // To prevent closing of modal we need to return promise rejection
        // return Promise.reject(() => console.error(err));
      }

      console.log(error);
      toggleFailNotification(true);
      // We added this project.reject to prevent the modal closing when error occured.
      // But in latest version of '@carbon/ibm-products' the below line is not required and is throwing error.
      // return Promise.reject(() => console.log(error));
    } finally {
      setLoading(false);
    }
  };

  const handleErrorBarClose = () => {
    toggleFailNotification(false);
    setAuthError(false);
  };

  return (
    <NarrowTearsheetComponent
      className='register-partition-tearsheet'
      title={
        partitonType === 'cluster'
          ? t('titleNamespace')
          : t('titleSecurityGroup')
      }
      description={
        partitonType === 'cluster'
          ? t('namespaceDescription')
          : t('securityGroupDescription')
      }
      open={open}
      actions={[
        {
          kind: 'primary',
          label: t('submitButtonText'),
          onClick: () => handleSubmit(),
          disabled: !isFormValid() || !changed,
          loading: loading,
        },
        {
          kind: 'secondary',
          label: t('cancelButtonText'),
          onClick: () => {
            cancelForm();
            toggleFailNotification(false);
            setChanged(false);
            setAuthError(false);
          },
        },
      ]}
      dataTestId='register-partition-tearsheet'
    >
      {showFailNotification && (
        <InlineNotification
          onClose={() => handleErrorBarClose() as any}
          kind={'error'}
          title={
            authError
              ? (t('failureNotification.authTitle') as string)
              : partitonType === 'cluster'
              ? (t('failureNotification.title') as string)
              : (t('failureNotification.titleSecurityGroup') as string)
          }
          subtitle={
            authError
              ? (t('failureNotification.authSubtitle') as string)
              : partitonType === 'cluster'
              ? (t('failureNotification.subtitle') as string)
              : (t('failureNotification.subtitle') as string)
          }
        />
      )}
      <RegisterPartitionForm
        formData={formData}
        onChange={handleOnChange}
        secrets={secrets}
        partitionType={partitonType}
      />
    </NarrowTearsheetComponent>
  );
};

export default RegisterPartition;
