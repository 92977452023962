import React from 'react';
import { Button } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';

import './LegendButton.scss';

interface Props {
  legendCustomStyle: {
    [key: string]: string;
  };
  setLegendOpen: (open: boolean) => void;
}

const LegendButton: React.FC<Props> = ({
  legendCustomStyle,
  setLegendOpen,
}) => {
  const { t } = useTranslation('topologyCanvasView');

  const handleLegendBtn = () => {
    setLegendOpen(true);
  };

  return (
    <>
      <div
        className='topology-legend-btn-container'
        style={legendCustomStyle ? legendCustomStyle : {}}
      >
        <Button
          className='topology-legend-btn'
          kind='primary'
          title={t('legend')}
          iconDescription={t('legend')}
          onClick={handleLegendBtn}
        >
          {t('legend')}
        </Button>
      </div>
    </>
  );
};

export default LegendButton;
