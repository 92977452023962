import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './i18n';
import App from './App.js';
import NotificationProvider from './components/Notifications/Context/NotificationProvider';
import reportWebVitals from './reportWebVitals';
import customHistory from './history';
import { CustomRouter } from './components/CustomRouter/CustomRouter';
import { AnalyticsProvider } from './components/analytics-context';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// Enable below import to show react query developer tools.
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <CustomRouter history={customHistory}>
        <AnalyticsProvider>
          <NotificationProvider>
            <App />
          </NotificationProvider>
        </AnalyticsProvider>
      </CustomRouter>
      {/* Enable below import to show react query developer tools */}
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
