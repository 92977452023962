import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tile, Button } from 'carbon-components-react';

import { ResourceGroup } from '../../../models/master';
import images from '../../../images/images';
import { VerticalEmptyState } from '../../../components/EmptyState/EmptyState';
import { useLocation, useNavigate } from 'react-router-dom';
import GwDeploymentEnvironmentList from './GatewayEnvironmentList';
import { getResourceGroups } from '../../../controllers/resourceGroupApi';

import './GatewayEnvironmentsList.scss';

type LocationState = {
  resourceType: string;
};

interface Props {
  deploymentEnvs: any[];
  gatewayId: string | null;
  addDeploymentEnv: (data: any) => void;
  resourceGroupsData: ResourceGroup[] | null;
  gwSubtype?: string | null;
}

const GwDeploymentEnvironmentsList = (props: Props) => {
  const { t } = useTranslation('gatewayDetails');
  const { deploymentEnvs, resourceGroupsData } = props;
  const [openServiceModal, toggleServiceModal] = useState(false);
  const location = useLocation();
  const state = location.state as LocationState;
  const navigate = useNavigate();

  const gatewaySubtype = props?.gwSubtype;

  useEffect(() => {
    if (state?.resourceType === 'GATEWAY') {
      toggleServiceModal(true);
    }
  }, [state]);

  const getResourceGroupName = (id: string | undefined) => {
    const resourceGroup =
      id && Array.isArray(resourceGroupsData)
        ? resourceGroupsData!.find(
            resourceGroup => resourceGroup.resource_id === id
          )
        : null;

    return resourceGroup ? resourceGroup.name : 'Default_Infrastructure_Group';
  };

  return (
    <>
      <Tile className='gateway-envs-services-tile'>
        <div className='header-container'>
          <div className='header'>
            {t('depEnvsTile.header', {
              title: t('depEnvsTile.environments'),
              count: deploymentEnvs?.length,
            })}
          </div>
        </div>
        {deploymentEnvs?.length > 0 ? (
          <div className='services'>
            {deploymentEnvs?.map((depEnv: any) => (
              <GwDeploymentEnvironmentList
                key={depEnv?.resource_id}
                depEnvId={depEnv?.resource_id}
                name={depEnv?.name}
                resGrp={getResourceGroupName(depEnv?.resource_group_id)}
                labels={depEnv?.labels}
              />
            ))}
          </div>
        ) : (
          <VerticalEmptyState
            icon={images.NoDeploymentEmptySmall()}
            header={t('depEnvsTile.emptyState.header')}
            description={`${t('depEnvsTile.emptyState.description')}`}
          />
        )}
      </Tile>
    </>
  );
};

export default GwDeploymentEnvironmentsList;
