import React from 'react';
import {
  TextInput,
  ComboBox,
  Row,
  FormLabel,
  Toggle,
  TooltipIcon,
} from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import { Information16 } from '@carbon/icons-react';
import AddLabels from '../../../components/AddLabels/AddLabels';
import './RegisterPartition.scss';
import { Secret } from '../../../models/master';

const RegisterPartitionForm = (props: {
  onChange: any;
  formData: any;
  secrets: Secret[] | null;
  partitionType: any;
}) => {
  const { onChange, formData, secrets, partitionType } = props;

  const { t } = useTranslation('registerPartition');
  return (
    <div className='register-partition-form'>
      <TextInput
        id='addNamespaceName'
        className='form-field'
        name='name'
        placeholder={
          partitionType === 'cluster'
            ? t('form.name.placeholder')
            : t('form.name.placeholderSecurity')
        }
        labelText={t<string>('form.name.label')}
        value={formData.name.value}
        onChange={e => onChange('name', e.target.value)}
        invalid={formData.name.error}
        invalidText={formData.name.errorMessage}
        maxLength={60}
      />

      <Row className='row autodiscover-row'>
        <div className='autodiscover-toggle-group'>
          <FormLabel className='label'>
            {t('addDetails.managedToggele.label')}
            <TooltipIcon
              className='autodiscover-tooltip'
              tooltipText={t('addDetails.managedToggele.tooltipText') as string}
            >
              <Information16 />
            </TooltipIcon>
          </FormLabel>
          <span className={'autodiscover-toggle-row'}>
            <Toggle
              className={'autodiscover-toggle'}
              labelText={''}
              id='managed-toggle-1'
              labelA={t('addDetails.managedToggele.managedOff')}
              labelB={t('addDetails.managedToggele.managedOn')}
              toggled={formData.managedStatus.value}
              onToggle={(val: any, id: string) =>
                onChange('managedStatus', val)
              }
              disabled={formData.auto_discover.value}
            />
          </span>
        </div>
      </Row>

      <AddLabels
        id='addApplicationLabels'
        labelText={t('form.labels.label')}
        placeholder={t('form.labels.placeholder')}
        onChange={data => onChange('labels', data)}
        btnText={t('form.labels.buttonText')}
        btnKind='secondary'
        tagType='green'
        defaultValues={formData.labels.value}
      />
      <ComboBox
        disabled={!(formData.auto_discover.value === true)}
        id='addSecretsName'
        name='secretsName'
        className='form-field'
        selectedItem={formData.secretsName.value}
        onChange={data => onChange('secretsName', data.selectedItem)}
        items={Array.isArray(secrets) ? secrets : []}
        itemToString={item => (item ? item.name : '')}
        translateWithId={t}
        titleText={t('form.secretsName.label')}
        placeholder={t('form.secretsName.placeholder')}
        invalid={formData.secretsName.error}
        invalidText={formData.secretsName.errorMessage}
      />

      <span id='secret-label' className='bx--label'>
        {t('secretAutoDiscoveryLabel')}
      </span>

      <Row className='row autodiscover-row'>
        <div className='autodiscover-toggle-group'>
          <FormLabel className='label'>
            {t('addDetails.autoDiscover.label')}
            <TooltipIcon
              className='autodiscover-tooltip'
              tooltipText={
                partitionType === 'cluster'
                  ? (t('addDetails.autoDiscover.tooltipText') as string)
                  : t('addDetails.autoDiscover.tooltipTextSecurityGroup')
              }
            >
              <Information16 />
            </TooltipIcon>
          </FormLabel>
          <span className={'autodiscover-toggle-row'}>
            <Toggle
              className={'autodiscover-toggle'}
              labelText={''}
              id='autodiscover-toggle-2'
              labelA={t('addDetails.managedToggele.managedOff')}
              labelB={t('addDetails.managedToggele.managedOn')}
              toggled={formData.auto_discover.value}
              onToggle={(val: any, id: string) => {
                onChange('auto_discover', val);
                formData.secretsName.value = '';
              }}
              disabled={!formData.managedStatus.value}
            />
          </span>
        </div>
      </Row>
    </div>
  );
};

export default RegisterPartitionForm;
