import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AppliedFilter, Service } from '../../../models/master';

import './PolicyOptions.scss';
import WideTearsheet from '../../../components/WideTearsheet/WideTearsheet';
import {
  Checkbox,
  RadioButton,
  Tab,
  Tabs,
  Tag,
  TooltipDefinition,
} from 'carbon-components-react';
import { useNavigate } from 'react-router';
import sortData from '../../../lib/tableSort';
import GenericTableWithFilters from '../../../components/GenericTableWithFilters/GenericTableWithFilters';
import { Filter } from '../../../components/FindAndFilterBar/FindAndFilterBar';
import PolicyTargetField from '../../../components/PolicyTargetField/PolicyTargetField';
import { IconType } from '../../../components/PolicyTargetField/config';
import {
  ConnectionTypesItems,
  Item,
  SelectedType,
  TableTypes,
  getEmptyStateValue,
  getTabConfig,
  getTableFilters,
  getTableHeaders,
} from '../config';

interface Props {
  open: boolean;
  fromItems: Item[];
  toItems: Item[];
  selected: SelectedType;
  fromSelectedItems: Item[];
  toSelectedItems: Item[];
  onClose: (close?: boolean) => void;
  onRefresh: (t: TableTypes) => void;
  onOptionsChange: (value: boolean, data: Item) => void;
  getTableData: (type: TableTypes) => ConnectionTypesItems;
  partitionPermission?: boolean;
  applicationPermission?: boolean;
  servicePermission?: boolean;
}

const PolicyOptions: React.FC<Props> = ({
  open,
  fromItems,
  toItems,
  selected,
  fromSelectedItems,
  toSelectedItems,
  onRefresh,
  onClose,
  onOptionsChange,
  getTableData,
  partitionPermission,
  applicationPermission,
  servicePermission,
}) => {
  const { t } = useTranslation('policyOptions');

  const [tabIndex, setTabIndex] = useState(0);
  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | 'NONE'>(
    'NONE'
  );
  const navigate = useNavigate();
  const [filteredPartitionData, setFilteredPartitionData] =
    useState<ConnectionTypesItems>([]);
  const [filterApplied, setFilterApplied] = useState<AppliedFilter[] | []>([]);
  const [partitionFilterApplied, setPartitionFilterApplied] = useState<
    AppliedFilter[] | []
  >([]);
  const [partitionVisibilityFlag, setPartitionVisibilityFlag] = useState('all');
  const [filteredData, setFilteredData] = useState<ConnectionTypesItems>([]);
  const [currentPageNumber, setPageNumber] = useState(1);
  const [currentPageSize, setPageSize] = useState(25);

  useEffect(() => {
    setFilterApplied([]);
    setPartitionFilterApplied([]);
    selected === 'from' &&
    fromItems.length &&
    fromItems[0].type === 'partitions'
      ? setTabIndex(1)
      : setTabIndex(0);
  }, [selected, fromItems, toItems]);

  const tabs = getTabConfig(selected, t);

  const renderLabels = (labels: string[]) => {
    return (
      <div>
        {labels.map((label, index) => (
          <Tag className='label' key={index} type='green'>
            {label}
          </Tag>
        ))}
      </div>
    );
  };

  const renderCheckBox = (
    item: Item,
    checked: boolean,
    disabled: boolean,
    type: string
  ) => {
    if (type === 'services')
      return (
        <RadioButton
          id={'' + item.id}
          labelText=''
          hideLabel
          checked={checked}
          onClick={e =>
            onOptionsChange((e.target as HTMLInputElement).checked, item)
          }
        />
      );
    else
      return (
        <div>
          <Checkbox
            id={'' + item.id}
            labelText=''
            hideLabel
            checked={checked}
            disabled={disabled}
            onChange={(value: boolean) => onOptionsChange(value, item)}
          />
        </div>
      );
  };

  const isChecked = (id: string | number) => {
    let item: Item | null;

    if (selected === 'from') {
      item = fromSelectedItems.find(item => item.id === id) ?? null;
    } else {
      item = toSelectedItems.find(item => item.id === id) ?? null;
    }

    return item ? true : false;
  };

  const getFormattedRows = (
    items: ConnectionTypesItems,
    type: TableTypes,
    valueColumn: string
  ) => {
    const formattedData: {
      checkbox: JSX.Element;
      id: string;
      name: string;
      resourceGroups: string;
      services: number;
      labels: string[] | JSX.Element;
      applicationName: string;
      resource_id: string;
      application_id: string;
      port: string;
      protocol: string;
      portProtocol: string;
      created_at: string;
      updated_at: string;
    }[] = [];
    if (items)
      items.map(item => {
        // Render Labels if labels attribute present in table data

        let dataObj = JSON.parse(JSON.stringify(item));
        if (Array.isArray(item.labels)) {
          dataObj.labels = renderLabels(item.labels);
        }

        const { ports = [] } = item as Service;
        // Displaying first port items for experimental release
        if (ports.length > 0) {
          const portProtocols: string[] = [];
          ports.forEach((port: any) => {
            portProtocols.push(
              `${port.port_number}/${port.protocol.toUpperCase()}`
            );
          });
          dataObj = {
            ...dataObj,
            port: ports[0].port_number,
            protocol: ports[0].protocol,
            portProtocol: portProtocols.toString().replaceAll(',', ', '),
          };
        }
        // Render custom checkboxes
        const data = {
          id: item.id,
          label: (item as any)[valueColumn],
          type,
          parentId: type === 'partitions' ? (item as any).deploymentEnvId : '',
        };

        const checked = isChecked(item.id);
        // Note: For now we need to prevent multi selection by disabling the checkboxes. We may later remove this.
        const disabled =
          selected === 'from'
            ? fromSelectedItems.length > 0 && !checked
            : toSelectedItems.length > 0 && !checked;

        formattedData.push({
          ...dataObj,
          name: (
            <PolicyTargetField
              label={(item as any).name}
              type={
                (type === 'partitions'
                  ? 'partition'
                  : type === 'applications'
                  ? 'application'
                  : 'service') as IconType
              }
            />
          ),
          checkbox: renderCheckBox(data, checked, disabled, type),
        });
        return null;
      });
    return formattedData;
  };

  const getInlineFilterData = (type: string, items: ConnectionTypesItems) => {
    if (
      Array.isArray(items) &&
      type === 'partitions' &&
      partitionVisibilityFlag !== 'all'
    ) {
      let filteredData = (items as any)?.filter(
        (item: any) => item.cloud_name === partitionVisibilityFlag
      );
      return filteredData;
    }
    return items;
  };

  const handleTableSort = (
    data: { id: string; text: string },
    sortDirection: 'ASC' | 'DESC' | 'NONE'
  ) => {
    setSortDirection(sortDirection);
    setSortKey(data.id);
  };

  const getSelectedDataCount = (type: TableTypes, selectedItems: any[]) => {
    if (selectedItems && selectedItems.length > 0) {
      const selectedType = selectedItems.filter(item => item.type === type);
      return selectedType.length;
    }
    return 0;
  };

  const handleRefresh = () => {
    setFilterApplied([]);
    setFilteredData([]);
    setPartitionFilterApplied([]);
    setFilteredPartitionData([]);
    setSortKey('');
    setSortDirection('NONE');
  };

  const getFilteredValues = (filter: any, data: any) => {
    if (filter.key === 'labels') {
      return [
        ...Array.from(
          new Set(
            data
              ?.map(
                (eachData: { [x: string]: any }) => eachData[filter.columnKey]
              )
              .flat()
          )
        ),
      ];
    } else if (filter.key === 'ports') {
      const values: string[] = [];
      data?.forEach((rec: any) => {
        rec?.ports?.forEach((port: any) => {
          values.push(port?.port_number);
        });
      });
      return [...Array.from(new Set(values))];
    } else {
      return [
        ...Array.from(
          new Set(
            data?.map(
              (eachData: { [x: string]: any }) => eachData[filter.columnKey]
            )
          )
        ),
      ];
    }
  };

  const getFilter = (filters: any[], data: ConnectionTypesItems) => {
    const formattedFilters: {
      key: any;
      type: any;
      label: string;
      mdWidth?: number;
      lgWidth?: number;
      values: unknown[];
    }[] = [];
    if (data)
      filters.map(
        (filter: {
          key: any;
          type: any;
          label: string;
          columnKey: string | number;
        }) => {
          formattedFilters.push({
            key: filter.key,
            type: filter.type,
            label: filter.label,
            mdWidth: 3,
            lgWidth: 4,
            values: getFilteredValues(filter, data),
          });
          return null;
        }
      );
    return formattedFilters;
  };

  const getPartitionLeftInlineFilters = (tableData: ConnectionTypesItems) => {
    return [
      {
        key: t('view'),
        label: t('table.partitions.leftFilter.label'),
        type: 'single',
        values: [t('table.partitions.leftFilter.dropdownOptionAll')]
          .concat(
            Array.from(new Set(tableData?.map((item: any) => item.cloud_name)))
          )
          .map(item => {
            return item === t('table.partitions.leftFilter.dropdownOptionAll')
              ? {
                  label: item,
                  value: 'all',
                }
              : {
                  label: item,
                  value: item,
                };
          }) as any,
        filterCallback: (e: any) => {
          if (e.selectedItem.label) {
            setPartitionVisibilityFlag(
              e.selectedItem.value ?? e.selectedItem.label
            );
          }
        },
      },
    ];
  };

  const setPageChange = (pageData: { page: number; pageSize: number }) => {
    setPageNumber(pageData.page);
    setPageSize(pageData.pageSize);
  };

  const isTabDisabled = (tabName: string) => {
    return (
      (selected === 'from' &&
        fromSelectedItems.length > 0 &&
        fromSelectedItems[0]?.type !== tabName) ??
      false
    );
  };

  const fromToOptionTableRows = (tabname: string) => {
    if (
      (tabname === 'applications' && !applicationPermission) ||
      (tabname === 'partitions' && !partitionPermission) ||
      (tabname === 'services' && !servicePermission)
    ) {
      return [];
    } else {
      return null;
    }
  };

  const show403Container = (tabname: string) => {
    if (
      (tabname === 'applications' && !applicationPermission) ||
      (tabname === 'partitions' && !partitionPermission) ||
      (tabname === 'services' && !servicePermission)
    ) {
      return true;
    }
  };

  return (
    <WideTearsheet
      className='policy-options-tearsheet'
      title={selected === 'from' ? t('from.header') : t('to.header')}
      description={selected === 'from' ? t('from.sublabel') : t('to.sublabel')}
      open={open}
      actions={[
        {
          kind: 'primary',
          label: t('save'),
          onClick: () => onClose(true),
        },
        {
          kind: 'secondary',
          label: t('cancel'),
          onClick: () => {
            onClose();
          },
        },
      ]}
    >
      <Tabs
        selected={tabIndex}
        onSelectionChange={(index: any) => setTabIndex(index)}
        type='container'
        className='policy-options-tabs'
      >
        {Array.isArray(tabs) &&
          tabs.map(tab => {
            const headers = getTableHeaders(tab.name as TableTypes, t);
            const filters = getTableFilters(tab.name as TableTypes, t);
            const tableData = getTableData(tab.name as TableTypes);

            const emptyState = getEmptyStateValue(
              tab.name as TableTypes,
              t,
              navigate
            );
            const formattedRows = Array.isArray(tableData)
              ? getFormattedRows(
                  getInlineFilterData(tab.name, tableData),
                  tab.name as TableTypes,
                  tab.valueColumn
                )
              : null;
            const selectedDataCount = getSelectedDataCount(
              tab.name as TableTypes,
              selected === 'from' ? fromSelectedItems : toSelectedItems
            );
            const inlineFilteredTableData = getInlineFilterData(
              tab.name,
              tableData
            );
            const filteredItems =
              tab.name === 'partitions' ? filteredPartitionData : filteredData;
            const appliedFilters =
              tab.name === 'partitions'
                ? partitionFilterApplied
                : filterApplied;
            return (
              <Tab
                className={`tab ${tab.name} ${
                  isTabDisabled(tab.name) ? 'disabled' : ''
                }`}
                key={tab.name}
                label={
                  isTabDisabled(tab.name) ? (
                    <TooltipDefinition
                      tooltipText={t('disabledTabTooltip')}
                      direction='bottom'
                    >
                      <span className='tab-name-container'>
                        {selectedDataCount > 0
                          ? `${tab.label} (${selectedDataCount})`
                          : tab.label}
                      </span>
                    </TooltipDefinition>
                  ) : (
                    <span className='tab-name-container'>
                      {selectedDataCount > 0
                        ? `${tab.label} (${selectedDataCount})`
                        : tab.label}
                    </span>
                  )
                }
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  return true;
                }}
                disabled={isTabDisabled(tab.name)}
              >
                <div className='table'>
                  <GenericTableWithFilters
                    id={tab.name}
                    rows={
                      fromToOptionTableRows(tab.name) ??
                      getInlineFilterData(tab.name, formattedRows)
                        ? appliedFilters.length > 0
                          ? sortData(
                              getFormattedRows(
                                filteredItems as any,
                                tab.name as TableTypes,
                                tab.valueColumn
                              ),
                              sortKey,
                              sortDirection
                            )
                          : sortData(
                              getFormattedRows(
                                inlineFilteredTableData,
                                tab.name as TableTypes,
                                tab.valueColumn
                              ),
                              sortKey,
                              sortDirection
                            )
                        : null
                    }
                    data={
                      inlineFilteredTableData
                        ? appliedFilters.length > 0
                          ? filteredItems
                          : inlineFilteredTableData
                        : null
                    }
                    headers={headers}
                    isSortable={true}
                    totalElementsCount={
                      inlineFilteredTableData
                        ? appliedFilters.length > 0
                          ? filteredItems?.length
                          : inlineFilteredTableData?.length
                        : 0
                    }
                    fullData={inlineFilteredTableData}
                    onTableRefresh={() => {
                      handleRefresh();
                      onRefresh(tab.name as TableTypes);
                    }}
                    filteredDataCallback={(data: ConnectionTypesItems) => {
                      if (tab.name === 'partitions') {
                        if (
                          JSON.stringify(filteredPartitionData) !==
                          JSON.stringify(data)
                        ) {
                          setFilteredPartitionData(data);
                          setPageNumber(1);
                        }
                      } else {
                        if (
                          JSON.stringify(filteredData) !== JSON.stringify(data)
                        ) {
                          setFilteredData(data);
                          setPageNumber(1);
                        }
                      }
                    }}
                    selectedFiltersVal={appliedFilters as any}
                    setFilterApplied={(data: AppliedFilter[]) =>
                      tab.name === 'partitions'
                        ? setPartitionFilterApplied(data)
                        : setFilterApplied(data)
                    }
                    filters={getFilter(filters, inlineFilteredTableData) as any}
                    currentPageNumber={currentPageNumber}
                    currentPageSize={currentPageSize}
                    onPageChange={(pageData: any) => setPageChange(pageData)}
                    emptyState={emptyState}
                    sortRows={(
                      data: { id: string; text: string },
                      sortDirection: 'ASC' | 'DESC' | 'NONE'
                    ) => handleTableSort(data, sortDirection)}
                    leftInlineFilters={
                      tab.name === 'partitions'
                        ? (getPartitionLeftInlineFilters(tableData) as Filter[])
                        : []
                    }
                    render403Container={show403Container(tab.name)}
                    visibilityFlag={partitionVisibilityFlag}
                  />
                </div>
              </Tab>
            );
          })}
      </Tabs>
    </WideTearsheet>
  );
};

export default PolicyOptions;
