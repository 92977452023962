import { Application16, EdgeService16, Folders16 } from '@carbon/icons-react';
import images from '../../../../images/images';

export type IconType =
  | 'applications'
  | 'services'
  | 'partitions'
  | 'nwSegments';

const iconConfig = {
  applications: Application16,
  services: EdgeService16,
  deployments: Folders16,
  partitions: images.partitionIcon,
  nwSegments: Folders16,
};

export const getIcon = (type: IconType) => iconConfig[type];
