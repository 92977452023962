import React, { useEffect, useState } from 'react';
import {
  Column,
  Row,
  TextInput,
  TextArea,
  ComboBox,
  SkeletonPlaceholder,
  FormLabel,
  TooltipIcon,
  Checkbox,
} from 'carbon-components-react';
import { Tooltip } from '@carbon/react';
import { useTranslation } from 'react-i18next';
import AddLabels from '../../../components/AddLabels/AddLabels';
import './EditDetails.scss';
import { getResourceGroups } from '../../../controllers/resourceGroupApi';
import { Locked16 } from '@carbon/icons-react';
import images from '../../../images/images';
import { NetworkSegmentCompatibilitySet } from '../../../lib/enums';
import GenericStatusField from '../../../components/GenericStatusField/GenericStatusField';

interface FormData {
  name: {
    value: string;
    error: boolean;
    errorMessage: string;
  };
  description: {
    value: string;
    error: boolean;
    errorMessage: string;
  };
  resourceGroup: {
    value: any;
    error: boolean;
    errorMessage: string;
  };
  labels: {
    value: string[];
    error: boolean;
    errorMessage: string;
  };
  resourceGroupList: {
    resource_id: string;
    name: string;
    description: string;
    type: string;
  }[];
  networkSegment: {
    resource_id: string;
    name: string;
    compatibility_set: string;
  };
}

interface Props {
  formData: FormData;
  onChange: (e: { target: { name: string; value: any } }) => void;
  checkFormValid: boolean;
  hasNetworkSegmentAuth: boolean;
}

const EditDetails: React.FC<Props> = ({
  formData,
  onChange,
  checkFormValid,
  children,
  hasNetworkSegmentAuth,
}) => {
  const { name, description, resourceGroup, resourceGroupList, labels } =
    formData;

  const { t } = useTranslation('editPolicy');

  const [resourceGroupName, setresourceGroupName] = useState<any>();
  const [formValue, setFormValue] = useState(false);

  useEffect(() => {
    getResourceGroups().then(response => {
      setresourceGroupName(
        response.resource_groups
          ? response.resource_groups.find(
              (resource: { resource_id: any }) =>
                resource?.resource_id === resourceGroup?.value
            )
          : ([] as any)
      );
      setFormValue(true);
    });
  }, [resourceGroup?.value]);

  return (
    <>
      {children}

      <div className='connection-access-policy-edit-details'>
        <div className='sub-header'>{t('editDetails.subtitle')}</div>
        <Row className='row'>
          <Column md={4}>
            {!formValue ? (
              <>
                <div className='form-field'>{t('editDetails.name')}</div>
                <SkeletonPlaceholder className='edit-policy-resourceGroup-skelton' />
              </>
            ) : (
              <TextInput
                labelText={t('editDetails.name') as string}
                id='edit-policy-name'
                value={name.value}
                name='name'
                placeholder={t('editDetails.PolicyNamePlaceholder')}
                onChange={onChange}
                invalid={name.error}
                invalidText={name.errorMessage}
                autoComplete='off'
                maxLength={60}
              />
            )}
          </Column>
          <Column md={4} sm={5}>
            {!formValue ? (
              <>
                <div className='form-field'>{t('editDetails.appGroup')}</div>
                <SkeletonPlaceholder className='edit-policy-resourceGroup-skelton' />
              </>
            ) : (
              <ComboBox
                id='edit-policy-resourceGroup'
                name='resourceGroup'
                className='form-field'
                selectedItem={resourceGroupName as any}
                onChange={(data: { selectedItem: any }) =>
                  onChange({
                    target: {
                      name: 'resourceGroup',
                      value: data?.selectedItem?.resource_id,
                    },
                  })
                }
                items={resourceGroupList ?? []}
                itemToString={(item: { name: any }) => (item ? item.name : '')}
                titleText={t('editDetails.appGroup')}
                translateWithId={t}
                placeholder={t('editDetails.resourceGroupText')}
                invalid={formData.resourceGroup.error}
                invalidText={formData.resourceGroup.errorMessage}
              />
            )}
          </Column>
        </Row>
        <Row className='row'>
          <Column md={4}>
            {!formValue ? (
              <>
                <div className='form-field'>
                  {t('editDetails.networkSegment')}
                </div>
                <SkeletonPlaceholder className='edit-policy-resourceGroup-skelton' />
              </>
            ) : (
              <>
                <div className='network-segment-edit'>
                  {hasNetworkSegmentAuth ? (
                    <TextInput
                      labelText={
                        <div className='network-segment-edit-label'>
                          <span className='network-segment-edit-label-name'>
                            {t('editDetails.networkSegment') as string}
                          </span>
                          <span>
                            <Tooltip
                              description={
                                t('editDetails.networkSegmentTooltip') as string
                              }
                              align='right'
                            >
                              <button className='segment-desc-tooltip'>
                                {images.infoIconTranspSvg()}
                              </button>
                            </Tooltip>
                          </span>
                        </div>
                      }
                      id='edit-policy-networkSegment'
                      value={formData.networkSegment.name}
                      name='networkSegment'
                      placeholder={t('editDetails.networkSegment')}
                      readOnly
                    />
                  ) : (
                    <>
                      <div className='network-segment-edit-label notAuthorized'>
                        <span className='network-segment-edit-label-name'>
                          {t('editDetails.networkSegment') as string}
                        </span>
                        <GenericStatusField status='notAuthorised'></GenericStatusField>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </Column>
        </Row>
        <Row className='row'>
          <Column md={4}>
            {!formValue ? (
              <>
                <div className='form-field'>{t('editDetails.appGroup')}</div>
                <SkeletonPlaceholder className='edit-policy-resourceGroup-skelton' />
              </>
            ) : (
              <>
                <FormLabel>{t('editDetails.action')}</FormLabel>
                <div className='selected-action-card'>
                  <span className='selected-action-icon'>
                    <span className='selected-action-name'>
                      {t('editDetails.allow')}
                    </span>
                  </span>
                  <TooltipIcon
                    tooltipText={t('editDetails.lockIconTooltipText') as string}
                  >
                    <Locked16 />
                  </TooltipIcon>
                </div>
              </>
            )}
          </Column>
          <Column md={4}>
            {!formValue ? (
              <>
                <div className='form-field'>{t('editDetails.appGroup')}</div>
                <SkeletonPlaceholder className='edit-policy-resourceGroup-skelton' />
              </>
            ) : (
              <AddLabels
                id='edit-policy-labels'
                labelText={t('editDetails.labelName')}
                placeholder={t('editDetails.labelPlaceholder')}
                onChange={data =>
                  onChange({ target: { name: 'labels', value: data } })
                }
                btnText={t('editDetails.labelbtnText')}
                btnKind='secondary'
                tagType='green'
                defaultValues={labels.value}
              />
            )}
          </Column>
        </Row>
        <Row className='row'>
          <Column md={8}>
            {!formValue ? (
              <>
                <div className='form-field'>{t('editDetails.description')}</div>
                <SkeletonPlaceholder className='edit-policy-resourceGroup-skelton' />
              </>
            ) : (
              <TextArea
                id='edit-policy-desciption'
                name='description'
                autoComplete='off'
                labelText={t('editDetails.description')}
                placeholder={t('editDetails.descriptionPlaceholder')}
                onChange={onChange}
                value={description.value}
                maxCount={300}
                enableCounter={true}
              />
            )}{' '}
          </Column>
        </Row>
      </div>
    </>
  );
};

export default EditDetails;
