import { useQuery } from '@tanstack/react-query';
import { QueryOptions, queryDefaultOptions } from './queryDefaults';
import { getTopologyResources } from '../controllers/topology';
import { QueryKeys, VisibilityFlags } from '../lib/enums';

export const useTopology = (
  view = VisibilityFlags.ALL,
  options?: QueryOptions
) => {
  return useQuery({
    queryKey: [QueryKeys.TOPOLOGY],
    queryFn: () => getTopologyResources(view),
    ...queryDefaultOptions,
    ...options,
  });
};
