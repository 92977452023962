import { HTTPError404 } from '@carbon/ibm-products';
import { useTranslation } from 'react-i18next';

export const Error404Page = () => {
  const { t }: { t: any } = useTranslation('error404Page');

  return (
    <HTTPError404
      errorCodeLabel={t('errorCodeLabel')}
      title={t('title')}
      description={t('description')}
      links={[
        {
          href: '/',
          text: t('backToHome'),
        },
      ]}
    />
  );
};

export default Error404Page;
