import React from 'react';
import { useTranslation } from 'react-i18next';
import { TagSet } from '@carbon/ibm-products';
import './LabelTag.scss';

export type TagType = { default: boolean; value: string };

const LabelTag = (props: {
  labelArray: string[] | TagType[];
  count?: number;
  singleLine?: boolean;
}) => {
  const { labelArray, count, singleLine } = props;
  const { t } = useTranslation('detailsCard');

  const labelCountData = () => {
    return labelArray?.map((tag: TagType | string) => ({
      label: typeof tag === 'object' ? tag.value : tag,
      type: typeof tag === 'object' && tag.default ? 'outline' : 'green',
    }));
  };

  return (
    <div className='label-tags'>
      {Array.isArray(labelArray) && labelArray?.length > 0 ? (
        <TagSet
          overflowClassName='label-tag-set'
          allTagsModalSearchLabel={t('labelTag.searchAllTags')}
          allTagsModalSearchPlaceholderText={t('labelTag.searchAllTags')}
          allTagsModalTitle={t('labelTag.allTags')}
          showAllTagsLabel={t('labelTag.ViewAllTags')}
          tags={labelCountData()}
          maxVisible={count}
          onClick={(e: any) => e.stopPropagation()}
          multiline={!singleLine ? true : false}
        />
      ) : (
        '—'
      )}
    </div>
  );
};

export default LabelTag;
