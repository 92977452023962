import { axiosInstance } from './api';
import { MCNM_AUTHORIZATION_API_URL } from './config';

// Get all of the permissions that MCNM oversees
export async function getPermissions() {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_AUTHORIZATION_API_URL}/permission`,
    });
    return response.data;
  } catch (error) {
    console.log('error in GET Permissions', error);
    throw error;
  }
}

//Add a new permission that MCNM should oversee.
export async function addPermission() {}

//Delete an existing permission that MCNM oversees
export async function deletePermission(permission_id) {}

//Update an existing permission that MCNM oversees.
export async function updatePermission(permission_id) {}

//Get all of the Roles for a given Permission that MCNM oversees.
export async function getPermissionRoles(permission_id) {}

//Associate a role with an existing permission that MCNM oversees
export async function addPermissionRole(permission_id, role_id) {}
