import { useQueries, useQuery } from '@tanstack/react-query';
import { getGateways } from '../controllers/gateway';
import { QueryOptions, queryDefaultOptions } from './queryDefaults';
import { QueryKeys } from '../lib/enums';
import { GatewayCustomData } from '../models/master';
import { getCloudDetails } from '../controllers/cloud';
import { getLocationDetails } from '../controllers/location';
import { getPartition } from '../controllers/deploymentEnvironment';
import { useMemoizedCollecionPick } from './useMemoizedCollecionPick';

export const useGateways = (view = 'all', options?: QueryOptions) => {
  return useQuery({
    queryKey: [QueryKeys.GATEWAYS],
    queryFn: () => getGateways(view),
    ...queryDefaultOptions,
    ...options,
  });
};

export const useGatewaysCloudDetails = (
  gateways: GatewayCustomData[],
  options?: QueryOptions
) => {
  const result = useQueries({
    queries:
      gateways?.length > 0
        ? gateways.map(gateway => {
            return {
              queryKey: [QueryKeys.CLOUDDETAILS, gateway.cloud_id],
              queryFn: () => getCloudDetails(gateway.cloud_id),
              ...queryDefaultOptions,
              ...options,
              enabled: !!options?.enabled && !!gateway.cloud_id,
            };
          })
        : [],
  });
  return useMemoizedCollecionPick(result);
};

export const useGatewaysLocationDetails = (
  gateways: GatewayCustomData[],
  options?: QueryOptions
) => {
  const result = useQueries({
    queries:
      gateways?.length > 0
        ? gateways.map(gateway => {
            return {
              queryKey: [
                QueryKeys.LOCATIONSDETAILS,
                gateway.cloud_id,
                gateway.location_id,
              ],
              queryFn: () =>
                getLocationDetails(gateway.cloud_id, gateway.location_id),
              ...queryDefaultOptions,
              ...options,
              enabled:
                !!options?.enabled &&
                !!gateway.cloud_id &&
                !!gateway.location_id,
            };
          })
        : [],
  });
  return useMemoizedCollecionPick(result);
};

export const useGatewaysPartitionDetails = (
  gateways: GatewayCustomData[],
  options?: QueryOptions
) => {
  const result = useQueries({
    queries:
      gateways?.length > 0
        ? gateways.map(gateway => {
            return {
              queryKey: [
                QueryKeys.PARTITIONDETAILS,
                gateway.deployed_in_depl_env_id,
                gateway.deployed_in_partition_id,
              ],
              queryFn: () =>
                getPartition(
                  gateway.deployed_in_depl_env_id as string,
                  gateway.deployed_in_partition_id as string
                ),
              ...queryDefaultOptions,
              ...options,
              enabled:
                !!options?.enabled &&
                !!gateway.deployed_in_depl_env_id &&
                !!gateway.deployed_in_partition_id,
            };
          })
        : [],
  });

  return useMemoizedCollecionPick(result);
};
