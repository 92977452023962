import { useState, useEffect, useContext } from 'react';
import GenericTableWithFilters from '../../../../components/GenericTableWithFilters/GenericTableWithFilters';
import { useTranslation } from 'react-i18next';
import {
  RemoteConnections,
  AppliedFilter,
  Gateway,
} from '../../../../models/master';
import {
  deleteGatewayRemoteConnection,
  getGatewayRemoteConnections,
} from '../../../../controllers/gateawayApis';
import images from '../../../../images/images.js';
import { Link } from 'react-router-dom';
import sortData from '../../../../lib/tableSort';
import GenericTruncateString from '../../../../components/GenericTruncateString/GenericTruncateString';
import { Modal, TooltipDefinition } from 'carbon-components-react';
import IconWithToolTip from '../../../../components/IconWithToolTip/IconWithToolTip';
import { TrashCan16 } from '@carbon/icons-react';
import useAnalytics from '../../../../lib/useAnalytics';
import { NotificationContext } from '../../../../components/Notifications/Context/NotificationProvider';
import { AxiosError } from 'axios';
import RegisterRemoteConnection from '../RegisterRemoteConnection/RegisterRemoteConnection';
import {
  getDeploymentEnv,
  getPartition,
} from '../../../../controllers/deploymentEnv';
import {
  GatewaySubTypes,
  RemoteConnectionDirection,
} from '../../../../lib/enums';

import './RemoteConnectionTable.scss';

interface TableRows {
  id: any;
  name: string;
  name__format?: JSX.Element;
  linkMetric?: string;
  outboundMetric?: string;
  inboundMetric?: string;
  actionBtns?: JSX.Element;
  createdDirection?: string;
  createdDirection_format?: JSX.Element;
  deployedIn?: string | undefined;
}

interface TableHeader {
  key: string;
  originalKey: string;
  sort: string | boolean;
  header: string | JSX.Element;
  style?: {};
}

interface RemoteConnectionData extends RemoteConnections {
  name: string;
  inboundMetric: string;
  remote_gw_connection_resource_id: string;
  createdDirection: string;
  deployedIn: string | undefined;
}

interface Props {
  gatewayData: Gateway | null;
  gatewayID: string | null;
  gatewaysList: Gateway[] | null;
  networkSegment: {
    resource_id: string;
    name: string;
  } | null;
}

const RemoteConnectionsTable = (props: Props) => {
  const { t } = useTranslation('remoteConnectionDetails');

  const { gatewayID, gatewaysList, gatewayData, networkSegment } = props;

  const tableHeaders = () => {
    const tableHeaders: TableHeader[] = [];

    if (gatewayData?.subtype !== 'RHSI-EDGE') {
      tableHeaders.push(
        {
          key: 'name__format',
          originalKey: 'name',
          sort: 'sortByName',
          header: t('tableLabels.remoteGateway'),
        },
        {
          key: 'outboundMetric',
          originalKey: 'this_gw_outboundMetric',
          sort: 'sortByThis_gw_outboundMetric',
          header: (
            <TooltipDefinition
              tooltipText={t('tableLabelsTooltip.outboundMetricTooltip')}
              align='center'
              direction='top'
            >
              {t('tableLabels.outboundMetric')}
            </TooltipDefinition>
          ),
        },
        {
          key: 'inboundMetric',
          originalKey: 'this_gw_inboundMetric',
          sort: 'sortByThis_gw_inboundMetric',
          header: (
            <TooltipDefinition
              tooltipText={t('tableLabelsTooltip.inboundMetricTooltip')}
              align='center'
              direction='top'
            >
              {t('tableLabels.inboundMetric')}
            </TooltipDefinition>
          ),
        }
      );
    } else {
      tableHeaders.push(
        {
          key: 'createdDirection_format',
          originalKey: 'createdDirection',
          sort: 'sortByCreatedDirection',
          header: (
            <TooltipDefinition
              tooltipText={t('tableLabelsTooltip.createdDirectionTooltip')}
              direction='bottom'
            >
              {t('tableLabels.createdDirection')}
            </TooltipDefinition>
          ),
        },
        {
          key: 'name__format',
          originalKey: 'name',
          sort: 'sortByName',
          header: t('tableLabels.remoteGateway'),
        },
        {
          key: 'deployedIn',
          originalKey: 'creatdeployedInedDirection',
          sort: 'sortByDeployedIn',
          header: t('tableLabels.deployedIn'),
        },
        {
          key: 'linkMetric',
          originalKey: 'linkMetric',
          sort: 'linkMetric',
          header: (
            <TooltipDefinition
              tooltipText={t('tableLabelsTooltip.linkMetricTooltip')}
              direction='bottom'
            >
              {t('tableLabels.linkMetric')}
            </TooltipDefinition>
          ),
        },
        {
          key: 'actionBtns',
          originalKey: '',
          header: '',
          sort: false,
          style: { minWidth: '3.125rem' },
        }
      );
    }

    return tableHeaders;
  };

  const [currentPageNumber, setPageNumber] = useState(1);
  const [currentPageSize, setPageSize] = useState(10);
  const [remoteConnectionsCount, setRemoteConnectionsCount] = useState(0);
  const [tableData, setTableData] = useState<RemoteConnections[] | null>(null);
  const [filteredData, setFilteredData] = useState<RemoteConnections[] | []>(
    []
  );
  const [remoteconnectionDataLoading, setRemoteconnectionDataLoading] =
    useState(false);
  const [filterApplied, setFilterApplied] = useState<AppliedFilter[] | []>([]);
  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | 'NONE'>(
    'NONE'
  );
  const [deleteModalOpen, toggleDeleteModal] = useState(false);
  const [deleteRow, setDeleteRow] = useState<RemoteConnectionData | null>(null);
  const [disableDeleteSubmitBtn, setDisableDeleteSubmitBtn] =
    useState<boolean>(false);
  const notification = useContext(NotificationContext);
  const { trackButtonClicked } = useAnalytics();
  const [
    openRegisterRemoteConnectionModel,
    setOpenRegisterRemoteConnectionModel,
  ] = useState(false);

  const handleTableSort = (
    data: { id: string; text: string },
    sortDirection: 'ASC' | 'DESC' | 'NONE'
  ) => {
    setSortDirection(sortDirection);
    setSortKey(data.id);
  };

  const fetchDeployedIn = async (id: String) => {
    let gateway: Gateway | null;
    let namespace;
    let depEnv;
    let deployedIn = '—';

    gateway =
      gatewaysList?.find(
        (gw: { resource_id: string | undefined }) => gw.resource_id === id
      ) ?? null;

    if (gateway && gateway?.deployed_in_depl_env_id) {
      try {
        depEnv = await getDeploymentEnv(gateway?.deployed_in_depl_env_id);
      } catch (error) {
        console.error(error);
        depEnv = '';
      }

      if (gateway?.deployed_in_partition_id) {
        try {
          namespace = await getPartition(
            gateway?.deployed_in_depl_env_id,
            gateway?.deployed_in_partition_id
          );
        } catch (error) {
          console.error(error);
          namespace = '';
        }
      }

      if (depEnv?.name) {
        deployedIn = (depEnv?.name).concat(
          ' / ',
          namespace?.name || t('noPartitionAvailable')
        );
      } else {
        deployedIn = namespace?.name || '—';
      }
    }

    return deployedIn;
  };

  const refreshData = async () => {
    try {
      setRemoteconnectionDataLoading(true);
      setTableData(null);
      setFilterApplied([]);
      setFilteredData([]);
      setSortKey('');
      setSortDirection('NONE');

      const remoteGwConnections: RemoteConnections[] =
        await getGatewayRemoteConnections(gatewayID);

      setRemoteConnectionsCount(remoteGwConnections?.length);

      const remConnData: RemoteConnectionData[] = [];

      if (Array.isArray(remoteGwConnections)) {
        for (const remoteGwConnection of remoteGwConnections) {
          const remote_gw =
            gatewayData?.subtype === GatewaySubTypes.RHSI_EDGE
              ? remoteGwConnection?.remote_gw_id === gatewayID
                ? remoteGwConnection?.gateway_id
                : remoteGwConnection?.remote_gw_id
              : remoteGwConnection?.remote_gw_id;
          const name =
            gatewaysList && Array.isArray(gatewaysList)
              ? gatewaysList?.find(
                  resource => resource.resource_id === remote_gw ?? ''
                )?.name ?? ''
              : '';

          const inboundMetric =
            gatewayData?.subtype !== GatewaySubTypes.RHSI_EDGE
              ? await getInboundMetric(remoteGwConnection?.remote_gw_id)
              : '';
          const remote_gw_connection_resource_id =
            await getInboundConnectionResourceId(remote_gw);
          const deployedIn =
            gatewayData?.subtype === GatewaySubTypes.RHSI_EDGE
              ? await fetchDeployedIn(remote_gw)
              : '';
          const createdDirection =
            gatewayData?.subtype === GatewaySubTypes.RHSI_EDGE
              ? remoteGwConnection?.remote_gw_id === gatewayID
                ? t('inbound')
                : t('outbound')
              : '';

          remConnData.push({
            ...remoteGwConnection,
            name,
            inboundMetric,
            remote_gw_connection_resource_id,
            createdDirection,
            deployedIn,
          });
        }
      }

      setTableData(remConnData);
    } catch (e) {
      setRemoteConnectionsCount(0);
      console.error(e);
    } finally {
      setRemoteconnectionDataLoading(false);
    }
  };

  const getInboundConnectionResourceId = async (remoteGatewayId: string) => {
    try {
      const remoteGwConnections = await getGatewayRemoteConnections(
        remoteGatewayId
      );

      for (const remoteGwConnection of remoteGwConnections) {
        if (remoteGwConnection.remote_gw_id === gatewayID) {
          return remoteGwConnection.resource_id;
        }
      }

      return '';
    } catch (error) {
      console.error(error);
      return '';
    }
  };

  const getInboundMetric = async (remoteGatewayId: string) => {
    try {
      const remoteGwConnections = await getGatewayRemoteConnections(
        remoteGatewayId
      );

      for (const remoteGwConnection of remoteGwConnections) {
        if (remoteGwConnection.remote_gw_id === gatewayID) {
          return remoteGwConnection.cost;
        }
      }

      return '';
    } catch (error) {
      console.error(error);
      return '';
    }
  };

  useEffect(() => {
    if (gatewaysList && gatewayID && gatewayData) refreshData();
  }, [gatewayID, gatewayData]);

  const setRowsData = (data: RemoteConnectionData[]) => {
    let formattedRows: TableRows[] = [];
    if (data && data.length === 0) return [];
    if (data)
      data.map((row: RemoteConnectionData) => {
        formattedRows.push({
          id: row?.resource_id || '',
          createdDirection: row?.createdDirection || '',
          createdDirection_format: !row?.createdDirection ? (
            <span>{'—'}</span>
          ) : row?.createdDirection === t('inbound') ? (
            <div className='direction-arrow-format'>
              <div className='direction-arrow-text'>{t('inbound')}</div>
              <div className='direction-arrow'>{images.leftArrowSvg()}</div>
            </div>
          ) : (
            <div className='direction-arrow-format'>
              <div className='direction-arrow-text'>{t('outbound')}</div>
              <div className='direction-arrow'>{images.rightArrowSvg()}</div>
            </div>
          ),
          name: row?.name || '—',
          name__format: (
            <div className='name-column-value'>
              <Link
                className='no-underline-link'
                to={`/gatewayDetails?gatewayId=${
                  row?.remote_gw_id !== gatewayID
                    ? row?.remote_gw_id
                    : row?.gateway_id
                }`}
              >
                <GenericTruncateString
                  str={row?.name || '—'}
                  tableView={true}
                />
              </Link>
            </div>
          ),
          deployedIn: row?.deployedIn || '—',
          linkMetric: row?.cost || '—',
          outboundMetric: row?.cost || '—',
          inboundMetric: row?.inboundMetric || '—',
          actionBtns: (
            <div className='action-buttons'>
              <div
                className='delete-remote-connection-btn'
                onClick={() => {
                  confirmRemoteConnectionDeletion(row);
                }}
              >
                <IconWithToolTip
                  icon={<TrashCan16 />}
                  iconDescription={t('tableLabelsTooltip.delete') as string}
                />
              </div>
            </div>
          ),
        });
        return 0;
      });
    else return null;
    return formattedRows;
  };

  const setPageChange = (pageData: { page: number; pageSize: number }) => {
    setPageNumber(pageData.page);
    setPageSize(pageData.pageSize);
  };

  const confirmRemoteConnectionDeletion = (deleteRow: RemoteConnectionData) => {
    trackButtonClicked({
      CTA: 'Gateways Details, Delete gateway remote connection',
      action: 'Deleting a gateway remote connection',
    });
    setDeleteRow(deleteRow);
    toggleDeleteModal(true);
  };
  const actionButtonShow = () => {
    if (
      gatewayData?.subtype === 'RHSI-EDGE' &&
      gatewayData?.terminate === false &&
      !remoteconnectionDataLoading
    ) {
      return {
        text: t('createRemoteConnection'),
        actionButtonCallback: () => setOpenRegisterRemoteConnectionModel(true),
      };
    }
  };

  const deleteRemoteConnection = () => {
    toggleDeleteModal(false);
    deleteRow && handleDeleteRemoteConnection();
  };

  const handleDeleteRemoteConnection = async () => {
    try {
      setRemoteconnectionDataLoading(true);
      setDisableDeleteSubmitBtn(true);

      await deleteGatewayRemoteConnection(
        deleteRow?.createdDirection === RemoteConnectionDirection.outbound
          ? deleteRow?.gateway_id
          : deleteRow?.remote_gw_id,
        deleteRow?.resource_id
      );

      notification.onTrigger('TOAST', {
        title: t('deleteSuccessNotification.title'),
        subtitle: t('deleteSuccessNotification.subtitle'),
      });

      refreshData();
    } catch (error: any) {
      console.log(error);
      const err = error as AxiosError;
      const errorMessage: string =
        error.response !== undefined
          ? error.response['customErrorMessage']
          : '';
      notification.onTrigger('TOAST', {
        kind: 'error',
        title:
          err.response?.status === 403
            ? (t('deletefailureNotification.authErrorTitle') as string)
            : (t('deletefailureNotification.title') as string),

        subtitle:
          err.response?.status === 403
            ? (t('deletefailureNotification.authErrorSubtitle') as string)
            : errorMessage.length > 0
            ? errorMessage
            : (t('deletefailureNotification.subtitle') as string),
      });
    } finally {
      setRemoteconnectionDataLoading(false);
      setDisableDeleteSubmitBtn(false);
    }
  };

  const getEmptyState = () => {
    return {
      icon: images.NoRemoteConnSmallIcon(),
      notFoundIcon: images.NotFoundLarge(),
      emptyStateHeader: t('noRemoteConnection'),
      emptyStateDescription: t('noRemoteConnectionDesc'),
      link: false, // need to be made true on implementing add namespace functionality
      buttonText: t('RegisterRemoteConnection'),
      click: () => {},
    };
  };

  return (
    <div className='remote-gateway-connections'>
      <GenericTableWithFilters
        tableDataLoading={remoteconnectionDataLoading}
        title={t('tableLabels.title')}
        id='remote-gateway-connections-table'
        paginationClass={remoteConnectionsCount < 9 ? 'pagination-hide' : ''}
        rows={
          tableData
            ? filterApplied.length > 0
              ? setRowsData(sortData(filteredData, sortKey, sortDirection))
              : setRowsData(sortData(tableData, sortKey, sortDirection))
            : null
        }
        data={
          tableData
            ? filterApplied.length > 0
              ? filteredData
              : tableData
            : null
        }
        headers={tableHeaders()}
        sortRows={(
          data: { id: string; text: string },
          direction: 'ASC' | 'DESC' | 'NONE'
        ) => handleTableSort(data, direction)}
        isSortable={true}
        totalElementsCount={
          tableData
            ? filterApplied.length > 0
              ? filteredData.length
              : tableData.length
            : 0
        }
        fullData={tableData}
        onTableRefresh={() => refreshData()}
        showRefresh={false}
        showSearch={(tableData && tableData?.length > 0) || false}
        currentPageNumber={currentPageNumber}
        currentPageSize={currentPageSize}
        onPageChange={(pageData: any) => setPageChange(pageData)}
        emptyState={getEmptyState()}
        selectedFiltersVal={filterApplied}
        setFilterApplied={data => {
          setFilterApplied(data);
        }}
        filteredDataCallback={(data: RemoteConnections[]) => {
          setFilteredData(data as RemoteConnections[] | []);
          setPageNumber(1);
        }}
        actionButton={actionButtonShow()}
      />
      <Modal
        className='delete-remote-connection-confirm-modal'
        danger
        modalHeading={t('deleteModal.header', {
          remoteGatewayName: deleteRow?.name,
        })}
        onRequestClose={() => toggleDeleteModal(false)}
        onRequestSubmit={() => deleteRemoteConnection()}
        primaryButtonText={t('deleteModal.confirmBtn')}
        secondaryButtonText={t('deleteModal.cancelBtn')}
        open={deleteModalOpen}
        size='sm'
        preventCloseOnClickOutside
        primaryButtonDisabled={disableDeleteSubmitBtn}
      >
        {t('deleteModal.body.text')}{' '}
        <span className='text-bold'>{gatewayData?.name}</span>
        {t('deleteModal.body.concatText')}{' '}
        <span className='text-bold'>{deleteRow?.name}</span>.
      </Modal>

      <RegisterRemoteConnection
        open={openRegisterRemoteConnectionModel}
        onClose={() => setOpenRegisterRemoteConnectionModel(false)}
        onRegister={() => refreshData()}
        gatewayData={gatewayData}
        currentNetworkSegment={networkSegment}
        remoteConnectionsList={tableData}
      />
    </div>
  );
};

export default RemoteConnectionsTable;
