import { DeploymentEnvironment, PartitionData } from '../models/master';
import { axiosInstance } from './api';
import { MCNM_NETWORK_API_URL } from './config';

export const getDeploymentEnvs = async (
  view = 'all',
  includeInfraOnly = 'all'
) => {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/deploymentenv?include_infra_only=${includeInfraOnly}&visibility=${view}`,
    });
    return response?.data?.deploymentenvs ?? [];
  } catch (error) {
    console.log('api error: ', error);
    throw error;
  }
};

export async function getDeploymentEnvSubtypes() {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/deploymentenvsubtypes`,
    });

    return response.data.deploymentenvsubtypes;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const getPartition = async (
  deploymentenv_id: string,
  partition_id: string
) => {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/deploymentenv/${deploymentenv_id}/partition/${partition_id}`,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getClustersNamespaces = async (
  clusters: DeploymentEnvironment[]
) => {
  try {
    let namespaces: PartitionData[] = [];
    for (const cluster of clusters) {
      const namespaceList = await getNamespace(
        cluster.cloud_id,
        cluster.resource_id
      );

      if (
        namespaceList?.namespaces &&
        Array.isArray(namespaceList.namespaces)
      ) {
        // include cluster name in response as api response dont have those details
        namespaceList.namespaces = namespaceList.namespaces.map(
          (namespace: PartitionData) => {
            return {
              ...namespace,
              deployment_env_name: cluster.name,
              cloud_name: cluster.cloud_name,
              location_name: cluster.location_name,
              resource_group_id: cluster.resource_group_id,
            };
          }
        );
        namespaces = [...namespaces, ...namespaceList.namespaces];
      }
    }
    return namespaces;
  } catch (error) {
    throw error;
  }
};

export const getNamespace = async (
  cloud_id: string,
  cluster_id: string,
  view = 'all'
) => {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloud_id}/cluster/${cluster_id}/namespace?visibility=${view}`,
    });
    return response?.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getCloudDeploymentEnvs = async (
  cloudId: string,
  view = 'all',
  includeInfraOnly = 'all'
) => {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/deploymentenv?cloud_id=${cloudId}&include_infra_only=${includeInfraOnly}&visibility=${view}`,
    });
    return response?.data?.deploymentenvs ?? [];
  } catch (error) {
    console.log('api error: ', error);
    throw error;
  }
};
