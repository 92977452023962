import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './SortDropDown.scss';
import { ComboBox } from 'carbon-components-react';

type Item = { id: string; text: string };

type SortDropdownProps = {
  id: string;
  titleText?: string;
  placeholder?: string;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  onSort: (data: Item) => void;
  items?: Item[];
  sortDir: 'ASC' | 'DESC' | 'NONE';
};

const SortDropDown = ({
  id,
  titleText,
  placeholder,
  size = 'lg',
  onSort,
  items,
  sortDir,
}: SortDropdownProps) => {
  const { t } = useTranslation('sortDropDown');

  const defaultValues = [
    { id: 'atoz', text: t('nameAZ') },
    { id: 'ztoa', text: t('nameZA') },
  ];

  const [selectedItem, setSelectedItem] = useState(defaultValues[0]);
  const selectItems = items || defaultValues;

  useEffect(() => {
    if (sortDir === 'DESC') {
      onSort(selectItems[1]);
      setSelectedItem(selectItems[1]);
    } else {
      onSort(selectItems[0]);
      setSelectedItem(selectItems[0]);
    }
  }, [sortDir]);

  return (
    <div
      className='sort-dropdown-component'
      data-testid='sort-dropdown-component'
    >
      <ComboBox
        className='wrapper'
        id={id}
        name='sortDropDown'
        data-testid='sort-dropdown'
        initialSelectedItem={selectItems[0]}
        onChange={(data: { selectedItem: Item }) => onSort(data.selectedItem)}
        items={selectItems}
        itemToString={item => (item ? item.text : '')}
        translateWithId={t}
        light={true}
        titleText={titleText || t('titleText')}
        placeholder={placeholder || t('placeholder')}
        size={size}
        selectedItem={selectedItem}
      />
    </div>
  );
};

export default SortDropDown;
